export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AcceptOrRejectOrganizationInviteInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  isRejected?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
};

/**  Information about `acquiring component` which will generate `billing receipts` and `payments`  */
export type AcquiringIntegration = {
  __typename?: 'AcquiringIntegration';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegration List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegration List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegration List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegration List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of `acquiring component`, which is set up by developer  */
  name?: Maybe<Scalars['String']>;
  /**  Url to setup page of acquiring integration  */
  setupUrl?: Maybe<Scalars['String']>;
  /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
  isHidden?: Maybe<Scalars['Boolean']>;
  accessRights: Array<AcquiringIntegrationAccessRight>;
  _accessRightsMeta?: Maybe<_QueryMeta>;
  /**  Can multiple receipts be united through this acquiring  */
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  /**  Url to acquiring integration service. Mobile devices will use it communicate with external acquiring. List of endpoints is the same for all of them.  */
  hostUrl?: Maybe<Scalars['String']>;
  /**  Supported billing integrations group. Useful when you need to restrict this acquiring to accept payment only from certain billing.  */
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  /**  The minimum payment amount that can be accepted  */
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  /**  Contains information about the default distribution of explicit fee. Each part is paid by the user on top of original amount if there is no part with the same name in the integration context. Otherwise, the part is ignored as it is paid by recipient  */
  explicitFeeDistributionSchema?: Maybe<Array<FeeDistributionField>>;
  /**  Status, which context will have by default after creation if no overwriting option provided  */
  contextDefaultStatus?: Maybe<AcquiringIntegrationContextDefaultStatusType>;
  /**  Comma separated values of VAT. Set by system administrators.  */
  vatPercentOptions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  Information about `acquiring component` which will generate `billing receipts` and `payments`  */
export type AcquiringIntegrationAccessRightsArgs = {
  where?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  Information about `acquiring component` which will generate `billing receipts` and `payments`  */
export type AcquiringIntegration_AccessRightsMetaArgs = {
  where?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/**  Link between Acquiring integration and user, the existence of this connection means that this user has the rights to perform actions on behalf of the integration  */
export type AcquiringIntegrationAccessRight = {
  __typename?: 'AcquiringIntegrationAccessRight';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationAccessRight List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationAccessRight List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Acquiring integration. Determines way of user's payment  */
  integration?: Maybe<AcquiringIntegration>;
  /**  Link to user. Note, that user must be of "service" type  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type AcquiringIntegrationAccessRightCreateInput = {
  integration?: Maybe<AcquiringIntegrationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type AcquiringIntegrationAccessRightHistoryRecord = {
  __typename?: 'AcquiringIntegrationAccessRightHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationAccessRightHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type AcquiringIntegrationAccessRightHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum AcquiringIntegrationAccessRightHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type AcquiringIntegrationAccessRightHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type AcquiringIntegrationAccessRightHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AcquiringIntegrationAccessRightHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type AcquiringIntegrationAccessRightHistoryRecordsCreateInput = {
  data?: Maybe<AcquiringIntegrationAccessRightHistoryRecordCreateInput>;
};

export type AcquiringIntegrationAccessRightHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationAccessRightHistoryRecordUpdateInput>;
};

export type AcquiringIntegrationAccessRightRelateToManyInput = {
  create?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightCreateInput>>>;
  connect?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type AcquiringIntegrationAccessRightUpdateInput = {
  integration?: Maybe<AcquiringIntegrationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type AcquiringIntegrationAccessRightWhereInput = {
  AND?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightWhereInput>>>;
  OR?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightWhereInput>>>;
  integration?: Maybe<AcquiringIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type AcquiringIntegrationAccessRightWhereUniqueInput = {
  id: Scalars['ID'];
};

export type AcquiringIntegrationAccessRightsCreateInput = {
  data?: Maybe<AcquiringIntegrationAccessRightCreateInput>;
};

export type AcquiringIntegrationAccessRightsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationAccessRightUpdateInput>;
};

/**  Object, which links `acquiring integration` with `service provider`, and stores additional data for it's proper work  */
export type AcquiringIntegrationContext = {
  __typename?: 'AcquiringIntegrationContext';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationContext List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationContext List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationContext List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationContext List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Acquiring integration. Determines way of user's payment  */
  integration?: Maybe<AcquiringIntegration>;
  /**  Service provider (organization)  */
  organization?: Maybe<Organization>;
  /**  Settings that are required for acquiring to work properly. The data structure depends on the integration and defined here  */
  settings?: Maybe<Scalars['JSON']>;
  /**  The current state of the integration process. Some integration need to store past state here, additional data and etc.  */
  state?: Maybe<Scalars['JSON']>;
  /**  Contract number and date. Basis for invoicing  */
  reason?: Maybe<Scalars['String']>;
  /**  Contract number and date. Basis for invoicing  */
  invoiceReason?: Maybe<Scalars['String']>;
  /**  Acquiring payments report will be sent to this email  */
  email?: Maybe<Scalars['String']>;
  /**  Email addresses separated by `,` or `;` Acquiring payments report will be sent to this emails  */
  invoiceEmails?: Maybe<Scalars['String']>;
  /**  Requisites from agreement. Are used for invoicing in case of not confirmed billing recipient in receipt  */
  recipient?: Maybe<RecipientField>;
  /**  Requisites from agreement. Are used for invoicing of invoices.  */
  invoiceRecipient?: Maybe<RecipientField>;
  /**  Contains information about the default distribution of implicit fee. Each part is paid by the recipient organization on deducted from payment amount. If part exists then explicit part with the same name from AcquiringIntegration.explicitFeeDistributionSchema is ignored  */
  implicitFeeDistributionSchema?: Maybe<Array<FeeDistributionField>>;
  /**  Contains information about the default distribution of implicit fee. Each part is paid by the recipient organization on deducted from payment amount. If part exists then explicit part with the same name from AcquiringIntegration.explicitFeeDistributionSchema is ignored  */
  invoiceImplicitFeeDistributionSchema?: Maybe<Array<FeeDistributionField>>;
  /**  Status of AcquiringIntegration connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related AcquiringIntegration model  */
  status?: Maybe<Scalars['String']>;
  /**  Status of AcquiringIntegration connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related AcquiringIntegration model  */
  invoiceStatus?: Maybe<Scalars['String']>;
  /**  The regime of counting taxes for company  */
  invoiceTaxRegime?: Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>;
  /**  The percentage of VAT. Depends of integrations settings (see AcquiringIntegration.vatPercentOptions)  */
  invoiceVatPercent?: Maybe<Scalars['String']>;
  /**  The percent value  */
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type AcquiringIntegrationContextCreateInput = {
  integration?: Maybe<AcquiringIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  settings?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['JSON']>;
  reason?: Maybe<Scalars['String']>;
  invoiceReason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invoiceEmails?: Maybe<Scalars['String']>;
  recipient?: Maybe<RecipientFieldInput>;
  invoiceRecipient?: Maybe<RecipientFieldInput>;
  implicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  status?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceTaxRegime?: Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum AcquiringIntegrationContextDefaultStatusType {
  InProgress = 'InProgress',
  Verification = 'Verification',
  Error = 'Error',
  Finished = 'Finished'
}

/**  A keystone list  */
export type AcquiringIntegrationContextHistoryRecord = {
  __typename?: 'AcquiringIntegrationContextHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationContextHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['JSON']>;
  reason?: Maybe<Scalars['String']>;
  invoiceReason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invoiceEmails?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  invoiceRecipient?: Maybe<Scalars['JSON']>;
  implicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceTaxRegime?: Maybe<Scalars['String']>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type AcquiringIntegrationContextHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['JSON']>;
  reason?: Maybe<Scalars['String']>;
  invoiceReason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invoiceEmails?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  invoiceRecipient?: Maybe<Scalars['JSON']>;
  implicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceTaxRegime?: Maybe<Scalars['String']>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum AcquiringIntegrationContextHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type AcquiringIntegrationContextHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['JSON']>;
  reason?: Maybe<Scalars['String']>;
  invoiceReason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invoiceEmails?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['JSON']>;
  invoiceRecipient?: Maybe<Scalars['JSON']>;
  implicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceTaxRegime?: Maybe<Scalars['String']>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type AcquiringIntegrationContextHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  settings?: Maybe<Scalars['JSON']>;
  settings_not?: Maybe<Scalars['JSON']>;
  settings_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  settings_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state?: Maybe<Scalars['JSON']>;
  state_not?: Maybe<Scalars['JSON']>;
  state_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  reason?: Maybe<Scalars['String']>;
  reason_not?: Maybe<Scalars['String']>;
  reason_contains?: Maybe<Scalars['String']>;
  reason_not_contains?: Maybe<Scalars['String']>;
  reason_starts_with?: Maybe<Scalars['String']>;
  reason_not_starts_with?: Maybe<Scalars['String']>;
  reason_ends_with?: Maybe<Scalars['String']>;
  reason_not_ends_with?: Maybe<Scalars['String']>;
  reason_i?: Maybe<Scalars['String']>;
  reason_not_i?: Maybe<Scalars['String']>;
  reason_contains_i?: Maybe<Scalars['String']>;
  reason_not_contains_i?: Maybe<Scalars['String']>;
  reason_starts_with_i?: Maybe<Scalars['String']>;
  reason_not_starts_with_i?: Maybe<Scalars['String']>;
  reason_ends_with_i?: Maybe<Scalars['String']>;
  reason_not_ends_with_i?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceReason?: Maybe<Scalars['String']>;
  invoiceReason_not?: Maybe<Scalars['String']>;
  invoiceReason_contains?: Maybe<Scalars['String']>;
  invoiceReason_not_contains?: Maybe<Scalars['String']>;
  invoiceReason_starts_with?: Maybe<Scalars['String']>;
  invoiceReason_not_starts_with?: Maybe<Scalars['String']>;
  invoiceReason_ends_with?: Maybe<Scalars['String']>;
  invoiceReason_not_ends_with?: Maybe<Scalars['String']>;
  invoiceReason_i?: Maybe<Scalars['String']>;
  invoiceReason_not_i?: Maybe<Scalars['String']>;
  invoiceReason_contains_i?: Maybe<Scalars['String']>;
  invoiceReason_not_contains_i?: Maybe<Scalars['String']>;
  invoiceReason_starts_with_i?: Maybe<Scalars['String']>;
  invoiceReason_not_starts_with_i?: Maybe<Scalars['String']>;
  invoiceReason_ends_with_i?: Maybe<Scalars['String']>;
  invoiceReason_not_ends_with_i?: Maybe<Scalars['String']>;
  invoiceReason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceReason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceEmails?: Maybe<Scalars['String']>;
  invoiceEmails_not?: Maybe<Scalars['String']>;
  invoiceEmails_contains?: Maybe<Scalars['String']>;
  invoiceEmails_not_contains?: Maybe<Scalars['String']>;
  invoiceEmails_starts_with?: Maybe<Scalars['String']>;
  invoiceEmails_not_starts_with?: Maybe<Scalars['String']>;
  invoiceEmails_ends_with?: Maybe<Scalars['String']>;
  invoiceEmails_not_ends_with?: Maybe<Scalars['String']>;
  invoiceEmails_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_i?: Maybe<Scalars['String']>;
  invoiceEmails_contains_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_contains_i?: Maybe<Scalars['String']>;
  invoiceEmails_starts_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_starts_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_ends_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_ends_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceEmails_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipient?: Maybe<Scalars['JSON']>;
  recipient_not?: Maybe<Scalars['JSON']>;
  recipient_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  recipient_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  invoiceRecipient?: Maybe<Scalars['JSON']>;
  invoiceRecipient_not?: Maybe<Scalars['JSON']>;
  invoiceRecipient_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  invoiceRecipient_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  implicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  implicitFeeDistributionSchema_not?: Maybe<Scalars['JSON']>;
  implicitFeeDistributionSchema_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  implicitFeeDistributionSchema_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  invoiceImplicitFeeDistributionSchema_not?: Maybe<Scalars['JSON']>;
  invoiceImplicitFeeDistributionSchema_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  invoiceImplicitFeeDistributionSchema_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceStatus_not?: Maybe<Scalars['String']>;
  invoiceStatus_contains?: Maybe<Scalars['String']>;
  invoiceStatus_not_contains?: Maybe<Scalars['String']>;
  invoiceStatus_starts_with?: Maybe<Scalars['String']>;
  invoiceStatus_not_starts_with?: Maybe<Scalars['String']>;
  invoiceStatus_ends_with?: Maybe<Scalars['String']>;
  invoiceStatus_not_ends_with?: Maybe<Scalars['String']>;
  invoiceStatus_i?: Maybe<Scalars['String']>;
  invoiceStatus_not_i?: Maybe<Scalars['String']>;
  invoiceStatus_contains_i?: Maybe<Scalars['String']>;
  invoiceStatus_not_contains_i?: Maybe<Scalars['String']>;
  invoiceStatus_starts_with_i?: Maybe<Scalars['String']>;
  invoiceStatus_not_starts_with_i?: Maybe<Scalars['String']>;
  invoiceStatus_ends_with_i?: Maybe<Scalars['String']>;
  invoiceStatus_not_ends_with_i?: Maybe<Scalars['String']>;
  invoiceStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceTaxRegime?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not?: Maybe<Scalars['String']>;
  invoiceTaxRegime_contains?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_contains?: Maybe<Scalars['String']>;
  invoiceTaxRegime_starts_with?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_starts_with?: Maybe<Scalars['String']>;
  invoiceTaxRegime_ends_with?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_ends_with?: Maybe<Scalars['String']>;
  invoiceTaxRegime_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_contains_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_contains_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_starts_with_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_starts_with_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_ends_with_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_not_ends_with_i?: Maybe<Scalars['String']>;
  invoiceTaxRegime_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceTaxRegime_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceVatPercent_not?: Maybe<Scalars['String']>;
  invoiceVatPercent_lt?: Maybe<Scalars['String']>;
  invoiceVatPercent_lte?: Maybe<Scalars['String']>;
  invoiceVatPercent_gt?: Maybe<Scalars['String']>;
  invoiceVatPercent_gte?: Maybe<Scalars['String']>;
  invoiceVatPercent_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceVatPercent_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_not?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_lt?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_lte?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_gt?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_gte?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceSalesTaxPercent_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AcquiringIntegrationContextHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type AcquiringIntegrationContextHistoryRecordsCreateInput = {
  data?: Maybe<AcquiringIntegrationContextHistoryRecordCreateInput>;
};

export type AcquiringIntegrationContextHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationContextHistoryRecordUpdateInput>;
};

export enum AcquiringIntegrationContextInvoiceTaxRegimeType {
  General = 'general',
  Simple = 'simple'
}

export type AcquiringIntegrationContextRelateToOneInput = {
  create?: Maybe<AcquiringIntegrationContextCreateInput>;
  connect?: Maybe<AcquiringIntegrationContextWhereUniqueInput>;
  disconnect?: Maybe<AcquiringIntegrationContextWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type AcquiringIntegrationContextUpdateInput = {
  integration?: Maybe<AcquiringIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  settings?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['JSON']>;
  reason?: Maybe<Scalars['String']>;
  invoiceReason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invoiceEmails?: Maybe<Scalars['String']>;
  recipient?: Maybe<RecipientFieldInput>;
  invoiceRecipient?: Maybe<RecipientFieldInput>;
  implicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  status?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceTaxRegime?: Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type AcquiringIntegrationContextWhereInput = {
  AND?: Maybe<Array<Maybe<AcquiringIntegrationContextWhereInput>>>;
  OR?: Maybe<Array<Maybe<AcquiringIntegrationContextWhereInput>>>;
  integration?: Maybe<AcquiringIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
  settings_not?: Maybe<Scalars['JSON']>;
  settings_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  settings_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state?: Maybe<Scalars['JSON']>;
  state_not?: Maybe<Scalars['JSON']>;
  state_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  reason?: Maybe<Scalars['String']>;
  reason_not?: Maybe<Scalars['String']>;
  reason_contains?: Maybe<Scalars['String']>;
  reason_not_contains?: Maybe<Scalars['String']>;
  reason_starts_with?: Maybe<Scalars['String']>;
  reason_not_starts_with?: Maybe<Scalars['String']>;
  reason_ends_with?: Maybe<Scalars['String']>;
  reason_not_ends_with?: Maybe<Scalars['String']>;
  reason_i?: Maybe<Scalars['String']>;
  reason_not_i?: Maybe<Scalars['String']>;
  reason_contains_i?: Maybe<Scalars['String']>;
  reason_not_contains_i?: Maybe<Scalars['String']>;
  reason_starts_with_i?: Maybe<Scalars['String']>;
  reason_not_starts_with_i?: Maybe<Scalars['String']>;
  reason_ends_with_i?: Maybe<Scalars['String']>;
  reason_not_ends_with_i?: Maybe<Scalars['String']>;
  reason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceReason?: Maybe<Scalars['String']>;
  invoiceReason_not?: Maybe<Scalars['String']>;
  invoiceReason_contains?: Maybe<Scalars['String']>;
  invoiceReason_not_contains?: Maybe<Scalars['String']>;
  invoiceReason_starts_with?: Maybe<Scalars['String']>;
  invoiceReason_not_starts_with?: Maybe<Scalars['String']>;
  invoiceReason_ends_with?: Maybe<Scalars['String']>;
  invoiceReason_not_ends_with?: Maybe<Scalars['String']>;
  invoiceReason_i?: Maybe<Scalars['String']>;
  invoiceReason_not_i?: Maybe<Scalars['String']>;
  invoiceReason_contains_i?: Maybe<Scalars['String']>;
  invoiceReason_not_contains_i?: Maybe<Scalars['String']>;
  invoiceReason_starts_with_i?: Maybe<Scalars['String']>;
  invoiceReason_not_starts_with_i?: Maybe<Scalars['String']>;
  invoiceReason_ends_with_i?: Maybe<Scalars['String']>;
  invoiceReason_not_ends_with_i?: Maybe<Scalars['String']>;
  invoiceReason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceReason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceEmails?: Maybe<Scalars['String']>;
  invoiceEmails_not?: Maybe<Scalars['String']>;
  invoiceEmails_contains?: Maybe<Scalars['String']>;
  invoiceEmails_not_contains?: Maybe<Scalars['String']>;
  invoiceEmails_starts_with?: Maybe<Scalars['String']>;
  invoiceEmails_not_starts_with?: Maybe<Scalars['String']>;
  invoiceEmails_ends_with?: Maybe<Scalars['String']>;
  invoiceEmails_not_ends_with?: Maybe<Scalars['String']>;
  invoiceEmails_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_i?: Maybe<Scalars['String']>;
  invoiceEmails_contains_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_contains_i?: Maybe<Scalars['String']>;
  invoiceEmails_starts_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_starts_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_ends_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_not_ends_with_i?: Maybe<Scalars['String']>;
  invoiceEmails_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceEmails_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipient?: Maybe<RecipientFieldInput>;
  recipient_not?: Maybe<RecipientFieldInput>;
  recipient_in?: Maybe<Array<Maybe<RecipientFieldInput>>>;
  recipient_not_in?: Maybe<Array<Maybe<RecipientFieldInput>>>;
  invoiceRecipient?: Maybe<RecipientFieldInput>;
  invoiceRecipient_not?: Maybe<RecipientFieldInput>;
  invoiceRecipient_in?: Maybe<Array<Maybe<RecipientFieldInput>>>;
  invoiceRecipient_not_in?: Maybe<Array<Maybe<RecipientFieldInput>>>;
  implicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  implicitFeeDistributionSchema_not?: Maybe<Array<FeeDistributionFieldInput>>;
  implicitFeeDistributionSchema_in?: Maybe<Array<Maybe<Array<FeeDistributionFieldInput>>>>;
  implicitFeeDistributionSchema_not_in?: Maybe<Array<Maybe<Array<FeeDistributionFieldInput>>>>;
  invoiceImplicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  invoiceImplicitFeeDistributionSchema_not?: Maybe<Array<FeeDistributionFieldInput>>;
  invoiceImplicitFeeDistributionSchema_in?: Maybe<Array<Maybe<Array<FeeDistributionFieldInput>>>>;
  invoiceImplicitFeeDistributionSchema_not_in?: Maybe<Array<Maybe<Array<FeeDistributionFieldInput>>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceStatus?: Maybe<Scalars['String']>;
  invoiceStatus_not?: Maybe<Scalars['String']>;
  invoiceStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceTaxRegime?: Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>;
  invoiceTaxRegime_not?: Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>;
  invoiceTaxRegime_in?: Maybe<Array<Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>>>;
  invoiceTaxRegime_not_in?: Maybe<Array<Maybe<AcquiringIntegrationContextInvoiceTaxRegimeType>>>;
  invoiceVatPercent?: Maybe<Scalars['String']>;
  invoiceVatPercent_not?: Maybe<Scalars['String']>;
  invoiceVatPercent_lt?: Maybe<Scalars['String']>;
  invoiceVatPercent_lte?: Maybe<Scalars['String']>;
  invoiceVatPercent_gt?: Maybe<Scalars['String']>;
  invoiceVatPercent_gte?: Maybe<Scalars['String']>;
  invoiceVatPercent_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceVatPercent_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceSalesTaxPercent?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_not?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_lt?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_lte?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_gt?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_gte?: Maybe<Scalars['String']>;
  invoiceSalesTaxPercent_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceSalesTaxPercent_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type AcquiringIntegrationContextWhereUniqueInput = {
  id: Scalars['ID'];
};

export type AcquiringIntegrationContextsCreateInput = {
  data?: Maybe<AcquiringIntegrationContextCreateInput>;
};

export type AcquiringIntegrationContextsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationContextUpdateInput>;
};

export type AcquiringIntegrationCreateInput = {
  name?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  accessRights?: Maybe<AcquiringIntegrationAccessRightRelateToManyInput>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  explicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  contextDefaultStatus?: Maybe<AcquiringIntegrationContextDefaultStatusType>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type AcquiringIntegrationHistoryRecord = {
  __typename?: 'AcquiringIntegrationHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the AcquiringIntegrationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the AcquiringIntegrationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the AcquiringIntegrationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the AcquiringIntegrationHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  explicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type AcquiringIntegrationHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  explicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum AcquiringIntegrationHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type AcquiringIntegrationHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  explicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type AcquiringIntegrationHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl?: Maybe<Scalars['String']>;
  setupUrl_not?: Maybe<Scalars['String']>;
  setupUrl_contains?: Maybe<Scalars['String']>;
  setupUrl_not_contains?: Maybe<Scalars['String']>;
  setupUrl_starts_with?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with?: Maybe<Scalars['String']>;
  setupUrl_ends_with?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with?: Maybe<Scalars['String']>;
  setupUrl_i?: Maybe<Scalars['String']>;
  setupUrl_not_i?: Maybe<Scalars['String']>;
  setupUrl_contains_i?: Maybe<Scalars['String']>;
  setupUrl_not_contains_i?: Maybe<Scalars['String']>;
  setupUrl_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  canGroupReceipts_not?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  hostUrl_not?: Maybe<Scalars['String']>;
  hostUrl_contains?: Maybe<Scalars['String']>;
  hostUrl_not_contains?: Maybe<Scalars['String']>;
  hostUrl_starts_with?: Maybe<Scalars['String']>;
  hostUrl_not_starts_with?: Maybe<Scalars['String']>;
  hostUrl_ends_with?: Maybe<Scalars['String']>;
  hostUrl_not_ends_with?: Maybe<Scalars['String']>;
  hostUrl_i?: Maybe<Scalars['String']>;
  hostUrl_not_i?: Maybe<Scalars['String']>;
  hostUrl_contains_i?: Maybe<Scalars['String']>;
  hostUrl_not_contains_i?: Maybe<Scalars['String']>;
  hostUrl_starts_with_i?: Maybe<Scalars['String']>;
  hostUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  hostUrl_ends_with_i?: Maybe<Scalars['String']>;
  hostUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  hostUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hostUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_contains?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_contains?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_starts_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_starts_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_ends_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_ends_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_contains_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_contains_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_starts_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_starts_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_ends_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_ends_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  supportedBillingIntegrationsGroup_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  minimumPaymentAmount_not?: Maybe<Scalars['String']>;
  minimumPaymentAmount_lt?: Maybe<Scalars['String']>;
  minimumPaymentAmount_lte?: Maybe<Scalars['String']>;
  minimumPaymentAmount_gt?: Maybe<Scalars['String']>;
  minimumPaymentAmount_gte?: Maybe<Scalars['String']>;
  minimumPaymentAmount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumPaymentAmount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFeeDistributionSchema?: Maybe<Scalars['JSON']>;
  explicitFeeDistributionSchema_not?: Maybe<Scalars['JSON']>;
  explicitFeeDistributionSchema_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  explicitFeeDistributionSchema_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  contextDefaultStatus_not?: Maybe<Scalars['String']>;
  contextDefaultStatus_contains?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_contains?: Maybe<Scalars['String']>;
  contextDefaultStatus_starts_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_starts_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_ends_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_ends_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_contains_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_contains_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_starts_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_starts_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_ends_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_ends_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contextDefaultStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  vatPercentOptions_not?: Maybe<Scalars['String']>;
  vatPercentOptions_contains?: Maybe<Scalars['String']>;
  vatPercentOptions_not_contains?: Maybe<Scalars['String']>;
  vatPercentOptions_starts_with?: Maybe<Scalars['String']>;
  vatPercentOptions_not_starts_with?: Maybe<Scalars['String']>;
  vatPercentOptions_ends_with?: Maybe<Scalars['String']>;
  vatPercentOptions_not_ends_with?: Maybe<Scalars['String']>;
  vatPercentOptions_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_i?: Maybe<Scalars['String']>;
  vatPercentOptions_contains_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_contains_i?: Maybe<Scalars['String']>;
  vatPercentOptions_starts_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_starts_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_ends_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_ends_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  vatPercentOptions_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AcquiringIntegrationHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type AcquiringIntegrationHistoryRecordsCreateInput = {
  data?: Maybe<AcquiringIntegrationHistoryRecordCreateInput>;
};

export type AcquiringIntegrationHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationHistoryRecordUpdateInput>;
};

export type AcquiringIntegrationRelateToOneInput = {
  create?: Maybe<AcquiringIntegrationCreateInput>;
  connect?: Maybe<AcquiringIntegrationWhereUniqueInput>;
  disconnect?: Maybe<AcquiringIntegrationWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type AcquiringIntegrationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  accessRights?: Maybe<AcquiringIntegrationAccessRightRelateToManyInput>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  explicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  contextDefaultStatus?: Maybe<AcquiringIntegrationContextDefaultStatusType>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type AcquiringIntegrationWhereInput = {
  AND?: Maybe<Array<Maybe<AcquiringIntegrationWhereInput>>>;
  OR?: Maybe<Array<Maybe<AcquiringIntegrationWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl?: Maybe<Scalars['String']>;
  setupUrl_not?: Maybe<Scalars['String']>;
  setupUrl_contains?: Maybe<Scalars['String']>;
  setupUrl_not_contains?: Maybe<Scalars['String']>;
  setupUrl_starts_with?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with?: Maybe<Scalars['String']>;
  setupUrl_ends_with?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with?: Maybe<Scalars['String']>;
  setupUrl_i?: Maybe<Scalars['String']>;
  setupUrl_not_i?: Maybe<Scalars['String']>;
  setupUrl_contains_i?: Maybe<Scalars['String']>;
  setupUrl_not_contains_i?: Maybe<Scalars['String']>;
  setupUrl_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  /**  condition must be true for all nodes  */
  accessRights_every?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  /**  condition must be true for at least 1 node  */
  accessRights_some?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  /**  condition must be false for all nodes  */
  accessRights_none?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  canGroupReceipts_not?: Maybe<Scalars['Boolean']>;
  hostUrl?: Maybe<Scalars['String']>;
  hostUrl_not?: Maybe<Scalars['String']>;
  hostUrl_contains?: Maybe<Scalars['String']>;
  hostUrl_not_contains?: Maybe<Scalars['String']>;
  hostUrl_starts_with?: Maybe<Scalars['String']>;
  hostUrl_not_starts_with?: Maybe<Scalars['String']>;
  hostUrl_ends_with?: Maybe<Scalars['String']>;
  hostUrl_not_ends_with?: Maybe<Scalars['String']>;
  hostUrl_i?: Maybe<Scalars['String']>;
  hostUrl_not_i?: Maybe<Scalars['String']>;
  hostUrl_contains_i?: Maybe<Scalars['String']>;
  hostUrl_not_contains_i?: Maybe<Scalars['String']>;
  hostUrl_starts_with_i?: Maybe<Scalars['String']>;
  hostUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  hostUrl_ends_with_i?: Maybe<Scalars['String']>;
  hostUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  hostUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hostUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  supportedBillingIntegrationsGroup?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_contains?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_contains?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_starts_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_starts_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_ends_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_ends_with?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_contains_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_contains_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_starts_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_starts_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_ends_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_not_ends_with_i?: Maybe<Scalars['String']>;
  supportedBillingIntegrationsGroup_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  supportedBillingIntegrationsGroup_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumPaymentAmount?: Maybe<Scalars['String']>;
  minimumPaymentAmount_not?: Maybe<Scalars['String']>;
  minimumPaymentAmount_lt?: Maybe<Scalars['String']>;
  minimumPaymentAmount_lte?: Maybe<Scalars['String']>;
  minimumPaymentAmount_gt?: Maybe<Scalars['String']>;
  minimumPaymentAmount_gte?: Maybe<Scalars['String']>;
  minimumPaymentAmount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  minimumPaymentAmount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFeeDistributionSchema?: Maybe<Array<FeeDistributionFieldInput>>;
  explicitFeeDistributionSchema_not?: Maybe<Array<FeeDistributionFieldInput>>;
  explicitFeeDistributionSchema_in?: Maybe<Array<Maybe<Array<FeeDistributionFieldInput>>>>;
  explicitFeeDistributionSchema_not_in?: Maybe<Array<Maybe<Array<FeeDistributionFieldInput>>>>;
  contextDefaultStatus?: Maybe<AcquiringIntegrationContextDefaultStatusType>;
  contextDefaultStatus_not?: Maybe<AcquiringIntegrationContextDefaultStatusType>;
  contextDefaultStatus_in?: Maybe<Array<Maybe<AcquiringIntegrationContextDefaultStatusType>>>;
  contextDefaultStatus_not_in?: Maybe<Array<Maybe<AcquiringIntegrationContextDefaultStatusType>>>;
  vatPercentOptions?: Maybe<Scalars['String']>;
  vatPercentOptions_not?: Maybe<Scalars['String']>;
  vatPercentOptions_contains?: Maybe<Scalars['String']>;
  vatPercentOptions_not_contains?: Maybe<Scalars['String']>;
  vatPercentOptions_starts_with?: Maybe<Scalars['String']>;
  vatPercentOptions_not_starts_with?: Maybe<Scalars['String']>;
  vatPercentOptions_ends_with?: Maybe<Scalars['String']>;
  vatPercentOptions_not_ends_with?: Maybe<Scalars['String']>;
  vatPercentOptions_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_i?: Maybe<Scalars['String']>;
  vatPercentOptions_contains_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_contains_i?: Maybe<Scalars['String']>;
  vatPercentOptions_starts_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_starts_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_ends_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_not_ends_with_i?: Maybe<Scalars['String']>;
  vatPercentOptions_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  vatPercentOptions_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type AcquiringIntegrationWhereUniqueInput = {
  id: Scalars['ID'];
};

export type AcquiringIntegrationsCreateInput = {
  data?: Maybe<AcquiringIntegrationCreateInput>;
};

export type AcquiringIntegrationsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationUpdateInput>;
};

export type AddressMetaDataField = {
  __typename?: 'AddressMetaDataField';
  postal_code?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  country_iso_code?: Maybe<Scalars['String']>;
  federal_district?: Maybe<Scalars['String']>;
  region_fias_id?: Maybe<Scalars['String']>;
  region_kladr_id?: Maybe<Scalars['String']>;
  region_iso_code?: Maybe<Scalars['String']>;
  region_with_type?: Maybe<Scalars['String']>;
  region_type?: Maybe<Scalars['String']>;
  region_type_full?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  area_fias_id?: Maybe<Scalars['String']>;
  area_kladr_id?: Maybe<Scalars['String']>;
  area_with_type?: Maybe<Scalars['String']>;
  area_type?: Maybe<Scalars['String']>;
  area_type_full?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  city_fias_id?: Maybe<Scalars['String']>;
  city_kladr_id?: Maybe<Scalars['String']>;
  city_with_type?: Maybe<Scalars['String']>;
  city_type?: Maybe<Scalars['String']>;
  city_type_full?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_area?: Maybe<Scalars['String']>;
  city_district_fias_id?: Maybe<Scalars['String']>;
  city_district_kladr_id?: Maybe<Scalars['String']>;
  city_district_with_type?: Maybe<Scalars['String']>;
  city_district_type?: Maybe<Scalars['String']>;
  city_district_type_full?: Maybe<Scalars['String']>;
  city_district?: Maybe<Scalars['String']>;
  settlement_fias_id?: Maybe<Scalars['String']>;
  settlement_kladr_id?: Maybe<Scalars['String']>;
  settlement_with_type?: Maybe<Scalars['String']>;
  settlement_type?: Maybe<Scalars['String']>;
  settlement_type_full?: Maybe<Scalars['String']>;
  settlement?: Maybe<Scalars['String']>;
  street_fias_id?: Maybe<Scalars['String']>;
  street_kladr_id?: Maybe<Scalars['String']>;
  street_with_type?: Maybe<Scalars['String']>;
  street_type?: Maybe<Scalars['String']>;
  street_type_full?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house_fias_id?: Maybe<Scalars['String']>;
  house_kladr_id?: Maybe<Scalars['String']>;
  house_type?: Maybe<Scalars['String']>;
  house_type_full?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  block_type?: Maybe<Scalars['String']>;
  block_type_full?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  flat_fias_id?: Maybe<Scalars['String']>;
  flat_type?: Maybe<Scalars['String']>;
  flat_type_full?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  flat_area?: Maybe<Scalars['String']>;
  square_meter_price?: Maybe<Scalars['String']>;
  flat_price?: Maybe<Scalars['String']>;
  postal_box?: Maybe<Scalars['String']>;
  fias_id?: Maybe<Scalars['String']>;
  fias_code?: Maybe<Scalars['String']>;
  fias_level?: Maybe<Scalars['String']>;
  fias_actuality_state?: Maybe<Scalars['String']>;
  kladr_id?: Maybe<Scalars['String']>;
  geoname_id?: Maybe<Scalars['String']>;
  capital_marker?: Maybe<Scalars['String']>;
  okato?: Maybe<Scalars['String']>;
  oktmo?: Maybe<Scalars['String']>;
  tax_office?: Maybe<Scalars['String']>;
  tax_office_legal?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['String']>;
  geo_lon?: Maybe<Scalars['String']>;
  beltway_hit?: Maybe<Scalars['String']>;
  beltway_distance?: Maybe<Scalars['String']>;
  metro?: Maybe<Array<Maybe<AddressMetaDataMetroField>>>;
  qc_geo?: Maybe<Scalars['String']>;
  qc_complete?: Maybe<Scalars['String']>;
  qc_house?: Maybe<Scalars['String']>;
  history_values?: Maybe<Array<Maybe<Scalars['String']>>>;
  unparsed_parts?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  qc?: Maybe<Scalars['String']>;
};

export type AddressMetaDataFieldInput = {
  postal_code?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  country_iso_code?: Maybe<Scalars['String']>;
  federal_district?: Maybe<Scalars['String']>;
  region_fias_id?: Maybe<Scalars['String']>;
  region_kladr_id?: Maybe<Scalars['String']>;
  region_iso_code?: Maybe<Scalars['String']>;
  region_with_type?: Maybe<Scalars['String']>;
  region_type?: Maybe<Scalars['String']>;
  region_type_full?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  area_fias_id?: Maybe<Scalars['String']>;
  area_kladr_id?: Maybe<Scalars['String']>;
  area_with_type?: Maybe<Scalars['String']>;
  area_type?: Maybe<Scalars['String']>;
  area_type_full?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  city_fias_id?: Maybe<Scalars['String']>;
  city_kladr_id?: Maybe<Scalars['String']>;
  city_with_type?: Maybe<Scalars['String']>;
  city_type?: Maybe<Scalars['String']>;
  city_type_full?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  city_area?: Maybe<Scalars['String']>;
  city_district_fias_id?: Maybe<Scalars['String']>;
  city_district_kladr_id?: Maybe<Scalars['String']>;
  city_district_with_type?: Maybe<Scalars['String']>;
  city_district_type?: Maybe<Scalars['String']>;
  city_district_type_full?: Maybe<Scalars['String']>;
  city_district?: Maybe<Scalars['String']>;
  settlement_fias_id?: Maybe<Scalars['String']>;
  settlement_kladr_id?: Maybe<Scalars['String']>;
  settlement_with_type?: Maybe<Scalars['String']>;
  settlement_type?: Maybe<Scalars['String']>;
  settlement_type_full?: Maybe<Scalars['String']>;
  settlement?: Maybe<Scalars['String']>;
  street_fias_id?: Maybe<Scalars['String']>;
  street_kladr_id?: Maybe<Scalars['String']>;
  street_with_type?: Maybe<Scalars['String']>;
  street_type?: Maybe<Scalars['String']>;
  street_type_full?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  house_fias_id?: Maybe<Scalars['String']>;
  house_kladr_id?: Maybe<Scalars['String']>;
  house_type?: Maybe<Scalars['String']>;
  house_type_full?: Maybe<Scalars['String']>;
  house?: Maybe<Scalars['String']>;
  block_type?: Maybe<Scalars['String']>;
  block_type_full?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  entrance?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['String']>;
  flat_fias_id?: Maybe<Scalars['String']>;
  flat_type?: Maybe<Scalars['String']>;
  flat_type_full?: Maybe<Scalars['String']>;
  flat?: Maybe<Scalars['String']>;
  flat_area?: Maybe<Scalars['String']>;
  square_meter_price?: Maybe<Scalars['String']>;
  flat_price?: Maybe<Scalars['String']>;
  postal_box?: Maybe<Scalars['String']>;
  fias_id?: Maybe<Scalars['String']>;
  fias_code?: Maybe<Scalars['String']>;
  fias_level?: Maybe<Scalars['String']>;
  fias_actuality_state?: Maybe<Scalars['String']>;
  kladr_id?: Maybe<Scalars['String']>;
  geoname_id?: Maybe<Scalars['String']>;
  capital_marker?: Maybe<Scalars['String']>;
  okato?: Maybe<Scalars['String']>;
  oktmo?: Maybe<Scalars['String']>;
  tax_office?: Maybe<Scalars['String']>;
  tax_office_legal?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['String']>;
  geo_lon?: Maybe<Scalars['String']>;
  beltway_hit?: Maybe<Scalars['String']>;
  beltway_distance?: Maybe<Scalars['String']>;
  metro?: Maybe<Array<Maybe<AddressMetaDataMetroFieldInput>>>;
  qc_geo?: Maybe<Scalars['String']>;
  qc_complete?: Maybe<Scalars['String']>;
  qc_house?: Maybe<Scalars['String']>;
  history_values?: Maybe<Array<Maybe<Scalars['String']>>>;
  unparsed_parts?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  qc?: Maybe<Scalars['String']>;
};

export type AddressMetaDataMetroField = {
  __typename?: 'AddressMetaDataMetroField';
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['String']>;
};

export type AddressMetaDataMetroFieldInput = {
  name?: Maybe<Scalars['String']>;
  line?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['String']>;
};

export type AddressMetaField = {
  __typename?: 'AddressMetaField';
  dv?: Maybe<Scalars['Int']>;
  value: Scalars['String'];
  unrestricted_value: Scalars['String'];
  data: AddressMetaDataField;
};

export type AddressMetaFieldInput = {
  dv: Scalars['Int'];
  value: Scalars['String'];
  unrestricted_value: Scalars['String'];
  data: AddressMetaDataFieldInput;
};

export type AllMiniAppsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
  where?: Maybe<AllMiniAppsWhereInput>;
  sortBy?: Maybe<SortAllMiniAppsBy>;
};

export type AllMiniAppsWhereInput = {
  app?: Maybe<B2BAppWhereInput>;
  connected?: Maybe<Scalars['Boolean']>;
  accessible?: Maybe<Scalars['Boolean']>;
};

export enum AppCategory {
  Dispatching = 'DISPATCHING',
  Gis = 'GIS',
  SmartHome = 'SMART_HOME',
  BusinessDevelopment = 'BUSINESS_DEVELOPMENT',
  Finance = 'FINANCE',
  Other = 'OTHER'
}

export type AppColorSchemaField = {
  __typename?: 'AppColorSchemaField';
  main: Scalars['String'];
  secondary: Scalars['String'];
};

export type AppColorSchemaFieldInput = {
  main: Scalars['String'];
  secondary: Scalars['String'];
};

export type AuthenticateUserWithPhoneAndPasswordInput = {
  phone: Scalars['String'];
  password: Scalars['String'];
};

export type AuthenticateUserWithPhoneAndPasswordOutput = {
  __typename?: 'AuthenticateUserWithPhoneAndPasswordOutput';
  item?: Maybe<User>;
  token: Scalars['String'];
};

/**  B2B app  */
export type B2BApp = {
  __typename?: 'B2BApp';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BApp List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BApp List config, or
   *  3. As an alias to a 'name' field on the B2BApp List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BApp List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of B2B App  */
  name?: Maybe<Scalars['String']>;
  /**  Logo of app's company or app itself  */
  logo?: Maybe<File>;
  /**  Short description / catch phrase providing information about integration functionality. Will be shown on App's card  */
  shortDescription?: Maybe<Scalars['String']>;
  /**  Developer company name  */
  developer?: Maybe<Scalars['String']>;
  /**  Link to the website of the developer company, where the user can find out detailed information about the partner  */
  partnerUrl?: Maybe<Scalars['String']>;
  /**  Text describing app functionality, connection process and pricing in full detail. Written in markdown without html tags. Will be shown on app's page  */
  detailedDescription?: Maybe<Scalars['String']>;
  /**  Url to app page, which is the app starting point and will be opened in iframe  */
  appUrl?: Maybe<Scalars['String']>;
  /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
  isHidden?: Maybe<Scalars['Boolean']>;
  /**  Indicates whether the app is global or not. If so, then the application will be opened in hidden mode and receive various notifications from the condo. It's also possible to trigger some condo IFrame methods via global app outside of miniapps CRM section  */
  isGlobal?: Maybe<Scalars['Boolean']>;
  /**  Indicates visibility of the app on the "services" page. If so, app will be shown. Otherwise it will be hidden until it's context become to "connected" status  */
  isPublic?: Maybe<Scalars['Boolean']>;
  /**  Indicates whether the miniapp has its own dynamic title. If so, the miniapp page will have no default title, shifting the responsibility for displaying it to the app itself. Otherwise, there will be a static title above the app iframe that corresponds to the application name.  */
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  /**  App icon. The presence of this field means that this app will be pinned to the CRM menu after the connection.  */
  icon?: Maybe<B2BAppIconType>;
  /**  This field is responsible for which category of the CRM menu the application icon will fall into when connected. If not specified - then connected with the icon will be displayed in category "MINIAPPS" by default.  */
  menuCategory?: Maybe<B2BAppMenuCategoryType>;
  /**  Status, which context will have by default after creation if no overwriting option provided  */
  contextDefaultStatus?: Maybe<B2BAppContextDefaultStatusType>;
  /**  Category of app. Can be one of the following: ["DISPATCHING", "GIS", "SMART_HOME", "BUSINESS_DEVELOPMENT", "FINANCE", "OTHER"] By default set to "OTHER"  */
  category?: Maybe<B2BAppCategoryType>;
  /**  Specifies set of service users, who can access app's contexts related as well as perform actions on behalf of the application  */
  accessRights: Array<B2BAppAccessRight>;
  _accessRightsMeta?: Maybe<_QueryMeta>;
  /**  List of features that this global mini-app implements. Can be one or more of the following: [PropertyMapGeneration, AttachCallRecordToTicket]  */
  features?: Maybe<Array<B2BAppGlobalFeature>>;
  /**  The number used to determine the position of the app among the others. App with higher priority appear earlier in "All" category, as well as in it's own category. Apps with the same priority are sorted from newest to oldest. The default value is 1.  */
  displayPriority?: Maybe<Scalars['Int']>;
  /**  App can be marked with one of the following labels in order to visually stand out from other applications: [FREE, DISCOUNT, POPULAR, NEW]  */
  label?: Maybe<B2BAppLabelType>;
  /**  Array containing links to promotional images, which will be shown to user on app's page  */
  gallery?: Maybe<Array<Scalars['String']>>;
  /**  String representing a base price of app. Usually it's something like "Free", "Individual", "$50 / year"  */
  price?: Maybe<Scalars['String']>;
  /**  Configuration for News Sharing feature. If specified, then this B2BApp is embeddable to the /news page and supports News Sharing API  */
  newsSharingConfig?: Maybe<B2BAppNewsSharingConfig>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  B2B app  */
export type B2BAppAccessRightsArgs = {
  where?: Maybe<B2BAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  B2B app  */
export type B2BApp_AccessRightsMetaArgs = {
  where?: Maybe<B2BAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/**  Link between service user and B2B App. The existence of this connection means that this user has the rights to perform actions on behalf of the integration  */
export type B2BAppAccessRight = {
  __typename?: 'B2BAppAccessRight';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRight List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRight List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to user. Note, that user must be of "service" type  */
  user?: Maybe<User>;
  /**  Link to B2BApp.accessRights  */
  app?: Maybe<B2BApp>;
  /**
   *  Link to the set of access rights. This set of access right will be used to check your service user access to schemas that are linked to "Organization" schema (such as "Organization", "Ticket" and others).
   * These accesses will only apply to entities that belong to organizations that connected your app
   */
  accessRightSet?: Maybe<B2BAppAccessRightSet>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppAccessRightCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  app?: Maybe<B2BAppRelateToOneInput>;
  accessRightSet?: Maybe<B2BAppAccessRightSetRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2BAppAccessRightHistoryRecord = {
  __typename?: 'B2BAppAccessRightHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRightHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  accessRightSet?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppAccessRightHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  accessRightSet?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppAccessRightHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppAccessRightHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  accessRightSet?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppAccessRightHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accessRightSet?: Maybe<Scalars['String']>;
  accessRightSet_not?: Maybe<Scalars['String']>;
  accessRightSet_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accessRightSet_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppAccessRightHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppAccessRightHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppAccessRightHistoryRecordCreateInput>;
};

export type B2BAppAccessRightHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightHistoryRecordUpdateInput>;
};

export type B2BAppAccessRightRelateToManyInput = {
  create?: Maybe<Array<Maybe<B2BAppAccessRightCreateInput>>>;
  connect?: Maybe<Array<Maybe<B2BAppAccessRightWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<B2BAppAccessRightWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**
 *  A set of access rights for a service user integrated with your B2BApp (exist B2BAppAccessRight with links to your service user and your B2BApp).
 * This set of access right will be used to check your service user access to schemas that are linked to "Organization" schema (such as "Organization", "Ticket" and others).
 * These accesses will only apply to entities that belong to organizations that connected your app.
 * This schema contains fields corresponding to "can<Action><Scheme name in plural>" format (e.g. "canReadOrganizations", "canManageContacts" and etc.)
 * NOTE: Some schemas have links with other models. Therefore, it is necessary to take this into account when specifying the necessary rights.
 * For example, to create a contact, in addition to having access to managing properties, you also need to have access to read organization
 */
export type B2BAppAccessRightSet = {
  __typename?: 'B2BAppAccessRightSet';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRightSet List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRightSet List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRightSet List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRightSet List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to B2BApp  */
  app?: Maybe<B2BApp>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  /**  Currently, this field is read-only. You cannot get manage access for the specified schema.  */
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppAccessRightSetCreateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2BAppAccessRightSetHistoryRecord = {
  __typename?: 'B2BAppAccessRightSetHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppAccessRightSetHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppAccessRightSetHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppAccessRightSetHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppAccessRightSetHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppAccessRightSetHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppAccessRightSetHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppAccessRightSetHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppAccessRightSetHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canReadContacts_not?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts_not?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadMeters_not?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters_not?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings_not?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings_not?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canReadOrganizations_not?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations_not?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canReadProperties_not?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties_not?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canReadTickets_not?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets_not?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketComments_not?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments_not?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles_not?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles_not?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles_not?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles_not?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppAccessRightSetHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppAccessRightSetHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppAccessRightSetHistoryRecordCreateInput>;
};

export type B2BAppAccessRightSetHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightSetHistoryRecordUpdateInput>;
};

export type B2BAppAccessRightSetRelateToOneInput = {
  create?: Maybe<B2BAppAccessRightSetCreateInput>;
  connect?: Maybe<B2BAppAccessRightSetWhereUniqueInput>;
  disconnect?: Maybe<B2BAppAccessRightSetWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type B2BAppAccessRightSetUpdateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppAccessRightSetWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppAccessRightSetWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppAccessRightSetWhereInput>>>;
  app?: Maybe<B2BAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canReadContacts_not?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts_not?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadMeters_not?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters_not?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadMeterReadings_not?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings_not?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canReadOrganizations_not?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations_not?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canReadProperties_not?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties_not?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canReadTickets_not?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets_not?: Maybe<Scalars['Boolean']>;
  canReadTicketComments?: Maybe<Scalars['Boolean']>;
  canReadTicketComments_not?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments_not?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketFiles_not?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketFiles_not?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canReadTicketCommentFiles_not?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles?: Maybe<Scalars['Boolean']>;
  canManageTicketCommentFiles_not?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterMetersReadings_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppAccessRightSetWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppAccessRightSetsCreateInput = {
  data?: Maybe<B2BAppAccessRightSetCreateInput>;
};

export type B2BAppAccessRightSetsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightSetUpdateInput>;
};

export type B2BAppAccessRightUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  app?: Maybe<B2BAppRelateToOneInput>;
  accessRightSet?: Maybe<B2BAppAccessRightSetRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppAccessRightWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppAccessRightWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppAccessRightWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  app?: Maybe<B2BAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  accessRightSet?: Maybe<B2BAppAccessRightSetWhereInput>;
  accessRightSet_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppAccessRightWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppAccessRightsCreateInput = {
  data?: Maybe<B2BAppAccessRightCreateInput>;
};

export type B2BAppAccessRightsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightUpdateInput>;
};

export enum B2BAppCategoryType {
  Dispatching = 'DISPATCHING',
  Gis = 'GIS',
  SmartHome = 'SMART_HOME',
  BusinessDevelopment = 'BUSINESS_DEVELOPMENT',
  Finance = 'FINANCE',
  Other = 'OTHER'
}

/**  Object which connects B2B App and Organization. Used to determine if app is connected or not, and store settings / state of app for specific organization  */
export type B2BAppContext = {
  __typename?: 'B2BAppContext';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppContext List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppContext List config, or
   *  3. As an alias to a 'name' field on the B2BAppContext List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppContext List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  B2B App  */
  app?: Maybe<B2BApp>;
  /**  Organization  */
  organization?: Maybe<Organization>;
  /**  Data that is required for specified app to work with specified organization. Filled by app's service account / support and can have any JSON structure  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Status of B2BApp connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related B2BApp model  */
  status?: Maybe<B2BAppContextStatusType>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppContextCreateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<B2BAppContextStatusType>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum B2BAppContextDefaultStatusType {
  InProgress = 'InProgress',
  Error = 'Error',
  Finished = 'Finished'
}

/**  A keystone list  */
export type B2BAppContextHistoryRecord = {
  __typename?: 'B2BAppContextHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppContextHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppContextHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppContextHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppContextHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppContextHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppContextHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppContextHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppContextHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppContextHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppContextHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppContextHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppContextHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppContextHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppContextHistoryRecordCreateInput>;
};

export type B2BAppContextHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppContextHistoryRecordUpdateInput>;
};

export type B2BAppContextRelateToOneInput = {
  create?: Maybe<B2BAppContextCreateInput>;
  connect?: Maybe<B2BAppContextWhereUniqueInput>;
  disconnect?: Maybe<B2BAppContextWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum B2BAppContextStatusType {
  InProgress = 'InProgress',
  Error = 'Error',
  Finished = 'Finished'
}

export type B2BAppContextUpdateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<B2BAppContextStatusType>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppContextWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppContextWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppContextWhereInput>>>;
  app?: Maybe<B2BAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<B2BAppContextStatusType>;
  status_not?: Maybe<B2BAppContextStatusType>;
  status_in?: Maybe<Array<Maybe<B2BAppContextStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<B2BAppContextStatusType>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppContextWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppContextsCreateInput = {
  data?: Maybe<B2BAppContextCreateInput>;
};

export type B2BAppContextsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppContextUpdateInput>;
};

export type B2BAppCreateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  partnerUrl?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<B2BAppIconType>;
  menuCategory?: Maybe<B2BAppMenuCategoryType>;
  contextDefaultStatus?: Maybe<B2BAppContextDefaultStatusType>;
  category?: Maybe<B2BAppCategoryType>;
  accessRights?: Maybe<B2BAppAccessRightRelateToManyInput>;
  features?: Maybe<Array<B2BAppGlobalFeature>>;
  displayPriority?: Maybe<Scalars['Int']>;
  label?: Maybe<B2BAppLabelType>;
  gallery?: Maybe<Array<Scalars['String']>>;
  price?: Maybe<Scalars['String']>;
  newsSharingConfig?: Maybe<B2BAppNewsSharingConfigRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum B2BAppGlobalFeature {
  PropertyMapGeneration = 'PropertyMapGeneration',
  AttachCallRecordToTicket = 'AttachCallRecordToTicket'
}

/**  A keystone list  */
export type B2BAppHistoryRecord = {
  __typename?: 'B2BAppHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  partnerUrl?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  appUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['JSON']>;
  displayPriority?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  gallery?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['String']>;
  newsSharingConfig?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  partnerUrl?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  appUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['JSON']>;
  displayPriority?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  gallery?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['String']>;
  newsSharingConfig?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  partnerUrl?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  appUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['JSON']>;
  displayPriority?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
  gallery?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['String']>;
  newsSharingConfig?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['JSON']>;
  logo_not?: Maybe<Scalars['JSON']>;
  logo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  logo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescription_not?: Maybe<Scalars['String']>;
  shortDescription_contains?: Maybe<Scalars['String']>;
  shortDescription_not_contains?: Maybe<Scalars['String']>;
  shortDescription_starts_with?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with?: Maybe<Scalars['String']>;
  shortDescription_ends_with?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with?: Maybe<Scalars['String']>;
  shortDescription_i?: Maybe<Scalars['String']>;
  shortDescription_not_i?: Maybe<Scalars['String']>;
  shortDescription_contains_i?: Maybe<Scalars['String']>;
  shortDescription_not_contains_i?: Maybe<Scalars['String']>;
  shortDescription_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer?: Maybe<Scalars['String']>;
  developer_not?: Maybe<Scalars['String']>;
  developer_contains?: Maybe<Scalars['String']>;
  developer_not_contains?: Maybe<Scalars['String']>;
  developer_starts_with?: Maybe<Scalars['String']>;
  developer_not_starts_with?: Maybe<Scalars['String']>;
  developer_ends_with?: Maybe<Scalars['String']>;
  developer_not_ends_with?: Maybe<Scalars['String']>;
  developer_i?: Maybe<Scalars['String']>;
  developer_not_i?: Maybe<Scalars['String']>;
  developer_contains_i?: Maybe<Scalars['String']>;
  developer_not_contains_i?: Maybe<Scalars['String']>;
  developer_starts_with_i?: Maybe<Scalars['String']>;
  developer_not_starts_with_i?: Maybe<Scalars['String']>;
  developer_ends_with_i?: Maybe<Scalars['String']>;
  developer_not_ends_with_i?: Maybe<Scalars['String']>;
  developer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnerUrl?: Maybe<Scalars['String']>;
  partnerUrl_not?: Maybe<Scalars['String']>;
  partnerUrl_contains?: Maybe<Scalars['String']>;
  partnerUrl_not_contains?: Maybe<Scalars['String']>;
  partnerUrl_starts_with?: Maybe<Scalars['String']>;
  partnerUrl_not_starts_with?: Maybe<Scalars['String']>;
  partnerUrl_ends_with?: Maybe<Scalars['String']>;
  partnerUrl_not_ends_with?: Maybe<Scalars['String']>;
  partnerUrl_i?: Maybe<Scalars['String']>;
  partnerUrl_not_i?: Maybe<Scalars['String']>;
  partnerUrl_contains_i?: Maybe<Scalars['String']>;
  partnerUrl_not_contains_i?: Maybe<Scalars['String']>;
  partnerUrl_starts_with_i?: Maybe<Scalars['String']>;
  partnerUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  partnerUrl_ends_with_i?: Maybe<Scalars['String']>;
  partnerUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  partnerUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnerUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  detailedDescription_not?: Maybe<Scalars['JSON']>;
  detailedDescription_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  detailedDescription_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  appUrl?: Maybe<Scalars['String']>;
  appUrl_not?: Maybe<Scalars['String']>;
  appUrl_contains?: Maybe<Scalars['String']>;
  appUrl_not_contains?: Maybe<Scalars['String']>;
  appUrl_starts_with?: Maybe<Scalars['String']>;
  appUrl_not_starts_with?: Maybe<Scalars['String']>;
  appUrl_ends_with?: Maybe<Scalars['String']>;
  appUrl_not_ends_with?: Maybe<Scalars['String']>;
  appUrl_i?: Maybe<Scalars['String']>;
  appUrl_not_i?: Maybe<Scalars['String']>;
  appUrl_contains_i?: Maybe<Scalars['String']>;
  appUrl_not_contains_i?: Maybe<Scalars['String']>;
  appUrl_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isGlobal_not?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_not?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle_not?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_contains?: Maybe<Scalars['String']>;
  icon_not_contains?: Maybe<Scalars['String']>;
  icon_starts_with?: Maybe<Scalars['String']>;
  icon_not_starts_with?: Maybe<Scalars['String']>;
  icon_ends_with?: Maybe<Scalars['String']>;
  icon_not_ends_with?: Maybe<Scalars['String']>;
  icon_i?: Maybe<Scalars['String']>;
  icon_not_i?: Maybe<Scalars['String']>;
  icon_contains_i?: Maybe<Scalars['String']>;
  icon_not_contains_i?: Maybe<Scalars['String']>;
  icon_starts_with_i?: Maybe<Scalars['String']>;
  icon_not_starts_with_i?: Maybe<Scalars['String']>;
  icon_ends_with_i?: Maybe<Scalars['String']>;
  icon_not_ends_with_i?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  menuCategory?: Maybe<Scalars['String']>;
  menuCategory_not?: Maybe<Scalars['String']>;
  menuCategory_contains?: Maybe<Scalars['String']>;
  menuCategory_not_contains?: Maybe<Scalars['String']>;
  menuCategory_starts_with?: Maybe<Scalars['String']>;
  menuCategory_not_starts_with?: Maybe<Scalars['String']>;
  menuCategory_ends_with?: Maybe<Scalars['String']>;
  menuCategory_not_ends_with?: Maybe<Scalars['String']>;
  menuCategory_i?: Maybe<Scalars['String']>;
  menuCategory_not_i?: Maybe<Scalars['String']>;
  menuCategory_contains_i?: Maybe<Scalars['String']>;
  menuCategory_not_contains_i?: Maybe<Scalars['String']>;
  menuCategory_starts_with_i?: Maybe<Scalars['String']>;
  menuCategory_not_starts_with_i?: Maybe<Scalars['String']>;
  menuCategory_ends_with_i?: Maybe<Scalars['String']>;
  menuCategory_not_ends_with_i?: Maybe<Scalars['String']>;
  menuCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  menuCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  contextDefaultStatus_not?: Maybe<Scalars['String']>;
  contextDefaultStatus_contains?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_contains?: Maybe<Scalars['String']>;
  contextDefaultStatus_starts_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_starts_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_ends_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_ends_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_contains_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_contains_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_starts_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_starts_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_ends_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_ends_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contextDefaultStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_contains?: Maybe<Scalars['String']>;
  category_not_contains?: Maybe<Scalars['String']>;
  category_starts_with?: Maybe<Scalars['String']>;
  category_not_starts_with?: Maybe<Scalars['String']>;
  category_ends_with?: Maybe<Scalars['String']>;
  category_not_ends_with?: Maybe<Scalars['String']>;
  category_i?: Maybe<Scalars['String']>;
  category_not_i?: Maybe<Scalars['String']>;
  category_contains_i?: Maybe<Scalars['String']>;
  category_not_contains_i?: Maybe<Scalars['String']>;
  category_starts_with_i?: Maybe<Scalars['String']>;
  category_not_starts_with_i?: Maybe<Scalars['String']>;
  category_ends_with_i?: Maybe<Scalars['String']>;
  category_not_ends_with_i?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  features?: Maybe<Scalars['JSON']>;
  features_not?: Maybe<Scalars['JSON']>;
  features_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  features_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  displayPriority?: Maybe<Scalars['Int']>;
  displayPriority_not?: Maybe<Scalars['Int']>;
  displayPriority_lt?: Maybe<Scalars['Int']>;
  displayPriority_lte?: Maybe<Scalars['Int']>;
  displayPriority_gt?: Maybe<Scalars['Int']>;
  displayPriority_gte?: Maybe<Scalars['Int']>;
  displayPriority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  displayPriority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  label?: Maybe<Scalars['String']>;
  label_not?: Maybe<Scalars['String']>;
  label_contains?: Maybe<Scalars['String']>;
  label_not_contains?: Maybe<Scalars['String']>;
  label_starts_with?: Maybe<Scalars['String']>;
  label_not_starts_with?: Maybe<Scalars['String']>;
  label_ends_with?: Maybe<Scalars['String']>;
  label_not_ends_with?: Maybe<Scalars['String']>;
  label_i?: Maybe<Scalars['String']>;
  label_not_i?: Maybe<Scalars['String']>;
  label_contains_i?: Maybe<Scalars['String']>;
  label_not_contains_i?: Maybe<Scalars['String']>;
  label_starts_with_i?: Maybe<Scalars['String']>;
  label_not_starts_with_i?: Maybe<Scalars['String']>;
  label_ends_with_i?: Maybe<Scalars['String']>;
  label_not_ends_with_i?: Maybe<Scalars['String']>;
  label_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  label_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  gallery?: Maybe<Scalars['JSON']>;
  gallery_not?: Maybe<Scalars['JSON']>;
  gallery_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  gallery_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  price?: Maybe<Scalars['String']>;
  price_not?: Maybe<Scalars['String']>;
  price_contains?: Maybe<Scalars['String']>;
  price_not_contains?: Maybe<Scalars['String']>;
  price_starts_with?: Maybe<Scalars['String']>;
  price_not_starts_with?: Maybe<Scalars['String']>;
  price_ends_with?: Maybe<Scalars['String']>;
  price_not_ends_with?: Maybe<Scalars['String']>;
  price_i?: Maybe<Scalars['String']>;
  price_not_i?: Maybe<Scalars['String']>;
  price_contains_i?: Maybe<Scalars['String']>;
  price_not_contains_i?: Maybe<Scalars['String']>;
  price_starts_with_i?: Maybe<Scalars['String']>;
  price_not_starts_with_i?: Maybe<Scalars['String']>;
  price_ends_with_i?: Maybe<Scalars['String']>;
  price_not_ends_with_i?: Maybe<Scalars['String']>;
  price_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  price_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsSharingConfig?: Maybe<Scalars['String']>;
  newsSharingConfig_not?: Maybe<Scalars['String']>;
  newsSharingConfig_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsSharingConfig_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppHistoryRecordCreateInput>;
};

export type B2BAppHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppHistoryRecordUpdateInput>;
};

export enum B2BAppIconType {
  AlertCircle = 'AlertCircle',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  BarChartHorizontal = 'BarChartHorizontal',
  BarChartVertical = 'BarChartVertical',
  Bill = 'Bill',
  Briefcase = 'Briefcase',
  Building = 'Building',
  CalendarDays = 'CalendarDays',
  Car = 'Car',
  Check = 'Check',
  CheckCircle = 'CheckCircle',
  CheckSquare = 'CheckSquare',
  ChevronDown = 'ChevronDown',
  ChevronLeft = 'ChevronLeft',
  ChevronRight = 'ChevronRight',
  ChevronUp = 'ChevronUp',
  CircleEllipsis = 'CircleEllipsis',
  Clock = 'Clock',
  Close = 'Close',
  Contacts = 'Contacts',
  Copy = 'Copy',
  CreditCard = 'CreditCard',
  DoorOpen = 'DoorOpen',
  Doors = 'Doors',
  Download = 'Download',
  Edit = 'Edit',
  Employee = 'Employee',
  ExternalLink = 'ExternalLink',
  Eye = 'Eye',
  EyeOff = 'EyeOff',
  FileCheck = 'FileCheck',
  FileDown = 'FileDown',
  FileEdit = 'FileEdit',
  FileText = 'FileText',
  FileUp = 'FileUp',
  Filter = 'Filter',
  Flat = 'Flat',
  Floor = 'Floor',
  Frown = 'Frown',
  Globe = 'Globe',
  Guide = 'Guide',
  History = 'History',
  Home = 'Home',
  House = 'House',
  Inbox = 'Inbox',
  Info = 'Info',
  Interfloor = 'Interfloor',
  Key = 'Key',
  Layers = 'Layers',
  LayoutList = 'LayoutList',
  Link = 'Link',
  List = 'List',
  Lock = 'Lock',
  LogOut = 'LogOut',
  Mail = 'Mail',
  Market = 'Market',
  Menu = 'Menu',
  Meters = 'Meters',
  MinusCircle = 'MinusCircle',
  MoreHorizontal = 'MoreHorizontal',
  MoreVertical = 'MoreVertical',
  NewAppeal = 'NewAppeal',
  Newspaper = 'Newspaper',
  OnOff = 'OnOff',
  Paperclip = 'Paperclip',
  Parking = 'Parking',
  Pause = 'Pause',
  PauseFilled = 'PauseFilled',
  Phone = 'Phone',
  PhoneIncoming = 'PhoneIncoming',
  PhoneOutgoing = 'PhoneOutgoing',
  PieChart = 'PieChart',
  Play = 'Play',
  PlayFilled = 'PlayFilled',
  Plus = 'Plus',
  PlusCircle = 'PlusCircle',
  Print = 'Print',
  QuestionCircle = 'QuestionCircle',
  Readings = 'Readings',
  RefreshCw = 'RefreshCw',
  Rocket = 'Rocket',
  Ruble = 'Ruble',
  Sber = 'Sber',
  Search = 'Search',
  Send = 'Send',
  Services = 'Services',
  Settings = 'Settings',
  Share = 'Share',
  Sheet = 'Sheet',
  Slash = 'Slash',
  SmartHome = 'SmartHome',
  Smartphone = 'Smartphone',
  Smile = 'Smile',
  SortAsc = 'SortAsc',
  SortDesc = 'SortDesc',
  Star = 'Star',
  StarFilled = 'StarFilled',
  Subtitles = 'Subtitles',
  Trash = 'Trash',
  Unlock = 'Unlock',
  User = 'User',
  UserSquare = 'UserSquare',
  Wallet = 'Wallet',
  XCircle = 'XCircle'
}

export enum B2BAppLabelType {
  Free = 'FREE',
  Discount = 'DISCOUNT',
  Popular = 'POPULAR',
  New = 'NEW'
}

export enum B2BAppMenuCategoryType {
  Tour = 'TOUR',
  Dashboard = 'DASHBOARD',
  Communication = 'COMMUNICATION',
  Properties = 'PROPERTIES',
  Residents = 'RESIDENTS',
  Employees = 'EMPLOYEES',
  Market = 'MARKET',
  Billing = 'BILLING',
  Meters = 'METERS',
  Miniapps = 'MINIAPPS',
  Settings = 'SETTINGS'
}

/**  News Sharing B2BApp allow b2b users to share their NewsItem to external source (like Telegram) from /news page  */
export type B2BAppNewsSharingConfig = {
  __typename?: 'B2BAppNewsSharingConfig';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppNewsSharingConfig List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppNewsSharingConfig List config, or
   *  3. As an alias to a 'name' field on the B2BAppNewsSharingConfig List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppNewsSharingConfig List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Short and simple name of the external source. For example: Telegram  */
  name?: Maybe<Scalars['String']>;
  /**  App icon. For example: Telegram app icon  */
  icon?: Maybe<File>;
  /**  Preview image. For example: Telegram app screenshot  */
  previewPicture?: Maybe<File>;
  /**  Push notification preferences for each B2BApp. The available options include: notifications are sent only for emergency alerts, all notifications are turned off, or all notifications are enabled  */
  pushNotificationSettings?: Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>;
  /**  URL that publishes NewsItem. Should implement POST publish method. It will be called once news item is ready to be published. Check News domain for reference  */
  publishUrl?: Maybe<Scalars['String']>;
  /**  URL that returns rendered HTML preview of News Item. Used to render NewsItem preview. If not provided, app preview will not be rendered  */
  previewUrl?: Maybe<Scalars['String']>;
  /**  URL that returns chats and/or channels. Should implement POST getRecipients method. If provided Select control with data from this endpoint will be used in /news/create page, If not provided, condo control will be used  */
  getRecipientsUrl?: Maybe<Scalars['String']>;
  /**  URL that implements customForm. Use only if you need custom NewsItemSharing data structure, for example if . Allows to provide custom UI for sending news. If not provided app will use condo news form  */
  customFormUrl?: Maybe<Scalars['String']>;
  /**  URL that returns number of subscribers for condo scopes. Should implement POST customGetRecipientsCounters method. Allows to provide custom values for recipients counter. If not provided app will use data from getRecipients. If getRecipients is not provided, recipients counter will not be rendered  */
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppNewsSharingConfigCreateInput = {
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  previewPicture?: Maybe<Scalars['Upload']>;
  pushNotificationSettings?: Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>;
  publishUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  customFormUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2BAppNewsSharingConfigHistoryRecord = {
  __typename?: 'B2BAppNewsSharingConfigHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppNewsSharingConfigHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppNewsSharingConfigHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppNewsSharingConfigHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppNewsSharingConfigHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['JSON']>;
  previewPicture?: Maybe<Scalars['JSON']>;
  pushNotificationSettings?: Maybe<Scalars['String']>;
  publishUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  customFormUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppNewsSharingConfigHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['JSON']>;
  previewPicture?: Maybe<Scalars['JSON']>;
  pushNotificationSettings?: Maybe<Scalars['String']>;
  publishUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  customFormUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppNewsSharingConfigHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppNewsSharingConfigHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['JSON']>;
  previewPicture?: Maybe<Scalars['JSON']>;
  pushNotificationSettings?: Maybe<Scalars['String']>;
  publishUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  customFormUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppNewsSharingConfigHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon?: Maybe<Scalars['JSON']>;
  icon_not?: Maybe<Scalars['JSON']>;
  icon_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  icon_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  previewPicture?: Maybe<Scalars['JSON']>;
  previewPicture_not?: Maybe<Scalars['JSON']>;
  previewPicture_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  previewPicture_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  pushNotificationSettings?: Maybe<Scalars['String']>;
  pushNotificationSettings_not?: Maybe<Scalars['String']>;
  pushNotificationSettings_contains?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_contains?: Maybe<Scalars['String']>;
  pushNotificationSettings_starts_with?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_starts_with?: Maybe<Scalars['String']>;
  pushNotificationSettings_ends_with?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_ends_with?: Maybe<Scalars['String']>;
  pushNotificationSettings_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_contains_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_contains_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_starts_with_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_starts_with_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_ends_with_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_not_ends_with_i?: Maybe<Scalars['String']>;
  pushNotificationSettings_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushNotificationSettings_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishUrl?: Maybe<Scalars['String']>;
  publishUrl_not?: Maybe<Scalars['String']>;
  publishUrl_contains?: Maybe<Scalars['String']>;
  publishUrl_not_contains?: Maybe<Scalars['String']>;
  publishUrl_starts_with?: Maybe<Scalars['String']>;
  publishUrl_not_starts_with?: Maybe<Scalars['String']>;
  publishUrl_ends_with?: Maybe<Scalars['String']>;
  publishUrl_not_ends_with?: Maybe<Scalars['String']>;
  publishUrl_i?: Maybe<Scalars['String']>;
  publishUrl_not_i?: Maybe<Scalars['String']>;
  publishUrl_contains_i?: Maybe<Scalars['String']>;
  publishUrl_not_contains_i?: Maybe<Scalars['String']>;
  publishUrl_starts_with_i?: Maybe<Scalars['String']>;
  publishUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  publishUrl_ends_with_i?: Maybe<Scalars['String']>;
  publishUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  publishUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewUrl?: Maybe<Scalars['String']>;
  previewUrl_not?: Maybe<Scalars['String']>;
  previewUrl_contains?: Maybe<Scalars['String']>;
  previewUrl_not_contains?: Maybe<Scalars['String']>;
  previewUrl_starts_with?: Maybe<Scalars['String']>;
  previewUrl_not_starts_with?: Maybe<Scalars['String']>;
  previewUrl_ends_with?: Maybe<Scalars['String']>;
  previewUrl_not_ends_with?: Maybe<Scalars['String']>;
  previewUrl_i?: Maybe<Scalars['String']>;
  previewUrl_not_i?: Maybe<Scalars['String']>;
  previewUrl_contains_i?: Maybe<Scalars['String']>;
  previewUrl_not_contains_i?: Maybe<Scalars['String']>;
  previewUrl_starts_with_i?: Maybe<Scalars['String']>;
  previewUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  previewUrl_ends_with_i?: Maybe<Scalars['String']>;
  previewUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  previewUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl_not?: Maybe<Scalars['String']>;
  getRecipientsUrl_contains?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_contains?: Maybe<Scalars['String']>;
  getRecipientsUrl_starts_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_starts_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_ends_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_ends_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_contains_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_contains_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  customFormUrl?: Maybe<Scalars['String']>;
  customFormUrl_not?: Maybe<Scalars['String']>;
  customFormUrl_contains?: Maybe<Scalars['String']>;
  customFormUrl_not_contains?: Maybe<Scalars['String']>;
  customFormUrl_starts_with?: Maybe<Scalars['String']>;
  customFormUrl_not_starts_with?: Maybe<Scalars['String']>;
  customFormUrl_ends_with?: Maybe<Scalars['String']>;
  customFormUrl_not_ends_with?: Maybe<Scalars['String']>;
  customFormUrl_i?: Maybe<Scalars['String']>;
  customFormUrl_not_i?: Maybe<Scalars['String']>;
  customFormUrl_contains_i?: Maybe<Scalars['String']>;
  customFormUrl_not_contains_i?: Maybe<Scalars['String']>;
  customFormUrl_starts_with_i?: Maybe<Scalars['String']>;
  customFormUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  customFormUrl_ends_with_i?: Maybe<Scalars['String']>;
  customFormUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  customFormUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  customFormUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_contains?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_contains?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_starts_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_starts_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_ends_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_ends_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_contains_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_contains_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsCountersUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppNewsSharingConfigHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppNewsSharingConfigHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppNewsSharingConfigHistoryRecordCreateInput>;
};

export type B2BAppNewsSharingConfigHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppNewsSharingConfigHistoryRecordUpdateInput>;
};

export enum B2BAppNewsSharingConfigPushNotificationSettingType {
  OnlyEmergency = 'ONLY_EMERGENCY',
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export type B2BAppNewsSharingConfigRelateToOneInput = {
  create?: Maybe<B2BAppNewsSharingConfigCreateInput>;
  connect?: Maybe<B2BAppNewsSharingConfigWhereUniqueInput>;
  disconnect?: Maybe<B2BAppNewsSharingConfigWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type B2BAppNewsSharingConfigUpdateInput = {
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  previewPicture?: Maybe<Scalars['Upload']>;
  pushNotificationSettings?: Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>;
  publishUrl?: Maybe<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  customFormUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppNewsSharingConfigWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppNewsSharingConfigWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppNewsSharingConfigWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon?: Maybe<Scalars['String']>;
  icon_not?: Maybe<Scalars['String']>;
  icon_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  icon_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewPicture?: Maybe<Scalars['String']>;
  previewPicture_not?: Maybe<Scalars['String']>;
  previewPicture_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewPicture_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushNotificationSettings?: Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>;
  pushNotificationSettings_not?: Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>;
  pushNotificationSettings_in?: Maybe<Array<Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>>>;
  pushNotificationSettings_not_in?: Maybe<Array<Maybe<B2BAppNewsSharingConfigPushNotificationSettingType>>>;
  publishUrl?: Maybe<Scalars['String']>;
  publishUrl_not?: Maybe<Scalars['String']>;
  publishUrl_contains?: Maybe<Scalars['String']>;
  publishUrl_not_contains?: Maybe<Scalars['String']>;
  publishUrl_starts_with?: Maybe<Scalars['String']>;
  publishUrl_not_starts_with?: Maybe<Scalars['String']>;
  publishUrl_ends_with?: Maybe<Scalars['String']>;
  publishUrl_not_ends_with?: Maybe<Scalars['String']>;
  publishUrl_i?: Maybe<Scalars['String']>;
  publishUrl_not_i?: Maybe<Scalars['String']>;
  publishUrl_contains_i?: Maybe<Scalars['String']>;
  publishUrl_not_contains_i?: Maybe<Scalars['String']>;
  publishUrl_starts_with_i?: Maybe<Scalars['String']>;
  publishUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  publishUrl_ends_with_i?: Maybe<Scalars['String']>;
  publishUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  publishUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewUrl?: Maybe<Scalars['String']>;
  previewUrl_not?: Maybe<Scalars['String']>;
  previewUrl_contains?: Maybe<Scalars['String']>;
  previewUrl_not_contains?: Maybe<Scalars['String']>;
  previewUrl_starts_with?: Maybe<Scalars['String']>;
  previewUrl_not_starts_with?: Maybe<Scalars['String']>;
  previewUrl_ends_with?: Maybe<Scalars['String']>;
  previewUrl_not_ends_with?: Maybe<Scalars['String']>;
  previewUrl_i?: Maybe<Scalars['String']>;
  previewUrl_not_i?: Maybe<Scalars['String']>;
  previewUrl_contains_i?: Maybe<Scalars['String']>;
  previewUrl_not_contains_i?: Maybe<Scalars['String']>;
  previewUrl_starts_with_i?: Maybe<Scalars['String']>;
  previewUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  previewUrl_ends_with_i?: Maybe<Scalars['String']>;
  previewUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  previewUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  previewUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsUrl?: Maybe<Scalars['String']>;
  getRecipientsUrl_not?: Maybe<Scalars['String']>;
  getRecipientsUrl_contains?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_contains?: Maybe<Scalars['String']>;
  getRecipientsUrl_starts_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_starts_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_ends_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_ends_with?: Maybe<Scalars['String']>;
  getRecipientsUrl_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_contains_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_contains_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  customFormUrl?: Maybe<Scalars['String']>;
  customFormUrl_not?: Maybe<Scalars['String']>;
  customFormUrl_contains?: Maybe<Scalars['String']>;
  customFormUrl_not_contains?: Maybe<Scalars['String']>;
  customFormUrl_starts_with?: Maybe<Scalars['String']>;
  customFormUrl_not_starts_with?: Maybe<Scalars['String']>;
  customFormUrl_ends_with?: Maybe<Scalars['String']>;
  customFormUrl_not_ends_with?: Maybe<Scalars['String']>;
  customFormUrl_i?: Maybe<Scalars['String']>;
  customFormUrl_not_i?: Maybe<Scalars['String']>;
  customFormUrl_contains_i?: Maybe<Scalars['String']>;
  customFormUrl_not_contains_i?: Maybe<Scalars['String']>;
  customFormUrl_starts_with_i?: Maybe<Scalars['String']>;
  customFormUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  customFormUrl_ends_with_i?: Maybe<Scalars['String']>;
  customFormUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  customFormUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  customFormUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsCountersUrl?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_contains?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_contains?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_starts_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_starts_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_ends_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_ends_with?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_contains_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_contains_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  getRecipientsCountersUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  getRecipientsCountersUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppNewsSharingConfigWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppNewsSharingConfigsCreateInput = {
  data?: Maybe<B2BAppNewsSharingConfigCreateInput>;
};

export type B2BAppNewsSharingConfigsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppNewsSharingConfigUpdateInput>;
};

/**  B2BApp permissions that describe additional capabilities within the mini-application and allow the organization administration to manage accesses within the miniapp depending on the employee's role by toggling the B2BAppPermissions of a particular application for each role. By default, for all employees with "canManageB2BApps" in their role, all permissions will be set to True. For all other roles with access to the application the default value is False.  */
export type B2BAppPermission = {
  __typename?: 'B2BAppPermission';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPermission List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPermission List config, or
   *  3. As an alias to a 'name' field on the B2BAppPermission List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPermission List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to the application to which the permission belongs  */
  app?: Maybe<B2BApp>;
  /**  The permission key. Must be written in lowerCamelCase, start with the prefix "can", and answer the question, "What can a user with this permission do?". Examples: "canManagePasses", "canReadPrivateData", etc.  */
  key?: Maybe<Scalars['String']>;
  /**  Name of permission. Must be very brief (from 1 to 50 characters) and describe the capabilities that the employee will get with this permission. Must use nouns that indicate actions with objects or sections of the mini-application. Examples: "Passes management", "Access to settings", "Contacts viewing".  */
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppPermissionCreateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2BAppPermissionHistoryRecord = {
  __typename?: 'B2BAppPermissionHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPermissionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPermissionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppPermissionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPermissionHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppPermissionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppPermissionHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppPermissionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppPermissionHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppPermissionHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppPermissionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppPermissionHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  key?: Maybe<Scalars['String']>;
  key_not?: Maybe<Scalars['String']>;
  key_contains?: Maybe<Scalars['String']>;
  key_not_contains?: Maybe<Scalars['String']>;
  key_starts_with?: Maybe<Scalars['String']>;
  key_not_starts_with?: Maybe<Scalars['String']>;
  key_ends_with?: Maybe<Scalars['String']>;
  key_not_ends_with?: Maybe<Scalars['String']>;
  key_i?: Maybe<Scalars['String']>;
  key_not_i?: Maybe<Scalars['String']>;
  key_contains_i?: Maybe<Scalars['String']>;
  key_not_contains_i?: Maybe<Scalars['String']>;
  key_starts_with_i?: Maybe<Scalars['String']>;
  key_not_starts_with_i?: Maybe<Scalars['String']>;
  key_ends_with_i?: Maybe<Scalars['String']>;
  key_not_ends_with_i?: Maybe<Scalars['String']>;
  key_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppPermissionHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppPermissionHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppPermissionHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppPermissionHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppPermissionHistoryRecordCreateInput>;
};

export type B2BAppPermissionHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPermissionHistoryRecordUpdateInput>;
};

export type B2BAppPermissionUpdateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppPermissionWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppPermissionWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppPermissionWhereInput>>>;
  app?: Maybe<B2BAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  key_not?: Maybe<Scalars['String']>;
  key_contains?: Maybe<Scalars['String']>;
  key_not_contains?: Maybe<Scalars['String']>;
  key_starts_with?: Maybe<Scalars['String']>;
  key_not_starts_with?: Maybe<Scalars['String']>;
  key_ends_with?: Maybe<Scalars['String']>;
  key_not_ends_with?: Maybe<Scalars['String']>;
  key_i?: Maybe<Scalars['String']>;
  key_not_i?: Maybe<Scalars['String']>;
  key_contains_i?: Maybe<Scalars['String']>;
  key_not_contains_i?: Maybe<Scalars['String']>;
  key_starts_with_i?: Maybe<Scalars['String']>;
  key_not_starts_with_i?: Maybe<Scalars['String']>;
  key_ends_with_i?: Maybe<Scalars['String']>;
  key_not_ends_with_i?: Maybe<Scalars['String']>;
  key_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppPermissionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppPermissionsCreateInput = {
  data?: Maybe<B2BAppPermissionCreateInput>;
};

export type B2BAppPermissionsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPermissionUpdateInput>;
};

/**  Promotion banner which appears in "Miniapps" section of CRM. Used to promote B2BApps, discounts, collaborations and so on  */
export type B2BAppPromoBlock = {
  __typename?: 'B2BAppPromoBlock';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPromoBlock List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPromoBlock List config, or
   *  3. As an alias to a 'name' field on the B2BAppPromoBlock List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPromoBlock List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Title of promotion banner. Main catch phrase is placed here. Must contain no more than 27 characters per line (including spaces) and no more than 2 lines in total.  */
  title?: Maybe<Scalars['String']>;
  /**  Secondary text of promotion banner. Some additional info goes here. Must contain no more than 40 characters per line (including spaces) and no more than 2 lines in total.  */
  subtitle?: Maybe<Scalars['String']>;
  /**  Variant of texts inside block. Can be one of the following: [BLACK, WHITE]  */
  textVariant?: Maybe<B2BAppPromoBlockTextVariantType>;
  /**  Background color of promo block. Can be hex code or linear gradient.  */
  backgroundColor?: Maybe<Scalars['String']>;
  /**  PNG image which appears next to text on large devices  */
  backgroundImage?: Maybe<File>;
  /**  Link to the resource that this B2BAppPromoBlock promotes  */
  targetUrl?: Maybe<Scalars['String']>;
  /**  Determines whether the banner leads to an external resource or not. If external, interaction with block will lead to new tab. Otherwise user will stay in current tab  */
  external?: Maybe<Scalars['Boolean']>;
  /**  The number used to determine the position of the block among the others. Blocks with higher priority appear earlier. Blocks with the same priority are sorted from newest to oldest. The default value is 1.  */
  priority?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppPromoBlockCreateInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  textVariant?: Maybe<B2BAppPromoBlockTextVariantType>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  targetUrl?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2BAppPromoBlockHistoryRecord = {
  __typename?: 'B2BAppPromoBlockHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppPromoBlockHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppPromoBlockHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppPromoBlockHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppPromoBlockHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  textVariant?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['JSON']>;
  targetUrl?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppPromoBlockHistoryRecordCreateInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  textVariant?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['JSON']>;
  targetUrl?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppPromoBlockHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppPromoBlockHistoryRecordUpdateInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  textVariant?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['JSON']>;
  targetUrl?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppPromoBlockHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecordWhereInput>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_not?: Maybe<Scalars['String']>;
  subtitle_contains?: Maybe<Scalars['String']>;
  subtitle_not_contains?: Maybe<Scalars['String']>;
  subtitle_starts_with?: Maybe<Scalars['String']>;
  subtitle_not_starts_with?: Maybe<Scalars['String']>;
  subtitle_ends_with?: Maybe<Scalars['String']>;
  subtitle_not_ends_with?: Maybe<Scalars['String']>;
  subtitle_i?: Maybe<Scalars['String']>;
  subtitle_not_i?: Maybe<Scalars['String']>;
  subtitle_contains_i?: Maybe<Scalars['String']>;
  subtitle_not_contains_i?: Maybe<Scalars['String']>;
  subtitle_starts_with_i?: Maybe<Scalars['String']>;
  subtitle_not_starts_with_i?: Maybe<Scalars['String']>;
  subtitle_ends_with_i?: Maybe<Scalars['String']>;
  subtitle_not_ends_with_i?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textVariant?: Maybe<Scalars['String']>;
  textVariant_not?: Maybe<Scalars['String']>;
  textVariant_contains?: Maybe<Scalars['String']>;
  textVariant_not_contains?: Maybe<Scalars['String']>;
  textVariant_starts_with?: Maybe<Scalars['String']>;
  textVariant_not_starts_with?: Maybe<Scalars['String']>;
  textVariant_ends_with?: Maybe<Scalars['String']>;
  textVariant_not_ends_with?: Maybe<Scalars['String']>;
  textVariant_i?: Maybe<Scalars['String']>;
  textVariant_not_i?: Maybe<Scalars['String']>;
  textVariant_contains_i?: Maybe<Scalars['String']>;
  textVariant_not_contains_i?: Maybe<Scalars['String']>;
  textVariant_starts_with_i?: Maybe<Scalars['String']>;
  textVariant_not_starts_with_i?: Maybe<Scalars['String']>;
  textVariant_ends_with_i?: Maybe<Scalars['String']>;
  textVariant_not_ends_with_i?: Maybe<Scalars['String']>;
  textVariant_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textVariant_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColor_not?: Maybe<Scalars['String']>;
  backgroundColor_contains?: Maybe<Scalars['String']>;
  backgroundColor_not_contains?: Maybe<Scalars['String']>;
  backgroundColor_starts_with?: Maybe<Scalars['String']>;
  backgroundColor_not_starts_with?: Maybe<Scalars['String']>;
  backgroundColor_ends_with?: Maybe<Scalars['String']>;
  backgroundColor_not_ends_with?: Maybe<Scalars['String']>;
  backgroundColor_i?: Maybe<Scalars['String']>;
  backgroundColor_not_i?: Maybe<Scalars['String']>;
  backgroundColor_contains_i?: Maybe<Scalars['String']>;
  backgroundColor_not_contains_i?: Maybe<Scalars['String']>;
  backgroundColor_starts_with_i?: Maybe<Scalars['String']>;
  backgroundColor_not_starts_with_i?: Maybe<Scalars['String']>;
  backgroundColor_ends_with_i?: Maybe<Scalars['String']>;
  backgroundColor_not_ends_with_i?: Maybe<Scalars['String']>;
  backgroundColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  backgroundColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  backgroundImage?: Maybe<Scalars['JSON']>;
  backgroundImage_not?: Maybe<Scalars['JSON']>;
  backgroundImage_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  backgroundImage_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  targetUrl?: Maybe<Scalars['String']>;
  targetUrl_not?: Maybe<Scalars['String']>;
  targetUrl_contains?: Maybe<Scalars['String']>;
  targetUrl_not_contains?: Maybe<Scalars['String']>;
  targetUrl_starts_with?: Maybe<Scalars['String']>;
  targetUrl_not_starts_with?: Maybe<Scalars['String']>;
  targetUrl_ends_with?: Maybe<Scalars['String']>;
  targetUrl_not_ends_with?: Maybe<Scalars['String']>;
  targetUrl_i?: Maybe<Scalars['String']>;
  targetUrl_not_i?: Maybe<Scalars['String']>;
  targetUrl_contains_i?: Maybe<Scalars['String']>;
  targetUrl_not_contains_i?: Maybe<Scalars['String']>;
  targetUrl_starts_with_i?: Maybe<Scalars['String']>;
  targetUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  targetUrl_ends_with_i?: Maybe<Scalars['String']>;
  targetUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  targetUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  external?: Maybe<Scalars['Boolean']>;
  external_not?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppPromoBlockHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppPromoBlockHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppPromoBlockHistoryRecordCreateInput>;
};

export type B2BAppPromoBlockHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPromoBlockHistoryRecordUpdateInput>;
};

export enum B2BAppPromoBlockTextVariantType {
  Black = 'BLACK',
  White = 'WHITE'
}

export type B2BAppPromoBlockUpdateInput = {
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  textVariant?: Maybe<B2BAppPromoBlockTextVariantType>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  targetUrl?: Maybe<Scalars['String']>;
  external?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppPromoBlockWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppPromoBlockWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppPromoBlockWhereInput>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle?: Maybe<Scalars['String']>;
  subtitle_not?: Maybe<Scalars['String']>;
  subtitle_contains?: Maybe<Scalars['String']>;
  subtitle_not_contains?: Maybe<Scalars['String']>;
  subtitle_starts_with?: Maybe<Scalars['String']>;
  subtitle_not_starts_with?: Maybe<Scalars['String']>;
  subtitle_ends_with?: Maybe<Scalars['String']>;
  subtitle_not_ends_with?: Maybe<Scalars['String']>;
  subtitle_i?: Maybe<Scalars['String']>;
  subtitle_not_i?: Maybe<Scalars['String']>;
  subtitle_contains_i?: Maybe<Scalars['String']>;
  subtitle_not_contains_i?: Maybe<Scalars['String']>;
  subtitle_starts_with_i?: Maybe<Scalars['String']>;
  subtitle_not_starts_with_i?: Maybe<Scalars['String']>;
  subtitle_ends_with_i?: Maybe<Scalars['String']>;
  subtitle_not_ends_with_i?: Maybe<Scalars['String']>;
  subtitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textVariant?: Maybe<B2BAppPromoBlockTextVariantType>;
  textVariant_not?: Maybe<B2BAppPromoBlockTextVariantType>;
  textVariant_in?: Maybe<Array<Maybe<B2BAppPromoBlockTextVariantType>>>;
  textVariant_not_in?: Maybe<Array<Maybe<B2BAppPromoBlockTextVariantType>>>;
  backgroundColor?: Maybe<Scalars['String']>;
  backgroundColor_not?: Maybe<Scalars['String']>;
  backgroundColor_contains?: Maybe<Scalars['String']>;
  backgroundColor_not_contains?: Maybe<Scalars['String']>;
  backgroundColor_starts_with?: Maybe<Scalars['String']>;
  backgroundColor_not_starts_with?: Maybe<Scalars['String']>;
  backgroundColor_ends_with?: Maybe<Scalars['String']>;
  backgroundColor_not_ends_with?: Maybe<Scalars['String']>;
  backgroundColor_i?: Maybe<Scalars['String']>;
  backgroundColor_not_i?: Maybe<Scalars['String']>;
  backgroundColor_contains_i?: Maybe<Scalars['String']>;
  backgroundColor_not_contains_i?: Maybe<Scalars['String']>;
  backgroundColor_starts_with_i?: Maybe<Scalars['String']>;
  backgroundColor_not_starts_with_i?: Maybe<Scalars['String']>;
  backgroundColor_ends_with_i?: Maybe<Scalars['String']>;
  backgroundColor_not_ends_with_i?: Maybe<Scalars['String']>;
  backgroundColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  backgroundColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  backgroundImage?: Maybe<Scalars['String']>;
  backgroundImage_not?: Maybe<Scalars['String']>;
  backgroundImage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  backgroundImage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetUrl?: Maybe<Scalars['String']>;
  targetUrl_not?: Maybe<Scalars['String']>;
  targetUrl_contains?: Maybe<Scalars['String']>;
  targetUrl_not_contains?: Maybe<Scalars['String']>;
  targetUrl_starts_with?: Maybe<Scalars['String']>;
  targetUrl_not_starts_with?: Maybe<Scalars['String']>;
  targetUrl_ends_with?: Maybe<Scalars['String']>;
  targetUrl_not_ends_with?: Maybe<Scalars['String']>;
  targetUrl_i?: Maybe<Scalars['String']>;
  targetUrl_not_i?: Maybe<Scalars['String']>;
  targetUrl_contains_i?: Maybe<Scalars['String']>;
  targetUrl_not_contains_i?: Maybe<Scalars['String']>;
  targetUrl_starts_with_i?: Maybe<Scalars['String']>;
  targetUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  targetUrl_ends_with_i?: Maybe<Scalars['String']>;
  targetUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  targetUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  external?: Maybe<Scalars['Boolean']>;
  external_not?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  priority_not?: Maybe<Scalars['Int']>;
  priority_lt?: Maybe<Scalars['Int']>;
  priority_lte?: Maybe<Scalars['Int']>;
  priority_gt?: Maybe<Scalars['Int']>;
  priority_gte?: Maybe<Scalars['Int']>;
  priority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  priority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppPromoBlockWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppPromoBlocksCreateInput = {
  data?: Maybe<B2BAppPromoBlockCreateInput>;
};

export type B2BAppPromoBlocksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPromoBlockUpdateInput>;
};

export type B2BAppRelateToOneInput = {
  create?: Maybe<B2BAppCreateInput>;
  connect?: Maybe<B2BAppWhereUniqueInput>;
  disconnect?: Maybe<B2BAppWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  This model links the role of the organization and mini-apps, allowing mini-apps to extend the main role template. Having this mapping between role A and mini-app B means that all employees in the organization with role A can access mini-app B. In addition, a mini-app may want to differentiate access within itself. To do this, it can create B2BAppPermissions via service user, the keys of which will appear as properties in the "permissions" field on all B2BAppRole associated with the mini-app.  By default, all existing and new permissions will be set to "true" for all roles in the organization that have "canManageB2BApps" checked, false for other employees. When connecting the miniapp will be automatically created B2BAppRole for all roles that have the "canManageB2BApps" checkbox. B2BAppRole can be created and updated manually by employees with the "canManageRoles" permission for other roles. When deleting B2BAppPermission, its key is also removed from the permissions field of all corresponding B2BAppRole, and when adding it - it is added to all roles, and the value is set according to the rules described above.  */
export type B2BAppRole = {
  __typename?: 'B2BAppRole';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppRole List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppRole List config, or
   *  3. As an alias to a 'name' field on the B2BAppRole List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppRole List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to the application to which the role belongs  */
  app?: Maybe<B2BApp>;
  /**  Link to the role of the organization that B2BAppRole extends  */
  role?: Maybe<OrganizationEmployeeRole>;
  /**  A set of specific permissions within a mini-app for a specific role. Is a Json object where the key is the "key" field from B2BAppPermission and the value is Boolean. Example: "{"canManagePasses": true, "canReadConfig": false}"  */
  permissions?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2BAppRoleCreateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  role?: Maybe<OrganizationEmployeeRoleRelateToOneInput>;
  permissions?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2BAppRoleHistoryRecord = {
  __typename?: 'B2BAppRoleHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2BAppRoleHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2BAppRoleHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2BAppRoleHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2BAppRoleHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppRoleHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2BAppRoleHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2BAppRoleHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2BAppRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2BAppRoleHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppRoleHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppRoleHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  permissions?: Maybe<Scalars['JSON']>;
  permissions_not?: Maybe<Scalars['JSON']>;
  permissions_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  permissions_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2BAppRoleHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2BAppRoleHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2BAppRoleHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2BAppRoleHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2BAppRoleHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppRoleHistoryRecordsCreateInput = {
  data?: Maybe<B2BAppRoleHistoryRecordCreateInput>;
};

export type B2BAppRoleHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppRoleHistoryRecordUpdateInput>;
};

export type B2BAppRoleUpdateInput = {
  app?: Maybe<B2BAppRelateToOneInput>;
  role?: Maybe<OrganizationEmployeeRoleRelateToOneInput>;
  permissions?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppRoleWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppRoleWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppRoleWhereInput>>>;
  app?: Maybe<B2BAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  role?: Maybe<OrganizationEmployeeRoleWhereInput>;
  role_is_null?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Scalars['JSON']>;
  permissions_not?: Maybe<Scalars['JSON']>;
  permissions_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  permissions_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppRoleWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppRolesCreateInput = {
  data?: Maybe<B2BAppRoleCreateInput>;
};

export type B2BAppRolesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppRoleUpdateInput>;
};

export type B2BAppUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  partnerUrl?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<B2BAppIconType>;
  menuCategory?: Maybe<B2BAppMenuCategoryType>;
  contextDefaultStatus?: Maybe<B2BAppContextDefaultStatusType>;
  category?: Maybe<B2BAppCategoryType>;
  accessRights?: Maybe<B2BAppAccessRightRelateToManyInput>;
  features?: Maybe<Array<B2BAppGlobalFeature>>;
  displayPriority?: Maybe<Scalars['Int']>;
  label?: Maybe<B2BAppLabelType>;
  gallery?: Maybe<Array<Scalars['String']>>;
  price?: Maybe<Scalars['String']>;
  newsSharingConfig?: Maybe<B2BAppNewsSharingConfigRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2BAppWhereInput = {
  AND?: Maybe<Array<Maybe<B2BAppWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2BAppWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['String']>;
  logo_not?: Maybe<Scalars['String']>;
  logo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescription_not?: Maybe<Scalars['String']>;
  shortDescription_contains?: Maybe<Scalars['String']>;
  shortDescription_not_contains?: Maybe<Scalars['String']>;
  shortDescription_starts_with?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with?: Maybe<Scalars['String']>;
  shortDescription_ends_with?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with?: Maybe<Scalars['String']>;
  shortDescription_i?: Maybe<Scalars['String']>;
  shortDescription_not_i?: Maybe<Scalars['String']>;
  shortDescription_contains_i?: Maybe<Scalars['String']>;
  shortDescription_not_contains_i?: Maybe<Scalars['String']>;
  shortDescription_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer?: Maybe<Scalars['String']>;
  developer_not?: Maybe<Scalars['String']>;
  developer_contains?: Maybe<Scalars['String']>;
  developer_not_contains?: Maybe<Scalars['String']>;
  developer_starts_with?: Maybe<Scalars['String']>;
  developer_not_starts_with?: Maybe<Scalars['String']>;
  developer_ends_with?: Maybe<Scalars['String']>;
  developer_not_ends_with?: Maybe<Scalars['String']>;
  developer_i?: Maybe<Scalars['String']>;
  developer_not_i?: Maybe<Scalars['String']>;
  developer_contains_i?: Maybe<Scalars['String']>;
  developer_not_contains_i?: Maybe<Scalars['String']>;
  developer_starts_with_i?: Maybe<Scalars['String']>;
  developer_not_starts_with_i?: Maybe<Scalars['String']>;
  developer_ends_with_i?: Maybe<Scalars['String']>;
  developer_not_ends_with_i?: Maybe<Scalars['String']>;
  developer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnerUrl?: Maybe<Scalars['String']>;
  partnerUrl_not?: Maybe<Scalars['String']>;
  partnerUrl_contains?: Maybe<Scalars['String']>;
  partnerUrl_not_contains?: Maybe<Scalars['String']>;
  partnerUrl_starts_with?: Maybe<Scalars['String']>;
  partnerUrl_not_starts_with?: Maybe<Scalars['String']>;
  partnerUrl_ends_with?: Maybe<Scalars['String']>;
  partnerUrl_not_ends_with?: Maybe<Scalars['String']>;
  partnerUrl_i?: Maybe<Scalars['String']>;
  partnerUrl_not_i?: Maybe<Scalars['String']>;
  partnerUrl_contains_i?: Maybe<Scalars['String']>;
  partnerUrl_not_contains_i?: Maybe<Scalars['String']>;
  partnerUrl_starts_with_i?: Maybe<Scalars['String']>;
  partnerUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  partnerUrl_ends_with_i?: Maybe<Scalars['String']>;
  partnerUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  partnerUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  partnerUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedDescription?: Maybe<Scalars['String']>;
  detailedDescription_not?: Maybe<Scalars['String']>;
  detailedDescription_contains?: Maybe<Scalars['String']>;
  detailedDescription_not_contains?: Maybe<Scalars['String']>;
  detailedDescription_starts_with?: Maybe<Scalars['String']>;
  detailedDescription_not_starts_with?: Maybe<Scalars['String']>;
  detailedDescription_ends_with?: Maybe<Scalars['String']>;
  detailedDescription_not_ends_with?: Maybe<Scalars['String']>;
  detailedDescription_i?: Maybe<Scalars['String']>;
  detailedDescription_not_i?: Maybe<Scalars['String']>;
  detailedDescription_contains_i?: Maybe<Scalars['String']>;
  detailedDescription_not_contains_i?: Maybe<Scalars['String']>;
  detailedDescription_starts_with_i?: Maybe<Scalars['String']>;
  detailedDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  detailedDescription_ends_with_i?: Maybe<Scalars['String']>;
  detailedDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  detailedDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl?: Maybe<Scalars['String']>;
  appUrl_not?: Maybe<Scalars['String']>;
  appUrl_contains?: Maybe<Scalars['String']>;
  appUrl_not_contains?: Maybe<Scalars['String']>;
  appUrl_starts_with?: Maybe<Scalars['String']>;
  appUrl_not_starts_with?: Maybe<Scalars['String']>;
  appUrl_ends_with?: Maybe<Scalars['String']>;
  appUrl_not_ends_with?: Maybe<Scalars['String']>;
  appUrl_i?: Maybe<Scalars['String']>;
  appUrl_not_i?: Maybe<Scalars['String']>;
  appUrl_contains_i?: Maybe<Scalars['String']>;
  appUrl_not_contains_i?: Maybe<Scalars['String']>;
  appUrl_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  isGlobal?: Maybe<Scalars['Boolean']>;
  isGlobal_not?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isPublic_not?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle?: Maybe<Scalars['Boolean']>;
  hasDynamicTitle_not?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<B2BAppIconType>;
  icon_not?: Maybe<B2BAppIconType>;
  icon_in?: Maybe<Array<Maybe<B2BAppIconType>>>;
  icon_not_in?: Maybe<Array<Maybe<B2BAppIconType>>>;
  menuCategory?: Maybe<B2BAppMenuCategoryType>;
  menuCategory_not?: Maybe<B2BAppMenuCategoryType>;
  menuCategory_in?: Maybe<Array<Maybe<B2BAppMenuCategoryType>>>;
  menuCategory_not_in?: Maybe<Array<Maybe<B2BAppMenuCategoryType>>>;
  contextDefaultStatus?: Maybe<B2BAppContextDefaultStatusType>;
  contextDefaultStatus_not?: Maybe<B2BAppContextDefaultStatusType>;
  contextDefaultStatus_in?: Maybe<Array<Maybe<B2BAppContextDefaultStatusType>>>;
  contextDefaultStatus_not_in?: Maybe<Array<Maybe<B2BAppContextDefaultStatusType>>>;
  category?: Maybe<B2BAppCategoryType>;
  category_not?: Maybe<B2BAppCategoryType>;
  category_in?: Maybe<Array<Maybe<B2BAppCategoryType>>>;
  category_not_in?: Maybe<Array<Maybe<B2BAppCategoryType>>>;
  /**  condition must be true for all nodes  */
  accessRights_every?: Maybe<B2BAppAccessRightWhereInput>;
  /**  condition must be true for at least 1 node  */
  accessRights_some?: Maybe<B2BAppAccessRightWhereInput>;
  /**  condition must be false for all nodes  */
  accessRights_none?: Maybe<B2BAppAccessRightWhereInput>;
  features?: Maybe<Array<B2BAppGlobalFeature>>;
  features_not?: Maybe<Array<B2BAppGlobalFeature>>;
  features_in?: Maybe<Array<Maybe<Array<B2BAppGlobalFeature>>>>;
  features_not_in?: Maybe<Array<Maybe<Array<B2BAppGlobalFeature>>>>;
  displayPriority?: Maybe<Scalars['Int']>;
  displayPriority_not?: Maybe<Scalars['Int']>;
  displayPriority_lt?: Maybe<Scalars['Int']>;
  displayPriority_lte?: Maybe<Scalars['Int']>;
  displayPriority_gt?: Maybe<Scalars['Int']>;
  displayPriority_gte?: Maybe<Scalars['Int']>;
  displayPriority_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  displayPriority_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  label?: Maybe<B2BAppLabelType>;
  label_not?: Maybe<B2BAppLabelType>;
  label_in?: Maybe<Array<Maybe<B2BAppLabelType>>>;
  label_not_in?: Maybe<Array<Maybe<B2BAppLabelType>>>;
  gallery?: Maybe<Array<Scalars['String']>>;
  gallery_not?: Maybe<Array<Scalars['String']>>;
  gallery_in?: Maybe<Array<Maybe<Array<Scalars['String']>>>>;
  gallery_not_in?: Maybe<Array<Maybe<Array<Scalars['String']>>>>;
  price?: Maybe<Scalars['String']>;
  price_not?: Maybe<Scalars['String']>;
  price_contains?: Maybe<Scalars['String']>;
  price_not_contains?: Maybe<Scalars['String']>;
  price_starts_with?: Maybe<Scalars['String']>;
  price_not_starts_with?: Maybe<Scalars['String']>;
  price_ends_with?: Maybe<Scalars['String']>;
  price_not_ends_with?: Maybe<Scalars['String']>;
  price_i?: Maybe<Scalars['String']>;
  price_not_i?: Maybe<Scalars['String']>;
  price_contains_i?: Maybe<Scalars['String']>;
  price_not_contains_i?: Maybe<Scalars['String']>;
  price_starts_with_i?: Maybe<Scalars['String']>;
  price_not_starts_with_i?: Maybe<Scalars['String']>;
  price_ends_with_i?: Maybe<Scalars['String']>;
  price_not_ends_with_i?: Maybe<Scalars['String']>;
  price_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  price_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsSharingConfig?: Maybe<B2BAppNewsSharingConfigWhereInput>;
  newsSharingConfig_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2BAppWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2BAppsCreateInput = {
  data?: Maybe<B2BAppCreateInput>;
};

export type B2BAppsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppUpdateInput>;
};

/**  B2C App  */
export type B2CApp = {
  __typename?: 'B2CApp';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CApp List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CApp List config, or
   *  3. As an alias to a 'name' field on the B2CApp List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CApp List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of B2C App  */
  name?: Maybe<Scalars['String']>;
  /**  Logo of app's company or app itself  */
  logo?: Maybe<File>;
  /**  [DEPRECATED] This field is not used by anyone but fetched from some clients  */
  shortDescription?: Maybe<Scalars['String']>;
  /**  Developer company name  */
  developer?: Maybe<Scalars['String']>;
  /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
  isHidden?: Maybe<Scalars['Boolean']>;
  /**  The color schema of the B2C application used to display it correctly. The main color is used for texts, icons etc. The secondary color is used for the background  */
  colorSchema?: Maybe<AppColorSchemaField>;
  /**  Link to current active app build  */
  currentBuild?: Maybe<B2CAppBuild>;
  /**  Specifies set of service users, who can modify B2CAppProperties of the app as well as perform actions on behalf of the application  */
  accessRights: Array<B2CAppAccessRight>;
  _accessRightsMeta?: Maybe<_QueryMeta>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  /**  ID of the object in the system from which it was imported  */
  importId?: Maybe<Scalars['String']>;
  /**  Name of the system from which object was imported  */
  importRemoteSystem?: Maybe<Scalars['String']>;
};


/**  B2C App  */
export type B2CAppAccessRightsArgs = {
  where?: Maybe<B2CAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  B2C App  */
export type B2CApp_AccessRightsMetaArgs = {
  where?: Maybe<B2CAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/**  Link between service user and B2C App. The existence of this connection means that this user has the rights to perform actions on behalf of the integration and modify some B2CApp-related models  */
export type B2CAppAccessRight = {
  __typename?: 'B2CAppAccessRight';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppAccessRight List config, or
   *  3. As an alias to a 'name' field on the B2CAppAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppAccessRight List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to user. Note, that user must be of "service" type  */
  user?: Maybe<User>;
  /**  Link to B2СApp.accessRights  */
  app?: Maybe<B2CApp>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  /**  ID of the object in the system from which it was imported  */
  importId?: Maybe<Scalars['String']>;
  /**  Name of the system from which object was imported  */
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type B2CAppAccessRightCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  app?: Maybe<B2CAppRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type B2CAppAccessRightHistoryRecord = {
  __typename?: 'B2CAppAccessRightHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppAccessRightHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppAccessRightHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2CAppAccessRightHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2CAppAccessRightHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppAccessRightHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppAccessRightHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppAccessRightHistoryRecordsCreateInput = {
  data?: Maybe<B2CAppAccessRightHistoryRecordCreateInput>;
};

export type B2CAppAccessRightHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppAccessRightHistoryRecordUpdateInput>;
};

export type B2CAppAccessRightRelateToManyInput = {
  create?: Maybe<Array<Maybe<B2CAppAccessRightCreateInput>>>;
  connect?: Maybe<Array<Maybe<B2CAppAccessRightWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<B2CAppAccessRightWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type B2CAppAccessRightUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  app?: Maybe<B2CAppRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type B2CAppAccessRightWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppAccessRightWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppAccessRightWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  app?: Maybe<B2CAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppAccessRightWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppAccessRightsCreateInput = {
  data?: Maybe<B2CAppAccessRightCreateInput>;
};

export type B2CAppAccessRightsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppAccessRightUpdateInput>;
};

/**  Cordova build of B2C Application  */
export type B2CAppBuild = {
  __typename?: 'B2CAppBuild';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppBuild List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppBuild List config, or
   *  3. As an alias to a 'name' field on the B2CAppBuild List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppBuild List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to B2C application  */
  app?: Maybe<B2CApp>;
  /**  Version of build which used to control builds inside B2CApp model  */
  version?: Maybe<Scalars['String']>;
  /**  B2C app cordova build compressed to single .zip file  */
  data?: Maybe<File>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  /**  ID of the object in the system from which it was imported  */
  importId?: Maybe<Scalars['String']>;
  /**  Name of the system from which object was imported  */
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type B2CAppBuildCreateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  version?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type B2CAppBuildHistoryRecord = {
  __typename?: 'B2CAppBuildHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppBuildHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppBuildHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppBuildHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppBuildHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppBuildHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppBuildHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppBuildHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2CAppBuildHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2CAppBuildHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppBuildHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppBuildHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppBuildHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppBuildHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['String']>;
  version_not?: Maybe<Scalars['String']>;
  version_contains?: Maybe<Scalars['String']>;
  version_not_contains?: Maybe<Scalars['String']>;
  version_starts_with?: Maybe<Scalars['String']>;
  version_not_starts_with?: Maybe<Scalars['String']>;
  version_ends_with?: Maybe<Scalars['String']>;
  version_not_ends_with?: Maybe<Scalars['String']>;
  version_i?: Maybe<Scalars['String']>;
  version_not_i?: Maybe<Scalars['String']>;
  version_contains_i?: Maybe<Scalars['String']>;
  version_not_contains_i?: Maybe<Scalars['String']>;
  version_starts_with_i?: Maybe<Scalars['String']>;
  version_not_starts_with_i?: Maybe<Scalars['String']>;
  version_ends_with_i?: Maybe<Scalars['String']>;
  version_not_ends_with_i?: Maybe<Scalars['String']>;
  version_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  version_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Scalars['JSON']>;
  data_not?: Maybe<Scalars['JSON']>;
  data_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  data_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2CAppBuildHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2CAppBuildHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2CAppBuildHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2CAppBuildHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppBuildHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppBuildHistoryRecordsCreateInput = {
  data?: Maybe<B2CAppBuildHistoryRecordCreateInput>;
};

export type B2CAppBuildHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppBuildHistoryRecordUpdateInput>;
};

export type B2CAppBuildRelateToOneInput = {
  create?: Maybe<B2CAppBuildCreateInput>;
  connect?: Maybe<B2CAppBuildWhereUniqueInput>;
  disconnect?: Maybe<B2CAppBuildWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type B2CAppBuildUpdateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  version?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type B2CAppBuildWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppBuildWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppBuildWhereInput>>>;
  app?: Maybe<B2CAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['String']>;
  version_not?: Maybe<Scalars['String']>;
  version_contains?: Maybe<Scalars['String']>;
  version_not_contains?: Maybe<Scalars['String']>;
  version_starts_with?: Maybe<Scalars['String']>;
  version_not_starts_with?: Maybe<Scalars['String']>;
  version_ends_with?: Maybe<Scalars['String']>;
  version_not_ends_with?: Maybe<Scalars['String']>;
  version_i?: Maybe<Scalars['String']>;
  version_not_i?: Maybe<Scalars['String']>;
  version_contains_i?: Maybe<Scalars['String']>;
  version_not_contains_i?: Maybe<Scalars['String']>;
  version_starts_with_i?: Maybe<Scalars['String']>;
  version_not_starts_with_i?: Maybe<Scalars['String']>;
  version_ends_with_i?: Maybe<Scalars['String']>;
  version_not_ends_with_i?: Maybe<Scalars['String']>;
  version_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  version_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<Scalars['String']>;
  data_not?: Maybe<Scalars['String']>;
  data_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  data_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppBuildWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppBuildsCreateInput = {
  data?: Maybe<B2CAppBuildCreateInput>;
};

export type B2CAppBuildsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppBuildUpdateInput>;
};

export type B2CAppCreateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<AppColorSchemaFieldInput>;
  currentBuild?: Maybe<B2CAppBuildRelateToOneInput>;
  accessRights?: Maybe<B2CAppAccessRightRelateToManyInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type B2CAppHistoryRecord = {
  __typename?: 'B2CAppHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<Scalars['JSON']>;
  currentBuild?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<Scalars['JSON']>;
  currentBuild?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2CAppHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2CAppHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<Scalars['JSON']>;
  currentBuild?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['JSON']>;
  logo_not?: Maybe<Scalars['JSON']>;
  logo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  logo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescription_not?: Maybe<Scalars['String']>;
  shortDescription_contains?: Maybe<Scalars['String']>;
  shortDescription_not_contains?: Maybe<Scalars['String']>;
  shortDescription_starts_with?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with?: Maybe<Scalars['String']>;
  shortDescription_ends_with?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with?: Maybe<Scalars['String']>;
  shortDescription_i?: Maybe<Scalars['String']>;
  shortDescription_not_i?: Maybe<Scalars['String']>;
  shortDescription_contains_i?: Maybe<Scalars['String']>;
  shortDescription_not_contains_i?: Maybe<Scalars['String']>;
  shortDescription_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer?: Maybe<Scalars['String']>;
  developer_not?: Maybe<Scalars['String']>;
  developer_contains?: Maybe<Scalars['String']>;
  developer_not_contains?: Maybe<Scalars['String']>;
  developer_starts_with?: Maybe<Scalars['String']>;
  developer_not_starts_with?: Maybe<Scalars['String']>;
  developer_ends_with?: Maybe<Scalars['String']>;
  developer_not_ends_with?: Maybe<Scalars['String']>;
  developer_i?: Maybe<Scalars['String']>;
  developer_not_i?: Maybe<Scalars['String']>;
  developer_contains_i?: Maybe<Scalars['String']>;
  developer_not_contains_i?: Maybe<Scalars['String']>;
  developer_starts_with_i?: Maybe<Scalars['String']>;
  developer_not_starts_with_i?: Maybe<Scalars['String']>;
  developer_ends_with_i?: Maybe<Scalars['String']>;
  developer_not_ends_with_i?: Maybe<Scalars['String']>;
  developer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<Scalars['JSON']>;
  colorSchema_not?: Maybe<Scalars['JSON']>;
  colorSchema_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  colorSchema_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  currentBuild?: Maybe<Scalars['String']>;
  currentBuild_not?: Maybe<Scalars['String']>;
  currentBuild_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentBuild_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2CAppHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2CAppHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2CAppHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2CAppHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppHistoryRecordsCreateInput = {
  data?: Maybe<B2CAppHistoryRecordCreateInput>;
};

export type B2CAppHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppHistoryRecordUpdateInput>;
};

/**  Configuration of sending push notifications for specific miniapps.Used to block certain types of push notifications and control throttling of notifications of a certain miniapp  */
export type B2CAppMessageSetting = {
  __typename?: 'B2CAppMessageSetting';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppMessageSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppMessageSetting List config, or
   *  3. As an alias to a 'name' field on the B2CAppMessageSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppMessageSetting List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to B2CApp to which this config applies  */
  app?: Maybe<B2CApp>;
  /**  Reason for blocking messages with types from type  */
  blockReason?: Maybe<Scalars['String']>;
  /**  Is message type in black list for this B2CApp  */
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  /**  The type of message we want to configure  */
  type?: Maybe<MessageType>;
  /**  Time window for sending push notifications in sec  */
  notificationWindowSize?: Maybe<Scalars['Int']>;
  /**  The number of notifications that can be sent in one time window in sec  */
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2CAppMessageSettingCreateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  blockReason?: Maybe<Scalars['String']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2CAppMessageSettingHistoryRecord = {
  __typename?: 'B2CAppMessageSettingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppMessageSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppMessageSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppMessageSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppMessageSettingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  blockReason?: Maybe<Scalars['String']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppMessageSettingHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  blockReason?: Maybe<Scalars['String']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2CAppMessageSettingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2CAppMessageSettingHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  blockReason?: Maybe<Scalars['String']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppMessageSettingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  blockReason?: Maybe<Scalars['String']>;
  blockReason_not?: Maybe<Scalars['String']>;
  blockReason_contains?: Maybe<Scalars['String']>;
  blockReason_not_contains?: Maybe<Scalars['String']>;
  blockReason_starts_with?: Maybe<Scalars['String']>;
  blockReason_not_starts_with?: Maybe<Scalars['String']>;
  blockReason_ends_with?: Maybe<Scalars['String']>;
  blockReason_not_ends_with?: Maybe<Scalars['String']>;
  blockReason_i?: Maybe<Scalars['String']>;
  blockReason_not_i?: Maybe<Scalars['String']>;
  blockReason_contains_i?: Maybe<Scalars['String']>;
  blockReason_not_contains_i?: Maybe<Scalars['String']>;
  blockReason_starts_with_i?: Maybe<Scalars['String']>;
  blockReason_not_starts_with_i?: Maybe<Scalars['String']>;
  blockReason_ends_with_i?: Maybe<Scalars['String']>;
  blockReason_not_ends_with_i?: Maybe<Scalars['String']>;
  blockReason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  blockReason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  isBlacklisted_not?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  notificationWindowSize_not?: Maybe<Scalars['Int']>;
  notificationWindowSize_lt?: Maybe<Scalars['Int']>;
  notificationWindowSize_lte?: Maybe<Scalars['Int']>;
  notificationWindowSize_gt?: Maybe<Scalars['Int']>;
  notificationWindowSize_gte?: Maybe<Scalars['Int']>;
  notificationWindowSize_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notificationWindowSize_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_not?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_lt?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_lte?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_gt?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_gte?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfNotificationInWindow_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppMessageSettingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppMessageSettingHistoryRecordsCreateInput = {
  data?: Maybe<B2CAppMessageSettingHistoryRecordCreateInput>;
};

export type B2CAppMessageSettingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppMessageSettingHistoryRecordUpdateInput>;
};

export type B2CAppMessageSettingUpdateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  blockReason?: Maybe<Scalars['String']>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2CAppMessageSettingWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppMessageSettingWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppMessageSettingWhereInput>>>;
  app?: Maybe<B2CAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  blockReason?: Maybe<Scalars['String']>;
  blockReason_not?: Maybe<Scalars['String']>;
  blockReason_contains?: Maybe<Scalars['String']>;
  blockReason_not_contains?: Maybe<Scalars['String']>;
  blockReason_starts_with?: Maybe<Scalars['String']>;
  blockReason_not_starts_with?: Maybe<Scalars['String']>;
  blockReason_ends_with?: Maybe<Scalars['String']>;
  blockReason_not_ends_with?: Maybe<Scalars['String']>;
  blockReason_i?: Maybe<Scalars['String']>;
  blockReason_not_i?: Maybe<Scalars['String']>;
  blockReason_contains_i?: Maybe<Scalars['String']>;
  blockReason_not_contains_i?: Maybe<Scalars['String']>;
  blockReason_starts_with_i?: Maybe<Scalars['String']>;
  blockReason_not_starts_with_i?: Maybe<Scalars['String']>;
  blockReason_ends_with_i?: Maybe<Scalars['String']>;
  blockReason_not_ends_with_i?: Maybe<Scalars['String']>;
  blockReason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  blockReason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isBlacklisted?: Maybe<Scalars['Boolean']>;
  isBlacklisted_not?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
  type_not?: Maybe<MessageType>;
  type_in?: Maybe<Array<Maybe<MessageType>>>;
  type_not_in?: Maybe<Array<Maybe<MessageType>>>;
  notificationWindowSize?: Maybe<Scalars['Int']>;
  notificationWindowSize_not?: Maybe<Scalars['Int']>;
  notificationWindowSize_lt?: Maybe<Scalars['Int']>;
  notificationWindowSize_lte?: Maybe<Scalars['Int']>;
  notificationWindowSize_gt?: Maybe<Scalars['Int']>;
  notificationWindowSize_gte?: Maybe<Scalars['Int']>;
  notificationWindowSize_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notificationWindowSize_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfNotificationInWindow?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_not?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_lt?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_lte?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_gt?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_gte?: Maybe<Scalars['Int']>;
  numberOfNotificationInWindow_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfNotificationInWindow_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2CAppMessageSettingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppMessageSettingsCreateInput = {
  data?: Maybe<B2CAppMessageSettingCreateInput>;
};

export type B2CAppMessageSettingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppMessageSettingUpdateInput>;
};

export type B2CAppPropertiesCreateInput = {
  data?: Maybe<B2CAppPropertyCreateInput>;
};

export type B2CAppPropertiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppPropertyUpdateInput>;
};

/**  Link between specific home address and B2C App. used to filter B2C applications that can be run on a specific address  */
export type B2CAppProperty = {
  __typename?: 'B2CAppProperty';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppProperty List config, or
   *  3. As an alias to a 'name' field on the B2CAppProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppProperty List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to B2C App  */
  app?: Maybe<B2CApp>;
  id: Scalars['ID'];
  /**  Normalized address  */
  address?: Maybe<Scalars['String']>;
  /**  The unique key of the address  */
  addressKey?: Maybe<Scalars['String']>;
  /**  Property address components  */
  addressMeta?: Maybe<AddressMetaField>;
  /**  The origins of the address (some strings which may looks like real address or some id)  */
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type B2CAppPropertyCreateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type B2CAppPropertyHistoryRecord = {
  __typename?: 'B2CAppPropertyHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the B2CAppPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the B2CAppPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the B2CAppPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the B2CAppPropertyHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppPropertyHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum B2CAppPropertyHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type B2CAppPropertyHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<B2CAppPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type B2CAppPropertyHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<B2CAppPropertyHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<B2CAppPropertyHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppPropertyHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppPropertyHistoryRecordsCreateInput = {
  data?: Maybe<B2CAppPropertyHistoryRecordCreateInput>;
};

export type B2CAppPropertyHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppPropertyHistoryRecordUpdateInput>;
};

export type B2CAppPropertyUpdateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type B2CAppPropertyWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppPropertyWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppPropertyWhereInput>>>;
  app?: Maybe<B2CAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type B2CAppPropertyWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppRelateToOneInput = {
  create?: Maybe<B2CAppCreateInput>;
  connect?: Maybe<B2CAppWhereUniqueInput>;
  disconnect?: Maybe<B2CAppWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type B2CAppUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
  developer?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<AppColorSchemaFieldInput>;
  currentBuild?: Maybe<B2CAppBuildRelateToOneInput>;
  accessRights?: Maybe<B2CAppAccessRightRelateToManyInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type B2CAppWhereInput = {
  AND?: Maybe<Array<Maybe<B2CAppWhereInput>>>;
  OR?: Maybe<Array<Maybe<B2CAppWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['String']>;
  logo_not?: Maybe<Scalars['String']>;
  logo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescription_not?: Maybe<Scalars['String']>;
  shortDescription_contains?: Maybe<Scalars['String']>;
  shortDescription_not_contains?: Maybe<Scalars['String']>;
  shortDescription_starts_with?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with?: Maybe<Scalars['String']>;
  shortDescription_ends_with?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with?: Maybe<Scalars['String']>;
  shortDescription_i?: Maybe<Scalars['String']>;
  shortDescription_not_i?: Maybe<Scalars['String']>;
  shortDescription_contains_i?: Maybe<Scalars['String']>;
  shortDescription_not_contains_i?: Maybe<Scalars['String']>;
  shortDescription_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer?: Maybe<Scalars['String']>;
  developer_not?: Maybe<Scalars['String']>;
  developer_contains?: Maybe<Scalars['String']>;
  developer_not_contains?: Maybe<Scalars['String']>;
  developer_starts_with?: Maybe<Scalars['String']>;
  developer_not_starts_with?: Maybe<Scalars['String']>;
  developer_ends_with?: Maybe<Scalars['String']>;
  developer_not_ends_with?: Maybe<Scalars['String']>;
  developer_i?: Maybe<Scalars['String']>;
  developer_not_i?: Maybe<Scalars['String']>;
  developer_contains_i?: Maybe<Scalars['String']>;
  developer_not_contains_i?: Maybe<Scalars['String']>;
  developer_starts_with_i?: Maybe<Scalars['String']>;
  developer_not_starts_with_i?: Maybe<Scalars['String']>;
  developer_ends_with_i?: Maybe<Scalars['String']>;
  developer_not_ends_with_i?: Maybe<Scalars['String']>;
  developer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  developer_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  colorSchema?: Maybe<AppColorSchemaFieldInput>;
  colorSchema_not?: Maybe<AppColorSchemaFieldInput>;
  colorSchema_in?: Maybe<Array<Maybe<AppColorSchemaFieldInput>>>;
  colorSchema_not_in?: Maybe<Array<Maybe<AppColorSchemaFieldInput>>>;
  currentBuild?: Maybe<B2CAppBuildWhereInput>;
  currentBuild_is_null?: Maybe<Scalars['Boolean']>;
  /**  condition must be true for all nodes  */
  accessRights_every?: Maybe<B2CAppAccessRightWhereInput>;
  /**  condition must be true for at least 1 node  */
  accessRights_some?: Maybe<B2CAppAccessRightWhereInput>;
  /**  condition must be false for all nodes  */
  accessRights_none?: Maybe<B2CAppAccessRightWhereInput>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type B2CAppWhereUniqueInput = {
  id: Scalars['ID'];
};

export type B2CAppsCreateInput = {
  data?: Maybe<B2CAppCreateInput>;
};

export type B2CAppsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppUpdateInput>;
};

/**  Bank account, that will have transactions, pulled from various integrated data sources  */
export type BankAccount = {
  __typename?: 'BankAccount';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccount List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccount List config, or
   *  3. As an alias to a 'name' field on the BankAccount List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccount List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Used data source to obtain transactions from  */
  integrationContext?: Maybe<BankIntegrationAccountContext>;
  /**  Property to which this bank account is connected  */
  property?: Maybe<Property>;
  /**  Tax Identification Number  */
  tin?: Maybe<Scalars['String']>;
  /**  Structured metadata found by tin  */
  tinMeta?: Maybe<Scalars['JSON']>;
  /**  Country where the bank is located  */
  country?: Maybe<BankAccountCountryType>;
  /**  The routing transit number for the bank account.  */
  routingNumber?: Maybe<Scalars['String']>;
  /**  Structured metadata found by routing number  */
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  /**  Bank account number  */
  number?: Maybe<Scalars['String']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<BankAccountCurrencyCodeType>;
  /**  Shows whether the bank account approved or not  */
  isApproved?: Maybe<Scalars['Boolean']>;
  /**  When the bank account received the status of approved  */
  approvedAt?: Maybe<Scalars['String']>;
  /**  Who set the approved status for the bank account  */
  approvedBy?: Maybe<User>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  /**  Location of the holder of this bank account. It depends on a country. In Russia it is OKTMO  */
  territoryCode?: Maybe<Scalars['String']>;
  /**  Bank name  */
  bankName?: Maybe<Scalars['String']>;
  /**  Structured non-typed metadata, can be used by mini-apps or external services to store information  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Budget classification code, used for state-funded organizations  */
  classificationCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export enum BankAccountCountryType {
  En = 'en',
  Ru = 'ru'
}

export type BankAccountCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  integrationContext?: Maybe<BankIntegrationAccountContextRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  tin?: Maybe<Scalars['String']>;
  tinMeta?: Maybe<Scalars['JSON']>;
  country?: Maybe<BankAccountCountryType>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<BankAccountCurrencyCodeType>;
  isApproved?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<UserRelateToOneInput>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum BankAccountCurrencyCodeType {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

/**  A keystone list  */
export type BankAccountHistoryRecord = {
  __typename?: 'BankAccountHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankAccountHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tinMeta?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankAccountHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tinMeta?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankAccountHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankAccountHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tinMeta?: Maybe<Scalars['JSON']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankAccountHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankAccountHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankAccountHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integrationContext?: Maybe<Scalars['String']>;
  integrationContext_not?: Maybe<Scalars['String']>;
  integrationContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integrationContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tinMeta?: Maybe<Scalars['JSON']>;
  tinMeta_not?: Maybe<Scalars['JSON']>;
  tinMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  tinMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  country?: Maybe<Scalars['String']>;
  country_not?: Maybe<Scalars['String']>;
  country_contains?: Maybe<Scalars['String']>;
  country_not_contains?: Maybe<Scalars['String']>;
  country_starts_with?: Maybe<Scalars['String']>;
  country_not_starts_with?: Maybe<Scalars['String']>;
  country_ends_with?: Maybe<Scalars['String']>;
  country_not_ends_with?: Maybe<Scalars['String']>;
  country_i?: Maybe<Scalars['String']>;
  country_not_i?: Maybe<Scalars['String']>;
  country_contains_i?: Maybe<Scalars['String']>;
  country_not_contains_i?: Maybe<Scalars['String']>;
  country_starts_with_i?: Maybe<Scalars['String']>;
  country_not_starts_with_i?: Maybe<Scalars['String']>;
  country_ends_with_i?: Maybe<Scalars['String']>;
  country_not_ends_with_i?: Maybe<Scalars['String']>;
  country_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumber_not?: Maybe<Scalars['String']>;
  routingNumber_contains?: Maybe<Scalars['String']>;
  routingNumber_not_contains?: Maybe<Scalars['String']>;
  routingNumber_starts_with?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with?: Maybe<Scalars['String']>;
  routingNumber_ends_with?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with?: Maybe<Scalars['String']>;
  routingNumber_i?: Maybe<Scalars['String']>;
  routingNumber_not_i?: Maybe<Scalars['String']>;
  routingNumber_contains_i?: Maybe<Scalars['String']>;
  routingNumber_not_contains_i?: Maybe<Scalars['String']>;
  routingNumber_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  routingNumberMeta_not?: Maybe<Scalars['JSON']>;
  routingNumberMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  routingNumberMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_not?: Maybe<Scalars['String']>;
  currencyCode_contains?: Maybe<Scalars['String']>;
  currencyCode_not_contains?: Maybe<Scalars['String']>;
  currencyCode_starts_with?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with?: Maybe<Scalars['String']>;
  currencyCode_ends_with?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with?: Maybe<Scalars['String']>;
  currencyCode_i?: Maybe<Scalars['String']>;
  currencyCode_not_i?: Maybe<Scalars['String']>;
  currencyCode_contains_i?: Maybe<Scalars['String']>;
  currencyCode_not_contains_i?: Maybe<Scalars['String']>;
  currencyCode_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isApproved_not?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedAt_not?: Maybe<Scalars['String']>;
  approvedAt_lt?: Maybe<Scalars['String']>;
  approvedAt_lte?: Maybe<Scalars['String']>;
  approvedAt_gt?: Maybe<Scalars['String']>;
  approvedAt_gte?: Maybe<Scalars['String']>;
  approvedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvedBy?: Maybe<Scalars['String']>;
  approvedBy_not?: Maybe<Scalars['String']>;
  approvedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode?: Maybe<Scalars['String']>;
  territoryCode_not?: Maybe<Scalars['String']>;
  territoryCode_contains?: Maybe<Scalars['String']>;
  territoryCode_not_contains?: Maybe<Scalars['String']>;
  territoryCode_starts_with?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with?: Maybe<Scalars['String']>;
  territoryCode_ends_with?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with?: Maybe<Scalars['String']>;
  territoryCode_i?: Maybe<Scalars['String']>;
  territoryCode_not_i?: Maybe<Scalars['String']>;
  territoryCode_contains_i?: Maybe<Scalars['String']>;
  territoryCode_not_contains_i?: Maybe<Scalars['String']>;
  territoryCode_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  bankName_i?: Maybe<Scalars['String']>;
  bankName_not_i?: Maybe<Scalars['String']>;
  bankName_contains_i?: Maybe<Scalars['String']>;
  bankName_not_contains_i?: Maybe<Scalars['String']>;
  bankName_starts_with_i?: Maybe<Scalars['String']>;
  bankName_not_starts_with_i?: Maybe<Scalars['String']>;
  bankName_ends_with_i?: Maybe<Scalars['String']>;
  bankName_not_ends_with_i?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  classificationCode?: Maybe<Scalars['String']>;
  classificationCode_not?: Maybe<Scalars['String']>;
  classificationCode_contains?: Maybe<Scalars['String']>;
  classificationCode_not_contains?: Maybe<Scalars['String']>;
  classificationCode_starts_with?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with?: Maybe<Scalars['String']>;
  classificationCode_ends_with?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with?: Maybe<Scalars['String']>;
  classificationCode_i?: Maybe<Scalars['String']>;
  classificationCode_not_i?: Maybe<Scalars['String']>;
  classificationCode_contains_i?: Maybe<Scalars['String']>;
  classificationCode_not_contains_i?: Maybe<Scalars['String']>;
  classificationCode_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankAccountHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankAccountHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankAccountHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankAccountHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankAccountHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankAccountHistoryRecordsCreateInput = {
  data?: Maybe<BankAccountHistoryRecordCreateInput>;
};

export type BankAccountHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountHistoryRecordUpdateInput>;
};

export type BankAccountRelateToOneInput = {
  create?: Maybe<BankAccountCreateInput>;
  connect?: Maybe<BankAccountWhereUniqueInput>;
  disconnect?: Maybe<BankAccountWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  Calculated data for financial report of specified form, period and version.  */
export type BankAccountReport = {
  __typename?: 'BankAccountReport';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReport List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReport List config, or
   *  3. As an alias to a 'name' field on the BankAccountReport List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReport List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Transactions of specified bank account will be used to calculate this report  */
  account?: Maybe<BankAccount>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  When manual changes in transactions covers specified period,   */
  version?: Maybe<Scalars['Int']>;
  /**  Form of this report  */
  template?: Maybe<BankAccountReportTemplateType>;
  /**  Specified time frame to calculate this report. Can be month, quarter, year. Has specified format  */
  period?: Maybe<Scalars['String']>;
  /**  Total amount for BankAccount on end of specified period of this report  */
  amount?: Maybe<Scalars['String']>;
  /**  Date on that the specified amount is actual  */
  amountAt?: Maybe<Scalars['String']>;
  /**  Report will be available for public view if this field will have a value, otherwise in case of null value this report will not be available for public view  */
  publishedAt?: Maybe<Scalars['String']>;
  /**  Total income amount over period of this report  */
  totalIncome?: Maybe<Scalars['String']>;
  /**  Total outcome amount over period of this report  */
  totalOutcome?: Maybe<Scalars['String']>;
  /**  Whether the report version is the latest  */
  isLatest?: Maybe<Scalars['Boolean']>;
  /**  Calculated data of this report, that will be used to display  */
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankAccountReportCreateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  version?: Maybe<Scalars['Int']>;
  template?: Maybe<BankAccountReportTemplateType>;
  period?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  amountAt?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  totalIncome?: Maybe<Scalars['String']>;
  totalOutcome?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankAccountReportHistoryRecord = {
  __typename?: 'BankAccountReportHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReportHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReportHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankAccountReportHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReportHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  template?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  amountAt?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  totalIncome?: Maybe<Scalars['String']>;
  totalOutcome?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountReportHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankAccountReportHistoryRecordCreateInput = {
  account?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  template?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  amountAt?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  totalIncome?: Maybe<Scalars['String']>;
  totalOutcome?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountReportHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankAccountReportHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankAccountReportHistoryRecordUpdateInput = {
  account?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  template?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  amountAt?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  totalIncome?: Maybe<Scalars['String']>;
  totalOutcome?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountReportHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankAccountReportHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankAccountReportHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankAccountReportHistoryRecordWhereInput>>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  version?: Maybe<Scalars['Int']>;
  version_not?: Maybe<Scalars['Int']>;
  version_lt?: Maybe<Scalars['Int']>;
  version_lte?: Maybe<Scalars['Int']>;
  version_gt?: Maybe<Scalars['Int']>;
  version_gte?: Maybe<Scalars['Int']>;
  version_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  version_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  template?: Maybe<Scalars['String']>;
  template_not?: Maybe<Scalars['String']>;
  template_contains?: Maybe<Scalars['String']>;
  template_not_contains?: Maybe<Scalars['String']>;
  template_starts_with?: Maybe<Scalars['String']>;
  template_not_starts_with?: Maybe<Scalars['String']>;
  template_ends_with?: Maybe<Scalars['String']>;
  template_not_ends_with?: Maybe<Scalars['String']>;
  template_i?: Maybe<Scalars['String']>;
  template_not_i?: Maybe<Scalars['String']>;
  template_contains_i?: Maybe<Scalars['String']>;
  template_not_contains_i?: Maybe<Scalars['String']>;
  template_starts_with_i?: Maybe<Scalars['String']>;
  template_not_starts_with_i?: Maybe<Scalars['String']>;
  template_ends_with_i?: Maybe<Scalars['String']>;
  template_not_ends_with_i?: Maybe<Scalars['String']>;
  template_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  template_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_contains?: Maybe<Scalars['String']>;
  period_not_contains?: Maybe<Scalars['String']>;
  period_starts_with?: Maybe<Scalars['String']>;
  period_not_starts_with?: Maybe<Scalars['String']>;
  period_ends_with?: Maybe<Scalars['String']>;
  period_not_ends_with?: Maybe<Scalars['String']>;
  period_i?: Maybe<Scalars['String']>;
  period_not_i?: Maybe<Scalars['String']>;
  period_contains_i?: Maybe<Scalars['String']>;
  period_not_contains_i?: Maybe<Scalars['String']>;
  period_starts_with_i?: Maybe<Scalars['String']>;
  period_not_starts_with_i?: Maybe<Scalars['String']>;
  period_ends_with_i?: Maybe<Scalars['String']>;
  period_not_ends_with_i?: Maybe<Scalars['String']>;
  period_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount?: Maybe<Scalars['String']>;
  amount_not?: Maybe<Scalars['String']>;
  amount_lt?: Maybe<Scalars['String']>;
  amount_lte?: Maybe<Scalars['String']>;
  amount_gt?: Maybe<Scalars['String']>;
  amount_gte?: Maybe<Scalars['String']>;
  amount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountAt?: Maybe<Scalars['String']>;
  amountAt_not?: Maybe<Scalars['String']>;
  amountAt_lt?: Maybe<Scalars['String']>;
  amountAt_lte?: Maybe<Scalars['String']>;
  amountAt_gt?: Maybe<Scalars['String']>;
  amountAt_gte?: Maybe<Scalars['String']>;
  amountAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedAt_not?: Maybe<Scalars['String']>;
  publishedAt_lt?: Maybe<Scalars['String']>;
  publishedAt_lte?: Maybe<Scalars['String']>;
  publishedAt_gt?: Maybe<Scalars['String']>;
  publishedAt_gte?: Maybe<Scalars['String']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalIncome?: Maybe<Scalars['String']>;
  totalIncome_not?: Maybe<Scalars['String']>;
  totalIncome_lt?: Maybe<Scalars['String']>;
  totalIncome_lte?: Maybe<Scalars['String']>;
  totalIncome_gt?: Maybe<Scalars['String']>;
  totalIncome_gte?: Maybe<Scalars['String']>;
  totalIncome_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalIncome_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalOutcome?: Maybe<Scalars['String']>;
  totalOutcome_not?: Maybe<Scalars['String']>;
  totalOutcome_lt?: Maybe<Scalars['String']>;
  totalOutcome_lte?: Maybe<Scalars['String']>;
  totalOutcome_gt?: Maybe<Scalars['String']>;
  totalOutcome_gte?: Maybe<Scalars['String']>;
  totalOutcome_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalOutcome_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isLatest?: Maybe<Scalars['Boolean']>;
  isLatest_not?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  data_not?: Maybe<Scalars['JSON']>;
  data_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  data_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankAccountReportHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankAccountReportHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankAccountReportHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankAccountReportHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankAccountReportHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankAccountReportHistoryRecordsCreateInput = {
  data?: Maybe<BankAccountReportHistoryRecordCreateInput>;
};

export type BankAccountReportHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportHistoryRecordUpdateInput>;
};

/**  Information about the report generation process  */
export type BankAccountReportTask = {
  __typename?: 'BankAccountReportTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReportTask List config, or
   *  3. As an alias to a 'name' field on the BankAccountReportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReportTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Bank account for which the current report generation operation is running.  */
  account?: Maybe<BankAccount>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Status of current generation operation  */
  status?: Maybe<BankAccountReportTaskStatusType>;
  /**  Progress of current generation operation  */
  progress?: Maybe<Scalars['Int']>;
  /**  User that requested this operation. Will be used for read access checks to display all tasks somewhere and to display progress indicator of ongoing generating task for current user  */
  user?: Maybe<User>;
  /**  Additional data, specific to used integration  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankAccountReportTaskCreateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  status?: Maybe<BankAccountReportTaskStatusType>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<UserRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankAccountReportTaskHistoryRecord = {
  __typename?: 'BankAccountReportTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankAccountReportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankAccountReportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankAccountReportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankAccountReportTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankAccountReportTaskHistoryRecordCreateInput = {
  account?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankAccountReportTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankAccountReportTaskHistoryRecordUpdateInput = {
  account?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankAccountReportTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecordWhereInput>>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  progress?: Maybe<Scalars['Int']>;
  progress_not?: Maybe<Scalars['Int']>;
  progress_lt?: Maybe<Scalars['Int']>;
  progress_lte?: Maybe<Scalars['Int']>;
  progress_gt?: Maybe<Scalars['Int']>;
  progress_gte?: Maybe<Scalars['Int']>;
  progress_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  progress_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankAccountReportTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankAccountReportTaskHistoryRecordsCreateInput = {
  data?: Maybe<BankAccountReportTaskHistoryRecordCreateInput>;
};

export type BankAccountReportTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportTaskHistoryRecordUpdateInput>;
};

export enum BankAccountReportTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type BankAccountReportTaskUpdateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  status?: Maybe<BankAccountReportTaskStatusType>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<UserRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankAccountReportTaskWhereInput = {
  AND?: Maybe<Array<Maybe<BankAccountReportTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankAccountReportTaskWhereInput>>>;
  account?: Maybe<BankAccountWhereInput>;
  account_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BankAccountReportTaskStatusType>;
  status_not?: Maybe<BankAccountReportTaskStatusType>;
  status_in?: Maybe<Array<Maybe<BankAccountReportTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<BankAccountReportTaskStatusType>>>;
  progress?: Maybe<Scalars['Int']>;
  progress_not?: Maybe<Scalars['Int']>;
  progress_lt?: Maybe<Scalars['Int']>;
  progress_lte?: Maybe<Scalars['Int']>;
  progress_gt?: Maybe<Scalars['Int']>;
  progress_gte?: Maybe<Scalars['Int']>;
  progress_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  progress_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankAccountReportTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankAccountReportTasksCreateInput = {
  data?: Maybe<BankAccountReportTaskCreateInput>;
};

export type BankAccountReportTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportTaskUpdateInput>;
};

export enum BankAccountReportTemplateType {
  ExpensesGroupedByCategoryAndCostItem = 'expenses_grouped_by_category_and_cost_item'
}

export type BankAccountReportUpdateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  version?: Maybe<Scalars['Int']>;
  template?: Maybe<BankAccountReportTemplateType>;
  period?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  amountAt?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  totalIncome?: Maybe<Scalars['String']>;
  totalOutcome?: Maybe<Scalars['String']>;
  isLatest?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankAccountReportWhereInput = {
  AND?: Maybe<Array<Maybe<BankAccountReportWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankAccountReportWhereInput>>>;
  account?: Maybe<BankAccountWhereInput>;
  account_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Int']>;
  version_not?: Maybe<Scalars['Int']>;
  version_lt?: Maybe<Scalars['Int']>;
  version_lte?: Maybe<Scalars['Int']>;
  version_gt?: Maybe<Scalars['Int']>;
  version_gte?: Maybe<Scalars['Int']>;
  version_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  version_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  template?: Maybe<BankAccountReportTemplateType>;
  template_not?: Maybe<BankAccountReportTemplateType>;
  template_in?: Maybe<Array<Maybe<BankAccountReportTemplateType>>>;
  template_not_in?: Maybe<Array<Maybe<BankAccountReportTemplateType>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_contains?: Maybe<Scalars['String']>;
  period_not_contains?: Maybe<Scalars['String']>;
  period_starts_with?: Maybe<Scalars['String']>;
  period_not_starts_with?: Maybe<Scalars['String']>;
  period_ends_with?: Maybe<Scalars['String']>;
  period_not_ends_with?: Maybe<Scalars['String']>;
  period_i?: Maybe<Scalars['String']>;
  period_not_i?: Maybe<Scalars['String']>;
  period_contains_i?: Maybe<Scalars['String']>;
  period_not_contains_i?: Maybe<Scalars['String']>;
  period_starts_with_i?: Maybe<Scalars['String']>;
  period_not_starts_with_i?: Maybe<Scalars['String']>;
  period_ends_with_i?: Maybe<Scalars['String']>;
  period_not_ends_with_i?: Maybe<Scalars['String']>;
  period_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount?: Maybe<Scalars['String']>;
  amount_not?: Maybe<Scalars['String']>;
  amount_lt?: Maybe<Scalars['String']>;
  amount_lte?: Maybe<Scalars['String']>;
  amount_gt?: Maybe<Scalars['String']>;
  amount_gte?: Maybe<Scalars['String']>;
  amount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountAt?: Maybe<Scalars['String']>;
  amountAt_not?: Maybe<Scalars['String']>;
  amountAt_lt?: Maybe<Scalars['String']>;
  amountAt_lte?: Maybe<Scalars['String']>;
  amountAt_gt?: Maybe<Scalars['String']>;
  amountAt_gte?: Maybe<Scalars['String']>;
  amountAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedAt_not?: Maybe<Scalars['String']>;
  publishedAt_lt?: Maybe<Scalars['String']>;
  publishedAt_lte?: Maybe<Scalars['String']>;
  publishedAt_gt?: Maybe<Scalars['String']>;
  publishedAt_gte?: Maybe<Scalars['String']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalIncome?: Maybe<Scalars['String']>;
  totalIncome_not?: Maybe<Scalars['String']>;
  totalIncome_lt?: Maybe<Scalars['String']>;
  totalIncome_lte?: Maybe<Scalars['String']>;
  totalIncome_gt?: Maybe<Scalars['String']>;
  totalIncome_gte?: Maybe<Scalars['String']>;
  totalIncome_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalIncome_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalOutcome?: Maybe<Scalars['String']>;
  totalOutcome_not?: Maybe<Scalars['String']>;
  totalOutcome_lt?: Maybe<Scalars['String']>;
  totalOutcome_lte?: Maybe<Scalars['String']>;
  totalOutcome_gt?: Maybe<Scalars['String']>;
  totalOutcome_gte?: Maybe<Scalars['String']>;
  totalOutcome_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalOutcome_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isLatest?: Maybe<Scalars['Boolean']>;
  isLatest_not?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Scalars['JSON']>;
  data_not?: Maybe<Scalars['JSON']>;
  data_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  data_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankAccountReportWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankAccountReportsCreateInput = {
  data?: Maybe<BankAccountReportCreateInput>;
};

export type BankAccountReportsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportUpdateInput>;
};

export type BankAccountUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  integrationContext?: Maybe<BankIntegrationAccountContextRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  tin?: Maybe<Scalars['String']>;
  tinMeta?: Maybe<Scalars['JSON']>;
  country?: Maybe<BankAccountCountryType>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<BankAccountCurrencyCodeType>;
  isApproved?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedBy?: Maybe<UserRelateToOneInput>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  classificationCode?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankAccountWhereInput = {
  AND?: Maybe<Array<Maybe<BankAccountWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankAccountWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  integrationContext?: Maybe<BankIntegrationAccountContextWhereInput>;
  integrationContext_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tinMeta?: Maybe<Scalars['JSON']>;
  tinMeta_not?: Maybe<Scalars['JSON']>;
  tinMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  tinMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  country?: Maybe<BankAccountCountryType>;
  country_not?: Maybe<BankAccountCountryType>;
  country_in?: Maybe<Array<Maybe<BankAccountCountryType>>>;
  country_not_in?: Maybe<Array<Maybe<BankAccountCountryType>>>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumber_not?: Maybe<Scalars['String']>;
  routingNumber_contains?: Maybe<Scalars['String']>;
  routingNumber_not_contains?: Maybe<Scalars['String']>;
  routingNumber_starts_with?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with?: Maybe<Scalars['String']>;
  routingNumber_ends_with?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with?: Maybe<Scalars['String']>;
  routingNumber_i?: Maybe<Scalars['String']>;
  routingNumber_not_i?: Maybe<Scalars['String']>;
  routingNumber_contains_i?: Maybe<Scalars['String']>;
  routingNumber_not_contains_i?: Maybe<Scalars['String']>;
  routingNumber_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
  routingNumberMeta_not?: Maybe<Scalars['JSON']>;
  routingNumberMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  routingNumberMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<BankAccountCurrencyCodeType>;
  currencyCode_not?: Maybe<BankAccountCurrencyCodeType>;
  currencyCode_in?: Maybe<Array<Maybe<BankAccountCurrencyCodeType>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<BankAccountCurrencyCodeType>>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isApproved_not?: Maybe<Scalars['Boolean']>;
  approvedAt?: Maybe<Scalars['String']>;
  approvedAt_not?: Maybe<Scalars['String']>;
  approvedAt_lt?: Maybe<Scalars['String']>;
  approvedAt_lte?: Maybe<Scalars['String']>;
  approvedAt_gt?: Maybe<Scalars['String']>;
  approvedAt_gte?: Maybe<Scalars['String']>;
  approvedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  approvedBy?: Maybe<UserWhereInput>;
  approvedBy_is_null?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode?: Maybe<Scalars['String']>;
  territoryCode_not?: Maybe<Scalars['String']>;
  territoryCode_contains?: Maybe<Scalars['String']>;
  territoryCode_not_contains?: Maybe<Scalars['String']>;
  territoryCode_starts_with?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with?: Maybe<Scalars['String']>;
  territoryCode_ends_with?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with?: Maybe<Scalars['String']>;
  territoryCode_i?: Maybe<Scalars['String']>;
  territoryCode_not_i?: Maybe<Scalars['String']>;
  territoryCode_contains_i?: Maybe<Scalars['String']>;
  territoryCode_not_contains_i?: Maybe<Scalars['String']>;
  territoryCode_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  bankName_i?: Maybe<Scalars['String']>;
  bankName_not_i?: Maybe<Scalars['String']>;
  bankName_contains_i?: Maybe<Scalars['String']>;
  bankName_not_contains_i?: Maybe<Scalars['String']>;
  bankName_starts_with_i?: Maybe<Scalars['String']>;
  bankName_not_starts_with_i?: Maybe<Scalars['String']>;
  bankName_ends_with_i?: Maybe<Scalars['String']>;
  bankName_not_ends_with_i?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  classificationCode?: Maybe<Scalars['String']>;
  classificationCode_not?: Maybe<Scalars['String']>;
  classificationCode_contains?: Maybe<Scalars['String']>;
  classificationCode_not_contains?: Maybe<Scalars['String']>;
  classificationCode_starts_with?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with?: Maybe<Scalars['String']>;
  classificationCode_ends_with?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with?: Maybe<Scalars['String']>;
  classificationCode_i?: Maybe<Scalars['String']>;
  classificationCode_not_i?: Maybe<Scalars['String']>;
  classificationCode_contains_i?: Maybe<Scalars['String']>;
  classificationCode_not_contains_i?: Maybe<Scalars['String']>;
  classificationCode_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankAccountWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankAccountsCreateInput = {
  data?: Maybe<BankAccountCreateInput>;
};

export type BankAccountsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountUpdateInput>;
};

export type BankCategoriesCreateInput = {
  data?: Maybe<BankCategoryCreateInput>;
};

export type BankCategoriesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankCategoryUpdateInput>;
};

/**  Expenses category, that will be associated with transactions and contractors  */
export type BankCategory = {
  __typename?: 'BankCategory';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCategory List config, or
   *  3. As an alias to a 'name' field on the BankCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCategory List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Key for i18n  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankCategoryCreateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankCategoryHistoryRecord = {
  __typename?: 'BankCategoryHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCategoryHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankCategoryHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankCategoryHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankCategoryHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankCategoryHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankCategoryHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankCategoryHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankCategoryHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankCategoryHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankCategoryHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankCategoryHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankCategoryHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankCategoryHistoryRecordsCreateInput = {
  data?: Maybe<BankCategoryHistoryRecordCreateInput>;
};

export type BankCategoryHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankCategoryHistoryRecordUpdateInput>;
};

export type BankCategoryRelateToOneInput = {
  create?: Maybe<BankCategoryCreateInput>;
  connect?: Maybe<BankCategoryWhereUniqueInput>;
  disconnect?: Maybe<BankCategoryWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BankCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankCategoryWhereInput = {
  AND?: Maybe<Array<Maybe<BankCategoryWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankCategoryWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankCategoryWhereUniqueInput = {
  id: Scalars['ID'];
};

/**  Account for external contractor, which organization is not stored in condo. Contractor is related to expenses transactions of an Organization. Will be obtained from export files with bank transactions or from banking API  */
export type BankContractorAccount = {
  __typename?: 'BankContractorAccount';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankContractorAccount List config, or
   *  2. As an alias to the field set on 'labelField' in the BankContractorAccount List config, or
   *  3. As an alias to a 'name' field on the BankContractorAccount List (if one exists), or
   *  4. As an alias to the 'id' field on the BankContractorAccount List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of contractor organization  */
  name?: Maybe<Scalars['String']>;
  /**  Technical link to property management organization, that has expenses transactions with this contractor. Used for access rights and cascade behaviour of data. Contractor organization is not stored in condo. The object will be deleted if the related organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Related cost item, that will determine cost items for transactions belonging to this contractor  */
  costItem?: Maybe<BankCostItem>;
  /**  Tax Identification Number  */
  tin?: Maybe<Scalars['String']>;
  /**  Country where the bank is located  */
  country?: Maybe<Scalars['String']>;
  /**  The routing transit number for the bank account.  */
  routingNumber?: Maybe<Scalars['String']>;
  /**  Bank account number  */
  number?: Maybe<Scalars['String']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<BankContractorAccountCurrencyCodeType>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  /**  Location of the holder of this bank account. It depends on a country. In Russia it is OKTMO  */
  territoryCode?: Maybe<Scalars['String']>;
  /**  Name of bank  */
  bankName?: Maybe<Scalars['String']>;
  /**  Structured non-typed metadata, can be used by mini-apps or external services to store information  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Number of related transactions without costItem for this contractor account  */
  relatedTransactions?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankContractorAccountCreateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  costItem?: Maybe<BankCostItemRelateToOneInput>;
  tin?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<BankContractorAccountCurrencyCodeType>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum BankContractorAccountCurrencyCodeType {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

/**  A keystone list  */
export type BankContractorAccountHistoryRecord = {
  __typename?: 'BankContractorAccountHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankContractorAccountHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankContractorAccountHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankContractorAccountHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankContractorAccountHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankContractorAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankContractorAccountHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankContractorAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankContractorAccountHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankContractorAccountHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankContractorAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankContractorAccountHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankContractorAccountHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankContractorAccountHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  costItem?: Maybe<Scalars['String']>;
  costItem_not?: Maybe<Scalars['String']>;
  costItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  costItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Scalars['String']>;
  country_not?: Maybe<Scalars['String']>;
  country_contains?: Maybe<Scalars['String']>;
  country_not_contains?: Maybe<Scalars['String']>;
  country_starts_with?: Maybe<Scalars['String']>;
  country_not_starts_with?: Maybe<Scalars['String']>;
  country_ends_with?: Maybe<Scalars['String']>;
  country_not_ends_with?: Maybe<Scalars['String']>;
  country_i?: Maybe<Scalars['String']>;
  country_not_i?: Maybe<Scalars['String']>;
  country_contains_i?: Maybe<Scalars['String']>;
  country_not_contains_i?: Maybe<Scalars['String']>;
  country_starts_with_i?: Maybe<Scalars['String']>;
  country_not_starts_with_i?: Maybe<Scalars['String']>;
  country_ends_with_i?: Maybe<Scalars['String']>;
  country_not_ends_with_i?: Maybe<Scalars['String']>;
  country_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumber_not?: Maybe<Scalars['String']>;
  routingNumber_contains?: Maybe<Scalars['String']>;
  routingNumber_not_contains?: Maybe<Scalars['String']>;
  routingNumber_starts_with?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with?: Maybe<Scalars['String']>;
  routingNumber_ends_with?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with?: Maybe<Scalars['String']>;
  routingNumber_i?: Maybe<Scalars['String']>;
  routingNumber_not_i?: Maybe<Scalars['String']>;
  routingNumber_contains_i?: Maybe<Scalars['String']>;
  routingNumber_not_contains_i?: Maybe<Scalars['String']>;
  routingNumber_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_not?: Maybe<Scalars['String']>;
  currencyCode_contains?: Maybe<Scalars['String']>;
  currencyCode_not_contains?: Maybe<Scalars['String']>;
  currencyCode_starts_with?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with?: Maybe<Scalars['String']>;
  currencyCode_ends_with?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with?: Maybe<Scalars['String']>;
  currencyCode_i?: Maybe<Scalars['String']>;
  currencyCode_not_i?: Maybe<Scalars['String']>;
  currencyCode_contains_i?: Maybe<Scalars['String']>;
  currencyCode_not_contains_i?: Maybe<Scalars['String']>;
  currencyCode_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode?: Maybe<Scalars['String']>;
  territoryCode_not?: Maybe<Scalars['String']>;
  territoryCode_contains?: Maybe<Scalars['String']>;
  territoryCode_not_contains?: Maybe<Scalars['String']>;
  territoryCode_starts_with?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with?: Maybe<Scalars['String']>;
  territoryCode_ends_with?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with?: Maybe<Scalars['String']>;
  territoryCode_i?: Maybe<Scalars['String']>;
  territoryCode_not_i?: Maybe<Scalars['String']>;
  territoryCode_contains_i?: Maybe<Scalars['String']>;
  territoryCode_not_contains_i?: Maybe<Scalars['String']>;
  territoryCode_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  bankName_i?: Maybe<Scalars['String']>;
  bankName_not_i?: Maybe<Scalars['String']>;
  bankName_contains_i?: Maybe<Scalars['String']>;
  bankName_not_contains_i?: Maybe<Scalars['String']>;
  bankName_starts_with_i?: Maybe<Scalars['String']>;
  bankName_not_starts_with_i?: Maybe<Scalars['String']>;
  bankName_ends_with_i?: Maybe<Scalars['String']>;
  bankName_not_ends_with_i?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankContractorAccountHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankContractorAccountHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankContractorAccountHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankContractorAccountHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankContractorAccountHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankContractorAccountHistoryRecordsCreateInput = {
  data?: Maybe<BankContractorAccountHistoryRecordCreateInput>;
};

export type BankContractorAccountHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankContractorAccountHistoryRecordUpdateInput>;
};

export type BankContractorAccountRelateToOneInput = {
  create?: Maybe<BankContractorAccountCreateInput>;
  connect?: Maybe<BankContractorAccountWhereUniqueInput>;
  disconnect?: Maybe<BankContractorAccountWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BankContractorAccountUpdateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  costItem?: Maybe<BankCostItemRelateToOneInput>;
  tin?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<BankContractorAccountCurrencyCodeType>;
  importId?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankContractorAccountWhereInput = {
  AND?: Maybe<Array<Maybe<BankContractorAccountWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankContractorAccountWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  costItem?: Maybe<BankCostItemWhereInput>;
  costItem_is_null?: Maybe<Scalars['Boolean']>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Scalars['String']>;
  country_not?: Maybe<Scalars['String']>;
  country_contains?: Maybe<Scalars['String']>;
  country_not_contains?: Maybe<Scalars['String']>;
  country_starts_with?: Maybe<Scalars['String']>;
  country_not_starts_with?: Maybe<Scalars['String']>;
  country_ends_with?: Maybe<Scalars['String']>;
  country_not_ends_with?: Maybe<Scalars['String']>;
  country_i?: Maybe<Scalars['String']>;
  country_not_i?: Maybe<Scalars['String']>;
  country_contains_i?: Maybe<Scalars['String']>;
  country_not_contains_i?: Maybe<Scalars['String']>;
  country_starts_with_i?: Maybe<Scalars['String']>;
  country_not_starts_with_i?: Maybe<Scalars['String']>;
  country_ends_with_i?: Maybe<Scalars['String']>;
  country_not_ends_with_i?: Maybe<Scalars['String']>;
  country_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber?: Maybe<Scalars['String']>;
  routingNumber_not?: Maybe<Scalars['String']>;
  routingNumber_contains?: Maybe<Scalars['String']>;
  routingNumber_not_contains?: Maybe<Scalars['String']>;
  routingNumber_starts_with?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with?: Maybe<Scalars['String']>;
  routingNumber_ends_with?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with?: Maybe<Scalars['String']>;
  routingNumber_i?: Maybe<Scalars['String']>;
  routingNumber_not_i?: Maybe<Scalars['String']>;
  routingNumber_contains_i?: Maybe<Scalars['String']>;
  routingNumber_not_contains_i?: Maybe<Scalars['String']>;
  routingNumber_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  routingNumber_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  routingNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  routingNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<BankContractorAccountCurrencyCodeType>;
  currencyCode_not?: Maybe<BankContractorAccountCurrencyCodeType>;
  currencyCode_in?: Maybe<Array<Maybe<BankContractorAccountCurrencyCodeType>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<BankContractorAccountCurrencyCodeType>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode?: Maybe<Scalars['String']>;
  territoryCode_not?: Maybe<Scalars['String']>;
  territoryCode_contains?: Maybe<Scalars['String']>;
  territoryCode_not_contains?: Maybe<Scalars['String']>;
  territoryCode_starts_with?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with?: Maybe<Scalars['String']>;
  territoryCode_ends_with?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with?: Maybe<Scalars['String']>;
  territoryCode_i?: Maybe<Scalars['String']>;
  territoryCode_not_i?: Maybe<Scalars['String']>;
  territoryCode_contains_i?: Maybe<Scalars['String']>;
  territoryCode_not_contains_i?: Maybe<Scalars['String']>;
  territoryCode_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  bankName_i?: Maybe<Scalars['String']>;
  bankName_not_i?: Maybe<Scalars['String']>;
  bankName_contains_i?: Maybe<Scalars['String']>;
  bankName_not_contains_i?: Maybe<Scalars['String']>;
  bankName_starts_with_i?: Maybe<Scalars['String']>;
  bankName_not_starts_with_i?: Maybe<Scalars['String']>;
  bankName_ends_with_i?: Maybe<Scalars['String']>;
  bankName_not_ends_with_i?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankContractorAccountWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankContractorAccountsCreateInput = {
  data?: Maybe<BankContractorAccountCreateInput>;
};

export type BankContractorAccountsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankContractorAccountUpdateInput>;
};

/**  Expenses classification item for BankTransaction. Will be determined by automatic classification feature for each transaction  */
export type BankCostItem = {
  __typename?: 'BankCostItem';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCostItem List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCostItem List config, or
   *  3. As an alias to a 'name' field on the BankCostItem List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCostItem List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of expenses item as key for i18n  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  /**  Indicator of cost item, corresponding to outcome transactions. Outcome-related cost item cannot be assigned to income-transaction  */
  isOutcome?: Maybe<Scalars['Boolean']>;
  /**  Used only for grouping to display in UI. Does not used in automatic classification feature  */
  category?: Maybe<BankCategory>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankCostItemCreateInput = {
  name?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BankCategoryRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankCostItemHistoryRecord = {
  __typename?: 'BankCostItemHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankCostItemHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankCostItemHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankCostItemHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankCostItemHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankCostItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankCostItemHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankCostItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankCostItemHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankCostItemHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankCostItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankCostItemHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankCostItemHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankCostItemHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  isOutcome_not?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankCostItemHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankCostItemHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankCostItemHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankCostItemHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankCostItemHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankCostItemHistoryRecordsCreateInput = {
  data?: Maybe<BankCostItemHistoryRecordCreateInput>;
};

export type BankCostItemHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankCostItemHistoryRecordUpdateInput>;
};

export type BankCostItemRelateToOneInput = {
  create?: Maybe<BankCostItemCreateInput>;
  connect?: Maybe<BankCostItemWhereUniqueInput>;
  disconnect?: Maybe<BankCostItemWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BankCostItemUpdateInput = {
  name?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BankCategoryRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankCostItemWhereInput = {
  AND?: Maybe<Array<Maybe<BankCostItemWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankCostItemWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  isOutcome_not?: Maybe<Scalars['Boolean']>;
  category?: Maybe<BankCategoryWhereInput>;
  category_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankCostItemWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankCostItemsCreateInput = {
  data?: Maybe<BankCostItemCreateInput>;
};

export type BankCostItemsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankCostItemUpdateInput>;
};

/**  Determines way of obtaining banking data  */
export type BankIntegration = {
  __typename?: 'BankIntegration';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegration List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegration List config, or
   *  3. As an alias to a 'name' field on the BankIntegration List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegration List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of integration  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  accessRights: Array<BankIntegrationAccessRight>;
  _accessRightsMeta?: Maybe<_QueryMeta>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  Determines way of obtaining banking data  */
export type BankIntegrationAccessRightsArgs = {
  where?: Maybe<BankIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  Determines way of obtaining banking data  */
export type BankIntegration_AccessRightsMetaArgs = {
  where?: Maybe<BankIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/**  Gives ability to service-user to access all schema records, connected to specified integration  */
export type BankIntegrationAccessRight = {
  __typename?: 'BankIntegrationAccessRight';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccessRight List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccessRight List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Specified integration, whose connected entities will be available for specified service user  */
  integration?: Maybe<BankIntegration>;
  /**  Link to user. Note, that user must be of "service" type  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankIntegrationAccessRightCreateInput = {
  integration?: Maybe<BankIntegrationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankIntegrationAccessRightHistoryRecord = {
  __typename?: 'BankIntegrationAccessRightHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccessRightHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationAccessRightHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankIntegrationAccessRightHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankIntegrationAccessRightHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationAccessRightHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankIntegrationAccessRightHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationAccessRightHistoryRecordsCreateInput = {
  data?: Maybe<BankIntegrationAccessRightHistoryRecordCreateInput>;
};

export type BankIntegrationAccessRightHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccessRightHistoryRecordUpdateInput>;
};

export type BankIntegrationAccessRightRelateToManyInput = {
  create?: Maybe<Array<Maybe<BankIntegrationAccessRightCreateInput>>>;
  connect?: Maybe<Array<Maybe<BankIntegrationAccessRightWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<BankIntegrationAccessRightWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BankIntegrationAccessRightUpdateInput = {
  integration?: Maybe<BankIntegrationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankIntegrationAccessRightWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationAccessRightWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationAccessRightWhereInput>>>;
  integration?: Maybe<BankIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankIntegrationAccessRightWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationAccessRightsCreateInput = {
  data?: Maybe<BankIntegrationAccessRightCreateInput>;
};

export type BankIntegrationAccessRightsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccessRightUpdateInput>;
};

/**  Usage of specific integration by specific organization. Contains summary information about last synchronization with data source (integration)  */
export type BankIntegrationAccountContext = {
  __typename?: 'BankIntegrationAccountContext';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccountContext List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccountContext List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccountContext List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccountContext List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Data source, used for this integration.  */
  integration?: Maybe<BankIntegration>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Controls availability of sync operation for this integration. Can be disabled by support in some cases, when client dont wants to have automatic synchronization via API, for example  */
  enabled?: Maybe<Scalars['Boolean']>;
  /**  Structured non-typed metadata, can be used by mini-apps or external services to store information  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankIntegrationAccountContextCreateInput = {
  integration?: Maybe<BankIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankIntegrationAccountContextHistoryRecord = {
  __typename?: 'BankIntegrationAccountContextHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationAccountContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationAccountContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationAccountContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationAccountContextHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationAccountContextHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankIntegrationAccountContextHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankIntegrationAccountContextHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationAccountContextHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabled_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankIntegrationAccountContextHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationAccountContextHistoryRecordsCreateInput = {
  data?: Maybe<BankIntegrationAccountContextHistoryRecordCreateInput>;
};

export type BankIntegrationAccountContextHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccountContextHistoryRecordUpdateInput>;
};

export type BankIntegrationAccountContextRelateToOneInput = {
  create?: Maybe<BankIntegrationAccountContextCreateInput>;
  connect?: Maybe<BankIntegrationAccountContextWhereUniqueInput>;
  disconnect?: Maybe<BankIntegrationAccountContextWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BankIntegrationAccountContextUpdateInput = {
  integration?: Maybe<BankIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankIntegrationAccountContextWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationAccountContextWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationAccountContextWhereInput>>>;
  integration?: Maybe<BankIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabled_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankIntegrationAccountContextWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationAccountContextsCreateInput = {
  data?: Maybe<BankIntegrationAccountContextCreateInput>;
};

export type BankIntegrationAccountContextsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccountContextUpdateInput>;
};

export type BankIntegrationCreateInput = {
  name?: Maybe<Scalars['String']>;
  accessRights?: Maybe<BankIntegrationAccessRightRelateToManyInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankIntegrationHistoryRecord = {
  __typename?: 'BankIntegrationHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankIntegrationHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankIntegrationHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankIntegrationHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankIntegrationHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankIntegrationHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankIntegrationHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankIntegrationHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationHistoryRecordsCreateInput = {
  data?: Maybe<BankIntegrationHistoryRecordCreateInput>;
};

export type BankIntegrationHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationHistoryRecordUpdateInput>;
};

/**  Stores the integrations connected to the organization  */
export type BankIntegrationOrganizationContext = {
  __typename?: 'BankIntegrationOrganizationContext';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationOrganizationContext List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationOrganizationContext List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationOrganizationContext List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationOrganizationContext List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Data source, used for this integration.  */
  integration?: Maybe<BankIntegration>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Controls availability of sync operation for this integration in scope of organization. Can be disabled by support in some cases, when client dont wants to have automatic synchronization via API, for example  */
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankIntegrationOrganizationContextCreateInput = {
  integration?: Maybe<BankIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankIntegrationOrganizationContextHistoryRecord = {
  __typename?: 'BankIntegrationOrganizationContextHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankIntegrationOrganizationContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankIntegrationOrganizationContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankIntegrationOrganizationContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankIntegrationOrganizationContextHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationOrganizationContextHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankIntegrationOrganizationContextHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankIntegrationOrganizationContextHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankIntegrationOrganizationContextHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabled_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankIntegrationOrganizationContextHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationOrganizationContextHistoryRecordsCreateInput = {
  data?: Maybe<BankIntegrationOrganizationContextHistoryRecordCreateInput>;
};

export type BankIntegrationOrganizationContextHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationOrganizationContextHistoryRecordUpdateInput>;
};

export type BankIntegrationOrganizationContextUpdateInput = {
  integration?: Maybe<BankIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankIntegrationOrganizationContextWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationOrganizationContextWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationOrganizationContextWhereInput>>>;
  integration?: Maybe<BankIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabled_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankIntegrationOrganizationContextWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationOrganizationContextsCreateInput = {
  data?: Maybe<BankIntegrationOrganizationContextCreateInput>;
};

export type BankIntegrationOrganizationContextsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationOrganizationContextUpdateInput>;
};

export type BankIntegrationRelateToOneInput = {
  create?: Maybe<BankIntegrationCreateInput>;
  connect?: Maybe<BankIntegrationWhereUniqueInput>;
  disconnect?: Maybe<BankIntegrationWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BankIntegrationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  accessRights?: Maybe<BankIntegrationAccessRightRelateToManyInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankIntegrationWhereInput = {
  AND?: Maybe<Array<Maybe<BankIntegrationWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankIntegrationWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  condition must be true for all nodes  */
  accessRights_every?: Maybe<BankIntegrationAccessRightWhereInput>;
  /**  condition must be true for at least 1 node  */
  accessRights_some?: Maybe<BankIntegrationAccessRightWhereInput>;
  /**  condition must be false for all nodes  */
  accessRights_none?: Maybe<BankIntegrationAccessRightWhereInput>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankIntegrationWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankIntegrationsCreateInput = {
  data?: Maybe<BankIntegrationCreateInput>;
};

export type BankIntegrationsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationUpdateInput>;
};

/**  information about synchronization process of transactions with external source of from uploaded file  */
export type BankSyncTask = {
  __typename?: 'BankSyncTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankSyncTask List config, or
   *  2. As an alias to the field set on 'labelField' in the BankSyncTask List config, or
   *  3. As an alias to a 'name' field on the BankSyncTask List (if one exists), or
   *  4. As an alias to the 'id' field on the BankSyncTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Bank account for which current synchronization operation is performed. Can be unknown when account does not exist before import  */
  account?: Maybe<BankAccount>;
  /**  Integration context of account for which current synchronization operation is performed. Can be unknown when account and integration does not exist before import  */
  integrationContext?: Maybe<BankIntegrationAccountContext>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Property to which sync operation in this task should be related. When property is specified, new BankAccount will be connected to it if it does not exists  */
  property?: Maybe<Property>;
  /**  Status of current synchronization operation  */
  status?: Maybe<BankSyncTaskStatusType>;
  /**  File from which transactions should be imported. Currently only 1CClientBankExchange format is supported  */
  file?: Maybe<File>;
  /**  Count of transactions to import. Can be unknown in advance  */
  totalCount?: Maybe<Scalars['Int']>;
  /**  Count of transactions, that has been actually created in our database based in data from external source, determined by integration context  */
  processedCount?: Maybe<Scalars['Int']>;
  /**  User that requested this operation. Will be used for read access checks to display all tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
  user?: Maybe<User>;
  /**  Options for launching tasks for banking domain  */
  options?: Maybe<BankSyncTaskOptions>;
  /**  Additional data, specific to used integration  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankSyncTaskCreateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  integrationContext?: Maybe<BankIntegrationAccountContextRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  status?: Maybe<BankSyncTaskStatusType>;
  file?: Maybe<Scalars['Upload']>;
  totalCount?: Maybe<Scalars['Int']>;
  processedCount?: Maybe<Scalars['Int']>;
  user?: Maybe<UserRelateToOneInput>;
  options?: Maybe<BankSyncTaskOptionsInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BankSyncTaskHistoryRecord = {
  __typename?: 'BankSyncTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankSyncTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankSyncTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankSyncTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankSyncTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  totalCount?: Maybe<Scalars['Int']>;
  processedCount?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankSyncTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankSyncTaskHistoryRecordCreateInput = {
  account?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  totalCount?: Maybe<Scalars['Int']>;
  processedCount?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankSyncTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankSyncTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankSyncTaskHistoryRecordUpdateInput = {
  account?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  totalCount?: Maybe<Scalars['Int']>;
  processedCount?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankSyncTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankSyncTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankSyncTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankSyncTaskHistoryRecordWhereInput>>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integrationContext?: Maybe<Scalars['String']>;
  integrationContext_not?: Maybe<Scalars['String']>;
  integrationContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integrationContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalCount_not?: Maybe<Scalars['Int']>;
  totalCount_lt?: Maybe<Scalars['Int']>;
  totalCount_lte?: Maybe<Scalars['Int']>;
  totalCount_gt?: Maybe<Scalars['Int']>;
  totalCount_gte?: Maybe<Scalars['Int']>;
  totalCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedCount?: Maybe<Scalars['Int']>;
  processedCount_not?: Maybe<Scalars['Int']>;
  processedCount_lt?: Maybe<Scalars['Int']>;
  processedCount_lte?: Maybe<Scalars['Int']>;
  processedCount_gt?: Maybe<Scalars['Int']>;
  processedCount_gte?: Maybe<Scalars['Int']>;
  processedCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  options?: Maybe<Scalars['JSON']>;
  options_not?: Maybe<Scalars['JSON']>;
  options_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  options_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankSyncTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankSyncTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankSyncTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankSyncTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankSyncTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankSyncTaskHistoryRecordsCreateInput = {
  data?: Maybe<BankSyncTaskHistoryRecordCreateInput>;
};

export type BankSyncTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankSyncTaskHistoryRecordUpdateInput>;
};

export type BankSyncTaskOptions = {
  __typename?: 'BankSyncTaskOptions';
  type?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};

export type BankSyncTaskOptionsInput = {
  type?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};

export enum BankSyncTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type BankSyncTaskUpdateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  integrationContext?: Maybe<BankIntegrationAccountContextRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  status?: Maybe<BankSyncTaskStatusType>;
  file?: Maybe<Scalars['Upload']>;
  totalCount?: Maybe<Scalars['Int']>;
  processedCount?: Maybe<Scalars['Int']>;
  user?: Maybe<UserRelateToOneInput>;
  options?: Maybe<BankSyncTaskOptionsInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankSyncTaskWhereInput = {
  AND?: Maybe<Array<Maybe<BankSyncTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankSyncTaskWhereInput>>>;
  account?: Maybe<BankAccountWhereInput>;
  account_is_null?: Maybe<Scalars['Boolean']>;
  integrationContext?: Maybe<BankIntegrationAccountContextWhereInput>;
  integrationContext_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  status?: Maybe<BankSyncTaskStatusType>;
  status_not?: Maybe<BankSyncTaskStatusType>;
  status_in?: Maybe<Array<Maybe<BankSyncTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<BankSyncTaskStatusType>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalCount?: Maybe<Scalars['Int']>;
  totalCount_not?: Maybe<Scalars['Int']>;
  totalCount_lt?: Maybe<Scalars['Int']>;
  totalCount_lte?: Maybe<Scalars['Int']>;
  totalCount_gt?: Maybe<Scalars['Int']>;
  totalCount_gte?: Maybe<Scalars['Int']>;
  totalCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedCount?: Maybe<Scalars['Int']>;
  processedCount_not?: Maybe<Scalars['Int']>;
  processedCount_lt?: Maybe<Scalars['Int']>;
  processedCount_lte?: Maybe<Scalars['Int']>;
  processedCount_gt?: Maybe<Scalars['Int']>;
  processedCount_gte?: Maybe<Scalars['Int']>;
  processedCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  options?: Maybe<BankSyncTaskOptionsInput>;
  options_not?: Maybe<BankSyncTaskOptionsInput>;
  options_in?: Maybe<Array<Maybe<BankSyncTaskOptionsInput>>>;
  options_not_in?: Maybe<Array<Maybe<BankSyncTaskOptionsInput>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankSyncTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankSyncTasksCreateInput = {
  data?: Maybe<BankSyncTaskCreateInput>;
};

export type BankSyncTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankSyncTaskUpdateInput>;
};

/**  Transaction related to costs of Organization with BankAccount. Full fields set from data import will be stored in "meta"  */
export type BankTransaction = {
  __typename?: 'BankTransaction';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankTransaction List config, or
   *  2. As an alias to the field set on 'labelField' in the BankTransaction List config, or
   *  3. As an alias to a 'name' field on the BankTransaction List (if one exists), or
   *  4. As an alias to the 'id' field on the BankTransaction List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Related BankAccount of Organization which payed  */
  account?: Maybe<BankAccount>;
  /**  Data source from which this transaction was created  */
  integrationContext?: Maybe<BankIntegrationAccountContext>;
  /**  Related account of contractor, which has received the payment via this transaction  */
  contractorAccount?: Maybe<BankContractorAccount>;
  /**  Related costs class  */
  costItem?: Maybe<BankCostItem>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Number of transaction, obtained from external system  */
  number?: Maybe<Scalars['String']>;
  /**  When payment order was created  */
  date?: Maybe<Scalars['String']>;
  /**  Amount of transaction in specified currency. Always positive number. Look at "isOutcome" field to determine whether this transaction commits negative or positive change to balance  */
  amount?: Maybe<Scalars['String']>;
  /**  Indicator of outcome transaction which commits negative change to balance  */
  isOutcome?: Maybe<Scalars['Boolean']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<BankTransactionCurrencyCodeType>;
  /**  Textual description of payment purpose in free form  */
  purpose?: Maybe<Scalars['String']>;
  /**  Stores data, obtained from external source  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Identifier of corresponding record in external system, from that this record was copied  */
  importId?: Maybe<Scalars['String']>;
  /**  Name of external system, from that this record was copied  */
  importRemoteSystem?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BankTransactionCreateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  integrationContext?: Maybe<BankIntegrationAccountContextRelateToOneInput>;
  contractorAccount?: Maybe<BankContractorAccountRelateToOneInput>;
  costItem?: Maybe<BankCostItemRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<BankTransactionCurrencyCodeType>;
  purpose?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum BankTransactionCurrencyCodeType {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

/**  A keystone list  */
export type BankTransactionHistoryRecord = {
  __typename?: 'BankTransactionHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BankTransactionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BankTransactionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BankTransactionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BankTransactionHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  contractorAccount?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankTransactionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankTransactionHistoryRecordCreateInput = {
  account?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  contractorAccount?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankTransactionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BankTransactionHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BankTransactionHistoryRecordUpdateInput = {
  account?: Maybe<Scalars['String']>;
  integrationContext?: Maybe<Scalars['String']>;
  contractorAccount?: Maybe<Scalars['String']>;
  costItem?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BankTransactionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BankTransactionHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BankTransactionHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankTransactionHistoryRecordWhereInput>>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integrationContext?: Maybe<Scalars['String']>;
  integrationContext_not?: Maybe<Scalars['String']>;
  integrationContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integrationContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractorAccount?: Maybe<Scalars['String']>;
  contractorAccount_not?: Maybe<Scalars['String']>;
  contractorAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractorAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  costItem?: Maybe<Scalars['String']>;
  costItem_not?: Maybe<Scalars['String']>;
  costItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  costItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount?: Maybe<Scalars['String']>;
  amount_not?: Maybe<Scalars['String']>;
  amount_lt?: Maybe<Scalars['String']>;
  amount_lte?: Maybe<Scalars['String']>;
  amount_gt?: Maybe<Scalars['String']>;
  amount_gte?: Maybe<Scalars['String']>;
  amount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  isOutcome_not?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_not?: Maybe<Scalars['String']>;
  currencyCode_contains?: Maybe<Scalars['String']>;
  currencyCode_not_contains?: Maybe<Scalars['String']>;
  currencyCode_starts_with?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with?: Maybe<Scalars['String']>;
  currencyCode_ends_with?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with?: Maybe<Scalars['String']>;
  currencyCode_i?: Maybe<Scalars['String']>;
  currencyCode_not_i?: Maybe<Scalars['String']>;
  currencyCode_contains_i?: Maybe<Scalars['String']>;
  currencyCode_not_contains_i?: Maybe<Scalars['String']>;
  currencyCode_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose?: Maybe<Scalars['String']>;
  purpose_not?: Maybe<Scalars['String']>;
  purpose_contains?: Maybe<Scalars['String']>;
  purpose_not_contains?: Maybe<Scalars['String']>;
  purpose_starts_with?: Maybe<Scalars['String']>;
  purpose_not_starts_with?: Maybe<Scalars['String']>;
  purpose_ends_with?: Maybe<Scalars['String']>;
  purpose_not_ends_with?: Maybe<Scalars['String']>;
  purpose_i?: Maybe<Scalars['String']>;
  purpose_not_i?: Maybe<Scalars['String']>;
  purpose_contains_i?: Maybe<Scalars['String']>;
  purpose_not_contains_i?: Maybe<Scalars['String']>;
  purpose_starts_with_i?: Maybe<Scalars['String']>;
  purpose_not_starts_with_i?: Maybe<Scalars['String']>;
  purpose_ends_with_i?: Maybe<Scalars['String']>;
  purpose_not_ends_with_i?: Maybe<Scalars['String']>;
  purpose_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BankTransactionHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BankTransactionHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BankTransactionHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BankTransactionHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BankTransactionHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankTransactionHistoryRecordsCreateInput = {
  data?: Maybe<BankTransactionHistoryRecordCreateInput>;
};

export type BankTransactionHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankTransactionHistoryRecordUpdateInput>;
};

export type BankTransactionUpdateInput = {
  account?: Maybe<BankAccountRelateToOneInput>;
  integrationContext?: Maybe<BankIntegrationAccountContextRelateToOneInput>;
  contractorAccount?: Maybe<BankContractorAccountRelateToOneInput>;
  costItem?: Maybe<BankCostItemRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<BankTransactionCurrencyCodeType>;
  purpose?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BankTransactionWhereInput = {
  AND?: Maybe<Array<Maybe<BankTransactionWhereInput>>>;
  OR?: Maybe<Array<Maybe<BankTransactionWhereInput>>>;
  account?: Maybe<BankAccountWhereInput>;
  account_is_null?: Maybe<Scalars['Boolean']>;
  integrationContext?: Maybe<BankIntegrationAccountContextWhereInput>;
  integrationContext_is_null?: Maybe<Scalars['Boolean']>;
  contractorAccount?: Maybe<BankContractorAccountWhereInput>;
  contractorAccount_is_null?: Maybe<Scalars['Boolean']>;
  costItem?: Maybe<BankCostItemWhereInput>;
  costItem_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount?: Maybe<Scalars['String']>;
  amount_not?: Maybe<Scalars['String']>;
  amount_lt?: Maybe<Scalars['String']>;
  amount_lte?: Maybe<Scalars['String']>;
  amount_gt?: Maybe<Scalars['String']>;
  amount_gte?: Maybe<Scalars['String']>;
  amount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isOutcome?: Maybe<Scalars['Boolean']>;
  isOutcome_not?: Maybe<Scalars['Boolean']>;
  currencyCode?: Maybe<BankTransactionCurrencyCodeType>;
  currencyCode_not?: Maybe<BankTransactionCurrencyCodeType>;
  currencyCode_in?: Maybe<Array<Maybe<BankTransactionCurrencyCodeType>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<BankTransactionCurrencyCodeType>>>;
  purpose?: Maybe<Scalars['String']>;
  purpose_not?: Maybe<Scalars['String']>;
  purpose_contains?: Maybe<Scalars['String']>;
  purpose_not_contains?: Maybe<Scalars['String']>;
  purpose_starts_with?: Maybe<Scalars['String']>;
  purpose_not_starts_with?: Maybe<Scalars['String']>;
  purpose_ends_with?: Maybe<Scalars['String']>;
  purpose_not_ends_with?: Maybe<Scalars['String']>;
  purpose_i?: Maybe<Scalars['String']>;
  purpose_not_i?: Maybe<Scalars['String']>;
  purpose_contains_i?: Maybe<Scalars['String']>;
  purpose_not_contains_i?: Maybe<Scalars['String']>;
  purpose_starts_with_i?: Maybe<Scalars['String']>;
  purpose_not_starts_with_i?: Maybe<Scalars['String']>;
  purpose_ends_with_i?: Maybe<Scalars['String']>;
  purpose_not_ends_with_i?: Maybe<Scalars['String']>;
  purpose_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BankTransactionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BankTransactionsCreateInput = {
  data?: Maybe<BankTransactionCreateInput>;
};

export type BankTransactionsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BankTransactionUpdateInput>;
};

/**  All `account` objects from `billing data source`. In close account cases, these objects should be soft deleted  */
export type BillingAccount = {
  __typename?: 'BillingAccount';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingAccount List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingAccount List config, or
   *  3. As an alias to a 'name' field on the BillingAccount List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingAccount List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Integration context  */
  context?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  /**  Raw non-structured data obtained from the `billing data source`. Used only for the internal needs of the `integration component`.  */
  raw?: Maybe<Scalars['JSON']>;
  /**  Billing property  */
  property?: Maybe<BillingProperty>;
  /**  A well-known universal identifier that allows you to identify the same objects in different systems. It may differ in different countries. Example: for Russia, the dom.gosuslugi.ru account number is used  */
  globalId?: Maybe<Scalars['String']>;
  /**  Account number  */
  number?: Maybe<Scalars['String']>;
  /**  Flat number / door number of an apartment building (property)  */
  unitName?: Maybe<Scalars['String']>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitType?: Maybe<BillingAccountUnitTypeType>;
  /**  Full name of the account holder  */
  fullName?: Maybe<Scalars['String']>;
  /**  Shows whether the billing account closed or not. When one resident leaves unit and another one went in we need to close hte old billing account.  */
  isClosed?: Maybe<Scalars['Boolean']>;
  /**  The account owner's type  */
  ownerType?: Maybe<BillingAccountOwnerTypeType>;
  /**  Structured metadata obtained from the `billing data source`. Some of this data is required for use in the `receipt template`. Examples of data keys: `property unit number`, `floor`, `entrance`, `is parking`  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingAccountCreateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  property?: Maybe<BillingPropertyRelateToOneInput>;
  globalId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<BillingAccountUnitTypeType>;
  fullName?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<BillingAccountOwnerTypeType>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BillingAccountHistoryRecord = {
  __typename?: 'BillingAccountHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingAccountHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingAccountHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingAccountHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingAccountHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingAccountHistoryRecordCreateInput = {
  context?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingAccountHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingAccountHistoryRecordUpdateInput = {
  context?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingAccountHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingAccountHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingAccountHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingAccountHistoryRecordWhereInput>>>;
  context?: Maybe<Scalars['String']>;
  context_not?: Maybe<Scalars['String']>;
  context_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['JSON']>;
  raw_not?: Maybe<Scalars['JSON']>;
  raw_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  raw_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalId?: Maybe<Scalars['String']>;
  globalId_not?: Maybe<Scalars['String']>;
  globalId_contains?: Maybe<Scalars['String']>;
  globalId_not_contains?: Maybe<Scalars['String']>;
  globalId_starts_with?: Maybe<Scalars['String']>;
  globalId_not_starts_with?: Maybe<Scalars['String']>;
  globalId_ends_with?: Maybe<Scalars['String']>;
  globalId_not_ends_with?: Maybe<Scalars['String']>;
  globalId_i?: Maybe<Scalars['String']>;
  globalId_not_i?: Maybe<Scalars['String']>;
  globalId_contains_i?: Maybe<Scalars['String']>;
  globalId_not_contains_i?: Maybe<Scalars['String']>;
  globalId_starts_with_i?: Maybe<Scalars['String']>;
  globalId_not_starts_with_i?: Maybe<Scalars['String']>;
  globalId_ends_with_i?: Maybe<Scalars['String']>;
  globalId_not_ends_with_i?: Maybe<Scalars['String']>;
  globalId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  fullName_i?: Maybe<Scalars['String']>;
  fullName_not_i?: Maybe<Scalars['String']>;
  fullName_contains_i?: Maybe<Scalars['String']>;
  fullName_not_contains_i?: Maybe<Scalars['String']>;
  fullName_starts_with_i?: Maybe<Scalars['String']>;
  fullName_not_starts_with_i?: Maybe<Scalars['String']>;
  fullName_ends_with_i?: Maybe<Scalars['String']>;
  fullName_not_ends_with_i?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fullName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isClosed_not?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<Scalars['String']>;
  ownerType_not?: Maybe<Scalars['String']>;
  ownerType_contains?: Maybe<Scalars['String']>;
  ownerType_not_contains?: Maybe<Scalars['String']>;
  ownerType_starts_with?: Maybe<Scalars['String']>;
  ownerType_not_starts_with?: Maybe<Scalars['String']>;
  ownerType_ends_with?: Maybe<Scalars['String']>;
  ownerType_not_ends_with?: Maybe<Scalars['String']>;
  ownerType_i?: Maybe<Scalars['String']>;
  ownerType_not_i?: Maybe<Scalars['String']>;
  ownerType_contains_i?: Maybe<Scalars['String']>;
  ownerType_not_contains_i?: Maybe<Scalars['String']>;
  ownerType_starts_with_i?: Maybe<Scalars['String']>;
  ownerType_not_starts_with_i?: Maybe<Scalars['String']>;
  ownerType_ends_with_i?: Maybe<Scalars['String']>;
  ownerType_not_ends_with_i?: Maybe<Scalars['String']>;
  ownerType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ownerType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingAccountHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingAccountHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingAccountHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingAccountHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingAccountHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingAccountHistoryRecordsCreateInput = {
  data?: Maybe<BillingAccountHistoryRecordCreateInput>;
};

export type BillingAccountHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingAccountHistoryRecordUpdateInput>;
};

export enum BillingAccountOwnerTypeType {
  Person = 'person',
  Company = 'company'
}

export type BillingAccountRelateToOneInput = {
  create?: Maybe<BillingAccountCreateInput>;
  connect?: Maybe<BillingAccountWhereUniqueInput>;
  disconnect?: Maybe<BillingAccountWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum BillingAccountUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type BillingAccountUpdateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  property?: Maybe<BillingPropertyRelateToOneInput>;
  globalId?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<BillingAccountUnitTypeType>;
  fullName?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<BillingAccountOwnerTypeType>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingAccountWhereInput = {
  AND?: Maybe<Array<Maybe<BillingAccountWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingAccountWhereInput>>>;
  context?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['JSON']>;
  raw_not?: Maybe<Scalars['JSON']>;
  raw_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  raw_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  property?: Maybe<BillingPropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  globalId?: Maybe<Scalars['String']>;
  globalId_not?: Maybe<Scalars['String']>;
  globalId_contains?: Maybe<Scalars['String']>;
  globalId_not_contains?: Maybe<Scalars['String']>;
  globalId_starts_with?: Maybe<Scalars['String']>;
  globalId_not_starts_with?: Maybe<Scalars['String']>;
  globalId_ends_with?: Maybe<Scalars['String']>;
  globalId_not_ends_with?: Maybe<Scalars['String']>;
  globalId_i?: Maybe<Scalars['String']>;
  globalId_not_i?: Maybe<Scalars['String']>;
  globalId_contains_i?: Maybe<Scalars['String']>;
  globalId_not_contains_i?: Maybe<Scalars['String']>;
  globalId_starts_with_i?: Maybe<Scalars['String']>;
  globalId_not_starts_with_i?: Maybe<Scalars['String']>;
  globalId_ends_with_i?: Maybe<Scalars['String']>;
  globalId_not_ends_with_i?: Maybe<Scalars['String']>;
  globalId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<BillingAccountUnitTypeType>;
  unitType_not?: Maybe<BillingAccountUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<BillingAccountUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<BillingAccountUnitTypeType>>>;
  fullName?: Maybe<Scalars['String']>;
  fullName_not?: Maybe<Scalars['String']>;
  fullName_contains?: Maybe<Scalars['String']>;
  fullName_not_contains?: Maybe<Scalars['String']>;
  fullName_starts_with?: Maybe<Scalars['String']>;
  fullName_not_starts_with?: Maybe<Scalars['String']>;
  fullName_ends_with?: Maybe<Scalars['String']>;
  fullName_not_ends_with?: Maybe<Scalars['String']>;
  fullName_i?: Maybe<Scalars['String']>;
  fullName_not_i?: Maybe<Scalars['String']>;
  fullName_contains_i?: Maybe<Scalars['String']>;
  fullName_not_contains_i?: Maybe<Scalars['String']>;
  fullName_starts_with_i?: Maybe<Scalars['String']>;
  fullName_not_starts_with_i?: Maybe<Scalars['String']>;
  fullName_ends_with_i?: Maybe<Scalars['String']>;
  fullName_not_ends_with_i?: Maybe<Scalars['String']>;
  fullName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fullName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isClosed_not?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<BillingAccountOwnerTypeType>;
  ownerType_not?: Maybe<BillingAccountOwnerTypeType>;
  ownerType_in?: Maybe<Array<Maybe<BillingAccountOwnerTypeType>>>;
  ownerType_not_in?: Maybe<Array<Maybe<BillingAccountOwnerTypeType>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingAccountWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingAccountsCreateInput = {
  data?: Maybe<BillingAccountCreateInput>;
};

export type BillingAccountsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingAccountUpdateInput>;
};

export type BillingCategoriesCreateInput = {
  data?: Maybe<BillingCategoryCreateInput>;
};

export type BillingCategoriesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingCategoryUpdateInput>;
};

/**  Receipt category - used primarily in display purposes  */
export type BillingCategory = {
  __typename?: 'BillingCategory';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingCategory List config, or
   *  3. As an alias to a 'name' field on the BillingCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingCategory List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Localized name of billing category: Hot water, Cold water, Housing Services  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  /**  Possible names of services to auto-detect receipt category  */
  serviceNames?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingCategoryCreateInput = {
  name?: Maybe<Scalars['String']>;
  serviceNames?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BillingCategoryHistoryRecord = {
  __typename?: 'BillingCategoryHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingCategoryHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceNames?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingCategoryHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  serviceNames?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingCategoryHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingCategoryHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  serviceNames?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingCategoryHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingCategoryHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingCategoryHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceNames?: Maybe<Scalars['JSON']>;
  serviceNames_not?: Maybe<Scalars['JSON']>;
  serviceNames_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  serviceNames_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingCategoryHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingCategoryHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingCategoryHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingCategoryHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingCategoryHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingCategoryHistoryRecordsCreateInput = {
  data?: Maybe<BillingCategoryHistoryRecordCreateInput>;
};

export type BillingCategoryHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingCategoryHistoryRecordUpdateInput>;
};

export type BillingCategoryRelateToOneInput = {
  create?: Maybe<BillingCategoryCreateInput>;
  connect?: Maybe<BillingCategoryWhereUniqueInput>;
  disconnect?: Maybe<BillingCategoryWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  serviceNames?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingCategoryWhereInput = {
  AND?: Maybe<Array<Maybe<BillingCategoryWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingCategoryWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceNames?: Maybe<Scalars['JSON']>;
  serviceNames_not?: Maybe<Scalars['JSON']>;
  serviceNames_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  serviceNames_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingCategoryWhereUniqueInput = {
  id: Scalars['ID'];
};

/**  Identification of the `integration component` which responsible for getting data from the `billing data source` and delivering the data to `this API`. Examples: tap-1c, ...   */
export type BillingIntegration = {
  __typename?: 'BillingIntegration';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegration List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegration List config, or
   *  3. As an alias to a 'name' field on the BillingIntegration List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegration List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The name of the `integration component` that the developer remembers  */
  name?: Maybe<Scalars['String']>;
  /**  Logo of integration's company or integration itself  */
  logo?: Maybe<File>;
  /**  Short description / catch phrase providing information about integration functionality. Will be shown on App's card  */
  shortDescription?: Maybe<Scalars['String']>;
  /**  Short text describing integration target group. Like The type of organization for which this integration is best suited.  */
  targetDescription?: Maybe<Scalars['String']>;
  /**  Controls text appearance in the integration banner  */
  bannerTextColor?: Maybe<BillingIntegrationBannerTextColorType>;
  /**  Background color of banner in the integration modal. Can be hex code or linear gradient.  */
  bannerColor?: Maybe<Scalars['String']>;
  /**  Image shown in the integration banner  */
  bannerPromoImage?: Maybe<File>;
  /**  A short sentence describing how long it usually takes for integration to upload receipts. Appears under correlated title in the integration modal  */
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  /**  Text describing app functionality, connection process and pricing in full detail. Written in markdown without html tags. Will be shown on app's page  */
  detailedDescription?: Maybe<Scalars['String']>;
  /**  Url to app page, which will be opened during app connection to setup the integration. One of setupUrl and instruction fields must be filled  */
  setupUrl?: Maybe<Scalars['String']>;
  /**  Online request for validation of the account number  */
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  /**  Online request to search organizations for the address  */
  checkAddressUrl?: Maybe<Scalars['String']>;
  /**  Short instruction for connecting the service written in markdown. Used in cases where integration has no frontend  */
  instruction?: Maybe<Scalars['String']>;
  /**  Used in cases when integration need to link external instruction or article.  */
  instructionExtraLink?: Maybe<Scalars['String']>;
  /**  Url to the application page that extends the "Accruals and Payments" section and opens in an iframe inside an additional tab named from the "billingPageTitle" or "name" field and controlled by "extendsBillingPage" flag  */
  appUrl?: Maybe<Scalars['String']>;
  /**  If this flag is set to true, then in the "Accruals and Payments" section will appear an additional tab with the "billingPageTitle" or "name" field and iframe from "appUrl" inside  */
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  /**  Used in billing section to override name of app tab  */
  billingPageTitle?: Maybe<Scalars['String']>;
  /**  Billing group which this billing is part of. Used to restrict certain billings from certain acquirings"  */
  group?: Maybe<Scalars['String']>;
  /**  The message shown to the user after the integration is connected before the first receipts are downloaded. Appeared while lastReport field of context is null  */
  connectedMessage?: Maybe<Scalars['String']>;
  /**  If specified, billing app will have a call-to-action button, which opens iframe with specified url to start receipts-uploading process. Text of a button can be overridden via "uploadMessage"  */
  uploadUrl?: Maybe<Scalars['String']>;
  /**  Overrides default "Upload receipts" message on call-to-action button  */
  uploadMessage?: Maybe<Scalars['String']>;
  /**  Status, which context will have by default after creation if no overwriting option provided  */
  contextDefaultStatus?: Maybe<BillingIntegrationContextDefaultStatusType>;
  /**  Format of the data, that is output of this integration. This field specifies the detail and size of columns. If not specified we can only show first level of detail (address, account, toPay)  */
  dataFormat?: Maybe<BillingIntegrationDataFormatField>;
  /**  Currency which this billing uses  */
  currencyCode?: Maybe<BillingIntegrationCurrencyCodeType>;
  accessRights: Array<BillingIntegrationAccessRight>;
  _accessRightsMeta?: Maybe<_QueryMeta>;
  /**  If checked, then bank account objects created by this billing are automatically approved. E.g government-controlled billing  */
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  /**  Indicates whether the integration or app is hidden inside the CRM. Used if integration is active by default or not ready to appear inside CRM  */
  isHidden?: Maybe<Scalars['Boolean']>;
  /**  If checked, then this integration's contexts' billing receipts will be skipped for BILLING_RECEIPT_AVAILABLE_NO_ACCOUNT_TYPE notifications handling logic  */
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  Identification of the `integration component` which responsible for getting data from the `billing data source` and delivering the data to `this API`. Examples: tap-1c, ...   */
export type BillingIntegrationAccessRightsArgs = {
  where?: Maybe<BillingIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  Identification of the `integration component` which responsible for getting data from the `billing data source` and delivering the data to `this API`. Examples: tap-1c, ...   */
export type BillingIntegration_AccessRightsMetaArgs = {
  where?: Maybe<BillingIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

/**  Link between billing integrations and users. The existence of the object means that there is user has access to integration  */
export type BillingIntegrationAccessRight = {
  __typename?: 'BillingIntegrationAccessRight';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationAccessRight List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationAccessRight List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Integration  */
  integration?: Maybe<BillingIntegration>;
  /**  Link to user. Note, that user must be of "service" type  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  v?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
};

export type BillingIntegrationAccessRightCreateInput = {
  integration?: Maybe<BillingIntegrationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  v?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type BillingIntegrationAccessRightHistoryRecord = {
  __typename?: 'BillingIntegrationAccessRightHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationAccessRightHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingIntegrationAccessRightHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingIntegrationAccessRightHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingIntegrationAccessRightHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingIntegrationAccessRightHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingIntegrationAccessRightHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationAccessRightHistoryRecordsCreateInput = {
  data?: Maybe<BillingIntegrationAccessRightHistoryRecordCreateInput>;
};

export type BillingIntegrationAccessRightHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationAccessRightHistoryRecordUpdateInput>;
};

export type BillingIntegrationAccessRightRelateToManyInput = {
  create?: Maybe<Array<Maybe<BillingIntegrationAccessRightCreateInput>>>;
  connect?: Maybe<Array<Maybe<BillingIntegrationAccessRightWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<BillingIntegrationAccessRightWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingIntegrationAccessRightUpdateInput = {
  integration?: Maybe<BillingIntegrationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  v?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
};

export type BillingIntegrationAccessRightWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationAccessRightWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationAccessRightWhereInput>>>;
  integration?: Maybe<BillingIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingIntegrationAccessRightWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationAccessRightsCreateInput = {
  data?: Maybe<BillingIntegrationAccessRightCreateInput>;
};

export type BillingIntegrationAccessRightsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationAccessRightUpdateInput>;
};

export enum BillingIntegrationBannerTextColorType {
  Black = 'BLACK',
  White = 'WHITE'
}

export enum BillingIntegrationContextDefaultStatusType {
  InProgress = 'InProgress',
  Error = 'Error',
  Finished = 'Finished'
}

export type BillingIntegrationCreateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
  targetDescription?: Maybe<Scalars['String']>;
  bannerTextColor?: Maybe<BillingIntegrationBannerTextColorType>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerPromoImage?: Maybe<Scalars['Upload']>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  connectedMessage?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadMessage?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<BillingIntegrationContextDefaultStatusType>;
  dataFormat?: Maybe<BillingIntegrationDataFormatFieldInput>;
  currencyCode?: Maybe<BillingIntegrationCurrencyCodeType>;
  accessRights?: Maybe<BillingIntegrationAccessRightRelateToManyInput>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum BillingIntegrationCurrencyCodeType {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export type BillingIntegrationDataFormatField = {
  __typename?: 'BillingIntegrationDataFormatField';
  hasToPayDetails: Scalars['Boolean'];
  hasServices: Scalars['Boolean'];
  hasServicesDetails: Scalars['Boolean'];
};

export type BillingIntegrationDataFormatFieldInput = {
  hasToPayDetails: Scalars['Boolean'];
  hasServices: Scalars['Boolean'];
  hasServicesDetails: Scalars['Boolean'];
};

/**  A keystone list  */
export type BillingIntegrationHistoryRecord = {
  __typename?: 'BillingIntegrationHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  targetDescription?: Maybe<Scalars['String']>;
  bannerTextColor?: Maybe<Scalars['String']>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerPromoImage?: Maybe<Scalars['JSON']>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  setupUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['JSON']>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  connectedMessage?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadMessage?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  dataFormat?: Maybe<Scalars['JSON']>;
  currencyCode?: Maybe<Scalars['String']>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingIntegrationHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  targetDescription?: Maybe<Scalars['String']>;
  bannerTextColor?: Maybe<Scalars['String']>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerPromoImage?: Maybe<Scalars['JSON']>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  setupUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['JSON']>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  connectedMessage?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadMessage?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  dataFormat?: Maybe<Scalars['JSON']>;
  currencyCode?: Maybe<Scalars['String']>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingIntegrationHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingIntegrationHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['JSON']>;
  shortDescription?: Maybe<Scalars['String']>;
  targetDescription?: Maybe<Scalars['String']>;
  bannerTextColor?: Maybe<Scalars['String']>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerPromoImage?: Maybe<Scalars['JSON']>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  setupUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['JSON']>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  connectedMessage?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadMessage?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  dataFormat?: Maybe<Scalars['JSON']>;
  currencyCode?: Maybe<Scalars['String']>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingIntegrationHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['JSON']>;
  logo_not?: Maybe<Scalars['JSON']>;
  logo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  logo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescription_not?: Maybe<Scalars['String']>;
  shortDescription_contains?: Maybe<Scalars['String']>;
  shortDescription_not_contains?: Maybe<Scalars['String']>;
  shortDescription_starts_with?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with?: Maybe<Scalars['String']>;
  shortDescription_ends_with?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with?: Maybe<Scalars['String']>;
  shortDescription_i?: Maybe<Scalars['String']>;
  shortDescription_not_i?: Maybe<Scalars['String']>;
  shortDescription_contains_i?: Maybe<Scalars['String']>;
  shortDescription_not_contains_i?: Maybe<Scalars['String']>;
  shortDescription_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetDescription?: Maybe<Scalars['String']>;
  targetDescription_not?: Maybe<Scalars['String']>;
  targetDescription_contains?: Maybe<Scalars['String']>;
  targetDescription_not_contains?: Maybe<Scalars['String']>;
  targetDescription_starts_with?: Maybe<Scalars['String']>;
  targetDescription_not_starts_with?: Maybe<Scalars['String']>;
  targetDescription_ends_with?: Maybe<Scalars['String']>;
  targetDescription_not_ends_with?: Maybe<Scalars['String']>;
  targetDescription_i?: Maybe<Scalars['String']>;
  targetDescription_not_i?: Maybe<Scalars['String']>;
  targetDescription_contains_i?: Maybe<Scalars['String']>;
  targetDescription_not_contains_i?: Maybe<Scalars['String']>;
  targetDescription_starts_with_i?: Maybe<Scalars['String']>;
  targetDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  targetDescription_ends_with_i?: Maybe<Scalars['String']>;
  targetDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  targetDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerTextColor?: Maybe<Scalars['String']>;
  bannerTextColor_not?: Maybe<Scalars['String']>;
  bannerTextColor_contains?: Maybe<Scalars['String']>;
  bannerTextColor_not_contains?: Maybe<Scalars['String']>;
  bannerTextColor_starts_with?: Maybe<Scalars['String']>;
  bannerTextColor_not_starts_with?: Maybe<Scalars['String']>;
  bannerTextColor_ends_with?: Maybe<Scalars['String']>;
  bannerTextColor_not_ends_with?: Maybe<Scalars['String']>;
  bannerTextColor_i?: Maybe<Scalars['String']>;
  bannerTextColor_not_i?: Maybe<Scalars['String']>;
  bannerTextColor_contains_i?: Maybe<Scalars['String']>;
  bannerTextColor_not_contains_i?: Maybe<Scalars['String']>;
  bannerTextColor_starts_with_i?: Maybe<Scalars['String']>;
  bannerTextColor_not_starts_with_i?: Maybe<Scalars['String']>;
  bannerTextColor_ends_with_i?: Maybe<Scalars['String']>;
  bannerTextColor_not_ends_with_i?: Maybe<Scalars['String']>;
  bannerTextColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerTextColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerColor_not?: Maybe<Scalars['String']>;
  bannerColor_contains?: Maybe<Scalars['String']>;
  bannerColor_not_contains?: Maybe<Scalars['String']>;
  bannerColor_starts_with?: Maybe<Scalars['String']>;
  bannerColor_not_starts_with?: Maybe<Scalars['String']>;
  bannerColor_ends_with?: Maybe<Scalars['String']>;
  bannerColor_not_ends_with?: Maybe<Scalars['String']>;
  bannerColor_i?: Maybe<Scalars['String']>;
  bannerColor_not_i?: Maybe<Scalars['String']>;
  bannerColor_contains_i?: Maybe<Scalars['String']>;
  bannerColor_not_contains_i?: Maybe<Scalars['String']>;
  bannerColor_starts_with_i?: Maybe<Scalars['String']>;
  bannerColor_not_starts_with_i?: Maybe<Scalars['String']>;
  bannerColor_ends_with_i?: Maybe<Scalars['String']>;
  bannerColor_not_ends_with_i?: Maybe<Scalars['String']>;
  bannerColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerPromoImage?: Maybe<Scalars['JSON']>;
  bannerPromoImage_not?: Maybe<Scalars['JSON']>;
  bannerPromoImage_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  bannerPromoImage_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not?: Maybe<Scalars['String']>;
  receiptsLoadingTime_contains?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_contains?: Maybe<Scalars['String']>;
  receiptsLoadingTime_starts_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_starts_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_ends_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_ends_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_contains_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_contains_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_starts_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_starts_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_ends_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_ends_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiptsLoadingTime_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedDescription?: Maybe<Scalars['JSON']>;
  detailedDescription_not?: Maybe<Scalars['JSON']>;
  detailedDescription_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  detailedDescription_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  setupUrl?: Maybe<Scalars['String']>;
  setupUrl_not?: Maybe<Scalars['String']>;
  setupUrl_contains?: Maybe<Scalars['String']>;
  setupUrl_not_contains?: Maybe<Scalars['String']>;
  setupUrl_starts_with?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with?: Maybe<Scalars['String']>;
  setupUrl_ends_with?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with?: Maybe<Scalars['String']>;
  setupUrl_i?: Maybe<Scalars['String']>;
  setupUrl_not_i?: Maybe<Scalars['String']>;
  setupUrl_contains_i?: Maybe<Scalars['String']>;
  setupUrl_not_contains_i?: Maybe<Scalars['String']>;
  setupUrl_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_contains?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_contains?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_starts_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_starts_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_ends_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_ends_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_contains_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_contains_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_starts_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_ends_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAccountNumberUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  checkAddressUrl_not?: Maybe<Scalars['String']>;
  checkAddressUrl_contains?: Maybe<Scalars['String']>;
  checkAddressUrl_not_contains?: Maybe<Scalars['String']>;
  checkAddressUrl_starts_with?: Maybe<Scalars['String']>;
  checkAddressUrl_not_starts_with?: Maybe<Scalars['String']>;
  checkAddressUrl_ends_with?: Maybe<Scalars['String']>;
  checkAddressUrl_not_ends_with?: Maybe<Scalars['String']>;
  checkAddressUrl_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_i?: Maybe<Scalars['String']>;
  checkAddressUrl_contains_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_contains_i?: Maybe<Scalars['String']>;
  checkAddressUrl_starts_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_ends_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddressUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instruction?: Maybe<Scalars['JSON']>;
  instruction_not?: Maybe<Scalars['JSON']>;
  instruction_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  instruction_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  instructionExtraLink_not?: Maybe<Scalars['String']>;
  instructionExtraLink_contains?: Maybe<Scalars['String']>;
  instructionExtraLink_not_contains?: Maybe<Scalars['String']>;
  instructionExtraLink_starts_with?: Maybe<Scalars['String']>;
  instructionExtraLink_not_starts_with?: Maybe<Scalars['String']>;
  instructionExtraLink_ends_with?: Maybe<Scalars['String']>;
  instructionExtraLink_not_ends_with?: Maybe<Scalars['String']>;
  instructionExtraLink_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_i?: Maybe<Scalars['String']>;
  instructionExtraLink_contains_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_contains_i?: Maybe<Scalars['String']>;
  instructionExtraLink_starts_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_starts_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_ends_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_ends_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instructionExtraLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl?: Maybe<Scalars['String']>;
  appUrl_not?: Maybe<Scalars['String']>;
  appUrl_contains?: Maybe<Scalars['String']>;
  appUrl_not_contains?: Maybe<Scalars['String']>;
  appUrl_starts_with?: Maybe<Scalars['String']>;
  appUrl_not_starts_with?: Maybe<Scalars['String']>;
  appUrl_ends_with?: Maybe<Scalars['String']>;
  appUrl_not_ends_with?: Maybe<Scalars['String']>;
  appUrl_i?: Maybe<Scalars['String']>;
  appUrl_not_i?: Maybe<Scalars['String']>;
  appUrl_contains_i?: Maybe<Scalars['String']>;
  appUrl_not_contains_i?: Maybe<Scalars['String']>;
  appUrl_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  extendsBillingPage_not?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  billingPageTitle_not?: Maybe<Scalars['String']>;
  billingPageTitle_contains?: Maybe<Scalars['String']>;
  billingPageTitle_not_contains?: Maybe<Scalars['String']>;
  billingPageTitle_starts_with?: Maybe<Scalars['String']>;
  billingPageTitle_not_starts_with?: Maybe<Scalars['String']>;
  billingPageTitle_ends_with?: Maybe<Scalars['String']>;
  billingPageTitle_not_ends_with?: Maybe<Scalars['String']>;
  billingPageTitle_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_i?: Maybe<Scalars['String']>;
  billingPageTitle_contains_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_contains_i?: Maybe<Scalars['String']>;
  billingPageTitle_starts_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_starts_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_ends_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_ends_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingPageTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  group?: Maybe<Scalars['String']>;
  group_not?: Maybe<Scalars['String']>;
  group_contains?: Maybe<Scalars['String']>;
  group_not_contains?: Maybe<Scalars['String']>;
  group_starts_with?: Maybe<Scalars['String']>;
  group_not_starts_with?: Maybe<Scalars['String']>;
  group_ends_with?: Maybe<Scalars['String']>;
  group_not_ends_with?: Maybe<Scalars['String']>;
  group_i?: Maybe<Scalars['String']>;
  group_not_i?: Maybe<Scalars['String']>;
  group_contains_i?: Maybe<Scalars['String']>;
  group_not_contains_i?: Maybe<Scalars['String']>;
  group_starts_with_i?: Maybe<Scalars['String']>;
  group_not_starts_with_i?: Maybe<Scalars['String']>;
  group_ends_with_i?: Maybe<Scalars['String']>;
  group_not_ends_with_i?: Maybe<Scalars['String']>;
  group_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  group_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectedMessage?: Maybe<Scalars['String']>;
  connectedMessage_not?: Maybe<Scalars['String']>;
  connectedMessage_contains?: Maybe<Scalars['String']>;
  connectedMessage_not_contains?: Maybe<Scalars['String']>;
  connectedMessage_starts_with?: Maybe<Scalars['String']>;
  connectedMessage_not_starts_with?: Maybe<Scalars['String']>;
  connectedMessage_ends_with?: Maybe<Scalars['String']>;
  connectedMessage_not_ends_with?: Maybe<Scalars['String']>;
  connectedMessage_i?: Maybe<Scalars['String']>;
  connectedMessage_not_i?: Maybe<Scalars['String']>;
  connectedMessage_contains_i?: Maybe<Scalars['String']>;
  connectedMessage_not_contains_i?: Maybe<Scalars['String']>;
  connectedMessage_starts_with_i?: Maybe<Scalars['String']>;
  connectedMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  connectedMessage_ends_with_i?: Maybe<Scalars['String']>;
  connectedMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  connectedMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectedMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadUrl_not?: Maybe<Scalars['String']>;
  uploadUrl_contains?: Maybe<Scalars['String']>;
  uploadUrl_not_contains?: Maybe<Scalars['String']>;
  uploadUrl_starts_with?: Maybe<Scalars['String']>;
  uploadUrl_not_starts_with?: Maybe<Scalars['String']>;
  uploadUrl_ends_with?: Maybe<Scalars['String']>;
  uploadUrl_not_ends_with?: Maybe<Scalars['String']>;
  uploadUrl_i?: Maybe<Scalars['String']>;
  uploadUrl_not_i?: Maybe<Scalars['String']>;
  uploadUrl_contains_i?: Maybe<Scalars['String']>;
  uploadUrl_not_contains_i?: Maybe<Scalars['String']>;
  uploadUrl_starts_with_i?: Maybe<Scalars['String']>;
  uploadUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  uploadUrl_ends_with_i?: Maybe<Scalars['String']>;
  uploadUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  uploadUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadMessage?: Maybe<Scalars['String']>;
  uploadMessage_not?: Maybe<Scalars['String']>;
  uploadMessage_contains?: Maybe<Scalars['String']>;
  uploadMessage_not_contains?: Maybe<Scalars['String']>;
  uploadMessage_starts_with?: Maybe<Scalars['String']>;
  uploadMessage_not_starts_with?: Maybe<Scalars['String']>;
  uploadMessage_ends_with?: Maybe<Scalars['String']>;
  uploadMessage_not_ends_with?: Maybe<Scalars['String']>;
  uploadMessage_i?: Maybe<Scalars['String']>;
  uploadMessage_not_i?: Maybe<Scalars['String']>;
  uploadMessage_contains_i?: Maybe<Scalars['String']>;
  uploadMessage_not_contains_i?: Maybe<Scalars['String']>;
  uploadMessage_starts_with_i?: Maybe<Scalars['String']>;
  uploadMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  uploadMessage_ends_with_i?: Maybe<Scalars['String']>;
  uploadMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  uploadMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contextDefaultStatus?: Maybe<Scalars['String']>;
  contextDefaultStatus_not?: Maybe<Scalars['String']>;
  contextDefaultStatus_contains?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_contains?: Maybe<Scalars['String']>;
  contextDefaultStatus_starts_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_starts_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_ends_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_ends_with?: Maybe<Scalars['String']>;
  contextDefaultStatus_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_contains_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_contains_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_starts_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_starts_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_ends_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_not_ends_with_i?: Maybe<Scalars['String']>;
  contextDefaultStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contextDefaultStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dataFormat?: Maybe<Scalars['JSON']>;
  dataFormat_not?: Maybe<Scalars['JSON']>;
  dataFormat_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dataFormat_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_not?: Maybe<Scalars['String']>;
  currencyCode_contains?: Maybe<Scalars['String']>;
  currencyCode_not_contains?: Maybe<Scalars['String']>;
  currencyCode_starts_with?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with?: Maybe<Scalars['String']>;
  currencyCode_ends_with?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with?: Maybe<Scalars['String']>;
  currencyCode_i?: Maybe<Scalars['String']>;
  currencyCode_not_i?: Maybe<Scalars['String']>;
  currencyCode_contains_i?: Maybe<Scalars['String']>;
  currencyCode_not_contains_i?: Maybe<Scalars['String']>;
  currencyCode_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isTrustedBankAccountSource_not?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingIntegrationHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingIntegrationHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingIntegrationHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingIntegrationHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingIntegrationHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationHistoryRecordsCreateInput = {
  data?: Maybe<BillingIntegrationHistoryRecordCreateInput>;
};

export type BillingIntegrationHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationHistoryRecordUpdateInput>;
};

/**  Integration state and settings for all organizations. The existence of this object means that there is a configured integration between the `billing data source` and `this API`  */
export type BillingIntegrationOrganizationContext = {
  __typename?: 'BillingIntegrationOrganizationContext';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationOrganizationContext List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationOrganizationContext List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationOrganizationContext List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationOrganizationContext List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  ID of the integration that is configured to receive data for the organization  */
  integration?: Maybe<BillingIntegration>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Settings that are required to get data from the `billing data source`. It can also contain fine-tuning optional integration settings. The data structure depends on the integration and defined there  */
  settings?: Maybe<Scalars['JSON']>;
  /**  Status of BillingIntegration connection, Can be one of the following: ["InProgress", "Error", "Finished"]. If not specified explicitly on creation, uses default value from related BillingIntegration model  */
  status?: Maybe<Scalars['String']>;
  /**  The current state of the integration process. Some integration need to store past state or data related to cache files/folders for past state. The data structure depends on the integration and defined there  */
  state?: Maybe<Scalars['JSON']>;
  /**  Information about last report, such as time of report, period of report, amount of loaded data and etc  */
  lastReport?: Maybe<Scalars['JSON']>;
  /**  Link to a problem occurred during last integration process. Filled automatically, can only be resolved to null manually.  */
  currentProblem?: Maybe<BillingIntegrationProblem>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingIntegrationOrganizationContextCreateInput = {
  integration?: Maybe<BillingIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  settings?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSON']>;
  lastReport?: Maybe<Scalars['JSON']>;
  currentProblem?: Maybe<BillingIntegrationProblemRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BillingIntegrationOrganizationContextHistoryRecord = {
  __typename?: 'BillingIntegrationOrganizationContextHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationOrganizationContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationOrganizationContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationOrganizationContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationOrganizationContextHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSON']>;
  lastReport?: Maybe<Scalars['JSON']>;
  currentProblem?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingIntegrationOrganizationContextHistoryRecordCreateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSON']>;
  lastReport?: Maybe<Scalars['JSON']>;
  currentProblem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingIntegrationOrganizationContextHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingIntegrationOrganizationContextHistoryRecordUpdateInput = {
  integration?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSON']>;
  lastReport?: Maybe<Scalars['JSON']>;
  currentProblem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingIntegrationOrganizationContextHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  settings?: Maybe<Scalars['JSON']>;
  settings_not?: Maybe<Scalars['JSON']>;
  settings_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  settings_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['JSON']>;
  state_not?: Maybe<Scalars['JSON']>;
  state_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lastReport?: Maybe<Scalars['JSON']>;
  lastReport_not?: Maybe<Scalars['JSON']>;
  lastReport_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lastReport_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  currentProblem?: Maybe<Scalars['String']>;
  currentProblem_not?: Maybe<Scalars['String']>;
  currentProblem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currentProblem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingIntegrationOrganizationContextHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationOrganizationContextHistoryRecordsCreateInput = {
  data?: Maybe<BillingIntegrationOrganizationContextHistoryRecordCreateInput>;
};

export type BillingIntegrationOrganizationContextHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationOrganizationContextHistoryRecordUpdateInput>;
};

export type BillingIntegrationOrganizationContextRelateToOneInput = {
  create?: Maybe<BillingIntegrationOrganizationContextCreateInput>;
  connect?: Maybe<BillingIntegrationOrganizationContextWhereUniqueInput>;
  disconnect?: Maybe<BillingIntegrationOrganizationContextWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingIntegrationOrganizationContextUpdateInput = {
  integration?: Maybe<BillingIntegrationRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  settings?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['JSON']>;
  lastReport?: Maybe<Scalars['JSON']>;
  currentProblem?: Maybe<BillingIntegrationProblemRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingIntegrationOrganizationContextWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextWhereInput>>>;
  integration?: Maybe<BillingIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<Scalars['JSON']>;
  settings_not?: Maybe<Scalars['JSON']>;
  settings_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  settings_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  state?: Maybe<Scalars['JSON']>;
  state_not?: Maybe<Scalars['JSON']>;
  state_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lastReport?: Maybe<Scalars['JSON']>;
  lastReport_not?: Maybe<Scalars['JSON']>;
  lastReport_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lastReport_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  currentProblem?: Maybe<BillingIntegrationProblemWhereInput>;
  currentProblem_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingIntegrationOrganizationContextWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationOrganizationContextsCreateInput = {
  data?: Maybe<BillingIntegrationOrganizationContextCreateInput>;
};

export type BillingIntegrationOrganizationContextsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationOrganizationContextUpdateInput>;
};

/**  Report about an error that occurred during integration process. There's 2 target groups for it: Organization employees will see title and message of last problem, so they can be notified, that something went wrong. Support can also read problem messages as well as metadata, which helps them to resolve an issue.  */
export type BillingIntegrationProblem = {
  __typename?: 'BillingIntegrationProblem';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingIntegrationProblem List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingIntegrationProblem List config, or
   *  3. As an alias to a 'name' field on the BillingIntegrationProblem List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingIntegrationProblem List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Integration context  */
  context?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Problem summary, like "Wrong requisites", "No access provided" and so on  */
  title?: Maybe<Scalars['String']>;
  /**  Client understandable message, describing what should client do to fix a problem  */
  message?: Maybe<Scalars['String']>;
  /**  The message metadata, which can help support to resolve an issue  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingIntegrationProblemCreateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingIntegrationProblemRelateToOneInput = {
  create?: Maybe<BillingIntegrationProblemCreateInput>;
  connect?: Maybe<BillingIntegrationProblemWhereUniqueInput>;
  disconnect?: Maybe<BillingIntegrationProblemWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingIntegrationProblemUpdateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingIntegrationProblemWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationProblemWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationProblemWhereInput>>>;
  context?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  message_i?: Maybe<Scalars['String']>;
  message_not_i?: Maybe<Scalars['String']>;
  message_contains_i?: Maybe<Scalars['String']>;
  message_not_contains_i?: Maybe<Scalars['String']>;
  message_starts_with_i?: Maybe<Scalars['String']>;
  message_not_starts_with_i?: Maybe<Scalars['String']>;
  message_ends_with_i?: Maybe<Scalars['String']>;
  message_not_ends_with_i?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingIntegrationProblemWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationProblemsCreateInput = {
  data?: Maybe<BillingIntegrationProblemCreateInput>;
};

export type BillingIntegrationProblemsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationProblemUpdateInput>;
};

export type BillingIntegrationRelateToOneInput = {
  create?: Maybe<BillingIntegrationCreateInput>;
  connect?: Maybe<BillingIntegrationWhereUniqueInput>;
  disconnect?: Maybe<BillingIntegrationWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingIntegrationUpdateInput = {
  name?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  shortDescription?: Maybe<Scalars['String']>;
  targetDescription?: Maybe<Scalars['String']>;
  bannerTextColor?: Maybe<BillingIntegrationBannerTextColorType>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerPromoImage?: Maybe<Scalars['Upload']>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  detailedDescription?: Maybe<Scalars['String']>;
  setupUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  appUrl?: Maybe<Scalars['String']>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  connectedMessage?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadMessage?: Maybe<Scalars['String']>;
  contextDefaultStatus?: Maybe<BillingIntegrationContextDefaultStatusType>;
  dataFormat?: Maybe<BillingIntegrationDataFormatFieldInput>;
  currencyCode?: Maybe<BillingIntegrationCurrencyCodeType>;
  accessRights?: Maybe<BillingIntegrationAccessRightRelateToManyInput>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingIntegrationWhereInput = {
  AND?: Maybe<Array<Maybe<BillingIntegrationWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingIntegrationWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['String']>;
  logo_not?: Maybe<Scalars['String']>;
  logo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortDescription_not?: Maybe<Scalars['String']>;
  shortDescription_contains?: Maybe<Scalars['String']>;
  shortDescription_not_contains?: Maybe<Scalars['String']>;
  shortDescription_starts_with?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with?: Maybe<Scalars['String']>;
  shortDescription_ends_with?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with?: Maybe<Scalars['String']>;
  shortDescription_i?: Maybe<Scalars['String']>;
  shortDescription_not_i?: Maybe<Scalars['String']>;
  shortDescription_contains_i?: Maybe<Scalars['String']>;
  shortDescription_not_contains_i?: Maybe<Scalars['String']>;
  shortDescription_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  shortDescription_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  shortDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  shortDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetDescription?: Maybe<Scalars['String']>;
  targetDescription_not?: Maybe<Scalars['String']>;
  targetDescription_contains?: Maybe<Scalars['String']>;
  targetDescription_not_contains?: Maybe<Scalars['String']>;
  targetDescription_starts_with?: Maybe<Scalars['String']>;
  targetDescription_not_starts_with?: Maybe<Scalars['String']>;
  targetDescription_ends_with?: Maybe<Scalars['String']>;
  targetDescription_not_ends_with?: Maybe<Scalars['String']>;
  targetDescription_i?: Maybe<Scalars['String']>;
  targetDescription_not_i?: Maybe<Scalars['String']>;
  targetDescription_contains_i?: Maybe<Scalars['String']>;
  targetDescription_not_contains_i?: Maybe<Scalars['String']>;
  targetDescription_starts_with_i?: Maybe<Scalars['String']>;
  targetDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  targetDescription_ends_with_i?: Maybe<Scalars['String']>;
  targetDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  targetDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerTextColor?: Maybe<BillingIntegrationBannerTextColorType>;
  bannerTextColor_not?: Maybe<BillingIntegrationBannerTextColorType>;
  bannerTextColor_in?: Maybe<Array<Maybe<BillingIntegrationBannerTextColorType>>>;
  bannerTextColor_not_in?: Maybe<Array<Maybe<BillingIntegrationBannerTextColorType>>>;
  bannerColor?: Maybe<Scalars['String']>;
  bannerColor_not?: Maybe<Scalars['String']>;
  bannerColor_contains?: Maybe<Scalars['String']>;
  bannerColor_not_contains?: Maybe<Scalars['String']>;
  bannerColor_starts_with?: Maybe<Scalars['String']>;
  bannerColor_not_starts_with?: Maybe<Scalars['String']>;
  bannerColor_ends_with?: Maybe<Scalars['String']>;
  bannerColor_not_ends_with?: Maybe<Scalars['String']>;
  bannerColor_i?: Maybe<Scalars['String']>;
  bannerColor_not_i?: Maybe<Scalars['String']>;
  bannerColor_contains_i?: Maybe<Scalars['String']>;
  bannerColor_not_contains_i?: Maybe<Scalars['String']>;
  bannerColor_starts_with_i?: Maybe<Scalars['String']>;
  bannerColor_not_starts_with_i?: Maybe<Scalars['String']>;
  bannerColor_ends_with_i?: Maybe<Scalars['String']>;
  bannerColor_not_ends_with_i?: Maybe<Scalars['String']>;
  bannerColor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerColor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerPromoImage?: Maybe<Scalars['String']>;
  bannerPromoImage_not?: Maybe<Scalars['String']>;
  bannerPromoImage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bannerPromoImage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiptsLoadingTime?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not?: Maybe<Scalars['String']>;
  receiptsLoadingTime_contains?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_contains?: Maybe<Scalars['String']>;
  receiptsLoadingTime_starts_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_starts_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_ends_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_ends_with?: Maybe<Scalars['String']>;
  receiptsLoadingTime_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_contains_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_contains_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_starts_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_starts_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_ends_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_not_ends_with_i?: Maybe<Scalars['String']>;
  receiptsLoadingTime_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiptsLoadingTime_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedDescription?: Maybe<Scalars['String']>;
  detailedDescription_not?: Maybe<Scalars['String']>;
  detailedDescription_contains?: Maybe<Scalars['String']>;
  detailedDescription_not_contains?: Maybe<Scalars['String']>;
  detailedDescription_starts_with?: Maybe<Scalars['String']>;
  detailedDescription_not_starts_with?: Maybe<Scalars['String']>;
  detailedDescription_ends_with?: Maybe<Scalars['String']>;
  detailedDescription_not_ends_with?: Maybe<Scalars['String']>;
  detailedDescription_i?: Maybe<Scalars['String']>;
  detailedDescription_not_i?: Maybe<Scalars['String']>;
  detailedDescription_contains_i?: Maybe<Scalars['String']>;
  detailedDescription_not_contains_i?: Maybe<Scalars['String']>;
  detailedDescription_starts_with_i?: Maybe<Scalars['String']>;
  detailedDescription_not_starts_with_i?: Maybe<Scalars['String']>;
  detailedDescription_ends_with_i?: Maybe<Scalars['String']>;
  detailedDescription_not_ends_with_i?: Maybe<Scalars['String']>;
  detailedDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailedDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl?: Maybe<Scalars['String']>;
  setupUrl_not?: Maybe<Scalars['String']>;
  setupUrl_contains?: Maybe<Scalars['String']>;
  setupUrl_not_contains?: Maybe<Scalars['String']>;
  setupUrl_starts_with?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with?: Maybe<Scalars['String']>;
  setupUrl_ends_with?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with?: Maybe<Scalars['String']>;
  setupUrl_i?: Maybe<Scalars['String']>;
  setupUrl_not_i?: Maybe<Scalars['String']>;
  setupUrl_contains_i?: Maybe<Scalars['String']>;
  setupUrl_not_contains_i?: Maybe<Scalars['String']>;
  setupUrl_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  setupUrl_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  setupUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  setupUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAccountNumberUrl?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_contains?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_contains?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_starts_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_starts_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_ends_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_ends_with?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_contains_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_contains_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_starts_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_ends_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  checkAccountNumberUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAccountNumberUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddressUrl?: Maybe<Scalars['String']>;
  checkAddressUrl_not?: Maybe<Scalars['String']>;
  checkAddressUrl_contains?: Maybe<Scalars['String']>;
  checkAddressUrl_not_contains?: Maybe<Scalars['String']>;
  checkAddressUrl_starts_with?: Maybe<Scalars['String']>;
  checkAddressUrl_not_starts_with?: Maybe<Scalars['String']>;
  checkAddressUrl_ends_with?: Maybe<Scalars['String']>;
  checkAddressUrl_not_ends_with?: Maybe<Scalars['String']>;
  checkAddressUrl_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_i?: Maybe<Scalars['String']>;
  checkAddressUrl_contains_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_contains_i?: Maybe<Scalars['String']>;
  checkAddressUrl_starts_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_ends_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  checkAddressUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  checkAddressUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instruction?: Maybe<Scalars['String']>;
  instruction_not?: Maybe<Scalars['String']>;
  instruction_contains?: Maybe<Scalars['String']>;
  instruction_not_contains?: Maybe<Scalars['String']>;
  instruction_starts_with?: Maybe<Scalars['String']>;
  instruction_not_starts_with?: Maybe<Scalars['String']>;
  instruction_ends_with?: Maybe<Scalars['String']>;
  instruction_not_ends_with?: Maybe<Scalars['String']>;
  instruction_i?: Maybe<Scalars['String']>;
  instruction_not_i?: Maybe<Scalars['String']>;
  instruction_contains_i?: Maybe<Scalars['String']>;
  instruction_not_contains_i?: Maybe<Scalars['String']>;
  instruction_starts_with_i?: Maybe<Scalars['String']>;
  instruction_not_starts_with_i?: Maybe<Scalars['String']>;
  instruction_ends_with_i?: Maybe<Scalars['String']>;
  instruction_not_ends_with_i?: Maybe<Scalars['String']>;
  instruction_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instruction_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instructionExtraLink?: Maybe<Scalars['String']>;
  instructionExtraLink_not?: Maybe<Scalars['String']>;
  instructionExtraLink_contains?: Maybe<Scalars['String']>;
  instructionExtraLink_not_contains?: Maybe<Scalars['String']>;
  instructionExtraLink_starts_with?: Maybe<Scalars['String']>;
  instructionExtraLink_not_starts_with?: Maybe<Scalars['String']>;
  instructionExtraLink_ends_with?: Maybe<Scalars['String']>;
  instructionExtraLink_not_ends_with?: Maybe<Scalars['String']>;
  instructionExtraLink_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_i?: Maybe<Scalars['String']>;
  instructionExtraLink_contains_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_contains_i?: Maybe<Scalars['String']>;
  instructionExtraLink_starts_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_starts_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_ends_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_not_ends_with_i?: Maybe<Scalars['String']>;
  instructionExtraLink_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  instructionExtraLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl?: Maybe<Scalars['String']>;
  appUrl_not?: Maybe<Scalars['String']>;
  appUrl_contains?: Maybe<Scalars['String']>;
  appUrl_not_contains?: Maybe<Scalars['String']>;
  appUrl_starts_with?: Maybe<Scalars['String']>;
  appUrl_not_starts_with?: Maybe<Scalars['String']>;
  appUrl_ends_with?: Maybe<Scalars['String']>;
  appUrl_not_ends_with?: Maybe<Scalars['String']>;
  appUrl_i?: Maybe<Scalars['String']>;
  appUrl_not_i?: Maybe<Scalars['String']>;
  appUrl_contains_i?: Maybe<Scalars['String']>;
  appUrl_not_contains_i?: Maybe<Scalars['String']>;
  appUrl_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  appUrl_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  appUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  extendsBillingPage?: Maybe<Scalars['Boolean']>;
  extendsBillingPage_not?: Maybe<Scalars['Boolean']>;
  billingPageTitle?: Maybe<Scalars['String']>;
  billingPageTitle_not?: Maybe<Scalars['String']>;
  billingPageTitle_contains?: Maybe<Scalars['String']>;
  billingPageTitle_not_contains?: Maybe<Scalars['String']>;
  billingPageTitle_starts_with?: Maybe<Scalars['String']>;
  billingPageTitle_not_starts_with?: Maybe<Scalars['String']>;
  billingPageTitle_ends_with?: Maybe<Scalars['String']>;
  billingPageTitle_not_ends_with?: Maybe<Scalars['String']>;
  billingPageTitle_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_i?: Maybe<Scalars['String']>;
  billingPageTitle_contains_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_contains_i?: Maybe<Scalars['String']>;
  billingPageTitle_starts_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_starts_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_ends_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_not_ends_with_i?: Maybe<Scalars['String']>;
  billingPageTitle_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingPageTitle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  group?: Maybe<Scalars['String']>;
  group_not?: Maybe<Scalars['String']>;
  group_contains?: Maybe<Scalars['String']>;
  group_not_contains?: Maybe<Scalars['String']>;
  group_starts_with?: Maybe<Scalars['String']>;
  group_not_starts_with?: Maybe<Scalars['String']>;
  group_ends_with?: Maybe<Scalars['String']>;
  group_not_ends_with?: Maybe<Scalars['String']>;
  group_i?: Maybe<Scalars['String']>;
  group_not_i?: Maybe<Scalars['String']>;
  group_contains_i?: Maybe<Scalars['String']>;
  group_not_contains_i?: Maybe<Scalars['String']>;
  group_starts_with_i?: Maybe<Scalars['String']>;
  group_not_starts_with_i?: Maybe<Scalars['String']>;
  group_ends_with_i?: Maybe<Scalars['String']>;
  group_not_ends_with_i?: Maybe<Scalars['String']>;
  group_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  group_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectedMessage?: Maybe<Scalars['String']>;
  connectedMessage_not?: Maybe<Scalars['String']>;
  connectedMessage_contains?: Maybe<Scalars['String']>;
  connectedMessage_not_contains?: Maybe<Scalars['String']>;
  connectedMessage_starts_with?: Maybe<Scalars['String']>;
  connectedMessage_not_starts_with?: Maybe<Scalars['String']>;
  connectedMessage_ends_with?: Maybe<Scalars['String']>;
  connectedMessage_not_ends_with?: Maybe<Scalars['String']>;
  connectedMessage_i?: Maybe<Scalars['String']>;
  connectedMessage_not_i?: Maybe<Scalars['String']>;
  connectedMessage_contains_i?: Maybe<Scalars['String']>;
  connectedMessage_not_contains_i?: Maybe<Scalars['String']>;
  connectedMessage_starts_with_i?: Maybe<Scalars['String']>;
  connectedMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  connectedMessage_ends_with_i?: Maybe<Scalars['String']>;
  connectedMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  connectedMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectedMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadUrl_not?: Maybe<Scalars['String']>;
  uploadUrl_contains?: Maybe<Scalars['String']>;
  uploadUrl_not_contains?: Maybe<Scalars['String']>;
  uploadUrl_starts_with?: Maybe<Scalars['String']>;
  uploadUrl_not_starts_with?: Maybe<Scalars['String']>;
  uploadUrl_ends_with?: Maybe<Scalars['String']>;
  uploadUrl_not_ends_with?: Maybe<Scalars['String']>;
  uploadUrl_i?: Maybe<Scalars['String']>;
  uploadUrl_not_i?: Maybe<Scalars['String']>;
  uploadUrl_contains_i?: Maybe<Scalars['String']>;
  uploadUrl_not_contains_i?: Maybe<Scalars['String']>;
  uploadUrl_starts_with_i?: Maybe<Scalars['String']>;
  uploadUrl_not_starts_with_i?: Maybe<Scalars['String']>;
  uploadUrl_ends_with_i?: Maybe<Scalars['String']>;
  uploadUrl_not_ends_with_i?: Maybe<Scalars['String']>;
  uploadUrl_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadUrl_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadMessage?: Maybe<Scalars['String']>;
  uploadMessage_not?: Maybe<Scalars['String']>;
  uploadMessage_contains?: Maybe<Scalars['String']>;
  uploadMessage_not_contains?: Maybe<Scalars['String']>;
  uploadMessage_starts_with?: Maybe<Scalars['String']>;
  uploadMessage_not_starts_with?: Maybe<Scalars['String']>;
  uploadMessage_ends_with?: Maybe<Scalars['String']>;
  uploadMessage_not_ends_with?: Maybe<Scalars['String']>;
  uploadMessage_i?: Maybe<Scalars['String']>;
  uploadMessage_not_i?: Maybe<Scalars['String']>;
  uploadMessage_contains_i?: Maybe<Scalars['String']>;
  uploadMessage_not_contains_i?: Maybe<Scalars['String']>;
  uploadMessage_starts_with_i?: Maybe<Scalars['String']>;
  uploadMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  uploadMessage_ends_with_i?: Maybe<Scalars['String']>;
  uploadMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  uploadMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uploadMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contextDefaultStatus?: Maybe<BillingIntegrationContextDefaultStatusType>;
  contextDefaultStatus_not?: Maybe<BillingIntegrationContextDefaultStatusType>;
  contextDefaultStatus_in?: Maybe<Array<Maybe<BillingIntegrationContextDefaultStatusType>>>;
  contextDefaultStatus_not_in?: Maybe<Array<Maybe<BillingIntegrationContextDefaultStatusType>>>;
  dataFormat?: Maybe<BillingIntegrationDataFormatFieldInput>;
  dataFormat_not?: Maybe<BillingIntegrationDataFormatFieldInput>;
  dataFormat_in?: Maybe<Array<Maybe<BillingIntegrationDataFormatFieldInput>>>;
  dataFormat_not_in?: Maybe<Array<Maybe<BillingIntegrationDataFormatFieldInput>>>;
  currencyCode?: Maybe<BillingIntegrationCurrencyCodeType>;
  currencyCode_not?: Maybe<BillingIntegrationCurrencyCodeType>;
  currencyCode_in?: Maybe<Array<Maybe<BillingIntegrationCurrencyCodeType>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<BillingIntegrationCurrencyCodeType>>>;
  /**  condition must be true for all nodes  */
  accessRights_every?: Maybe<BillingIntegrationAccessRightWhereInput>;
  /**  condition must be true for at least 1 node  */
  accessRights_some?: Maybe<BillingIntegrationAccessRightWhereInput>;
  /**  condition must be false for all nodes  */
  accessRights_none?: Maybe<BillingIntegrationAccessRightWhereInput>;
  isTrustedBankAccountSource?: Maybe<Scalars['Boolean']>;
  isTrustedBankAccountSource_not?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications?: Maybe<Scalars['Boolean']>;
  skipNoAccountNotifications_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingIntegrationWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingIntegrationsCreateInput = {
  data?: Maybe<BillingIntegrationCreateInput>;
};

export type BillingIntegrationsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationUpdateInput>;
};

export type BillingPropertiesCreateInput = {
  data?: Maybe<BillingPropertyCreateInput>;
};

export type BillingPropertiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingPropertyUpdateInput>;
};

/**  All `property` objects from `billing data source`  */
export type BillingProperty = {
  __typename?: 'BillingProperty';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingProperty List config, or
   *  3. As an alias to a 'name' field on the BillingProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingProperty List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Integration context  */
  context?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  /**  Raw non-structured data obtained from the `billing data source`. Used only for the internal needs of the `integration component`.  */
  raw?: Maybe<Scalars['JSON']>;
  /**  A well-known universal identifier that allows you to identify the same objects in different systems. It may differ in different countries. Example: for Russia, the FIAS ID is used  */
  globalId?: Maybe<Scalars['String']>;
  /**  [DEPRECATED] Normalized address from `billing data source`. Used to map Properties to BillingProperties  */
  normalizedAddress?: Maybe<Scalars['String']>;
  /**  Structured metadata obtained from the `billing data source`. Some of this data is required for use in the `receipt template`. Examples of data keys: `total space of building`, `property beginning of exploitation year`, `has cultural heritage status`, `number of underground floors`, `number of above-ground floors`  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Link to the property model  */
  property?: Maybe<Property>;
  id: Scalars['ID'];
  /**  Normalized address  */
  address?: Maybe<Scalars['String']>;
  /**  The unique key of the address  */
  addressKey?: Maybe<Scalars['String']>;
  /**  Property address components  */
  addressMeta?: Maybe<AddressMetaField>;
  /**  The origins of the address (some strings which may looks like real address or some id)  */
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingPropertyCreateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  globalId?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BillingPropertyHistoryRecord = {
  __typename?: 'BillingPropertyHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingPropertyHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  globalId?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingPropertyHistoryRecordCreateInput = {
  context?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  globalId?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingPropertyHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingPropertyHistoryRecordUpdateInput = {
  context?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  globalId?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingPropertyHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingPropertyHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingPropertyHistoryRecordWhereInput>>>;
  context?: Maybe<Scalars['String']>;
  context_not?: Maybe<Scalars['String']>;
  context_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['JSON']>;
  raw_not?: Maybe<Scalars['JSON']>;
  raw_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  raw_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  globalId?: Maybe<Scalars['String']>;
  globalId_not?: Maybe<Scalars['String']>;
  globalId_contains?: Maybe<Scalars['String']>;
  globalId_not_contains?: Maybe<Scalars['String']>;
  globalId_starts_with?: Maybe<Scalars['String']>;
  globalId_not_starts_with?: Maybe<Scalars['String']>;
  globalId_ends_with?: Maybe<Scalars['String']>;
  globalId_not_ends_with?: Maybe<Scalars['String']>;
  globalId_i?: Maybe<Scalars['String']>;
  globalId_not_i?: Maybe<Scalars['String']>;
  globalId_contains_i?: Maybe<Scalars['String']>;
  globalId_not_contains_i?: Maybe<Scalars['String']>;
  globalId_starts_with_i?: Maybe<Scalars['String']>;
  globalId_not_starts_with_i?: Maybe<Scalars['String']>;
  globalId_ends_with_i?: Maybe<Scalars['String']>;
  globalId_not_ends_with_i?: Maybe<Scalars['String']>;
  globalId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalizedAddress?: Maybe<Scalars['String']>;
  normalizedAddress_not?: Maybe<Scalars['String']>;
  normalizedAddress_contains?: Maybe<Scalars['String']>;
  normalizedAddress_not_contains?: Maybe<Scalars['String']>;
  normalizedAddress_starts_with?: Maybe<Scalars['String']>;
  normalizedAddress_not_starts_with?: Maybe<Scalars['String']>;
  normalizedAddress_ends_with?: Maybe<Scalars['String']>;
  normalizedAddress_not_ends_with?: Maybe<Scalars['String']>;
  normalizedAddress_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_i?: Maybe<Scalars['String']>;
  normalizedAddress_contains_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_contains_i?: Maybe<Scalars['String']>;
  normalizedAddress_starts_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_starts_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_ends_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_ends_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalizedAddress_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingPropertyHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingPropertyHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingPropertyHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingPropertyHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingPropertyHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingPropertyHistoryRecordsCreateInput = {
  data?: Maybe<BillingPropertyHistoryRecordCreateInput>;
};

export type BillingPropertyHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingPropertyHistoryRecordUpdateInput>;
};

export type BillingPropertyRelateToOneInput = {
  create?: Maybe<BillingPropertyCreateInput>;
  connect?: Maybe<BillingPropertyWhereUniqueInput>;
  disconnect?: Maybe<BillingPropertyWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingPropertyUpdateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  importId?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  globalId?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingPropertyWhereInput = {
  AND?: Maybe<Array<Maybe<BillingPropertyWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingPropertyWhereInput>>>;
  context?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['JSON']>;
  raw_not?: Maybe<Scalars['JSON']>;
  raw_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  raw_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  globalId?: Maybe<Scalars['String']>;
  globalId_not?: Maybe<Scalars['String']>;
  globalId_contains?: Maybe<Scalars['String']>;
  globalId_not_contains?: Maybe<Scalars['String']>;
  globalId_starts_with?: Maybe<Scalars['String']>;
  globalId_not_starts_with?: Maybe<Scalars['String']>;
  globalId_ends_with?: Maybe<Scalars['String']>;
  globalId_not_ends_with?: Maybe<Scalars['String']>;
  globalId_i?: Maybe<Scalars['String']>;
  globalId_not_i?: Maybe<Scalars['String']>;
  globalId_contains_i?: Maybe<Scalars['String']>;
  globalId_not_contains_i?: Maybe<Scalars['String']>;
  globalId_starts_with_i?: Maybe<Scalars['String']>;
  globalId_not_starts_with_i?: Maybe<Scalars['String']>;
  globalId_ends_with_i?: Maybe<Scalars['String']>;
  globalId_not_ends_with_i?: Maybe<Scalars['String']>;
  globalId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  globalId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalizedAddress?: Maybe<Scalars['String']>;
  normalizedAddress_not?: Maybe<Scalars['String']>;
  normalizedAddress_contains?: Maybe<Scalars['String']>;
  normalizedAddress_not_contains?: Maybe<Scalars['String']>;
  normalizedAddress_starts_with?: Maybe<Scalars['String']>;
  normalizedAddress_not_starts_with?: Maybe<Scalars['String']>;
  normalizedAddress_ends_with?: Maybe<Scalars['String']>;
  normalizedAddress_not_ends_with?: Maybe<Scalars['String']>;
  normalizedAddress_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_i?: Maybe<Scalars['String']>;
  normalizedAddress_contains_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_contains_i?: Maybe<Scalars['String']>;
  normalizedAddress_starts_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_starts_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_ends_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_not_ends_with_i?: Maybe<Scalars['String']>;
  normalizedAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  normalizedAddress_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingPropertyWhereUniqueInput = {
  id: Scalars['ID'];
};

/**  Account monthly invoice document  */
export type BillingReceipt = {
  __typename?: 'BillingReceipt';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceipt List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceipt List config, or
   *  3. As an alias to a 'name' field on the BillingReceipt List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceipt List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Integration context  */
  context?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Billing property  */
  property?: Maybe<BillingProperty>;
  /**  Billing account  */
  account?: Maybe<BillingAccount>;
  /**  Period date: Generated on template <year>-<month>-01  */
  period?: Maybe<Scalars['String']>;
  /**  `billing receipt` local object ID. Unique up to billing context. It is unique up to the context. The constrain is a combination of contextId and importId.  */
  importId?: Maybe<Scalars['String']>;
  /**  A category to print on display on the payment document.  */
  category?: Maybe<BillingCategory>;
  /**  A number to print on the payment document.  */
  printableNumber?: Maybe<Scalars['String']>;
  /**  Raw non-structured data obtained from the `billing data source`. Used only for the internal needs of the `integration component`.  */
  raw?: Maybe<Scalars['JSON']>;
  /**  Total sum to pay. Usually counts as the sum of all services.  */
  toPay?: Maybe<Scalars['String']>;
  /**  Calculation formula. Example: balance + charge + recalculation + privilege + penalty  */
  formula?: Maybe<Scalars['String']>;
  /**  Amount of money charged by paid period. Example: "50.00", "-50.00"  */
  charge?: Maybe<Scalars['String']>;
  /**  Recipient balance on the receipt creation moment. Example: "50.00", "-50.00"  */
  balance?: Maybe<Scalars['String']>;
  /**  Recipient balance recalculation in case of overpaid or etc. Example: "50.00", "-50.00"  */
  recalculation?: Maybe<Scalars['String']>;
  /**  Special privileges for recipient. Example: "50.00", "-50.00"  */
  privilege?: Maybe<Scalars['String']>;
  /**  Amount of money that recipient doesn't pay for previous receipt. Example: "50.00", "-50.00"  */
  penalty?: Maybe<Scalars['String']>;
  /**  Amount of money that recipient already paid by current receipt. Example: "50.00", "-50.00"  */
  paid?: Maybe<Scalars['String']>;
  /**  @deprecated Sum to pay details. Detail level 2. This field will be removed in the future. All data is saved in the corresponding fields of the BillingReceipt (charge formula balance recalculation privilege penalty paid). After toPayDetails field removal you should update it content explicitly  */
  toPayDetails?: Maybe<BillingReceiptToPayDetailsField>;
  /**  Services to pay for. Every service has id, name and toPay. Service may or may not have toPay detail. Detail level 3 and 4  */
  services?: Maybe<Array<BillingReceiptServiceField>>;
  /**  Recipient. Should contain all meta information to identify the organization  */
  recipient?: Maybe<BillingReceiptsRecipientField>;
  /**  Relation to the BillingRecipient. Going to override recipient field, has the same meaning  */
  receiver?: Maybe<BillingRecipient>;
  /**  Indicates if services are valid and add up to total sum toPay.  */
  invalidServicesError?: Maybe<Scalars['String']>;
  /**  A BillingReceiptFile that related to this billing receipt (filled up by integration)  */
  file?: Maybe<BillingReceiptFile>;
  /**  Indicator that this invoice document is latest for its account and receiver/category  */
  isPayable?: Maybe<Scalars['Boolean']>;
  /**  Integration ID through which this receipt can be paid  */
  acquiringIntegrationId?: Maybe<Scalars['ID']>;
  /**  Url to acquiring integration service. Mobile devices will use it communicate with external acquiring. List of endpoints is the same for all of them.  */
  acquiringHostUrl?: Maybe<Scalars['String']>;
  /**  Can multiple receipts be united through this acquiring  */
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingReceiptCreateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  property?: Maybe<BillingPropertyRelateToOneInput>;
  account?: Maybe<BillingAccountRelateToOneInput>;
  period?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  category?: Maybe<BillingCategoryRelateToOneInput>;
  printableNumber?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  toPay?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  toPayDetails?: Maybe<BillingReceiptToPayDetailsFieldInput>;
  services?: Maybe<Array<BillingReceiptServiceFieldInput>>;
  recipient?: Maybe<BillingReceiptsRecipientFieldInput>;
  receiver?: Maybe<BillingRecipientRelateToOneInput>;
  file?: Maybe<BillingReceiptFileRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  File for billing receipt  */
export type BillingReceiptFile = {
  __typename?: 'BillingReceiptFile';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceiptFile List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceiptFile List config, or
   *  3. As an alias to a 'name' field on the BillingReceiptFile List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceiptFile List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Wrapper to return file version of the receipt (usually PDF) with personal information or without  */
  file?: Maybe<File>;
  /**  File version of the receipt with personal information  */
  sensitiveDataFile?: Maybe<File>;
  /**  File version of the receipt without personal information  */
  publicDataFile?: Maybe<File>;
  /**  Link to Context  */
  context?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Link to Billing Receipt  */
  receipt?: Maybe<BillingReceipt>;
  /**  Meta information about the file  */
  controlSum?: Maybe<Scalars['String']>;
  /**  Unique import id for each file  */
  importId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingReceiptFileCreateInput = {
  sensitiveDataFile?: Maybe<Scalars['Upload']>;
  publicDataFile?: Maybe<Scalars['Upload']>;
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  receipt?: Maybe<BillingReceiptRelateToOneInput>;
  controlSum?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BillingReceiptFileHistoryRecord = {
  __typename?: 'BillingReceiptFileHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceiptFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceiptFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingReceiptFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceiptFileHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  sensitiveDataFile?: Maybe<Scalars['JSON']>;
  publicDataFile?: Maybe<Scalars['JSON']>;
  context?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  controlSum?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingReceiptFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingReceiptFileHistoryRecordCreateInput = {
  sensitiveDataFile?: Maybe<Scalars['JSON']>;
  publicDataFile?: Maybe<Scalars['JSON']>;
  context?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  controlSum?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingReceiptFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingReceiptFileHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingReceiptFileHistoryRecordUpdateInput = {
  sensitiveDataFile?: Maybe<Scalars['JSON']>;
  publicDataFile?: Maybe<Scalars['JSON']>;
  context?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  controlSum?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingReceiptFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingReceiptFileHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecordWhereInput>>>;
  sensitiveDataFile?: Maybe<Scalars['JSON']>;
  sensitiveDataFile_not?: Maybe<Scalars['JSON']>;
  sensitiveDataFile_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sensitiveDataFile_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  publicDataFile?: Maybe<Scalars['JSON']>;
  publicDataFile_not?: Maybe<Scalars['JSON']>;
  publicDataFile_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  publicDataFile_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  context?: Maybe<Scalars['String']>;
  context_not?: Maybe<Scalars['String']>;
  context_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt?: Maybe<Scalars['String']>;
  receipt_not?: Maybe<Scalars['String']>;
  receipt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlSum?: Maybe<Scalars['String']>;
  controlSum_not?: Maybe<Scalars['String']>;
  controlSum_contains?: Maybe<Scalars['String']>;
  controlSum_not_contains?: Maybe<Scalars['String']>;
  controlSum_starts_with?: Maybe<Scalars['String']>;
  controlSum_not_starts_with?: Maybe<Scalars['String']>;
  controlSum_ends_with?: Maybe<Scalars['String']>;
  controlSum_not_ends_with?: Maybe<Scalars['String']>;
  controlSum_i?: Maybe<Scalars['String']>;
  controlSum_not_i?: Maybe<Scalars['String']>;
  controlSum_contains_i?: Maybe<Scalars['String']>;
  controlSum_not_contains_i?: Maybe<Scalars['String']>;
  controlSum_starts_with_i?: Maybe<Scalars['String']>;
  controlSum_not_starts_with_i?: Maybe<Scalars['String']>;
  controlSum_ends_with_i?: Maybe<Scalars['String']>;
  controlSum_not_ends_with_i?: Maybe<Scalars['String']>;
  controlSum_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlSum_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingReceiptFileHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingReceiptFileHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingReceiptFileHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingReceiptFileHistoryRecordsCreateInput = {
  data?: Maybe<BillingReceiptFileHistoryRecordCreateInput>;
};

export type BillingReceiptFileHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptFileHistoryRecordUpdateInput>;
};

export type BillingReceiptFileRelateToOneInput = {
  create?: Maybe<BillingReceiptFileCreateInput>;
  connect?: Maybe<BillingReceiptFileWhereUniqueInput>;
  disconnect?: Maybe<BillingReceiptFileWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingReceiptFileUpdateInput = {
  sensitiveDataFile?: Maybe<Scalars['Upload']>;
  publicDataFile?: Maybe<Scalars['Upload']>;
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  receipt?: Maybe<BillingReceiptRelateToOneInput>;
  controlSum?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingReceiptFileWhereInput = {
  AND?: Maybe<Array<Maybe<BillingReceiptFileWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingReceiptFileWhereInput>>>;
  sensitiveDataFile?: Maybe<Scalars['String']>;
  sensitiveDataFile_not?: Maybe<Scalars['String']>;
  sensitiveDataFile_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sensitiveDataFile_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicDataFile?: Maybe<Scalars['String']>;
  publicDataFile_not?: Maybe<Scalars['String']>;
  publicDataFile_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicDataFile_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  receipt?: Maybe<BillingReceiptWhereInput>;
  receipt_is_null?: Maybe<Scalars['Boolean']>;
  controlSum?: Maybe<Scalars['String']>;
  controlSum_not?: Maybe<Scalars['String']>;
  controlSum_contains?: Maybe<Scalars['String']>;
  controlSum_not_contains?: Maybe<Scalars['String']>;
  controlSum_starts_with?: Maybe<Scalars['String']>;
  controlSum_not_starts_with?: Maybe<Scalars['String']>;
  controlSum_ends_with?: Maybe<Scalars['String']>;
  controlSum_not_ends_with?: Maybe<Scalars['String']>;
  controlSum_i?: Maybe<Scalars['String']>;
  controlSum_not_i?: Maybe<Scalars['String']>;
  controlSum_contains_i?: Maybe<Scalars['String']>;
  controlSum_not_contains_i?: Maybe<Scalars['String']>;
  controlSum_starts_with_i?: Maybe<Scalars['String']>;
  controlSum_not_starts_with_i?: Maybe<Scalars['String']>;
  controlSum_ends_with_i?: Maybe<Scalars['String']>;
  controlSum_not_ends_with_i?: Maybe<Scalars['String']>;
  controlSum_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlSum_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingReceiptFileWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingReceiptFilesCreateInput = {
  data?: Maybe<BillingReceiptFileCreateInput>;
};

export type BillingReceiptFilesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptFileUpdateInput>;
};

/**  A keystone list  */
export type BillingReceiptHistoryRecord = {
  __typename?: 'BillingReceiptHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingReceiptHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingReceiptHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingReceiptHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingReceiptHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  printableNumber?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  toPay?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  toPayDetails?: Maybe<Scalars['JSON']>;
  services?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  receiver?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingReceiptHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingReceiptHistoryRecordCreateInput = {
  context?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  printableNumber?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  toPay?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  toPayDetails?: Maybe<Scalars['JSON']>;
  services?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  receiver?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingReceiptHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingReceiptHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingReceiptHistoryRecordUpdateInput = {
  context?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  account?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  printableNumber?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  toPay?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  toPayDetails?: Maybe<Scalars['JSON']>;
  services?: Maybe<Scalars['JSON']>;
  recipient?: Maybe<Scalars['JSON']>;
  receiver?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingReceiptHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingReceiptHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingReceiptHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingReceiptHistoryRecordWhereInput>>>;
  context?: Maybe<Scalars['String']>;
  context_not?: Maybe<Scalars['String']>;
  context_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  account?: Maybe<Scalars['String']>;
  account_not?: Maybe<Scalars['String']>;
  account_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  account_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_lt?: Maybe<Scalars['String']>;
  period_lte?: Maybe<Scalars['String']>;
  period_gt?: Maybe<Scalars['String']>;
  period_gte?: Maybe<Scalars['String']>;
  period_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  printableNumber?: Maybe<Scalars['String']>;
  printableNumber_not?: Maybe<Scalars['String']>;
  printableNumber_contains?: Maybe<Scalars['String']>;
  printableNumber_not_contains?: Maybe<Scalars['String']>;
  printableNumber_starts_with?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with?: Maybe<Scalars['String']>;
  printableNumber_ends_with?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with?: Maybe<Scalars['String']>;
  printableNumber_i?: Maybe<Scalars['String']>;
  printableNumber_not_i?: Maybe<Scalars['String']>;
  printableNumber_contains_i?: Maybe<Scalars['String']>;
  printableNumber_not_contains_i?: Maybe<Scalars['String']>;
  printableNumber_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  printableNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['JSON']>;
  raw_not?: Maybe<Scalars['JSON']>;
  raw_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  raw_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  toPay?: Maybe<Scalars['String']>;
  toPay_not?: Maybe<Scalars['String']>;
  toPay_lt?: Maybe<Scalars['String']>;
  toPay_lte?: Maybe<Scalars['String']>;
  toPay_gt?: Maybe<Scalars['String']>;
  toPay_gte?: Maybe<Scalars['String']>;
  toPay_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPay_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  formula?: Maybe<Scalars['String']>;
  formula_not?: Maybe<Scalars['String']>;
  formula_contains?: Maybe<Scalars['String']>;
  formula_not_contains?: Maybe<Scalars['String']>;
  formula_starts_with?: Maybe<Scalars['String']>;
  formula_not_starts_with?: Maybe<Scalars['String']>;
  formula_ends_with?: Maybe<Scalars['String']>;
  formula_not_ends_with?: Maybe<Scalars['String']>;
  formula_i?: Maybe<Scalars['String']>;
  formula_not_i?: Maybe<Scalars['String']>;
  formula_contains_i?: Maybe<Scalars['String']>;
  formula_not_contains_i?: Maybe<Scalars['String']>;
  formula_starts_with_i?: Maybe<Scalars['String']>;
  formula_not_starts_with_i?: Maybe<Scalars['String']>;
  formula_ends_with_i?: Maybe<Scalars['String']>;
  formula_not_ends_with_i?: Maybe<Scalars['String']>;
  formula_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  formula_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  charge?: Maybe<Scalars['String']>;
  charge_not?: Maybe<Scalars['String']>;
  charge_lt?: Maybe<Scalars['String']>;
  charge_lte?: Maybe<Scalars['String']>;
  charge_gt?: Maybe<Scalars['String']>;
  charge_gte?: Maybe<Scalars['String']>;
  charge_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  charge_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  balance?: Maybe<Scalars['String']>;
  balance_not?: Maybe<Scalars['String']>;
  balance_lt?: Maybe<Scalars['String']>;
  balance_lte?: Maybe<Scalars['String']>;
  balance_gt?: Maybe<Scalars['String']>;
  balance_gte?: Maybe<Scalars['String']>;
  balance_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  balance_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recalculation?: Maybe<Scalars['String']>;
  recalculation_not?: Maybe<Scalars['String']>;
  recalculation_lt?: Maybe<Scalars['String']>;
  recalculation_lte?: Maybe<Scalars['String']>;
  recalculation_gt?: Maybe<Scalars['String']>;
  recalculation_gte?: Maybe<Scalars['String']>;
  recalculation_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recalculation_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  privilege?: Maybe<Scalars['String']>;
  privilege_not?: Maybe<Scalars['String']>;
  privilege_lt?: Maybe<Scalars['String']>;
  privilege_lte?: Maybe<Scalars['String']>;
  privilege_gt?: Maybe<Scalars['String']>;
  privilege_gte?: Maybe<Scalars['String']>;
  privilege_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  privilege_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  penalty?: Maybe<Scalars['String']>;
  penalty_not?: Maybe<Scalars['String']>;
  penalty_lt?: Maybe<Scalars['String']>;
  penalty_lte?: Maybe<Scalars['String']>;
  penalty_gt?: Maybe<Scalars['String']>;
  penalty_gte?: Maybe<Scalars['String']>;
  penalty_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  penalty_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paid?: Maybe<Scalars['String']>;
  paid_not?: Maybe<Scalars['String']>;
  paid_lt?: Maybe<Scalars['String']>;
  paid_lte?: Maybe<Scalars['String']>;
  paid_gt?: Maybe<Scalars['String']>;
  paid_gte?: Maybe<Scalars['String']>;
  paid_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paid_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPayDetails?: Maybe<Scalars['JSON']>;
  toPayDetails_not?: Maybe<Scalars['JSON']>;
  toPayDetails_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  toPayDetails_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  services?: Maybe<Scalars['JSON']>;
  services_not?: Maybe<Scalars['JSON']>;
  services_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  services_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  recipient?: Maybe<Scalars['JSON']>;
  recipient_not?: Maybe<Scalars['JSON']>;
  recipient_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  recipient_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  receiver?: Maybe<Scalars['String']>;
  receiver_not?: Maybe<Scalars['String']>;
  receiver_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receiver_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingReceiptHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingReceiptHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingReceiptHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingReceiptHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingReceiptHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingReceiptHistoryRecordsCreateInput = {
  data?: Maybe<BillingReceiptHistoryRecordCreateInput>;
};

export type BillingReceiptHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptHistoryRecordUpdateInput>;
};

export type BillingReceiptRelateToOneInput = {
  create?: Maybe<BillingReceiptCreateInput>;
  connect?: Maybe<BillingReceiptWhereUniqueInput>;
  disconnect?: Maybe<BillingReceiptWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingReceiptServiceField = {
  __typename?: 'BillingReceiptServiceField';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  toPay: Scalars['String'];
  toPayDetails?: Maybe<BillingReceiptServiceToPayDetailsField>;
};

export type BillingReceiptServiceFieldInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  toPay: Scalars['String'];
  toPayDetails?: Maybe<BillingReceiptServiceToPayDetailsFieldInput>;
};

export type BillingReceiptServiceToPayDetailsField = {
  __typename?: 'BillingReceiptServiceToPayDetailsField';
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
  tariff?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
};

export type BillingReceiptServiceToPayDetailsFieldInput = {
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['String']>;
  tariff?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
};

export type BillingReceiptToPayDetailsField = {
  __typename?: 'BillingReceiptToPayDetailsField';
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
};

export type BillingReceiptToPayDetailsFieldInput = {
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
};

export type BillingReceiptUpdateInput = {
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  property?: Maybe<BillingPropertyRelateToOneInput>;
  account?: Maybe<BillingAccountRelateToOneInput>;
  period?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  category?: Maybe<BillingCategoryRelateToOneInput>;
  printableNumber?: Maybe<Scalars['String']>;
  raw?: Maybe<Scalars['JSON']>;
  toPay?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  charge?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  recalculation?: Maybe<Scalars['String']>;
  privilege?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['String']>;
  toPayDetails?: Maybe<BillingReceiptToPayDetailsFieldInput>;
  services?: Maybe<Array<BillingReceiptServiceFieldInput>>;
  recipient?: Maybe<BillingReceiptsRecipientFieldInput>;
  receiver?: Maybe<BillingRecipientRelateToOneInput>;
  file?: Maybe<BillingReceiptFileRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingReceiptWhereInput = {
  AND?: Maybe<Array<Maybe<BillingReceiptWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingReceiptWhereInput>>>;
  context?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<BillingPropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  account?: Maybe<BillingAccountWhereInput>;
  account_is_null?: Maybe<Scalars['Boolean']>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_lt?: Maybe<Scalars['String']>;
  period_lte?: Maybe<Scalars['String']>;
  period_gt?: Maybe<Scalars['String']>;
  period_gte?: Maybe<Scalars['String']>;
  period_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<BillingCategoryWhereInput>;
  category_is_null?: Maybe<Scalars['Boolean']>;
  printableNumber?: Maybe<Scalars['String']>;
  printableNumber_not?: Maybe<Scalars['String']>;
  printableNumber_contains?: Maybe<Scalars['String']>;
  printableNumber_not_contains?: Maybe<Scalars['String']>;
  printableNumber_starts_with?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with?: Maybe<Scalars['String']>;
  printableNumber_ends_with?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with?: Maybe<Scalars['String']>;
  printableNumber_i?: Maybe<Scalars['String']>;
  printableNumber_not_i?: Maybe<Scalars['String']>;
  printableNumber_contains_i?: Maybe<Scalars['String']>;
  printableNumber_not_contains_i?: Maybe<Scalars['String']>;
  printableNumber_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  printableNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  raw?: Maybe<Scalars['JSON']>;
  raw_not?: Maybe<Scalars['JSON']>;
  raw_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  raw_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  toPay?: Maybe<Scalars['String']>;
  toPay_not?: Maybe<Scalars['String']>;
  toPay_lt?: Maybe<Scalars['String']>;
  toPay_lte?: Maybe<Scalars['String']>;
  toPay_gt?: Maybe<Scalars['String']>;
  toPay_gte?: Maybe<Scalars['String']>;
  toPay_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPay_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  formula?: Maybe<Scalars['String']>;
  formula_not?: Maybe<Scalars['String']>;
  formula_contains?: Maybe<Scalars['String']>;
  formula_not_contains?: Maybe<Scalars['String']>;
  formula_starts_with?: Maybe<Scalars['String']>;
  formula_not_starts_with?: Maybe<Scalars['String']>;
  formula_ends_with?: Maybe<Scalars['String']>;
  formula_not_ends_with?: Maybe<Scalars['String']>;
  formula_i?: Maybe<Scalars['String']>;
  formula_not_i?: Maybe<Scalars['String']>;
  formula_contains_i?: Maybe<Scalars['String']>;
  formula_not_contains_i?: Maybe<Scalars['String']>;
  formula_starts_with_i?: Maybe<Scalars['String']>;
  formula_not_starts_with_i?: Maybe<Scalars['String']>;
  formula_ends_with_i?: Maybe<Scalars['String']>;
  formula_not_ends_with_i?: Maybe<Scalars['String']>;
  formula_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  formula_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  charge?: Maybe<Scalars['String']>;
  charge_not?: Maybe<Scalars['String']>;
  charge_lt?: Maybe<Scalars['String']>;
  charge_lte?: Maybe<Scalars['String']>;
  charge_gt?: Maybe<Scalars['String']>;
  charge_gte?: Maybe<Scalars['String']>;
  charge_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  charge_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  balance?: Maybe<Scalars['String']>;
  balance_not?: Maybe<Scalars['String']>;
  balance_lt?: Maybe<Scalars['String']>;
  balance_lte?: Maybe<Scalars['String']>;
  balance_gt?: Maybe<Scalars['String']>;
  balance_gte?: Maybe<Scalars['String']>;
  balance_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  balance_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recalculation?: Maybe<Scalars['String']>;
  recalculation_not?: Maybe<Scalars['String']>;
  recalculation_lt?: Maybe<Scalars['String']>;
  recalculation_lte?: Maybe<Scalars['String']>;
  recalculation_gt?: Maybe<Scalars['String']>;
  recalculation_gte?: Maybe<Scalars['String']>;
  recalculation_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recalculation_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  privilege?: Maybe<Scalars['String']>;
  privilege_not?: Maybe<Scalars['String']>;
  privilege_lt?: Maybe<Scalars['String']>;
  privilege_lte?: Maybe<Scalars['String']>;
  privilege_gt?: Maybe<Scalars['String']>;
  privilege_gte?: Maybe<Scalars['String']>;
  privilege_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  privilege_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  penalty?: Maybe<Scalars['String']>;
  penalty_not?: Maybe<Scalars['String']>;
  penalty_lt?: Maybe<Scalars['String']>;
  penalty_lte?: Maybe<Scalars['String']>;
  penalty_gt?: Maybe<Scalars['String']>;
  penalty_gte?: Maybe<Scalars['String']>;
  penalty_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  penalty_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paid?: Maybe<Scalars['String']>;
  paid_not?: Maybe<Scalars['String']>;
  paid_lt?: Maybe<Scalars['String']>;
  paid_lte?: Maybe<Scalars['String']>;
  paid_gt?: Maybe<Scalars['String']>;
  paid_gte?: Maybe<Scalars['String']>;
  paid_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paid_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPayDetails?: Maybe<BillingReceiptToPayDetailsFieldInput>;
  toPayDetails_not?: Maybe<BillingReceiptToPayDetailsFieldInput>;
  toPayDetails_in?: Maybe<Array<Maybe<BillingReceiptToPayDetailsFieldInput>>>;
  toPayDetails_not_in?: Maybe<Array<Maybe<BillingReceiptToPayDetailsFieldInput>>>;
  services?: Maybe<Array<BillingReceiptServiceFieldInput>>;
  services_not?: Maybe<Array<BillingReceiptServiceFieldInput>>;
  services_in?: Maybe<Array<Maybe<Array<BillingReceiptServiceFieldInput>>>>;
  services_not_in?: Maybe<Array<Maybe<Array<BillingReceiptServiceFieldInput>>>>;
  recipient?: Maybe<BillingReceiptsRecipientFieldInput>;
  recipient_not?: Maybe<BillingReceiptsRecipientFieldInput>;
  recipient_in?: Maybe<Array<Maybe<BillingReceiptsRecipientFieldInput>>>;
  recipient_not_in?: Maybe<Array<Maybe<BillingReceiptsRecipientFieldInput>>>;
  receiver?: Maybe<BillingRecipientWhereInput>;
  receiver_is_null?: Maybe<Scalars['Boolean']>;
  file?: Maybe<BillingReceiptFileWhereInput>;
  file_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingReceiptWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingReceiptsCreateInput = {
  data?: Maybe<BillingReceiptCreateInput>;
};

export type BillingReceiptsField = {
  __typename?: 'BillingReceiptsField';
  id: Scalars['String'];
};

export type BillingReceiptsFieldInput = {
  id: Scalars['String'];
};

export type BillingReceiptsRecipientField = {
  __typename?: 'BillingReceiptsRecipientField';
  name?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  tin: Scalars['String'];
  iec?: Maybe<Scalars['String']>;
  bic: Scalars['String'];
  bankAccount: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
};

export type BillingReceiptsRecipientFieldInput = {
  name?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  tin: Scalars['String'];
  iec?: Maybe<Scalars['String']>;
  bic: Scalars['String'];
  bankAccount: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
};

export type BillingReceiptsSumInput = {
  period: Scalars['String'];
  organization?: Maybe<OrganizationWhereUniqueInput>;
  tin?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type BillingReceiptsSumOutput = {
  __typename?: 'BillingReceiptsSumOutput';
  sum: Scalars['String'];
};

export type BillingReceiptsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptUpdateInput>;
};

/**  Organization' billing information: bank account, bic, and so on  */
export type BillingRecipient = {
  __typename?: 'BillingRecipient';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingRecipient List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingRecipient List config, or
   *  3. As an alias to a 'name' field on the BillingRecipient List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingRecipient List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  /**  Integration context  */
  context?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Tax Identification Number  */
  tin?: Maybe<Scalars['String']>;
  /**  Importer-Exporter Code  */
  iec?: Maybe<Scalars['String']>;
  /**  Bank Identification Code  */
  bic?: Maybe<Scalars['String']>;
  /**  Number of bank account of this recipient  */
  bankAccount?: Maybe<Scalars['String']>;
  /**  Bank name  */
  bankName?: Maybe<Scalars['String']>;
  /**  Bank account  */
  offsettingAccount?: Maybe<Scalars['String']>;
  /**  Location code (Classifier of Territories of Municipal Units - OKTMO)  */
  territoryCode?: Maybe<Scalars['String']>;
  /**  For what reason is this recipient is able to collect payments.  */
  purpose?: Maybe<Scalars['String']>;
  /**  Billing Recipient name. Usually the juristic name of the organization  */
  name?: Maybe<Scalars['String']>;
  /**  If set to True, then this billing recipient info is considered allowed and users are allowed to pay for receipts with this recipient  */
  isApproved?: Maybe<Scalars['Boolean']>;
  /**  Budget classification code, used for state-funded organizations  */
  classificationCode?: Maybe<Scalars['String']>;
  /**  Structured metadata obtained from the `billing data source`. The structure depends on the integration system.  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type BillingRecipientCreateInput = {
  importId?: Maybe<Scalars['String']>;
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  tin?: Maybe<Scalars['String']>;
  iec?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type BillingRecipientHistoryRecord = {
  __typename?: 'BillingRecipientHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the BillingRecipientHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the BillingRecipientHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the BillingRecipientHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the BillingRecipientHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  iec?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingRecipientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingRecipientHistoryRecordCreateInput = {
  importId?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  iec?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingRecipientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum BillingRecipientHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type BillingRecipientHistoryRecordUpdateInput = {
  importId?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  iec?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<BillingRecipientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type BillingRecipientHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<BillingRecipientHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingRecipientHistoryRecordWhereInput>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context?: Maybe<Scalars['String']>;
  context_not?: Maybe<Scalars['String']>;
  context_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iec?: Maybe<Scalars['String']>;
  iec_not?: Maybe<Scalars['String']>;
  iec_contains?: Maybe<Scalars['String']>;
  iec_not_contains?: Maybe<Scalars['String']>;
  iec_starts_with?: Maybe<Scalars['String']>;
  iec_not_starts_with?: Maybe<Scalars['String']>;
  iec_ends_with?: Maybe<Scalars['String']>;
  iec_not_ends_with?: Maybe<Scalars['String']>;
  iec_i?: Maybe<Scalars['String']>;
  iec_not_i?: Maybe<Scalars['String']>;
  iec_contains_i?: Maybe<Scalars['String']>;
  iec_not_contains_i?: Maybe<Scalars['String']>;
  iec_starts_with_i?: Maybe<Scalars['String']>;
  iec_not_starts_with_i?: Maybe<Scalars['String']>;
  iec_ends_with_i?: Maybe<Scalars['String']>;
  iec_not_ends_with_i?: Maybe<Scalars['String']>;
  iec_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iec_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bic?: Maybe<Scalars['String']>;
  bic_not?: Maybe<Scalars['String']>;
  bic_contains?: Maybe<Scalars['String']>;
  bic_not_contains?: Maybe<Scalars['String']>;
  bic_starts_with?: Maybe<Scalars['String']>;
  bic_not_starts_with?: Maybe<Scalars['String']>;
  bic_ends_with?: Maybe<Scalars['String']>;
  bic_not_ends_with?: Maybe<Scalars['String']>;
  bic_i?: Maybe<Scalars['String']>;
  bic_not_i?: Maybe<Scalars['String']>;
  bic_contains_i?: Maybe<Scalars['String']>;
  bic_not_contains_i?: Maybe<Scalars['String']>;
  bic_starts_with_i?: Maybe<Scalars['String']>;
  bic_not_starts_with_i?: Maybe<Scalars['String']>;
  bic_ends_with_i?: Maybe<Scalars['String']>;
  bic_not_ends_with_i?: Maybe<Scalars['String']>;
  bic_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bic_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccount_not?: Maybe<Scalars['String']>;
  bankAccount_contains?: Maybe<Scalars['String']>;
  bankAccount_not_contains?: Maybe<Scalars['String']>;
  bankAccount_starts_with?: Maybe<Scalars['String']>;
  bankAccount_not_starts_with?: Maybe<Scalars['String']>;
  bankAccount_ends_with?: Maybe<Scalars['String']>;
  bankAccount_not_ends_with?: Maybe<Scalars['String']>;
  bankAccount_i?: Maybe<Scalars['String']>;
  bankAccount_not_i?: Maybe<Scalars['String']>;
  bankAccount_contains_i?: Maybe<Scalars['String']>;
  bankAccount_not_contains_i?: Maybe<Scalars['String']>;
  bankAccount_starts_with_i?: Maybe<Scalars['String']>;
  bankAccount_not_starts_with_i?: Maybe<Scalars['String']>;
  bankAccount_ends_with_i?: Maybe<Scalars['String']>;
  bankAccount_not_ends_with_i?: Maybe<Scalars['String']>;
  bankAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  bankName_i?: Maybe<Scalars['String']>;
  bankName_not_i?: Maybe<Scalars['String']>;
  bankName_contains_i?: Maybe<Scalars['String']>;
  bankName_not_contains_i?: Maybe<Scalars['String']>;
  bankName_starts_with_i?: Maybe<Scalars['String']>;
  bankName_not_starts_with_i?: Maybe<Scalars['String']>;
  bankName_ends_with_i?: Maybe<Scalars['String']>;
  bankName_not_ends_with_i?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  offsettingAccount?: Maybe<Scalars['String']>;
  offsettingAccount_not?: Maybe<Scalars['String']>;
  offsettingAccount_contains?: Maybe<Scalars['String']>;
  offsettingAccount_not_contains?: Maybe<Scalars['String']>;
  offsettingAccount_starts_with?: Maybe<Scalars['String']>;
  offsettingAccount_not_starts_with?: Maybe<Scalars['String']>;
  offsettingAccount_ends_with?: Maybe<Scalars['String']>;
  offsettingAccount_not_ends_with?: Maybe<Scalars['String']>;
  offsettingAccount_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_i?: Maybe<Scalars['String']>;
  offsettingAccount_contains_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_contains_i?: Maybe<Scalars['String']>;
  offsettingAccount_starts_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_starts_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_ends_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_ends_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  offsettingAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode?: Maybe<Scalars['String']>;
  territoryCode_not?: Maybe<Scalars['String']>;
  territoryCode_contains?: Maybe<Scalars['String']>;
  territoryCode_not_contains?: Maybe<Scalars['String']>;
  territoryCode_starts_with?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with?: Maybe<Scalars['String']>;
  territoryCode_ends_with?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with?: Maybe<Scalars['String']>;
  territoryCode_i?: Maybe<Scalars['String']>;
  territoryCode_not_i?: Maybe<Scalars['String']>;
  territoryCode_contains_i?: Maybe<Scalars['String']>;
  territoryCode_not_contains_i?: Maybe<Scalars['String']>;
  territoryCode_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose?: Maybe<Scalars['String']>;
  purpose_not?: Maybe<Scalars['String']>;
  purpose_contains?: Maybe<Scalars['String']>;
  purpose_not_contains?: Maybe<Scalars['String']>;
  purpose_starts_with?: Maybe<Scalars['String']>;
  purpose_not_starts_with?: Maybe<Scalars['String']>;
  purpose_ends_with?: Maybe<Scalars['String']>;
  purpose_not_ends_with?: Maybe<Scalars['String']>;
  purpose_i?: Maybe<Scalars['String']>;
  purpose_not_i?: Maybe<Scalars['String']>;
  purpose_contains_i?: Maybe<Scalars['String']>;
  purpose_not_contains_i?: Maybe<Scalars['String']>;
  purpose_starts_with_i?: Maybe<Scalars['String']>;
  purpose_not_starts_with_i?: Maybe<Scalars['String']>;
  purpose_ends_with_i?: Maybe<Scalars['String']>;
  purpose_not_ends_with_i?: Maybe<Scalars['String']>;
  purpose_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationCode?: Maybe<Scalars['String']>;
  classificationCode_not?: Maybe<Scalars['String']>;
  classificationCode_contains?: Maybe<Scalars['String']>;
  classificationCode_not_contains?: Maybe<Scalars['String']>;
  classificationCode_starts_with?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with?: Maybe<Scalars['String']>;
  classificationCode_ends_with?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with?: Maybe<Scalars['String']>;
  classificationCode_i?: Maybe<Scalars['String']>;
  classificationCode_not_i?: Maybe<Scalars['String']>;
  classificationCode_contains_i?: Maybe<Scalars['String']>;
  classificationCode_not_contains_i?: Maybe<Scalars['String']>;
  classificationCode_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<BillingRecipientHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<BillingRecipientHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<BillingRecipientHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<BillingRecipientHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BillingRecipientHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingRecipientHistoryRecordsCreateInput = {
  data?: Maybe<BillingRecipientHistoryRecordCreateInput>;
};

export type BillingRecipientHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingRecipientHistoryRecordUpdateInput>;
};

export type BillingRecipientRelateToOneInput = {
  create?: Maybe<BillingRecipientCreateInput>;
  connect?: Maybe<BillingRecipientWhereUniqueInput>;
  disconnect?: Maybe<BillingRecipientWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type BillingRecipientUpdateInput = {
  importId?: Maybe<Scalars['String']>;
  context?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  tin?: Maybe<Scalars['String']>;
  iec?: Maybe<Scalars['String']>;
  bic?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  classificationCode?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type BillingRecipientWhereInput = {
  AND?: Maybe<Array<Maybe<BillingRecipientWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillingRecipientWhereInput>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iec?: Maybe<Scalars['String']>;
  iec_not?: Maybe<Scalars['String']>;
  iec_contains?: Maybe<Scalars['String']>;
  iec_not_contains?: Maybe<Scalars['String']>;
  iec_starts_with?: Maybe<Scalars['String']>;
  iec_not_starts_with?: Maybe<Scalars['String']>;
  iec_ends_with?: Maybe<Scalars['String']>;
  iec_not_ends_with?: Maybe<Scalars['String']>;
  iec_i?: Maybe<Scalars['String']>;
  iec_not_i?: Maybe<Scalars['String']>;
  iec_contains_i?: Maybe<Scalars['String']>;
  iec_not_contains_i?: Maybe<Scalars['String']>;
  iec_starts_with_i?: Maybe<Scalars['String']>;
  iec_not_starts_with_i?: Maybe<Scalars['String']>;
  iec_ends_with_i?: Maybe<Scalars['String']>;
  iec_not_ends_with_i?: Maybe<Scalars['String']>;
  iec_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  iec_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bic?: Maybe<Scalars['String']>;
  bic_not?: Maybe<Scalars['String']>;
  bic_contains?: Maybe<Scalars['String']>;
  bic_not_contains?: Maybe<Scalars['String']>;
  bic_starts_with?: Maybe<Scalars['String']>;
  bic_not_starts_with?: Maybe<Scalars['String']>;
  bic_ends_with?: Maybe<Scalars['String']>;
  bic_not_ends_with?: Maybe<Scalars['String']>;
  bic_i?: Maybe<Scalars['String']>;
  bic_not_i?: Maybe<Scalars['String']>;
  bic_contains_i?: Maybe<Scalars['String']>;
  bic_not_contains_i?: Maybe<Scalars['String']>;
  bic_starts_with_i?: Maybe<Scalars['String']>;
  bic_not_starts_with_i?: Maybe<Scalars['String']>;
  bic_ends_with_i?: Maybe<Scalars['String']>;
  bic_not_ends_with_i?: Maybe<Scalars['String']>;
  bic_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bic_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankAccount?: Maybe<Scalars['String']>;
  bankAccount_not?: Maybe<Scalars['String']>;
  bankAccount_contains?: Maybe<Scalars['String']>;
  bankAccount_not_contains?: Maybe<Scalars['String']>;
  bankAccount_starts_with?: Maybe<Scalars['String']>;
  bankAccount_not_starts_with?: Maybe<Scalars['String']>;
  bankAccount_ends_with?: Maybe<Scalars['String']>;
  bankAccount_not_ends_with?: Maybe<Scalars['String']>;
  bankAccount_i?: Maybe<Scalars['String']>;
  bankAccount_not_i?: Maybe<Scalars['String']>;
  bankAccount_contains_i?: Maybe<Scalars['String']>;
  bankAccount_not_contains_i?: Maybe<Scalars['String']>;
  bankAccount_starts_with_i?: Maybe<Scalars['String']>;
  bankAccount_not_starts_with_i?: Maybe<Scalars['String']>;
  bankAccount_ends_with_i?: Maybe<Scalars['String']>;
  bankAccount_not_ends_with_i?: Maybe<Scalars['String']>;
  bankAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName?: Maybe<Scalars['String']>;
  bankName_not?: Maybe<Scalars['String']>;
  bankName_contains?: Maybe<Scalars['String']>;
  bankName_not_contains?: Maybe<Scalars['String']>;
  bankName_starts_with?: Maybe<Scalars['String']>;
  bankName_not_starts_with?: Maybe<Scalars['String']>;
  bankName_ends_with?: Maybe<Scalars['String']>;
  bankName_not_ends_with?: Maybe<Scalars['String']>;
  bankName_i?: Maybe<Scalars['String']>;
  bankName_not_i?: Maybe<Scalars['String']>;
  bankName_contains_i?: Maybe<Scalars['String']>;
  bankName_not_contains_i?: Maybe<Scalars['String']>;
  bankName_starts_with_i?: Maybe<Scalars['String']>;
  bankName_not_starts_with_i?: Maybe<Scalars['String']>;
  bankName_ends_with_i?: Maybe<Scalars['String']>;
  bankName_not_ends_with_i?: Maybe<Scalars['String']>;
  bankName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  bankName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  offsettingAccount?: Maybe<Scalars['String']>;
  offsettingAccount_not?: Maybe<Scalars['String']>;
  offsettingAccount_contains?: Maybe<Scalars['String']>;
  offsettingAccount_not_contains?: Maybe<Scalars['String']>;
  offsettingAccount_starts_with?: Maybe<Scalars['String']>;
  offsettingAccount_not_starts_with?: Maybe<Scalars['String']>;
  offsettingAccount_ends_with?: Maybe<Scalars['String']>;
  offsettingAccount_not_ends_with?: Maybe<Scalars['String']>;
  offsettingAccount_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_i?: Maybe<Scalars['String']>;
  offsettingAccount_contains_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_contains_i?: Maybe<Scalars['String']>;
  offsettingAccount_starts_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_starts_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_ends_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_not_ends_with_i?: Maybe<Scalars['String']>;
  offsettingAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  offsettingAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode?: Maybe<Scalars['String']>;
  territoryCode_not?: Maybe<Scalars['String']>;
  territoryCode_contains?: Maybe<Scalars['String']>;
  territoryCode_not_contains?: Maybe<Scalars['String']>;
  territoryCode_starts_with?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with?: Maybe<Scalars['String']>;
  territoryCode_ends_with?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with?: Maybe<Scalars['String']>;
  territoryCode_i?: Maybe<Scalars['String']>;
  territoryCode_not_i?: Maybe<Scalars['String']>;
  territoryCode_contains_i?: Maybe<Scalars['String']>;
  territoryCode_not_contains_i?: Maybe<Scalars['String']>;
  territoryCode_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_starts_with_i?: Maybe<Scalars['String']>;
  territoryCode_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_not_ends_with_i?: Maybe<Scalars['String']>;
  territoryCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  territoryCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose?: Maybe<Scalars['String']>;
  purpose_not?: Maybe<Scalars['String']>;
  purpose_contains?: Maybe<Scalars['String']>;
  purpose_not_contains?: Maybe<Scalars['String']>;
  purpose_starts_with?: Maybe<Scalars['String']>;
  purpose_not_starts_with?: Maybe<Scalars['String']>;
  purpose_ends_with?: Maybe<Scalars['String']>;
  purpose_not_ends_with?: Maybe<Scalars['String']>;
  purpose_i?: Maybe<Scalars['String']>;
  purpose_not_i?: Maybe<Scalars['String']>;
  purpose_contains_i?: Maybe<Scalars['String']>;
  purpose_not_contains_i?: Maybe<Scalars['String']>;
  purpose_starts_with_i?: Maybe<Scalars['String']>;
  purpose_not_starts_with_i?: Maybe<Scalars['String']>;
  purpose_ends_with_i?: Maybe<Scalars['String']>;
  purpose_not_ends_with_i?: Maybe<Scalars['String']>;
  purpose_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationCode?: Maybe<Scalars['String']>;
  classificationCode_not?: Maybe<Scalars['String']>;
  classificationCode_contains?: Maybe<Scalars['String']>;
  classificationCode_not_contains?: Maybe<Scalars['String']>;
  classificationCode_starts_with?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with?: Maybe<Scalars['String']>;
  classificationCode_ends_with?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with?: Maybe<Scalars['String']>;
  classificationCode_i?: Maybe<Scalars['String']>;
  classificationCode_not_i?: Maybe<Scalars['String']>;
  classificationCode_contains_i?: Maybe<Scalars['String']>;
  classificationCode_not_contains_i?: Maybe<Scalars['String']>;
  classificationCode_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_starts_with_i?: Maybe<Scalars['String']>;
  classificationCode_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_not_ends_with_i?: Maybe<Scalars['String']>;
  classificationCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classificationCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type BillingRecipientWhereUniqueInput = {
  id: Scalars['ID'];
};

export type BillingRecipientsCreateInput = {
  data?: Maybe<BillingRecipientCreateInput>;
};

export type BillingRecipientsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<BillingRecipientUpdateInput>;
};

export type BuildingFloor = {
  __typename?: 'BuildingFloor';
  id: Scalars['String'];
  type: BuildingFloorType;
  index: Scalars['Int'];
  name: Scalars['String'];
  units: Array<Maybe<BuildingUnit>>;
};

export enum BuildingFloorType {
  Floor = 'floor'
}

/** Technical map of the 'building' type Property object. We assume that there will be different maps for different property types.  */
export type BuildingMap = {
  __typename?: 'BuildingMap';
  dv: Scalars['Int'];
  sections?: Maybe<Array<Maybe<BuildingSection>>>;
  parking?: Maybe<Array<Maybe<BuildingSection>>>;
  type?: Maybe<BuildingMapType>;
};

export enum BuildingMapEntityType {
  Building = 'building',
  Section = 'section',
  Floor = 'floor',
  Unit = 'unit',
  Village = 'village'
}

export enum BuildingMapType {
  Building = 'building'
}

export type BuildingSection = {
  __typename?: 'BuildingSection';
  id: Scalars['String'];
  type: BuildingSectionType;
  index: Scalars['Int'];
  name: Scalars['String'];
  floors: Array<Maybe<BuildingFloor>>;
  preview?: Maybe<Scalars['Boolean']>;
};

export enum BuildingSectionType {
  Section = 'section'
}

export type BuildingUnit = {
  __typename?: 'BuildingUnit';
  id: Scalars['String'];
  type: BuildingUnitType;
  unitType?: Maybe<BuildingUnitSubType>;
  name?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  preview?: Maybe<Scalars['Boolean']>;
};

export enum BuildingUnitSubType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export enum BuildingUnitType {
  Unit = 'unit'
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type CalculateFeeForReceiptInput = {
  receipt: BillingReceiptWhereUniqueInput;
  amount: Scalars['String'];
};

export type CalculateFeeForReceiptOutput = {
  __typename?: 'CalculateFeeForReceiptOutput';
  amountWithoutExplicitFee: Scalars['String'];
  explicitFee: Scalars['String'];
  explicitServiceCharge: Scalars['String'];
};

/**  Conversation record between operator and client  */
export type CallRecord = {
  __typename?: 'CallRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecord List config, or
   *  3. As an alias to a 'name' field on the CallRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Organization of the operator  */
  organization?: Maybe<Organization>;
  /**  Conversation file  */
  file?: Maybe<File>;
  /**  Phone number of the person who called  */
  callerPhone?: Maybe<Scalars['String']>;
  /**  Phone number of the person to whom called  */
  destCallerPhone?: Maybe<Scalars['String']>;
  /**  Time of conversation between operator and client (in seconds)  */
  talkTime?: Maybe<Scalars['Int']>;
  /**  Call start date in UTC  */
  startedAt?: Maybe<Scalars['String']>;
  /**  Incoming or outgoing call for operator  */
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  /**  Call unique identifier  */
  importId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type CallRecordCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  file?: Maybe<Scalars['Upload']>;
  callerPhone?: Maybe<Scalars['String']>;
  destCallerPhone?: Maybe<Scalars['String']>;
  talkTime?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['String']>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  M2M relationship between ticket and call record  */
export type CallRecordFragment = {
  __typename?: 'CallRecordFragment';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecordFragment List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecordFragment List config, or
   *  3. As an alias to a 'name' field on the CallRecordFragment List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecordFragment List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ticket to which this call fragment belongs  */
  ticket?: Maybe<Ticket>;
  /**  Call record to which this call fragment belongs  */
  callRecord?: Maybe<CallRecord>;
  /**  Call fragment start date in UTC  */
  startedAt?: Maybe<Scalars['String']>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type CallRecordFragmentCreateInput = {
  ticket?: Maybe<TicketRelateToOneInput>;
  callRecord?: Maybe<CallRecordRelateToOneInput>;
  startedAt?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type CallRecordFragmentHistoryRecord = {
  __typename?: 'CallRecordFragmentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecordFragmentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecordFragmentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the CallRecordFragmentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecordFragmentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  callRecord?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<CallRecordFragmentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type CallRecordFragmentHistoryRecordCreateInput = {
  ticket?: Maybe<Scalars['String']>;
  callRecord?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<CallRecordFragmentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum CallRecordFragmentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type CallRecordFragmentHistoryRecordUpdateInput = {
  ticket?: Maybe<Scalars['String']>;
  callRecord?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<CallRecordFragmentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type CallRecordFragmentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecordWhereInput>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  callRecord?: Maybe<Scalars['String']>;
  callRecord_not?: Maybe<Scalars['String']>;
  callRecord_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  callRecord_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt?: Maybe<Scalars['String']>;
  startedAt_not?: Maybe<Scalars['String']>;
  startedAt_lt?: Maybe<Scalars['String']>;
  startedAt_lte?: Maybe<Scalars['String']>;
  startedAt_gt?: Maybe<Scalars['String']>;
  startedAt_gte?: Maybe<Scalars['String']>;
  startedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<CallRecordFragmentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<CallRecordFragmentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CallRecordFragmentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CallRecordFragmentHistoryRecordsCreateInput = {
  data?: Maybe<CallRecordFragmentHistoryRecordCreateInput>;
};

export type CallRecordFragmentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordFragmentHistoryRecordUpdateInput>;
};

export type CallRecordFragmentUpdateInput = {
  ticket?: Maybe<TicketRelateToOneInput>;
  callRecord?: Maybe<CallRecordRelateToOneInput>;
  startedAt?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type CallRecordFragmentWhereInput = {
  AND?: Maybe<Array<Maybe<CallRecordFragmentWhereInput>>>;
  OR?: Maybe<Array<Maybe<CallRecordFragmentWhereInput>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  callRecord?: Maybe<CallRecordWhereInput>;
  callRecord_is_null?: Maybe<Scalars['Boolean']>;
  startedAt?: Maybe<Scalars['String']>;
  startedAt_not?: Maybe<Scalars['String']>;
  startedAt_lt?: Maybe<Scalars['String']>;
  startedAt_lte?: Maybe<Scalars['String']>;
  startedAt_gt?: Maybe<Scalars['String']>;
  startedAt_gte?: Maybe<Scalars['String']>;
  startedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type CallRecordFragmentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CallRecordFragmentsCreateInput = {
  data?: Maybe<CallRecordFragmentCreateInput>;
};

export type CallRecordFragmentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordFragmentUpdateInput>;
};

/**  A keystone list  */
export type CallRecordHistoryRecord = {
  __typename?: 'CallRecordHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the CallRecordHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the CallRecordHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the CallRecordHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the CallRecordHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  callerPhone?: Maybe<Scalars['String']>;
  destCallerPhone?: Maybe<Scalars['String']>;
  talkTime?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['String']>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<CallRecordHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type CallRecordHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  callerPhone?: Maybe<Scalars['String']>;
  destCallerPhone?: Maybe<Scalars['String']>;
  talkTime?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['String']>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<CallRecordHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum CallRecordHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type CallRecordHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  callerPhone?: Maybe<Scalars['String']>;
  destCallerPhone?: Maybe<Scalars['String']>;
  talkTime?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['String']>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<CallRecordHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type CallRecordHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<CallRecordHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<CallRecordHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  callerPhone?: Maybe<Scalars['String']>;
  callerPhone_not?: Maybe<Scalars['String']>;
  callerPhone_contains?: Maybe<Scalars['String']>;
  callerPhone_not_contains?: Maybe<Scalars['String']>;
  callerPhone_starts_with?: Maybe<Scalars['String']>;
  callerPhone_not_starts_with?: Maybe<Scalars['String']>;
  callerPhone_ends_with?: Maybe<Scalars['String']>;
  callerPhone_not_ends_with?: Maybe<Scalars['String']>;
  callerPhone_i?: Maybe<Scalars['String']>;
  callerPhone_not_i?: Maybe<Scalars['String']>;
  callerPhone_contains_i?: Maybe<Scalars['String']>;
  callerPhone_not_contains_i?: Maybe<Scalars['String']>;
  callerPhone_starts_with_i?: Maybe<Scalars['String']>;
  callerPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  callerPhone_ends_with_i?: Maybe<Scalars['String']>;
  callerPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  callerPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  callerPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  destCallerPhone?: Maybe<Scalars['String']>;
  destCallerPhone_not?: Maybe<Scalars['String']>;
  destCallerPhone_contains?: Maybe<Scalars['String']>;
  destCallerPhone_not_contains?: Maybe<Scalars['String']>;
  destCallerPhone_starts_with?: Maybe<Scalars['String']>;
  destCallerPhone_not_starts_with?: Maybe<Scalars['String']>;
  destCallerPhone_ends_with?: Maybe<Scalars['String']>;
  destCallerPhone_not_ends_with?: Maybe<Scalars['String']>;
  destCallerPhone_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_i?: Maybe<Scalars['String']>;
  destCallerPhone_contains_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_contains_i?: Maybe<Scalars['String']>;
  destCallerPhone_starts_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_ends_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  destCallerPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  talkTime?: Maybe<Scalars['Int']>;
  talkTime_not?: Maybe<Scalars['Int']>;
  talkTime_lt?: Maybe<Scalars['Int']>;
  talkTime_lte?: Maybe<Scalars['Int']>;
  talkTime_gt?: Maybe<Scalars['Int']>;
  talkTime_gte?: Maybe<Scalars['Int']>;
  talkTime_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  talkTime_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startedAt?: Maybe<Scalars['String']>;
  startedAt_not?: Maybe<Scalars['String']>;
  startedAt_lt?: Maybe<Scalars['String']>;
  startedAt_lte?: Maybe<Scalars['String']>;
  startedAt_gt?: Maybe<Scalars['String']>;
  startedAt_gte?: Maybe<Scalars['String']>;
  startedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  isIncomingCall_not?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<CallRecordHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<CallRecordHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<CallRecordHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<CallRecordHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CallRecordHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CallRecordHistoryRecordsCreateInput = {
  data?: Maybe<CallRecordHistoryRecordCreateInput>;
};

export type CallRecordHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordHistoryRecordUpdateInput>;
};

export type CallRecordRelateToOneInput = {
  create?: Maybe<CallRecordCreateInput>;
  connect?: Maybe<CallRecordWhereUniqueInput>;
  disconnect?: Maybe<CallRecordWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type CallRecordUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  file?: Maybe<Scalars['Upload']>;
  callerPhone?: Maybe<Scalars['String']>;
  destCallerPhone?: Maybe<Scalars['String']>;
  talkTime?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['String']>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type CallRecordWhereInput = {
  AND?: Maybe<Array<Maybe<CallRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<CallRecordWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  callerPhone?: Maybe<Scalars['String']>;
  callerPhone_not?: Maybe<Scalars['String']>;
  callerPhone_contains?: Maybe<Scalars['String']>;
  callerPhone_not_contains?: Maybe<Scalars['String']>;
  callerPhone_starts_with?: Maybe<Scalars['String']>;
  callerPhone_not_starts_with?: Maybe<Scalars['String']>;
  callerPhone_ends_with?: Maybe<Scalars['String']>;
  callerPhone_not_ends_with?: Maybe<Scalars['String']>;
  callerPhone_i?: Maybe<Scalars['String']>;
  callerPhone_not_i?: Maybe<Scalars['String']>;
  callerPhone_contains_i?: Maybe<Scalars['String']>;
  callerPhone_not_contains_i?: Maybe<Scalars['String']>;
  callerPhone_starts_with_i?: Maybe<Scalars['String']>;
  callerPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  callerPhone_ends_with_i?: Maybe<Scalars['String']>;
  callerPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  callerPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  callerPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  destCallerPhone?: Maybe<Scalars['String']>;
  destCallerPhone_not?: Maybe<Scalars['String']>;
  destCallerPhone_contains?: Maybe<Scalars['String']>;
  destCallerPhone_not_contains?: Maybe<Scalars['String']>;
  destCallerPhone_starts_with?: Maybe<Scalars['String']>;
  destCallerPhone_not_starts_with?: Maybe<Scalars['String']>;
  destCallerPhone_ends_with?: Maybe<Scalars['String']>;
  destCallerPhone_not_ends_with?: Maybe<Scalars['String']>;
  destCallerPhone_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_i?: Maybe<Scalars['String']>;
  destCallerPhone_contains_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_contains_i?: Maybe<Scalars['String']>;
  destCallerPhone_starts_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_ends_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  destCallerPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  destCallerPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  talkTime?: Maybe<Scalars['Int']>;
  talkTime_not?: Maybe<Scalars['Int']>;
  talkTime_lt?: Maybe<Scalars['Int']>;
  talkTime_lte?: Maybe<Scalars['Int']>;
  talkTime_gt?: Maybe<Scalars['Int']>;
  talkTime_gte?: Maybe<Scalars['Int']>;
  talkTime_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  talkTime_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startedAt?: Maybe<Scalars['String']>;
  startedAt_not?: Maybe<Scalars['String']>;
  startedAt_lt?: Maybe<Scalars['String']>;
  startedAt_lte?: Maybe<Scalars['String']>;
  startedAt_gt?: Maybe<Scalars['String']>;
  startedAt_gte?: Maybe<Scalars['String']>;
  startedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isIncomingCall?: Maybe<Scalars['Boolean']>;
  isIncomingCall_not?: Maybe<Scalars['Boolean']>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type CallRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type CallRecordsCreateInput = {
  data?: Maybe<CallRecordCreateInput>;
};

export type CallRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordUpdateInput>;
};

export type ChangePasswordWithTokenInput = {
  token: Scalars['String'];
  password: Scalars['String'];
  sender: SenderFieldInput;
  dv: Scalars['Int'];
};

export type ChangePasswordWithTokenOutput = {
  __typename?: 'ChangePasswordWithTokenOutput';
  status: Scalars['String'];
  phone: Scalars['String'];
};

export type ChangePhoneNumberResidentUserInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  token: Scalars['String'];
  removeUserExternalIdentitiesIfPhoneDifferent?: Maybe<Scalars['Boolean']>;
};

export type ChangePhoneNumberResidentUserOutput = {
  __typename?: 'ChangePhoneNumberResidentUserOutput';
  status: Scalars['String'];
};

export type CheckUserExistenceInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  confirmActionToken: Scalars['ID'];
  userType: UserTypeType;
};

export type CheckUserExistenceOutput = {
  __typename?: 'CheckUserExistenceOutput';
  userExists: Scalars['Boolean'];
  nameSet: Scalars['Boolean'];
  emailSet: Scalars['Boolean'];
  phoneSet: Scalars['Boolean'];
  passwordSet: Scalars['Boolean'];
};

export type CompleteConfirmPhoneActionInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  captcha: Scalars['String'];
  token: Scalars['String'];
  smsCode: Scalars['Int'];
};

export type CompleteConfirmPhoneActionOutput = {
  __typename?: 'CompleteConfirmPhoneActionOutput';
  status: Scalars['String'];
};

/**  User confirm phone actions is used before registration starts  */
export type ConfirmPhoneAction = {
  __typename?: 'ConfirmPhoneAction';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ConfirmPhoneAction List config, or
   *  2. As an alias to the field set on 'labelField' in the ConfirmPhoneAction List config, or
   *  3. As an alias to a 'name' field on the ConfirmPhoneAction List (if one exists), or
   *  4. As an alias to the 'id' field on the ConfirmPhoneAction List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Normalized phone in E.164 format without spaces  */
  phone?: Maybe<Scalars['String']>;
  /**  Unique token to complete confirmation  */
  token?: Maybe<Scalars['String']>;
  /**  Last sms code sent to user  */
  smsCode?: Maybe<Scalars['Int']>;
  /**  Time when sms code was requested  */
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  /**  Time when sms code becomes not valid  */
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  /**  Number of times sms code input from user failed  */
  retries?: Maybe<Scalars['Int']>;
  /**  Phone verification flag. User verify phone by access to secret sms message  */
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  /**  DateTime when confirm phone action was started  */
  requestedAt?: Maybe<Scalars['String']>;
  /**  When confirm phone action becomes invalid  */
  expiresAt?: Maybe<Scalars['String']>;
  /**  When confirm phone action was completed  */
  completedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ConfirmPhoneActionCreateInput = {
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  retries?: Maybe<Scalars['Int']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type ConfirmPhoneActionHistoryRecord = {
  __typename?: 'ConfirmPhoneActionHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ConfirmPhoneActionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ConfirmPhoneActionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ConfirmPhoneActionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ConfirmPhoneActionHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  retries?: Maybe<Scalars['Int']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ConfirmPhoneActionHistoryRecordCreateInput = {
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  retries?: Maybe<Scalars['Int']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ConfirmPhoneActionHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ConfirmPhoneActionHistoryRecordUpdateInput = {
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  retries?: Maybe<Scalars['Int']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ConfirmPhoneActionHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecordWhereInput>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_contains?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  token_i?: Maybe<Scalars['String']>;
  token_not_i?: Maybe<Scalars['String']>;
  token_contains_i?: Maybe<Scalars['String']>;
  token_not_contains_i?: Maybe<Scalars['String']>;
  token_starts_with_i?: Maybe<Scalars['String']>;
  token_not_starts_with_i?: Maybe<Scalars['String']>;
  token_ends_with_i?: Maybe<Scalars['String']>;
  token_not_ends_with_i?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  token_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCode_not?: Maybe<Scalars['Int']>;
  smsCode_lt?: Maybe<Scalars['Int']>;
  smsCode_lte?: Maybe<Scalars['Int']>;
  smsCode_gt?: Maybe<Scalars['Int']>;
  smsCode_gte?: Maybe<Scalars['Int']>;
  smsCode_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  smsCode_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_not?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_lt?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_lte?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_gt?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_gte?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCodeRequestedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_not?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_lt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_lte?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_gt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_gte?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCodeExpiresAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  retries?: Maybe<Scalars['Int']>;
  retries_not?: Maybe<Scalars['Int']>;
  retries_lt?: Maybe<Scalars['Int']>;
  retries_lte?: Maybe<Scalars['Int']>;
  retries_gt?: Maybe<Scalars['Int']>;
  retries_gte?: Maybe<Scalars['Int']>;
  retries_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  retries_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  isPhoneVerified_not?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  requestedAt_not?: Maybe<Scalars['String']>;
  requestedAt_lt?: Maybe<Scalars['String']>;
  requestedAt_lte?: Maybe<Scalars['String']>;
  requestedAt_gt?: Maybe<Scalars['String']>;
  requestedAt_gte?: Maybe<Scalars['String']>;
  requestedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiresAt?: Maybe<Scalars['String']>;
  expiresAt_not?: Maybe<Scalars['String']>;
  expiresAt_lt?: Maybe<Scalars['String']>;
  expiresAt_lte?: Maybe<Scalars['String']>;
  expiresAt_gt?: Maybe<Scalars['String']>;
  expiresAt_gte?: Maybe<Scalars['String']>;
  expiresAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiresAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt?: Maybe<Scalars['String']>;
  completedAt_not?: Maybe<Scalars['String']>;
  completedAt_lt?: Maybe<Scalars['String']>;
  completedAt_lte?: Maybe<Scalars['String']>;
  completedAt_gt?: Maybe<Scalars['String']>;
  completedAt_gte?: Maybe<Scalars['String']>;
  completedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ConfirmPhoneActionHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ConfirmPhoneActionHistoryRecordsCreateInput = {
  data?: Maybe<ConfirmPhoneActionHistoryRecordCreateInput>;
};

export type ConfirmPhoneActionHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ConfirmPhoneActionHistoryRecordUpdateInput>;
};

export type ConfirmPhoneActionUpdateInput = {
  phone?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  retries?: Maybe<Scalars['Int']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ConfirmPhoneActionWhereInput = {
  AND?: Maybe<Array<Maybe<ConfirmPhoneActionWhereInput>>>;
  OR?: Maybe<Array<Maybe<ConfirmPhoneActionWhereInput>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  token?: Maybe<Scalars['String']>;
  token_not?: Maybe<Scalars['String']>;
  token_contains?: Maybe<Scalars['String']>;
  token_not_contains?: Maybe<Scalars['String']>;
  token_starts_with?: Maybe<Scalars['String']>;
  token_not_starts_with?: Maybe<Scalars['String']>;
  token_ends_with?: Maybe<Scalars['String']>;
  token_not_ends_with?: Maybe<Scalars['String']>;
  token_i?: Maybe<Scalars['String']>;
  token_not_i?: Maybe<Scalars['String']>;
  token_contains_i?: Maybe<Scalars['String']>;
  token_not_contains_i?: Maybe<Scalars['String']>;
  token_starts_with_i?: Maybe<Scalars['String']>;
  token_not_starts_with_i?: Maybe<Scalars['String']>;
  token_ends_with_i?: Maybe<Scalars['String']>;
  token_not_ends_with_i?: Maybe<Scalars['String']>;
  token_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  token_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCode?: Maybe<Scalars['Int']>;
  smsCode_not?: Maybe<Scalars['Int']>;
  smsCode_lt?: Maybe<Scalars['Int']>;
  smsCode_lte?: Maybe<Scalars['Int']>;
  smsCode_gt?: Maybe<Scalars['Int']>;
  smsCode_gte?: Maybe<Scalars['Int']>;
  smsCode_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  smsCode_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  smsCodeRequestedAt?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_not?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_lt?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_lte?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_gt?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_gte?: Maybe<Scalars['String']>;
  smsCodeRequestedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCodeRequestedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCodeExpiresAt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_not?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_lt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_lte?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_gt?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_gte?: Maybe<Scalars['String']>;
  smsCodeExpiresAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  smsCodeExpiresAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  retries?: Maybe<Scalars['Int']>;
  retries_not?: Maybe<Scalars['Int']>;
  retries_lt?: Maybe<Scalars['Int']>;
  retries_lte?: Maybe<Scalars['Int']>;
  retries_gt?: Maybe<Scalars['Int']>;
  retries_gte?: Maybe<Scalars['Int']>;
  retries_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  retries_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  isPhoneVerified_not?: Maybe<Scalars['Boolean']>;
  requestedAt?: Maybe<Scalars['String']>;
  requestedAt_not?: Maybe<Scalars['String']>;
  requestedAt_lt?: Maybe<Scalars['String']>;
  requestedAt_lte?: Maybe<Scalars['String']>;
  requestedAt_gt?: Maybe<Scalars['String']>;
  requestedAt_gte?: Maybe<Scalars['String']>;
  requestedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiresAt?: Maybe<Scalars['String']>;
  expiresAt_not?: Maybe<Scalars['String']>;
  expiresAt_lt?: Maybe<Scalars['String']>;
  expiresAt_lte?: Maybe<Scalars['String']>;
  expiresAt_gt?: Maybe<Scalars['String']>;
  expiresAt_gte?: Maybe<Scalars['String']>;
  expiresAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiresAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt?: Maybe<Scalars['String']>;
  completedAt_not?: Maybe<Scalars['String']>;
  completedAt_lt?: Maybe<Scalars['String']>;
  completedAt_lte?: Maybe<Scalars['String']>;
  completedAt_gt?: Maybe<Scalars['String']>;
  completedAt_gte?: Maybe<Scalars['String']>;
  completedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ConfirmPhoneActionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ConfirmPhoneActionsCreateInput = {
  data?: Maybe<ConfirmPhoneActionCreateInput>;
};

export type ConfirmPhoneActionsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ConfirmPhoneActionUpdateInput>;
};

/**  Contact information of a person. Currently it will be related to a ticket, but in the future, it will be associated with more things  */
export type Contact = {
  __typename?: 'Contact';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Contact List config, or
   *  2. As an alias to the field set on 'labelField' in the Contact List config, or
   *  3. As an alias to a 'name' field on the Contact List (if one exists), or
   *  4. As an alias to the 'id' field on the Contact List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Property, that is a subject of an issue, reported by this person in first ticket. Meaning of this field will be revised in the future  */
  property?: Maybe<Property>;
  /**  Property unit, that is a subject of an issue, reported by this person in first ticket. Meaning of this field will be revised in the future  */
  unitName?: Maybe<Scalars['String']>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitType?: Maybe<ContactUnitTypeType>;
  /**  Normalized contact email of this person  */
  email?: Maybe<Scalars['String']>;
  /**  Normalized contact phone of this person in E.164 format without spaces  */
  phone?: Maybe<Scalars['String']>;
  /**  Name or full name of this person  */
  name?: Maybe<Scalars['String']>;
  /**  The contact's role  */
  role?: Maybe<ContactRole>;
  /**  Contact verification flag.  */
  isVerified?: Maybe<Scalars['Boolean']>;
  /**  Percentage ownership of an apartment  */
  quota?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ContactCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<ContactUnitTypeType>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<ContactRoleRelateToOneInput>;
  isVerified?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type ContactExportTask = {
  __typename?: 'ContactExportTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactExportTask List config, or
   *  3. As an alias to a 'name' field on the ContactExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactExportTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Status of the job. Used at client side progress information component  */
  status?: Maybe<ContactExportTaskStatusType>;
  /**  Requested export file format  */
  format?: Maybe<ContactExportTaskFormatType>;
  /**  How many records at the moment are exported  */
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  /**  Total records to export that will be determined at server side in export operation  */
  totalRecordsCount?: Maybe<Scalars['Int']>;
  /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  file?: Maybe<File>;
  /**  Stores information about query and ids of exported and failed records  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Filtering conditions for records to export  */
  where?: Maybe<Scalars['JSON']>;
  /**  Sorting parameters for records to export  */
  sortBy?: Maybe<Scalars['JSON']>;
  /**  Requested export locale, in that the resulting file will be rendered  */
  locale?: Maybe<Scalars['String']>;
  /**  To requested timeZone all datetime fields will be converted  */
  timeZone?: Maybe<Scalars['String']>;
  /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ContactExportTaskCreateInput = {
  status?: Maybe<ContactExportTaskStatusType>;
  format?: Maybe<ContactExportTaskFormatType>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum ContactExportTaskFormatType {
  Excel = 'excel'
}

/**  A keystone list  */
export type ContactExportTaskHistoryRecord = {
  __typename?: 'ContactExportTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ContactExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactExportTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ContactExportTaskHistoryRecordCreateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ContactExportTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ContactExportTaskHistoryRecordUpdateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ContactExportTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ContactExportTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ContactExportTaskHistoryRecordWhereInput>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  format_i?: Maybe<Scalars['String']>;
  format_not_i?: Maybe<Scalars['String']>;
  format_contains_i?: Maybe<Scalars['String']>;
  format_not_contains_i?: Maybe<Scalars['String']>;
  format_starts_with_i?: Maybe<Scalars['String']>;
  format_not_starts_with_i?: Maybe<Scalars['String']>;
  format_ends_with_i?: Maybe<Scalars['String']>;
  format_not_ends_with_i?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  exportedRecordsCount_not?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  exportedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where?: Maybe<Scalars['JSON']>;
  where_not?: Maybe<Scalars['JSON']>;
  where_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy?: Maybe<Scalars['JSON']>;
  sortBy_not?: Maybe<Scalars['JSON']>;
  sortBy_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ContactExportTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ContactExportTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ContactExportTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ContactExportTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContactExportTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ContactExportTaskHistoryRecordsCreateInput = {
  data?: Maybe<ContactExportTaskHistoryRecordCreateInput>;
};

export type ContactExportTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ContactExportTaskHistoryRecordUpdateInput>;
};

export enum ContactExportTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type ContactExportTaskUpdateInput = {
  status?: Maybe<ContactExportTaskStatusType>;
  format?: Maybe<ContactExportTaskFormatType>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ContactExportTaskWhereInput = {
  AND?: Maybe<Array<Maybe<ContactExportTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<ContactExportTaskWhereInput>>>;
  status?: Maybe<ContactExportTaskStatusType>;
  status_not?: Maybe<ContactExportTaskStatusType>;
  status_in?: Maybe<Array<Maybe<ContactExportTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<ContactExportTaskStatusType>>>;
  format?: Maybe<ContactExportTaskFormatType>;
  format_not?: Maybe<ContactExportTaskFormatType>;
  format_in?: Maybe<Array<Maybe<ContactExportTaskFormatType>>>;
  format_not_in?: Maybe<Array<Maybe<ContactExportTaskFormatType>>>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  exportedRecordsCount_not?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  exportedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where?: Maybe<Scalars['JSON']>;
  where_not?: Maybe<Scalars['JSON']>;
  where_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy?: Maybe<Scalars['JSON']>;
  sortBy_not?: Maybe<Scalars['JSON']>;
  sortBy_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ContactExportTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ContactExportTasksCreateInput = {
  data?: Maybe<ContactExportTaskCreateInput>;
};

export type ContactExportTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ContactExportTaskUpdateInput>;
};

/**  A keystone list  */
export type ContactHistoryRecord = {
  __typename?: 'ContactHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ContactHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ContactHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ContactHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ContactHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ContactHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ContactHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ContactHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isVerified?: Maybe<Scalars['Boolean']>;
  isVerified_not?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  quota_not?: Maybe<Scalars['String']>;
  quota_lt?: Maybe<Scalars['String']>;
  quota_lte?: Maybe<Scalars['String']>;
  quota_gt?: Maybe<Scalars['String']>;
  quota_gte?: Maybe<Scalars['String']>;
  quota_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  quota_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ContactHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ContactHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ContactHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ContactHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContactHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ContactHistoryRecordsCreateInput = {
  data?: Maybe<ContactHistoryRecordCreateInput>;
};

export type ContactHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ContactHistoryRecordUpdateInput>;
};

export type ContactRelateToOneInput = {
  create?: Maybe<ContactCreateInput>;
  connect?: Maybe<ContactWhereUniqueInput>;
  disconnect?: Maybe<ContactWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  Role for contact  */
export type ContactRole = {
  __typename?: 'ContactRole';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactRole List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactRole List config, or
   *  3. As an alias to a 'name' field on the ContactRole List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactRole List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  The role's name  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ContactRoleCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type ContactRoleHistoryRecord = {
  __typename?: 'ContactRoleHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ContactRoleHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ContactRoleHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ContactRoleHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ContactRoleHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ContactRoleHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ContactRoleHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ContactRoleHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ContactRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ContactRoleHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ContactRoleHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ContactRoleHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ContactRoleHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ContactRoleHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ContactRoleHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ContactRoleHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContactRoleHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ContactRoleHistoryRecordsCreateInput = {
  data?: Maybe<ContactRoleHistoryRecordCreateInput>;
};

export type ContactRoleHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ContactRoleHistoryRecordUpdateInput>;
};

export type ContactRoleRelateToOneInput = {
  create?: Maybe<ContactRoleCreateInput>;
  connect?: Maybe<ContactRoleWhereUniqueInput>;
  disconnect?: Maybe<ContactRoleWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type ContactRoleUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ContactRoleWhereInput = {
  AND?: Maybe<Array<Maybe<ContactRoleWhereInput>>>;
  OR?: Maybe<Array<Maybe<ContactRoleWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ContactRoleWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ContactRolesCreateInput = {
  data?: Maybe<ContactRoleCreateInput>;
};

export type ContactRolesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ContactRoleUpdateInput>;
};

export enum ContactUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type ContactUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<ContactUnitTypeType>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<ContactRoleRelateToOneInput>;
  isVerified?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ContactWhereInput = {
  AND?: Maybe<Array<Maybe<ContactWhereInput>>>;
  OR?: Maybe<Array<Maybe<ContactWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<ContactUnitTypeType>;
  unitType_not?: Maybe<ContactUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<ContactUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<ContactUnitTypeType>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<ContactRoleWhereInput>;
  role_is_null?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  isVerified_not?: Maybe<Scalars['Boolean']>;
  quota?: Maybe<Scalars['String']>;
  quota_not?: Maybe<Scalars['String']>;
  quota_lt?: Maybe<Scalars['String']>;
  quota_lte?: Maybe<Scalars['String']>;
  quota_gt?: Maybe<Scalars['String']>;
  quota_gte?: Maybe<Scalars['String']>;
  quota_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  quota_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ContactWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ContactsCreateInput = {
  data?: Maybe<ContactCreateInput>;
};

export type ContactsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ContactUpdateInput>;
};

export type CreateBankAccountRequestInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  organizationId: Scalars['ID'];
  propertyId: Scalars['ID'];
};

export type CreateBankAccountRequestOutput = {
  __typename?: 'CreateBankAccountRequestOutput';
  status: Scalars['String'];
  id: Scalars['ID'];
};

export type CreatePaymentByLinkInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  qrCode: Scalars['String'];
};

export type CreatePaymentByLinkOutput = {
  __typename?: 'CreatePaymentByLinkOutput';
  multiPaymentId: Scalars['ID'];
  amount: Scalars['String'];
  explicitFee: Scalars['String'];
  totalAmount: Scalars['String'];
  acquiringIntegrationHostUrl: Scalars['String'];
  currencyCode: Scalars['String'];
  address: Scalars['String'];
  addressMeta: AddressMetaField;
  unitType: Scalars['String'];
  unitName: Scalars['String'];
  accountNumber: Scalars['String'];
  period: Scalars['String'];
};

export type CustomAccess = {
  __typename?: 'CustomAccess';
  accessRules?: Maybe<Array<Maybe<CustomAccessListRule>>>;
};

export type CustomAccessFieldRule = {
  __typename?: 'CustomAccessFieldRule';
  field: Scalars['String'];
  create?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
};

export type CustomAccessFieldRuleInput = {
  field: Scalars['String'];
  create?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
};

export type CustomAccessInput = {
  accessRules?: Maybe<Array<Maybe<CustomAccessListRuleInput>>>;
};

export type CustomAccessListRule = {
  __typename?: 'CustomAccessListRule';
  list: Scalars['String'];
  create?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Array<Maybe<CustomAccessFieldRule>>>;
};

export type CustomAccessListRuleInput = {
  list: Scalars['String'];
  create?: Maybe<Scalars['Boolean']>;
  read?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Array<Maybe<CustomAccessFieldRuleInput>>>;
};

export enum DevicePlatformType {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

export type DisconnectUserFromRemoteClientInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  deviceId: Scalars['String'];
  appId: Scalars['String'];
};

export type DisconnectUserFromRemoteClientOutput = {
  __typename?: 'DisconnectUserFromRemoteClientOutput';
  status: Scalars['String'];
};

export type DiscoverServiceConsumersInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  billingAccountsIds: Array<Scalars['ID']>;
  filters?: Maybe<DiscoverServiceConsumersInputFilters>;
};

export type DiscoverServiceConsumersInputFilters = {
  residentsIds?: Maybe<Array<Scalars['ID']>>;
};

export type DiscoverServiceConsumersOutput = {
  __typename?: 'DiscoverServiceConsumersOutput';
  status: Scalars['String'];
  statistics: DiscoverServiceConsumersOutputStatistics;
};

export type DiscoverServiceConsumersOutputStatistics = {
  __typename?: 'DiscoverServiceConsumersOutputStatistics';
  created: Scalars['Int'];
  residentsFound: Scalars['Int'];
  billingAccountsFound: Scalars['Int'];
};

/**  Document with file attached to organization or property. It could be some kind of property documentation, inspection reports or other documents  */
export type Document = {
  __typename?: 'Document';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Document List config, or
   *  2. As an alias to the field set on 'labelField' in the Document List config, or
   *  3. As an alias to a 'name' field on the Document List (if one exists), or
   *  4. As an alias to the 'id' field on the Document List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Property to which the document is attached  */
  property?: Maybe<Property>;
  /**  Document type  */
  category?: Maybe<DocumentCategory>;
  /**  Name of the document. By default it's file name  */
  name?: Maybe<Scalars['String']>;
  /**  File attached to document  */
  file?: Maybe<File>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type DocumentCategoriesCreateInput = {
  data?: Maybe<DocumentCategoryCreateInput>;
};

export type DocumentCategoriesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<DocumentCategoryUpdateInput>;
};

/**  Document category, for example, cleaning acts, works performed, design or technical documentation and other categories  */
export type DocumentCategory = {
  __typename?: 'DocumentCategory';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the DocumentCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the DocumentCategory List config, or
   *  3. As an alias to a 'name' field on the DocumentCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the DocumentCategory List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of the category  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type DocumentCategoryCreateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type DocumentCategoryHistoryRecord = {
  __typename?: 'DocumentCategoryHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the DocumentCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the DocumentCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the DocumentCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the DocumentCategoryHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<DocumentCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type DocumentCategoryHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<DocumentCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum DocumentCategoryHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type DocumentCategoryHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<DocumentCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type DocumentCategoryHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<DocumentCategoryHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<DocumentCategoryHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<DocumentCategoryHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<DocumentCategoryHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<DocumentCategoryHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<DocumentCategoryHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentCategoryHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type DocumentCategoryHistoryRecordsCreateInput = {
  data?: Maybe<DocumentCategoryHistoryRecordCreateInput>;
};

export type DocumentCategoryHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<DocumentCategoryHistoryRecordUpdateInput>;
};

export type DocumentCategoryRelateToOneInput = {
  create?: Maybe<DocumentCategoryCreateInput>;
  connect?: Maybe<DocumentCategoryWhereUniqueInput>;
  disconnect?: Maybe<DocumentCategoryWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type DocumentCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type DocumentCategoryWhereInput = {
  AND?: Maybe<Array<Maybe<DocumentCategoryWhereInput>>>;
  OR?: Maybe<Array<Maybe<DocumentCategoryWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type DocumentCategoryWhereUniqueInput = {
  id: Scalars['ID'];
};

export type DocumentCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  category?: Maybe<DocumentCategoryRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type DocumentHistoryRecord = {
  __typename?: 'DocumentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the DocumentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the DocumentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the DocumentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the DocumentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<DocumentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type DocumentHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<DocumentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum DocumentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type DocumentHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<DocumentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type DocumentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<DocumentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<DocumentHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<DocumentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<DocumentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<DocumentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<DocumentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DocumentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type DocumentHistoryRecordsCreateInput = {
  data?: Maybe<DocumentHistoryRecordCreateInput>;
};

export type DocumentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<DocumentHistoryRecordUpdateInput>;
};

export type DocumentUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  category?: Maybe<DocumentCategoryRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type DocumentWhereInput = {
  AND?: Maybe<Array<Maybe<DocumentWhereInput>>>;
  OR?: Maybe<Array<Maybe<DocumentWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  category?: Maybe<DocumentCategoryWhereInput>;
  category_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type DocumentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type DocumentsCreateInput = {
  data?: Maybe<DocumentCreateInput>;
};

export type DocumentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<DocumentUpdateInput>;
};

export type ExportMeterReadingsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  where: MeterReadingWhereInput;
  sortBy?: Maybe<Array<SortMeterReadingsBy>>;
  timeZone: Scalars['String'];
};

export type ExportMeterReadingsOutput = {
  __typename?: 'ExportMeterReadingsOutput';
  status: Scalars['String'];
  linkToFile: Scalars['String'];
};

export type ExportPaymentsToExcelInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  where: PaymentWhereInput;
  sortBy?: Maybe<Array<SortPaymentsBy>>;
  timeZone: Scalars['String'];
};

export type ExportPaymentsToExcelOutput = {
  __typename?: 'ExportPaymentsToExcelOutput';
  status: Scalars['String'];
  linkToFile: Scalars['String'];
};

export type ExportPropertiesToExcelInput = {
  where: PropertyWhereInput;
  sortBy?: Maybe<Array<SortPropertiesBy>>;
};

export type ExportPropertiesToExcelOutput = {
  __typename?: 'ExportPropertiesToExcelOutput';
  status: Scalars['String'];
  linkToFile: Scalars['String'];
};

export type ExportPropertyMeterReadingsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  where: PropertyMeterReadingWhereInput;
  sortBy?: Maybe<Array<SortPropertyMeterReadingsBy>>;
  timeZone: Scalars['String'];
};

export type ExportPropertyMeterReadingsOutput = {
  __typename?: 'ExportPropertyMeterReadingsOutput';
  status: Scalars['String'];
  linkToFile: Scalars['String'];
};

export type ExportPropertyScopeToExcelInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  where: PropertyScopeWhereInput;
  sortBy?: Maybe<Array<SortPropertyScopesBy>>;
};

export type ExportPropertyScopeToExcelOutput = {
  __typename?: 'ExportPropertyScopeToExcelOutput';
  status: Scalars['String'];
  linkToFile: Scalars['String'];
};

export type ExportTicketAnalyticsToExcelInput = {
  where: TicketWhereInput;
  groupBy?: Maybe<Array<TicketAnalyticsGroupBy>>;
  translates: ExportTicketAnalyticsToExcelTranslates;
  nullReplaces: TicketAnalyticsNullReplaces;
};

export type ExportTicketAnalyticsToExcelOutput = {
  __typename?: 'ExportTicketAnalyticsToExcelOutput';
  link: Scalars['String'];
};

export type ExportTicketAnalyticsToExcelTranslates = {
  property?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  categoryClassifier?: Maybe<Scalars['String']>;
};

/**  External report for analytics section that could be displayed with iframe  */
export type ExternalReport = {
  __typename?: 'ExternalReport';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ExternalReport List config, or
   *  2. As an alias to the field set on 'labelField' in the ExternalReport List config, or
   *  3. As an alias to a 'name' field on the ExternalReport List (if one exists), or
   *  4. As an alias to the 'id' field on the ExternalReport List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Type of external report. Way to determine logic of url building process  */
  type?: Maybe<ExternalReportTypeType>;
  /**  Report card title text  */
  title?: Maybe<Scalars['String']>;
  /**  Report card description  */
  description?: Maybe<Scalars['String']>;
  /**  Meta data for building iframe link. For example, external id or custom options that required at specified type of report  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  Indicates visibility of concrete external report at ui  */
  isHidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ExternalReportCreateInput = {
  type?: Maybe<ExternalReportTypeType>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  isHidden?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type ExternalReportHistoryRecord = {
  __typename?: 'ExternalReportHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ExternalReportHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ExternalReportHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ExternalReportHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ExternalReportHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ExternalReportHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ExternalReportHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ExternalReportHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ExternalReportHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ExternalReportHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ExternalReportHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ExternalReportHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ExternalReportHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ExternalReportHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ExternalReportHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ExternalReportHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ExternalReportHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ExternalReportHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ExternalReportHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ExternalReportHistoryRecordsCreateInput = {
  data?: Maybe<ExternalReportHistoryRecordCreateInput>;
};

export type ExternalReportHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ExternalReportHistoryRecordUpdateInput>;
};

export enum ExternalReportTypeType {
  Metabase = 'metabase'
}

export type ExternalReportUpdateInput = {
  type?: Maybe<ExternalReportTypeType>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  isHidden?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ExternalReportWhereInput = {
  AND?: Maybe<Array<Maybe<ExternalReportWhereInput>>>;
  OR?: Maybe<Array<Maybe<ExternalReportWhereInput>>>;
  type?: Maybe<ExternalReportTypeType>;
  type_not?: Maybe<ExternalReportTypeType>;
  type_in?: Maybe<Array<Maybe<ExternalReportTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<ExternalReportTypeType>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isHidden_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ExternalReportWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ExternalReportsCreateInput = {
  data?: Maybe<ExternalReportCreateInput>;
};

export type ExternalReportsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ExternalReportUpdateInput>;
};

/**
 *  The scheme allows the service user to get read access to all user access tokens.
 * Used to check permissions in the getAccessTokenByUserId query
 */
export type ExternalTokenAccessRight = {
  __typename?: 'ExternalTokenAccessRight';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ExternalTokenAccessRight List config, or
   *  2. As an alias to the field set on 'labelField' in the ExternalTokenAccessRight List config, or
   *  3. As an alias to a 'name' field on the ExternalTokenAccessRight List (if one exists), or
   *  4. As an alias to the 'id' field on the ExternalTokenAccessRight List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Which service token the user has access to  */
  type?: Maybe<ExternalTokenAccessRightTypeType>;
  /**  Link to user. Note, that user must be of "service" type  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ExternalTokenAccessRightCreateInput = {
  type?: Maybe<ExternalTokenAccessRightTypeType>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type ExternalTokenAccessRightHistoryRecord = {
  __typename?: 'ExternalTokenAccessRightHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ExternalTokenAccessRightHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ExternalTokenAccessRightHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ExternalTokenAccessRightHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ExternalTokenAccessRightHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ExternalTokenAccessRightHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ExternalTokenAccessRightHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ExternalTokenAccessRightHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ExternalTokenAccessRightHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ExternalTokenAccessRightHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ExternalTokenAccessRightHistoryRecordsCreateInput = {
  data?: Maybe<ExternalTokenAccessRightHistoryRecordCreateInput>;
};

export type ExternalTokenAccessRightHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ExternalTokenAccessRightHistoryRecordUpdateInput>;
};

export enum ExternalTokenAccessRightTypeType {
  Sbbol = 'sbbol'
}

export type ExternalTokenAccessRightUpdateInput = {
  type?: Maybe<ExternalTokenAccessRightTypeType>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ExternalTokenAccessRightWhereInput = {
  AND?: Maybe<Array<Maybe<ExternalTokenAccessRightWhereInput>>>;
  OR?: Maybe<Array<Maybe<ExternalTokenAccessRightWhereInput>>>;
  type?: Maybe<ExternalTokenAccessRightTypeType>;
  type_not?: Maybe<ExternalTokenAccessRightTypeType>;
  type_in?: Maybe<Array<Maybe<ExternalTokenAccessRightTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<ExternalTokenAccessRightTypeType>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ExternalTokenAccessRightWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ExternalTokenAccessRightsCreateInput = {
  data?: Maybe<ExternalTokenAccessRightCreateInput>;
};

export type ExternalTokenAccessRightsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ExternalTokenAccessRightUpdateInput>;
};

export type FeeDistributionField = {
  __typename?: 'FeeDistributionField';
  recipient: Scalars['String'];
  percent: Scalars['String'];
  minAmount?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type FeeDistributionFieldInput = {
  recipient: Scalars['String'];
  percent: Scalars['String'];
  minAmount?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export enum FeedbackAdditionalOptionsType {
  LowQuality = 'lowQuality',
  HighQuality = 'highQuality',
  Slowly = 'slowly',
  Quickly = 'quickly'
}

export type File = {
  __typename?: 'File';
  id?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  encoding?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
};

export type FindOrganizationForAddressAccountType = {
  __typename?: 'FindOrganizationForAddressAccountType';
  number: Scalars['String'];
  category: Scalars['ID'];
  balance?: Maybe<Scalars['String']>;
  routingNumber: Scalars['String'];
  bankAccountNumber: Scalars['String'];
};

export type FindOrganizationForAddressOrganizationType = {
  __typename?: 'FindOrganizationForAddressOrganizationType';
  id: Scalars['ID'];
  name: Scalars['String'];
  tin: Scalars['String'];
  type: OrganizationTypeType;
};

export type FindOrganizationForAddressOutput = {
  __typename?: 'FindOrganizationForAddressOutput';
  organization: FindOrganizationForAddressOrganizationType;
  account?: Maybe<FindOrganizationForAddressAccountType>;
  hasMeters: Scalars['Boolean'];
  hasBillingData: Scalars['Boolean'];
};

export type FindOrganizationsForAddressInput = {
  addressKey: Scalars['String'];
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
};

export type GeneratePaymentLinkCallbacksInput = {
  successUrl: Scalars['String'];
  failureUrl: Scalars['String'];
};

export type GeneratePaymentLinkInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  receipt?: Maybe<BillingReceiptWhereUniqueInput>;
  receiptData?: Maybe<GeneratePaymentLinkReceiptDataInput>;
  acquiringIntegrationContext?: Maybe<AcquiringIntegrationContextWhereUniqueInput>;
  invoices?: Maybe<Array<InvoiceWhereUniqueInput>>;
  callbacks: GeneratePaymentLinkCallbacksInput;
};

export type GeneratePaymentLinkOutput = {
  __typename?: 'GeneratePaymentLinkOutput';
  dv: Scalars['Int'];
  paymentUrl: Scalars['String'];
};

export type GeneratePaymentLinkReceiptDataInput = {
  currencyCode: Scalars['String'];
  amount: Scalars['String'];
  periodYear: Scalars['Int'];
  periodMonth: Scalars['Int'];
  accountNumber: Scalars['String'];
};

export type GetAccessTokenByUserIdInput = {
  userId: Scalars['ID'];
  type: Scalars['String'];
};

export type GetAccessTokenByUserIdOutput = {
  __typename?: 'GetAccessTokenByUserIdOutput';
  accessToken: Scalars['ID'];
  ttl: Scalars['Int'];
};

export type GetExternalReportIframeUrlInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type GetExternalReportIframeUrlOutput = {
  __typename?: 'GetExternalReportIframeUrlOutput';
  title: Scalars['String'];
  iframeUrl?: Maybe<Scalars['String']>;
};

export type GetNewsItemsRecipientsCountersInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  organization: OrganizationWhereUniqueInput;
  newsItemScopes: Array<NewsItemScopeWhereInput>;
};

export type GetNewsItemsRecipientsCountersOutput = {
  __typename?: 'GetNewsItemsRecipientsCountersOutput';
  propertiesCount: Scalars['Int'];
  unitsCount: Scalars['Int'];
  receiversCount: Scalars['Int'];
};

export type GetNewsSharingRecipientsCountersInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  b2bAppContext: B2BAppContextWhereUniqueInput;
  newsItemScopes: Array<NewsItemScopeWhereInput>;
};

export type GetNewsSharingRecipientsCountersOutput = {
  __typename?: 'GetNewsSharingRecipientsCountersOutput';
  receiversCount: Scalars['Int'];
};

export type GetNewsSharingRecipientsInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  b2bAppContext: B2BAppContextWhereUniqueInput;
};

export type GetNewsSharingRecipientsOutput = {
  __typename?: 'GetNewsSharingRecipientsOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  receiversCount?: Maybe<Scalars['Int']>;
};

export enum GetOverviewDashboardAggregatePeriod {
  Day = 'day',
  Week = 'week'
}

export type GetOverviewDashboardGroupByInput = {
  aggregatePeriod: GetOverviewDashboardAggregatePeriod;
};

export type GetOverviewDashboardInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  where: GetOverviewDashboardWhereInput;
  groupBy: GetOverviewDashboardGroupByInput;
  entities?: Maybe<Array<Maybe<OverviewDashboardEntities>>>;
};

export type GetOverviewDashboardOutput = {
  __typename?: 'GetOverviewDashboardOutput';
  overview: OverviewData;
};

export type GetOverviewDashboardWhereInput = {
  organization: Scalars['String'];
  dateFrom: Scalars['String'];
  dateTo: Scalars['String'];
  propertyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  executorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type GetPhoneByConfirmPhoneActionTokenInput = {
  token: Scalars['String'];
  captcha: Scalars['String'];
};

export type GetPhoneByConfirmPhoneActionTokenOutput = {
  __typename?: 'GetPhoneByConfirmPhoneActionTokenOutput';
  phone: Scalars['String'];
  isPhoneVerified: Scalars['Boolean'];
};

export type GetResidentExistenceByPhoneAndAddressInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  phone: Scalars['String'];
  propertyId: Scalars['ID'];
  unitName: Scalars['String'];
  unitType: BuildingUnitSubType;
};

export type GetResidentExistenceByPhoneAndAddressOutput = {
  __typename?: 'GetResidentExistenceByPhoneAndAddressOutput';
  hasResident: Scalars['Boolean'];
  hasResidentOnAddress: Scalars['Boolean'];
};

/**  Entries of mass planned and emergency incidents with water, electricity, etc.  */
export type Incident = {
  __typename?: 'Incident';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Incident List config, or
   *  2. As an alias to the field set on 'labelField' in the Incident List config, or
   *  3. As an alias to a 'name' field on the Incident List (if one exists), or
   *  4. As an alias to the 'id' field on the Incident List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Autogenerated incident human readable ID  */
  number?: Maybe<Scalars['Int']>;
  /**  Text description of the incident  */
  details?: Maybe<Scalars['String']>;
  /**  Incident status indicates the actuality of the incident  */
  status?: Maybe<IncidentStatusType>;
  /**  Text that employees should say to residents  */
  textForResident?: Maybe<Scalars['String']>;
  /**  Start date of work related to the incident (seconds and milliseconds are forced to zero)  */
  workStart?: Maybe<Scalars['String']>;
  /**  Finish date of work related to the incident (seconds and milliseconds are forced to zero)  */
  workFinish?: Maybe<Scalars['String']>;
  /**  Type of work that is carried out during the incident: emergency or scheduled  */
  workType?: Maybe<IncidentWorkTypeType>;
  /**  True if incident includes all properties in organization  */
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

/**  Incremental changes of Incident  */
export type IncidentChange = {
  __typename?: 'IncidentChange';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentChange List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentChange List config, or
   *  3. As an alias to a 'name' field on the IncidentChange List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentChange List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Related incident, whose change is logged in this entity  */
  incident?: Maybe<Incident>;
  /**  Text description of the incident  */
  detailsFrom?: Maybe<Scalars['String']>;
  /**  Text description of the incident  */
  detailsTo?: Maybe<Scalars['String']>;
  /**  Incident status indicates the actuality of the incident  */
  statusFrom?: Maybe<IncidentChangeStatusFromType>;
  /**  Incident status indicates the actuality of the incident  */
  statusTo?: Maybe<IncidentChangeStatusToType>;
  /**  Text that employees should say to residents  */
  textForResidentFrom?: Maybe<Scalars['String']>;
  /**  Text that employees should say to residents  */
  textForResidentTo?: Maybe<Scalars['String']>;
  /**  Start date of work related to the incident (seconds and milliseconds are forced to zero)  */
  workStartFrom?: Maybe<Scalars['String']>;
  /**  Start date of work related to the incident (seconds and milliseconds are forced to zero)  */
  workStartTo?: Maybe<Scalars['String']>;
  /**  Finish date of work related to the incident (seconds and milliseconds are forced to zero)  */
  workFinishFrom?: Maybe<Scalars['String']>;
  /**  Finish date of work related to the incident (seconds and milliseconds are forced to zero)  */
  workFinishTo?: Maybe<Scalars['String']>;
  /**  Type of work that is carried out during the incident: emergency or scheduled  */
  workTypeFrom?: Maybe<IncidentChangeWorkTypeFromType>;
  /**  Type of work that is carried out during the incident: emergency or scheduled  */
  workTypeTo?: Maybe<IncidentChangeWorkTypeToType>;
  /**  Old id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Type of user who changed the incident, can be employee role from same organization or related, resident or deleted employee  */
  changedByRole?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type IncidentChangeCreateInput = {
  incident?: Maybe<IncidentRelateToOneInput>;
  detailsFrom?: Maybe<Scalars['String']>;
  detailsTo?: Maybe<Scalars['String']>;
  statusFrom?: Maybe<IncidentChangeStatusFromType>;
  statusTo?: Maybe<IncidentChangeStatusToType>;
  textForResidentFrom?: Maybe<Scalars['String']>;
  textForResidentTo?: Maybe<Scalars['String']>;
  workStartFrom?: Maybe<Scalars['String']>;
  workStartTo?: Maybe<Scalars['String']>;
  workFinishFrom?: Maybe<Scalars['String']>;
  workFinishTo?: Maybe<Scalars['String']>;
  workTypeFrom?: Maybe<IncidentChangeWorkTypeFromType>;
  workTypeTo?: Maybe<IncidentChangeWorkTypeToType>;
  organizationIdFrom?: Maybe<Scalars['ID']>;
  organizationIdTo?: Maybe<Scalars['ID']>;
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum IncidentChangeStatusFromType {
  Actual = 'actual',
  NotActual = 'not_actual'
}

export enum IncidentChangeStatusToType {
  Actual = 'actual',
  NotActual = 'not_actual'
}

export type IncidentChangeUpdateInput = {
  incident?: Maybe<IncidentRelateToOneInput>;
  detailsFrom?: Maybe<Scalars['String']>;
  detailsTo?: Maybe<Scalars['String']>;
  statusFrom?: Maybe<IncidentChangeStatusFromType>;
  statusTo?: Maybe<IncidentChangeStatusToType>;
  textForResidentFrom?: Maybe<Scalars['String']>;
  textForResidentTo?: Maybe<Scalars['String']>;
  workStartFrom?: Maybe<Scalars['String']>;
  workStartTo?: Maybe<Scalars['String']>;
  workFinishFrom?: Maybe<Scalars['String']>;
  workFinishTo?: Maybe<Scalars['String']>;
  workTypeFrom?: Maybe<IncidentChangeWorkTypeFromType>;
  workTypeTo?: Maybe<IncidentChangeWorkTypeToType>;
  organizationIdFrom?: Maybe<Scalars['ID']>;
  organizationIdTo?: Maybe<Scalars['ID']>;
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type IncidentChangeWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentChangeWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentChangeWhereInput>>>;
  incident?: Maybe<IncidentWhereInput>;
  incident_is_null?: Maybe<Scalars['Boolean']>;
  detailsFrom?: Maybe<Scalars['String']>;
  detailsFrom_not?: Maybe<Scalars['String']>;
  detailsFrom_contains?: Maybe<Scalars['String']>;
  detailsFrom_not_contains?: Maybe<Scalars['String']>;
  detailsFrom_starts_with?: Maybe<Scalars['String']>;
  detailsFrom_not_starts_with?: Maybe<Scalars['String']>;
  detailsFrom_ends_with?: Maybe<Scalars['String']>;
  detailsFrom_not_ends_with?: Maybe<Scalars['String']>;
  detailsFrom_i?: Maybe<Scalars['String']>;
  detailsFrom_not_i?: Maybe<Scalars['String']>;
  detailsFrom_contains_i?: Maybe<Scalars['String']>;
  detailsFrom_not_contains_i?: Maybe<Scalars['String']>;
  detailsFrom_starts_with_i?: Maybe<Scalars['String']>;
  detailsFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  detailsFrom_ends_with_i?: Maybe<Scalars['String']>;
  detailsFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  detailsFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsTo?: Maybe<Scalars['String']>;
  detailsTo_not?: Maybe<Scalars['String']>;
  detailsTo_contains?: Maybe<Scalars['String']>;
  detailsTo_not_contains?: Maybe<Scalars['String']>;
  detailsTo_starts_with?: Maybe<Scalars['String']>;
  detailsTo_not_starts_with?: Maybe<Scalars['String']>;
  detailsTo_ends_with?: Maybe<Scalars['String']>;
  detailsTo_not_ends_with?: Maybe<Scalars['String']>;
  detailsTo_i?: Maybe<Scalars['String']>;
  detailsTo_not_i?: Maybe<Scalars['String']>;
  detailsTo_contains_i?: Maybe<Scalars['String']>;
  detailsTo_not_contains_i?: Maybe<Scalars['String']>;
  detailsTo_starts_with_i?: Maybe<Scalars['String']>;
  detailsTo_not_starts_with_i?: Maybe<Scalars['String']>;
  detailsTo_ends_with_i?: Maybe<Scalars['String']>;
  detailsTo_not_ends_with_i?: Maybe<Scalars['String']>;
  detailsTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusFrom?: Maybe<IncidentChangeStatusFromType>;
  statusFrom_not?: Maybe<IncidentChangeStatusFromType>;
  statusFrom_in?: Maybe<Array<Maybe<IncidentChangeStatusFromType>>>;
  statusFrom_not_in?: Maybe<Array<Maybe<IncidentChangeStatusFromType>>>;
  statusTo?: Maybe<IncidentChangeStatusToType>;
  statusTo_not?: Maybe<IncidentChangeStatusToType>;
  statusTo_in?: Maybe<Array<Maybe<IncidentChangeStatusToType>>>;
  statusTo_not_in?: Maybe<Array<Maybe<IncidentChangeStatusToType>>>;
  textForResidentFrom?: Maybe<Scalars['String']>;
  textForResidentFrom_not?: Maybe<Scalars['String']>;
  textForResidentFrom_contains?: Maybe<Scalars['String']>;
  textForResidentFrom_not_contains?: Maybe<Scalars['String']>;
  textForResidentFrom_starts_with?: Maybe<Scalars['String']>;
  textForResidentFrom_not_starts_with?: Maybe<Scalars['String']>;
  textForResidentFrom_ends_with?: Maybe<Scalars['String']>;
  textForResidentFrom_not_ends_with?: Maybe<Scalars['String']>;
  textForResidentFrom_i?: Maybe<Scalars['String']>;
  textForResidentFrom_not_i?: Maybe<Scalars['String']>;
  textForResidentFrom_contains_i?: Maybe<Scalars['String']>;
  textForResidentFrom_not_contains_i?: Maybe<Scalars['String']>;
  textForResidentFrom_starts_with_i?: Maybe<Scalars['String']>;
  textForResidentFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  textForResidentFrom_ends_with_i?: Maybe<Scalars['String']>;
  textForResidentFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  textForResidentFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textForResidentFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textForResidentTo?: Maybe<Scalars['String']>;
  textForResidentTo_not?: Maybe<Scalars['String']>;
  textForResidentTo_contains?: Maybe<Scalars['String']>;
  textForResidentTo_not_contains?: Maybe<Scalars['String']>;
  textForResidentTo_starts_with?: Maybe<Scalars['String']>;
  textForResidentTo_not_starts_with?: Maybe<Scalars['String']>;
  textForResidentTo_ends_with?: Maybe<Scalars['String']>;
  textForResidentTo_not_ends_with?: Maybe<Scalars['String']>;
  textForResidentTo_i?: Maybe<Scalars['String']>;
  textForResidentTo_not_i?: Maybe<Scalars['String']>;
  textForResidentTo_contains_i?: Maybe<Scalars['String']>;
  textForResidentTo_not_contains_i?: Maybe<Scalars['String']>;
  textForResidentTo_starts_with_i?: Maybe<Scalars['String']>;
  textForResidentTo_not_starts_with_i?: Maybe<Scalars['String']>;
  textForResidentTo_ends_with_i?: Maybe<Scalars['String']>;
  textForResidentTo_not_ends_with_i?: Maybe<Scalars['String']>;
  textForResidentTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textForResidentTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStartFrom?: Maybe<Scalars['String']>;
  workStartFrom_not?: Maybe<Scalars['String']>;
  workStartFrom_lt?: Maybe<Scalars['String']>;
  workStartFrom_lte?: Maybe<Scalars['String']>;
  workStartFrom_gt?: Maybe<Scalars['String']>;
  workStartFrom_gte?: Maybe<Scalars['String']>;
  workStartFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStartFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStartTo?: Maybe<Scalars['String']>;
  workStartTo_not?: Maybe<Scalars['String']>;
  workStartTo_lt?: Maybe<Scalars['String']>;
  workStartTo_lte?: Maybe<Scalars['String']>;
  workStartTo_gt?: Maybe<Scalars['String']>;
  workStartTo_gte?: Maybe<Scalars['String']>;
  workStartTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStartTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinishFrom?: Maybe<Scalars['String']>;
  workFinishFrom_not?: Maybe<Scalars['String']>;
  workFinishFrom_lt?: Maybe<Scalars['String']>;
  workFinishFrom_lte?: Maybe<Scalars['String']>;
  workFinishFrom_gt?: Maybe<Scalars['String']>;
  workFinishFrom_gte?: Maybe<Scalars['String']>;
  workFinishFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinishFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinishTo?: Maybe<Scalars['String']>;
  workFinishTo_not?: Maybe<Scalars['String']>;
  workFinishTo_lt?: Maybe<Scalars['String']>;
  workFinishTo_lte?: Maybe<Scalars['String']>;
  workFinishTo_gt?: Maybe<Scalars['String']>;
  workFinishTo_gte?: Maybe<Scalars['String']>;
  workFinishTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinishTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workTypeFrom?: Maybe<IncidentChangeWorkTypeFromType>;
  workTypeFrom_not?: Maybe<IncidentChangeWorkTypeFromType>;
  workTypeFrom_in?: Maybe<Array<Maybe<IncidentChangeWorkTypeFromType>>>;
  workTypeFrom_not_in?: Maybe<Array<Maybe<IncidentChangeWorkTypeFromType>>>;
  workTypeTo?: Maybe<IncidentChangeWorkTypeToType>;
  workTypeTo_not?: Maybe<IncidentChangeWorkTypeToType>;
  workTypeTo_in?: Maybe<Array<Maybe<IncidentChangeWorkTypeToType>>>;
  workTypeTo_not_in?: Maybe<Array<Maybe<IncidentChangeWorkTypeToType>>>;
  organizationIdFrom?: Maybe<Scalars['ID']>;
  organizationIdFrom_not?: Maybe<Scalars['ID']>;
  organizationIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationIdTo?: Maybe<Scalars['ID']>;
  organizationIdTo_not?: Maybe<Scalars['ID']>;
  organizationIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type IncidentChangeWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum IncidentChangeWorkTypeFromType {
  Scheduled = 'scheduled',
  Emergency = 'emergency'
}

export enum IncidentChangeWorkTypeToType {
  Scheduled = 'scheduled',
  Emergency = 'emergency'
}

export type IncidentChangesCreateInput = {
  data?: Maybe<IncidentChangeCreateInput>;
};

export type IncidentChangesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentChangeUpdateInput>;
};

/**  Rules for all possible valid combinations of classifiers for incidents  */
export type IncidentClassifier = {
  __typename?: 'IncidentClassifier';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifier List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifier List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  Type of work to fix incident  */
  category?: Maybe<TicketCategoryClassifier>;
  /**  What needs to be done  */
  problem?: Maybe<TicketProblemClassifier>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type IncidentClassifierCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  category?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  problem?: Maybe<TicketProblemClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type IncidentClassifierHistoryRecord = {
  __typename?: 'IncidentClassifierHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifierHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentClassifierHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum IncidentClassifierHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type IncidentClassifierHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentClassifierHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentClassifierHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentClassifierHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  problem?: Maybe<Scalars['String']>;
  problem_not?: Maybe<Scalars['String']>;
  problem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  problem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<IncidentClassifierHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<IncidentClassifierHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<IncidentClassifierHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<IncidentClassifierHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncidentClassifierHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentClassifierHistoryRecordsCreateInput = {
  data?: Maybe<IncidentClassifierHistoryRecordCreateInput>;
};

export type IncidentClassifierHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierHistoryRecordUpdateInput>;
};

/**  Many-to-many relationship between Incident and IncidentClassifier  */
export type IncidentClassifierIncident = {
  __typename?: 'IncidentClassifierIncident';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifierIncident List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifierIncident List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifierIncident List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifierIncident List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Incident which has a classifier  */
  incident?: Maybe<Incident>;
  /**  A classifier which is in the incident entry  */
  classifier?: Maybe<IncidentClassifier>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type IncidentClassifierIncidentCreateInput = {
  incident?: Maybe<IncidentRelateToOneInput>;
  classifier?: Maybe<IncidentClassifierRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type IncidentClassifierIncidentHistoryRecord = {
  __typename?: 'IncidentClassifierIncidentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentClassifierIncidentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentClassifierIncidentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentClassifierIncidentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentClassifierIncidentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  incident?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentClassifierIncidentHistoryRecordCreateInput = {
  incident?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum IncidentClassifierIncidentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type IncidentClassifierIncidentHistoryRecordUpdateInput = {
  incident?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentClassifierIncidentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecordWhereInput>>>;
  incident?: Maybe<Scalars['String']>;
  incident_not?: Maybe<Scalars['String']>;
  incident_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  incident_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifier?: Maybe<Scalars['String']>;
  classifier_not?: Maybe<Scalars['String']>;
  classifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncidentClassifierIncidentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentClassifierIncidentHistoryRecordsCreateInput = {
  data?: Maybe<IncidentClassifierIncidentHistoryRecordCreateInput>;
};

export type IncidentClassifierIncidentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierIncidentHistoryRecordUpdateInput>;
};

export type IncidentClassifierIncidentUpdateInput = {
  incident?: Maybe<IncidentRelateToOneInput>;
  classifier?: Maybe<IncidentClassifierRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type IncidentClassifierIncidentWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentClassifierIncidentWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentClassifierIncidentWhereInput>>>;
  incident?: Maybe<IncidentWhereInput>;
  incident_is_null?: Maybe<Scalars['Boolean']>;
  classifier?: Maybe<IncidentClassifierWhereInput>;
  classifier_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type IncidentClassifierIncidentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentClassifierIncidentsCreateInput = {
  data?: Maybe<IncidentClassifierIncidentCreateInput>;
};

export type IncidentClassifierIncidentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierIncidentUpdateInput>;
};

export type IncidentClassifierRelateToOneInput = {
  create?: Maybe<IncidentClassifierCreateInput>;
  connect?: Maybe<IncidentClassifierWhereUniqueInput>;
  disconnect?: Maybe<IncidentClassifierWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type IncidentClassifierUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  category?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  problem?: Maybe<TicketProblemClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type IncidentClassifierWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentClassifierWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentClassifierWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  category?: Maybe<TicketCategoryClassifierWhereInput>;
  category_is_null?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<TicketProblemClassifierWhereInput>;
  problem_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type IncidentClassifierWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentClassifiersCreateInput = {
  data?: Maybe<IncidentClassifierCreateInput>;
};

export type IncidentClassifiersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierUpdateInput>;
};

export type IncidentCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  status?: Maybe<IncidentStatusType>;
  textForResident?: Maybe<Scalars['String']>;
  workStart?: Maybe<Scalars['String']>;
  workFinish?: Maybe<Scalars['String']>;
  workType?: Maybe<IncidentWorkTypeType>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type IncidentExportTask = {
  __typename?: 'IncidentExportTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentExportTask List config, or
   *  3. As an alias to a 'name' field on the IncidentExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentExportTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Status of the job. Used at client side progress information component  */
  status?: Maybe<IncidentExportTaskStatusType>;
  /**  Requested export file format  */
  format?: Maybe<IncidentExportTaskFormatType>;
  /**  How many records at the moment are exported  */
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  /**  Total records to export that will be determined at server side in export operation  */
  totalRecordsCount?: Maybe<Scalars['Int']>;
  /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  file?: Maybe<File>;
  /**  Stores information about query and ids of exported and failed records  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Filtering conditions for records to export  */
  where?: Maybe<Scalars['JSON']>;
  /**  Sorting parameters for records to export  */
  sortBy?: Maybe<Scalars['JSON']>;
  /**  Requested export locale, in that the resulting file will be rendered  */
  locale?: Maybe<Scalars['String']>;
  /**  To requested timeZone all datetime fields will be converted  */
  timeZone?: Maybe<Scalars['String']>;
  /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type IncidentExportTaskCreateInput = {
  status?: Maybe<IncidentExportTaskStatusType>;
  format?: Maybe<IncidentExportTaskFormatType>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum IncidentExportTaskFormatType {
  Excel = 'excel'
}

/**  A keystone list  */
export type IncidentExportTaskHistoryRecord = {
  __typename?: 'IncidentExportTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentExportTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentExportTaskHistoryRecordCreateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum IncidentExportTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type IncidentExportTaskHistoryRecordUpdateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentExportTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecordWhereInput>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  format_i?: Maybe<Scalars['String']>;
  format_not_i?: Maybe<Scalars['String']>;
  format_contains_i?: Maybe<Scalars['String']>;
  format_not_contains_i?: Maybe<Scalars['String']>;
  format_starts_with_i?: Maybe<Scalars['String']>;
  format_not_starts_with_i?: Maybe<Scalars['String']>;
  format_ends_with_i?: Maybe<Scalars['String']>;
  format_not_ends_with_i?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  exportedRecordsCount_not?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  exportedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where?: Maybe<Scalars['JSON']>;
  where_not?: Maybe<Scalars['JSON']>;
  where_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy?: Maybe<Scalars['JSON']>;
  sortBy_not?: Maybe<Scalars['JSON']>;
  sortBy_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<IncidentExportTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<IncidentExportTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncidentExportTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentExportTaskHistoryRecordsCreateInput = {
  data?: Maybe<IncidentExportTaskHistoryRecordCreateInput>;
};

export type IncidentExportTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentExportTaskHistoryRecordUpdateInput>;
};

export enum IncidentExportTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type IncidentExportTaskUpdateInput = {
  status?: Maybe<IncidentExportTaskStatusType>;
  format?: Maybe<IncidentExportTaskFormatType>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type IncidentExportTaskWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentExportTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentExportTaskWhereInput>>>;
  status?: Maybe<IncidentExportTaskStatusType>;
  status_not?: Maybe<IncidentExportTaskStatusType>;
  status_in?: Maybe<Array<Maybe<IncidentExportTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<IncidentExportTaskStatusType>>>;
  format?: Maybe<IncidentExportTaskFormatType>;
  format_not?: Maybe<IncidentExportTaskFormatType>;
  format_in?: Maybe<Array<Maybe<IncidentExportTaskFormatType>>>;
  format_not_in?: Maybe<Array<Maybe<IncidentExportTaskFormatType>>>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  exportedRecordsCount_not?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  exportedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where?: Maybe<Scalars['JSON']>;
  where_not?: Maybe<Scalars['JSON']>;
  where_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy?: Maybe<Scalars['JSON']>;
  sortBy_not?: Maybe<Scalars['JSON']>;
  sortBy_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type IncidentExportTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentExportTasksCreateInput = {
  data?: Maybe<IncidentExportTaskCreateInput>;
};

export type IncidentExportTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentExportTaskUpdateInput>;
};

/**  A keystone list  */
export type IncidentHistoryRecord = {
  __typename?: 'IncidentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  details?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  textForResident?: Maybe<Scalars['String']>;
  workStart?: Maybe<Scalars['String']>;
  workFinish?: Maybe<Scalars['String']>;
  workType?: Maybe<Scalars['String']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  details?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  textForResident?: Maybe<Scalars['String']>;
  workStart?: Maybe<Scalars['String']>;
  workFinish?: Maybe<Scalars['String']>;
  workType?: Maybe<Scalars['String']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum IncidentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type IncidentHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  details?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  textForResident?: Maybe<Scalars['String']>;
  workStart?: Maybe<Scalars['String']>;
  workFinish?: Maybe<Scalars['String']>;
  workType?: Maybe<Scalars['String']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['JSON']>;
  number_not?: Maybe<Scalars['JSON']>;
  number_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  details?: Maybe<Scalars['String']>;
  details_not?: Maybe<Scalars['String']>;
  details_contains?: Maybe<Scalars['String']>;
  details_not_contains?: Maybe<Scalars['String']>;
  details_starts_with?: Maybe<Scalars['String']>;
  details_not_starts_with?: Maybe<Scalars['String']>;
  details_ends_with?: Maybe<Scalars['String']>;
  details_not_ends_with?: Maybe<Scalars['String']>;
  details_i?: Maybe<Scalars['String']>;
  details_not_i?: Maybe<Scalars['String']>;
  details_contains_i?: Maybe<Scalars['String']>;
  details_not_contains_i?: Maybe<Scalars['String']>;
  details_starts_with_i?: Maybe<Scalars['String']>;
  details_not_starts_with_i?: Maybe<Scalars['String']>;
  details_ends_with_i?: Maybe<Scalars['String']>;
  details_not_ends_with_i?: Maybe<Scalars['String']>;
  details_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  details_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textForResident?: Maybe<Scalars['String']>;
  textForResident_not?: Maybe<Scalars['String']>;
  textForResident_contains?: Maybe<Scalars['String']>;
  textForResident_not_contains?: Maybe<Scalars['String']>;
  textForResident_starts_with?: Maybe<Scalars['String']>;
  textForResident_not_starts_with?: Maybe<Scalars['String']>;
  textForResident_ends_with?: Maybe<Scalars['String']>;
  textForResident_not_ends_with?: Maybe<Scalars['String']>;
  textForResident_i?: Maybe<Scalars['String']>;
  textForResident_not_i?: Maybe<Scalars['String']>;
  textForResident_contains_i?: Maybe<Scalars['String']>;
  textForResident_not_contains_i?: Maybe<Scalars['String']>;
  textForResident_starts_with_i?: Maybe<Scalars['String']>;
  textForResident_not_starts_with_i?: Maybe<Scalars['String']>;
  textForResident_ends_with_i?: Maybe<Scalars['String']>;
  textForResident_not_ends_with_i?: Maybe<Scalars['String']>;
  textForResident_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textForResident_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStart?: Maybe<Scalars['String']>;
  workStart_not?: Maybe<Scalars['String']>;
  workStart_lt?: Maybe<Scalars['String']>;
  workStart_lte?: Maybe<Scalars['String']>;
  workStart_gt?: Maybe<Scalars['String']>;
  workStart_gte?: Maybe<Scalars['String']>;
  workStart_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStart_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinish?: Maybe<Scalars['String']>;
  workFinish_not?: Maybe<Scalars['String']>;
  workFinish_lt?: Maybe<Scalars['String']>;
  workFinish_lte?: Maybe<Scalars['String']>;
  workFinish_gt?: Maybe<Scalars['String']>;
  workFinish_gte?: Maybe<Scalars['String']>;
  workFinish_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinish_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workType?: Maybe<Scalars['String']>;
  workType_not?: Maybe<Scalars['String']>;
  workType_contains?: Maybe<Scalars['String']>;
  workType_not_contains?: Maybe<Scalars['String']>;
  workType_starts_with?: Maybe<Scalars['String']>;
  workType_not_starts_with?: Maybe<Scalars['String']>;
  workType_ends_with?: Maybe<Scalars['String']>;
  workType_not_ends_with?: Maybe<Scalars['String']>;
  workType_i?: Maybe<Scalars['String']>;
  workType_not_i?: Maybe<Scalars['String']>;
  workType_contains_i?: Maybe<Scalars['String']>;
  workType_not_contains_i?: Maybe<Scalars['String']>;
  workType_starts_with_i?: Maybe<Scalars['String']>;
  workType_not_starts_with_i?: Maybe<Scalars['String']>;
  workType_ends_with_i?: Maybe<Scalars['String']>;
  workType_not_ends_with_i?: Maybe<Scalars['String']>;
  workType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllProperties_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<IncidentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<IncidentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<IncidentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<IncidentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncidentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentHistoryRecordsCreateInput = {
  data?: Maybe<IncidentHistoryRecordCreateInput>;
};

export type IncidentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentHistoryRecordUpdateInput>;
};

export type IncidentOverviewResult = {
  __typename?: 'IncidentOverviewResult';
  count: Scalars['String'];
};

export type IncidentPropertiesCreateInput = {
  data?: Maybe<IncidentPropertyCreateInput>;
};

export type IncidentPropertiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentPropertyUpdateInput>;
};

/**  Many-to-many relationship between Incident and Property  */
export type IncidentProperty = {
  __typename?: 'IncidentProperty';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentProperty List config, or
   *  3. As an alias to a 'name' field on the IncidentProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentProperty List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Incident which has a property  */
  incident?: Maybe<Incident>;
  /**  A property which is in the incident entry  */
  property?: Maybe<Property>;
  /**  Address of property, which synced with property and displayed, if property is deleted  */
  propertyAddress?: Maybe<Scalars['String']>;
  /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
  propertyAddressMeta?: Maybe<AddressMetaField>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type IncidentPropertyCreateInput = {
  incident?: Maybe<IncidentRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type IncidentPropertyHistoryRecord = {
  __typename?: 'IncidentPropertyHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the IncidentPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the IncidentPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the IncidentPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the IncidentPropertyHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  incident?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentPropertyHistoryRecordCreateInput = {
  incident?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum IncidentPropertyHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type IncidentPropertyHistoryRecordUpdateInput = {
  incident?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<IncidentPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type IncidentPropertyHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentPropertyHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentPropertyHistoryRecordWhereInput>>>;
  incident?: Maybe<Scalars['String']>;
  incident_not?: Maybe<Scalars['String']>;
  incident_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  incident_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddress_not?: Maybe<Scalars['String']>;
  propertyAddress_contains?: Maybe<Scalars['String']>;
  propertyAddress_not_contains?: Maybe<Scalars['String']>;
  propertyAddress_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_i?: Maybe<Scalars['String']>;
  propertyAddress_not_i?: Maybe<Scalars['String']>;
  propertyAddress_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_not_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddress_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_not?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<IncidentPropertyHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<IncidentPropertyHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<IncidentPropertyHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<IncidentPropertyHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IncidentPropertyHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentPropertyHistoryRecordsCreateInput = {
  data?: Maybe<IncidentPropertyHistoryRecordCreateInput>;
};

export type IncidentPropertyHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentPropertyHistoryRecordUpdateInput>;
};

export type IncidentPropertyUpdateInput = {
  incident?: Maybe<IncidentRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type IncidentPropertyWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentPropertyWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentPropertyWhereInput>>>;
  incident?: Maybe<IncidentWhereInput>;
  incident_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddress_not?: Maybe<Scalars['String']>;
  propertyAddress_contains?: Maybe<Scalars['String']>;
  propertyAddress_not_contains?: Maybe<Scalars['String']>;
  propertyAddress_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_i?: Maybe<Scalars['String']>;
  propertyAddress_not_i?: Maybe<Scalars['String']>;
  propertyAddress_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_not_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddress_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_not?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type IncidentPropertyWhereUniqueInput = {
  id: Scalars['ID'];
};

export type IncidentRelateToOneInput = {
  create?: Maybe<IncidentCreateInput>;
  connect?: Maybe<IncidentWhereUniqueInput>;
  disconnect?: Maybe<IncidentWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum IncidentStatusType {
  Actual = 'actual',
  NotActual = 'not_actual'
}

export type IncidentUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['Int']>;
  details?: Maybe<Scalars['String']>;
  status?: Maybe<IncidentStatusType>;
  textForResident?: Maybe<Scalars['String']>;
  workStart?: Maybe<Scalars['String']>;
  workFinish?: Maybe<Scalars['String']>;
  workType?: Maybe<IncidentWorkTypeType>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type IncidentWhereInput = {
  AND?: Maybe<Array<Maybe<IncidentWhereInput>>>;
  OR?: Maybe<Array<Maybe<IncidentWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  details?: Maybe<Scalars['String']>;
  details_not?: Maybe<Scalars['String']>;
  details_contains?: Maybe<Scalars['String']>;
  details_not_contains?: Maybe<Scalars['String']>;
  details_starts_with?: Maybe<Scalars['String']>;
  details_not_starts_with?: Maybe<Scalars['String']>;
  details_ends_with?: Maybe<Scalars['String']>;
  details_not_ends_with?: Maybe<Scalars['String']>;
  details_i?: Maybe<Scalars['String']>;
  details_not_i?: Maybe<Scalars['String']>;
  details_contains_i?: Maybe<Scalars['String']>;
  details_not_contains_i?: Maybe<Scalars['String']>;
  details_starts_with_i?: Maybe<Scalars['String']>;
  details_not_starts_with_i?: Maybe<Scalars['String']>;
  details_ends_with_i?: Maybe<Scalars['String']>;
  details_not_ends_with_i?: Maybe<Scalars['String']>;
  details_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  details_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<IncidentStatusType>;
  status_not?: Maybe<IncidentStatusType>;
  status_in?: Maybe<Array<Maybe<IncidentStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<IncidentStatusType>>>;
  textForResident?: Maybe<Scalars['String']>;
  textForResident_not?: Maybe<Scalars['String']>;
  textForResident_contains?: Maybe<Scalars['String']>;
  textForResident_not_contains?: Maybe<Scalars['String']>;
  textForResident_starts_with?: Maybe<Scalars['String']>;
  textForResident_not_starts_with?: Maybe<Scalars['String']>;
  textForResident_ends_with?: Maybe<Scalars['String']>;
  textForResident_not_ends_with?: Maybe<Scalars['String']>;
  textForResident_i?: Maybe<Scalars['String']>;
  textForResident_not_i?: Maybe<Scalars['String']>;
  textForResident_contains_i?: Maybe<Scalars['String']>;
  textForResident_not_contains_i?: Maybe<Scalars['String']>;
  textForResident_starts_with_i?: Maybe<Scalars['String']>;
  textForResident_not_starts_with_i?: Maybe<Scalars['String']>;
  textForResident_ends_with_i?: Maybe<Scalars['String']>;
  textForResident_not_ends_with_i?: Maybe<Scalars['String']>;
  textForResident_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  textForResident_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStart?: Maybe<Scalars['String']>;
  workStart_not?: Maybe<Scalars['String']>;
  workStart_lt?: Maybe<Scalars['String']>;
  workStart_lte?: Maybe<Scalars['String']>;
  workStart_gt?: Maybe<Scalars['String']>;
  workStart_gte?: Maybe<Scalars['String']>;
  workStart_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workStart_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinish?: Maybe<Scalars['String']>;
  workFinish_not?: Maybe<Scalars['String']>;
  workFinish_lt?: Maybe<Scalars['String']>;
  workFinish_lte?: Maybe<Scalars['String']>;
  workFinish_gt?: Maybe<Scalars['String']>;
  workFinish_gte?: Maybe<Scalars['String']>;
  workFinish_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workFinish_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  workType?: Maybe<IncidentWorkTypeType>;
  workType_not?: Maybe<IncidentWorkTypeType>;
  workType_in?: Maybe<Array<Maybe<IncidentWorkTypeType>>>;
  workType_not_in?: Maybe<Array<Maybe<IncidentWorkTypeType>>>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllProperties_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type IncidentWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum IncidentWorkTypeType {
  Scheduled = 'scheduled',
  Emergency = 'emergency'
}

export type IncidentsCreateInput = {
  data?: Maybe<IncidentCreateInput>;
};

export type IncidentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<IncidentUpdateInput>;
};

export type InviteNewOrganizationEmployeeInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role: OrganizationEmployeeRoleWhereUniqueInput;
  position?: Maybe<Scalars['String']>;
  specializations?: Maybe<Array<Maybe<TicketCategoryClassifierWhereUniqueInput>>>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
};

/**  Invoice model contains information about paid items and payer  */
export type Invoice = {
  __typename?: 'Invoice';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Invoice List config, or
   *  2. As an alias to the field set on 'labelField' in the Invoice List config, or
   *  3. As an alias to a 'name' field on the Invoice List (if one exists), or
   *  4. As an alias to the 'id' field on the Invoice List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  The invoice number within organization  */
  number?: Maybe<Scalars['Int']>;
  /**  The payer's property  */
  property?: Maybe<Property>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitType?: Maybe<InvoiceUnitTypeType>;
  /**  The payer's unitName  */
  unitName?: Maybe<Scalars['String']>;
  /**  The payer's accountNumber within organization  */
  accountNumber?: Maybe<Scalars['String']>;
  /**  Money field  */
  toPay?: Maybe<Scalars['String']>;
  /**  The list of paid items  */
  rows: Array<InvoiceRowSchemaField>;
  /**  The ticket related to this invoice  */
  ticket?: Maybe<Ticket>;
  /**  The contact the invoice created for. Fill by organization  */
  contact?: Maybe<Contact>;
  /**  This field indicates, that the Invoice is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Invoice was created by Resident from mobile app;2) the Invoice was created by OrganizationEmployee with phone number, that matches some Resident;  */
  client?: Maybe<User>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientName?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientPhone?: Maybe<Scalars['String']>;
  /**  Invoice status affects which invoices can be read by residents and which invoices can be managed. The newly created invoice has status "draft"; the "published" invoice may be paid by resident; "paid" means that invoice already paid; "canceled" means no modifications allowed. Each status, except draft, has related timestamp.  */
  status?: Maybe<InvoiceStatusType>;
  /**  Shows which payment type chosen: online or cash or something else  */
  paymentType?: Maybe<InvoicePaymentTypeType>;
  /**  When status of the invoice was changed to published (ready to pay)  */
  publishedAt?: Maybe<Scalars['String']>;
  /**  When status of the invoice was changed to paid  */
  paidAt?: Maybe<Scalars['String']>;
  /**  When status of the invoice was changed to canceled  */
  canceledAt?: Maybe<Scalars['String']>;
  /**  The recipient's requisites  */
  recipient?: Maybe<RecipientField>;
  /**  Integration ID through which this invoice can be paid  */
  acquiringIntegrationId?: Maybe<Scalars['ID']>;
  /**  Url to acquiring integration service. Mobile devices will use it communicate with external acquiring. List of endpoints is the same for all of them.  */
  acquiringHostUrl?: Maybe<Scalars['String']>;
  /**  Can multiple receipts be united through this acquiring  */
  canGroupReceipts?: Maybe<Scalars['Boolean']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type InvoiceCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['Int']>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitType?: Maybe<InvoiceUnitTypeType>;
  unitName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  rows?: Maybe<Array<InvoiceRowSchemaFieldInput>>;
  ticket?: Maybe<TicketRelateToOneInput>;
  contact?: Maybe<ContactRelateToOneInput>;
  client?: Maybe<UserRelateToOneInput>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatusType>;
  paymentType?: Maybe<InvoicePaymentTypeType>;
  publishedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type InvoiceHistoryRecord = {
  __typename?: 'InvoiceHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the InvoiceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the InvoiceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the InvoiceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the InvoiceHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  rows?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<InvoiceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type InvoiceHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  rows?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<InvoiceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum InvoiceHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type InvoiceHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  rows?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<InvoiceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type InvoiceHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<InvoiceHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<InvoiceHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['JSON']>;
  number_not?: Maybe<Scalars['JSON']>;
  number_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPay?: Maybe<Scalars['String']>;
  toPay_not?: Maybe<Scalars['String']>;
  toPay_lt?: Maybe<Scalars['String']>;
  toPay_lte?: Maybe<Scalars['String']>;
  toPay_gt?: Maybe<Scalars['String']>;
  toPay_gte?: Maybe<Scalars['String']>;
  toPay_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPay_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  rows?: Maybe<Scalars['JSON']>;
  rows_not?: Maybe<Scalars['JSON']>;
  rows_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  rows_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact?: Maybe<Scalars['String']>;
  contact_not?: Maybe<Scalars['String']>;
  contact_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  client?: Maybe<Scalars['String']>;
  client_not?: Maybe<Scalars['String']>;
  client_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName?: Maybe<Scalars['String']>;
  clientName_not?: Maybe<Scalars['String']>;
  clientName_contains?: Maybe<Scalars['String']>;
  clientName_not_contains?: Maybe<Scalars['String']>;
  clientName_starts_with?: Maybe<Scalars['String']>;
  clientName_not_starts_with?: Maybe<Scalars['String']>;
  clientName_ends_with?: Maybe<Scalars['String']>;
  clientName_not_ends_with?: Maybe<Scalars['String']>;
  clientName_i?: Maybe<Scalars['String']>;
  clientName_not_i?: Maybe<Scalars['String']>;
  clientName_contains_i?: Maybe<Scalars['String']>;
  clientName_not_contains_i?: Maybe<Scalars['String']>;
  clientName_starts_with_i?: Maybe<Scalars['String']>;
  clientName_not_starts_with_i?: Maybe<Scalars['String']>;
  clientName_ends_with_i?: Maybe<Scalars['String']>;
  clientName_not_ends_with_i?: Maybe<Scalars['String']>;
  clientName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Scalars['String']>;
  clientPhone_not?: Maybe<Scalars['String']>;
  clientPhone_contains?: Maybe<Scalars['String']>;
  clientPhone_not_contains?: Maybe<Scalars['String']>;
  clientPhone_starts_with?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with?: Maybe<Scalars['String']>;
  clientPhone_ends_with?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with?: Maybe<Scalars['String']>;
  clientPhone_i?: Maybe<Scalars['String']>;
  clientPhone_not_i?: Maybe<Scalars['String']>;
  clientPhone_contains_i?: Maybe<Scalars['String']>;
  clientPhone_not_contains_i?: Maybe<Scalars['String']>;
  clientPhone_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentType?: Maybe<Scalars['String']>;
  paymentType_not?: Maybe<Scalars['String']>;
  paymentType_contains?: Maybe<Scalars['String']>;
  paymentType_not_contains?: Maybe<Scalars['String']>;
  paymentType_starts_with?: Maybe<Scalars['String']>;
  paymentType_not_starts_with?: Maybe<Scalars['String']>;
  paymentType_ends_with?: Maybe<Scalars['String']>;
  paymentType_not_ends_with?: Maybe<Scalars['String']>;
  paymentType_i?: Maybe<Scalars['String']>;
  paymentType_not_i?: Maybe<Scalars['String']>;
  paymentType_contains_i?: Maybe<Scalars['String']>;
  paymentType_not_contains_i?: Maybe<Scalars['String']>;
  paymentType_starts_with_i?: Maybe<Scalars['String']>;
  paymentType_not_starts_with_i?: Maybe<Scalars['String']>;
  paymentType_ends_with_i?: Maybe<Scalars['String']>;
  paymentType_not_ends_with_i?: Maybe<Scalars['String']>;
  paymentType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedAt_not?: Maybe<Scalars['String']>;
  publishedAt_lt?: Maybe<Scalars['String']>;
  publishedAt_lte?: Maybe<Scalars['String']>;
  publishedAt_gt?: Maybe<Scalars['String']>;
  publishedAt_gte?: Maybe<Scalars['String']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paidAt?: Maybe<Scalars['String']>;
  paidAt_not?: Maybe<Scalars['String']>;
  paidAt_lt?: Maybe<Scalars['String']>;
  paidAt_lte?: Maybe<Scalars['String']>;
  paidAt_gt?: Maybe<Scalars['String']>;
  paidAt_gte?: Maybe<Scalars['String']>;
  paidAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paidAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canceledAt?: Maybe<Scalars['String']>;
  canceledAt_not?: Maybe<Scalars['String']>;
  canceledAt_lt?: Maybe<Scalars['String']>;
  canceledAt_lte?: Maybe<Scalars['String']>;
  canceledAt_gt?: Maybe<Scalars['String']>;
  canceledAt_gte?: Maybe<Scalars['String']>;
  canceledAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canceledAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<InvoiceHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<InvoiceHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<InvoiceHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<InvoiceHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoiceHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type InvoiceHistoryRecordsCreateInput = {
  data?: Maybe<InvoiceHistoryRecordCreateInput>;
};

export type InvoiceHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<InvoiceHistoryRecordUpdateInput>;
};

export enum InvoicePaymentType {
  Online = 'online',
  Cash = 'cash'
}

export enum InvoicePaymentTypeType {
  Online = 'online',
  Cash = 'cash'
}

export type InvoiceRelateToOneInput = {
  create?: Maybe<InvoiceCreateInput>;
  connect?: Maybe<InvoiceWhereUniqueInput>;
  disconnect?: Maybe<InvoiceWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type InvoiceRowMetaSchemaField = {
  __typename?: 'InvoiceRowMetaSchemaField';
  imageUrl?: Maybe<Scalars['String']>;
  categoryBgColor?: Maybe<Scalars['String']>;
};

export type InvoiceRowMetaSchemaFieldInput = {
  imageUrl?: Maybe<Scalars['String']>;
  categoryBgColor?: Maybe<Scalars['String']>;
};

export type InvoiceRowSchemaField = {
  __typename?: 'InvoiceRowSchemaField';
  name: Scalars['String'];
  toPay: Scalars['String'];
  count: Scalars['Int'];
  currencyCode?: Maybe<Scalars['String']>;
  vatPercent?: Maybe<Scalars['String']>;
  salesTaxPercent?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  isMin: Scalars['Boolean'];
  meta?: Maybe<InvoiceRowMetaSchemaField>;
};

export type InvoiceRowSchemaFieldInput = {
  name: Scalars['String'];
  toPay: Scalars['String'];
  count: Scalars['Int'];
  currencyCode?: Maybe<Scalars['String']>;
  vatPercent?: Maybe<Scalars['String']>;
  salesTaxPercent?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  isMin: Scalars['Boolean'];
  meta?: Maybe<InvoiceRowMetaSchemaFieldInput>;
};

export type InvoiceRowsInput = {
  priceScope: MarketPriceScopeWhereUniqueInput;
  count: Scalars['Int'];
};

export enum InvoiceStatusType {
  Draft = 'draft',
  Published = 'published',
  Paid = 'paid',
  Canceled = 'canceled'
}

export enum InvoiceUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type InvoiceUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['Int']>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitType?: Maybe<InvoiceUnitTypeType>;
  unitName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  rows?: Maybe<Array<InvoiceRowSchemaFieldInput>>;
  ticket?: Maybe<TicketRelateToOneInput>;
  contact?: Maybe<ContactRelateToOneInput>;
  client?: Maybe<UserRelateToOneInput>;
  clientName?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  status?: Maybe<InvoiceStatusType>;
  paymentType?: Maybe<InvoicePaymentTypeType>;
  publishedAt?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['String']>;
  canceledAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<Maybe<InvoiceWhereInput>>>;
  OR?: Maybe<Array<Maybe<InvoiceWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  unitType?: Maybe<InvoiceUnitTypeType>;
  unitType_not?: Maybe<InvoiceUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<InvoiceUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<InvoiceUnitTypeType>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPay?: Maybe<Scalars['String']>;
  toPay_not?: Maybe<Scalars['String']>;
  toPay_lt?: Maybe<Scalars['String']>;
  toPay_lte?: Maybe<Scalars['String']>;
  toPay_gt?: Maybe<Scalars['String']>;
  toPay_gte?: Maybe<Scalars['String']>;
  toPay_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  toPay_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  rows?: Maybe<Array<InvoiceRowSchemaFieldInput>>;
  rows_not?: Maybe<Array<InvoiceRowSchemaFieldInput>>;
  rows_in?: Maybe<Array<Maybe<Array<InvoiceRowSchemaFieldInput>>>>;
  rows_not_in?: Maybe<Array<Maybe<Array<InvoiceRowSchemaFieldInput>>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<ContactWhereInput>;
  contact_is_null?: Maybe<Scalars['Boolean']>;
  client?: Maybe<UserWhereInput>;
  client_is_null?: Maybe<Scalars['Boolean']>;
  clientName?: Maybe<Scalars['String']>;
  clientName_not?: Maybe<Scalars['String']>;
  clientName_contains?: Maybe<Scalars['String']>;
  clientName_not_contains?: Maybe<Scalars['String']>;
  clientName_starts_with?: Maybe<Scalars['String']>;
  clientName_not_starts_with?: Maybe<Scalars['String']>;
  clientName_ends_with?: Maybe<Scalars['String']>;
  clientName_not_ends_with?: Maybe<Scalars['String']>;
  clientName_i?: Maybe<Scalars['String']>;
  clientName_not_i?: Maybe<Scalars['String']>;
  clientName_contains_i?: Maybe<Scalars['String']>;
  clientName_not_contains_i?: Maybe<Scalars['String']>;
  clientName_starts_with_i?: Maybe<Scalars['String']>;
  clientName_not_starts_with_i?: Maybe<Scalars['String']>;
  clientName_ends_with_i?: Maybe<Scalars['String']>;
  clientName_not_ends_with_i?: Maybe<Scalars['String']>;
  clientName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Scalars['String']>;
  clientPhone_not?: Maybe<Scalars['String']>;
  clientPhone_contains?: Maybe<Scalars['String']>;
  clientPhone_not_contains?: Maybe<Scalars['String']>;
  clientPhone_starts_with?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with?: Maybe<Scalars['String']>;
  clientPhone_ends_with?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with?: Maybe<Scalars['String']>;
  clientPhone_i?: Maybe<Scalars['String']>;
  clientPhone_not_i?: Maybe<Scalars['String']>;
  clientPhone_contains_i?: Maybe<Scalars['String']>;
  clientPhone_not_contains_i?: Maybe<Scalars['String']>;
  clientPhone_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<InvoiceStatusType>;
  status_not?: Maybe<InvoiceStatusType>;
  status_in?: Maybe<Array<Maybe<InvoiceStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<InvoiceStatusType>>>;
  paymentType?: Maybe<InvoicePaymentTypeType>;
  paymentType_not?: Maybe<InvoicePaymentTypeType>;
  paymentType_in?: Maybe<Array<Maybe<InvoicePaymentTypeType>>>;
  paymentType_not_in?: Maybe<Array<Maybe<InvoicePaymentTypeType>>>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedAt_not?: Maybe<Scalars['String']>;
  publishedAt_lt?: Maybe<Scalars['String']>;
  publishedAt_lte?: Maybe<Scalars['String']>;
  publishedAt_gt?: Maybe<Scalars['String']>;
  publishedAt_gte?: Maybe<Scalars['String']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paidAt?: Maybe<Scalars['String']>;
  paidAt_not?: Maybe<Scalars['String']>;
  paidAt_lt?: Maybe<Scalars['String']>;
  paidAt_lte?: Maybe<Scalars['String']>;
  paidAt_gt?: Maybe<Scalars['String']>;
  paidAt_gte?: Maybe<Scalars['String']>;
  paidAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paidAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canceledAt?: Maybe<Scalars['String']>;
  canceledAt_not?: Maybe<Scalars['String']>;
  canceledAt_lt?: Maybe<Scalars['String']>;
  canceledAt_lte?: Maybe<Scalars['String']>;
  canceledAt_gt?: Maybe<Scalars['String']>;
  canceledAt_gte?: Maybe<Scalars['String']>;
  canceledAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canceledAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type InvoiceWhereUniqueInput = {
  id: Scalars['ID'];
};

export type InvoicesCreateInput = {
  data?: Maybe<InvoiceCreateInput>;
};

export type InvoicesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<InvoiceUpdateInput>;
};


export type MarketCategoriesCreateInput = {
  data?: Maybe<MarketCategoryCreateInput>;
};

export type MarketCategoriesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketCategoryUpdateInput>;
};

/**  An entity that allows you to define a category tree in the marketplace  */
export type MarketCategory = {
  __typename?: 'MarketCategory';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketCategory List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketCategory List config, or
   *  3. As an alias to a 'name' field on the MarketCategory List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketCategory List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The category name  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  /**  Preview image  */
  image?: Maybe<File>;
  /**  Settings for mobile ui  */
  mobileSettings?: Maybe<MarketCategoryMobileSettingsSchemaFieldInput>;
  /**  Which category does this subcategory belong to  */
  parentCategory?: Maybe<MarketCategory>;
  /**  The number used for sorting at the client. It's possible to sort categories any manner. For example, set some category to the bottom by setting order=100 and sorting asc.  */
  order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MarketCategoryCreateInput = {
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  mobileSettings?: Maybe<MarketCategoryMobileSettingsSchemaField>;
  parentCategory?: Maybe<MarketCategoryRelateToOneInput>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MarketCategoryHistoryRecord = {
  __typename?: 'MarketCategoryHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketCategoryHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketCategoryHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketCategoryHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketCategoryHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['JSON']>;
  mobileSettings?: Maybe<Scalars['JSON']>;
  parentCategory?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketCategoryHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['JSON']>;
  mobileSettings?: Maybe<Scalars['JSON']>;
  parentCategory?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MarketCategoryHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MarketCategoryHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['JSON']>;
  mobileSettings?: Maybe<Scalars['JSON']>;
  parentCategory?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketCategoryHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketCategoryHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MarketCategoryHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketCategoryHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['JSON']>;
  image_not?: Maybe<Scalars['JSON']>;
  image_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  image_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  mobileSettings?: Maybe<Scalars['JSON']>;
  mobileSettings_not?: Maybe<Scalars['JSON']>;
  mobileSettings_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  mobileSettings_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  parentCategory?: Maybe<Scalars['String']>;
  parentCategory_not?: Maybe<Scalars['String']>;
  parentCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  parentCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MarketCategoryHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MarketCategoryHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MarketCategoryHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MarketCategoryHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketCategoryHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketCategoryHistoryRecordsCreateInput = {
  data?: Maybe<MarketCategoryHistoryRecordCreateInput>;
};

export type MarketCategoryHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketCategoryHistoryRecordUpdateInput>;
};

export type MarketCategoryMobileSettingsSchemaField = {
  bgColor: Scalars['String'];
  titleColor: Scalars['String'];
};

export type MarketCategoryMobileSettingsSchemaFieldInput = {
  __typename?: 'MarketCategoryMobileSettingsSchemaFieldInput';
  bgColor: Scalars['String'];
  titleColor: Scalars['String'];
};

export type MarketCategoryRelateToOneInput = {
  create?: Maybe<MarketCategoryCreateInput>;
  connect?: Maybe<MarketCategoryWhereUniqueInput>;
  disconnect?: Maybe<MarketCategoryWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type MarketCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  mobileSettings?: Maybe<MarketCategoryMobileSettingsSchemaField>;
  parentCategory?: Maybe<MarketCategoryRelateToOneInput>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MarketCategoryWhereInput = {
  AND?: Maybe<Array<Maybe<MarketCategoryWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketCategoryWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  image_not?: Maybe<Scalars['String']>;
  image_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  image_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  mobileSettings?: Maybe<MarketCategoryMobileSettingsSchemaField>;
  mobileSettings_not?: Maybe<MarketCategoryMobileSettingsSchemaField>;
  mobileSettings_in?: Maybe<Array<Maybe<MarketCategoryMobileSettingsSchemaField>>>;
  mobileSettings_not_in?: Maybe<Array<Maybe<MarketCategoryMobileSettingsSchemaField>>>;
  parentCategory?: Maybe<MarketCategoryWhereInput>;
  parentCategory_is_null?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MarketCategoryWhereUniqueInput = {
  id: Scalars['ID'];
};

/**  Information about the market product  */
export type MarketItem = {
  __typename?: 'MarketItem';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItem List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItem List config, or
   *  3. As an alias to a 'name' field on the MarketItem List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItem List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name of market item  */
  name?: Maybe<Scalars['String']>;
  /**  Category to which the item belongs  */
  marketCategory?: Maybe<MarketCategory>;
  /**  Stock keeping unit  */
  sku?: Maybe<Scalars['String']>;
  /**  Description of market item  */
  description?: Maybe<Scalars['String']>;
  /**  Organization to which the item belongs  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MarketItemCreateInput = {
  name?: Maybe<Scalars['String']>;
  marketCategory?: Maybe<MarketCategoryRelateToOneInput>;
  sku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  Image file attached to the market item  */
export type MarketItemFile = {
  __typename?: 'MarketItemFile';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemFile List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemFile List config, or
   *  3. As an alias to a 'name' field on the MarketItemFile List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemFile List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to MarketItem  */
  marketItem?: Maybe<MarketItem>;
  /**  File object with meta information and publicUrl  */
  file?: Maybe<File>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MarketItemFileCreateInput = {
  marketItem?: Maybe<MarketItemRelateToOneInput>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MarketItemFileHistoryRecord = {
  __typename?: 'MarketItemFileHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketItemFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemFileHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  marketItem?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketItemFileHistoryRecordCreateInput = {
  marketItem?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MarketItemFileHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MarketItemFileHistoryRecordUpdateInput = {
  marketItem?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketItemFileHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MarketItemFileHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketItemFileHistoryRecordWhereInput>>>;
  marketItem?: Maybe<Scalars['String']>;
  marketItem_not?: Maybe<Scalars['String']>;
  marketItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MarketItemFileHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MarketItemFileHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MarketItemFileHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MarketItemFileHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketItemFileHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketItemFileHistoryRecordsCreateInput = {
  data?: Maybe<MarketItemFileHistoryRecordCreateInput>;
};

export type MarketItemFileHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemFileHistoryRecordUpdateInput>;
};

export type MarketItemFileUpdateInput = {
  marketItem?: Maybe<MarketItemRelateToOneInput>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MarketItemFileWhereInput = {
  AND?: Maybe<Array<Maybe<MarketItemFileWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketItemFileWhereInput>>>;
  marketItem?: Maybe<MarketItemWhereInput>;
  marketItem_is_null?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MarketItemFileWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketItemFilesCreateInput = {
  data?: Maybe<MarketItemFileCreateInput>;
};

export type MarketItemFilesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemFileUpdateInput>;
};

/**  A keystone list  */
export type MarketItemHistoryRecord = {
  __typename?: 'MarketItemHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketItemHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  marketCategory?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketItemHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  marketCategory?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MarketItemHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MarketItemHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  marketCategory?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketItemHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MarketItemHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketItemHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketCategory?: Maybe<Scalars['String']>;
  marketCategory_not?: Maybe<Scalars['String']>;
  marketCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  sku_i?: Maybe<Scalars['String']>;
  sku_not_i?: Maybe<Scalars['String']>;
  sku_contains_i?: Maybe<Scalars['String']>;
  sku_not_contains_i?: Maybe<Scalars['String']>;
  sku_starts_with_i?: Maybe<Scalars['String']>;
  sku_not_starts_with_i?: Maybe<Scalars['String']>;
  sku_ends_with_i?: Maybe<Scalars['String']>;
  sku_not_ends_with_i?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sku_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MarketItemHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MarketItemHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MarketItemHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MarketItemHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketItemHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketItemHistoryRecordsCreateInput = {
  data?: Maybe<MarketItemHistoryRecordCreateInput>;
};

export type MarketItemHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemHistoryRecordUpdateInput>;
};

/**  MarketItemPrice is set separately from MarketItem and indicates the price of the service.  */
export type MarketItemPrice = {
  __typename?: 'MarketItemPrice';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemPrice List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemPrice List config, or
   *  3. As an alias to a 'name' field on the MarketItemPrice List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemPrice List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The price field contains information about options (type=variant) and additional services (type=extra). Options are grouped by the group field and offer the choice of one option from the group. Extras are not grouped in any way and can be selected independently. The price may not be final but minimum. To do this, we use the isMin field.  */
  price: Array<MarketItemPricePriceSchemaField>;
  /**  Link to MarketItem  */
  marketItem?: Maybe<MarketItem>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MarketItemPriceCreateInput = {
  price?: Maybe<Array<MarketItemPricePriceSchemaFieldInput>>;
  marketItem?: Maybe<MarketItemRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MarketItemPriceHistoryRecord = {
  __typename?: 'MarketItemPriceHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketItemPriceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketItemPriceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketItemPriceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketItemPriceHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['JSON']>;
  marketItem?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemPriceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketItemPriceHistoryRecordCreateInput = {
  price?: Maybe<Scalars['JSON']>;
  marketItem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemPriceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MarketItemPriceHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MarketItemPriceHistoryRecordUpdateInput = {
  price?: Maybe<Scalars['JSON']>;
  marketItem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketItemPriceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketItemPriceHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MarketItemPriceHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketItemPriceHistoryRecordWhereInput>>>;
  price?: Maybe<Scalars['JSON']>;
  price_not?: Maybe<Scalars['JSON']>;
  price_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  price_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  marketItem?: Maybe<Scalars['String']>;
  marketItem_not?: Maybe<Scalars['String']>;
  marketItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MarketItemPriceHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MarketItemPriceHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MarketItemPriceHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MarketItemPriceHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketItemPriceHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketItemPriceHistoryRecordsCreateInput = {
  data?: Maybe<MarketItemPriceHistoryRecordCreateInput>;
};

export type MarketItemPriceHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemPriceHistoryRecordUpdateInput>;
};

export type MarketItemPricePriceSchemaField = {
  __typename?: 'MarketItemPricePriceSchemaField';
  type: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['String'];
  isMin: Scalars['Boolean'];
  vatPercent?: Maybe<Scalars['String']>;
  salesTaxPercent?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type MarketItemPricePriceSchemaFieldInput = {
  type: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['String'];
  isMin: Scalars['Boolean'];
  vatPercent?: Maybe<Scalars['String']>;
  salesTaxPercent?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
};

export type MarketItemPriceRelateToOneInput = {
  create?: Maybe<MarketItemPriceCreateInput>;
  connect?: Maybe<MarketItemPriceWhereUniqueInput>;
  disconnect?: Maybe<MarketItemPriceWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type MarketItemPriceUpdateInput = {
  price?: Maybe<Array<MarketItemPricePriceSchemaFieldInput>>;
  marketItem?: Maybe<MarketItemRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MarketItemPriceWhereInput = {
  AND?: Maybe<Array<Maybe<MarketItemPriceWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketItemPriceWhereInput>>>;
  price?: Maybe<Array<MarketItemPricePriceSchemaFieldInput>>;
  price_not?: Maybe<Array<MarketItemPricePriceSchemaFieldInput>>;
  price_in?: Maybe<Array<Maybe<Array<MarketItemPricePriceSchemaFieldInput>>>>;
  price_not_in?: Maybe<Array<Maybe<Array<MarketItemPricePriceSchemaFieldInput>>>>;
  marketItem?: Maybe<MarketItemWhereInput>;
  marketItem_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MarketItemPriceWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketItemPricesCreateInput = {
  data?: Maybe<MarketItemPriceCreateInput>;
};

export type MarketItemPricesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemPriceUpdateInput>;
};

export type MarketItemRelateToOneInput = {
  create?: Maybe<MarketItemCreateInput>;
  connect?: Maybe<MarketItemWhereUniqueInput>;
  disconnect?: Maybe<MarketItemWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type MarketItemUpdateInput = {
  name?: Maybe<Scalars['String']>;
  marketCategory?: Maybe<MarketCategoryRelateToOneInput>;
  sku?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MarketItemWhereInput = {
  AND?: Maybe<Array<Maybe<MarketItemWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketItemWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketCategory?: Maybe<MarketCategoryWhereInput>;
  marketCategory_is_null?: Maybe<Scalars['Boolean']>;
  sku?: Maybe<Scalars['String']>;
  sku_not?: Maybe<Scalars['String']>;
  sku_contains?: Maybe<Scalars['String']>;
  sku_not_contains?: Maybe<Scalars['String']>;
  sku_starts_with?: Maybe<Scalars['String']>;
  sku_not_starts_with?: Maybe<Scalars['String']>;
  sku_ends_with?: Maybe<Scalars['String']>;
  sku_not_ends_with?: Maybe<Scalars['String']>;
  sku_i?: Maybe<Scalars['String']>;
  sku_not_i?: Maybe<Scalars['String']>;
  sku_contains_i?: Maybe<Scalars['String']>;
  sku_not_contains_i?: Maybe<Scalars['String']>;
  sku_starts_with_i?: Maybe<Scalars['String']>;
  sku_not_starts_with_i?: Maybe<Scalars['String']>;
  sku_ends_with_i?: Maybe<Scalars['String']>;
  sku_not_ends_with_i?: Maybe<Scalars['String']>;
  sku_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sku_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MarketItemWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketItemsCreateInput = {
  data?: Maybe<MarketItemCreateInput>;
};

export type MarketItemsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemUpdateInput>;
};

/**  Which residents can see the particular market item price instance  */
export type MarketPriceScope = {
  __typename?: 'MarketPriceScope';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketPriceScope List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketPriceScope List config, or
   *  3. As an alias to a 'name' field on the MarketPriceScope List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketPriceScope List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to market item price  */
  marketItemPrice?: Maybe<MarketItemPrice>;
  /**  Link to property  */
  property?: Maybe<Property>;
  /**  The scope type. This is an auto-calculated field. Used to find items by scopes filled with some set of attributes.  */
  type?: Maybe<MarketPriceScopeTypeType>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MarketPriceScopeCreateInput = {
  marketItemPrice?: Maybe<MarketItemPriceRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  type?: Maybe<MarketPriceScopeTypeType>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MarketPriceScopeHistoryRecord = {
  __typename?: 'MarketPriceScopeHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketPriceScopeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketPriceScopeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketPriceScopeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketPriceScopeHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  marketItemPrice?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketPriceScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketPriceScopeHistoryRecordCreateInput = {
  marketItemPrice?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketPriceScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MarketPriceScopeHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MarketPriceScopeHistoryRecordUpdateInput = {
  marketItemPrice?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketPriceScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketPriceScopeHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecordWhereInput>>>;
  marketItemPrice?: Maybe<Scalars['String']>;
  marketItemPrice_not?: Maybe<Scalars['String']>;
  marketItemPrice_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketItemPrice_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MarketPriceScopeHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MarketPriceScopeHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketPriceScopeHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketPriceScopeHistoryRecordsCreateInput = {
  data?: Maybe<MarketPriceScopeHistoryRecordCreateInput>;
};

export type MarketPriceScopeHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketPriceScopeHistoryRecordUpdateInput>;
};

export enum MarketPriceScopeTypeType {
  Unknown = 'unknown',
  Organization = 'organization',
  Property = 'property'
}

export type MarketPriceScopeUpdateInput = {
  marketItemPrice?: Maybe<MarketItemPriceRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  type?: Maybe<MarketPriceScopeTypeType>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MarketPriceScopeWhereInput = {
  AND?: Maybe<Array<Maybe<MarketPriceScopeWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketPriceScopeWhereInput>>>;
  marketItemPrice?: Maybe<MarketItemPriceWhereInput>;
  marketItemPrice_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MarketPriceScopeTypeType>;
  type_not?: Maybe<MarketPriceScopeTypeType>;
  type_in?: Maybe<Array<Maybe<MarketPriceScopeTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<MarketPriceScopeTypeType>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MarketPriceScopeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketPriceScopesCreateInput = {
  data?: Maybe<MarketPriceScopeCreateInput>;
};

export type MarketPriceScopesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketPriceScopeUpdateInput>;
};

/**  Setting up special rules for the marketplace  */
export type MarketSetting = {
  __typename?: 'MarketSetting';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketSetting List config, or
   *  3. As an alias to a 'name' field on the MarketSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketSetting List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Types of payments are allowed for selection on the resident’s side  */
  residentAllowedPaymentTypes?: Maybe<Array<InvoicePaymentTypeType>>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MarketSettingCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  residentAllowedPaymentTypes?: Maybe<Array<InvoicePaymentTypeType>>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MarketSettingHistoryRecord = {
  __typename?: 'MarketSettingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MarketSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MarketSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MarketSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MarketSettingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  residentAllowedPaymentTypes?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketSettingHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  residentAllowedPaymentTypes?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MarketSettingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MarketSettingHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  residentAllowedPaymentTypes?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MarketSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MarketSettingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MarketSettingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketSettingHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  residentAllowedPaymentTypes?: Maybe<Scalars['JSON']>;
  residentAllowedPaymentTypes_not?: Maybe<Scalars['JSON']>;
  residentAllowedPaymentTypes_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  residentAllowedPaymentTypes_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MarketSettingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MarketSettingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MarketSettingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MarketSettingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MarketSettingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketSettingHistoryRecordsCreateInput = {
  data?: Maybe<MarketSettingHistoryRecordCreateInput>;
};

export type MarketSettingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketSettingHistoryRecordUpdateInput>;
};

export type MarketSettingUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  residentAllowedPaymentTypes?: Maybe<Array<InvoicePaymentTypeType>>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MarketSettingWhereInput = {
  AND?: Maybe<Array<Maybe<MarketSettingWhereInput>>>;
  OR?: Maybe<Array<Maybe<MarketSettingWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  residentAllowedPaymentTypes?: Maybe<Array<InvoicePaymentTypeType>>;
  residentAllowedPaymentTypes_not?: Maybe<Array<InvoicePaymentTypeType>>;
  residentAllowedPaymentTypes_in?: Maybe<Array<Maybe<Array<InvoicePaymentTypeType>>>>;
  residentAllowedPaymentTypes_not_in?: Maybe<Array<Maybe<Array<InvoicePaymentTypeType>>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MarketSettingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MarketSettingsCreateInput = {
  data?: Maybe<MarketSettingCreateInput>;
};

export type MarketSettingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MarketSettingUpdateInput>;
};

/**  Notification message  */
export type Message = {
  __typename?: 'Message';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Message List config, or
   *  2. As an alias to the field set on 'labelField' in the Message List config, or
   *  3. As an alias to a 'name' field on the Message List (if one exists), or
   *  4. As an alias to the 'id' field on the Message List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  This message is related to some organization. Organization can manage their messages  */
  organization?: Maybe<Organization>;
  /**  to User  */
  user?: Maybe<User>;
  /**  to Phone  */
  phone?: Maybe<Scalars['String']>;
  /**  to Email  */
  email?: Maybe<Scalars['String']>;
  /**  to Remote client  */
  remoteClient?: Maybe<RemoteClient>;
  /**  from Email  */
  emailFrom?: Maybe<Scalars['String']>;
  /**  Message status  */
  lang?: Maybe<MessageLangType>;
  /**  Message type  */
  type?: Maybe<MessageType>;
  /**  Message context  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Message status  */
  status?: Maybe<MessageStatusType>;
  /**  Task processing metadata. Just for debug purpose. You can see exactly what and where the message was sent  */
  processingMeta?: Maybe<Scalars['JSON']>;
  /**  Delivered (received) at time  */
  deliveredAt?: Maybe<Scalars['String']>;
  /**  Sent at time  */
  sentAt?: Maybe<Scalars['String']>;
  /**  Read at time  */
  readAt?: Maybe<Scalars['String']>;
  /**  Unique message key. You can use it if you need to make sure that the message you are trying to create has not been created before. Fields `user`, `type` and `uniqkey` is to be unique. If you don't have a `user`, the fields `type` and `uniqkey` is to be unique  */
  uniqKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

/**  [Deprecated] Rule for blocking specific type of messages for apps. Use B2CAppMessageSetting  */
export type MessageAppBlackList = {
  __typename?: 'MessageAppBlackList';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageAppBlackList List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageAppBlackList List config, or
   *  3. As an alias to a 'name' field on the MessageAppBlackList List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageAppBlackList List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  the app we want to block from sending messages to (null - all apps)  */
  app?: Maybe<B2CApp>;
  /**  The type of message we want to block  */
  type?: Maybe<MessageType>;
  /**  The reason why the entry was added to the MessageAppBlackList  */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MessageAppBlackListCreateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  type?: Maybe<MessageType>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MessageAppBlackListHistoryRecord = {
  __typename?: 'MessageAppBlackListHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageAppBlackListHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageAppBlackListHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageAppBlackListHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageAppBlackListHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageAppBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageAppBlackListHistoryRecordCreateInput = {
  app?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageAppBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MessageAppBlackListHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MessageAppBlackListHistoryRecordUpdateInput = {
  app?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageAppBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageAppBlackListHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecordWhereInput>>>;
  app?: Maybe<Scalars['String']>;
  app_not?: Maybe<Scalars['String']>;
  app_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MessageAppBlackListHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MessageAppBlackListHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MessageAppBlackListHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageAppBlackListHistoryRecordsCreateInput = {
  data?: Maybe<MessageAppBlackListHistoryRecordCreateInput>;
};

export type MessageAppBlackListHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageAppBlackListHistoryRecordUpdateInput>;
};

export type MessageAppBlackListUpdateInput = {
  app?: Maybe<B2CAppRelateToOneInput>;
  type?: Maybe<MessageType>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MessageAppBlackListWhereInput = {
  AND?: Maybe<Array<Maybe<MessageAppBlackListWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageAppBlackListWhereInput>>>;
  app?: Maybe<B2CAppWhereInput>;
  app_is_null?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
  type_not?: Maybe<MessageType>;
  type_in?: Maybe<Array<Maybe<MessageType>>>;
  type_not_in?: Maybe<Array<Maybe<MessageType>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MessageAppBlackListWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageAppBlackListsCreateInput = {
  data?: Maybe<MessageAppBlackListCreateInput>;
};

export type MessageAppBlackListsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageAppBlackListUpdateInput>;
};

/**  Message batch. Adding record here will cause sending message to all targets (user, phone or email) listed in targets via transport detected based on target type.  */
export type MessageBatch = {
  __typename?: 'MessageBatch';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageBatch List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageBatch List config, or
   *  3. As an alias to a 'name' field on the MessageBatch List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageBatch List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Message type to use for sending notification  */
  messageType?: Maybe<MessageBatchMessageTypeType>;
  /**  Common title for messages to be sent. Single line, shouldn't be very long.  */
  title?: Maybe<Scalars['String']>;
  /**  Common body for messages to be sent. Can be multiline, but shouldn't be very long in case of SMS or Push.  */
  message?: Maybe<Scalars['String']>;
  /**  A link to bring user to specified position within a mobile app. Used ONLY for push notifications  */
  deepLink?: Maybe<Scalars['String']>;
  /**  List of ids for "push", "email", "sms" message types; list of emails for "email" message types; list of phone numbers for "sms" message types. Can be mixed. For each entry an appropriate message type will be used.  */
  targets?: Maybe<Scalars['JSON']>;
  /**  Batch processing status  */
  status?: Maybe<MessageBatchStatusType>;
  /**  Batch processing results will be stored here  */
  processingMeta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MessageBatchCreateInput = {
  messageType?: Maybe<MessageBatchMessageTypeType>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  targets?: Maybe<Scalars['JSON']>;
  status?: Maybe<MessageBatchStatusType>;
  processingMeta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MessageBatchHistoryRecord = {
  __typename?: 'MessageBatchHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageBatchHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageBatchHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageBatchHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageBatchHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  targets?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  processingMeta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageBatchHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageBatchHistoryRecordCreateInput = {
  messageType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  targets?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  processingMeta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageBatchHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MessageBatchHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MessageBatchHistoryRecordUpdateInput = {
  messageType?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  targets?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  processingMeta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageBatchHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageBatchHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MessageBatchHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageBatchHistoryRecordWhereInput>>>;
  messageType?: Maybe<Scalars['String']>;
  messageType_not?: Maybe<Scalars['String']>;
  messageType_contains?: Maybe<Scalars['String']>;
  messageType_not_contains?: Maybe<Scalars['String']>;
  messageType_starts_with?: Maybe<Scalars['String']>;
  messageType_not_starts_with?: Maybe<Scalars['String']>;
  messageType_ends_with?: Maybe<Scalars['String']>;
  messageType_not_ends_with?: Maybe<Scalars['String']>;
  messageType_i?: Maybe<Scalars['String']>;
  messageType_not_i?: Maybe<Scalars['String']>;
  messageType_contains_i?: Maybe<Scalars['String']>;
  messageType_not_contains_i?: Maybe<Scalars['String']>;
  messageType_starts_with_i?: Maybe<Scalars['String']>;
  messageType_not_starts_with_i?: Maybe<Scalars['String']>;
  messageType_ends_with_i?: Maybe<Scalars['String']>;
  messageType_not_ends_with_i?: Maybe<Scalars['String']>;
  messageType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  message_i?: Maybe<Scalars['String']>;
  message_not_i?: Maybe<Scalars['String']>;
  message_contains_i?: Maybe<Scalars['String']>;
  message_not_contains_i?: Maybe<Scalars['String']>;
  message_starts_with_i?: Maybe<Scalars['String']>;
  message_not_starts_with_i?: Maybe<Scalars['String']>;
  message_ends_with_i?: Maybe<Scalars['String']>;
  message_not_ends_with_i?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deepLink?: Maybe<Scalars['String']>;
  deepLink_not?: Maybe<Scalars['String']>;
  deepLink_contains?: Maybe<Scalars['String']>;
  deepLink_not_contains?: Maybe<Scalars['String']>;
  deepLink_starts_with?: Maybe<Scalars['String']>;
  deepLink_not_starts_with?: Maybe<Scalars['String']>;
  deepLink_ends_with?: Maybe<Scalars['String']>;
  deepLink_not_ends_with?: Maybe<Scalars['String']>;
  deepLink_i?: Maybe<Scalars['String']>;
  deepLink_not_i?: Maybe<Scalars['String']>;
  deepLink_contains_i?: Maybe<Scalars['String']>;
  deepLink_not_contains_i?: Maybe<Scalars['String']>;
  deepLink_starts_with_i?: Maybe<Scalars['String']>;
  deepLink_not_starts_with_i?: Maybe<Scalars['String']>;
  deepLink_ends_with_i?: Maybe<Scalars['String']>;
  deepLink_not_ends_with_i?: Maybe<Scalars['String']>;
  deepLink_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deepLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targets?: Maybe<Scalars['JSON']>;
  targets_not?: Maybe<Scalars['JSON']>;
  targets_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  targets_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  processingMeta?: Maybe<Scalars['JSON']>;
  processingMeta_not?: Maybe<Scalars['JSON']>;
  processingMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  processingMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MessageBatchHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MessageBatchHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MessageBatchHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MessageBatchHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MessageBatchHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageBatchHistoryRecordsCreateInput = {
  data?: Maybe<MessageBatchHistoryRecordCreateInput>;
};

export type MessageBatchHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageBatchHistoryRecordUpdateInput>;
};

export enum MessageBatchMessageTypeType {
  CustomContentMessage = 'CUSTOM_CONTENT_MESSAGE',
  MobileAppUpdateAvailableMessagePush = 'MOBILE_APP_UPDATE_AVAILABLE_MESSAGE_PUSH',
  CustomContentMessagePush = 'CUSTOM_CONTENT_MESSAGE_PUSH',
  CustomContentMessageEmail = 'CUSTOM_CONTENT_MESSAGE_EMAIL',
  CustomContentMessageSms = 'CUSTOM_CONTENT_MESSAGE_SMS'
}

export enum MessageBatchStatusType {
  Created = 'created',
  Processing = 'processing',
  Failed = 'failed',
  Done = 'done'
}

export type MessageBatchUpdateInput = {
  messageType?: Maybe<MessageBatchMessageTypeType>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  deepLink?: Maybe<Scalars['String']>;
  targets?: Maybe<Scalars['JSON']>;
  status?: Maybe<MessageBatchStatusType>;
  processingMeta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MessageBatchWhereInput = {
  AND?: Maybe<Array<Maybe<MessageBatchWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageBatchWhereInput>>>;
  messageType?: Maybe<MessageBatchMessageTypeType>;
  messageType_not?: Maybe<MessageBatchMessageTypeType>;
  messageType_in?: Maybe<Array<Maybe<MessageBatchMessageTypeType>>>;
  messageType_not_in?: Maybe<Array<Maybe<MessageBatchMessageTypeType>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  message_not?: Maybe<Scalars['String']>;
  message_contains?: Maybe<Scalars['String']>;
  message_not_contains?: Maybe<Scalars['String']>;
  message_starts_with?: Maybe<Scalars['String']>;
  message_not_starts_with?: Maybe<Scalars['String']>;
  message_ends_with?: Maybe<Scalars['String']>;
  message_not_ends_with?: Maybe<Scalars['String']>;
  message_i?: Maybe<Scalars['String']>;
  message_not_i?: Maybe<Scalars['String']>;
  message_contains_i?: Maybe<Scalars['String']>;
  message_not_contains_i?: Maybe<Scalars['String']>;
  message_starts_with_i?: Maybe<Scalars['String']>;
  message_not_starts_with_i?: Maybe<Scalars['String']>;
  message_ends_with_i?: Maybe<Scalars['String']>;
  message_not_ends_with_i?: Maybe<Scalars['String']>;
  message_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  message_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deepLink?: Maybe<Scalars['String']>;
  deepLink_not?: Maybe<Scalars['String']>;
  deepLink_contains?: Maybe<Scalars['String']>;
  deepLink_not_contains?: Maybe<Scalars['String']>;
  deepLink_starts_with?: Maybe<Scalars['String']>;
  deepLink_not_starts_with?: Maybe<Scalars['String']>;
  deepLink_ends_with?: Maybe<Scalars['String']>;
  deepLink_not_ends_with?: Maybe<Scalars['String']>;
  deepLink_i?: Maybe<Scalars['String']>;
  deepLink_not_i?: Maybe<Scalars['String']>;
  deepLink_contains_i?: Maybe<Scalars['String']>;
  deepLink_not_contains_i?: Maybe<Scalars['String']>;
  deepLink_starts_with_i?: Maybe<Scalars['String']>;
  deepLink_not_starts_with_i?: Maybe<Scalars['String']>;
  deepLink_ends_with_i?: Maybe<Scalars['String']>;
  deepLink_not_ends_with_i?: Maybe<Scalars['String']>;
  deepLink_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deepLink_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  targets?: Maybe<Scalars['JSON']>;
  targets_not?: Maybe<Scalars['JSON']>;
  targets_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  targets_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<MessageBatchStatusType>;
  status_not?: Maybe<MessageBatchStatusType>;
  status_in?: Maybe<Array<Maybe<MessageBatchStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<MessageBatchStatusType>>>;
  processingMeta?: Maybe<Scalars['JSON']>;
  processingMeta_not?: Maybe<Scalars['JSON']>;
  processingMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  processingMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MessageBatchWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageBatchesCreateInput = {
  data?: Maybe<MessageBatchCreateInput>;
};

export type MessageBatchesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageBatchUpdateInput>;
};

export type MessageCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  remoteClient?: Maybe<RemoteClientRelateToOneInput>;
  emailFrom?: Maybe<Scalars['String']>;
  lang?: Maybe<MessageLangType>;
  type?: Maybe<MessageType>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<MessageStatusType>;
  processingMeta?: Maybe<Scalars['JSON']>;
  deliveredAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
  uniqKey?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MessageHistoryRecord = {
  __typename?: 'MessageHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  remoteClient?: Maybe<Scalars['String']>;
  emailFrom?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  processingMeta?: Maybe<Scalars['JSON']>;
  deliveredAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
  uniqKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  remoteClient?: Maybe<Scalars['String']>;
  emailFrom?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  processingMeta?: Maybe<Scalars['JSON']>;
  deliveredAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
  uniqKey?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MessageHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MessageHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  remoteClient?: Maybe<Scalars['String']>;
  emailFrom?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  processingMeta?: Maybe<Scalars['JSON']>;
  deliveredAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
  uniqKey?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MessageHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  remoteClient?: Maybe<Scalars['String']>;
  remoteClient_not?: Maybe<Scalars['String']>;
  remoteClient_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  remoteClient_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailFrom?: Maybe<Scalars['String']>;
  emailFrom_not?: Maybe<Scalars['String']>;
  emailFrom_contains?: Maybe<Scalars['String']>;
  emailFrom_not_contains?: Maybe<Scalars['String']>;
  emailFrom_starts_with?: Maybe<Scalars['String']>;
  emailFrom_not_starts_with?: Maybe<Scalars['String']>;
  emailFrom_ends_with?: Maybe<Scalars['String']>;
  emailFrom_not_ends_with?: Maybe<Scalars['String']>;
  emailFrom_i?: Maybe<Scalars['String']>;
  emailFrom_not_i?: Maybe<Scalars['String']>;
  emailFrom_contains_i?: Maybe<Scalars['String']>;
  emailFrom_not_contains_i?: Maybe<Scalars['String']>;
  emailFrom_starts_with_i?: Maybe<Scalars['String']>;
  emailFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  emailFrom_ends_with_i?: Maybe<Scalars['String']>;
  emailFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  emailFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
  lang_not?: Maybe<Scalars['String']>;
  lang_contains?: Maybe<Scalars['String']>;
  lang_not_contains?: Maybe<Scalars['String']>;
  lang_starts_with?: Maybe<Scalars['String']>;
  lang_not_starts_with?: Maybe<Scalars['String']>;
  lang_ends_with?: Maybe<Scalars['String']>;
  lang_not_ends_with?: Maybe<Scalars['String']>;
  lang_i?: Maybe<Scalars['String']>;
  lang_not_i?: Maybe<Scalars['String']>;
  lang_contains_i?: Maybe<Scalars['String']>;
  lang_not_contains_i?: Maybe<Scalars['String']>;
  lang_starts_with_i?: Maybe<Scalars['String']>;
  lang_not_starts_with_i?: Maybe<Scalars['String']>;
  lang_ends_with_i?: Maybe<Scalars['String']>;
  lang_not_ends_with_i?: Maybe<Scalars['String']>;
  lang_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  processingMeta?: Maybe<Scalars['JSON']>;
  processingMeta_not?: Maybe<Scalars['JSON']>;
  processingMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  processingMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  deliveredAt?: Maybe<Scalars['String']>;
  deliveredAt_not?: Maybe<Scalars['String']>;
  deliveredAt_lt?: Maybe<Scalars['String']>;
  deliveredAt_lte?: Maybe<Scalars['String']>;
  deliveredAt_gt?: Maybe<Scalars['String']>;
  deliveredAt_gte?: Maybe<Scalars['String']>;
  deliveredAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deliveredAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt?: Maybe<Scalars['String']>;
  sentAt_not?: Maybe<Scalars['String']>;
  sentAt_lt?: Maybe<Scalars['String']>;
  sentAt_lte?: Maybe<Scalars['String']>;
  sentAt_gt?: Maybe<Scalars['String']>;
  sentAt_gte?: Maybe<Scalars['String']>;
  sentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readAt?: Maybe<Scalars['String']>;
  readAt_not?: Maybe<Scalars['String']>;
  readAt_lt?: Maybe<Scalars['String']>;
  readAt_lte?: Maybe<Scalars['String']>;
  readAt_gt?: Maybe<Scalars['String']>;
  readAt_gte?: Maybe<Scalars['String']>;
  readAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uniqKey?: Maybe<Scalars['String']>;
  uniqKey_not?: Maybe<Scalars['String']>;
  uniqKey_contains?: Maybe<Scalars['String']>;
  uniqKey_not_contains?: Maybe<Scalars['String']>;
  uniqKey_starts_with?: Maybe<Scalars['String']>;
  uniqKey_not_starts_with?: Maybe<Scalars['String']>;
  uniqKey_ends_with?: Maybe<Scalars['String']>;
  uniqKey_not_ends_with?: Maybe<Scalars['String']>;
  uniqKey_i?: Maybe<Scalars['String']>;
  uniqKey_not_i?: Maybe<Scalars['String']>;
  uniqKey_contains_i?: Maybe<Scalars['String']>;
  uniqKey_not_contains_i?: Maybe<Scalars['String']>;
  uniqKey_starts_with_i?: Maybe<Scalars['String']>;
  uniqKey_not_starts_with_i?: Maybe<Scalars['String']>;
  uniqKey_ends_with_i?: Maybe<Scalars['String']>;
  uniqKey_not_ends_with_i?: Maybe<Scalars['String']>;
  uniqKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uniqKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MessageHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MessageHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MessageHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MessageHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MessageHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageHistoryRecordsCreateInput = {
  data?: Maybe<MessageHistoryRecordCreateInput>;
};

export type MessageHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageHistoryRecordUpdateInput>;
};

export enum MessageLangType {
  Ru = 'ru',
  En = 'en'
}

/**  Rule for blocking specific type of messages for organization  */
export type MessageOrganizationBlackList = {
  __typename?: 'MessageOrganizationBlackList';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageOrganizationBlackList List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageOrganizationBlackList List config, or
   *  3. As an alias to a 'name' field on the MessageOrganizationBlackList List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageOrganizationBlackList List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The organization we want to block from sending messages to (null - all organizations)  */
  organization?: Maybe<Organization>;
  /**  The type of message we want to block  */
  type?: Maybe<MessageType>;
  /**  The reason why the entry was added to the MessageOrganizationBlackList  */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MessageOrganizationBlackListCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<MessageType>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MessageOrganizationBlackListHistoryRecord = {
  __typename?: 'MessageOrganizationBlackListHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageOrganizationBlackListHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageOrganizationBlackListHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageOrganizationBlackListHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageOrganizationBlackListHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageOrganizationBlackListHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MessageOrganizationBlackListHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MessageOrganizationBlackListHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageOrganizationBlackListHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MessageOrganizationBlackListHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageOrganizationBlackListHistoryRecordsCreateInput = {
  data?: Maybe<MessageOrganizationBlackListHistoryRecordCreateInput>;
};

export type MessageOrganizationBlackListHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageOrganizationBlackListHistoryRecordUpdateInput>;
};

export type MessageOrganizationBlackListUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<MessageType>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MessageOrganizationBlackListWhereInput = {
  AND?: Maybe<Array<Maybe<MessageOrganizationBlackListWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageOrganizationBlackListWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
  type_not?: Maybe<MessageType>;
  type_in?: Maybe<Array<Maybe<MessageType>>>;
  type_not_in?: Maybe<Array<Maybe<MessageType>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MessageOrganizationBlackListWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageOrganizationBlackListsCreateInput = {
  data?: Maybe<MessageOrganizationBlackListCreateInput>;
};

export type MessageOrganizationBlackListsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageOrganizationBlackListUpdateInput>;
};

export enum MessageStatusType {
  Sending = 'sending',
  Resending = 'resending',
  Processing = 'processing',
  Error = 'error',
  Blacklisted = 'blacklisted',
  Sent = 'sent',
  Delivered = 'delivered',
  Read = 'read',
  Canceled = 'canceled',
  DisabledByUser = 'disabledByUser',
  Throttled = 'throttled'
}

export enum MessageType {
  TicketCreated = 'TICKET_CREATED',
  TicketCommentCreated = 'TICKET_COMMENT_CREATED',
  InviteNewEmployee = 'INVITE_NEW_EMPLOYEE',
  ShareTicket = 'SHARE_TICKET',
  BankAccountCreationRequest = 'BANK_ACCOUNT_CREATION_REQUEST',
  DirtyInviteNewEmployeeSms = 'DIRTY_INVITE_NEW_EMPLOYEE_SMS',
  DirtyInviteNewEmployeeEmail = 'DIRTY_INVITE_NEW_EMPLOYEE_EMAIL',
  RegisterNewUser = 'REGISTER_NEW_USER',
  SmsVerify = 'SMS_VERIFY',
  DeveloperImportantNoteType = 'DEVELOPER_IMPORTANT_NOTE_TYPE',
  CustomerImportantNoteType = 'CUSTOMER_IMPORTANT_NOTE_TYPE',
  MessageForwardedToSupport = 'MESSAGE_FORWARDED_TO_SUPPORT',
  TicketAssigneeConnected = 'TICKET_ASSIGNEE_CONNECTED',
  TicketExecutorConnected = 'TICKET_EXECUTOR_CONNECTED',
  TrackTicketInDomaApp = 'TRACK_TICKET_IN_DOMA_APP',
  TicketStatusOpened = 'TICKET_STATUS_OPENED',
  TicketStatusInProgress = 'TICKET_STATUS_IN_PROGRESS',
  TicketStatusCompleted = 'TICKET_STATUS_COMPLETED',
  TicketStatusReturned = 'TICKET_STATUS_RETURNED',
  TicketStatusDeclined = 'TICKET_STATUS_DECLINED',
  TicketCommentAdded = 'TICKET_COMMENT_ADDED',
  MeterVerificationDateReminder = 'METER_VERIFICATION_DATE_REMINDER',
  ResidentAddBillingAccount = 'RESIDENT_ADD_BILLING_ACCOUNT',
  BillingReceiptAvailable = 'BILLING_RECEIPT_AVAILABLE',
  BillingReceiptAvailableNoAccount = 'BILLING_RECEIPT_AVAILABLE_NO_ACCOUNT',
  BillingReceiptCategoryAvailable = 'BILLING_RECEIPT_CATEGORY_AVAILABLE',
  BillingReceiptAdded = 'BILLING_RECEIPT_ADDED',
  BillingReceiptFileAdded = 'BILLING_RECEIPT_FILE_ADDED',
  BillingReceiptAddedWithDebt = 'BILLING_RECEIPT_ADDED_WITH_DEBT',
  BillingReceiptAddedWithNoDebt = 'BILLING_RECEIPT_ADDED_WITH_NO_DEBT',
  MeterSubmitReadingsReminder = 'METER_SUBMIT_READINGS_REMINDER',
  MeterSubmitReadingsReminderStartPeriod = 'METER_SUBMIT_READINGS_REMINDER_START_PERIOD',
  MeterSubmitReadingsReminderEndPeriod = 'METER_SUBMIT_READINGS_REMINDER_END_PERIOD',
  MeterVerificationDateExpired = 'METER_VERIFICATION_DATE_EXPIRED',
  ResidentUpgradeApp = 'RESIDENT_UPGRADE_APP',
  StaffUpgradeApp = 'STAFF_UPGRADE_APP',
  CustomContentMessagePush = 'CUSTOM_CONTENT_MESSAGE_PUSH',
  MobileAppUpdateAvailableMessagePush = 'MOBILE_APP_UPDATE_AVAILABLE_MESSAGE_PUSH',
  CustomContentMessageEmail = 'CUSTOM_CONTENT_MESSAGE_EMAIL',
  CustomContentMessageSms = 'CUSTOM_CONTENT_MESSAGE_SMS',
  VoipIncomingCallMessage = 'VOIP_INCOMING_CALL_MESSAGE',
  B2CAppMessagePush = 'B2C_APP_MESSAGE_PUSH',
  RecurrentPaymentProceedingSuccessResultMessage = 'RECURRENT_PAYMENT_PROCEEDING_SUCCESS_RESULT_MESSAGE',
  RecurrentPaymentProceedingUnknownErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_UNKNOWN_ERROR_MESSAGE',
  RecurrentPaymentProceedingAcquiringPaymentProceedErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_ACQUIRING_PAYMENT_PROCEED_ERROR_MESSAGE',
  RecurrentPaymentProceedingServiceConsumerNotFoundErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_SERVICE_CONSUMER_NOT_FOUND_ERROR_MESSAGE',
  RecurrentPaymentProceedingLimitExceededErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_LIMIT_EXCEEDED_ERROR_MESSAGE',
  RecurrentPaymentProceedingContextNotFoundErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CONTEXT_NOT_FOUND_ERROR_MESSAGE',
  RecurrentPaymentProceedingContextDisabledErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CONTEXT_DISABLED_ERROR_MESSAGE',
  RecurrentPaymentProceedingCardTokenNotValidErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CARD_TOKEN_NOT_VALID_ERROR_MESSAGE',
  RecurrentPaymentProceedingCanNotRegisterMultiPaymentErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_CAN_NOT_REGISTER_MULTI_PAYMENT_ERROR_MESSAGE',
  RecurrentPaymentProceedingNoReceiptsToProceedErrorMessage = 'RECURRENT_PAYMENT_PROCEEDING_NO_RECEIPTS_TO_PROCEED_ERROR_MESSAGE',
  RecurrentPaymentTomorrowPaymentMessage = 'RECURRENT_PAYMENT_TOMORROW_PAYMENT_MESSAGE',
  RecurrentPaymentTomorrowPaymentNoReceiptsMessage = 'RECURRENT_PAYMENT_TOMORROW_PAYMENT_NO_RECEIPTS_MESSAGE',
  RecurrentPaymentTomorrowPaymentLimitExceedMessage = 'RECURRENT_PAYMENT_TOMORROW_PAYMENT_LIMIT_EXCEED_MESSAGE',
  NewsItemCommonMessageType = 'NEWS_ITEM_COMMON_MESSAGE_TYPE',
  NewsItemEmergencyMessageType = 'NEWS_ITEM_EMERGENCY_MESSAGE_TYPE',
  DevPortalMessage = 'DEV_PORTAL_MESSAGE',
  SendBillingReceiptsOnPaydayReminderMessage = 'SEND_BILLING_RECEIPTS_ON_PAYDAY_REMINDER_MESSAGE',
  MarketplaceInvoicePublishedMessage = 'MARKETPLACE_INVOICE_PUBLISHED_MESSAGE',
  MarketplaceInvoiceWithTicketPublishedMessage = 'MARKETPLACE_INVOICE_WITH_TICKET_PUBLISHED_MESSAGE',
  MarketplaceInvoiceCashPublishedMessage = 'MARKETPLACE_INVOICE_CASH_PUBLISHED_MESSAGE',
  MarketplaceInvoiceCashWithTicketPublishedMessage = 'MARKETPLACE_INVOICE_CASH_WITH_TICKET_PUBLISHED_MESSAGE',
  ServiceUserCreated = 'SERVICE_USER_CREATED',
  SendDailyStatistics = 'SEND_DAILY_STATISTICS'
}

export type MessageUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  remoteClient?: Maybe<RemoteClientRelateToOneInput>;
  emailFrom?: Maybe<Scalars['String']>;
  lang?: Maybe<MessageLangType>;
  type?: Maybe<MessageType>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<MessageStatusType>;
  processingMeta?: Maybe<Scalars['JSON']>;
  deliveredAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
  uniqKey?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  Rule for blocking messages (specific type or all) for user, phone or email  */
export type MessageUserBlackList = {
  __typename?: 'MessageUserBlackList';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageUserBlackList List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageUserBlackList List config, or
   *  3. As an alias to a 'name' field on the MessageUserBlackList List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageUserBlackList List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The user to whom we want to block sending messages  */
  user?: Maybe<User>;
  /**  The phone number to which we want to block sending messages  */
  phone?: Maybe<Scalars['String']>;
  /**  Email to which we want to block the sending of messages  */
  email?: Maybe<Scalars['String']>;
  /**  The type of message we want to block (null means all types)  */
  type?: Maybe<MessageType>;
  /**  The reason why the entry was added to the MessageUserBlackList  */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MessageUserBlackListCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MessageUserBlackListHistoryRecord = {
  __typename?: 'MessageUserBlackListHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MessageUserBlackListHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MessageUserBlackListHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MessageUserBlackListHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MessageUserBlackListHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageUserBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageUserBlackListHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageUserBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MessageUserBlackListHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MessageUserBlackListHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MessageUserBlackListHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MessageUserBlackListHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MessageUserBlackListHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MessageUserBlackListHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MessageUserBlackListHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageUserBlackListHistoryRecordsCreateInput = {
  data?: Maybe<MessageUserBlackListHistoryRecordCreateInput>;
};

export type MessageUserBlackListHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageUserBlackListHistoryRecordUpdateInput>;
};

export type MessageUserBlackListUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<MessageType>;
  description?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MessageUserBlackListWhereInput = {
  AND?: Maybe<Array<Maybe<MessageUserBlackListWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageUserBlackListWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<MessageType>;
  type_not?: Maybe<MessageType>;
  type_in?: Maybe<Array<Maybe<MessageType>>>;
  type_not_in?: Maybe<Array<Maybe<MessageType>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MessageUserBlackListWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessageUserBlackListsCreateInput = {
  data?: Maybe<MessageUserBlackListCreateInput>;
};

export type MessageUserBlackListsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageUserBlackListUpdateInput>;
};

export type MessageWhereInput = {
  AND?: Maybe<Array<Maybe<MessageWhereInput>>>;
  OR?: Maybe<Array<Maybe<MessageWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  remoteClient?: Maybe<RemoteClientWhereInput>;
  remoteClient_is_null?: Maybe<Scalars['Boolean']>;
  emailFrom?: Maybe<Scalars['String']>;
  emailFrom_not?: Maybe<Scalars['String']>;
  emailFrom_contains?: Maybe<Scalars['String']>;
  emailFrom_not_contains?: Maybe<Scalars['String']>;
  emailFrom_starts_with?: Maybe<Scalars['String']>;
  emailFrom_not_starts_with?: Maybe<Scalars['String']>;
  emailFrom_ends_with?: Maybe<Scalars['String']>;
  emailFrom_not_ends_with?: Maybe<Scalars['String']>;
  emailFrom_i?: Maybe<Scalars['String']>;
  emailFrom_not_i?: Maybe<Scalars['String']>;
  emailFrom_contains_i?: Maybe<Scalars['String']>;
  emailFrom_not_contains_i?: Maybe<Scalars['String']>;
  emailFrom_starts_with_i?: Maybe<Scalars['String']>;
  emailFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  emailFrom_ends_with_i?: Maybe<Scalars['String']>;
  emailFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  emailFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<MessageLangType>;
  lang_not?: Maybe<MessageLangType>;
  lang_in?: Maybe<Array<Maybe<MessageLangType>>>;
  lang_not_in?: Maybe<Array<Maybe<MessageLangType>>>;
  type?: Maybe<MessageType>;
  type_not?: Maybe<MessageType>;
  type_in?: Maybe<Array<Maybe<MessageType>>>;
  type_not_in?: Maybe<Array<Maybe<MessageType>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<MessageStatusType>;
  status_not?: Maybe<MessageStatusType>;
  status_in?: Maybe<Array<Maybe<MessageStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<MessageStatusType>>>;
  processingMeta?: Maybe<Scalars['JSON']>;
  processingMeta_not?: Maybe<Scalars['JSON']>;
  processingMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  processingMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  deliveredAt?: Maybe<Scalars['String']>;
  deliveredAt_not?: Maybe<Scalars['String']>;
  deliveredAt_lt?: Maybe<Scalars['String']>;
  deliveredAt_lte?: Maybe<Scalars['String']>;
  deliveredAt_gt?: Maybe<Scalars['String']>;
  deliveredAt_gte?: Maybe<Scalars['String']>;
  deliveredAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deliveredAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt?: Maybe<Scalars['String']>;
  sentAt_not?: Maybe<Scalars['String']>;
  sentAt_lt?: Maybe<Scalars['String']>;
  sentAt_lte?: Maybe<Scalars['String']>;
  sentAt_gt?: Maybe<Scalars['String']>;
  sentAt_gte?: Maybe<Scalars['String']>;
  sentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readAt?: Maybe<Scalars['String']>;
  readAt_not?: Maybe<Scalars['String']>;
  readAt_lt?: Maybe<Scalars['String']>;
  readAt_lte?: Maybe<Scalars['String']>;
  readAt_gt?: Maybe<Scalars['String']>;
  readAt_gte?: Maybe<Scalars['String']>;
  readAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uniqKey?: Maybe<Scalars['String']>;
  uniqKey_not?: Maybe<Scalars['String']>;
  uniqKey_contains?: Maybe<Scalars['String']>;
  uniqKey_not_contains?: Maybe<Scalars['String']>;
  uniqKey_starts_with?: Maybe<Scalars['String']>;
  uniqKey_not_starts_with?: Maybe<Scalars['String']>;
  uniqKey_ends_with?: Maybe<Scalars['String']>;
  uniqKey_not_ends_with?: Maybe<Scalars['String']>;
  uniqKey_i?: Maybe<Scalars['String']>;
  uniqKey_not_i?: Maybe<Scalars['String']>;
  uniqKey_contains_i?: Maybe<Scalars['String']>;
  uniqKey_not_contains_i?: Maybe<Scalars['String']>;
  uniqKey_starts_with_i?: Maybe<Scalars['String']>;
  uniqKey_not_starts_with_i?: Maybe<Scalars['String']>;
  uniqKey_ends_with_i?: Maybe<Scalars['String']>;
  uniqKey_not_ends_with_i?: Maybe<Scalars['String']>;
  uniqKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  uniqKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MessageWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MessagesCreateInput = {
  data?: Maybe<MessageCreateInput>;
};

export type MessagesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MessageUpdateInput>;
};

/**  Resource meter at a certain place in the unitName  */
export type Meter = {
  __typename?: 'Meter';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Meter List config, or
   *  2. As an alias to the field set on 'labelField' in the Meter List config, or
   *  3. As an alias to a 'name' field on the Meter List (if one exists), or
   *  4. As an alias to the 'id' field on the Meter List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  /**  Date when the meter was installed  */
  installationDate?: Maybe<Scalars['String']>;
  /**  Date when the meter was commissioned.Commissioning - documentation of the meter as a billing meter  */
  commissioningDate?: Maybe<Scalars['String']>;
  /**  The date when the employee came and checked how accurately the meter counts the resource  */
  verificationDate?: Maybe<Scalars['String']>;
  /**  The date of the next meter verification.For example, for a cold water meter - usually 6 years after the verification date  */
  nextVerificationDate?: Maybe<Scalars['String']>;
  /**  The date when the employee came and took readings from the meter  */
  controlReadingsDate?: Maybe<Scalars['String']>;
  /**  The date when meter was sealed.Sealing is the installation of a unique single-use device (directly a seal and a sealing rope)on the metering device, which is designed to control unauthorized access to the equipment.  */
  sealingDate?: Maybe<Scalars['String']>;
  /**  The date when the meter was disabled and no longer in use  */
  archiveDate?: Maybe<Scalars['String']>;
  /**  Determines, if Meter is automatic or not. False by default. If set to True - prevents user with type "resident" from creating MeterReading.So MeterReadings only be acquired through external integration or adjusted by organization employee  */
  isAutomatic?: Maybe<Scalars['Boolean']>;
  /**  Meter resource, such as hot water or electricity  */
  resource?: Maybe<MeterResource>;
  /**  Ref to B2BApp, which is used as a master system for this meter. Specified organization must connect this app.  */
  b2bApp?: Maybe<B2BApp>;
  /**  Link to property which contains unit with this meter  */
  property?: Maybe<Property>;
  /**  Number of resource meter, such as "А03 9908"  */
  number?: Maybe<Scalars['String']>;
  /**  Client's billing account  */
  accountNumber?: Maybe<Scalars['String']>;
  /**  Unit with this meter  */
  unitName?: Maybe<Scalars['String']>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitType?: Maybe<MeterUnitTypeType>;
  /**  Certain place in unit where meter is, such as kitchen  */
  place?: Maybe<Scalars['String']>;
  /**  Meter metadata. Can be used to store additional settings from external sources, such as billing integrations or mini apps  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Ref to the B2CApp which used to replace default integration with meter by resident's user in resident's app  */
  b2cApp?: Maybe<B2CApp>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceRelateToOneInput>;
  b2bApp?: Maybe<B2BAppRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  number?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<MeterUnitTypeType>;
  place?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  b2cApp?: Maybe<B2CAppRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MeterHistoryRecord = {
  __typename?: 'MeterHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  b2bApp?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  b2cApp?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  b2bApp?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  b2cApp?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  b2bApp?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  b2cApp?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  numberOfTariffs_not?: Maybe<Scalars['Int']>;
  numberOfTariffs_lt?: Maybe<Scalars['Int']>;
  numberOfTariffs_lte?: Maybe<Scalars['Int']>;
  numberOfTariffs_gt?: Maybe<Scalars['Int']>;
  numberOfTariffs_gte?: Maybe<Scalars['Int']>;
  numberOfTariffs_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfTariffs_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  installationDate?: Maybe<Scalars['String']>;
  installationDate_not?: Maybe<Scalars['String']>;
  installationDate_lt?: Maybe<Scalars['String']>;
  installationDate_lte?: Maybe<Scalars['String']>;
  installationDate_gt?: Maybe<Scalars['String']>;
  installationDate_gte?: Maybe<Scalars['String']>;
  installationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  installationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate?: Maybe<Scalars['String']>;
  commissioningDate_not?: Maybe<Scalars['String']>;
  commissioningDate_lt?: Maybe<Scalars['String']>;
  commissioningDate_lte?: Maybe<Scalars['String']>;
  commissioningDate_gt?: Maybe<Scalars['String']>;
  commissioningDate_gte?: Maybe<Scalars['String']>;
  commissioningDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate?: Maybe<Scalars['String']>;
  verificationDate_not?: Maybe<Scalars['String']>;
  verificationDate_lt?: Maybe<Scalars['String']>;
  verificationDate_lte?: Maybe<Scalars['String']>;
  verificationDate_gt?: Maybe<Scalars['String']>;
  verificationDate_gte?: Maybe<Scalars['String']>;
  verificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate_not?: Maybe<Scalars['String']>;
  nextVerificationDate_lt?: Maybe<Scalars['String']>;
  nextVerificationDate_lte?: Maybe<Scalars['String']>;
  nextVerificationDate_gt?: Maybe<Scalars['String']>;
  nextVerificationDate_gte?: Maybe<Scalars['String']>;
  nextVerificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  controlReadingsDate_not?: Maybe<Scalars['String']>;
  controlReadingsDate_lt?: Maybe<Scalars['String']>;
  controlReadingsDate_lte?: Maybe<Scalars['String']>;
  controlReadingsDate_gt?: Maybe<Scalars['String']>;
  controlReadingsDate_gte?: Maybe<Scalars['String']>;
  controlReadingsDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate?: Maybe<Scalars['String']>;
  sealingDate_not?: Maybe<Scalars['String']>;
  sealingDate_lt?: Maybe<Scalars['String']>;
  sealingDate_lte?: Maybe<Scalars['String']>;
  sealingDate_gt?: Maybe<Scalars['String']>;
  sealingDate_gte?: Maybe<Scalars['String']>;
  sealingDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate?: Maybe<Scalars['String']>;
  archiveDate_not?: Maybe<Scalars['String']>;
  archiveDate_lt?: Maybe<Scalars['String']>;
  archiveDate_lte?: Maybe<Scalars['String']>;
  archiveDate_gt?: Maybe<Scalars['String']>;
  archiveDate_gte?: Maybe<Scalars['String']>;
  archiveDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  isAutomatic_not?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  resource_not?: Maybe<Scalars['String']>;
  resource_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resource_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  b2bApp?: Maybe<Scalars['String']>;
  b2bApp_not?: Maybe<Scalars['String']>;
  b2bApp_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  b2bApp_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  place_i?: Maybe<Scalars['String']>;
  place_not_i?: Maybe<Scalars['String']>;
  place_contains_i?: Maybe<Scalars['String']>;
  place_not_contains_i?: Maybe<Scalars['String']>;
  place_starts_with_i?: Maybe<Scalars['String']>;
  place_not_starts_with_i?: Maybe<Scalars['String']>;
  place_ends_with_i?: Maybe<Scalars['String']>;
  place_not_ends_with_i?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  place_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  b2cApp?: Maybe<Scalars['String']>;
  b2cApp_not?: Maybe<Scalars['String']>;
  b2cApp_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  b2cApp_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterHistoryRecordsCreateInput = {
  data?: Maybe<MeterHistoryRecordCreateInput>;
};

export type MeterHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterHistoryRecordUpdateInput>;
};

/**  Meter reading taken from a client or billing  */
export type MeterReading = {
  __typename?: 'MeterReading';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReading List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReading List config, or
   *  3. As an alias to a 'name' field on the MeterReading List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReading List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Date when the readings were taken  */
  date?: Maybe<Scalars['String']>;
  /**  Meter from which readings were taken  */
  meter?: Maybe<Meter>;
  /**  If the meter is single-tariff, then only this value will be filled in;If multi-tariff, then the value of the first tariff will be in this field  */
  value1?: Maybe<Scalars['String']>;
  /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
  value2?: Maybe<Scalars['String']>;
  /**  If the meter is multi-tariff, then the value of the third tariff is stored here  */
  value3?: Maybe<Scalars['String']>;
  /**  If the meter is multi-tariff, then the value of the fourth tariff is stored here  */
  value4?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client.  */
  client?: Maybe<User>;
  /**  Contact, that reported issue, described in this ticket  */
  contact?: Maybe<Contact>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientName?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientEmail?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientPhone?: Maybe<Scalars['String']>;
  /**  Meter reading source channel/system. Examples: call, mobile_app, billing, ...  */
  source?: Maybe<MeterReadingSource>;
  /**  A status from external billing system. Changing during processing the reading in external system.  */
  billingStatus?: Maybe<MeterReadingBillingStatusType>;
  /**  A message from external billing system. Set to null if billing status is `approved`.  */
  billingStatusText?: Maybe<Scalars['String']>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export enum MeterReadingBillingStatusType {
  Approved = 'approved',
  Declined = 'declined'
}

export type MeterReadingCreateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<MeterRelateToOneInput>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  client?: Maybe<UserRelateToOneInput>;
  contact?: Maybe<ContactRelateToOneInput>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  source?: Maybe<MeterReadingSourceRelateToOneInput>;
  billingStatus?: Maybe<MeterReadingBillingStatusType>;
  billingStatusText?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  Employee specific meter reading filter template  */
export type MeterReadingFilterTemplate = {
  __typename?: 'MeterReadingFilterTemplate';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingFilterTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingFilterTemplate List config, or
   *  3. As an alias to a 'name' field on the MeterReadingFilterTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingFilterTemplate List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Meter reading filter template name  */
  name?: Maybe<Scalars['String']>;
  /**  Link to employee, who created this template  */
  employee?: Maybe<OrganizationEmployee>;
  /**  Filter that match the given template  */
  fields?: Maybe<MeterReadingFilters>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterReadingFilterTemplateCreateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MeterReadingFilterTemplateHistoryRecord = {
  __typename?: 'MeterReadingFilterTemplateHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingFilterTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingFilterTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingFilterTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingFilterTemplateHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingFilterTemplateHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterReadingFilterTemplateHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterReadingFilterTemplateHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingFilterTemplateHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Scalars['String']>;
  employee_not?: Maybe<Scalars['String']>;
  employee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<Scalars['JSON']>;
  fields_not?: Maybe<Scalars['JSON']>;
  fields_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterReadingFilterTemplateHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingFilterTemplateHistoryRecordsCreateInput = {
  data?: Maybe<MeterReadingFilterTemplateHistoryRecordCreateInput>;
};

export type MeterReadingFilterTemplateHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingFilterTemplateHistoryRecordUpdateInput>;
};

export type MeterReadingFilterTemplateUpdateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterReadingFilterTemplateWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingFilterTemplateWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingFilterTemplateWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<OrganizationEmployeeWhereInput>;
  employee_is_null?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Scalars['JSON']>;
  fields_not?: Maybe<Scalars['JSON']>;
  fields_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterReadingFilterTemplateWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingFilterTemplatesCreateInput = {
  data?: Maybe<MeterReadingFilterTemplateCreateInput>;
};

export type MeterReadingFilterTemplatesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingFilterTemplateUpdateInput>;
};

export type MeterReadingFilters = {
  __typename?: 'MeterReadingFilters';
  organization?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  unitName?: Maybe<Array<Maybe<Scalars['String']>>>;
  resource?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  date?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  installationDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**  A keystone list  */
export type MeterReadingHistoryRecord = {
  __typename?: 'MeterReadingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<Scalars['String']>;
  billingStatusText?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingHistoryRecordCreateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<Scalars['String']>;
  billingStatusText?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterReadingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterReadingHistoryRecordUpdateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  billingStatus?: Maybe<Scalars['String']>;
  billingStatusText?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingHistoryRecordWhereInput>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meter?: Maybe<Scalars['String']>;
  meter_not?: Maybe<Scalars['String']>;
  meter_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meter_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value1?: Maybe<Scalars['String']>;
  value1_not?: Maybe<Scalars['String']>;
  value1_lt?: Maybe<Scalars['String']>;
  value1_lte?: Maybe<Scalars['String']>;
  value1_gt?: Maybe<Scalars['String']>;
  value1_gte?: Maybe<Scalars['String']>;
  value1_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2?: Maybe<Scalars['String']>;
  value2_not?: Maybe<Scalars['String']>;
  value2_lt?: Maybe<Scalars['String']>;
  value2_lte?: Maybe<Scalars['String']>;
  value2_gt?: Maybe<Scalars['String']>;
  value2_gte?: Maybe<Scalars['String']>;
  value2_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3?: Maybe<Scalars['String']>;
  value3_not?: Maybe<Scalars['String']>;
  value3_lt?: Maybe<Scalars['String']>;
  value3_lte?: Maybe<Scalars['String']>;
  value3_gt?: Maybe<Scalars['String']>;
  value3_gte?: Maybe<Scalars['String']>;
  value3_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4?: Maybe<Scalars['String']>;
  value4_not?: Maybe<Scalars['String']>;
  value4_lt?: Maybe<Scalars['String']>;
  value4_lte?: Maybe<Scalars['String']>;
  value4_gt?: Maybe<Scalars['String']>;
  value4_gte?: Maybe<Scalars['String']>;
  value4_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  client?: Maybe<Scalars['String']>;
  client_not?: Maybe<Scalars['String']>;
  client_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact?: Maybe<Scalars['String']>;
  contact_not?: Maybe<Scalars['String']>;
  contact_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName?: Maybe<Scalars['String']>;
  clientName_not?: Maybe<Scalars['String']>;
  clientName_contains?: Maybe<Scalars['String']>;
  clientName_not_contains?: Maybe<Scalars['String']>;
  clientName_starts_with?: Maybe<Scalars['String']>;
  clientName_not_starts_with?: Maybe<Scalars['String']>;
  clientName_ends_with?: Maybe<Scalars['String']>;
  clientName_not_ends_with?: Maybe<Scalars['String']>;
  clientName_i?: Maybe<Scalars['String']>;
  clientName_not_i?: Maybe<Scalars['String']>;
  clientName_contains_i?: Maybe<Scalars['String']>;
  clientName_not_contains_i?: Maybe<Scalars['String']>;
  clientName_starts_with_i?: Maybe<Scalars['String']>;
  clientName_not_starts_with_i?: Maybe<Scalars['String']>;
  clientName_ends_with_i?: Maybe<Scalars['String']>;
  clientName_not_ends_with_i?: Maybe<Scalars['String']>;
  clientName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail?: Maybe<Scalars['String']>;
  clientEmail_not?: Maybe<Scalars['String']>;
  clientEmail_contains?: Maybe<Scalars['String']>;
  clientEmail_not_contains?: Maybe<Scalars['String']>;
  clientEmail_starts_with?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with?: Maybe<Scalars['String']>;
  clientEmail_ends_with?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with?: Maybe<Scalars['String']>;
  clientEmail_i?: Maybe<Scalars['String']>;
  clientEmail_not_i?: Maybe<Scalars['String']>;
  clientEmail_contains_i?: Maybe<Scalars['String']>;
  clientEmail_not_contains_i?: Maybe<Scalars['String']>;
  clientEmail_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Scalars['String']>;
  clientPhone_not?: Maybe<Scalars['String']>;
  clientPhone_contains?: Maybe<Scalars['String']>;
  clientPhone_not_contains?: Maybe<Scalars['String']>;
  clientPhone_starts_with?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with?: Maybe<Scalars['String']>;
  clientPhone_ends_with?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with?: Maybe<Scalars['String']>;
  clientPhone_i?: Maybe<Scalars['String']>;
  clientPhone_not_i?: Maybe<Scalars['String']>;
  clientPhone_contains_i?: Maybe<Scalars['String']>;
  clientPhone_not_contains_i?: Maybe<Scalars['String']>;
  clientPhone_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Scalars['String']>;
  source_not?: Maybe<Scalars['String']>;
  source_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingStatus?: Maybe<Scalars['String']>;
  billingStatus_not?: Maybe<Scalars['String']>;
  billingStatus_contains?: Maybe<Scalars['String']>;
  billingStatus_not_contains?: Maybe<Scalars['String']>;
  billingStatus_starts_with?: Maybe<Scalars['String']>;
  billingStatus_not_starts_with?: Maybe<Scalars['String']>;
  billingStatus_ends_with?: Maybe<Scalars['String']>;
  billingStatus_not_ends_with?: Maybe<Scalars['String']>;
  billingStatus_i?: Maybe<Scalars['String']>;
  billingStatus_not_i?: Maybe<Scalars['String']>;
  billingStatus_contains_i?: Maybe<Scalars['String']>;
  billingStatus_not_contains_i?: Maybe<Scalars['String']>;
  billingStatus_starts_with_i?: Maybe<Scalars['String']>;
  billingStatus_not_starts_with_i?: Maybe<Scalars['String']>;
  billingStatus_ends_with_i?: Maybe<Scalars['String']>;
  billingStatus_not_ends_with_i?: Maybe<Scalars['String']>;
  billingStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingStatusText?: Maybe<Scalars['String']>;
  billingStatusText_not?: Maybe<Scalars['String']>;
  billingStatusText_contains?: Maybe<Scalars['String']>;
  billingStatusText_not_contains?: Maybe<Scalars['String']>;
  billingStatusText_starts_with?: Maybe<Scalars['String']>;
  billingStatusText_not_starts_with?: Maybe<Scalars['String']>;
  billingStatusText_ends_with?: Maybe<Scalars['String']>;
  billingStatusText_not_ends_with?: Maybe<Scalars['String']>;
  billingStatusText_i?: Maybe<Scalars['String']>;
  billingStatusText_not_i?: Maybe<Scalars['String']>;
  billingStatusText_contains_i?: Maybe<Scalars['String']>;
  billingStatusText_not_contains_i?: Maybe<Scalars['String']>;
  billingStatusText_starts_with_i?: Maybe<Scalars['String']>;
  billingStatusText_not_starts_with_i?: Maybe<Scalars['String']>;
  billingStatusText_ends_with_i?: Maybe<Scalars['String']>;
  billingStatusText_not_ends_with_i?: Maybe<Scalars['String']>;
  billingStatusText_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingStatusText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterReadingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterReadingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterReadingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterReadingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterReadingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingHistoryRecordsCreateInput = {
  data?: Maybe<MeterReadingHistoryRecordCreateInput>;
};

export type MeterReadingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingHistoryRecordUpdateInput>;
};

/**  Ticket source. Income call, mobile_app, ...  */
export type MeterReadingSource = {
  __typename?: 'MeterReadingSource';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingSource List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingSource List config, or
   *  3. As an alias to a 'name' field on the MeterReadingSource List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingSource List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<MeterReadingSourceTypeType>;
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterReadingSourceCreateInput = {
  type?: Maybe<MeterReadingSourceTypeType>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MeterReadingSourceHistoryRecord = {
  __typename?: 'MeterReadingSourceHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingSourceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingSourceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingSourceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingSourceHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingSourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingSourceHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingSourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterReadingSourceHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterReadingSourceHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingSourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingSourceHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterReadingSourceHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterReadingSourceHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterReadingSourceHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingSourceHistoryRecordsCreateInput = {
  data?: Maybe<MeterReadingSourceHistoryRecordCreateInput>;
};

export type MeterReadingSourceHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingSourceHistoryRecordUpdateInput>;
};

export type MeterReadingSourceRelateToOneInput = {
  create?: Maybe<MeterReadingSourceCreateInput>;
  connect?: Maybe<MeterReadingSourceWhereUniqueInput>;
  disconnect?: Maybe<MeterReadingSourceWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum MeterReadingSourceTypeType {
  ImportCondo = 'import_condo',
  Call = 'call',
  MobileApp = 'mobile_app',
  ExternalImport = 'external_import',
  Email = 'email',
  RemoteSystem = 'remote_system',
  Other = 'other',
  Visit = 'visit',
  WebApp = 'web_app',
  OrganizationSite = 'organization_site',
  Messenger = 'messenger',
  SocialNetwork = 'social_network',
  MobileAppStaff = 'mobile_app_staff',
  Crm = 'crm'
}

export type MeterReadingSourceUpdateInput = {
  type?: Maybe<MeterReadingSourceTypeType>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterReadingSourceWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingSourceWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingSourceWhereInput>>>;
  type?: Maybe<MeterReadingSourceTypeType>;
  type_not?: Maybe<MeterReadingSourceTypeType>;
  type_in?: Maybe<Array<Maybe<MeterReadingSourceTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<MeterReadingSourceTypeType>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterReadingSourceWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingSourcesCreateInput = {
  data?: Maybe<MeterReadingSourceCreateInput>;
};

export type MeterReadingSourcesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingSourceUpdateInput>;
};

export type MeterReadingUpdateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<MeterRelateToOneInput>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  client?: Maybe<UserRelateToOneInput>;
  contact?: Maybe<ContactRelateToOneInput>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  source?: Maybe<MeterReadingSourceRelateToOneInput>;
  billingStatus?: Maybe<MeterReadingBillingStatusType>;
  billingStatusText?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterReadingWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingWhereInput>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meter?: Maybe<MeterWhereInput>;
  meter_is_null?: Maybe<Scalars['Boolean']>;
  value1?: Maybe<Scalars['String']>;
  value1_not?: Maybe<Scalars['String']>;
  value1_lt?: Maybe<Scalars['String']>;
  value1_lte?: Maybe<Scalars['String']>;
  value1_gt?: Maybe<Scalars['String']>;
  value1_gte?: Maybe<Scalars['String']>;
  value1_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2?: Maybe<Scalars['String']>;
  value2_not?: Maybe<Scalars['String']>;
  value2_lt?: Maybe<Scalars['String']>;
  value2_lte?: Maybe<Scalars['String']>;
  value2_gt?: Maybe<Scalars['String']>;
  value2_gte?: Maybe<Scalars['String']>;
  value2_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3?: Maybe<Scalars['String']>;
  value3_not?: Maybe<Scalars['String']>;
  value3_lt?: Maybe<Scalars['String']>;
  value3_lte?: Maybe<Scalars['String']>;
  value3_gt?: Maybe<Scalars['String']>;
  value3_gte?: Maybe<Scalars['String']>;
  value3_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4?: Maybe<Scalars['String']>;
  value4_not?: Maybe<Scalars['String']>;
  value4_lt?: Maybe<Scalars['String']>;
  value4_lte?: Maybe<Scalars['String']>;
  value4_gt?: Maybe<Scalars['String']>;
  value4_gte?: Maybe<Scalars['String']>;
  value4_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  client?: Maybe<UserWhereInput>;
  client_is_null?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<ContactWhereInput>;
  contact_is_null?: Maybe<Scalars['Boolean']>;
  clientName?: Maybe<Scalars['String']>;
  clientName_not?: Maybe<Scalars['String']>;
  clientName_contains?: Maybe<Scalars['String']>;
  clientName_not_contains?: Maybe<Scalars['String']>;
  clientName_starts_with?: Maybe<Scalars['String']>;
  clientName_not_starts_with?: Maybe<Scalars['String']>;
  clientName_ends_with?: Maybe<Scalars['String']>;
  clientName_not_ends_with?: Maybe<Scalars['String']>;
  clientName_i?: Maybe<Scalars['String']>;
  clientName_not_i?: Maybe<Scalars['String']>;
  clientName_contains_i?: Maybe<Scalars['String']>;
  clientName_not_contains_i?: Maybe<Scalars['String']>;
  clientName_starts_with_i?: Maybe<Scalars['String']>;
  clientName_not_starts_with_i?: Maybe<Scalars['String']>;
  clientName_ends_with_i?: Maybe<Scalars['String']>;
  clientName_not_ends_with_i?: Maybe<Scalars['String']>;
  clientName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail?: Maybe<Scalars['String']>;
  clientEmail_not?: Maybe<Scalars['String']>;
  clientEmail_contains?: Maybe<Scalars['String']>;
  clientEmail_not_contains?: Maybe<Scalars['String']>;
  clientEmail_starts_with?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with?: Maybe<Scalars['String']>;
  clientEmail_ends_with?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with?: Maybe<Scalars['String']>;
  clientEmail_i?: Maybe<Scalars['String']>;
  clientEmail_not_i?: Maybe<Scalars['String']>;
  clientEmail_contains_i?: Maybe<Scalars['String']>;
  clientEmail_not_contains_i?: Maybe<Scalars['String']>;
  clientEmail_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Scalars['String']>;
  clientPhone_not?: Maybe<Scalars['String']>;
  clientPhone_contains?: Maybe<Scalars['String']>;
  clientPhone_not_contains?: Maybe<Scalars['String']>;
  clientPhone_starts_with?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with?: Maybe<Scalars['String']>;
  clientPhone_ends_with?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with?: Maybe<Scalars['String']>;
  clientPhone_i?: Maybe<Scalars['String']>;
  clientPhone_not_i?: Maybe<Scalars['String']>;
  clientPhone_contains_i?: Maybe<Scalars['String']>;
  clientPhone_not_contains_i?: Maybe<Scalars['String']>;
  clientPhone_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<MeterReadingSourceWhereInput>;
  source_is_null?: Maybe<Scalars['Boolean']>;
  billingStatus?: Maybe<MeterReadingBillingStatusType>;
  billingStatus_not?: Maybe<MeterReadingBillingStatusType>;
  billingStatus_in?: Maybe<Array<Maybe<MeterReadingBillingStatusType>>>;
  billingStatus_not_in?: Maybe<Array<Maybe<MeterReadingBillingStatusType>>>;
  billingStatusText?: Maybe<Scalars['String']>;
  billingStatusText_not?: Maybe<Scalars['String']>;
  billingStatusText_contains?: Maybe<Scalars['String']>;
  billingStatusText_not_contains?: Maybe<Scalars['String']>;
  billingStatusText_starts_with?: Maybe<Scalars['String']>;
  billingStatusText_not_starts_with?: Maybe<Scalars['String']>;
  billingStatusText_ends_with?: Maybe<Scalars['String']>;
  billingStatusText_not_ends_with?: Maybe<Scalars['String']>;
  billingStatusText_i?: Maybe<Scalars['String']>;
  billingStatusText_not_i?: Maybe<Scalars['String']>;
  billingStatusText_contains_i?: Maybe<Scalars['String']>;
  billingStatusText_not_contains_i?: Maybe<Scalars['String']>;
  billingStatusText_starts_with_i?: Maybe<Scalars['String']>;
  billingStatusText_not_starts_with_i?: Maybe<Scalars['String']>;
  billingStatusText_ends_with_i?: Maybe<Scalars['String']>;
  billingStatusText_not_ends_with_i?: Maybe<Scalars['String']>;
  billingStatusText_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingStatusText_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterReadingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingsCreateInput = {
  data?: Maybe<MeterReadingCreateInput>;
};

/**  Stores requested import job parameters, link to error file (if exists) and information about progress of import job  */
export type MeterReadingsImportTask = {
  __typename?: 'MeterReadingsImportTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingsImportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingsImportTask List config, or
   *  3. As an alias to a 'name' field on the MeterReadingsImportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingsImportTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Status of import job  */
  status?: Maybe<MeterReadingsImportTaskStatusType>;
  /**  Requested import file format  */
  format?: Maybe<MeterReadingsImportTaskFormatType>;
  /**  How many records at the moment are successfully imported  */
  importedRecordsCount?: Maybe<Scalars['Int']>;
  /**  How many records were processed at the moment  */
  processedRecordsCount?: Maybe<Scalars['Int']>;
  /**  Total records to import that will be determined at server side in import operation  */
  totalRecordsCount?: Maybe<Scalars['Int']>;
  /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  file?: Maybe<File>;
  /**  Meta information about error file. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  errorFile?: Maybe<File>;
  /**  Proceeding error information message  */
  errorMessage?: Maybe<Scalars['String']>;
  /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
  user?: Maybe<User>;
  /**  Direct link to organization, since meters import requires to specify target organization  */
  organization?: Maybe<Organization>;
  /**  Requested import locale  */
  locale?: Maybe<Scalars['String']>;
  /**  Stores information about query and ids of exported and failed records  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterReadingsImportTaskCreateInput = {
  status?: Maybe<MeterReadingsImportTaskStatusType>;
  format?: Maybe<MeterReadingsImportTaskFormatType>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  errorFile?: Maybe<Scalars['Upload']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  locale?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum MeterReadingsImportTaskFormatType {
  ExcelDoma = 'excel_doma',
  Csv_1s = 'csv_1s'
}

/**  A keystone list  */
export type MeterReadingsImportTaskHistoryRecord = {
  __typename?: 'MeterReadingsImportTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReadingsImportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReadingsImportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReadingsImportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReadingsImportTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  errorFile?: Maybe<Scalars['JSON']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingsImportTaskHistoryRecordCreateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  errorFile?: Maybe<Scalars['JSON']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterReadingsImportTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterReadingsImportTaskHistoryRecordUpdateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  errorFile?: Maybe<Scalars['JSON']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReadingsImportTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecordWhereInput>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  format_i?: Maybe<Scalars['String']>;
  format_not_i?: Maybe<Scalars['String']>;
  format_contains_i?: Maybe<Scalars['String']>;
  format_not_contains_i?: Maybe<Scalars['String']>;
  format_starts_with_i?: Maybe<Scalars['String']>;
  format_not_starts_with_i?: Maybe<Scalars['String']>;
  format_ends_with_i?: Maybe<Scalars['String']>;
  format_not_ends_with_i?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  importedRecordsCount_not?: Maybe<Scalars['Int']>;
  importedRecordsCount_lt?: Maybe<Scalars['Int']>;
  importedRecordsCount_lte?: Maybe<Scalars['Int']>;
  importedRecordsCount_gt?: Maybe<Scalars['Int']>;
  importedRecordsCount_gte?: Maybe<Scalars['Int']>;
  importedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  importedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount_not?: Maybe<Scalars['Int']>;
  processedRecordsCount_lt?: Maybe<Scalars['Int']>;
  processedRecordsCount_lte?: Maybe<Scalars['Int']>;
  processedRecordsCount_gt?: Maybe<Scalars['Int']>;
  processedRecordsCount_gte?: Maybe<Scalars['Int']>;
  processedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  errorFile?: Maybe<Scalars['JSON']>;
  errorFile_not?: Maybe<Scalars['JSON']>;
  errorFile_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  errorFile_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessage_not?: Maybe<Scalars['String']>;
  errorMessage_contains?: Maybe<Scalars['String']>;
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  errorMessage_i?: Maybe<Scalars['String']>;
  errorMessage_not_i?: Maybe<Scalars['String']>;
  errorMessage_contains_i?: Maybe<Scalars['String']>;
  errorMessage_not_contains_i?: Maybe<Scalars['String']>;
  errorMessage_starts_with_i?: Maybe<Scalars['String']>;
  errorMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  errorMessage_ends_with_i?: Maybe<Scalars['String']>;
  errorMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterReadingsImportTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingsImportTaskHistoryRecordsCreateInput = {
  data?: Maybe<MeterReadingsImportTaskHistoryRecordCreateInput>;
};

export type MeterReadingsImportTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingsImportTaskHistoryRecordUpdateInput>;
};

export enum MeterReadingsImportTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type MeterReadingsImportTaskUpdateInput = {
  status?: Maybe<MeterReadingsImportTaskStatusType>;
  format?: Maybe<MeterReadingsImportTaskFormatType>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  errorFile?: Maybe<Scalars['Upload']>;
  errorMessage?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  locale?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterReadingsImportTaskWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReadingsImportTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReadingsImportTaskWhereInput>>>;
  status?: Maybe<MeterReadingsImportTaskStatusType>;
  status_not?: Maybe<MeterReadingsImportTaskStatusType>;
  status_in?: Maybe<Array<Maybe<MeterReadingsImportTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<MeterReadingsImportTaskStatusType>>>;
  format?: Maybe<MeterReadingsImportTaskFormatType>;
  format_not?: Maybe<MeterReadingsImportTaskFormatType>;
  format_in?: Maybe<Array<Maybe<MeterReadingsImportTaskFormatType>>>;
  format_not_in?: Maybe<Array<Maybe<MeterReadingsImportTaskFormatType>>>;
  importedRecordsCount?: Maybe<Scalars['Int']>;
  importedRecordsCount_not?: Maybe<Scalars['Int']>;
  importedRecordsCount_lt?: Maybe<Scalars['Int']>;
  importedRecordsCount_lte?: Maybe<Scalars['Int']>;
  importedRecordsCount_gt?: Maybe<Scalars['Int']>;
  importedRecordsCount_gte?: Maybe<Scalars['Int']>;
  importedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  importedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedRecordsCount?: Maybe<Scalars['Int']>;
  processedRecordsCount_not?: Maybe<Scalars['Int']>;
  processedRecordsCount_lt?: Maybe<Scalars['Int']>;
  processedRecordsCount_lte?: Maybe<Scalars['Int']>;
  processedRecordsCount_gt?: Maybe<Scalars['Int']>;
  processedRecordsCount_gte?: Maybe<Scalars['Int']>;
  processedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  processedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorFile?: Maybe<Scalars['String']>;
  errorFile_not?: Maybe<Scalars['String']>;
  errorFile_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorFile_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessage_not?: Maybe<Scalars['String']>;
  errorMessage_contains?: Maybe<Scalars['String']>;
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  errorMessage_i?: Maybe<Scalars['String']>;
  errorMessage_not_i?: Maybe<Scalars['String']>;
  errorMessage_contains_i?: Maybe<Scalars['String']>;
  errorMessage_not_contains_i?: Maybe<Scalars['String']>;
  errorMessage_starts_with_i?: Maybe<Scalars['String']>;
  errorMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  errorMessage_ends_with_i?: Maybe<Scalars['String']>;
  errorMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  errorMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  errorMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterReadingsImportTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReadingsImportTasksCreateInput = {
  data?: Maybe<MeterReadingsImportTaskCreateInput>;
};

export type MeterReadingsImportTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingsImportTaskUpdateInput>;
};

export type MeterReadingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingUpdateInput>;
};

export type MeterRelateToOneInput = {
  create?: Maybe<MeterCreateInput>;
  connect?: Maybe<MeterWhereUniqueInput>;
  disconnect?: Maybe<MeterWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  Settings for the meter reading report period. Readings can be transferred at any time. The scheme is needed for notifications on the client side  */
export type MeterReportingPeriod = {
  __typename?: 'MeterReportingPeriod';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReportingPeriod List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReportingPeriod List config, or
   *  3. As an alias to a 'name' field on the MeterReportingPeriod List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReportingPeriod List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. Will be null if the instance is the default installation for all users  */
  organization?: Maybe<Organization>;
  /**  Ref to the property. Will be null if the instance is an installation for all users in the organization  */
  property?: Maybe<Property>;
  /**  Starting day of the month for sending a push about the need to submit meter readings  */
  notifyStartDay?: Maybe<Scalars['Int']>;
  /**  Finish day of the month for sending a push about the need to submit meter readings  */
  notifyEndDay?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterReportingPeriodCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MeterReportingPeriodHistoryRecord = {
  __typename?: 'MeterReportingPeriodHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterReportingPeriodHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterReportingPeriodHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterReportingPeriodHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterReportingPeriodHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReportingPeriodHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterReportingPeriodHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterReportingPeriodHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterReportingPeriodHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyStartDay_not?: Maybe<Scalars['Int']>;
  notifyStartDay_lt?: Maybe<Scalars['Int']>;
  notifyStartDay_lte?: Maybe<Scalars['Int']>;
  notifyStartDay_gt?: Maybe<Scalars['Int']>;
  notifyStartDay_gte?: Maybe<Scalars['Int']>;
  notifyStartDay_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notifyStartDay_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  notifyEndDay_not?: Maybe<Scalars['Int']>;
  notifyEndDay_lt?: Maybe<Scalars['Int']>;
  notifyEndDay_lte?: Maybe<Scalars['Int']>;
  notifyEndDay_gt?: Maybe<Scalars['Int']>;
  notifyEndDay_gte?: Maybe<Scalars['Int']>;
  notifyEndDay_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notifyEndDay_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterReportingPeriodHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReportingPeriodHistoryRecordsCreateInput = {
  data?: Maybe<MeterReportingPeriodHistoryRecordCreateInput>;
};

export type MeterReportingPeriodHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReportingPeriodHistoryRecordUpdateInput>;
};

export type MeterReportingPeriodUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterReportingPeriodWhereInput = {
  AND?: Maybe<Array<Maybe<MeterReportingPeriodWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterReportingPeriodWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  notifyStartDay?: Maybe<Scalars['Int']>;
  notifyStartDay_not?: Maybe<Scalars['Int']>;
  notifyStartDay_lt?: Maybe<Scalars['Int']>;
  notifyStartDay_lte?: Maybe<Scalars['Int']>;
  notifyStartDay_gt?: Maybe<Scalars['Int']>;
  notifyStartDay_gte?: Maybe<Scalars['Int']>;
  notifyStartDay_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notifyStartDay_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notifyEndDay?: Maybe<Scalars['Int']>;
  notifyEndDay_not?: Maybe<Scalars['Int']>;
  notifyEndDay_lt?: Maybe<Scalars['Int']>;
  notifyEndDay_lte?: Maybe<Scalars['Int']>;
  notifyEndDay_gt?: Maybe<Scalars['Int']>;
  notifyEndDay_gte?: Maybe<Scalars['Int']>;
  notifyEndDay_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notifyEndDay_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterReportingPeriodWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterReportingPeriodsCreateInput = {
  data?: Maybe<MeterReportingPeriodCreateInput>;
};

export type MeterReportingPeriodsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterReportingPeriodUpdateInput>;
};

/**  Kind of consumed resource, measured by meter  */
export type MeterResource = {
  __typename?: 'MeterResource';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResource List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResource List config, or
   *  3. As an alias to a 'name' field on the MeterResource List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResource List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  measureNonLocalized?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterResourceCreateInput = {
  name?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MeterResourceHistoryRecord = {
  __typename?: 'MeterResourceHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResourceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResourceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterResourceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResourceHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterResourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterResourceHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterResourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterResourceHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterResourceHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterResourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterResourceHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterResourceHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterResourceHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  measure?: Maybe<Scalars['String']>;
  measure_not?: Maybe<Scalars['String']>;
  measure_contains?: Maybe<Scalars['String']>;
  measure_not_contains?: Maybe<Scalars['String']>;
  measure_starts_with?: Maybe<Scalars['String']>;
  measure_not_starts_with?: Maybe<Scalars['String']>;
  measure_ends_with?: Maybe<Scalars['String']>;
  measure_not_ends_with?: Maybe<Scalars['String']>;
  measure_i?: Maybe<Scalars['String']>;
  measure_not_i?: Maybe<Scalars['String']>;
  measure_contains_i?: Maybe<Scalars['String']>;
  measure_not_contains_i?: Maybe<Scalars['String']>;
  measure_starts_with_i?: Maybe<Scalars['String']>;
  measure_not_starts_with_i?: Maybe<Scalars['String']>;
  measure_ends_with_i?: Maybe<Scalars['String']>;
  measure_not_ends_with_i?: Maybe<Scalars['String']>;
  measure_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  measure_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterResourceHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterResourceHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterResourceHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterResourceHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterResourceHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterResourceHistoryRecordsCreateInput = {
  data?: Maybe<MeterResourceHistoryRecordCreateInput>;
};

export type MeterResourceHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceHistoryRecordUpdateInput>;
};

/**  Determines whether the meter belongs to the organization for a specific apartment  */
export type MeterResourceOwner = {
  __typename?: 'MeterResourceOwner';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResourceOwner List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResourceOwner List config, or
   *  3. As an alias to a 'name' field on the MeterResourceOwner List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResourceOwner List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Meter resource that organization is owned by  */
  resource?: Maybe<MeterResource>;
  id: Scalars['ID'];
  /**  Normalized address  */
  address?: Maybe<Scalars['String']>;
  /**  The unique key of the address  */
  addressKey?: Maybe<Scalars['String']>;
  /**  Property address components  */
  addressMeta?: Maybe<AddressMetaField>;
  /**  The origins of the address (some strings which may looks like real address or some id)  */
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MeterResourceOwnerCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  resource?: Maybe<MeterResourceRelateToOneInput>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MeterResourceOwnerHistoryRecord = {
  __typename?: 'MeterResourceOwnerHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MeterResourceOwnerHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MeterResourceOwnerHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MeterResourceOwnerHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MeterResourceOwnerHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterResourceOwnerHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MeterResourceOwnerHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MeterResourceOwnerHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MeterResourceOwnerHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resource?: Maybe<Scalars['String']>;
  resource_not?: Maybe<Scalars['String']>;
  resource_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resource_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MeterResourceOwnerHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterResourceOwnerHistoryRecordsCreateInput = {
  data?: Maybe<MeterResourceOwnerHistoryRecordCreateInput>;
};

export type MeterResourceOwnerHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceOwnerHistoryRecordUpdateInput>;
};

export type MeterResourceOwnerUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  resource?: Maybe<MeterResourceRelateToOneInput>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterResourceOwnerWhereInput = {
  AND?: Maybe<Array<Maybe<MeterResourceOwnerWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterResourceOwnerWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceWhereInput>;
  resource_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterResourceOwnerWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterResourceOwnersCreateInput = {
  data?: Maybe<MeterResourceOwnerCreateInput>;
};

export type MeterResourceOwnersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceOwnerUpdateInput>;
};

export type MeterResourceRelateToOneInput = {
  create?: Maybe<MeterResourceCreateInput>;
  connect?: Maybe<MeterResourceWhereUniqueInput>;
  disconnect?: Maybe<MeterResourceWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type MeterResourceUpdateInput = {
  name?: Maybe<Scalars['String']>;
  measure?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterResourceWhereInput = {
  AND?: Maybe<Array<Maybe<MeterResourceWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterResourceWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  measure?: Maybe<Scalars['String']>;
  measure_not?: Maybe<Scalars['String']>;
  measure_contains?: Maybe<Scalars['String']>;
  measure_not_contains?: Maybe<Scalars['String']>;
  measure_starts_with?: Maybe<Scalars['String']>;
  measure_not_starts_with?: Maybe<Scalars['String']>;
  measure_ends_with?: Maybe<Scalars['String']>;
  measure_not_ends_with?: Maybe<Scalars['String']>;
  measure_i?: Maybe<Scalars['String']>;
  measure_not_i?: Maybe<Scalars['String']>;
  measure_contains_i?: Maybe<Scalars['String']>;
  measure_not_contains_i?: Maybe<Scalars['String']>;
  measure_starts_with_i?: Maybe<Scalars['String']>;
  measure_not_starts_with_i?: Maybe<Scalars['String']>;
  measure_ends_with_i?: Maybe<Scalars['String']>;
  measure_not_ends_with_i?: Maybe<Scalars['String']>;
  measure_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  measure_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterResourceWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MeterResourcesCreateInput = {
  data?: Maybe<MeterResourceCreateInput>;
};

export type MeterResourcesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceUpdateInput>;
};

export enum MeterUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type MeterUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceRelateToOneInput>;
  b2bApp?: Maybe<B2BAppRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  number?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<MeterUnitTypeType>;
  place?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  b2cApp?: Maybe<B2CAppRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MeterWhereInput = {
  AND?: Maybe<Array<Maybe<MeterWhereInput>>>;
  OR?: Maybe<Array<Maybe<MeterWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  numberOfTariffs_not?: Maybe<Scalars['Int']>;
  numberOfTariffs_lt?: Maybe<Scalars['Int']>;
  numberOfTariffs_lte?: Maybe<Scalars['Int']>;
  numberOfTariffs_gt?: Maybe<Scalars['Int']>;
  numberOfTariffs_gte?: Maybe<Scalars['Int']>;
  numberOfTariffs_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfTariffs_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  installationDate?: Maybe<Scalars['String']>;
  installationDate_not?: Maybe<Scalars['String']>;
  installationDate_lt?: Maybe<Scalars['String']>;
  installationDate_lte?: Maybe<Scalars['String']>;
  installationDate_gt?: Maybe<Scalars['String']>;
  installationDate_gte?: Maybe<Scalars['String']>;
  installationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  installationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate?: Maybe<Scalars['String']>;
  commissioningDate_not?: Maybe<Scalars['String']>;
  commissioningDate_lt?: Maybe<Scalars['String']>;
  commissioningDate_lte?: Maybe<Scalars['String']>;
  commissioningDate_gt?: Maybe<Scalars['String']>;
  commissioningDate_gte?: Maybe<Scalars['String']>;
  commissioningDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate?: Maybe<Scalars['String']>;
  verificationDate_not?: Maybe<Scalars['String']>;
  verificationDate_lt?: Maybe<Scalars['String']>;
  verificationDate_lte?: Maybe<Scalars['String']>;
  verificationDate_gt?: Maybe<Scalars['String']>;
  verificationDate_gte?: Maybe<Scalars['String']>;
  verificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate_not?: Maybe<Scalars['String']>;
  nextVerificationDate_lt?: Maybe<Scalars['String']>;
  nextVerificationDate_lte?: Maybe<Scalars['String']>;
  nextVerificationDate_gt?: Maybe<Scalars['String']>;
  nextVerificationDate_gte?: Maybe<Scalars['String']>;
  nextVerificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  controlReadingsDate_not?: Maybe<Scalars['String']>;
  controlReadingsDate_lt?: Maybe<Scalars['String']>;
  controlReadingsDate_lte?: Maybe<Scalars['String']>;
  controlReadingsDate_gt?: Maybe<Scalars['String']>;
  controlReadingsDate_gte?: Maybe<Scalars['String']>;
  controlReadingsDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate?: Maybe<Scalars['String']>;
  sealingDate_not?: Maybe<Scalars['String']>;
  sealingDate_lt?: Maybe<Scalars['String']>;
  sealingDate_lte?: Maybe<Scalars['String']>;
  sealingDate_gt?: Maybe<Scalars['String']>;
  sealingDate_gte?: Maybe<Scalars['String']>;
  sealingDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate?: Maybe<Scalars['String']>;
  archiveDate_not?: Maybe<Scalars['String']>;
  archiveDate_lt?: Maybe<Scalars['String']>;
  archiveDate_lte?: Maybe<Scalars['String']>;
  archiveDate_gt?: Maybe<Scalars['String']>;
  archiveDate_gte?: Maybe<Scalars['String']>;
  archiveDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  isAutomatic_not?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceWhereInput>;
  resource_is_null?: Maybe<Scalars['Boolean']>;
  b2bApp?: Maybe<B2BAppWhereInput>;
  b2bApp_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<MeterUnitTypeType>;
  unitType_not?: Maybe<MeterUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<MeterUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<MeterUnitTypeType>>>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_contains?: Maybe<Scalars['String']>;
  place_not_contains?: Maybe<Scalars['String']>;
  place_starts_with?: Maybe<Scalars['String']>;
  place_not_starts_with?: Maybe<Scalars['String']>;
  place_ends_with?: Maybe<Scalars['String']>;
  place_not_ends_with?: Maybe<Scalars['String']>;
  place_i?: Maybe<Scalars['String']>;
  place_not_i?: Maybe<Scalars['String']>;
  place_contains_i?: Maybe<Scalars['String']>;
  place_not_contains_i?: Maybe<Scalars['String']>;
  place_starts_with_i?: Maybe<Scalars['String']>;
  place_not_starts_with_i?: Maybe<Scalars['String']>;
  place_ends_with_i?: Maybe<Scalars['String']>;
  place_not_ends_with_i?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  place_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  b2cApp?: Maybe<B2CAppWhereInput>;
  b2cApp_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MeterWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MetersCreateInput = {
  data?: Maybe<MeterCreateInput>;
};

export type MetersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MeterUpdateInput>;
};

export type MiniAppOutput = {
  __typename?: 'MiniAppOutput';
  id: Scalars['ID'];
  connected: Scalars['Boolean'];
  accessible: Scalars['Boolean'];
  name: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  category: AppCategory;
  logo?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  menuCategory?: Maybe<Scalars['String']>;
};

export enum MobileApp {
  Resident = 'resident',
  Master = 'master'
}

/**  Manages availability of some features in mobile application, stores data required in disabled state of a feature.  */
export type MobileFeatureConfig = {
  __typename?: 'MobileFeatureConfig';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MobileFeatureConfig List config, or
   *  2. As an alias to the field set on 'labelField' in the MobileFeatureConfig List config, or
   *  3. As an alias to a 'name' field on the MobileFeatureConfig List (if one exists), or
   *  4. As an alias to the 'id' field on the MobileFeatureConfig List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Phone number where the organization wants to receive common calls  */
  commonPhone?: Maybe<Scalars['String']>;
  /**  Disabling the ability to send tickets by the user of the resident's mobile application. Instead, he will be shown a screen with phones where he can contact to send a request  */
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  /**  Restricts residents to submit only meter readings, that are greater than previous. Restrictions apply to mobile phones only.  */
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  /**  Can be used to store some meta information for mobile applications.  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type MobileFeatureConfigCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  commonPhone?: Maybe<Scalars['String']>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type MobileFeatureConfigHistoryRecord = {
  __typename?: 'MobileFeatureConfigHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MobileFeatureConfigHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MobileFeatureConfigHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MobileFeatureConfigHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MobileFeatureConfigHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  commonPhone?: Maybe<Scalars['String']>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MobileFeatureConfigHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  commonPhone?: Maybe<Scalars['String']>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MobileFeatureConfigHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MobileFeatureConfigHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  commonPhone?: Maybe<Scalars['String']>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MobileFeatureConfigHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commonPhone?: Maybe<Scalars['String']>;
  commonPhone_not?: Maybe<Scalars['String']>;
  commonPhone_contains?: Maybe<Scalars['String']>;
  commonPhone_not_contains?: Maybe<Scalars['String']>;
  commonPhone_starts_with?: Maybe<Scalars['String']>;
  commonPhone_not_starts_with?: Maybe<Scalars['String']>;
  commonPhone_ends_with?: Maybe<Scalars['String']>;
  commonPhone_not_ends_with?: Maybe<Scalars['String']>;
  commonPhone_i?: Maybe<Scalars['String']>;
  commonPhone_not_i?: Maybe<Scalars['String']>;
  commonPhone_contains_i?: Maybe<Scalars['String']>;
  commonPhone_not_contains_i?: Maybe<Scalars['String']>;
  commonPhone_starts_with_i?: Maybe<Scalars['String']>;
  commonPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  commonPhone_ends_with_i?: Maybe<Scalars['String']>;
  commonPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  commonPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commonPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  ticketSubmittingIsDisabled_not?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MobileFeatureConfigHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MobileFeatureConfigHistoryRecordsCreateInput = {
  data?: Maybe<MobileFeatureConfigHistoryRecordCreateInput>;
};

export type MobileFeatureConfigHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MobileFeatureConfigHistoryRecordUpdateInput>;
};

export type MobileFeatureConfigUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  commonPhone?: Maybe<Scalars['String']>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MobileFeatureConfigWhereInput = {
  AND?: Maybe<Array<Maybe<MobileFeatureConfigWhereInput>>>;
  OR?: Maybe<Array<Maybe<MobileFeatureConfigWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  commonPhone?: Maybe<Scalars['String']>;
  commonPhone_not?: Maybe<Scalars['String']>;
  commonPhone_contains?: Maybe<Scalars['String']>;
  commonPhone_not_contains?: Maybe<Scalars['String']>;
  commonPhone_starts_with?: Maybe<Scalars['String']>;
  commonPhone_not_starts_with?: Maybe<Scalars['String']>;
  commonPhone_ends_with?: Maybe<Scalars['String']>;
  commonPhone_not_ends_with?: Maybe<Scalars['String']>;
  commonPhone_i?: Maybe<Scalars['String']>;
  commonPhone_not_i?: Maybe<Scalars['String']>;
  commonPhone_contains_i?: Maybe<Scalars['String']>;
  commonPhone_not_contains_i?: Maybe<Scalars['String']>;
  commonPhone_starts_with_i?: Maybe<Scalars['String']>;
  commonPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  commonPhone_ends_with_i?: Maybe<Scalars['String']>;
  commonPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  commonPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commonPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticketSubmittingIsDisabled?: Maybe<Scalars['Boolean']>;
  ticketSubmittingIsDisabled_not?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled?: Maybe<Scalars['Boolean']>;
  onlyGreaterThanPreviousMeterReadingIsEnabled_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MobileFeatureConfigWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MobileFeatureConfigsCreateInput = {
  data?: Maybe<MobileFeatureConfigCreateInput>;
};

export type MobileFeatureConfigsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MobileFeatureConfigUpdateInput>;
};

export enum MobilePlatform {
  Android = 'android',
  Ios = 'ios'
}

/**  Information about resident's payment for single or multiple services/receipts  */
export type MultiPayment = {
  __typename?: 'MultiPayment';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MultiPayment List config, or
   *  2. As an alias to the field set on 'labelField' in the MultiPayment List config, or
   *  3. As an alias to a 'name' field on the MultiPayment List (if one exists), or
   *  4. As an alias to the 'id' field on the MultiPayment List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Total amount of withdraw. amount = amountWithoutExplicitFee + explicitFee + explicitServiceCharge  */
  amount?: Maybe<Scalars['String']>;
  /**  Amount of money which payer pays on top of initial "amount", which counts as fee for total "amount"  */
  explicitFee?: Maybe<Scalars['String']>;
  /**  Amount of money which payer pays on top of initial "amount", which counts as internal service charge for all payments  */
  explicitServiceCharge?: Maybe<Scalars['String']>;
  /**  The amount of money charged by service (Doma) for the provision of service after subtracting from it the shares of all participants in the process. Can be part of explicit fee, implicit fee or explicit service charge  */
  serviceFee?: Maybe<Scalars['String']>;
  /**  Total amount of money charged to recipients from amountWithoutExplicitFee of multipayment as fee for transaction  */
  implicitFee?: Maybe<Scalars['String']>;
  /**  The amount of money used to pay bills, initialized by resident.  */
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<MultiPaymentCurrencyCodeType>;
  /**  Time of money withdraw (UTC)  */
  withdrawnAt?: Maybe<Scalars['String']>;
  /**  Number of the card (masked) from which the money was withdrawn. Needed for creating receipt  */
  cardNumber?: Maybe<Scalars['String']>;
  /**  Payment way, such as `CARD` or `APPLE_PAY`  */
  paymentWay?: Maybe<MultiPaymentPaymentWayType>;
  /**  Payer email address (optional). Can be used by support to find MultiPayment faster or to send digital receipt  */
  payerEmail?: Maybe<Scalars['String']>;
  /**  Name of the payment document, such as `Квитанция`, `Штраф`  */
  serviceCategory?: Maybe<Scalars['String']>;
  /**  Transaction ID of money withdraw  */
  transactionId?: Maybe<Scalars['String']>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  /**  Additional acquiring-specific information  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Status of multipayment. Can be: "CREATED", "DONE", "PROCESSING", "ERROR", "WITHDRAWN"  */
  status?: Maybe<MultiPaymentStatusType>;
  /**  Link to user  */
  user?: Maybe<User>;
  /**  Link to all related payments  */
  payments: Array<Payment>;
  _paymentsMeta?: Maybe<_QueryMeta>;
  /**  Acquiring integration. Determines way of user's payment  */
  integration?: Maybe<AcquiringIntegration>;
  /**  Link to RecurrentPaymentContext  */
  recurrentPaymentContext?: Maybe<RecurrentPaymentContext>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  Information about resident's payment for single or multiple services/receipts  */
export type MultiPaymentPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  Information about resident's payment for single or multiple services/receipts  */
export type MultiPayment_PaymentsMetaArgs = {
  where?: Maybe<PaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type MultiPaymentCreateInput = {
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<MultiPaymentCurrencyCodeType>;
  withdrawnAt?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  paymentWay?: Maybe<MultiPaymentPaymentWayType>;
  payerEmail?: Maybe<Scalars['String']>;
  serviceCategory?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<MultiPaymentStatusType>;
  user?: Maybe<UserRelateToOneInput>;
  payments?: Maybe<PaymentRelateToManyInput>;
  integration?: Maybe<AcquiringIntegrationRelateToOneInput>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum MultiPaymentCurrencyCodeType {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

/**  A keystone list  */
export type MultiPaymentHistoryRecord = {
  __typename?: 'MultiPaymentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the MultiPaymentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the MultiPaymentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the MultiPaymentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the MultiPaymentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  withdrawnAt?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  paymentWay?: Maybe<Scalars['String']>;
  payerEmail?: Maybe<Scalars['String']>;
  serviceCategory?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MultiPaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MultiPaymentHistoryRecordCreateInput = {
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  withdrawnAt?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  paymentWay?: Maybe<Scalars['String']>;
  payerEmail?: Maybe<Scalars['String']>;
  serviceCategory?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MultiPaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum MultiPaymentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type MultiPaymentHistoryRecordUpdateInput = {
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  withdrawnAt?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  paymentWay?: Maybe<Scalars['String']>;
  payerEmail?: Maybe<Scalars['String']>;
  serviceCategory?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  integration?: Maybe<Scalars['String']>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<MultiPaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type MultiPaymentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<MultiPaymentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<MultiPaymentHistoryRecordWhereInput>>>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitFee_not?: Maybe<Scalars['String']>;
  explicitFee_lt?: Maybe<Scalars['String']>;
  explicitFee_lte?: Maybe<Scalars['String']>;
  explicitFee_gt?: Maybe<Scalars['String']>;
  explicitFee_gte?: Maybe<Scalars['String']>;
  explicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  explicitServiceCharge_not?: Maybe<Scalars['String']>;
  explicitServiceCharge_lt?: Maybe<Scalars['String']>;
  explicitServiceCharge_lte?: Maybe<Scalars['String']>;
  explicitServiceCharge_gt?: Maybe<Scalars['String']>;
  explicitServiceCharge_gte?: Maybe<Scalars['String']>;
  explicitServiceCharge_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee?: Maybe<Scalars['String']>;
  serviceFee_not?: Maybe<Scalars['String']>;
  serviceFee_lt?: Maybe<Scalars['String']>;
  serviceFee_lte?: Maybe<Scalars['String']>;
  serviceFee_gt?: Maybe<Scalars['String']>;
  serviceFee_gte?: Maybe<Scalars['String']>;
  serviceFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee?: Maybe<Scalars['String']>;
  implicitFee_not?: Maybe<Scalars['String']>;
  implicitFee_lt?: Maybe<Scalars['String']>;
  implicitFee_lte?: Maybe<Scalars['String']>;
  implicitFee_gt?: Maybe<Scalars['String']>;
  implicitFee_gte?: Maybe<Scalars['String']>;
  implicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_not?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_lt?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_lte?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_gt?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_gte?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountWithoutExplicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_not?: Maybe<Scalars['String']>;
  currencyCode_contains?: Maybe<Scalars['String']>;
  currencyCode_not_contains?: Maybe<Scalars['String']>;
  currencyCode_starts_with?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with?: Maybe<Scalars['String']>;
  currencyCode_ends_with?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with?: Maybe<Scalars['String']>;
  currencyCode_i?: Maybe<Scalars['String']>;
  currencyCode_not_i?: Maybe<Scalars['String']>;
  currencyCode_contains_i?: Maybe<Scalars['String']>;
  currencyCode_not_contains_i?: Maybe<Scalars['String']>;
  currencyCode_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  withdrawnAt?: Maybe<Scalars['String']>;
  withdrawnAt_not?: Maybe<Scalars['String']>;
  withdrawnAt_lt?: Maybe<Scalars['String']>;
  withdrawnAt_lte?: Maybe<Scalars['String']>;
  withdrawnAt_gt?: Maybe<Scalars['String']>;
  withdrawnAt_gte?: Maybe<Scalars['String']>;
  withdrawnAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  withdrawnAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardNumber?: Maybe<Scalars['String']>;
  cardNumber_not?: Maybe<Scalars['String']>;
  cardNumber_contains?: Maybe<Scalars['String']>;
  cardNumber_not_contains?: Maybe<Scalars['String']>;
  cardNumber_starts_with?: Maybe<Scalars['String']>;
  cardNumber_not_starts_with?: Maybe<Scalars['String']>;
  cardNumber_ends_with?: Maybe<Scalars['String']>;
  cardNumber_not_ends_with?: Maybe<Scalars['String']>;
  cardNumber_i?: Maybe<Scalars['String']>;
  cardNumber_not_i?: Maybe<Scalars['String']>;
  cardNumber_contains_i?: Maybe<Scalars['String']>;
  cardNumber_not_contains_i?: Maybe<Scalars['String']>;
  cardNumber_starts_with_i?: Maybe<Scalars['String']>;
  cardNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  cardNumber_ends_with_i?: Maybe<Scalars['String']>;
  cardNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  cardNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentWay?: Maybe<Scalars['String']>;
  paymentWay_not?: Maybe<Scalars['String']>;
  paymentWay_contains?: Maybe<Scalars['String']>;
  paymentWay_not_contains?: Maybe<Scalars['String']>;
  paymentWay_starts_with?: Maybe<Scalars['String']>;
  paymentWay_not_starts_with?: Maybe<Scalars['String']>;
  paymentWay_ends_with?: Maybe<Scalars['String']>;
  paymentWay_not_ends_with?: Maybe<Scalars['String']>;
  paymentWay_i?: Maybe<Scalars['String']>;
  paymentWay_not_i?: Maybe<Scalars['String']>;
  paymentWay_contains_i?: Maybe<Scalars['String']>;
  paymentWay_not_contains_i?: Maybe<Scalars['String']>;
  paymentWay_starts_with_i?: Maybe<Scalars['String']>;
  paymentWay_not_starts_with_i?: Maybe<Scalars['String']>;
  paymentWay_ends_with_i?: Maybe<Scalars['String']>;
  paymentWay_not_ends_with_i?: Maybe<Scalars['String']>;
  paymentWay_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentWay_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payerEmail?: Maybe<Scalars['String']>;
  payerEmail_not?: Maybe<Scalars['String']>;
  payerEmail_contains?: Maybe<Scalars['String']>;
  payerEmail_not_contains?: Maybe<Scalars['String']>;
  payerEmail_starts_with?: Maybe<Scalars['String']>;
  payerEmail_not_starts_with?: Maybe<Scalars['String']>;
  payerEmail_ends_with?: Maybe<Scalars['String']>;
  payerEmail_not_ends_with?: Maybe<Scalars['String']>;
  payerEmail_i?: Maybe<Scalars['String']>;
  payerEmail_not_i?: Maybe<Scalars['String']>;
  payerEmail_contains_i?: Maybe<Scalars['String']>;
  payerEmail_not_contains_i?: Maybe<Scalars['String']>;
  payerEmail_starts_with_i?: Maybe<Scalars['String']>;
  payerEmail_not_starts_with_i?: Maybe<Scalars['String']>;
  payerEmail_ends_with_i?: Maybe<Scalars['String']>;
  payerEmail_not_ends_with_i?: Maybe<Scalars['String']>;
  payerEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payerEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceCategory?: Maybe<Scalars['String']>;
  serviceCategory_not?: Maybe<Scalars['String']>;
  serviceCategory_contains?: Maybe<Scalars['String']>;
  serviceCategory_not_contains?: Maybe<Scalars['String']>;
  serviceCategory_starts_with?: Maybe<Scalars['String']>;
  serviceCategory_not_starts_with?: Maybe<Scalars['String']>;
  serviceCategory_ends_with?: Maybe<Scalars['String']>;
  serviceCategory_not_ends_with?: Maybe<Scalars['String']>;
  serviceCategory_i?: Maybe<Scalars['String']>;
  serviceCategory_not_i?: Maybe<Scalars['String']>;
  serviceCategory_contains_i?: Maybe<Scalars['String']>;
  serviceCategory_not_contains_i?: Maybe<Scalars['String']>;
  serviceCategory_starts_with_i?: Maybe<Scalars['String']>;
  serviceCategory_not_starts_with_i?: Maybe<Scalars['String']>;
  serviceCategory_ends_with_i?: Maybe<Scalars['String']>;
  serviceCategory_not_ends_with_i?: Maybe<Scalars['String']>;
  serviceCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionId?: Maybe<Scalars['String']>;
  transactionId_not?: Maybe<Scalars['String']>;
  transactionId_contains?: Maybe<Scalars['String']>;
  transactionId_not_contains?: Maybe<Scalars['String']>;
  transactionId_starts_with?: Maybe<Scalars['String']>;
  transactionId_not_starts_with?: Maybe<Scalars['String']>;
  transactionId_ends_with?: Maybe<Scalars['String']>;
  transactionId_not_ends_with?: Maybe<Scalars['String']>;
  transactionId_i?: Maybe<Scalars['String']>;
  transactionId_not_i?: Maybe<Scalars['String']>;
  transactionId_contains_i?: Maybe<Scalars['String']>;
  transactionId_not_contains_i?: Maybe<Scalars['String']>;
  transactionId_starts_with_i?: Maybe<Scalars['String']>;
  transactionId_not_starts_with_i?: Maybe<Scalars['String']>;
  transactionId_ends_with_i?: Maybe<Scalars['String']>;
  transactionId_not_ends_with_i?: Maybe<Scalars['String']>;
  transactionId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration?: Maybe<Scalars['String']>;
  integration_not?: Maybe<Scalars['String']>;
  integration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  integration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  recurrentPaymentContext_not?: Maybe<Scalars['String']>;
  recurrentPaymentContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recurrentPaymentContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<MultiPaymentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<MultiPaymentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<MultiPaymentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<MultiPaymentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type MultiPaymentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MultiPaymentHistoryRecordsCreateInput = {
  data?: Maybe<MultiPaymentHistoryRecordCreateInput>;
};

export type MultiPaymentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MultiPaymentHistoryRecordUpdateInput>;
};

export enum MultiPaymentPaymentWayType {
  Card = 'CARD',
  CardBinding = 'CARD_BINDING',
  CardMoto = 'CARD_MOTO',
  CardPresent = 'CARD_PRESENT',
  SbrfSbol = 'SBRF_SBOL',
  Upop = 'UPOP',
  FileBinding = 'FILE_BINDING',
  SmsBinding = 'SMS_BINDING',
  P2P = 'P2P',
  P2PBinding = 'P2P_BINDING',
  Paypal = 'PAYPAL',
  Mts = 'MTS',
  ApplePay = 'APPLE_PAY',
  ApplePayBinding = 'APPLE_PAY_BINDING',
  AndroidPay = 'ANDROID_PAY',
  AndroidPayBinding = 'ANDROID_PAY_BINDING',
  GooglePayCard = 'GOOGLE_PAY_CARD',
  GooglePayCardBinding = 'GOOGLE_PAY_CARD_BINDING',
  GooglePayTokenized = 'GOOGLE_PAY_TOKENIZED',
  GooglePayTokenizedBinding = 'GOOGLE_PAY_TOKENIZED_BINDING',
  SamsungPay = 'SAMSUNG_PAY',
  SamsungPayBinding = 'SAMSUNG_PAY_BINDING',
  Ipos = 'IPOS',
  Sberpay = 'SBERPAY',
  Sberid = 'SBERID',
  SbrfSbolBinding = 'SBRF_SBOL_BINDING'
}

export type MultiPaymentRelateToOneInput = {
  create?: Maybe<MultiPaymentCreateInput>;
  connect?: Maybe<MultiPaymentWhereUniqueInput>;
  disconnect?: Maybe<MultiPaymentWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum MultiPaymentStatusType {
  Created = 'CREATED',
  Done = 'DONE',
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Withdrawn = 'WITHDRAWN'
}

export type MultiPaymentUpdateInput = {
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<MultiPaymentCurrencyCodeType>;
  withdrawnAt?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  paymentWay?: Maybe<MultiPaymentPaymentWayType>;
  payerEmail?: Maybe<Scalars['String']>;
  serviceCategory?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  status?: Maybe<MultiPaymentStatusType>;
  user?: Maybe<UserRelateToOneInput>;
  payments?: Maybe<PaymentRelateToManyInput>;
  integration?: Maybe<AcquiringIntegrationRelateToOneInput>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type MultiPaymentWhereInput = {
  AND?: Maybe<Array<Maybe<MultiPaymentWhereInput>>>;
  OR?: Maybe<Array<Maybe<MultiPaymentWhereInput>>>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitFee_not?: Maybe<Scalars['String']>;
  explicitFee_lt?: Maybe<Scalars['String']>;
  explicitFee_lte?: Maybe<Scalars['String']>;
  explicitFee_gt?: Maybe<Scalars['String']>;
  explicitFee_gte?: Maybe<Scalars['String']>;
  explicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  explicitServiceCharge_not?: Maybe<Scalars['String']>;
  explicitServiceCharge_lt?: Maybe<Scalars['String']>;
  explicitServiceCharge_lte?: Maybe<Scalars['String']>;
  explicitServiceCharge_gt?: Maybe<Scalars['String']>;
  explicitServiceCharge_gte?: Maybe<Scalars['String']>;
  explicitServiceCharge_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee?: Maybe<Scalars['String']>;
  serviceFee_not?: Maybe<Scalars['String']>;
  serviceFee_lt?: Maybe<Scalars['String']>;
  serviceFee_lte?: Maybe<Scalars['String']>;
  serviceFee_gt?: Maybe<Scalars['String']>;
  serviceFee_gte?: Maybe<Scalars['String']>;
  serviceFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee?: Maybe<Scalars['String']>;
  implicitFee_not?: Maybe<Scalars['String']>;
  implicitFee_lt?: Maybe<Scalars['String']>;
  implicitFee_lte?: Maybe<Scalars['String']>;
  implicitFee_gt?: Maybe<Scalars['String']>;
  implicitFee_gte?: Maybe<Scalars['String']>;
  implicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountWithoutExplicitFee?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_not?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_lt?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_lte?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_gt?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_gte?: Maybe<Scalars['String']>;
  amountWithoutExplicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amountWithoutExplicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<MultiPaymentCurrencyCodeType>;
  currencyCode_not?: Maybe<MultiPaymentCurrencyCodeType>;
  currencyCode_in?: Maybe<Array<Maybe<MultiPaymentCurrencyCodeType>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<MultiPaymentCurrencyCodeType>>>;
  withdrawnAt?: Maybe<Scalars['String']>;
  withdrawnAt_not?: Maybe<Scalars['String']>;
  withdrawnAt_lt?: Maybe<Scalars['String']>;
  withdrawnAt_lte?: Maybe<Scalars['String']>;
  withdrawnAt_gt?: Maybe<Scalars['String']>;
  withdrawnAt_gte?: Maybe<Scalars['String']>;
  withdrawnAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  withdrawnAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardNumber?: Maybe<Scalars['String']>;
  cardNumber_not?: Maybe<Scalars['String']>;
  cardNumber_contains?: Maybe<Scalars['String']>;
  cardNumber_not_contains?: Maybe<Scalars['String']>;
  cardNumber_starts_with?: Maybe<Scalars['String']>;
  cardNumber_not_starts_with?: Maybe<Scalars['String']>;
  cardNumber_ends_with?: Maybe<Scalars['String']>;
  cardNumber_not_ends_with?: Maybe<Scalars['String']>;
  cardNumber_i?: Maybe<Scalars['String']>;
  cardNumber_not_i?: Maybe<Scalars['String']>;
  cardNumber_contains_i?: Maybe<Scalars['String']>;
  cardNumber_not_contains_i?: Maybe<Scalars['String']>;
  cardNumber_starts_with_i?: Maybe<Scalars['String']>;
  cardNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  cardNumber_ends_with_i?: Maybe<Scalars['String']>;
  cardNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  cardNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  cardNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentWay?: Maybe<MultiPaymentPaymentWayType>;
  paymentWay_not?: Maybe<MultiPaymentPaymentWayType>;
  paymentWay_in?: Maybe<Array<Maybe<MultiPaymentPaymentWayType>>>;
  paymentWay_not_in?: Maybe<Array<Maybe<MultiPaymentPaymentWayType>>>;
  payerEmail?: Maybe<Scalars['String']>;
  payerEmail_not?: Maybe<Scalars['String']>;
  payerEmail_contains?: Maybe<Scalars['String']>;
  payerEmail_not_contains?: Maybe<Scalars['String']>;
  payerEmail_starts_with?: Maybe<Scalars['String']>;
  payerEmail_not_starts_with?: Maybe<Scalars['String']>;
  payerEmail_ends_with?: Maybe<Scalars['String']>;
  payerEmail_not_ends_with?: Maybe<Scalars['String']>;
  payerEmail_i?: Maybe<Scalars['String']>;
  payerEmail_not_i?: Maybe<Scalars['String']>;
  payerEmail_contains_i?: Maybe<Scalars['String']>;
  payerEmail_not_contains_i?: Maybe<Scalars['String']>;
  payerEmail_starts_with_i?: Maybe<Scalars['String']>;
  payerEmail_not_starts_with_i?: Maybe<Scalars['String']>;
  payerEmail_ends_with_i?: Maybe<Scalars['String']>;
  payerEmail_not_ends_with_i?: Maybe<Scalars['String']>;
  payerEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payerEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceCategory?: Maybe<Scalars['String']>;
  serviceCategory_not?: Maybe<Scalars['String']>;
  serviceCategory_contains?: Maybe<Scalars['String']>;
  serviceCategory_not_contains?: Maybe<Scalars['String']>;
  serviceCategory_starts_with?: Maybe<Scalars['String']>;
  serviceCategory_not_starts_with?: Maybe<Scalars['String']>;
  serviceCategory_ends_with?: Maybe<Scalars['String']>;
  serviceCategory_not_ends_with?: Maybe<Scalars['String']>;
  serviceCategory_i?: Maybe<Scalars['String']>;
  serviceCategory_not_i?: Maybe<Scalars['String']>;
  serviceCategory_contains_i?: Maybe<Scalars['String']>;
  serviceCategory_not_contains_i?: Maybe<Scalars['String']>;
  serviceCategory_starts_with_i?: Maybe<Scalars['String']>;
  serviceCategory_not_starts_with_i?: Maybe<Scalars['String']>;
  serviceCategory_ends_with_i?: Maybe<Scalars['String']>;
  serviceCategory_not_ends_with_i?: Maybe<Scalars['String']>;
  serviceCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionId?: Maybe<Scalars['String']>;
  transactionId_not?: Maybe<Scalars['String']>;
  transactionId_contains?: Maybe<Scalars['String']>;
  transactionId_not_contains?: Maybe<Scalars['String']>;
  transactionId_starts_with?: Maybe<Scalars['String']>;
  transactionId_not_starts_with?: Maybe<Scalars['String']>;
  transactionId_ends_with?: Maybe<Scalars['String']>;
  transactionId_not_ends_with?: Maybe<Scalars['String']>;
  transactionId_i?: Maybe<Scalars['String']>;
  transactionId_not_i?: Maybe<Scalars['String']>;
  transactionId_contains_i?: Maybe<Scalars['String']>;
  transactionId_not_contains_i?: Maybe<Scalars['String']>;
  transactionId_starts_with_i?: Maybe<Scalars['String']>;
  transactionId_not_starts_with_i?: Maybe<Scalars['String']>;
  transactionId_ends_with_i?: Maybe<Scalars['String']>;
  transactionId_not_ends_with_i?: Maybe<Scalars['String']>;
  transactionId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transactionId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<MultiPaymentStatusType>;
  status_not?: Maybe<MultiPaymentStatusType>;
  status_in?: Maybe<Array<Maybe<MultiPaymentStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<MultiPaymentStatusType>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  /**  condition must be true for all nodes  */
  payments_every?: Maybe<PaymentWhereInput>;
  /**  condition must be true for at least 1 node  */
  payments_some?: Maybe<PaymentWhereInput>;
  /**  condition must be false for all nodes  */
  payments_none?: Maybe<PaymentWhereInput>;
  integration?: Maybe<AcquiringIntegrationWhereInput>;
  integration_is_null?: Maybe<Scalars['Boolean']>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextWhereInput>;
  recurrentPaymentContext_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type MultiPaymentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type MultiPaymentsCreateInput = {
  data?: Maybe<MultiPaymentCreateInput>;
};

export type MultiPaymentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<MultiPaymentUpdateInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**  Create a single UserHistoryRecord item.  */
  createUserHistoryRecord?: Maybe<UserHistoryRecord>;
  /**  Create multiple UserHistoryRecord items.  */
  createUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>;
  /**  Update a single UserHistoryRecord item by ID.  */
  updateUserHistoryRecord?: Maybe<UserHistoryRecord>;
  /**  Update multiple UserHistoryRecord items by ID.  */
  updateUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>;
  /**  Delete a single UserHistoryRecord item by ID.  */
  deleteUserHistoryRecord?: Maybe<UserHistoryRecord>;
  /**  Delete multiple UserHistoryRecord items by ID.  */
  deleteUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>;
  /**  Create a single User item.  */
  createUser?: Maybe<User>;
  /**  Create multiple User items.  */
  createUsers?: Maybe<Array<Maybe<User>>>;
  /**  Update a single User item by ID.  */
  updateUser?: Maybe<User>;
  /**  Update multiple User items by ID.  */
  updateUsers?: Maybe<Array<Maybe<User>>>;
  /**  Delete a single User item by ID.  */
  deleteUser?: Maybe<User>;
  /**  Delete multiple User items by ID.  */
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  /**  Create a single ConfirmPhoneActionHistoryRecord item.  */
  createConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>;
  /**  Create multiple ConfirmPhoneActionHistoryRecord items.  */
  createConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>;
  /**  Update a single ConfirmPhoneActionHistoryRecord item by ID.  */
  updateConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>;
  /**  Update multiple ConfirmPhoneActionHistoryRecord items by ID.  */
  updateConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>;
  /**  Delete a single ConfirmPhoneActionHistoryRecord item by ID.  */
  deleteConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>;
  /**  Delete multiple ConfirmPhoneActionHistoryRecord items by ID.  */
  deleteConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>;
  /**  Create a single ConfirmPhoneAction item.  */
  createConfirmPhoneAction?: Maybe<ConfirmPhoneAction>;
  /**  Create multiple ConfirmPhoneAction items.  */
  createConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>;
  /**  Update a single ConfirmPhoneAction item by ID.  */
  updateConfirmPhoneAction?: Maybe<ConfirmPhoneAction>;
  /**  Update multiple ConfirmPhoneAction items by ID.  */
  updateConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>;
  /**  Delete a single ConfirmPhoneAction item by ID.  */
  deleteConfirmPhoneAction?: Maybe<ConfirmPhoneAction>;
  /**  Delete multiple ConfirmPhoneAction items by ID.  */
  deleteConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>;
  /**  Create a single OidcClientHistoryRecord item.  */
  createOidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>;
  /**  Create multiple OidcClientHistoryRecord items.  */
  createOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>;
  /**  Update a single OidcClientHistoryRecord item by ID.  */
  updateOidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>;
  /**  Update multiple OidcClientHistoryRecord items by ID.  */
  updateOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>;
  /**  Delete a single OidcClientHistoryRecord item by ID.  */
  deleteOidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>;
  /**  Delete multiple OidcClientHistoryRecord items by ID.  */
  deleteOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>;
  /**  Create a single OidcClient item.  */
  createOidcClient?: Maybe<OidcClient>;
  /**  Create multiple OidcClient items.  */
  createOidcClients?: Maybe<Array<Maybe<OidcClient>>>;
  /**  Update a single OidcClient item by ID.  */
  updateOidcClient?: Maybe<OidcClient>;
  /**  Update multiple OidcClient items by ID.  */
  updateOidcClients?: Maybe<Array<Maybe<OidcClient>>>;
  /**  Delete a single OidcClient item by ID.  */
  deleteOidcClient?: Maybe<OidcClient>;
  /**  Delete multiple OidcClient items by ID.  */
  deleteOidcClients?: Maybe<Array<Maybe<OidcClient>>>;
  /**  Create a single UserExternalIdentityHistoryRecord item.  */
  createUserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>;
  /**  Create multiple UserExternalIdentityHistoryRecord items.  */
  createUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>;
  /**  Update a single UserExternalIdentityHistoryRecord item by ID.  */
  updateUserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>;
  /**  Update multiple UserExternalIdentityHistoryRecord items by ID.  */
  updateUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>;
  /**  Delete a single UserExternalIdentityHistoryRecord item by ID.  */
  deleteUserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>;
  /**  Delete multiple UserExternalIdentityHistoryRecord items by ID.  */
  deleteUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>;
  /**  Create a single UserExternalIdentity item.  */
  createUserExternalIdentity?: Maybe<UserExternalIdentity>;
  /**  Create multiple UserExternalIdentity items.  */
  createUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>;
  /**  Update a single UserExternalIdentity item by ID.  */
  updateUserExternalIdentity?: Maybe<UserExternalIdentity>;
  /**  Update multiple UserExternalIdentity items by ID.  */
  updateUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>;
  /**  Delete a single UserExternalIdentity item by ID.  */
  deleteUserExternalIdentity?: Maybe<UserExternalIdentity>;
  /**  Delete multiple UserExternalIdentity items by ID.  */
  deleteUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>;
  /**  Create a single ExternalTokenAccessRightHistoryRecord item.  */
  createExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>;
  /**  Create multiple ExternalTokenAccessRightHistoryRecord items.  */
  createExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>;
  /**  Update a single ExternalTokenAccessRightHistoryRecord item by ID.  */
  updateExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>;
  /**  Update multiple ExternalTokenAccessRightHistoryRecord items by ID.  */
  updateExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>;
  /**  Delete a single ExternalTokenAccessRightHistoryRecord item by ID.  */
  deleteExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>;
  /**  Delete multiple ExternalTokenAccessRightHistoryRecord items by ID.  */
  deleteExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>;
  /**  Create a single ExternalTokenAccessRight item.  */
  createExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>;
  /**  Create multiple ExternalTokenAccessRight items.  */
  createExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>;
  /**  Update a single ExternalTokenAccessRight item by ID.  */
  updateExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>;
  /**  Update multiple ExternalTokenAccessRight items by ID.  */
  updateExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>;
  /**  Delete a single ExternalTokenAccessRight item by ID.  */
  deleteExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>;
  /**  Delete multiple ExternalTokenAccessRight items by ID.  */
  deleteExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>;
  /**  Create a single UserRightsSetHistoryRecord item.  */
  createUserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>;
  /**  Create multiple UserRightsSetHistoryRecord items.  */
  createUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>;
  /**  Update a single UserRightsSetHistoryRecord item by ID.  */
  updateUserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>;
  /**  Update multiple UserRightsSetHistoryRecord items by ID.  */
  updateUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>;
  /**  Delete a single UserRightsSetHistoryRecord item by ID.  */
  deleteUserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>;
  /**  Delete multiple UserRightsSetHistoryRecord items by ID.  */
  deleteUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>;
  /**  Create a single UserRightsSet item.  */
  createUserRightsSet?: Maybe<UserRightsSet>;
  /**  Create multiple UserRightsSet items.  */
  createUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>;
  /**  Update a single UserRightsSet item by ID.  */
  updateUserRightsSet?: Maybe<UserRightsSet>;
  /**  Update multiple UserRightsSet items by ID.  */
  updateUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>;
  /**  Delete a single UserRightsSet item by ID.  */
  deleteUserRightsSet?: Maybe<UserRightsSet>;
  /**  Delete multiple UserRightsSet items by ID.  */
  deleteUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>;
  /**  Create a single OrganizationHistoryRecord item.  */
  createOrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>;
  /**  Create multiple OrganizationHistoryRecord items.  */
  createOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>;
  /**  Update a single OrganizationHistoryRecord item by ID.  */
  updateOrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>;
  /**  Update multiple OrganizationHistoryRecord items by ID.  */
  updateOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>;
  /**  Delete a single OrganizationHistoryRecord item by ID.  */
  deleteOrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>;
  /**  Delete multiple OrganizationHistoryRecord items by ID.  */
  deleteOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>;
  /**  Create a single Organization item.  */
  createOrganization?: Maybe<Organization>;
  /**  Create multiple Organization items.  */
  createOrganizations?: Maybe<Array<Maybe<Organization>>>;
  /**  Update a single Organization item by ID.  */
  updateOrganization?: Maybe<Organization>;
  /**  Update multiple Organization items by ID.  */
  updateOrganizations?: Maybe<Array<Maybe<Organization>>>;
  /**  Delete a single Organization item by ID.  */
  deleteOrganization?: Maybe<Organization>;
  /**  Delete multiple Organization items by ID.  */
  deleteOrganizations?: Maybe<Array<Maybe<Organization>>>;
  /**  Create a single OrganizationEmployeeHistoryRecord item.  */
  createOrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>;
  /**  Create multiple OrganizationEmployeeHistoryRecord items.  */
  createOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>;
  /**  Update a single OrganizationEmployeeHistoryRecord item by ID.  */
  updateOrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>;
  /**  Update multiple OrganizationEmployeeHistoryRecord items by ID.  */
  updateOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>;
  /**  Delete a single OrganizationEmployeeHistoryRecord item by ID.  */
  deleteOrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>;
  /**  Delete multiple OrganizationEmployeeHistoryRecord items by ID.  */
  deleteOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>;
  /**  Create a single OrganizationEmployee item.  */
  createOrganizationEmployee?: Maybe<OrganizationEmployee>;
  /**  Create multiple OrganizationEmployee items.  */
  createOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>;
  /**  Update a single OrganizationEmployee item by ID.  */
  updateOrganizationEmployee?: Maybe<OrganizationEmployee>;
  /**  Update multiple OrganizationEmployee items by ID.  */
  updateOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>;
  /**  Delete a single OrganizationEmployee item by ID.  */
  deleteOrganizationEmployee?: Maybe<OrganizationEmployee>;
  /**  Delete multiple OrganizationEmployee items by ID.  */
  deleteOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>;
  /**  Create a single OrganizationEmployeeRoleHistoryRecord item.  */
  createOrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>;
  /**  Create multiple OrganizationEmployeeRoleHistoryRecord items.  */
  createOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>;
  /**  Update a single OrganizationEmployeeRoleHistoryRecord item by ID.  */
  updateOrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>;
  /**  Update multiple OrganizationEmployeeRoleHistoryRecord items by ID.  */
  updateOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>;
  /**  Delete a single OrganizationEmployeeRoleHistoryRecord item by ID.  */
  deleteOrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>;
  /**  Delete multiple OrganizationEmployeeRoleHistoryRecord items by ID.  */
  deleteOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>;
  /**  Create a single OrganizationEmployeeRole item.  */
  createOrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>;
  /**  Create multiple OrganizationEmployeeRole items.  */
  createOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>;
  /**  Update a single OrganizationEmployeeRole item by ID.  */
  updateOrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>;
  /**  Update multiple OrganizationEmployeeRole items by ID.  */
  updateOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>;
  /**  Delete a single OrganizationEmployeeRole item by ID.  */
  deleteOrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>;
  /**  Delete multiple OrganizationEmployeeRole items by ID.  */
  deleteOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>;
  /**  Create a single OrganizationLinkHistoryRecord item.  */
  createOrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>;
  /**  Create multiple OrganizationLinkHistoryRecord items.  */
  createOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>;
  /**  Update a single OrganizationLinkHistoryRecord item by ID.  */
  updateOrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>;
  /**  Update multiple OrganizationLinkHistoryRecord items by ID.  */
  updateOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>;
  /**  Delete a single OrganizationLinkHistoryRecord item by ID.  */
  deleteOrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>;
  /**  Delete multiple OrganizationLinkHistoryRecord items by ID.  */
  deleteOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>;
  /**  Create a single OrganizationLink item.  */
  createOrganizationLink?: Maybe<OrganizationLink>;
  /**  Create multiple OrganizationLink items.  */
  createOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>;
  /**  Update a single OrganizationLink item by ID.  */
  updateOrganizationLink?: Maybe<OrganizationLink>;
  /**  Update multiple OrganizationLink items by ID.  */
  updateOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>;
  /**  Delete a single OrganizationLink item by ID.  */
  deleteOrganizationLink?: Maybe<OrganizationLink>;
  /**  Delete multiple OrganizationLink items by ID.  */
  deleteOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>;
  /**  Create a single OrganizationEmployeeSpecializationHistoryRecord item.  */
  createOrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>;
  /**  Create multiple OrganizationEmployeeSpecializationHistoryRecord items.  */
  createOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>;
  /**  Update a single OrganizationEmployeeSpecializationHistoryRecord item by ID.  */
  updateOrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>;
  /**  Update multiple OrganizationEmployeeSpecializationHistoryRecord items by ID.  */
  updateOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>;
  /**  Delete a single OrganizationEmployeeSpecializationHistoryRecord item by ID.  */
  deleteOrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>;
  /**  Delete multiple OrganizationEmployeeSpecializationHistoryRecord items by ID.  */
  deleteOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>;
  /**  Create a single OrganizationEmployeeSpecialization item.  */
  createOrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>;
  /**  Create multiple OrganizationEmployeeSpecialization items.  */
  createOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>;
  /**  Update a single OrganizationEmployeeSpecialization item by ID.  */
  updateOrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>;
  /**  Update multiple OrganizationEmployeeSpecialization items by ID.  */
  updateOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>;
  /**  Delete a single OrganizationEmployeeSpecialization item by ID.  */
  deleteOrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>;
  /**  Delete multiple OrganizationEmployeeSpecialization items by ID.  */
  deleteOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>;
  /**  Create a single PropertyHistoryRecord item.  */
  createPropertyHistoryRecord?: Maybe<PropertyHistoryRecord>;
  /**  Create multiple PropertyHistoryRecord items.  */
  createPropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>;
  /**  Update a single PropertyHistoryRecord item by ID.  */
  updatePropertyHistoryRecord?: Maybe<PropertyHistoryRecord>;
  /**  Update multiple PropertyHistoryRecord items by ID.  */
  updatePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>;
  /**  Delete a single PropertyHistoryRecord item by ID.  */
  deletePropertyHistoryRecord?: Maybe<PropertyHistoryRecord>;
  /**  Delete multiple PropertyHistoryRecord items by ID.  */
  deletePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>;
  /**  Create a single Property item.  */
  createProperty?: Maybe<Property>;
  /**  Create multiple Property items.  */
  createProperties?: Maybe<Array<Maybe<Property>>>;
  /**  Update a single Property item by ID.  */
  updateProperty?: Maybe<Property>;
  /**  Update multiple Property items by ID.  */
  updateProperties?: Maybe<Array<Maybe<Property>>>;
  /**  Delete a single Property item by ID.  */
  deleteProperty?: Maybe<Property>;
  /**  Delete multiple Property items by ID.  */
  deleteProperties?: Maybe<Array<Maybe<Property>>>;
  /**  Create a single BillingIntegrationHistoryRecord item.  */
  createBillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>;
  /**  Create multiple BillingIntegrationHistoryRecord items.  */
  createBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>;
  /**  Update a single BillingIntegrationHistoryRecord item by ID.  */
  updateBillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>;
  /**  Update multiple BillingIntegrationHistoryRecord items by ID.  */
  updateBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>;
  /**  Delete a single BillingIntegrationHistoryRecord item by ID.  */
  deleteBillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>;
  /**  Delete multiple BillingIntegrationHistoryRecord items by ID.  */
  deleteBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>;
  /**  Create a single BillingIntegration item.  */
  createBillingIntegration?: Maybe<BillingIntegration>;
  /**  Create multiple BillingIntegration items.  */
  createBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>;
  /**  Update a single BillingIntegration item by ID.  */
  updateBillingIntegration?: Maybe<BillingIntegration>;
  /**  Update multiple BillingIntegration items by ID.  */
  updateBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>;
  /**  Delete a single BillingIntegration item by ID.  */
  deleteBillingIntegration?: Maybe<BillingIntegration>;
  /**  Delete multiple BillingIntegration items by ID.  */
  deleteBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>;
  /**  Create a single BillingIntegrationAccessRightHistoryRecord item.  */
  createBillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>;
  /**  Create multiple BillingIntegrationAccessRightHistoryRecord items.  */
  createBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>;
  /**  Update a single BillingIntegrationAccessRightHistoryRecord item by ID.  */
  updateBillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>;
  /**  Update multiple BillingIntegrationAccessRightHistoryRecord items by ID.  */
  updateBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>;
  /**  Delete a single BillingIntegrationAccessRightHistoryRecord item by ID.  */
  deleteBillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>;
  /**  Delete multiple BillingIntegrationAccessRightHistoryRecord items by ID.  */
  deleteBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>;
  /**  Create a single BillingIntegrationAccessRight item.  */
  createBillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>;
  /**  Create multiple BillingIntegrationAccessRight items.  */
  createBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>;
  /**  Update a single BillingIntegrationAccessRight item by ID.  */
  updateBillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>;
  /**  Update multiple BillingIntegrationAccessRight items by ID.  */
  updateBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>;
  /**  Delete a single BillingIntegrationAccessRight item by ID.  */
  deleteBillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>;
  /**  Delete multiple BillingIntegrationAccessRight items by ID.  */
  deleteBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>;
  /**  Create a single BillingIntegrationOrganizationContextHistoryRecord item.  */
  createBillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>;
  /**  Create multiple BillingIntegrationOrganizationContextHistoryRecord items.  */
  createBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>;
  /**  Update a single BillingIntegrationOrganizationContextHistoryRecord item by ID.  */
  updateBillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>;
  /**  Update multiple BillingIntegrationOrganizationContextHistoryRecord items by ID.  */
  updateBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>;
  /**  Delete a single BillingIntegrationOrganizationContextHistoryRecord item by ID.  */
  deleteBillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>;
  /**  Delete multiple BillingIntegrationOrganizationContextHistoryRecord items by ID.  */
  deleteBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>;
  /**  Create a single BillingIntegrationOrganizationContext item.  */
  createBillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Create multiple BillingIntegrationOrganizationContext items.  */
  createBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>;
  /**  Update a single BillingIntegrationOrganizationContext item by ID.  */
  updateBillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Update multiple BillingIntegrationOrganizationContext items by ID.  */
  updateBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>;
  /**  Delete a single BillingIntegrationOrganizationContext item by ID.  */
  deleteBillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Delete multiple BillingIntegrationOrganizationContext items by ID.  */
  deleteBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>;
  /**  Create a single BillingIntegrationProblem item.  */
  createBillingIntegrationProblem?: Maybe<BillingIntegrationProblem>;
  /**  Create multiple BillingIntegrationProblem items.  */
  createBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>;
  /**  Update a single BillingIntegrationProblem item by ID.  */
  updateBillingIntegrationProblem?: Maybe<BillingIntegrationProblem>;
  /**  Update multiple BillingIntegrationProblem items by ID.  */
  updateBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>;
  /**  Delete a single BillingIntegrationProblem item by ID.  */
  deleteBillingIntegrationProblem?: Maybe<BillingIntegrationProblem>;
  /**  Delete multiple BillingIntegrationProblem items by ID.  */
  deleteBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>;
  /**  Create a single BillingPropertyHistoryRecord item.  */
  createBillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>;
  /**  Create multiple BillingPropertyHistoryRecord items.  */
  createBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>;
  /**  Update a single BillingPropertyHistoryRecord item by ID.  */
  updateBillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>;
  /**  Update multiple BillingPropertyHistoryRecord items by ID.  */
  updateBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>;
  /**  Delete a single BillingPropertyHistoryRecord item by ID.  */
  deleteBillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>;
  /**  Delete multiple BillingPropertyHistoryRecord items by ID.  */
  deleteBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>;
  /**  Create a single BillingProperty item.  */
  createBillingProperty?: Maybe<BillingProperty>;
  /**  Create multiple BillingProperty items.  */
  createBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>;
  /**  Update a single BillingProperty item by ID.  */
  updateBillingProperty?: Maybe<BillingProperty>;
  /**  Update multiple BillingProperty items by ID.  */
  updateBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>;
  /**  Delete a single BillingProperty item by ID.  */
  deleteBillingProperty?: Maybe<BillingProperty>;
  /**  Delete multiple BillingProperty items by ID.  */
  deleteBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>;
  /**  Create a single BillingAccountHistoryRecord item.  */
  createBillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>;
  /**  Create multiple BillingAccountHistoryRecord items.  */
  createBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>;
  /**  Update a single BillingAccountHistoryRecord item by ID.  */
  updateBillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>;
  /**  Update multiple BillingAccountHistoryRecord items by ID.  */
  updateBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>;
  /**  Delete a single BillingAccountHistoryRecord item by ID.  */
  deleteBillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>;
  /**  Delete multiple BillingAccountHistoryRecord items by ID.  */
  deleteBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>;
  /**  Create a single BillingAccount item.  */
  createBillingAccount?: Maybe<BillingAccount>;
  /**  Create multiple BillingAccount items.  */
  createBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>;
  /**  Update a single BillingAccount item by ID.  */
  updateBillingAccount?: Maybe<BillingAccount>;
  /**  Update multiple BillingAccount items by ID.  */
  updateBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>;
  /**  Delete a single BillingAccount item by ID.  */
  deleteBillingAccount?: Maybe<BillingAccount>;
  /**  Delete multiple BillingAccount items by ID.  */
  deleteBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>;
  /**  Create a single BillingReceiptHistoryRecord item.  */
  createBillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>;
  /**  Create multiple BillingReceiptHistoryRecord items.  */
  createBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>;
  /**  Update a single BillingReceiptHistoryRecord item by ID.  */
  updateBillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>;
  /**  Update multiple BillingReceiptHistoryRecord items by ID.  */
  updateBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>;
  /**  Delete a single BillingReceiptHistoryRecord item by ID.  */
  deleteBillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>;
  /**  Delete multiple BillingReceiptHistoryRecord items by ID.  */
  deleteBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>;
  /**  Create a single BillingReceipt item.  */
  createBillingReceipt?: Maybe<BillingReceipt>;
  /**  Create multiple BillingReceipt items.  */
  createBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>;
  /**  Update a single BillingReceipt item by ID.  */
  updateBillingReceipt?: Maybe<BillingReceipt>;
  /**  Update multiple BillingReceipt items by ID.  */
  updateBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>;
  /**  Delete a single BillingReceipt item by ID.  */
  deleteBillingReceipt?: Maybe<BillingReceipt>;
  /**  Delete multiple BillingReceipt items by ID.  */
  deleteBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>;
  /**  Create a single BillingRecipientHistoryRecord item.  */
  createBillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>;
  /**  Create multiple BillingRecipientHistoryRecord items.  */
  createBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>;
  /**  Update a single BillingRecipientHistoryRecord item by ID.  */
  updateBillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>;
  /**  Update multiple BillingRecipientHistoryRecord items by ID.  */
  updateBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>;
  /**  Delete a single BillingRecipientHistoryRecord item by ID.  */
  deleteBillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>;
  /**  Delete multiple BillingRecipientHistoryRecord items by ID.  */
  deleteBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>;
  /**  Create a single BillingRecipient item.  */
  createBillingRecipient?: Maybe<BillingRecipient>;
  /**  Create multiple BillingRecipient items.  */
  createBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>;
  /**  Update a single BillingRecipient item by ID.  */
  updateBillingRecipient?: Maybe<BillingRecipient>;
  /**  Update multiple BillingRecipient items by ID.  */
  updateBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>;
  /**  Delete a single BillingRecipient item by ID.  */
  deleteBillingRecipient?: Maybe<BillingRecipient>;
  /**  Delete multiple BillingRecipient items by ID.  */
  deleteBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>;
  /**  Create a single BillingCategoryHistoryRecord item.  */
  createBillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>;
  /**  Create multiple BillingCategoryHistoryRecord items.  */
  createBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>;
  /**  Update a single BillingCategoryHistoryRecord item by ID.  */
  updateBillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>;
  /**  Update multiple BillingCategoryHistoryRecord items by ID.  */
  updateBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>;
  /**  Delete a single BillingCategoryHistoryRecord item by ID.  */
  deleteBillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>;
  /**  Delete multiple BillingCategoryHistoryRecord items by ID.  */
  deleteBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>;
  /**  Create a single BillingCategory item.  */
  createBillingCategory?: Maybe<BillingCategory>;
  /**  Create multiple BillingCategory items.  */
  createBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>;
  /**  Update a single BillingCategory item by ID.  */
  updateBillingCategory?: Maybe<BillingCategory>;
  /**  Update multiple BillingCategory items by ID.  */
  updateBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>;
  /**  Delete a single BillingCategory item by ID.  */
  deleteBillingCategory?: Maybe<BillingCategory>;
  /**  Delete multiple BillingCategory items by ID.  */
  deleteBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>;
  /**  Create a single BillingReceiptFileHistoryRecord item.  */
  createBillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>;
  /**  Create multiple BillingReceiptFileHistoryRecord items.  */
  createBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>;
  /**  Update a single BillingReceiptFileHistoryRecord item by ID.  */
  updateBillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>;
  /**  Update multiple BillingReceiptFileHistoryRecord items by ID.  */
  updateBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>;
  /**  Delete a single BillingReceiptFileHistoryRecord item by ID.  */
  deleteBillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>;
  /**  Delete multiple BillingReceiptFileHistoryRecord items by ID.  */
  deleteBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>;
  /**  Create a single BillingReceiptFile item.  */
  createBillingReceiptFile?: Maybe<BillingReceiptFile>;
  /**  Create multiple BillingReceiptFile items.  */
  createBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>;
  /**  Update a single BillingReceiptFile item by ID.  */
  updateBillingReceiptFile?: Maybe<BillingReceiptFile>;
  /**  Update multiple BillingReceiptFile items by ID.  */
  updateBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>;
  /**  Delete a single BillingReceiptFile item by ID.  */
  deleteBillingReceiptFile?: Maybe<BillingReceiptFile>;
  /**  Delete multiple BillingReceiptFile items by ID.  */
  deleteBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>;
  /**  Create a single BankAccountHistoryRecord item.  */
  createBankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>;
  /**  Create multiple BankAccountHistoryRecord items.  */
  createBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>;
  /**  Update a single BankAccountHistoryRecord item by ID.  */
  updateBankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>;
  /**  Update multiple BankAccountHistoryRecord items by ID.  */
  updateBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>;
  /**  Delete a single BankAccountHistoryRecord item by ID.  */
  deleteBankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>;
  /**  Delete multiple BankAccountHistoryRecord items by ID.  */
  deleteBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>;
  /**  Create a single BankAccount item.  */
  createBankAccount?: Maybe<BankAccount>;
  /**  Create multiple BankAccount items.  */
  createBankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  /**  Update a single BankAccount item by ID.  */
  updateBankAccount?: Maybe<BankAccount>;
  /**  Update multiple BankAccount items by ID.  */
  updateBankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  /**  Delete a single BankAccount item by ID.  */
  deleteBankAccount?: Maybe<BankAccount>;
  /**  Delete multiple BankAccount items by ID.  */
  deleteBankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  /**  Create a single BankCategoryHistoryRecord item.  */
  createBankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>;
  /**  Create multiple BankCategoryHistoryRecord items.  */
  createBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>;
  /**  Update a single BankCategoryHistoryRecord item by ID.  */
  updateBankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>;
  /**  Update multiple BankCategoryHistoryRecord items by ID.  */
  updateBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>;
  /**  Delete a single BankCategoryHistoryRecord item by ID.  */
  deleteBankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>;
  /**  Delete multiple BankCategoryHistoryRecord items by ID.  */
  deleteBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>;
  /**  Create a single BankCategory item.  */
  createBankCategory?: Maybe<BankCategory>;
  /**  Create multiple BankCategory items.  */
  createBankCategories?: Maybe<Array<Maybe<BankCategory>>>;
  /**  Update a single BankCategory item by ID.  */
  updateBankCategory?: Maybe<BankCategory>;
  /**  Update multiple BankCategory items by ID.  */
  updateBankCategories?: Maybe<Array<Maybe<BankCategory>>>;
  /**  Delete a single BankCategory item by ID.  */
  deleteBankCategory?: Maybe<BankCategory>;
  /**  Delete multiple BankCategory items by ID.  */
  deleteBankCategories?: Maybe<Array<Maybe<BankCategory>>>;
  /**  Create a single BankCostItemHistoryRecord item.  */
  createBankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>;
  /**  Create multiple BankCostItemHistoryRecord items.  */
  createBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>;
  /**  Update a single BankCostItemHistoryRecord item by ID.  */
  updateBankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>;
  /**  Update multiple BankCostItemHistoryRecord items by ID.  */
  updateBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>;
  /**  Delete a single BankCostItemHistoryRecord item by ID.  */
  deleteBankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>;
  /**  Delete multiple BankCostItemHistoryRecord items by ID.  */
  deleteBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>;
  /**  Create a single BankCostItem item.  */
  createBankCostItem?: Maybe<BankCostItem>;
  /**  Create multiple BankCostItem items.  */
  createBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>;
  /**  Update a single BankCostItem item by ID.  */
  updateBankCostItem?: Maybe<BankCostItem>;
  /**  Update multiple BankCostItem items by ID.  */
  updateBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>;
  /**  Delete a single BankCostItem item by ID.  */
  deleteBankCostItem?: Maybe<BankCostItem>;
  /**  Delete multiple BankCostItem items by ID.  */
  deleteBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>;
  /**  Create a single BankContractorAccountHistoryRecord item.  */
  createBankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>;
  /**  Create multiple BankContractorAccountHistoryRecord items.  */
  createBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>;
  /**  Update a single BankContractorAccountHistoryRecord item by ID.  */
  updateBankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>;
  /**  Update multiple BankContractorAccountHistoryRecord items by ID.  */
  updateBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>;
  /**  Delete a single BankContractorAccountHistoryRecord item by ID.  */
  deleteBankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>;
  /**  Delete multiple BankContractorAccountHistoryRecord items by ID.  */
  deleteBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>;
  /**  Create a single BankContractorAccount item.  */
  createBankContractorAccount?: Maybe<BankContractorAccount>;
  /**  Create multiple BankContractorAccount items.  */
  createBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>;
  /**  Update a single BankContractorAccount item by ID.  */
  updateBankContractorAccount?: Maybe<BankContractorAccount>;
  /**  Update multiple BankContractorAccount items by ID.  */
  updateBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>;
  /**  Delete a single BankContractorAccount item by ID.  */
  deleteBankContractorAccount?: Maybe<BankContractorAccount>;
  /**  Delete multiple BankContractorAccount items by ID.  */
  deleteBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>;
  /**  Create a single BankIntegrationHistoryRecord item.  */
  createBankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>;
  /**  Create multiple BankIntegrationHistoryRecord items.  */
  createBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>;
  /**  Update a single BankIntegrationHistoryRecord item by ID.  */
  updateBankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>;
  /**  Update multiple BankIntegrationHistoryRecord items by ID.  */
  updateBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>;
  /**  Delete a single BankIntegrationHistoryRecord item by ID.  */
  deleteBankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>;
  /**  Delete multiple BankIntegrationHistoryRecord items by ID.  */
  deleteBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>;
  /**  Create a single BankIntegration item.  */
  createBankIntegration?: Maybe<BankIntegration>;
  /**  Create multiple BankIntegration items.  */
  createBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>;
  /**  Update a single BankIntegration item by ID.  */
  updateBankIntegration?: Maybe<BankIntegration>;
  /**  Update multiple BankIntegration items by ID.  */
  updateBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>;
  /**  Delete a single BankIntegration item by ID.  */
  deleteBankIntegration?: Maybe<BankIntegration>;
  /**  Delete multiple BankIntegration items by ID.  */
  deleteBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>;
  /**  Create a single BankIntegrationAccessRightHistoryRecord item.  */
  createBankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>;
  /**  Create multiple BankIntegrationAccessRightHistoryRecord items.  */
  createBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>;
  /**  Update a single BankIntegrationAccessRightHistoryRecord item by ID.  */
  updateBankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>;
  /**  Update multiple BankIntegrationAccessRightHistoryRecord items by ID.  */
  updateBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>;
  /**  Delete a single BankIntegrationAccessRightHistoryRecord item by ID.  */
  deleteBankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>;
  /**  Delete multiple BankIntegrationAccessRightHistoryRecord items by ID.  */
  deleteBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>;
  /**  Create a single BankIntegrationAccessRight item.  */
  createBankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>;
  /**  Create multiple BankIntegrationAccessRight items.  */
  createBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>;
  /**  Update a single BankIntegrationAccessRight item by ID.  */
  updateBankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>;
  /**  Update multiple BankIntegrationAccessRight items by ID.  */
  updateBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>;
  /**  Delete a single BankIntegrationAccessRight item by ID.  */
  deleteBankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>;
  /**  Delete multiple BankIntegrationAccessRight items by ID.  */
  deleteBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>;
  /**  Create a single BankIntegrationAccountContextHistoryRecord item.  */
  createBankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>;
  /**  Create multiple BankIntegrationAccountContextHistoryRecord items.  */
  createBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>;
  /**  Update a single BankIntegrationAccountContextHistoryRecord item by ID.  */
  updateBankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>;
  /**  Update multiple BankIntegrationAccountContextHistoryRecord items by ID.  */
  updateBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>;
  /**  Delete a single BankIntegrationAccountContextHistoryRecord item by ID.  */
  deleteBankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>;
  /**  Delete multiple BankIntegrationAccountContextHistoryRecord items by ID.  */
  deleteBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>;
  /**  Create a single BankIntegrationAccountContext item.  */
  createBankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>;
  /**  Create multiple BankIntegrationAccountContext items.  */
  createBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>;
  /**  Update a single BankIntegrationAccountContext item by ID.  */
  updateBankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>;
  /**  Update multiple BankIntegrationAccountContext items by ID.  */
  updateBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>;
  /**  Delete a single BankIntegrationAccountContext item by ID.  */
  deleteBankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>;
  /**  Delete multiple BankIntegrationAccountContext items by ID.  */
  deleteBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>;
  /**  Create a single BankTransactionHistoryRecord item.  */
  createBankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>;
  /**  Create multiple BankTransactionHistoryRecord items.  */
  createBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>;
  /**  Update a single BankTransactionHistoryRecord item by ID.  */
  updateBankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>;
  /**  Update multiple BankTransactionHistoryRecord items by ID.  */
  updateBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>;
  /**  Delete a single BankTransactionHistoryRecord item by ID.  */
  deleteBankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>;
  /**  Delete multiple BankTransactionHistoryRecord items by ID.  */
  deleteBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>;
  /**  Create a single BankTransaction item.  */
  createBankTransaction?: Maybe<BankTransaction>;
  /**  Create multiple BankTransaction items.  */
  createBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>;
  /**  Update a single BankTransaction item by ID.  */
  updateBankTransaction?: Maybe<BankTransaction>;
  /**  Update multiple BankTransaction items by ID.  */
  updateBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>;
  /**  Delete a single BankTransaction item by ID.  */
  deleteBankTransaction?: Maybe<BankTransaction>;
  /**  Delete multiple BankTransaction items by ID.  */
  deleteBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>;
  /**  Create a single BankSyncTaskHistoryRecord item.  */
  createBankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>;
  /**  Create multiple BankSyncTaskHistoryRecord items.  */
  createBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>;
  /**  Update a single BankSyncTaskHistoryRecord item by ID.  */
  updateBankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>;
  /**  Update multiple BankSyncTaskHistoryRecord items by ID.  */
  updateBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>;
  /**  Delete a single BankSyncTaskHistoryRecord item by ID.  */
  deleteBankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>;
  /**  Delete multiple BankSyncTaskHistoryRecord items by ID.  */
  deleteBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>;
  /**  Create a single BankSyncTask item.  */
  createBankSyncTask?: Maybe<BankSyncTask>;
  /**  Create multiple BankSyncTask items.  */
  createBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>;
  /**  Update a single BankSyncTask item by ID.  */
  updateBankSyncTask?: Maybe<BankSyncTask>;
  /**  Update multiple BankSyncTask items by ID.  */
  updateBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>;
  /**  Delete a single BankSyncTask item by ID.  */
  deleteBankSyncTask?: Maybe<BankSyncTask>;
  /**  Delete multiple BankSyncTask items by ID.  */
  deleteBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>;
  /**  Create a single BankIntegrationOrganizationContextHistoryRecord item.  */
  createBankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>;
  /**  Create multiple BankIntegrationOrganizationContextHistoryRecord items.  */
  createBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>;
  /**  Update a single BankIntegrationOrganizationContextHistoryRecord item by ID.  */
  updateBankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>;
  /**  Update multiple BankIntegrationOrganizationContextHistoryRecord items by ID.  */
  updateBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>;
  /**  Delete a single BankIntegrationOrganizationContextHistoryRecord item by ID.  */
  deleteBankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>;
  /**  Delete multiple BankIntegrationOrganizationContextHistoryRecord items by ID.  */
  deleteBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>;
  /**  Create a single BankIntegrationOrganizationContext item.  */
  createBankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>;
  /**  Create multiple BankIntegrationOrganizationContext items.  */
  createBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>;
  /**  Update a single BankIntegrationOrganizationContext item by ID.  */
  updateBankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>;
  /**  Update multiple BankIntegrationOrganizationContext items by ID.  */
  updateBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>;
  /**  Delete a single BankIntegrationOrganizationContext item by ID.  */
  deleteBankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>;
  /**  Delete multiple BankIntegrationOrganizationContext items by ID.  */
  deleteBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>;
  /**  Create a single BankAccountReportHistoryRecord item.  */
  createBankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>;
  /**  Create multiple BankAccountReportHistoryRecord items.  */
  createBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>;
  /**  Update a single BankAccountReportHistoryRecord item by ID.  */
  updateBankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>;
  /**  Update multiple BankAccountReportHistoryRecord items by ID.  */
  updateBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>;
  /**  Delete a single BankAccountReportHistoryRecord item by ID.  */
  deleteBankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>;
  /**  Delete multiple BankAccountReportHistoryRecord items by ID.  */
  deleteBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>;
  /**  Create a single BankAccountReport item.  */
  createBankAccountReport?: Maybe<BankAccountReport>;
  /**  Create multiple BankAccountReport items.  */
  createBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>;
  /**  Update a single BankAccountReport item by ID.  */
  updateBankAccountReport?: Maybe<BankAccountReport>;
  /**  Update multiple BankAccountReport items by ID.  */
  updateBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>;
  /**  Delete a single BankAccountReport item by ID.  */
  deleteBankAccountReport?: Maybe<BankAccountReport>;
  /**  Delete multiple BankAccountReport items by ID.  */
  deleteBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>;
  /**  Create a single BankAccountReportTaskHistoryRecord item.  */
  createBankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>;
  /**  Create multiple BankAccountReportTaskHistoryRecord items.  */
  createBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>;
  /**  Update a single BankAccountReportTaskHistoryRecord item by ID.  */
  updateBankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>;
  /**  Update multiple BankAccountReportTaskHistoryRecord items by ID.  */
  updateBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>;
  /**  Delete a single BankAccountReportTaskHistoryRecord item by ID.  */
  deleteBankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>;
  /**  Delete multiple BankAccountReportTaskHistoryRecord items by ID.  */
  deleteBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>;
  /**  Create a single BankAccountReportTask item.  */
  createBankAccountReportTask?: Maybe<BankAccountReportTask>;
  /**  Create multiple BankAccountReportTask items.  */
  createBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>;
  /**  Update a single BankAccountReportTask item by ID.  */
  updateBankAccountReportTask?: Maybe<BankAccountReportTask>;
  /**  Update multiple BankAccountReportTask items by ID.  */
  updateBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>;
  /**  Delete a single BankAccountReportTask item by ID.  */
  deleteBankAccountReportTask?: Maybe<BankAccountReportTask>;
  /**  Delete multiple BankAccountReportTask items by ID.  */
  deleteBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>;
  /**  Create a single TicketHistoryRecord item.  */
  createTicketHistoryRecord?: Maybe<TicketHistoryRecord>;
  /**  Create multiple TicketHistoryRecord items.  */
  createTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>;
  /**  Update a single TicketHistoryRecord item by ID.  */
  updateTicketHistoryRecord?: Maybe<TicketHistoryRecord>;
  /**  Update multiple TicketHistoryRecord items by ID.  */
  updateTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>;
  /**  Delete a single TicketHistoryRecord item by ID.  */
  deleteTicketHistoryRecord?: Maybe<TicketHistoryRecord>;
  /**  Delete multiple TicketHistoryRecord items by ID.  */
  deleteTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>;
  /**  Create a single Ticket item.  */
  createTicket?: Maybe<Ticket>;
  /**  Create multiple Ticket items.  */
  createTickets?: Maybe<Array<Maybe<Ticket>>>;
  /**  Update a single Ticket item by ID.  */
  updateTicket?: Maybe<Ticket>;
  /**  Update multiple Ticket items by ID.  */
  updateTickets?: Maybe<Array<Maybe<Ticket>>>;
  /**  Delete a single Ticket item by ID.  */
  deleteTicket?: Maybe<Ticket>;
  /**  Delete multiple Ticket items by ID.  */
  deleteTickets?: Maybe<Array<Maybe<Ticket>>>;
  /**  Create a single TicketSourceHistoryRecord item.  */
  createTicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>;
  /**  Create multiple TicketSourceHistoryRecord items.  */
  createTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>;
  /**  Update a single TicketSourceHistoryRecord item by ID.  */
  updateTicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>;
  /**  Update multiple TicketSourceHistoryRecord items by ID.  */
  updateTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>;
  /**  Delete a single TicketSourceHistoryRecord item by ID.  */
  deleteTicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>;
  /**  Delete multiple TicketSourceHistoryRecord items by ID.  */
  deleteTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>;
  /**  Create a single TicketSource item.  */
  createTicketSource?: Maybe<TicketSource>;
  /**  Create multiple TicketSource items.  */
  createTicketSources?: Maybe<Array<Maybe<TicketSource>>>;
  /**  Update a single TicketSource item by ID.  */
  updateTicketSource?: Maybe<TicketSource>;
  /**  Update multiple TicketSource items by ID.  */
  updateTicketSources?: Maybe<Array<Maybe<TicketSource>>>;
  /**  Delete a single TicketSource item by ID.  */
  deleteTicketSource?: Maybe<TicketSource>;
  /**  Delete multiple TicketSource items by ID.  */
  deleteTicketSources?: Maybe<Array<Maybe<TicketSource>>>;
  /**  Create a single TicketStatusHistoryRecord item.  */
  createTicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>;
  /**  Create multiple TicketStatusHistoryRecord items.  */
  createTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>;
  /**  Update a single TicketStatusHistoryRecord item by ID.  */
  updateTicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>;
  /**  Update multiple TicketStatusHistoryRecord items by ID.  */
  updateTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>;
  /**  Delete a single TicketStatusHistoryRecord item by ID.  */
  deleteTicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>;
  /**  Delete multiple TicketStatusHistoryRecord items by ID.  */
  deleteTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>;
  /**  Create a single TicketStatus item.  */
  createTicketStatus?: Maybe<TicketStatus>;
  /**  Create multiple TicketStatus items.  */
  createTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>;
  /**  Update a single TicketStatus item by ID.  */
  updateTicketStatus?: Maybe<TicketStatus>;
  /**  Update multiple TicketStatus items by ID.  */
  updateTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>;
  /**  Delete a single TicketStatus item by ID.  */
  deleteTicketStatus?: Maybe<TicketStatus>;
  /**  Delete multiple TicketStatus items by ID.  */
  deleteTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>;
  /**  Create a single TicketFileHistoryRecord item.  */
  createTicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>;
  /**  Create multiple TicketFileHistoryRecord items.  */
  createTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>;
  /**  Update a single TicketFileHistoryRecord item by ID.  */
  updateTicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>;
  /**  Update multiple TicketFileHistoryRecord items by ID.  */
  updateTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>;
  /**  Delete a single TicketFileHistoryRecord item by ID.  */
  deleteTicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>;
  /**  Delete multiple TicketFileHistoryRecord items by ID.  */
  deleteTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>;
  /**  Create a single TicketFile item.  */
  createTicketFile?: Maybe<TicketFile>;
  /**  Create multiple TicketFile items.  */
  createTicketFiles?: Maybe<Array<Maybe<TicketFile>>>;
  /**  Update a single TicketFile item by ID.  */
  updateTicketFile?: Maybe<TicketFile>;
  /**  Update multiple TicketFile items by ID.  */
  updateTicketFiles?: Maybe<Array<Maybe<TicketFile>>>;
  /**  Delete a single TicketFile item by ID.  */
  deleteTicketFile?: Maybe<TicketFile>;
  /**  Delete multiple TicketFile items by ID.  */
  deleteTicketFiles?: Maybe<Array<Maybe<TicketFile>>>;
  /**  Create a single TicketChange item.  */
  createTicketChange?: Maybe<TicketChange>;
  /**  Create multiple TicketChange items.  */
  createTicketChanges?: Maybe<Array<Maybe<TicketChange>>>;
  /**  Update a single TicketChange item by ID.  */
  updateTicketChange?: Maybe<TicketChange>;
  /**  Update multiple TicketChange items by ID.  */
  updateTicketChanges?: Maybe<Array<Maybe<TicketChange>>>;
  /**  Delete a single TicketChange item by ID.  */
  deleteTicketChange?: Maybe<TicketChange>;
  /**  Delete multiple TicketChange items by ID.  */
  deleteTicketChanges?: Maybe<Array<Maybe<TicketChange>>>;
  /**  Create a single TicketCommentHistoryRecord item.  */
  createTicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>;
  /**  Create multiple TicketCommentHistoryRecord items.  */
  createTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>;
  /**  Update a single TicketCommentHistoryRecord item by ID.  */
  updateTicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>;
  /**  Update multiple TicketCommentHistoryRecord items by ID.  */
  updateTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>;
  /**  Delete a single TicketCommentHistoryRecord item by ID.  */
  deleteTicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>;
  /**  Delete multiple TicketCommentHistoryRecord items by ID.  */
  deleteTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>;
  /**  Create a single TicketComment item.  */
  createTicketComment?: Maybe<TicketComment>;
  /**  Create multiple TicketComment items.  */
  createTicketComments?: Maybe<Array<Maybe<TicketComment>>>;
  /**  Update a single TicketComment item by ID.  */
  updateTicketComment?: Maybe<TicketComment>;
  /**  Update multiple TicketComment items by ID.  */
  updateTicketComments?: Maybe<Array<Maybe<TicketComment>>>;
  /**  Delete a single TicketComment item by ID.  */
  deleteTicketComment?: Maybe<TicketComment>;
  /**  Delete multiple TicketComment items by ID.  */
  deleteTicketComments?: Maybe<Array<Maybe<TicketComment>>>;
  /**  Create a single TicketPlaceClassifierHistoryRecord item.  */
  createTicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>;
  /**  Create multiple TicketPlaceClassifierHistoryRecord items.  */
  createTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>;
  /**  Update a single TicketPlaceClassifierHistoryRecord item by ID.  */
  updateTicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>;
  /**  Update multiple TicketPlaceClassifierHistoryRecord items by ID.  */
  updateTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>;
  /**  Delete a single TicketPlaceClassifierHistoryRecord item by ID.  */
  deleteTicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>;
  /**  Delete multiple TicketPlaceClassifierHistoryRecord items by ID.  */
  deleteTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>;
  /**  Create a single TicketPlaceClassifier item.  */
  createTicketPlaceClassifier?: Maybe<TicketPlaceClassifier>;
  /**  Create multiple TicketPlaceClassifier items.  */
  createTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>;
  /**  Update a single TicketPlaceClassifier item by ID.  */
  updateTicketPlaceClassifier?: Maybe<TicketPlaceClassifier>;
  /**  Update multiple TicketPlaceClassifier items by ID.  */
  updateTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>;
  /**  Delete a single TicketPlaceClassifier item by ID.  */
  deleteTicketPlaceClassifier?: Maybe<TicketPlaceClassifier>;
  /**  Delete multiple TicketPlaceClassifier items by ID.  */
  deleteTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>;
  /**  Create a single TicketCategoryClassifierHistoryRecord item.  */
  createTicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>;
  /**  Create multiple TicketCategoryClassifierHistoryRecord items.  */
  createTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>;
  /**  Update a single TicketCategoryClassifierHistoryRecord item by ID.  */
  updateTicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>;
  /**  Update multiple TicketCategoryClassifierHistoryRecord items by ID.  */
  updateTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>;
  /**  Delete a single TicketCategoryClassifierHistoryRecord item by ID.  */
  deleteTicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>;
  /**  Delete multiple TicketCategoryClassifierHistoryRecord items by ID.  */
  deleteTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>;
  /**  Create a single TicketCategoryClassifier item.  */
  createTicketCategoryClassifier?: Maybe<TicketCategoryClassifier>;
  /**  Create multiple TicketCategoryClassifier items.  */
  createTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>;
  /**  Update a single TicketCategoryClassifier item by ID.  */
  updateTicketCategoryClassifier?: Maybe<TicketCategoryClassifier>;
  /**  Update multiple TicketCategoryClassifier items by ID.  */
  updateTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>;
  /**  Delete a single TicketCategoryClassifier item by ID.  */
  deleteTicketCategoryClassifier?: Maybe<TicketCategoryClassifier>;
  /**  Delete multiple TicketCategoryClassifier items by ID.  */
  deleteTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>;
  /**  Create a single TicketProblemClassifierHistoryRecord item.  */
  createTicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>;
  /**  Create multiple TicketProblemClassifierHistoryRecord items.  */
  createTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>;
  /**  Update a single TicketProblemClassifierHistoryRecord item by ID.  */
  updateTicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>;
  /**  Update multiple TicketProblemClassifierHistoryRecord items by ID.  */
  updateTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>;
  /**  Delete a single TicketProblemClassifierHistoryRecord item by ID.  */
  deleteTicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>;
  /**  Delete multiple TicketProblemClassifierHistoryRecord items by ID.  */
  deleteTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>;
  /**  Create a single TicketProblemClassifier item.  */
  createTicketProblemClassifier?: Maybe<TicketProblemClassifier>;
  /**  Create multiple TicketProblemClassifier items.  */
  createTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>;
  /**  Update a single TicketProblemClassifier item by ID.  */
  updateTicketProblemClassifier?: Maybe<TicketProblemClassifier>;
  /**  Update multiple TicketProblemClassifier items by ID.  */
  updateTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>;
  /**  Delete a single TicketProblemClassifier item by ID.  */
  deleteTicketProblemClassifier?: Maybe<TicketProblemClassifier>;
  /**  Delete multiple TicketProblemClassifier items by ID.  */
  deleteTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>;
  /**  Create a single TicketClassifierHistoryRecord item.  */
  createTicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>;
  /**  Create multiple TicketClassifierHistoryRecord items.  */
  createTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>;
  /**  Update a single TicketClassifierHistoryRecord item by ID.  */
  updateTicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>;
  /**  Update multiple TicketClassifierHistoryRecord items by ID.  */
  updateTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>;
  /**  Delete a single TicketClassifierHistoryRecord item by ID.  */
  deleteTicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>;
  /**  Delete multiple TicketClassifierHistoryRecord items by ID.  */
  deleteTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>;
  /**  Create a single TicketClassifier item.  */
  createTicketClassifier?: Maybe<TicketClassifier>;
  /**  Create multiple TicketClassifier items.  */
  createTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>;
  /**  Update a single TicketClassifier item by ID.  */
  updateTicketClassifier?: Maybe<TicketClassifier>;
  /**  Update multiple TicketClassifier items by ID.  */
  updateTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>;
  /**  Delete a single TicketClassifier item by ID.  */
  deleteTicketClassifier?: Maybe<TicketClassifier>;
  /**  Delete multiple TicketClassifier items by ID.  */
  deleteTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>;
  /**  Create a single TicketFilterTemplateHistoryRecord item.  */
  createTicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>;
  /**  Create multiple TicketFilterTemplateHistoryRecord items.  */
  createTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>;
  /**  Update a single TicketFilterTemplateHistoryRecord item by ID.  */
  updateTicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>;
  /**  Update multiple TicketFilterTemplateHistoryRecord items by ID.  */
  updateTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>;
  /**  Delete a single TicketFilterTemplateHistoryRecord item by ID.  */
  deleteTicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>;
  /**  Delete multiple TicketFilterTemplateHistoryRecord items by ID.  */
  deleteTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>;
  /**  Create a single TicketFilterTemplate item.  */
  createTicketFilterTemplate?: Maybe<TicketFilterTemplate>;
  /**  Create multiple TicketFilterTemplate items.  */
  createTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>;
  /**  Update a single TicketFilterTemplate item by ID.  */
  updateTicketFilterTemplate?: Maybe<TicketFilterTemplate>;
  /**  Update multiple TicketFilterTemplate items by ID.  */
  updateTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>;
  /**  Delete a single TicketFilterTemplate item by ID.  */
  deleteTicketFilterTemplate?: Maybe<TicketFilterTemplate>;
  /**  Delete multiple TicketFilterTemplate items by ID.  */
  deleteTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>;
  /**  Create a single TicketCommentFileHistoryRecord item.  */
  createTicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>;
  /**  Create multiple TicketCommentFileHistoryRecord items.  */
  createTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>;
  /**  Update a single TicketCommentFileHistoryRecord item by ID.  */
  updateTicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>;
  /**  Update multiple TicketCommentFileHistoryRecord items by ID.  */
  updateTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>;
  /**  Delete a single TicketCommentFileHistoryRecord item by ID.  */
  deleteTicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>;
  /**  Delete multiple TicketCommentFileHistoryRecord items by ID.  */
  deleteTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>;
  /**  Create a single TicketCommentFile item.  */
  createTicketCommentFile?: Maybe<TicketCommentFile>;
  /**  Create multiple TicketCommentFile items.  */
  createTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>;
  /**  Update a single TicketCommentFile item by ID.  */
  updateTicketCommentFile?: Maybe<TicketCommentFile>;
  /**  Update multiple TicketCommentFile items by ID.  */
  updateTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>;
  /**  Delete a single TicketCommentFile item by ID.  */
  deleteTicketCommentFile?: Maybe<TicketCommentFile>;
  /**  Delete multiple TicketCommentFile items by ID.  */
  deleteTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>;
  /**  Create a single UserTicketCommentReadTimeHistoryRecord item.  */
  createUserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>;
  /**  Create multiple UserTicketCommentReadTimeHistoryRecord items.  */
  createUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>;
  /**  Update a single UserTicketCommentReadTimeHistoryRecord item by ID.  */
  updateUserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>;
  /**  Update multiple UserTicketCommentReadTimeHistoryRecord items by ID.  */
  updateUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>;
  /**  Delete a single UserTicketCommentReadTimeHistoryRecord item by ID.  */
  deleteUserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>;
  /**  Delete multiple UserTicketCommentReadTimeHistoryRecord items by ID.  */
  deleteUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>;
  /**  Create a single UserTicketCommentReadTime item.  */
  createUserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>;
  /**  Create multiple UserTicketCommentReadTime items.  */
  createUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>;
  /**  Update a single UserTicketCommentReadTime item by ID.  */
  updateUserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>;
  /**  Update multiple UserTicketCommentReadTime items by ID.  */
  updateUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>;
  /**  Delete a single UserTicketCommentReadTime item by ID.  */
  deleteUserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>;
  /**  Delete multiple UserTicketCommentReadTime items by ID.  */
  deleteUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>;
  /**  Create a single TicketPropertyHintHistoryRecord item.  */
  createTicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>;
  /**  Create multiple TicketPropertyHintHistoryRecord items.  */
  createTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>;
  /**  Update a single TicketPropertyHintHistoryRecord item by ID.  */
  updateTicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>;
  /**  Update multiple TicketPropertyHintHistoryRecord items by ID.  */
  updateTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>;
  /**  Delete a single TicketPropertyHintHistoryRecord item by ID.  */
  deleteTicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>;
  /**  Delete multiple TicketPropertyHintHistoryRecord items by ID.  */
  deleteTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>;
  /**  Create a single TicketPropertyHint item.  */
  createTicketPropertyHint?: Maybe<TicketPropertyHint>;
  /**  Create multiple TicketPropertyHint items.  */
  createTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>;
  /**  Update a single TicketPropertyHint item by ID.  */
  updateTicketPropertyHint?: Maybe<TicketPropertyHint>;
  /**  Update multiple TicketPropertyHint items by ID.  */
  updateTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>;
  /**  Delete a single TicketPropertyHint item by ID.  */
  deleteTicketPropertyHint?: Maybe<TicketPropertyHint>;
  /**  Delete multiple TicketPropertyHint items by ID.  */
  deleteTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>;
  /**  Create a single TicketPropertyHintPropertyHistoryRecord item.  */
  createTicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>;
  /**  Create multiple TicketPropertyHintPropertyHistoryRecord items.  */
  createTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>;
  /**  Update a single TicketPropertyHintPropertyHistoryRecord item by ID.  */
  updateTicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>;
  /**  Update multiple TicketPropertyHintPropertyHistoryRecord items by ID.  */
  updateTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>;
  /**  Delete a single TicketPropertyHintPropertyHistoryRecord item by ID.  */
  deleteTicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>;
  /**  Delete multiple TicketPropertyHintPropertyHistoryRecord items by ID.  */
  deleteTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>;
  /**  Create a single TicketPropertyHintProperty item.  */
  createTicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>;
  /**  Create multiple TicketPropertyHintProperty items.  */
  createTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>;
  /**  Update a single TicketPropertyHintProperty item by ID.  */
  updateTicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>;
  /**  Update multiple TicketPropertyHintProperty items by ID.  */
  updateTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>;
  /**  Delete a single TicketPropertyHintProperty item by ID.  */
  deleteTicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>;
  /**  Delete multiple TicketPropertyHintProperty items by ID.  */
  deleteTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>;
  /**  Create a single TicketExportTaskHistoryRecord item.  */
  createTicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>;
  /**  Create multiple TicketExportTaskHistoryRecord items.  */
  createTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>;
  /**  Update a single TicketExportTaskHistoryRecord item by ID.  */
  updateTicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>;
  /**  Update multiple TicketExportTaskHistoryRecord items by ID.  */
  updateTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>;
  /**  Delete a single TicketExportTaskHistoryRecord item by ID.  */
  deleteTicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>;
  /**  Delete multiple TicketExportTaskHistoryRecord items by ID.  */
  deleteTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>;
  /**  Create a single TicketExportTask item.  */
  createTicketExportTask?: Maybe<TicketExportTask>;
  /**  Create multiple TicketExportTask items.  */
  createTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>;
  /**  Update a single TicketExportTask item by ID.  */
  updateTicketExportTask?: Maybe<TicketExportTask>;
  /**  Update multiple TicketExportTask items by ID.  */
  updateTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>;
  /**  Delete a single TicketExportTask item by ID.  */
  deleteTicketExportTask?: Maybe<TicketExportTask>;
  /**  Delete multiple TicketExportTask items by ID.  */
  deleteTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>;
  /**  Create a single TicketOrganizationSettingHistoryRecord item.  */
  createTicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>;
  /**  Create multiple TicketOrganizationSettingHistoryRecord items.  */
  createTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>;
  /**  Update a single TicketOrganizationSettingHistoryRecord item by ID.  */
  updateTicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>;
  /**  Update multiple TicketOrganizationSettingHistoryRecord items by ID.  */
  updateTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>;
  /**  Delete a single TicketOrganizationSettingHistoryRecord item by ID.  */
  deleteTicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>;
  /**  Delete multiple TicketOrganizationSettingHistoryRecord items by ID.  */
  deleteTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>;
  /**  Create a single TicketOrganizationSetting item.  */
  createTicketOrganizationSetting?: Maybe<TicketOrganizationSetting>;
  /**  Create multiple TicketOrganizationSetting items.  */
  createTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>;
  /**  Update a single TicketOrganizationSetting item by ID.  */
  updateTicketOrganizationSetting?: Maybe<TicketOrganizationSetting>;
  /**  Update multiple TicketOrganizationSetting items by ID.  */
  updateTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>;
  /**  Delete a single TicketOrganizationSetting item by ID.  */
  deleteTicketOrganizationSetting?: Maybe<TicketOrganizationSetting>;
  /**  Delete multiple TicketOrganizationSetting items by ID.  */
  deleteTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>;
  /**  Create a single IncidentHistoryRecord item.  */
  createIncidentHistoryRecord?: Maybe<IncidentHistoryRecord>;
  /**  Create multiple IncidentHistoryRecord items.  */
  createIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>;
  /**  Update a single IncidentHistoryRecord item by ID.  */
  updateIncidentHistoryRecord?: Maybe<IncidentHistoryRecord>;
  /**  Update multiple IncidentHistoryRecord items by ID.  */
  updateIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>;
  /**  Delete a single IncidentHistoryRecord item by ID.  */
  deleteIncidentHistoryRecord?: Maybe<IncidentHistoryRecord>;
  /**  Delete multiple IncidentHistoryRecord items by ID.  */
  deleteIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>;
  /**  Create a single Incident item.  */
  createIncident?: Maybe<Incident>;
  /**  Create multiple Incident items.  */
  createIncidents?: Maybe<Array<Maybe<Incident>>>;
  /**  Update a single Incident item by ID.  */
  updateIncident?: Maybe<Incident>;
  /**  Update multiple Incident items by ID.  */
  updateIncidents?: Maybe<Array<Maybe<Incident>>>;
  /**  Delete a single Incident item by ID.  */
  deleteIncident?: Maybe<Incident>;
  /**  Delete multiple Incident items by ID.  */
  deleteIncidents?: Maybe<Array<Maybe<Incident>>>;
  /**  Create a single IncidentChange item.  */
  createIncidentChange?: Maybe<IncidentChange>;
  /**  Create multiple IncidentChange items.  */
  createIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>;
  /**  Update a single IncidentChange item by ID.  */
  updateIncidentChange?: Maybe<IncidentChange>;
  /**  Update multiple IncidentChange items by ID.  */
  updateIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>;
  /**  Delete a single IncidentChange item by ID.  */
  deleteIncidentChange?: Maybe<IncidentChange>;
  /**  Delete multiple IncidentChange items by ID.  */
  deleteIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>;
  /**  Create a single IncidentPropertyHistoryRecord item.  */
  createIncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>;
  /**  Create multiple IncidentPropertyHistoryRecord items.  */
  createIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>;
  /**  Update a single IncidentPropertyHistoryRecord item by ID.  */
  updateIncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>;
  /**  Update multiple IncidentPropertyHistoryRecord items by ID.  */
  updateIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>;
  /**  Delete a single IncidentPropertyHistoryRecord item by ID.  */
  deleteIncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>;
  /**  Delete multiple IncidentPropertyHistoryRecord items by ID.  */
  deleteIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>;
  /**  Create a single IncidentProperty item.  */
  createIncidentProperty?: Maybe<IncidentProperty>;
  /**  Create multiple IncidentProperty items.  */
  createIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>;
  /**  Update a single IncidentProperty item by ID.  */
  updateIncidentProperty?: Maybe<IncidentProperty>;
  /**  Update multiple IncidentProperty items by ID.  */
  updateIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>;
  /**  Delete a single IncidentProperty item by ID.  */
  deleteIncidentProperty?: Maybe<IncidentProperty>;
  /**  Delete multiple IncidentProperty items by ID.  */
  deleteIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>;
  /**  Create a single IncidentClassifierHistoryRecord item.  */
  createIncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>;
  /**  Create multiple IncidentClassifierHistoryRecord items.  */
  createIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>;
  /**  Update a single IncidentClassifierHistoryRecord item by ID.  */
  updateIncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>;
  /**  Update multiple IncidentClassifierHistoryRecord items by ID.  */
  updateIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>;
  /**  Delete a single IncidentClassifierHistoryRecord item by ID.  */
  deleteIncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>;
  /**  Delete multiple IncidentClassifierHistoryRecord items by ID.  */
  deleteIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>;
  /**  Create a single IncidentClassifier item.  */
  createIncidentClassifier?: Maybe<IncidentClassifier>;
  /**  Create multiple IncidentClassifier items.  */
  createIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>;
  /**  Update a single IncidentClassifier item by ID.  */
  updateIncidentClassifier?: Maybe<IncidentClassifier>;
  /**  Update multiple IncidentClassifier items by ID.  */
  updateIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>;
  /**  Delete a single IncidentClassifier item by ID.  */
  deleteIncidentClassifier?: Maybe<IncidentClassifier>;
  /**  Delete multiple IncidentClassifier items by ID.  */
  deleteIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>;
  /**  Create a single IncidentClassifierIncidentHistoryRecord item.  */
  createIncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>;
  /**  Create multiple IncidentClassifierIncidentHistoryRecord items.  */
  createIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>;
  /**  Update a single IncidentClassifierIncidentHistoryRecord item by ID.  */
  updateIncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>;
  /**  Update multiple IncidentClassifierIncidentHistoryRecord items by ID.  */
  updateIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>;
  /**  Delete a single IncidentClassifierIncidentHistoryRecord item by ID.  */
  deleteIncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>;
  /**  Delete multiple IncidentClassifierIncidentHistoryRecord items by ID.  */
  deleteIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>;
  /**  Create a single IncidentClassifierIncident item.  */
  createIncidentClassifierIncident?: Maybe<IncidentClassifierIncident>;
  /**  Create multiple IncidentClassifierIncident items.  */
  createIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>;
  /**  Update a single IncidentClassifierIncident item by ID.  */
  updateIncidentClassifierIncident?: Maybe<IncidentClassifierIncident>;
  /**  Update multiple IncidentClassifierIncident items by ID.  */
  updateIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>;
  /**  Delete a single IncidentClassifierIncident item by ID.  */
  deleteIncidentClassifierIncident?: Maybe<IncidentClassifierIncident>;
  /**  Delete multiple IncidentClassifierIncident items by ID.  */
  deleteIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>;
  /**  Create a single UserFavoriteTicketHistoryRecord item.  */
  createUserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>;
  /**  Create multiple UserFavoriteTicketHistoryRecord items.  */
  createUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>;
  /**  Update a single UserFavoriteTicketHistoryRecord item by ID.  */
  updateUserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>;
  /**  Update multiple UserFavoriteTicketHistoryRecord items by ID.  */
  updateUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>;
  /**  Delete a single UserFavoriteTicketHistoryRecord item by ID.  */
  deleteUserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>;
  /**  Delete multiple UserFavoriteTicketHistoryRecord items by ID.  */
  deleteUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>;
  /**  Create a single UserFavoriteTicket item.  */
  createUserFavoriteTicket?: Maybe<UserFavoriteTicket>;
  /**  Create multiple UserFavoriteTicket items.  */
  createUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>;
  /**  Update a single UserFavoriteTicket item by ID.  */
  updateUserFavoriteTicket?: Maybe<UserFavoriteTicket>;
  /**  Update multiple UserFavoriteTicket items by ID.  */
  updateUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>;
  /**  Delete a single UserFavoriteTicket item by ID.  */
  deleteUserFavoriteTicket?: Maybe<UserFavoriteTicket>;
  /**  Delete multiple UserFavoriteTicket items by ID.  */
  deleteUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>;
  /**  Create a single IncidentExportTaskHistoryRecord item.  */
  createIncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>;
  /**  Create multiple IncidentExportTaskHistoryRecord items.  */
  createIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>;
  /**  Update a single IncidentExportTaskHistoryRecord item by ID.  */
  updateIncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>;
  /**  Update multiple IncidentExportTaskHistoryRecord items by ID.  */
  updateIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>;
  /**  Delete a single IncidentExportTaskHistoryRecord item by ID.  */
  deleteIncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>;
  /**  Delete multiple IncidentExportTaskHistoryRecord items by ID.  */
  deleteIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>;
  /**  Create a single IncidentExportTask item.  */
  createIncidentExportTask?: Maybe<IncidentExportTask>;
  /**  Create multiple IncidentExportTask items.  */
  createIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>;
  /**  Update a single IncidentExportTask item by ID.  */
  updateIncidentExportTask?: Maybe<IncidentExportTask>;
  /**  Update multiple IncidentExportTask items by ID.  */
  updateIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>;
  /**  Delete a single IncidentExportTask item by ID.  */
  deleteIncidentExportTask?: Maybe<IncidentExportTask>;
  /**  Delete multiple IncidentExportTask items by ID.  */
  deleteIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>;
  /**  Create a single CallRecordHistoryRecord item.  */
  createCallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>;
  /**  Create multiple CallRecordHistoryRecord items.  */
  createCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>;
  /**  Update a single CallRecordHistoryRecord item by ID.  */
  updateCallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>;
  /**  Update multiple CallRecordHistoryRecord items by ID.  */
  updateCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>;
  /**  Delete a single CallRecordHistoryRecord item by ID.  */
  deleteCallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>;
  /**  Delete multiple CallRecordHistoryRecord items by ID.  */
  deleteCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>;
  /**  Create a single CallRecord item.  */
  createCallRecord?: Maybe<CallRecord>;
  /**  Create multiple CallRecord items.  */
  createCallRecords?: Maybe<Array<Maybe<CallRecord>>>;
  /**  Update a single CallRecord item by ID.  */
  updateCallRecord?: Maybe<CallRecord>;
  /**  Update multiple CallRecord items by ID.  */
  updateCallRecords?: Maybe<Array<Maybe<CallRecord>>>;
  /**  Delete a single CallRecord item by ID.  */
  deleteCallRecord?: Maybe<CallRecord>;
  /**  Delete multiple CallRecord items by ID.  */
  deleteCallRecords?: Maybe<Array<Maybe<CallRecord>>>;
  /**  Create a single CallRecordFragmentHistoryRecord item.  */
  createCallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>;
  /**  Create multiple CallRecordFragmentHistoryRecord items.  */
  createCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>;
  /**  Update a single CallRecordFragmentHistoryRecord item by ID.  */
  updateCallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>;
  /**  Update multiple CallRecordFragmentHistoryRecord items by ID.  */
  updateCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>;
  /**  Delete a single CallRecordFragmentHistoryRecord item by ID.  */
  deleteCallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>;
  /**  Delete multiple CallRecordFragmentHistoryRecord items by ID.  */
  deleteCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>;
  /**  Create a single CallRecordFragment item.  */
  createCallRecordFragment?: Maybe<CallRecordFragment>;
  /**  Create multiple CallRecordFragment items.  */
  createCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>;
  /**  Update a single CallRecordFragment item by ID.  */
  updateCallRecordFragment?: Maybe<CallRecordFragment>;
  /**  Update multiple CallRecordFragment items by ID.  */
  updateCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>;
  /**  Delete a single CallRecordFragment item by ID.  */
  deleteCallRecordFragment?: Maybe<CallRecordFragment>;
  /**  Delete multiple CallRecordFragment items by ID.  */
  deleteCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>;
  /**  Create a single TicketAutoAssignmentHistoryRecord item.  */
  createTicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>;
  /**  Create multiple TicketAutoAssignmentHistoryRecord items.  */
  createTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>;
  /**  Update a single TicketAutoAssignmentHistoryRecord item by ID.  */
  updateTicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>;
  /**  Update multiple TicketAutoAssignmentHistoryRecord items by ID.  */
  updateTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>;
  /**  Delete a single TicketAutoAssignmentHistoryRecord item by ID.  */
  deleteTicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>;
  /**  Delete multiple TicketAutoAssignmentHistoryRecord items by ID.  */
  deleteTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>;
  /**  Create a single TicketAutoAssignment item.  */
  createTicketAutoAssignment?: Maybe<TicketAutoAssignment>;
  /**  Create multiple TicketAutoAssignment items.  */
  createTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>;
  /**  Update a single TicketAutoAssignment item by ID.  */
  updateTicketAutoAssignment?: Maybe<TicketAutoAssignment>;
  /**  Update multiple TicketAutoAssignment items by ID.  */
  updateTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>;
  /**  Delete a single TicketAutoAssignment item by ID.  */
  deleteTicketAutoAssignment?: Maybe<TicketAutoAssignment>;
  /**  Delete multiple TicketAutoAssignment items by ID.  */
  deleteTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>;
  /**  Create a single TicketDocumentGenerationTaskHistoryRecord item.  */
  createTicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>;
  /**  Create multiple TicketDocumentGenerationTaskHistoryRecord items.  */
  createTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>;
  /**  Update a single TicketDocumentGenerationTaskHistoryRecord item by ID.  */
  updateTicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>;
  /**  Update multiple TicketDocumentGenerationTaskHistoryRecord items by ID.  */
  updateTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>;
  /**  Delete a single TicketDocumentGenerationTaskHistoryRecord item by ID.  */
  deleteTicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>;
  /**  Delete multiple TicketDocumentGenerationTaskHistoryRecord items by ID.  */
  deleteTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>;
  /**  Create a single TicketDocumentGenerationTask item.  */
  createTicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>;
  /**  Create multiple TicketDocumentGenerationTask items.  */
  createTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>;
  /**  Update a single TicketDocumentGenerationTask item by ID.  */
  updateTicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>;
  /**  Update multiple TicketDocumentGenerationTask items by ID.  */
  updateTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>;
  /**  Delete a single TicketDocumentGenerationTask item by ID.  */
  deleteTicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>;
  /**  Delete multiple TicketDocumentGenerationTask items by ID.  */
  deleteTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>;
  /**  Create a single MessageHistoryRecord item.  */
  createMessageHistoryRecord?: Maybe<MessageHistoryRecord>;
  /**  Create multiple MessageHistoryRecord items.  */
  createMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>;
  /**  Update a single MessageHistoryRecord item by ID.  */
  updateMessageHistoryRecord?: Maybe<MessageHistoryRecord>;
  /**  Update multiple MessageHistoryRecord items by ID.  */
  updateMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>;
  /**  Delete a single MessageHistoryRecord item by ID.  */
  deleteMessageHistoryRecord?: Maybe<MessageHistoryRecord>;
  /**  Delete multiple MessageHistoryRecord items by ID.  */
  deleteMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>;
  /**  Create a single Message item.  */
  createMessage?: Maybe<Message>;
  /**  Create multiple Message items.  */
  createMessages?: Maybe<Array<Maybe<Message>>>;
  /**  Update a single Message item by ID.  */
  updateMessage?: Maybe<Message>;
  /**  Update multiple Message items by ID.  */
  updateMessages?: Maybe<Array<Maybe<Message>>>;
  /**  Delete a single Message item by ID.  */
  deleteMessage?: Maybe<Message>;
  /**  Delete multiple Message items by ID.  */
  deleteMessages?: Maybe<Array<Maybe<Message>>>;
  /**  Create a single RemoteClientHistoryRecord item.  */
  createRemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>;
  /**  Create multiple RemoteClientHistoryRecord items.  */
  createRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>;
  /**  Update a single RemoteClientHistoryRecord item by ID.  */
  updateRemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>;
  /**  Update multiple RemoteClientHistoryRecord items by ID.  */
  updateRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>;
  /**  Delete a single RemoteClientHistoryRecord item by ID.  */
  deleteRemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>;
  /**  Delete multiple RemoteClientHistoryRecord items by ID.  */
  deleteRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>;
  /**  Create a single RemoteClient item.  */
  createRemoteClient?: Maybe<RemoteClient>;
  /**  Create multiple RemoteClient items.  */
  createRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>;
  /**  Update a single RemoteClient item by ID.  */
  updateRemoteClient?: Maybe<RemoteClient>;
  /**  Update multiple RemoteClient items by ID.  */
  updateRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>;
  /**  Delete a single RemoteClient item by ID.  */
  deleteRemoteClient?: Maybe<RemoteClient>;
  /**  Delete multiple RemoteClient items by ID.  */
  deleteRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>;
  /**  Create a single MessageUserBlackListHistoryRecord item.  */
  createMessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>;
  /**  Create multiple MessageUserBlackListHistoryRecord items.  */
  createMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>;
  /**  Update a single MessageUserBlackListHistoryRecord item by ID.  */
  updateMessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>;
  /**  Update multiple MessageUserBlackListHistoryRecord items by ID.  */
  updateMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>;
  /**  Delete a single MessageUserBlackListHistoryRecord item by ID.  */
  deleteMessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>;
  /**  Delete multiple MessageUserBlackListHistoryRecord items by ID.  */
  deleteMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>;
  /**  Create a single MessageUserBlackList item.  */
  createMessageUserBlackList?: Maybe<MessageUserBlackList>;
  /**  Create multiple MessageUserBlackList items.  */
  createMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>;
  /**  Update a single MessageUserBlackList item by ID.  */
  updateMessageUserBlackList?: Maybe<MessageUserBlackList>;
  /**  Update multiple MessageUserBlackList items by ID.  */
  updateMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>;
  /**  Delete a single MessageUserBlackList item by ID.  */
  deleteMessageUserBlackList?: Maybe<MessageUserBlackList>;
  /**  Delete multiple MessageUserBlackList items by ID.  */
  deleteMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>;
  /**  Create a single MessageOrganizationBlackListHistoryRecord item.  */
  createMessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>;
  /**  Create multiple MessageOrganizationBlackListHistoryRecord items.  */
  createMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>;
  /**  Update a single MessageOrganizationBlackListHistoryRecord item by ID.  */
  updateMessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>;
  /**  Update multiple MessageOrganizationBlackListHistoryRecord items by ID.  */
  updateMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>;
  /**  Delete a single MessageOrganizationBlackListHistoryRecord item by ID.  */
  deleteMessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>;
  /**  Delete multiple MessageOrganizationBlackListHistoryRecord items by ID.  */
  deleteMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>;
  /**  Create a single MessageOrganizationBlackList item.  */
  createMessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>;
  /**  Create multiple MessageOrganizationBlackList items.  */
  createMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>;
  /**  Update a single MessageOrganizationBlackList item by ID.  */
  updateMessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>;
  /**  Update multiple MessageOrganizationBlackList items by ID.  */
  updateMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>;
  /**  Delete a single MessageOrganizationBlackList item by ID.  */
  deleteMessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>;
  /**  Delete multiple MessageOrganizationBlackList items by ID.  */
  deleteMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>;
  /**  Create a single MessageBatchHistoryRecord item.  */
  createMessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>;
  /**  Create multiple MessageBatchHistoryRecord items.  */
  createMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>;
  /**  Update a single MessageBatchHistoryRecord item by ID.  */
  updateMessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>;
  /**  Update multiple MessageBatchHistoryRecord items by ID.  */
  updateMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>;
  /**  Delete a single MessageBatchHistoryRecord item by ID.  */
  deleteMessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>;
  /**  Delete multiple MessageBatchHistoryRecord items by ID.  */
  deleteMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>;
  /**  Create a single MessageBatch item.  */
  createMessageBatch?: Maybe<MessageBatch>;
  /**  Create multiple MessageBatch items.  */
  createMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>;
  /**  Update a single MessageBatch item by ID.  */
  updateMessageBatch?: Maybe<MessageBatch>;
  /**  Update multiple MessageBatch items by ID.  */
  updateMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>;
  /**  Delete a single MessageBatch item by ID.  */
  deleteMessageBatch?: Maybe<MessageBatch>;
  /**  Delete multiple MessageBatch items by ID.  */
  deleteMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>;
  /**  Create a single NotificationUserSettingHistoryRecord item.  */
  createNotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>;
  /**  Create multiple NotificationUserSettingHistoryRecord items.  */
  createNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>;
  /**  Update a single NotificationUserSettingHistoryRecord item by ID.  */
  updateNotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>;
  /**  Update multiple NotificationUserSettingHistoryRecord items by ID.  */
  updateNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>;
  /**  Delete a single NotificationUserSettingHistoryRecord item by ID.  */
  deleteNotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>;
  /**  Delete multiple NotificationUserSettingHistoryRecord items by ID.  */
  deleteNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>;
  /**  Create a single NotificationUserSetting item.  */
  createNotificationUserSetting?: Maybe<NotificationUserSetting>;
  /**  Create multiple NotificationUserSetting items.  */
  createNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>;
  /**  Update a single NotificationUserSetting item by ID.  */
  updateNotificationUserSetting?: Maybe<NotificationUserSetting>;
  /**  Update multiple NotificationUserSetting items by ID.  */
  updateNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>;
  /**  Delete a single NotificationUserSetting item by ID.  */
  deleteNotificationUserSetting?: Maybe<NotificationUserSetting>;
  /**  Delete multiple NotificationUserSetting items by ID.  */
  deleteNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>;
  /**  Create a single TelegramUserChatHistoryRecord item.  */
  createTelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>;
  /**  Create multiple TelegramUserChatHistoryRecord items.  */
  createTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>;
  /**  Update a single TelegramUserChatHistoryRecord item by ID.  */
  updateTelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>;
  /**  Update multiple TelegramUserChatHistoryRecord items by ID.  */
  updateTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>;
  /**  Delete a single TelegramUserChatHistoryRecord item by ID.  */
  deleteTelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>;
  /**  Delete multiple TelegramUserChatHistoryRecord items by ID.  */
  deleteTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>;
  /**  Create a single TelegramUserChat item.  */
  createTelegramUserChat?: Maybe<TelegramUserChat>;
  /**  Create multiple TelegramUserChat items.  */
  createTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>;
  /**  Update a single TelegramUserChat item by ID.  */
  updateTelegramUserChat?: Maybe<TelegramUserChat>;
  /**  Update multiple TelegramUserChat items by ID.  */
  updateTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>;
  /**  Delete a single TelegramUserChat item by ID.  */
  deleteTelegramUserChat?: Maybe<TelegramUserChat>;
  /**  Delete multiple TelegramUserChat items by ID.  */
  deleteTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>;
  /**  Create a single NotificationAnonymousSettingHistoryRecord item.  */
  createNotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>;
  /**  Create multiple NotificationAnonymousSettingHistoryRecord items.  */
  createNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>;
  /**  Update a single NotificationAnonymousSettingHistoryRecord item by ID.  */
  updateNotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>;
  /**  Update multiple NotificationAnonymousSettingHistoryRecord items by ID.  */
  updateNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>;
  /**  Delete a single NotificationAnonymousSettingHistoryRecord item by ID.  */
  deleteNotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>;
  /**  Delete multiple NotificationAnonymousSettingHistoryRecord items by ID.  */
  deleteNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>;
  /**  Create a single NotificationAnonymousSetting item.  */
  createNotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>;
  /**  Create multiple NotificationAnonymousSetting items.  */
  createNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>;
  /**  Update a single NotificationAnonymousSetting item by ID.  */
  updateNotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>;
  /**  Update multiple NotificationAnonymousSetting items by ID.  */
  updateNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>;
  /**  Delete a single NotificationAnonymousSetting item by ID.  */
  deleteNotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>;
  /**  Delete multiple NotificationAnonymousSetting items by ID.  */
  deleteNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>;
  /**  Create a single ContactHistoryRecord item.  */
  createContactHistoryRecord?: Maybe<ContactHistoryRecord>;
  /**  Create multiple ContactHistoryRecord items.  */
  createContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>;
  /**  Update a single ContactHistoryRecord item by ID.  */
  updateContactHistoryRecord?: Maybe<ContactHistoryRecord>;
  /**  Update multiple ContactHistoryRecord items by ID.  */
  updateContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>;
  /**  Delete a single ContactHistoryRecord item by ID.  */
  deleteContactHistoryRecord?: Maybe<ContactHistoryRecord>;
  /**  Delete multiple ContactHistoryRecord items by ID.  */
  deleteContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>;
  /**  Create a single Contact item.  */
  createContact?: Maybe<Contact>;
  /**  Create multiple Contact items.  */
  createContacts?: Maybe<Array<Maybe<Contact>>>;
  /**  Update a single Contact item by ID.  */
  updateContact?: Maybe<Contact>;
  /**  Update multiple Contact items by ID.  */
  updateContacts?: Maybe<Array<Maybe<Contact>>>;
  /**  Delete a single Contact item by ID.  */
  deleteContact?: Maybe<Contact>;
  /**  Delete multiple Contact items by ID.  */
  deleteContacts?: Maybe<Array<Maybe<Contact>>>;
  /**  Create a single ContactRoleHistoryRecord item.  */
  createContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>;
  /**  Create multiple ContactRoleHistoryRecord items.  */
  createContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>;
  /**  Update a single ContactRoleHistoryRecord item by ID.  */
  updateContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>;
  /**  Update multiple ContactRoleHistoryRecord items by ID.  */
  updateContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>;
  /**  Delete a single ContactRoleHistoryRecord item by ID.  */
  deleteContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>;
  /**  Delete multiple ContactRoleHistoryRecord items by ID.  */
  deleteContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>;
  /**  Create a single ContactRole item.  */
  createContactRole?: Maybe<ContactRole>;
  /**  Create multiple ContactRole items.  */
  createContactRoles?: Maybe<Array<Maybe<ContactRole>>>;
  /**  Update a single ContactRole item by ID.  */
  updateContactRole?: Maybe<ContactRole>;
  /**  Update multiple ContactRole items by ID.  */
  updateContactRoles?: Maybe<Array<Maybe<ContactRole>>>;
  /**  Delete a single ContactRole item by ID.  */
  deleteContactRole?: Maybe<ContactRole>;
  /**  Delete multiple ContactRole items by ID.  */
  deleteContactRoles?: Maybe<Array<Maybe<ContactRole>>>;
  /**  Create a single ContactExportTaskHistoryRecord item.  */
  createContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>;
  /**  Create multiple ContactExportTaskHistoryRecord items.  */
  createContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>;
  /**  Update a single ContactExportTaskHistoryRecord item by ID.  */
  updateContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>;
  /**  Update multiple ContactExportTaskHistoryRecord items by ID.  */
  updateContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>;
  /**  Delete a single ContactExportTaskHistoryRecord item by ID.  */
  deleteContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>;
  /**  Delete multiple ContactExportTaskHistoryRecord items by ID.  */
  deleteContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>;
  /**  Create a single ContactExportTask item.  */
  createContactExportTask?: Maybe<ContactExportTask>;
  /**  Create multiple ContactExportTask items.  */
  createContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>;
  /**  Update a single ContactExportTask item by ID.  */
  updateContactExportTask?: Maybe<ContactExportTask>;
  /**  Update multiple ContactExportTask items by ID.  */
  updateContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>;
  /**  Delete a single ContactExportTask item by ID.  */
  deleteContactExportTask?: Maybe<ContactExportTask>;
  /**  Delete multiple ContactExportTask items by ID.  */
  deleteContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>;
  /**  Create a single ResidentHistoryRecord item.  */
  createResidentHistoryRecord?: Maybe<ResidentHistoryRecord>;
  /**  Create multiple ResidentHistoryRecord items.  */
  createResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>;
  /**  Update a single ResidentHistoryRecord item by ID.  */
  updateResidentHistoryRecord?: Maybe<ResidentHistoryRecord>;
  /**  Update multiple ResidentHistoryRecord items by ID.  */
  updateResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>;
  /**  Delete a single ResidentHistoryRecord item by ID.  */
  deleteResidentHistoryRecord?: Maybe<ResidentHistoryRecord>;
  /**  Delete multiple ResidentHistoryRecord items by ID.  */
  deleteResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>;
  /**  Create a single Resident item.  */
  createResident?: Maybe<Resident>;
  /**  Create multiple Resident items.  */
  createResidents?: Maybe<Array<Maybe<Resident>>>;
  /**  Update a single Resident item by ID.  */
  updateResident?: Maybe<Resident>;
  /**  Update multiple Resident items by ID.  */
  updateResidents?: Maybe<Array<Maybe<Resident>>>;
  /**  Delete a single Resident item by ID.  */
  deleteResident?: Maybe<Resident>;
  /**  Delete multiple Resident items by ID.  */
  deleteResidents?: Maybe<Array<Maybe<Resident>>>;
  /**  Create a single ServiceConsumerHistoryRecord item.  */
  createServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>;
  /**  Create multiple ServiceConsumerHistoryRecord items.  */
  createServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>;
  /**  Update a single ServiceConsumerHistoryRecord item by ID.  */
  updateServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>;
  /**  Update multiple ServiceConsumerHistoryRecord items by ID.  */
  updateServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>;
  /**  Delete a single ServiceConsumerHistoryRecord item by ID.  */
  deleteServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>;
  /**  Delete multiple ServiceConsumerHistoryRecord items by ID.  */
  deleteServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>;
  /**  Create a single ServiceConsumer item.  */
  createServiceConsumer?: Maybe<ServiceConsumer>;
  /**  Create multiple ServiceConsumer items.  */
  createServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>;
  /**  Update a single ServiceConsumer item by ID.  */
  updateServiceConsumer?: Maybe<ServiceConsumer>;
  /**  Update multiple ServiceConsumer items by ID.  */
  updateServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>;
  /**  Delete a single ServiceConsumer item by ID.  */
  deleteServiceConsumer?: Maybe<ServiceConsumer>;
  /**  Delete multiple ServiceConsumer items by ID.  */
  deleteServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>;
  /**  Create a single TourStepHistoryRecord item.  */
  createTourStepHistoryRecord?: Maybe<TourStepHistoryRecord>;
  /**  Create multiple TourStepHistoryRecord items.  */
  createTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>;
  /**  Update a single TourStepHistoryRecord item by ID.  */
  updateTourStepHistoryRecord?: Maybe<TourStepHistoryRecord>;
  /**  Update multiple TourStepHistoryRecord items by ID.  */
  updateTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>;
  /**  Delete a single TourStepHistoryRecord item by ID.  */
  deleteTourStepHistoryRecord?: Maybe<TourStepHistoryRecord>;
  /**  Delete multiple TourStepHistoryRecord items by ID.  */
  deleteTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>;
  /**  Create a single TourStep item.  */
  createTourStep?: Maybe<TourStep>;
  /**  Create multiple TourStep items.  */
  createTourSteps?: Maybe<Array<Maybe<TourStep>>>;
  /**  Update a single TourStep item by ID.  */
  updateTourStep?: Maybe<TourStep>;
  /**  Update multiple TourStep items by ID.  */
  updateTourSteps?: Maybe<Array<Maybe<TourStep>>>;
  /**  Delete a single TourStep item by ID.  */
  deleteTourStep?: Maybe<TourStep>;
  /**  Delete multiple TourStep items by ID.  */
  deleteTourSteps?: Maybe<Array<Maybe<TourStep>>>;
  /**  Create a single UserHelpRequestHistoryRecord item.  */
  createUserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>;
  /**  Create multiple UserHelpRequestHistoryRecord items.  */
  createUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>;
  /**  Update a single UserHelpRequestHistoryRecord item by ID.  */
  updateUserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>;
  /**  Update multiple UserHelpRequestHistoryRecord items by ID.  */
  updateUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>;
  /**  Delete a single UserHelpRequestHistoryRecord item by ID.  */
  deleteUserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>;
  /**  Delete multiple UserHelpRequestHistoryRecord items by ID.  */
  deleteUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>;
  /**  Create a single UserHelpRequest item.  */
  createUserHelpRequest?: Maybe<UserHelpRequest>;
  /**  Create multiple UserHelpRequest items.  */
  createUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>;
  /**  Update a single UserHelpRequest item by ID.  */
  updateUserHelpRequest?: Maybe<UserHelpRequest>;
  /**  Update multiple UserHelpRequest items by ID.  */
  updateUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>;
  /**  Delete a single UserHelpRequest item by ID.  */
  deleteUserHelpRequest?: Maybe<UserHelpRequest>;
  /**  Delete multiple UserHelpRequest items by ID.  */
  deleteUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>;
  /**  Create a single UserHelpRequestFileHistoryRecord item.  */
  createUserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>;
  /**  Create multiple UserHelpRequestFileHistoryRecord items.  */
  createUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>;
  /**  Update a single UserHelpRequestFileHistoryRecord item by ID.  */
  updateUserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>;
  /**  Update multiple UserHelpRequestFileHistoryRecord items by ID.  */
  updateUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>;
  /**  Delete a single UserHelpRequestFileHistoryRecord item by ID.  */
  deleteUserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>;
  /**  Delete multiple UserHelpRequestFileHistoryRecord items by ID.  */
  deleteUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>;
  /**  Create a single UserHelpRequestFile item.  */
  createUserHelpRequestFile?: Maybe<UserHelpRequestFile>;
  /**  Create multiple UserHelpRequestFile items.  */
  createUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>;
  /**  Update a single UserHelpRequestFile item by ID.  */
  updateUserHelpRequestFile?: Maybe<UserHelpRequestFile>;
  /**  Update multiple UserHelpRequestFile items by ID.  */
  updateUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>;
  /**  Delete a single UserHelpRequestFile item by ID.  */
  deleteUserHelpRequestFile?: Maybe<UserHelpRequestFile>;
  /**  Delete multiple UserHelpRequestFile items by ID.  */
  deleteUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>;
  /**  Create a single MeterResourceHistoryRecord item.  */
  createMeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>;
  /**  Create multiple MeterResourceHistoryRecord items.  */
  createMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>;
  /**  Update a single MeterResourceHistoryRecord item by ID.  */
  updateMeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>;
  /**  Update multiple MeterResourceHistoryRecord items by ID.  */
  updateMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>;
  /**  Delete a single MeterResourceHistoryRecord item by ID.  */
  deleteMeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>;
  /**  Delete multiple MeterResourceHistoryRecord items by ID.  */
  deleteMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>;
  /**  Create a single MeterResource item.  */
  createMeterResource?: Maybe<MeterResource>;
  /**  Create multiple MeterResource items.  */
  createMeterResources?: Maybe<Array<Maybe<MeterResource>>>;
  /**  Update a single MeterResource item by ID.  */
  updateMeterResource?: Maybe<MeterResource>;
  /**  Update multiple MeterResource items by ID.  */
  updateMeterResources?: Maybe<Array<Maybe<MeterResource>>>;
  /**  Delete a single MeterResource item by ID.  */
  deleteMeterResource?: Maybe<MeterResource>;
  /**  Delete multiple MeterResource items by ID.  */
  deleteMeterResources?: Maybe<Array<Maybe<MeterResource>>>;
  /**  Create a single MeterReadingSourceHistoryRecord item.  */
  createMeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>;
  /**  Create multiple MeterReadingSourceHistoryRecord items.  */
  createMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>;
  /**  Update a single MeterReadingSourceHistoryRecord item by ID.  */
  updateMeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>;
  /**  Update multiple MeterReadingSourceHistoryRecord items by ID.  */
  updateMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>;
  /**  Delete a single MeterReadingSourceHistoryRecord item by ID.  */
  deleteMeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>;
  /**  Delete multiple MeterReadingSourceHistoryRecord items by ID.  */
  deleteMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>;
  /**  Create a single MeterReadingSource item.  */
  createMeterReadingSource?: Maybe<MeterReadingSource>;
  /**  Create multiple MeterReadingSource items.  */
  createMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>;
  /**  Update a single MeterReadingSource item by ID.  */
  updateMeterReadingSource?: Maybe<MeterReadingSource>;
  /**  Update multiple MeterReadingSource items by ID.  */
  updateMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>;
  /**  Delete a single MeterReadingSource item by ID.  */
  deleteMeterReadingSource?: Maybe<MeterReadingSource>;
  /**  Delete multiple MeterReadingSource items by ID.  */
  deleteMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>;
  /**  Create a single MeterReadingHistoryRecord item.  */
  createMeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>;
  /**  Create multiple MeterReadingHistoryRecord items.  */
  createMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>;
  /**  Update a single MeterReadingHistoryRecord item by ID.  */
  updateMeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>;
  /**  Update multiple MeterReadingHistoryRecord items by ID.  */
  updateMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>;
  /**  Delete a single MeterReadingHistoryRecord item by ID.  */
  deleteMeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>;
  /**  Delete multiple MeterReadingHistoryRecord items by ID.  */
  deleteMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>;
  /**  Create a single MeterReading item.  */
  createMeterReading?: Maybe<MeterReading>;
  /**  Create multiple MeterReading items.  */
  createMeterReadings?: Maybe<Array<Maybe<MeterReading>>>;
  /**  Update a single MeterReading item by ID.  */
  updateMeterReading?: Maybe<MeterReading>;
  /**  Update multiple MeterReading items by ID.  */
  updateMeterReadings?: Maybe<Array<Maybe<MeterReading>>>;
  /**  Delete a single MeterReading item by ID.  */
  deleteMeterReading?: Maybe<MeterReading>;
  /**  Delete multiple MeterReading items by ID.  */
  deleteMeterReadings?: Maybe<Array<Maybe<MeterReading>>>;
  /**  Create a single MeterHistoryRecord item.  */
  createMeterHistoryRecord?: Maybe<MeterHistoryRecord>;
  /**  Create multiple MeterHistoryRecord items.  */
  createMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>;
  /**  Update a single MeterHistoryRecord item by ID.  */
  updateMeterHistoryRecord?: Maybe<MeterHistoryRecord>;
  /**  Update multiple MeterHistoryRecord items by ID.  */
  updateMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>;
  /**  Delete a single MeterHistoryRecord item by ID.  */
  deleteMeterHistoryRecord?: Maybe<MeterHistoryRecord>;
  /**  Delete multiple MeterHistoryRecord items by ID.  */
  deleteMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>;
  /**  Create a single Meter item.  */
  createMeter?: Maybe<Meter>;
  /**  Create multiple Meter items.  */
  createMeters?: Maybe<Array<Maybe<Meter>>>;
  /**  Update a single Meter item by ID.  */
  updateMeter?: Maybe<Meter>;
  /**  Update multiple Meter items by ID.  */
  updateMeters?: Maybe<Array<Maybe<Meter>>>;
  /**  Delete a single Meter item by ID.  */
  deleteMeter?: Maybe<Meter>;
  /**  Delete multiple Meter items by ID.  */
  deleteMeters?: Maybe<Array<Maybe<Meter>>>;
  /**  Create a single MeterReadingFilterTemplateHistoryRecord item.  */
  createMeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>;
  /**  Create multiple MeterReadingFilterTemplateHistoryRecord items.  */
  createMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>;
  /**  Update a single MeterReadingFilterTemplateHistoryRecord item by ID.  */
  updateMeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>;
  /**  Update multiple MeterReadingFilterTemplateHistoryRecord items by ID.  */
  updateMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>;
  /**  Delete a single MeterReadingFilterTemplateHistoryRecord item by ID.  */
  deleteMeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>;
  /**  Delete multiple MeterReadingFilterTemplateHistoryRecord items by ID.  */
  deleteMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>;
  /**  Create a single MeterReadingFilterTemplate item.  */
  createMeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>;
  /**  Create multiple MeterReadingFilterTemplate items.  */
  createMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>;
  /**  Update a single MeterReadingFilterTemplate item by ID.  */
  updateMeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>;
  /**  Update multiple MeterReadingFilterTemplate items by ID.  */
  updateMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>;
  /**  Delete a single MeterReadingFilterTemplate item by ID.  */
  deleteMeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>;
  /**  Delete multiple MeterReadingFilterTemplate items by ID.  */
  deleteMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>;
  /**  Create a single PropertyMeterHistoryRecord item.  */
  createPropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>;
  /**  Create multiple PropertyMeterHistoryRecord items.  */
  createPropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>;
  /**  Update a single PropertyMeterHistoryRecord item by ID.  */
  updatePropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>;
  /**  Update multiple PropertyMeterHistoryRecord items by ID.  */
  updatePropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>;
  /**  Delete a single PropertyMeterHistoryRecord item by ID.  */
  deletePropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>;
  /**  Delete multiple PropertyMeterHistoryRecord items by ID.  */
  deletePropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>;
  /**  Create a single PropertyMeter item.  */
  createPropertyMeter?: Maybe<PropertyMeter>;
  /**  Create multiple PropertyMeter items.  */
  createPropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>;
  /**  Update a single PropertyMeter item by ID.  */
  updatePropertyMeter?: Maybe<PropertyMeter>;
  /**  Update multiple PropertyMeter items by ID.  */
  updatePropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>;
  /**  Delete a single PropertyMeter item by ID.  */
  deletePropertyMeter?: Maybe<PropertyMeter>;
  /**  Delete multiple PropertyMeter items by ID.  */
  deletePropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>;
  /**  Create a single PropertyMeterReadingHistoryRecord item.  */
  createPropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>;
  /**  Create multiple PropertyMeterReadingHistoryRecord items.  */
  createPropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>;
  /**  Update a single PropertyMeterReadingHistoryRecord item by ID.  */
  updatePropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>;
  /**  Update multiple PropertyMeterReadingHistoryRecord items by ID.  */
  updatePropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>;
  /**  Delete a single PropertyMeterReadingHistoryRecord item by ID.  */
  deletePropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>;
  /**  Delete multiple PropertyMeterReadingHistoryRecord items by ID.  */
  deletePropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>;
  /**  Create a single PropertyMeterReading item.  */
  createPropertyMeterReading?: Maybe<PropertyMeterReading>;
  /**  Create multiple PropertyMeterReading items.  */
  createPropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>;
  /**  Update a single PropertyMeterReading item by ID.  */
  updatePropertyMeterReading?: Maybe<PropertyMeterReading>;
  /**  Update multiple PropertyMeterReading items by ID.  */
  updatePropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>;
  /**  Delete a single PropertyMeterReading item by ID.  */
  deletePropertyMeterReading?: Maybe<PropertyMeterReading>;
  /**  Delete multiple PropertyMeterReading items by ID.  */
  deletePropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>;
  /**  Create a single MeterReportingPeriodHistoryRecord item.  */
  createMeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>;
  /**  Create multiple MeterReportingPeriodHistoryRecord items.  */
  createMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>;
  /**  Update a single MeterReportingPeriodHistoryRecord item by ID.  */
  updateMeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>;
  /**  Update multiple MeterReportingPeriodHistoryRecord items by ID.  */
  updateMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>;
  /**  Delete a single MeterReportingPeriodHistoryRecord item by ID.  */
  deleteMeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>;
  /**  Delete multiple MeterReportingPeriodHistoryRecord items by ID.  */
  deleteMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>;
  /**  Create a single MeterReportingPeriod item.  */
  createMeterReportingPeriod?: Maybe<MeterReportingPeriod>;
  /**  Create multiple MeterReportingPeriod items.  */
  createMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>;
  /**  Update a single MeterReportingPeriod item by ID.  */
  updateMeterReportingPeriod?: Maybe<MeterReportingPeriod>;
  /**  Update multiple MeterReportingPeriod items by ID.  */
  updateMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>;
  /**  Delete a single MeterReportingPeriod item by ID.  */
  deleteMeterReportingPeriod?: Maybe<MeterReportingPeriod>;
  /**  Delete multiple MeterReportingPeriod items by ID.  */
  deleteMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>;
  /**  Create a single MeterResourceOwnerHistoryRecord item.  */
  createMeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>;
  /**  Create multiple MeterResourceOwnerHistoryRecord items.  */
  createMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>;
  /**  Update a single MeterResourceOwnerHistoryRecord item by ID.  */
  updateMeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>;
  /**  Update multiple MeterResourceOwnerHistoryRecord items by ID.  */
  updateMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>;
  /**  Delete a single MeterResourceOwnerHistoryRecord item by ID.  */
  deleteMeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>;
  /**  Delete multiple MeterResourceOwnerHistoryRecord items by ID.  */
  deleteMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>;
  /**  Create a single MeterResourceOwner item.  */
  createMeterResourceOwner?: Maybe<MeterResourceOwner>;
  /**  Create multiple MeterResourceOwner items.  */
  createMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>;
  /**  Update a single MeterResourceOwner item by ID.  */
  updateMeterResourceOwner?: Maybe<MeterResourceOwner>;
  /**  Update multiple MeterResourceOwner items by ID.  */
  updateMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>;
  /**  Delete a single MeterResourceOwner item by ID.  */
  deleteMeterResourceOwner?: Maybe<MeterResourceOwner>;
  /**  Delete multiple MeterResourceOwner items by ID.  */
  deleteMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>;
  /**  Create a single MeterReadingsImportTaskHistoryRecord item.  */
  createMeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>;
  /**  Create multiple MeterReadingsImportTaskHistoryRecord items.  */
  createMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>;
  /**  Update a single MeterReadingsImportTaskHistoryRecord item by ID.  */
  updateMeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>;
  /**  Update multiple MeterReadingsImportTaskHistoryRecord items by ID.  */
  updateMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>;
  /**  Delete a single MeterReadingsImportTaskHistoryRecord item by ID.  */
  deleteMeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>;
  /**  Delete multiple MeterReadingsImportTaskHistoryRecord items by ID.  */
  deleteMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>;
  /**  Create a single MeterReadingsImportTask item.  */
  createMeterReadingsImportTask?: Maybe<MeterReadingsImportTask>;
  /**  Create multiple MeterReadingsImportTask items.  */
  createMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>;
  /**  Update a single MeterReadingsImportTask item by ID.  */
  updateMeterReadingsImportTask?: Maybe<MeterReadingsImportTask>;
  /**  Update multiple MeterReadingsImportTask items by ID.  */
  updateMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>;
  /**  Delete a single MeterReadingsImportTask item by ID.  */
  deleteMeterReadingsImportTask?: Maybe<MeterReadingsImportTask>;
  /**  Delete multiple MeterReadingsImportTask items by ID.  */
  deleteMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>;
  /**  Create a single ServiceSubscriptionHistoryRecord item.  */
  createServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>;
  /**  Create multiple ServiceSubscriptionHistoryRecord items.  */
  createServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>;
  /**  Update a single ServiceSubscriptionHistoryRecord item by ID.  */
  updateServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>;
  /**  Update multiple ServiceSubscriptionHistoryRecord items by ID.  */
  updateServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>;
  /**  Delete a single ServiceSubscriptionHistoryRecord item by ID.  */
  deleteServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>;
  /**  Delete multiple ServiceSubscriptionHistoryRecord items by ID.  */
  deleteServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>;
  /**  Create a single ServiceSubscription item.  */
  createServiceSubscription?: Maybe<ServiceSubscription>;
  /**  Create multiple ServiceSubscription items.  */
  createServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>;
  /**  Update a single ServiceSubscription item by ID.  */
  updateServiceSubscription?: Maybe<ServiceSubscription>;
  /**  Update multiple ServiceSubscription items by ID.  */
  updateServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>;
  /**  Delete a single ServiceSubscription item by ID.  */
  deleteServiceSubscription?: Maybe<ServiceSubscription>;
  /**  Delete multiple ServiceSubscription items by ID.  */
  deleteServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>;
  /**  Create a single AcquiringIntegrationHistoryRecord item.  */
  createAcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>;
  /**  Create multiple AcquiringIntegrationHistoryRecord items.  */
  createAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>;
  /**  Update a single AcquiringIntegrationHistoryRecord item by ID.  */
  updateAcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>;
  /**  Update multiple AcquiringIntegrationHistoryRecord items by ID.  */
  updateAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>;
  /**  Delete a single AcquiringIntegrationHistoryRecord item by ID.  */
  deleteAcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>;
  /**  Delete multiple AcquiringIntegrationHistoryRecord items by ID.  */
  deleteAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>;
  /**  Create a single AcquiringIntegration item.  */
  createAcquiringIntegration?: Maybe<AcquiringIntegration>;
  /**  Create multiple AcquiringIntegration items.  */
  createAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>;
  /**  Update a single AcquiringIntegration item by ID.  */
  updateAcquiringIntegration?: Maybe<AcquiringIntegration>;
  /**  Update multiple AcquiringIntegration items by ID.  */
  updateAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>;
  /**  Delete a single AcquiringIntegration item by ID.  */
  deleteAcquiringIntegration?: Maybe<AcquiringIntegration>;
  /**  Delete multiple AcquiringIntegration items by ID.  */
  deleteAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>;
  /**  Create a single AcquiringIntegrationAccessRightHistoryRecord item.  */
  createAcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>;
  /**  Create multiple AcquiringIntegrationAccessRightHistoryRecord items.  */
  createAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>;
  /**  Update a single AcquiringIntegrationAccessRightHistoryRecord item by ID.  */
  updateAcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>;
  /**  Update multiple AcquiringIntegrationAccessRightHistoryRecord items by ID.  */
  updateAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>;
  /**  Delete a single AcquiringIntegrationAccessRightHistoryRecord item by ID.  */
  deleteAcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>;
  /**  Delete multiple AcquiringIntegrationAccessRightHistoryRecord items by ID.  */
  deleteAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>;
  /**  Create a single AcquiringIntegrationAccessRight item.  */
  createAcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>;
  /**  Create multiple AcquiringIntegrationAccessRight items.  */
  createAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>;
  /**  Update a single AcquiringIntegrationAccessRight item by ID.  */
  updateAcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>;
  /**  Update multiple AcquiringIntegrationAccessRight items by ID.  */
  updateAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>;
  /**  Delete a single AcquiringIntegrationAccessRight item by ID.  */
  deleteAcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>;
  /**  Delete multiple AcquiringIntegrationAccessRight items by ID.  */
  deleteAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>;
  /**  Create a single AcquiringIntegrationContextHistoryRecord item.  */
  createAcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>;
  /**  Create multiple AcquiringIntegrationContextHistoryRecord items.  */
  createAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>;
  /**  Update a single AcquiringIntegrationContextHistoryRecord item by ID.  */
  updateAcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>;
  /**  Update multiple AcquiringIntegrationContextHistoryRecord items by ID.  */
  updateAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>;
  /**  Delete a single AcquiringIntegrationContextHistoryRecord item by ID.  */
  deleteAcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>;
  /**  Delete multiple AcquiringIntegrationContextHistoryRecord items by ID.  */
  deleteAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>;
  /**  Create a single AcquiringIntegrationContext item.  */
  createAcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>;
  /**  Create multiple AcquiringIntegrationContext items.  */
  createAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>;
  /**  Update a single AcquiringIntegrationContext item by ID.  */
  updateAcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>;
  /**  Update multiple AcquiringIntegrationContext items by ID.  */
  updateAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>;
  /**  Delete a single AcquiringIntegrationContext item by ID.  */
  deleteAcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>;
  /**  Delete multiple AcquiringIntegrationContext items by ID.  */
  deleteAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>;
  /**  Create a single MultiPaymentHistoryRecord item.  */
  createMultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>;
  /**  Create multiple MultiPaymentHistoryRecord items.  */
  createMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>;
  /**  Update a single MultiPaymentHistoryRecord item by ID.  */
  updateMultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>;
  /**  Update multiple MultiPaymentHistoryRecord items by ID.  */
  updateMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>;
  /**  Delete a single MultiPaymentHistoryRecord item by ID.  */
  deleteMultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>;
  /**  Delete multiple MultiPaymentHistoryRecord items by ID.  */
  deleteMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>;
  /**  Create a single MultiPayment item.  */
  createMultiPayment?: Maybe<MultiPayment>;
  /**  Create multiple MultiPayment items.  */
  createMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>;
  /**  Update a single MultiPayment item by ID.  */
  updateMultiPayment?: Maybe<MultiPayment>;
  /**  Update multiple MultiPayment items by ID.  */
  updateMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>;
  /**  Delete a single MultiPayment item by ID.  */
  deleteMultiPayment?: Maybe<MultiPayment>;
  /**  Delete multiple MultiPayment items by ID.  */
  deleteMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>;
  /**  Create a single PaymentHistoryRecord item.  */
  createPaymentHistoryRecord?: Maybe<PaymentHistoryRecord>;
  /**  Create multiple PaymentHistoryRecord items.  */
  createPaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>;
  /**  Update a single PaymentHistoryRecord item by ID.  */
  updatePaymentHistoryRecord?: Maybe<PaymentHistoryRecord>;
  /**  Update multiple PaymentHistoryRecord items by ID.  */
  updatePaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>;
  /**  Delete a single PaymentHistoryRecord item by ID.  */
  deletePaymentHistoryRecord?: Maybe<PaymentHistoryRecord>;
  /**  Delete multiple PaymentHistoryRecord items by ID.  */
  deletePaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>;
  /**  Create a single Payment item.  */
  createPayment?: Maybe<Payment>;
  /**  Create multiple Payment items.  */
  createPayments?: Maybe<Array<Maybe<Payment>>>;
  /**  Update a single Payment item by ID.  */
  updatePayment?: Maybe<Payment>;
  /**  Update multiple Payment items by ID.  */
  updatePayments?: Maybe<Array<Maybe<Payment>>>;
  /**  Delete a single Payment item by ID.  */
  deletePayment?: Maybe<Payment>;
  /**  Delete multiple Payment items by ID.  */
  deletePayments?: Maybe<Array<Maybe<Payment>>>;
  /**  Create a single PaymentsFilterTemplateHistoryRecord item.  */
  createPaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>;
  /**  Create multiple PaymentsFilterTemplateHistoryRecord items.  */
  createPaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>;
  /**  Update a single PaymentsFilterTemplateHistoryRecord item by ID.  */
  updatePaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>;
  /**  Update multiple PaymentsFilterTemplateHistoryRecord items by ID.  */
  updatePaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>;
  /**  Delete a single PaymentsFilterTemplateHistoryRecord item by ID.  */
  deletePaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>;
  /**  Delete multiple PaymentsFilterTemplateHistoryRecord items by ID.  */
  deletePaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>;
  /**  Create a single PaymentsFilterTemplate item.  */
  createPaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>;
  /**  Create multiple PaymentsFilterTemplate items.  */
  createPaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>;
  /**  Update a single PaymentsFilterTemplate item by ID.  */
  updatePaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>;
  /**  Update multiple PaymentsFilterTemplate items by ID.  */
  updatePaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>;
  /**  Delete a single PaymentsFilterTemplate item by ID.  */
  deletePaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>;
  /**  Delete multiple PaymentsFilterTemplate items by ID.  */
  deletePaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>;
  /**  Create a single RecurrentPaymentContextHistoryRecord item.  */
  createRecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>;
  /**  Create multiple RecurrentPaymentContextHistoryRecord items.  */
  createRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>;
  /**  Update a single RecurrentPaymentContextHistoryRecord item by ID.  */
  updateRecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>;
  /**  Update multiple RecurrentPaymentContextHistoryRecord items by ID.  */
  updateRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>;
  /**  Delete a single RecurrentPaymentContextHistoryRecord item by ID.  */
  deleteRecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>;
  /**  Delete multiple RecurrentPaymentContextHistoryRecord items by ID.  */
  deleteRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>;
  /**  Create a single RecurrentPaymentContext item.  */
  createRecurrentPaymentContext?: Maybe<RecurrentPaymentContext>;
  /**  Create multiple RecurrentPaymentContext items.  */
  createRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>;
  /**  Update a single RecurrentPaymentContext item by ID.  */
  updateRecurrentPaymentContext?: Maybe<RecurrentPaymentContext>;
  /**  Update multiple RecurrentPaymentContext items by ID.  */
  updateRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>;
  /**  Delete a single RecurrentPaymentContext item by ID.  */
  deleteRecurrentPaymentContext?: Maybe<RecurrentPaymentContext>;
  /**  Delete multiple RecurrentPaymentContext items by ID.  */
  deleteRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>;
  /**  Create a single RecurrentPaymentHistoryRecord item.  */
  createRecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>;
  /**  Create multiple RecurrentPaymentHistoryRecord items.  */
  createRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>;
  /**  Update a single RecurrentPaymentHistoryRecord item by ID.  */
  updateRecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>;
  /**  Update multiple RecurrentPaymentHistoryRecord items by ID.  */
  updateRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>;
  /**  Delete a single RecurrentPaymentHistoryRecord item by ID.  */
  deleteRecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>;
  /**  Delete multiple RecurrentPaymentHistoryRecord items by ID.  */
  deleteRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>;
  /**  Create a single RecurrentPayment item.  */
  createRecurrentPayment?: Maybe<RecurrentPayment>;
  /**  Create multiple RecurrentPayment items.  */
  createRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>;
  /**  Update a single RecurrentPayment item by ID.  */
  updateRecurrentPayment?: Maybe<RecurrentPayment>;
  /**  Update multiple RecurrentPayment items by ID.  */
  updateRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>;
  /**  Delete a single RecurrentPayment item by ID.  */
  deleteRecurrentPayment?: Maybe<RecurrentPayment>;
  /**  Delete multiple RecurrentPayment items by ID.  */
  deleteRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>;
  /**  Create a single ExternalReportHistoryRecord item.  */
  createExternalReportHistoryRecord?: Maybe<ExternalReportHistoryRecord>;
  /**  Create multiple ExternalReportHistoryRecord items.  */
  createExternalReportHistoryRecords?: Maybe<Array<Maybe<ExternalReportHistoryRecord>>>;
  /**  Update a single ExternalReportHistoryRecord item by ID.  */
  updateExternalReportHistoryRecord?: Maybe<ExternalReportHistoryRecord>;
  /**  Update multiple ExternalReportHistoryRecord items by ID.  */
  updateExternalReportHistoryRecords?: Maybe<Array<Maybe<ExternalReportHistoryRecord>>>;
  /**  Delete a single ExternalReportHistoryRecord item by ID.  */
  deleteExternalReportHistoryRecord?: Maybe<ExternalReportHistoryRecord>;
  /**  Delete multiple ExternalReportHistoryRecord items by ID.  */
  deleteExternalReportHistoryRecords?: Maybe<Array<Maybe<ExternalReportHistoryRecord>>>;
  /**  Create a single ExternalReport item.  */
  createExternalReport?: Maybe<ExternalReport>;
  /**  Create multiple ExternalReport items.  */
  createExternalReports?: Maybe<Array<Maybe<ExternalReport>>>;
  /**  Update a single ExternalReport item by ID.  */
  updateExternalReport?: Maybe<ExternalReport>;
  /**  Update multiple ExternalReport items by ID.  */
  updateExternalReports?: Maybe<Array<Maybe<ExternalReport>>>;
  /**  Delete a single ExternalReport item by ID.  */
  deleteExternalReport?: Maybe<ExternalReport>;
  /**  Delete multiple ExternalReport items by ID.  */
  deleteExternalReports?: Maybe<Array<Maybe<ExternalReport>>>;
  /**  Create a single PropertyScopeHistoryRecord item.  */
  createPropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>;
  /**  Create multiple PropertyScopeHistoryRecord items.  */
  createPropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>;
  /**  Update a single PropertyScopeHistoryRecord item by ID.  */
  updatePropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>;
  /**  Update multiple PropertyScopeHistoryRecord items by ID.  */
  updatePropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>;
  /**  Delete a single PropertyScopeHistoryRecord item by ID.  */
  deletePropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>;
  /**  Delete multiple PropertyScopeHistoryRecord items by ID.  */
  deletePropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>;
  /**  Create a single PropertyScope item.  */
  createPropertyScope?: Maybe<PropertyScope>;
  /**  Create multiple PropertyScope items.  */
  createPropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>;
  /**  Update a single PropertyScope item by ID.  */
  updatePropertyScope?: Maybe<PropertyScope>;
  /**  Update multiple PropertyScope items by ID.  */
  updatePropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>;
  /**  Delete a single PropertyScope item by ID.  */
  deletePropertyScope?: Maybe<PropertyScope>;
  /**  Delete multiple PropertyScope items by ID.  */
  deletePropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>;
  /**  Create a single PropertyScopeOrganizationEmployeeHistoryRecord item.  */
  createPropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>;
  /**  Create multiple PropertyScopeOrganizationEmployeeHistoryRecord items.  */
  createPropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>;
  /**  Update a single PropertyScopeOrganizationEmployeeHistoryRecord item by ID.  */
  updatePropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>;
  /**  Update multiple PropertyScopeOrganizationEmployeeHistoryRecord items by ID.  */
  updatePropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>;
  /**  Delete a single PropertyScopeOrganizationEmployeeHistoryRecord item by ID.  */
  deletePropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>;
  /**  Delete multiple PropertyScopeOrganizationEmployeeHistoryRecord items by ID.  */
  deletePropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>;
  /**  Create a single PropertyScopeOrganizationEmployee item.  */
  createPropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>;
  /**  Create multiple PropertyScopeOrganizationEmployee items.  */
  createPropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>;
  /**  Update a single PropertyScopeOrganizationEmployee item by ID.  */
  updatePropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>;
  /**  Update multiple PropertyScopeOrganizationEmployee items by ID.  */
  updatePropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>;
  /**  Delete a single PropertyScopeOrganizationEmployee item by ID.  */
  deletePropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>;
  /**  Delete multiple PropertyScopeOrganizationEmployee items by ID.  */
  deletePropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>;
  /**  Create a single PropertyScopePropertyHistoryRecord item.  */
  createPropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>;
  /**  Create multiple PropertyScopePropertyHistoryRecord items.  */
  createPropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>;
  /**  Update a single PropertyScopePropertyHistoryRecord item by ID.  */
  updatePropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>;
  /**  Update multiple PropertyScopePropertyHistoryRecord items by ID.  */
  updatePropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>;
  /**  Delete a single PropertyScopePropertyHistoryRecord item by ID.  */
  deletePropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>;
  /**  Delete multiple PropertyScopePropertyHistoryRecord items by ID.  */
  deletePropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>;
  /**  Create a single PropertyScopeProperty item.  */
  createPropertyScopeProperty?: Maybe<PropertyScopeProperty>;
  /**  Create multiple PropertyScopeProperty items.  */
  createPropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>;
  /**  Update a single PropertyScopeProperty item by ID.  */
  updatePropertyScopeProperty?: Maybe<PropertyScopeProperty>;
  /**  Update multiple PropertyScopeProperty items by ID.  */
  updatePropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>;
  /**  Delete a single PropertyScopeProperty item by ID.  */
  deletePropertyScopeProperty?: Maybe<PropertyScopeProperty>;
  /**  Delete multiple PropertyScopeProperty items by ID.  */
  deletePropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>;
  /**  Create a single NewsItemHistoryRecord item.  */
  createNewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>;
  /**  Create multiple NewsItemHistoryRecord items.  */
  createNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>;
  /**  Update a single NewsItemHistoryRecord item by ID.  */
  updateNewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>;
  /**  Update multiple NewsItemHistoryRecord items by ID.  */
  updateNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>;
  /**  Delete a single NewsItemHistoryRecord item by ID.  */
  deleteNewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>;
  /**  Delete multiple NewsItemHistoryRecord items by ID.  */
  deleteNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>;
  /**  Create a single NewsItem item.  */
  createNewsItem?: Maybe<NewsItem>;
  /**  Create multiple NewsItem items.  */
  createNewsItems?: Maybe<Array<Maybe<NewsItem>>>;
  /**  Update a single NewsItem item by ID.  */
  updateNewsItem?: Maybe<NewsItem>;
  /**  Update multiple NewsItem items by ID.  */
  updateNewsItems?: Maybe<Array<Maybe<NewsItem>>>;
  /**  Delete a single NewsItem item by ID.  */
  deleteNewsItem?: Maybe<NewsItem>;
  /**  Delete multiple NewsItem items by ID.  */
  deleteNewsItems?: Maybe<Array<Maybe<NewsItem>>>;
  /**  Create a single NewsItemScopeHistoryRecord item.  */
  createNewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>;
  /**  Create multiple NewsItemScopeHistoryRecord items.  */
  createNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>;
  /**  Update a single NewsItemScopeHistoryRecord item by ID.  */
  updateNewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>;
  /**  Update multiple NewsItemScopeHistoryRecord items by ID.  */
  updateNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>;
  /**  Delete a single NewsItemScopeHistoryRecord item by ID.  */
  deleteNewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>;
  /**  Delete multiple NewsItemScopeHistoryRecord items by ID.  */
  deleteNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>;
  /**  Create a single NewsItemScope item.  */
  createNewsItemScope?: Maybe<NewsItemScope>;
  /**  Create multiple NewsItemScope items.  */
  createNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>;
  /**  Update a single NewsItemScope item by ID.  */
  updateNewsItemScope?: Maybe<NewsItemScope>;
  /**  Update multiple NewsItemScope items by ID.  */
  updateNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>;
  /**  Delete a single NewsItemScope item by ID.  */
  deleteNewsItemScope?: Maybe<NewsItemScope>;
  /**  Delete multiple NewsItemScope items by ID.  */
  deleteNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>;
  /**  Create a single NewsItemTemplateHistoryRecord item.  */
  createNewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>;
  /**  Create multiple NewsItemTemplateHistoryRecord items.  */
  createNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>;
  /**  Update a single NewsItemTemplateHistoryRecord item by ID.  */
  updateNewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>;
  /**  Update multiple NewsItemTemplateHistoryRecord items by ID.  */
  updateNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>;
  /**  Delete a single NewsItemTemplateHistoryRecord item by ID.  */
  deleteNewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>;
  /**  Delete multiple NewsItemTemplateHistoryRecord items by ID.  */
  deleteNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>;
  /**  Create a single NewsItemTemplate item.  */
  createNewsItemTemplate?: Maybe<NewsItemTemplate>;
  /**  Create multiple NewsItemTemplate items.  */
  createNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>;
  /**  Update a single NewsItemTemplate item by ID.  */
  updateNewsItemTemplate?: Maybe<NewsItemTemplate>;
  /**  Update multiple NewsItemTemplate items by ID.  */
  updateNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>;
  /**  Delete a single NewsItemTemplate item by ID.  */
  deleteNewsItemTemplate?: Maybe<NewsItemTemplate>;
  /**  Delete multiple NewsItemTemplate items by ID.  */
  deleteNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>;
  /**  Create a single NewsItemUserReadHistoryRecord item.  */
  createNewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>;
  /**  Create multiple NewsItemUserReadHistoryRecord items.  */
  createNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>;
  /**  Update a single NewsItemUserReadHistoryRecord item by ID.  */
  updateNewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>;
  /**  Update multiple NewsItemUserReadHistoryRecord items by ID.  */
  updateNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>;
  /**  Delete a single NewsItemUserReadHistoryRecord item by ID.  */
  deleteNewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>;
  /**  Delete multiple NewsItemUserReadHistoryRecord items by ID.  */
  deleteNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>;
  /**  Create a single NewsItemUserRead item.  */
  createNewsItemUserRead?: Maybe<NewsItemUserRead>;
  /**  Create multiple NewsItemUserRead items.  */
  createNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>;
  /**  Update a single NewsItemUserRead item by ID.  */
  updateNewsItemUserRead?: Maybe<NewsItemUserRead>;
  /**  Update multiple NewsItemUserRead items by ID.  */
  updateNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>;
  /**  Delete a single NewsItemUserRead item by ID.  */
  deleteNewsItemUserRead?: Maybe<NewsItemUserRead>;
  /**  Delete multiple NewsItemUserRead items by ID.  */
  deleteNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>;
  /**  Create a single NewsItemRecipientsExportTaskHistoryRecord item.  */
  createNewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>;
  /**  Create multiple NewsItemRecipientsExportTaskHistoryRecord items.  */
  createNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>;
  /**  Update a single NewsItemRecipientsExportTaskHistoryRecord item by ID.  */
  updateNewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>;
  /**  Update multiple NewsItemRecipientsExportTaskHistoryRecord items by ID.  */
  updateNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>;
  /**  Delete a single NewsItemRecipientsExportTaskHistoryRecord item by ID.  */
  deleteNewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>;
  /**  Delete multiple NewsItemRecipientsExportTaskHistoryRecord items by ID.  */
  deleteNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>;
  /**  Create a single NewsItemRecipientsExportTask item.  */
  createNewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>;
  /**  Create multiple NewsItemRecipientsExportTask items.  */
  createNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>;
  /**  Update a single NewsItemRecipientsExportTask item by ID.  */
  updateNewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>;
  /**  Update multiple NewsItemRecipientsExportTask items by ID.  */
  updateNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>;
  /**  Delete a single NewsItemRecipientsExportTask item by ID.  */
  deleteNewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>;
  /**  Delete multiple NewsItemRecipientsExportTask items by ID.  */
  deleteNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>;
  /**  Create a single NewsItemSharingHistoryRecord item.  */
  createNewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>;
  /**  Create multiple NewsItemSharingHistoryRecord items.  */
  createNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>;
  /**  Update a single NewsItemSharingHistoryRecord item by ID.  */
  updateNewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>;
  /**  Update multiple NewsItemSharingHistoryRecord items by ID.  */
  updateNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>;
  /**  Delete a single NewsItemSharingHistoryRecord item by ID.  */
  deleteNewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>;
  /**  Delete multiple NewsItemSharingHistoryRecord items by ID.  */
  deleteNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>;
  /**  Create a single NewsItemSharing item.  */
  createNewsItemSharing?: Maybe<NewsItemSharing>;
  /**  Create multiple NewsItemSharing items.  */
  createNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>;
  /**  Update a single NewsItemSharing item by ID.  */
  updateNewsItemSharing?: Maybe<NewsItemSharing>;
  /**  Update multiple NewsItemSharing items by ID.  */
  updateNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>;
  /**  Delete a single NewsItemSharing item by ID.  */
  deleteNewsItemSharing?: Maybe<NewsItemSharing>;
  /**  Delete multiple NewsItemSharing items by ID.  */
  deleteNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>;
  /**  Create a single B2BAppHistoryRecord item.  */
  createB2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>;
  /**  Create multiple B2BAppHistoryRecord items.  */
  createB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>;
  /**  Update a single B2BAppHistoryRecord item by ID.  */
  updateB2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>;
  /**  Update multiple B2BAppHistoryRecord items by ID.  */
  updateB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>;
  /**  Delete a single B2BAppHistoryRecord item by ID.  */
  deleteB2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>;
  /**  Delete multiple B2BAppHistoryRecord items by ID.  */
  deleteB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>;
  /**  Create a single B2BApp item.  */
  createB2BApp?: Maybe<B2BApp>;
  /**  Create multiple B2BApp items.  */
  createB2BApps?: Maybe<Array<Maybe<B2BApp>>>;
  /**  Update a single B2BApp item by ID.  */
  updateB2BApp?: Maybe<B2BApp>;
  /**  Update multiple B2BApp items by ID.  */
  updateB2BApps?: Maybe<Array<Maybe<B2BApp>>>;
  /**  Delete a single B2BApp item by ID.  */
  deleteB2BApp?: Maybe<B2BApp>;
  /**  Delete multiple B2BApp items by ID.  */
  deleteB2BApps?: Maybe<Array<Maybe<B2BApp>>>;
  /**  Create a single B2BAppContextHistoryRecord item.  */
  createB2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>;
  /**  Create multiple B2BAppContextHistoryRecord items.  */
  createB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>;
  /**  Update a single B2BAppContextHistoryRecord item by ID.  */
  updateB2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>;
  /**  Update multiple B2BAppContextHistoryRecord items by ID.  */
  updateB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>;
  /**  Delete a single B2BAppContextHistoryRecord item by ID.  */
  deleteB2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>;
  /**  Delete multiple B2BAppContextHistoryRecord items by ID.  */
  deleteB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>;
  /**  Create a single B2BAppContext item.  */
  createB2BAppContext?: Maybe<B2BAppContext>;
  /**  Create multiple B2BAppContext items.  */
  createB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>;
  /**  Update a single B2BAppContext item by ID.  */
  updateB2BAppContext?: Maybe<B2BAppContext>;
  /**  Update multiple B2BAppContext items by ID.  */
  updateB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>;
  /**  Delete a single B2BAppContext item by ID.  */
  deleteB2BAppContext?: Maybe<B2BAppContext>;
  /**  Delete multiple B2BAppContext items by ID.  */
  deleteB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>;
  /**  Create a single B2BAppAccessRightHistoryRecord item.  */
  createB2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>;
  /**  Create multiple B2BAppAccessRightHistoryRecord items.  */
  createB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>;
  /**  Update a single B2BAppAccessRightHistoryRecord item by ID.  */
  updateB2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>;
  /**  Update multiple B2BAppAccessRightHistoryRecord items by ID.  */
  updateB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>;
  /**  Delete a single B2BAppAccessRightHistoryRecord item by ID.  */
  deleteB2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>;
  /**  Delete multiple B2BAppAccessRightHistoryRecord items by ID.  */
  deleteB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>;
  /**  Create a single B2BAppAccessRight item.  */
  createB2BAppAccessRight?: Maybe<B2BAppAccessRight>;
  /**  Create multiple B2BAppAccessRight items.  */
  createB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>;
  /**  Update a single B2BAppAccessRight item by ID.  */
  updateB2BAppAccessRight?: Maybe<B2BAppAccessRight>;
  /**  Update multiple B2BAppAccessRight items by ID.  */
  updateB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>;
  /**  Delete a single B2BAppAccessRight item by ID.  */
  deleteB2BAppAccessRight?: Maybe<B2BAppAccessRight>;
  /**  Delete multiple B2BAppAccessRight items by ID.  */
  deleteB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>;
  /**  Create a single B2CAppHistoryRecord item.  */
  createB2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>;
  /**  Create multiple B2CAppHistoryRecord items.  */
  createB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>;
  /**  Update a single B2CAppHistoryRecord item by ID.  */
  updateB2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>;
  /**  Update multiple B2CAppHistoryRecord items by ID.  */
  updateB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>;
  /**  Delete a single B2CAppHistoryRecord item by ID.  */
  deleteB2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>;
  /**  Delete multiple B2CAppHistoryRecord items by ID.  */
  deleteB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>;
  /**  Create a single B2CApp item.  */
  createB2CApp?: Maybe<B2CApp>;
  /**  Create multiple B2CApp items.  */
  createB2CApps?: Maybe<Array<Maybe<B2CApp>>>;
  /**  Update a single B2CApp item by ID.  */
  updateB2CApp?: Maybe<B2CApp>;
  /**  Update multiple B2CApp items by ID.  */
  updateB2CApps?: Maybe<Array<Maybe<B2CApp>>>;
  /**  Delete a single B2CApp item by ID.  */
  deleteB2CApp?: Maybe<B2CApp>;
  /**  Delete multiple B2CApp items by ID.  */
  deleteB2CApps?: Maybe<Array<Maybe<B2CApp>>>;
  /**  Create a single B2CAppAccessRightHistoryRecord item.  */
  createB2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>;
  /**  Create multiple B2CAppAccessRightHistoryRecord items.  */
  createB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>;
  /**  Update a single B2CAppAccessRightHistoryRecord item by ID.  */
  updateB2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>;
  /**  Update multiple B2CAppAccessRightHistoryRecord items by ID.  */
  updateB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>;
  /**  Delete a single B2CAppAccessRightHistoryRecord item by ID.  */
  deleteB2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>;
  /**  Delete multiple B2CAppAccessRightHistoryRecord items by ID.  */
  deleteB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>;
  /**  Create a single B2CAppAccessRight item.  */
  createB2CAppAccessRight?: Maybe<B2CAppAccessRight>;
  /**  Create multiple B2CAppAccessRight items.  */
  createB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>;
  /**  Update a single B2CAppAccessRight item by ID.  */
  updateB2CAppAccessRight?: Maybe<B2CAppAccessRight>;
  /**  Update multiple B2CAppAccessRight items by ID.  */
  updateB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>;
  /**  Delete a single B2CAppAccessRight item by ID.  */
  deleteB2CAppAccessRight?: Maybe<B2CAppAccessRight>;
  /**  Delete multiple B2CAppAccessRight items by ID.  */
  deleteB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>;
  /**  Create a single B2CAppBuildHistoryRecord item.  */
  createB2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>;
  /**  Create multiple B2CAppBuildHistoryRecord items.  */
  createB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>;
  /**  Update a single B2CAppBuildHistoryRecord item by ID.  */
  updateB2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>;
  /**  Update multiple B2CAppBuildHistoryRecord items by ID.  */
  updateB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>;
  /**  Delete a single B2CAppBuildHistoryRecord item by ID.  */
  deleteB2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>;
  /**  Delete multiple B2CAppBuildHistoryRecord items by ID.  */
  deleteB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>;
  /**  Create a single B2CAppBuild item.  */
  createB2CAppBuild?: Maybe<B2CAppBuild>;
  /**  Create multiple B2CAppBuild items.  */
  createB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>;
  /**  Update a single B2CAppBuild item by ID.  */
  updateB2CAppBuild?: Maybe<B2CAppBuild>;
  /**  Update multiple B2CAppBuild items by ID.  */
  updateB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>;
  /**  Delete a single B2CAppBuild item by ID.  */
  deleteB2CAppBuild?: Maybe<B2CAppBuild>;
  /**  Delete multiple B2CAppBuild items by ID.  */
  deleteB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>;
  /**  Create a single B2CAppPropertyHistoryRecord item.  */
  createB2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>;
  /**  Create multiple B2CAppPropertyHistoryRecord items.  */
  createB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>;
  /**  Update a single B2CAppPropertyHistoryRecord item by ID.  */
  updateB2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>;
  /**  Update multiple B2CAppPropertyHistoryRecord items by ID.  */
  updateB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>;
  /**  Delete a single B2CAppPropertyHistoryRecord item by ID.  */
  deleteB2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>;
  /**  Delete multiple B2CAppPropertyHistoryRecord items by ID.  */
  deleteB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>;
  /**  Create a single B2CAppProperty item.  */
  createB2CAppProperty?: Maybe<B2CAppProperty>;
  /**  Create multiple B2CAppProperty items.  */
  createB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>;
  /**  Update a single B2CAppProperty item by ID.  */
  updateB2CAppProperty?: Maybe<B2CAppProperty>;
  /**  Update multiple B2CAppProperty items by ID.  */
  updateB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>;
  /**  Delete a single B2CAppProperty item by ID.  */
  deleteB2CAppProperty?: Maybe<B2CAppProperty>;
  /**  Delete multiple B2CAppProperty items by ID.  */
  deleteB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>;
  /**  Create a single B2BAppPromoBlockHistoryRecord item.  */
  createB2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>;
  /**  Create multiple B2BAppPromoBlockHistoryRecord items.  */
  createB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>;
  /**  Update a single B2BAppPromoBlockHistoryRecord item by ID.  */
  updateB2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>;
  /**  Update multiple B2BAppPromoBlockHistoryRecord items by ID.  */
  updateB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>;
  /**  Delete a single B2BAppPromoBlockHistoryRecord item by ID.  */
  deleteB2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>;
  /**  Delete multiple B2BAppPromoBlockHistoryRecord items by ID.  */
  deleteB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>;
  /**  Create a single B2BAppPromoBlock item.  */
  createB2BAppPromoBlock?: Maybe<B2BAppPromoBlock>;
  /**  Create multiple B2BAppPromoBlock items.  */
  createB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>;
  /**  Update a single B2BAppPromoBlock item by ID.  */
  updateB2BAppPromoBlock?: Maybe<B2BAppPromoBlock>;
  /**  Update multiple B2BAppPromoBlock items by ID.  */
  updateB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>;
  /**  Delete a single B2BAppPromoBlock item by ID.  */
  deleteB2BAppPromoBlock?: Maybe<B2BAppPromoBlock>;
  /**  Delete multiple B2BAppPromoBlock items by ID.  */
  deleteB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>;
  /**  Create a single MessageAppBlackListHistoryRecord item.  */
  createMessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>;
  /**  Create multiple MessageAppBlackListHistoryRecord items.  */
  createMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>;
  /**  Update a single MessageAppBlackListHistoryRecord item by ID.  */
  updateMessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>;
  /**  Update multiple MessageAppBlackListHistoryRecord items by ID.  */
  updateMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>;
  /**  Delete a single MessageAppBlackListHistoryRecord item by ID.  */
  deleteMessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>;
  /**  Delete multiple MessageAppBlackListHistoryRecord items by ID.  */
  deleteMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>;
  /**  Create a single MessageAppBlackList item.  */
  createMessageAppBlackList?: Maybe<MessageAppBlackList>;
  /**  Create multiple MessageAppBlackList items.  */
  createMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>;
  /**  Update a single MessageAppBlackList item by ID.  */
  updateMessageAppBlackList?: Maybe<MessageAppBlackList>;
  /**  Update multiple MessageAppBlackList items by ID.  */
  updateMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>;
  /**  Delete a single MessageAppBlackList item by ID.  */
  deleteMessageAppBlackList?: Maybe<MessageAppBlackList>;
  /**  Delete multiple MessageAppBlackList items by ID.  */
  deleteMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>;
  /**  Create a single B2BAppPermissionHistoryRecord item.  */
  createB2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>;
  /**  Create multiple B2BAppPermissionHistoryRecord items.  */
  createB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>;
  /**  Update a single B2BAppPermissionHistoryRecord item by ID.  */
  updateB2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>;
  /**  Update multiple B2BAppPermissionHistoryRecord items by ID.  */
  updateB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>;
  /**  Delete a single B2BAppPermissionHistoryRecord item by ID.  */
  deleteB2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>;
  /**  Delete multiple B2BAppPermissionHistoryRecord items by ID.  */
  deleteB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>;
  /**  Create a single B2BAppPermission item.  */
  createB2BAppPermission?: Maybe<B2BAppPermission>;
  /**  Create multiple B2BAppPermission items.  */
  createB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>;
  /**  Update a single B2BAppPermission item by ID.  */
  updateB2BAppPermission?: Maybe<B2BAppPermission>;
  /**  Update multiple B2BAppPermission items by ID.  */
  updateB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>;
  /**  Delete a single B2BAppPermission item by ID.  */
  deleteB2BAppPermission?: Maybe<B2BAppPermission>;
  /**  Delete multiple B2BAppPermission items by ID.  */
  deleteB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>;
  /**  Create a single B2BAppRoleHistoryRecord item.  */
  createB2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>;
  /**  Create multiple B2BAppRoleHistoryRecord items.  */
  createB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>;
  /**  Update a single B2BAppRoleHistoryRecord item by ID.  */
  updateB2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>;
  /**  Update multiple B2BAppRoleHistoryRecord items by ID.  */
  updateB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>;
  /**  Delete a single B2BAppRoleHistoryRecord item by ID.  */
  deleteB2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>;
  /**  Delete multiple B2BAppRoleHistoryRecord items by ID.  */
  deleteB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>;
  /**  Create a single B2BAppRole item.  */
  createB2BAppRole?: Maybe<B2BAppRole>;
  /**  Create multiple B2BAppRole items.  */
  createB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>;
  /**  Update a single B2BAppRole item by ID.  */
  updateB2BAppRole?: Maybe<B2BAppRole>;
  /**  Update multiple B2BAppRole items by ID.  */
  updateB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>;
  /**  Delete a single B2BAppRole item by ID.  */
  deleteB2BAppRole?: Maybe<B2BAppRole>;
  /**  Delete multiple B2BAppRole items by ID.  */
  deleteB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>;
  /**  Create a single B2BAppAccessRightSetHistoryRecord item.  */
  createB2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>;
  /**  Create multiple B2BAppAccessRightSetHistoryRecord items.  */
  createB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>;
  /**  Update a single B2BAppAccessRightSetHistoryRecord item by ID.  */
  updateB2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>;
  /**  Update multiple B2BAppAccessRightSetHistoryRecord items by ID.  */
  updateB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>;
  /**  Delete a single B2BAppAccessRightSetHistoryRecord item by ID.  */
  deleteB2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>;
  /**  Delete multiple B2BAppAccessRightSetHistoryRecord items by ID.  */
  deleteB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>;
  /**  Create a single B2BAppAccessRightSet item.  */
  createB2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>;
  /**  Create multiple B2BAppAccessRightSet items.  */
  createB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>;
  /**  Update a single B2BAppAccessRightSet item by ID.  */
  updateB2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>;
  /**  Update multiple B2BAppAccessRightSet items by ID.  */
  updateB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>;
  /**  Delete a single B2BAppAccessRightSet item by ID.  */
  deleteB2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>;
  /**  Delete multiple B2BAppAccessRightSet items by ID.  */
  deleteB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>;
  /**  Create a single B2BAppNewsSharingConfigHistoryRecord item.  */
  createB2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>;
  /**  Create multiple B2BAppNewsSharingConfigHistoryRecord items.  */
  createB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>;
  /**  Update a single B2BAppNewsSharingConfigHistoryRecord item by ID.  */
  updateB2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>;
  /**  Update multiple B2BAppNewsSharingConfigHistoryRecord items by ID.  */
  updateB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>;
  /**  Delete a single B2BAppNewsSharingConfigHistoryRecord item by ID.  */
  deleteB2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>;
  /**  Delete multiple B2BAppNewsSharingConfigHistoryRecord items by ID.  */
  deleteB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>;
  /**  Create a single B2BAppNewsSharingConfig item.  */
  createB2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>;
  /**  Create multiple B2BAppNewsSharingConfig items.  */
  createB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>;
  /**  Update a single B2BAppNewsSharingConfig item by ID.  */
  updateB2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>;
  /**  Update multiple B2BAppNewsSharingConfig items by ID.  */
  updateB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>;
  /**  Delete a single B2BAppNewsSharingConfig item by ID.  */
  deleteB2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>;
  /**  Delete multiple B2BAppNewsSharingConfig items by ID.  */
  deleteB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>;
  /**  Create a single B2CAppMessageSettingHistoryRecord item.  */
  createB2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>;
  /**  Create multiple B2CAppMessageSettingHistoryRecord items.  */
  createB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>;
  /**  Update a single B2CAppMessageSettingHistoryRecord item by ID.  */
  updateB2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>;
  /**  Update multiple B2CAppMessageSettingHistoryRecord items by ID.  */
  updateB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>;
  /**  Delete a single B2CAppMessageSettingHistoryRecord item by ID.  */
  deleteB2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>;
  /**  Delete multiple B2CAppMessageSettingHistoryRecord items by ID.  */
  deleteB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>;
  /**  Create a single B2CAppMessageSetting item.  */
  createB2CAppMessageSetting?: Maybe<B2CAppMessageSetting>;
  /**  Create multiple B2CAppMessageSetting items.  */
  createB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>;
  /**  Update a single B2CAppMessageSetting item by ID.  */
  updateB2CAppMessageSetting?: Maybe<B2CAppMessageSetting>;
  /**  Update multiple B2CAppMessageSetting items by ID.  */
  updateB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>;
  /**  Delete a single B2CAppMessageSetting item by ID.  */
  deleteB2CAppMessageSetting?: Maybe<B2CAppMessageSetting>;
  /**  Delete multiple B2CAppMessageSetting items by ID.  */
  deleteB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>;
  /**  Create a single MobileFeatureConfigHistoryRecord item.  */
  createMobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>;
  /**  Create multiple MobileFeatureConfigHistoryRecord items.  */
  createMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>;
  /**  Update a single MobileFeatureConfigHistoryRecord item by ID.  */
  updateMobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>;
  /**  Update multiple MobileFeatureConfigHistoryRecord items by ID.  */
  updateMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>;
  /**  Delete a single MobileFeatureConfigHistoryRecord item by ID.  */
  deleteMobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>;
  /**  Delete multiple MobileFeatureConfigHistoryRecord items by ID.  */
  deleteMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>;
  /**  Create a single MobileFeatureConfig item.  */
  createMobileFeatureConfig?: Maybe<MobileFeatureConfig>;
  /**  Create multiple MobileFeatureConfig items.  */
  createMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>;
  /**  Update a single MobileFeatureConfig item by ID.  */
  updateMobileFeatureConfig?: Maybe<MobileFeatureConfig>;
  /**  Update multiple MobileFeatureConfig items by ID.  */
  updateMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>;
  /**  Delete a single MobileFeatureConfig item by ID.  */
  deleteMobileFeatureConfig?: Maybe<MobileFeatureConfig>;
  /**  Delete multiple MobileFeatureConfig items by ID.  */
  deleteMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>;
  /**  Create a single MarketCategoryHistoryRecord item.  */
  createMarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>;
  /**  Create multiple MarketCategoryHistoryRecord items.  */
  createMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>;
  /**  Update a single MarketCategoryHistoryRecord item by ID.  */
  updateMarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>;
  /**  Update multiple MarketCategoryHistoryRecord items by ID.  */
  updateMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>;
  /**  Delete a single MarketCategoryHistoryRecord item by ID.  */
  deleteMarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>;
  /**  Delete multiple MarketCategoryHistoryRecord items by ID.  */
  deleteMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>;
  /**  Create a single MarketCategory item.  */
  createMarketCategory?: Maybe<MarketCategory>;
  /**  Create multiple MarketCategory items.  */
  createMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>;
  /**  Update a single MarketCategory item by ID.  */
  updateMarketCategory?: Maybe<MarketCategory>;
  /**  Update multiple MarketCategory items by ID.  */
  updateMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>;
  /**  Delete a single MarketCategory item by ID.  */
  deleteMarketCategory?: Maybe<MarketCategory>;
  /**  Delete multiple MarketCategory items by ID.  */
  deleteMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>;
  /**  Create a single MarketItemHistoryRecord item.  */
  createMarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>;
  /**  Create multiple MarketItemHistoryRecord items.  */
  createMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>;
  /**  Update a single MarketItemHistoryRecord item by ID.  */
  updateMarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>;
  /**  Update multiple MarketItemHistoryRecord items by ID.  */
  updateMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>;
  /**  Delete a single MarketItemHistoryRecord item by ID.  */
  deleteMarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>;
  /**  Delete multiple MarketItemHistoryRecord items by ID.  */
  deleteMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>;
  /**  Create a single MarketItem item.  */
  createMarketItem?: Maybe<MarketItem>;
  /**  Create multiple MarketItem items.  */
  createMarketItems?: Maybe<Array<Maybe<MarketItem>>>;
  /**  Update a single MarketItem item by ID.  */
  updateMarketItem?: Maybe<MarketItem>;
  /**  Update multiple MarketItem items by ID.  */
  updateMarketItems?: Maybe<Array<Maybe<MarketItem>>>;
  /**  Delete a single MarketItem item by ID.  */
  deleteMarketItem?: Maybe<MarketItem>;
  /**  Delete multiple MarketItem items by ID.  */
  deleteMarketItems?: Maybe<Array<Maybe<MarketItem>>>;
  /**  Create a single InvoiceHistoryRecord item.  */
  createInvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>;
  /**  Create multiple InvoiceHistoryRecord items.  */
  createInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>;
  /**  Update a single InvoiceHistoryRecord item by ID.  */
  updateInvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>;
  /**  Update multiple InvoiceHistoryRecord items by ID.  */
  updateInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>;
  /**  Delete a single InvoiceHistoryRecord item by ID.  */
  deleteInvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>;
  /**  Delete multiple InvoiceHistoryRecord items by ID.  */
  deleteInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>;
  /**  Create a single Invoice item.  */
  createInvoice?: Maybe<Invoice>;
  /**  Create multiple Invoice items.  */
  createInvoices?: Maybe<Array<Maybe<Invoice>>>;
  /**  Update a single Invoice item by ID.  */
  updateInvoice?: Maybe<Invoice>;
  /**  Update multiple Invoice items by ID.  */
  updateInvoices?: Maybe<Array<Maybe<Invoice>>>;
  /**  Delete a single Invoice item by ID.  */
  deleteInvoice?: Maybe<Invoice>;
  /**  Delete multiple Invoice items by ID.  */
  deleteInvoices?: Maybe<Array<Maybe<Invoice>>>;
  /**  Create a single MarketItemFileHistoryRecord item.  */
  createMarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>;
  /**  Create multiple MarketItemFileHistoryRecord items.  */
  createMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>;
  /**  Update a single MarketItemFileHistoryRecord item by ID.  */
  updateMarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>;
  /**  Update multiple MarketItemFileHistoryRecord items by ID.  */
  updateMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>;
  /**  Delete a single MarketItemFileHistoryRecord item by ID.  */
  deleteMarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>;
  /**  Delete multiple MarketItemFileHistoryRecord items by ID.  */
  deleteMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>;
  /**  Create a single MarketItemFile item.  */
  createMarketItemFile?: Maybe<MarketItemFile>;
  /**  Create multiple MarketItemFile items.  */
  createMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>;
  /**  Update a single MarketItemFile item by ID.  */
  updateMarketItemFile?: Maybe<MarketItemFile>;
  /**  Update multiple MarketItemFile items by ID.  */
  updateMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>;
  /**  Delete a single MarketItemFile item by ID.  */
  deleteMarketItemFile?: Maybe<MarketItemFile>;
  /**  Delete multiple MarketItemFile items by ID.  */
  deleteMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>;
  /**  Create a single MarketItemPriceHistoryRecord item.  */
  createMarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>;
  /**  Create multiple MarketItemPriceHistoryRecord items.  */
  createMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>;
  /**  Update a single MarketItemPriceHistoryRecord item by ID.  */
  updateMarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>;
  /**  Update multiple MarketItemPriceHistoryRecord items by ID.  */
  updateMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>;
  /**  Delete a single MarketItemPriceHistoryRecord item by ID.  */
  deleteMarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>;
  /**  Delete multiple MarketItemPriceHistoryRecord items by ID.  */
  deleteMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>;
  /**  Create a single MarketItemPrice item.  */
  createMarketItemPrice?: Maybe<MarketItemPrice>;
  /**  Create multiple MarketItemPrice items.  */
  createMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>;
  /**  Update a single MarketItemPrice item by ID.  */
  updateMarketItemPrice?: Maybe<MarketItemPrice>;
  /**  Update multiple MarketItemPrice items by ID.  */
  updateMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>;
  /**  Delete a single MarketItemPrice item by ID.  */
  deleteMarketItemPrice?: Maybe<MarketItemPrice>;
  /**  Delete multiple MarketItemPrice items by ID.  */
  deleteMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>;
  /**  Create a single MarketPriceScopeHistoryRecord item.  */
  createMarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>;
  /**  Create multiple MarketPriceScopeHistoryRecord items.  */
  createMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>;
  /**  Update a single MarketPriceScopeHistoryRecord item by ID.  */
  updateMarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>;
  /**  Update multiple MarketPriceScopeHistoryRecord items by ID.  */
  updateMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>;
  /**  Delete a single MarketPriceScopeHistoryRecord item by ID.  */
  deleteMarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>;
  /**  Delete multiple MarketPriceScopeHistoryRecord items by ID.  */
  deleteMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>;
  /**  Create a single MarketPriceScope item.  */
  createMarketPriceScope?: Maybe<MarketPriceScope>;
  /**  Create multiple MarketPriceScope items.  */
  createMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>;
  /**  Update a single MarketPriceScope item by ID.  */
  updateMarketPriceScope?: Maybe<MarketPriceScope>;
  /**  Update multiple MarketPriceScope items by ID.  */
  updateMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>;
  /**  Delete a single MarketPriceScope item by ID.  */
  deleteMarketPriceScope?: Maybe<MarketPriceScope>;
  /**  Delete multiple MarketPriceScope items by ID.  */
  deleteMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>;
  /**  Create a single MarketSettingHistoryRecord item.  */
  createMarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>;
  /**  Create multiple MarketSettingHistoryRecord items.  */
  createMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>;
  /**  Update a single MarketSettingHistoryRecord item by ID.  */
  updateMarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>;
  /**  Update multiple MarketSettingHistoryRecord items by ID.  */
  updateMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>;
  /**  Delete a single MarketSettingHistoryRecord item by ID.  */
  deleteMarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>;
  /**  Delete multiple MarketSettingHistoryRecord items by ID.  */
  deleteMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>;
  /**  Create a single MarketSetting item.  */
  createMarketSetting?: Maybe<MarketSetting>;
  /**  Create multiple MarketSetting items.  */
  createMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>;
  /**  Update a single MarketSetting item by ID.  */
  updateMarketSetting?: Maybe<MarketSetting>;
  /**  Update multiple MarketSetting items by ID.  */
  updateMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>;
  /**  Delete a single MarketSetting item by ID.  */
  deleteMarketSetting?: Maybe<MarketSetting>;
  /**  Delete multiple MarketSetting items by ID.  */
  deleteMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>;
  /**  Create a single DocumentCategoryHistoryRecord item.  */
  createDocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>;
  /**  Create multiple DocumentCategoryHistoryRecord items.  */
  createDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>;
  /**  Update a single DocumentCategoryHistoryRecord item by ID.  */
  updateDocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>;
  /**  Update multiple DocumentCategoryHistoryRecord items by ID.  */
  updateDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>;
  /**  Delete a single DocumentCategoryHistoryRecord item by ID.  */
  deleteDocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>;
  /**  Delete multiple DocumentCategoryHistoryRecord items by ID.  */
  deleteDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>;
  /**  Create a single DocumentCategory item.  */
  createDocumentCategory?: Maybe<DocumentCategory>;
  /**  Create multiple DocumentCategory items.  */
  createDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  /**  Update a single DocumentCategory item by ID.  */
  updateDocumentCategory?: Maybe<DocumentCategory>;
  /**  Update multiple DocumentCategory items by ID.  */
  updateDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  /**  Delete a single DocumentCategory item by ID.  */
  deleteDocumentCategory?: Maybe<DocumentCategory>;
  /**  Delete multiple DocumentCategory items by ID.  */
  deleteDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  /**  Create a single DocumentHistoryRecord item.  */
  createDocumentHistoryRecord?: Maybe<DocumentHistoryRecord>;
  /**  Create multiple DocumentHistoryRecord items.  */
  createDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>;
  /**  Update a single DocumentHistoryRecord item by ID.  */
  updateDocumentHistoryRecord?: Maybe<DocumentHistoryRecord>;
  /**  Update multiple DocumentHistoryRecord items by ID.  */
  updateDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>;
  /**  Delete a single DocumentHistoryRecord item by ID.  */
  deleteDocumentHistoryRecord?: Maybe<DocumentHistoryRecord>;
  /**  Delete multiple DocumentHistoryRecord items by ID.  */
  deleteDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>;
  /**  Create a single Document item.  */
  createDocument?: Maybe<Document>;
  /**  Create multiple Document items.  */
  createDocuments?: Maybe<Array<Maybe<Document>>>;
  /**  Update a single Document item by ID.  */
  updateDocument?: Maybe<Document>;
  /**  Update multiple Document items by ID.  */
  updateDocuments?: Maybe<Array<Maybe<Document>>>;
  /**  Delete a single Document item by ID.  */
  deleteDocument?: Maybe<Document>;
  /**  Delete multiple Document items by ID.  */
  deleteDocuments?: Maybe<Array<Maybe<Document>>>;
  /**  Create a single WebhookHistoryRecord item.  */
  createWebhookHistoryRecord?: Maybe<WebhookHistoryRecord>;
  /**  Create multiple WebhookHistoryRecord items.  */
  createWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>;
  /**  Update a single WebhookHistoryRecord item by ID.  */
  updateWebhookHistoryRecord?: Maybe<WebhookHistoryRecord>;
  /**  Update multiple WebhookHistoryRecord items by ID.  */
  updateWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>;
  /**  Delete a single WebhookHistoryRecord item by ID.  */
  deleteWebhookHistoryRecord?: Maybe<WebhookHistoryRecord>;
  /**  Delete multiple WebhookHistoryRecord items by ID.  */
  deleteWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>;
  /**  Create a single Webhook item.  */
  createWebhook?: Maybe<Webhook>;
  /**  Create multiple Webhook items.  */
  createWebhooks?: Maybe<Array<Maybe<Webhook>>>;
  /**  Update a single Webhook item by ID.  */
  updateWebhook?: Maybe<Webhook>;
  /**  Update multiple Webhook items by ID.  */
  updateWebhooks?: Maybe<Array<Maybe<Webhook>>>;
  /**  Delete a single Webhook item by ID.  */
  deleteWebhook?: Maybe<Webhook>;
  /**  Delete multiple Webhook items by ID.  */
  deleteWebhooks?: Maybe<Array<Maybe<Webhook>>>;
  /**  Create a single WebhookSubscriptionHistoryRecord item.  */
  createWebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>;
  /**  Create multiple WebhookSubscriptionHistoryRecord items.  */
  createWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>;
  /**  Update a single WebhookSubscriptionHistoryRecord item by ID.  */
  updateWebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>;
  /**  Update multiple WebhookSubscriptionHistoryRecord items by ID.  */
  updateWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>;
  /**  Delete a single WebhookSubscriptionHistoryRecord item by ID.  */
  deleteWebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>;
  /**  Delete multiple WebhookSubscriptionHistoryRecord items by ID.  */
  deleteWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>;
  /**  Create a single WebhookSubscription item.  */
  createWebhookSubscription?: Maybe<WebhookSubscription>;
  /**  Create multiple WebhookSubscription items.  */
  createWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>;
  /**  Update a single WebhookSubscription item by ID.  */
  updateWebhookSubscription?: Maybe<WebhookSubscription>;
  /**  Update multiple WebhookSubscription items by ID.  */
  updateWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>;
  /**  Delete a single WebhookSubscription item by ID.  */
  deleteWebhookSubscription?: Maybe<WebhookSubscription>;
  /**  Delete multiple WebhookSubscription items by ID.  */
  deleteWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>;
  _internalScheduleTaskByName?: Maybe<_InternalScheduleTaskByNameOutput>;
  /**
   * Registers new user and sends notification
   *
   * User will be registered only in case of correct provided token of phone confirmation action. After successful registration, phone confirmation action will be marked as completed and will not be allowed for further usage
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "registerNewUser",
   *   "variable": [
   *     "data",
   *     "confirmPhoneActionToken"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Unable to find confirm phone action",
   *   "messageForUser": "api.user.registerNewUser.UNABLE_TO_FIND_CONFIRM_PHONE_ACTION"
   * }`
   *
   * `{
   *   "mutation": "registerNewUser",
   *   "variable": [
   *     "data",
   *     "phone"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PHONE_FORMAT",
   *   "message": "Wrong format of provided phone number",
   *   "messageForUser": "api.common.WRONG_PHONE_FORMAT",
   *   "correctExample": "+79991234567"
   * }`
   *
   * `{
   *   "mutation": "registerNewUser",
   *   "variable": [
   *     "data",
   *     "confirmPhoneActionToken"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NO_CONFIRM_PHONE_ACTION_TOKEN",
   *   "message": "\"confirmPhoneActionToken\" cannot be empty",
   *   "messageForUser": "api.user.registerNewUser.NO_CONFIRM_PHONE_ACTION_TOKEN"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_PASSWORD_LENGTH",
   *   "message": "Password length must be between 8 and 128 characters",
   *   "messageForUser": "api.user.INVALID_PASSWORD_LENGTH",
   *   "messageInterpolation": {
   *     "min": 8,
   *     "max": 128
   *   }
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_EMAIL",
   *   "message": "Password must not contain email",
   *   "messageForUser": "api.user.PASSWORD_CONTAINS_EMAIL"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_PHONE",
   *   "message": "Password must not contain phone",
   *   "messageForUser": "api.user.PASSWORD_CONTAINS_PHONE"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_IS_FREQUENTLY_USED",
   *   "message": "The password is too simple. We found it in the list of stolen passwords. You need to use something more secure",
   *   "messageForUser": "api.user.PASSWORD_IS_FREQUENTLY_USED"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONSISTS_OF_SMALL_SET_OF_CHARACTERS",
   *   "message": "Password must contain at least 4 different characters",
   *   "messageForUser": "api.user.PASSWORD_CONSISTS_OF_SMALL_SET_OF_CHARACTERS",
   *   "messageInterpolation": {
   *     "min": 4
   *   }
   * }`
   *
   * `{
   *   "mutation": "registerNewUser",
   *   "variable": [
   *     "data",
   *     "phone"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_UNIQUE",
   *   "message": "User with specified phone already exists",
   *   "messageForUser": "api.user.registerNewUser.USER_WITH_SPECIFIED_PHONE_ALREADY_EXISTS"
   * }`
   *
   * `{
   *   "mutation": "registerNewUser",
   *   "variable": [
   *     "data",
   *     "email"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_UNIQUE",
   *   "message": "User with specified email already exists",
   *   "messageForUser": "api.user.registerNewUser.USER_WITH_SPECIFIED_EMAIL_ALREADY_EXISTS"
   * }`
   *
   * `{
   *   "mutation": "registerNewUser",
   *   "code": "INTERNAL_ERROR",
   *   "type": "UNABLE_TO_CREATE_USER",
   *   "message": "Unable to create user",
   *   "messageForUser": "api.user.registerNewUser.UNABLE_TO_CREATE_USER"
   * }`
   */
  registerNewUser?: Maybe<User>;
  authenticateUserWithPhoneAndPassword?: Maybe<AuthenticateUserWithPhoneAndPasswordOutput>;
  /**
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PASSWORD_FORMAT",
   *   "message": "Password must be in string format",
   *   "messageForUser": "api.user.WRONG_PASSWORD_FORMAT"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_PASSWORD_LENGTH",
   *   "message": "Password length must be between 8 and 128 characters",
   *   "messageForUser": "api.user.INVALID_PASSWORD_LENGTH",
   *   "messageInterpolation": {
   *     "min": 8,
   *     "max": 128
   *   }
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_EMAIL",
   *   "message": "Password must not contain email",
   *   "messageForUser": "api.user.PASSWORD_CONTAINS_EMAIL"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONTAINS_PHONE",
   *   "message": "Password must not contain phone",
   *   "messageForUser": "api.user.PASSWORD_CONTAINS_PHONE"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_IS_FREQUENTLY_USED",
   *   "message": "The password is too simple. We found it in the list of stolen passwords. You need to use something more secure",
   *   "messageForUser": "api.user.PASSWORD_IS_FREQUENTLY_USED"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "password"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "PASSWORD_CONSISTS_OF_SMALL_SET_OF_CHARACTERS",
   *   "message": "Password must contain at least 4 different characters",
   *   "messageForUser": "api.user.PASSWORD_CONSISTS_OF_SMALL_SET_OF_CHARACTERS",
   *   "messageInterpolation": {
   *     "min": 4
   *   }
   * }`
   *
   * `{
   *   "mutation": "changePasswordWithToken",
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOKEN_NOT_FOUND",
   *   "message": "Unable to find non-expired ConfirmPhoneAction by specified token",
   *   "messageForUser": "api.user.changePasswordWithToken.TOKEN_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "changePasswordWithToken",
   *   "variable": [
   *     "data",
   *     "phone"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "USER_NOT_FOUND",
   *   "message": "Unable to find user with specified phone",
   *   "messageForUser": "api.user.changePasswordWithToken.USER_NOT_FOUND"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number",
   *   "query": "checkUserExistence"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}",
   *   "query": "checkUserExistence"
   * }`
   */
  changePasswordWithToken?: Maybe<ChangePasswordWithTokenOutput>;
  /**
   * Send confirmation phone SMS message and return confirmation token. You can use the token for completeConfirmPhoneAction mutation. And then use the token in other mutations to prove that the phone number is verified
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "variable": [
   *     "data",
   *     "captcha"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "api.user.CAPTCHA_CHECK_FAILED"
   * }`
   *
   * `{
   *   "mutation": "startConfirmPhoneAction",
   *   "variable": [
   *     "data",
   *     "phone"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PHONE_FORMAT",
   *   "message": "Wrong format of provided phone number",
   *   "correctExample": "+79991234567",
   *   "messageForUser": "api.common.WRONG_PHONE_FORMAT"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MANY_REQUESTS",
   *   "message": "You have to wait {secondsRemaining} seconds to be able to send request again",
   *   "messageForUser": "api.user.TOO_MANY_REQUESTS"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_PHONE_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests for this phone number. Try again tomorrow"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_IP_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests from this ip address. Try again tomorrow"
   * }`
   */
  startConfirmPhoneAction?: Maybe<StartConfirmPhoneActionOutput>;
  /**
   * Resend the confirm phone SMS message for existing token
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "variable": [
   *     "data",
   *     "captcha"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "api.user.CAPTCHA_CHECK_FAILED"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Confirm phone action was expired or it could not be found. Try to initiate phone confirmation again",
   *   "messageForUser": "api.user.UNABLE_TO_FIND_CONFIRM_PHONE_ACTION"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MANY_REQUESTS",
   *   "message": "You have to wait {secondsRemaining} seconds to be able to send request again",
   *   "messageForUser": "api.user.TOO_MANY_REQUESTS"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_PHONE_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests for this phone number. Try again tomorrow"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_FOR_IP_DAY_LIMIT_REACHED",
   *   "message": "Too many sms requests from this ip address. Try again tomorrow"
   * }`
   */
  resendConfirmPhoneActionSms?: Maybe<ResendConfirmPhoneActionSmsOutput>;
  /**
   * The final step of a phone number confirmation. You should use the token from startConfirmPhoneAction and a secret code from the confirm phone SMS message. After success call, you can use the token in other mutations to prove that you have access to the phone number
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "variable": [
   *     "data",
   *     "captcha"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "api.user.CAPTCHA_CHECK_FAILED"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Confirm phone action was expired or it could not be found. Try to initiate phone confirmation again",
   *   "messageForUser": "api.user.UNABLE_TO_FIND_CONFIRM_PHONE_ACTION"
   * }`
   *
   * `{
   *   "mutation": "completeConfirmPhoneAction",
   *   "variable": [
   *     "data",
   *     "smsCode"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_CODE_EXPIRED",
   *   "message": "SMS code expired. Try to initiate phone confirmation again",
   *   "messageForUser": "api.user.completeConfirmPhoneAction.SMS_CODE_EXPIRED"
   * }`
   *
   * `{
   *   "mutation": "completeConfirmPhoneAction",
   *   "variable": [
   *     "data",
   *     "smsCode"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_CODE_MAX_RETRIES_REACHED",
   *   "message": "Max retries reached for SMS code confirmation. Try to initiate phone confirmation again",
   *   "messageForUser": "api.user.completeConfirmPhoneAction.SMS_CODE_MAX_RETRIES_REACHED"
   * }`
   *
   * `{
   *   "mutation": "completeConfirmPhoneAction",
   *   "variable": [
   *     "data",
   *     "smsCode"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "SMS_CODE_VERIFICATION_FAILED",
   *   "message": "SMS code verification mismatch",
   *   "messageForUser": "api.user.completeConfirmPhoneAction.SMS_CODE_VERIFICATION_FAILED"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOO_MANY_REQUESTS",
   *   "message": "You have to wait {secondsRemaining} seconds to be able to send request again",
   *   "messageForUser": "api.user.TOO_MANY_REQUESTS"
   * }`
   */
  completeConfirmPhoneAction?: Maybe<CompleteConfirmPhoneActionOutput>;
  /**
   * Authenticates resident user for mobile apps
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "signinResidentUser",
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "TOKEN_NOT_FOUND",
   *   "message": "Unable to find a non-expired confirm phone action, that corresponds to provided token",
   *   "messageForUser": "api.user.signinResidentUser.TOKEN_NOT_FOUND"
   * }`
   *
   * `{
   *   "code": "INTERNAL_ERROR",
   *   "type": "UNABLE_TO_CREATE_USER",
   *   "mutation": "signinResidentUser",
   *   "message": "Something went wrong while trying to create a User record",
   *   "messageForUser": "api.user.signinResidentUser.UNABLE_TO_CREATE_USER"
   * }`
   */
  signinResidentUser?: Maybe<SigninResidentUserOutput>;
  /**
   * Changes a phone of a resident, that corresponds to confirmed phone number, specified via token
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "changePhoneNumberResidentUser",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "mutation": "changePhoneNumberResidentUser",
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value. {details}",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "mutation": "changePhoneNumberResidentUser",
   *   "message": "Unable to find a non-expired confirm phone action, that corresponds to provided token",
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "messageForUser": "api.user.changePhoneNumberResidentUser.UNABLE_TO_FIND_CONFIRM_PHONE_ACTION"
   * }`
   *
   * `{
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "mutation": "changePhoneNumberResidentUser",
   *   "message": "Unable to change phone number since user has external identity and phone number are different",
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "messageForUser": "api.user.changePhoneNumberResidentUser.USER_HAS_CONNECTED_EXTERNAL_IDENTITY_WITH_DIFFERENT_PHONE"
   * }`
   */
  changePhoneNumberResidentUser?: Maybe<ChangePhoneNumberResidentUserOutput>;
  /**
   * Authenticates as an another user to be able to see the system, as it does
   *
   * You cannot authenticate for another admin or support or whatever kind of a non-client user
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "signinAsUser",
   *   "code": "BAD_USER_INPUT",
   *   "type": "USER_NOT_FOUND",
   *   "message": "Could not find a user with a specified id",
   *   "messageForUser": "api.user.signinAsUser.USER_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "signinAsUser",
   *   "code": "FORBIDDEN",
   *   "type": "DENIED_FOR_ADMIN",
   *   "message": "You cannot authenticate for an another admin user",
   *   "messageForUser": "api.user.signinAsUser.DENIED_FOR_ADMIN"
   * }`
   *
   * `{
   *   "mutation": "signinAsUser",
   *   "code": "FORBIDDEN",
   *   "type": "DENIED_FOR_SUPPORT",
   *   "message": "You cannot authenticate for an another support user",
   *   "messageForUser": "api.user.signinAsUser.DENIED_FOR_SUPPORT"
   * }`
   */
  signinAsUser?: Maybe<SigninAsUserOutput>;
  registerNewServiceUser?: Maybe<RegisterNewServiceUserOutput>;
  sendMessageToSupport?: Maybe<SendMessageToSupportOutput>;
  /**
   * Used by QA for cleaning existing test user record to avoid utilizing every time new phone and email, which is hard to obtain again and again for every manual testing procedure
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "resetUser",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Unsupported value for dv"
   * }`
   *
   * `{
   *   "mutation": "resetUser",
   *   "variable": [
   *     "data",
   *     "user",
   *     "id"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "USER_NOT_FOUND",
   *   "message": "Could not find User by provided id"
   * }`
   *
   * `{
   *   "mutation": "resetUser",
   *   "variable": [
   *     "data",
   *     "user",
   *     "id"
   *   ],
   *   "code": "FORBIDDEN",
   *   "type": "CANNOT_RESET_ADMIN_USER",
   *   "message": "You cannot reset admin user"
   * }`
   */
  resetUser?: Maybe<ResetUserOutput>;
  /**
   * Registers new Organization for current user
   *
   * Creates new Organization, new OrganizationEmployee for current user, creates a set of default OrganizationEmployeeRole for organization and connects created OrganizationEmployee to "Admin" OrganizationEmployeeRole, creates trial ServiceSubscription for organization
   */
  registerNewOrganization?: Maybe<Organization>;
  /**
   * Invites staff-user into specified Organization
   *
   * For corresponding User record it creates a new OrganizationEmployee and sends message with notification about invitation
   * It tries to find already existing User with type "staff" first by phone, then by email.
   * If User is not found, it will be registered.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "inviteNewOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "ALREADY_INVITED_EMAIL",
   *   "message": "Employee with same email already invited into the organization",
   *   "messageForUser": "api.organization.inviteNewOrganizationEmployee.ALREADY_INVITED_EMAIL",
   *   "variable": [
   *     "email"
   *   ]
   * }`
   *
   * `{
   *   "mutation": "inviteNewOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "ALREADY_INVITED_PHONE",
   *   "message": "Employee with same phone already invited into the organization",
   *   "messageForUser": "api.organization.inviteNewOrganizationEmployee.ALREADY_INVITED_PHONE",
   *   "variable": [
   *     "phone"
   *   ]
   * }`
   *
   * `{
   *   "mutation": "inviteNewOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_PHONE_FORMAT",
   *   "message": "Wrong phone format",
   *   "messageForUser": "api.common.WRONG_PHONE_FORMAT"
   * }`
   *
   * `{
   *   "mutation": "inviteNewOrganizationEmployee",
   *   "code": "INTERNAL_ERROR",
   *   "type": "UNABLE_TO_REGISTER_USER",
   *   "message": "Unable to register user",
   *   "messageForUser": "api.organization.inviteNewOrganizationEmployee.UNABLE_TO_REGISTER_USER"
   * }`
   *
   * `{
   *   "mutation": "inviteNewOrganizationEmployee",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   */
  inviteNewOrganizationEmployee?: Maybe<OrganizationEmployee>;
  /**
   * Tries to send notification message again to already invited user
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "reInviteOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Wrong phone format",
   *   "messageForUser": "api.common.WRONG_PHONE_FORMAT"
   * }`
   *
   * `{
   *   "mutation": "reInviteOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Could not find Organization by specified search criteria",
   *   "messageForUser": "api.organization.reInviteOrganizationEmployee.ORGANIZATION_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "reInviteOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Could not find User by specified phone or email",
   *   "messageForUser": "api.organization.reInviteOrganizationEmployee.USER_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "reInviteOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Could not find OrganizationEmployee that has not accepted invitation for found User",
   *   "messageForUser": "api.organization.reInviteOrganizationEmployee.EMPLOYEE_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "reInviteOrganizationEmployee",
   *   "code": "BAD_USER_INPUT",
   *   "type": "ALREADY_ACCEPTED_INVITATION",
   *   "message": "Corresponding OrganizationEmployee has already accepted invitation",
   *   "messageForUser": "api.organization.reInviteOrganizationEmployee.ALREADY_ACCEPTED_INVITATION"
   * }`
   */
  reInviteOrganizationEmployee?: Maybe<OrganizationEmployee>;
  acceptOrRejectOrganizationInviteById?: Maybe<OrganizationEmployee>;
  acceptOrRejectOrganizationInviteByCode?: Maybe<OrganizationEmployee>;
  resetOrganization?: Maybe<ResetOrganizationOutput>;
  /**
   * Replaces old role "A" with new role "B" for all employees with role "A"
   *
   * Replaces old role "A" with new role "B" for all employees with role "A". By default, old role is retained. If you pass the “withDeletionOldRole” flag, then old role will be deleted.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value. {details}",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data",
   *     "organization"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "ORGANIZATION_NOT_FOUND",
   *   "message": "Organization not found",
   *   "messageForUser": "api.organization.ReplaceOrganizationEmployeeRole.ORGANIZATION_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data",
   *     "oldRole"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "OLD_ROLE_NOT_FOUND",
   *   "message": "Old role not found in specified organization",
   *   "messageForUser": "api.organization.ReplaceOrganizationEmployeeRole.OLD_ROLE_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data",
   *     "newRole"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NEW_ROLE_NOT_FOUND",
   *   "message": "New role not found in specified organization",
   *   "messageForUser": "api.organization.ReplaceOrganizationEmployeeRole.NEW_ROLE_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DEFAULT_ROLE_CANNOT_BE_DELETED",
   *   "message": "The default role cannot be deleted",
   *   "messageForUser": "api.organization.ReplaceOrganizationEmployeeRole.DEFAULT_ROLE_CANNOT_BE_DELETED"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "ROLES_MUST_BE_DIFFERENT",
   *   "message": "The old role and new role must be different",
   *   "messageForUser": "api.organization.ReplaceOrganizationEmployeeRole.ROLES_MUST_BE_DIFFERENT"
   * }`
   *
   * `{
   *   "mutation": "replaceOrganizationEmployeeRole",
   *   "variable": [
   *     "data"
   *   ],
   *   "code": "TOO_MANY_REQUESTS",
   *   "type": "ROLES_ARE_BEING_PROCESSED",
   *   "message": "These roles are already being processed. Please try again a little later",
   *   "messageForUser": "api.organization.ReplaceOrganizationEmployeeRole.ROLES_ARE_BEING_PROCESSED"
   * }`
   */
  replaceOrganizationEmployeeRole?: Maybe<ReplaceOrganizationEmployeeRoleOutput>;
  registerBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>;
  sendNewBillingReceiptFilesNotifications?: Maybe<SendNewBillingReceiptFilesNotificationsOutput>;
  /**
   * Sends notification of BILLING_RECEIPT_CATEGORY_AVAILABLE_TYPE type to all residents of provided scopes. Requires service user with access rights to billing domain. All properties in scopes should be connected to provided context.
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "sendNewReceiptMessagesToResidentScopes",
   *   "variable": [
   *     "data",
   *     "context"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Please provide proper period not before start of previous and not later than start of current month, valid format is YYYY-MM-DD",
   *   "messageForUser": "api.billing.sendNewReceiptMessagesToResidentScopes.INVALID_PERIOD_PROVIDED"
   * }`
   *
   * `{
   *   "mutation": "sendNewReceiptMessagesToResidentScopes",
   *   "variable": [
   *     "data",
   *     "context"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Please provide existing non-deleted billing integration organization context id",
   *   "messageForUser": "api.billing.sendNewReceiptMessagesToResidentScopes.INVALID_BILLING_CONTEXT_PROVIDED"
   * }`
   *
   * `{
   *   "mutation": "sendNewReceiptMessagesToResidentScopes",
   *   "variable": [
   *     "data",
   *     "category"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Please provide existing non-deleted billing category id",
   *   "messageForUser": "api.billing.sendNewReceiptMessagesToResidentScopes.INVALID_BILLING_CATEGORY_PROVIDED"
   * }`
   *
   * `{
   *   "mutation": "sendNewReceiptMessagesToResidentScopes",
   *   "variable": [
   *     "data",
   *     "scopes"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Scopes could not be empty",
   *   "messageForUser": "api.billing.sendNewReceiptMessagesToResidentScopes.SCOPES_IS_EMPTY"
   * }`
   *
   * `{
   *   "mutation": "sendNewReceiptMessagesToResidentScopes",
   *   "variable": [
   *     "data",
   *     "scopes"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "All provided billing properties in scopes should be non-deleted & connected to organization of the billing context",
   *   "messageForUser": "api.billing.sendNewReceiptMessagesToResidentScopes.INVALID_BILLING_PROPERTY_PROVIDED"
   * }`
   */
  sendNewReceiptMessagesToResidentScopes?: Maybe<SendNewReceiptMessagesToResidentScopesOutput>;
  validateQRCode?: Maybe<ValidateQrCodeOutput>;
  registerBillingReceiptFile?: Maybe<RegisterBillingReceiptFileOutput>;
  createBankAccountRequest?: Maybe<CreateBankAccountRequestOutput>;
  shareTicket?: Maybe<ShareTicketOutput>;
  ticketMultipleUpdate: Ticket;
  /**
   * Sends message of specified type to specified contact
   *
   * Each message type has specific set of required fields:
   *
   * `{
   * 	"TICKET_CREATED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketStatus": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketAddress": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketUnit": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketCreatedAt": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketDetails": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketClassifier": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		},
   * 		"telegramMeta": {
   * 			"required": false
   * 		}
   * 	},
   * 	"TICKET_COMMENT_CREATED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketStatus": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketAddress": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketUnit": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketDetails": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketClassifier": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentContent": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentCreatedAt": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentType": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentTypeMessage": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		},
   * 		"telegramMeta": {
   * 			"required": false
   * 		}
   * 	},
   * 	"INVITE_NEW_EMPLOYEE": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"inviteCode": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"userName": {
   * 			"defaultValue": "USERNAME",
   * 			"required": false
   * 		},
   * 		"userEmail": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		},
   * 		"userPhone": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		},
   * 		"organizationName": {
   * 			"defaultValue": "ORGANIZATION",
   * 			"required": false
   * 		}
   * 	},
   * 	"SHARE_TICKET": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"ticketNumber": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"date": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"id": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"details": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		}
   * 	},
   * 	"BANK_ACCOUNT_CREATION_REQUEST": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"bankAccountClient": {
   * 			"defaultValue": {},
   * 			"required": true
   * 		},
   * 		"tin": {
   * 			"required": true
   * 		},
   * 		"name": {
   * 			"required": true
   * 		},
   * 		"propertyAddress": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		}
   * 	},
   * 	"DIRTY_INVITE_NEW_EMPLOYEE_SMS": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"organizationName": {
   * 			"defaultValue": "ORGANIZATION",
   * 			"required": false
   * 		},
   * 		"serverUrl": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		}
   * 	},
   * 	"DIRTY_INVITE_NEW_EMPLOYEE_EMAIL": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"organizationName": {
   * 			"defaultValue": "ORGANIZATION",
   * 			"required": false
   * 		},
   * 		"serverUrl": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		}
   * 	},
   * 	"REGISTER_NEW_USER": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"userPhone": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		},
   * 		"userPassword": {
   * 			"defaultValue": "",
   * 			"required": false
   * 		}
   * 	},
   * 	"SMS_VERIFY": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"smsCode": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"appId": {
   * 			"required": false
   * 		}
   * 	},
   * 	"DEVELOPER_IMPORTANT_NOTE_TYPE": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"type": {
   * 			"defaultValue": "UNKNOWN",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		}
   * 	},
   * 	"CUSTOMER_IMPORTANT_NOTE_TYPE": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"type": {
   * 			"defaultValue": "UNKNOWN",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		}
   * 	},
   * 	"MESSAGE_FORWARDED_TO_SUPPORT": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"text": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"residentsExtraInfo": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"os": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"appVersion": {
   * 			"defaultValue": null,
   * 			"required": true
   * 		},
   * 		"attachments": {
   * 			"defaultValue": [],
   * 			"isRequired": false
   * 		}
   * 	},
   * 	"TICKET_ASSIGNEE_CONNECTED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_EXECUTOR_CONNECTED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TRACK_TICKET_IN_DOMA_APP": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"organization": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_OPENED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_IN_PROGRESS": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_COMPLETED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_RETURNED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_STATUS_DECLINED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"TICKET_COMMENT_ADDED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"ticketId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"commentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_VERIFICATION_DATE_REMINDER": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"reminderDate": {
   * 				"required": true
   * 			},
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RESIDENT_ADD_BILLING_ACCOUNT": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"residentIds": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_AVAILABLE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"receiptId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"accountId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_AVAILABLE_NO_ACCOUNT": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"residentIds": {
   * 				"required": true
   * 			},
   * 			"propertyId": {
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_CATEGORY_AVAILABLE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"propertyId": {
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			},
   * 			"categoryId": {
   * 				"required": true
   * 			}
   * 		},
   * 		"categoryName": {
   * 			"required": true
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_ADDED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingReceiptId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingAccountId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingPropertyId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_FILE_ADDED": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"organization": {
   * 				"required": true
   * 			},
   * 			"year": {
   * 				"required": true
   * 			},
   * 			"month": {
   * 				"required": true
   * 			}
   * 		},
   * 		"attachments": {
   * 			"defaultValue": [],
   * 			"isRequired": false
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_ADDED_WITH_DEBT": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingReceiptId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingAccountId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingPropertyId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			},
   * 			"category": {
   * 				"required": true
   * 			},
   * 			"toPay": {
   * 				"required": true
   * 			},
   * 			"currencyCode": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"BILLING_RECEIPT_ADDED_WITH_NO_DEBT": {
   * 		"dv": {
   * 			"defaultValue": "",
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"residentId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingReceiptId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingAccountId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"billingPropertyId": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"period": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_SUBMIT_READINGS_REMINDER": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_SUBMIT_READINGS_REMINDER_START_PERIOD": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"monthName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_SUBMIT_READINGS_REMINDER_END_PERIOD": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"monthName": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			},
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"METER_VERIFICATION_DATE_EXPIRED": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"meterId": {
   * 				"required": true
   * 			},
   * 			"resource": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RESIDENT_UPGRADE_APP": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"userType": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"STAFF_UPGRADE_APP": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"userType": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"CUSTOM_CONTENT_MESSAGE_PUSH": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"userId": {
   * 				"required": false
   * 			},
   * 			"remoteClient": {
   * 				"required": false
   * 			},
   * 			"url": {
   * 				"defaultValue": "",
   * 				"required": false
   * 			},
   * 			"messageBatchId": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"MOBILE_APP_UPDATE_AVAILABLE_MESSAGE_PUSH": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": false
   * 		}
   * 	},
   * 	"CUSTOM_CONTENT_MESSAGE_EMAIL": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"subject": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"email": {
   * 				"required": false
   * 			},
   * 			"messageBatchId": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"CUSTOM_CONTENT_MESSAGE_SMS": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"phone": {
   * 				"required": false
   * 			},
   * 			"messageBatchId": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"VOIP_INCOMING_CALL_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"B2CAppId": {
   * 				"required": true
   * 			},
   * 			"B2CAppContext": {
   * 				"required": false
   * 			},
   * 			"B2CAppName": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"callId": {
   * 				"required": false
   * 			},
   * 			"voipType": {
   * 				"required": false
   * 			},
   * 			"voipAddress": {
   * 				"required": false
   * 			},
   * 			"voipLogin": {
   * 				"required": false
   * 			},
   * 			"voipPassword": {
   * 				"required": false
   * 			},
   * 			"voipDtfmCommand": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"B2C_APP_MESSAGE_PUSH": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": false
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"B2CAppId": {
   * 				"required": true
   * 			},
   * 			"B2CAppContext": {
   * 				"required": false
   * 			},
   * 			"B2CAppName": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_SUCCESS_RESULT_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_UNKNOWN_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_ACQUIRING_PAYMENT_PROCEED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_SERVICE_CONSUMER_NOT_FOUND_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_LIMIT_EXCEEDED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CONTEXT_NOT_FOUND_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CONTEXT_DISABLED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CARD_TOKEN_NOT_VALID_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_CAN_NOT_REGISTER_MULTI_PAYMENT_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"recurrentPaymentId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"errorCode": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"lastTry": {
   * 				"required": false
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_PROCEEDING_NO_RECEIPTS_TO_PROCEED_ERROR_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_TOMORROW_PAYMENT_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_TOMORROW_PAYMENT_NO_RECEIPTS_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"RECURRENT_PAYMENT_TOMORROW_PAYMENT_LIMIT_EXCEED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"recurrentPaymentContextId": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			},
   * 			"toPayAmount": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"NEWS_ITEM_COMMON_MESSAGE_TYPE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": true
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"newsItemId": {
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"NEWS_ITEM_EMERGENCY_MESSAGE_TYPE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"title": {
   * 			"required": true
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"newsItemId": {
   * 				"required": true
   * 			},
   * 			"organizationId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"DEV_PORTAL_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"body": {
   * 			"required": true
   * 		},
   * 		"subject": {
   * 			"required": false
   * 		}
   * 	},
   * 	"SEND_BILLING_RECEIPTS_ON_PAYDAY_REMINDER_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"monthName": {
   * 				"required": true
   * 			},
   * 			"serviceConsumerId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_WITH_TICKET_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"ticketId": {
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_CASH_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"MARKETPLACE_INVOICE_CASH_WITH_TICKET_PUBLISHED_MESSAGE": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"invoiceId": {
   * 				"required": true
   * 			},
   * 			"ticketId": {
   * 				"required": true
   * 			},
   * 			"ticketNumber": {
   * 				"required": true
   * 			},
   * 			"residentId": {
   * 				"required": true
   * 			},
   * 			"userId": {
   * 				"required": true
   * 			},
   * 			"url": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"SERVICE_USER_CREATED": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"data": {
   * 			"serverUrl": {
   * 				"required": true
   * 			},
   * 			"email": {
   * 				"required": true
   * 			},
   * 			"password": {
   * 				"required": true
   * 			}
   * 		}
   * 	},
   * 	"SEND_DAILY_STATISTICS": {
   * 		"dv": {
   * 			"required": true
   * 		},
   * 		"attachingData": {
   * 			"required": false
   * 		},
   * 		"data": {
   * 			"date": {
   * 				"required": true
   * 			},
   * 			"tickets": {
   * 				"required": true
   * 			},
   * 			"incidents": {
   * 				"required": true
   * 			}
   * 		}
   * 	}
   * }`
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "sendMessage",
   *   "variable": [
   *     "data",
   *     "to",
   *     "email"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "REQUIRED",
   *   "message": "You can not use emailFrom without to.email"
   * }`
   *
   * `{
   *   "mutation": "sendMessage",
   *   "variable": [
   *     "data"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "REQUIRED",
   *   "message": "You should provide either \"user\", \"email\", \"phone\" or \"remoteClient\" attribute"
   * }`
   *
   * `{
   *   "mutation": "sendMessage",
   *   "variable": [
   *     "data",
   *     "meta"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNKNOWN_ATTRIBUTE",
   *   "message": "Unknown attribute \"{attr}\" provided to \"meta\" variable"
   * }`
   *
   * `{
   *   "mutation": "sendMessage",
   *   "variable": [
   *     "data",
   *     "meta"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "REQUIRED",
   *   "message": "Missing value for required \"meta.{attr}\" attribute"
   * }`
   *
   * `{
   *   "mutation": "sendMessage",
   *   "variable": [
   *     "data",
   *     "meta"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "Unknown value \"{type}\" provided for message type"
   * }`
   *
   * `{
   *   "mutation": "sendMessage",
   *   "variable": [
   *     "data",
   *     "meta",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   */
  sendMessage?: Maybe<SendMessageOutput>;
  resendMessage?: Maybe<ResendMessageOutput>;
  syncRemoteClient?: Maybe<RemoteClient>;
  disconnectUserFromRemoteClient?: Maybe<DisconnectUserFromRemoteClientOutput>;
  setMessageStatus?: Maybe<SetMessageStatusOutput>;
  _internalSendNotificationNewMobileAppVersion?: Maybe<_InternalSendNotificationNewMobileAppVersionOutput>;
  _internalSendHashedResidentPhones?: Maybe<_InternalSendHashedResidentPhonesOutput>;
  _internalSyncContactsWithResidentsForOrganization?: Maybe<Array<Maybe<Contact>>>;
  registerResident?: Maybe<Resident>;
  /**
   * Creates service consumers with default data, and automatically populates the optional data fields, such as `billingAccount
   *
   * Search for accountNumbers across all organizations matching resident address and provided accountNumber. May return more than one ServiceConsumer in case of some organizations at resident address had same accountNumber. To be successfully created accountNumber and unitName should at least have billingAccount with same data or Meter with same data
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "residentId"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Resident for current user"
   * }`
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "organizationId"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current user"
   * }`
   *
   * `{
   *   "mutation": "registerResidentServiceConsumers",
   *   "variable": [
   *     "data",
   *     "resident",
   *     "id"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current resident"
   * }`
   *
   * `{
   *   "mutation": "registerResidentServiceConsumers",
   *   "variable": [
   *     "data",
   *     "accountNumber"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and address",
   *   "messageForUser": "api.resident.RegisterServiceConsumerService.BILLING_ACCOUNT_OR_METER_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "accountNumber"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and organization combination",
   *   "messageForUser": "api.resident.RegisterServiceConsumerService.BILLING_ACCOUNT_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "accountNumber"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Argument \"accountNumber\" is null or empty"
   * }`
   */
  registerResidentServiceConsumers?: Maybe<Array<ServiceConsumer>>;
  /**
   * Creates service consumer with default data, and automatically populates the optional data fields, such as `billingAccount
   *
   * To be successfully created accountNumber and unitName should at least have billingAccount with same data or Meter with same data
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "residentId"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Resident for current user"
   * }`
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "organizationId"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current user"
   * }`
   *
   * `{
   *   "mutation": "registerResidentServiceConsumers",
   *   "variable": [
   *     "data",
   *     "resident",
   *     "id"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Cannot find Organization for current resident"
   * }`
   *
   * `{
   *   "mutation": "registerResidentServiceConsumers",
   *   "variable": [
   *     "data",
   *     "accountNumber"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and address",
   *   "messageForUser": "api.resident.RegisterServiceConsumerService.BILLING_ACCOUNT_OR_METER_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "accountNumber"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Can't find billingAccount and any meters with this accountNumber, unitName and organization combination",
   *   "messageForUser": "api.resident.RegisterServiceConsumerService.BILLING_ACCOUNT_NOT_FOUND"
   * }`
   *
   * `{
   *   "mutation": "registerServiceConsumer",
   *   "variable": [
   *     "data",
   *     "accountNumber"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Argument \"accountNumber\" is null or empty"
   * }`
   */
  registerServiceConsumer?: Maybe<ServiceConsumer>;
  /**
   * Sends notification of requested type to all residents of provided scopes
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "sendMessageToResidentScopes",
   *   "variable": [
   *     "data",
   *     "scopes"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOT_FOUND",
   *   "message": "Scopes could not be empty",
   *   "messageForUser": "api.resident.sendMessageToResidentScopes.SCOPES_IS_EMPTY"
   * }`
   */
  sendMessageToResidentScopes?: Maybe<SendMessageToResidentScopesServiceOutput>;
  discoverServiceConsumers?: Maybe<DiscoverServiceConsumersOutput>;
  registerResidentInvoice?: Maybe<Invoice>;
  syncTourSteps?: Maybe<SyncTourStepsOutput>;
  /**
   * This mutation deletes meters and meter readings in specified organization.
   *
   * This mutation deletes meters in specified organization for specified period.
   *  Readings are deleted automatically in a deferred task.
   *  You can also specify properties in which meters need to be deleted.
   *  The response will return the status of the operation: “success” if all meters for the specified filter were deleted, otherwise “error”.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "query": "_internalDeleteMeterAndMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "query": "_internalDeleteMeterAndMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}"
   * }`
   */
  _internalDeleteMeterAndMeterReadings?: Maybe<_InternalDeleteMeterAndMeterReadingsOutput>;
  /**
   * This mutation deletes readings in specified organization for specified period.
   *
   * This mutation deletes readings in specified organization for specified period.
   *  You can also specify properties in which readings need to be deleted.
   *  The response will return the status of the operation: “success” if all readings for the specified filter were deleted, otherwise “error”.
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "query": "_internalDeleteMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "query": "_internalDeleteMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}"
   * }`
   *
   * `{
   *   "query": "_internalDeleteMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "startDateTime"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_START_DATE_TIME",
   *   "message": "Invalid startDateTime. Should be in format DD.MM.YYYY HH:mm:ss"
   * }`
   *
   * `{
   *   "query": "_internalDeleteMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "endDateTime"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_END_DATE_TIME",
   *   "message": "invalid endDateTime. Should be in format DD.MM.YYYY HH:mm:ss"
   * }`
   *
   * `{
   *   "query": "_internalDeleteMeterReadingsService",
   *   "variable": [
   *     "data",
   *     "startDateTime"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_PERIOD",
   *   "message": "The start date cannot be greater than the end date"
   * }`
   */
  _internalDeleteMeterReadings?: Maybe<_InternalDeleteMeterReadingsOutput>;
  registerMetersReadings?: Maybe<Array<Maybe<RegisterMetersReadingsOutput>>>;
  registerMultiPayment?: Maybe<RegisterMultiPaymentOutput>;
  registerMultiPaymentForOneReceipt?: Maybe<RegisterMultiPaymentForOneReceiptOutput>;
  registerMultiPaymentForVirtualReceipt?: Maybe<RegisterMultiPaymentForOneReceiptOutput>;
  createPaymentByLink?: Maybe<CreatePaymentByLinkOutput>;
  registerMultiPaymentForInvoices?: Maybe<RegisterMultiPaymentForInvoicesOutput>;
  sendB2CAppPushMessage?: Maybe<SendB2CAppPushMessageOutput>;
  /**  Authenticate and generate a token for a User with the Password Authentication Strategy.  */
  authenticateUserWithPassword?: Maybe<AuthenticateUserOutput>;
  unauthenticateUser?: Maybe<UnauthenticateUserOutput>;
  updateAuthenticatedUser?: Maybe<User>;
};


export type MutationCreateUserHistoryRecordArgs = {
  data?: Maybe<UserHistoryRecordCreateInput>;
};


export type MutationCreateUserHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserHistoryRecordUpdateInput>;
};


export type MutationUpdateUserHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserArgs = {
  data?: Maybe<UserCreateInput>;
};


export type MutationCreateUsersArgs = {
  data?: Maybe<Array<Maybe<UsersCreateInput>>>;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserUpdateInput>;
};


export type MutationUpdateUsersArgs = {
  data?: Maybe<Array<Maybe<UsersUpdateInput>>>;
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateConfirmPhoneActionHistoryRecordArgs = {
  data?: Maybe<ConfirmPhoneActionHistoryRecordCreateInput>;
};


export type MutationCreateConfirmPhoneActionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecordsCreateInput>>>;
};


export type MutationUpdateConfirmPhoneActionHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ConfirmPhoneActionHistoryRecordUpdateInput>;
};


export type MutationUpdateConfirmPhoneActionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteConfirmPhoneActionHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConfirmPhoneActionHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateConfirmPhoneActionArgs = {
  data?: Maybe<ConfirmPhoneActionCreateInput>;
};


export type MutationCreateConfirmPhoneActionsArgs = {
  data?: Maybe<Array<Maybe<ConfirmPhoneActionsCreateInput>>>;
};


export type MutationUpdateConfirmPhoneActionArgs = {
  id: Scalars['ID'];
  data?: Maybe<ConfirmPhoneActionUpdateInput>;
};


export type MutationUpdateConfirmPhoneActionsArgs = {
  data?: Maybe<Array<Maybe<ConfirmPhoneActionsUpdateInput>>>;
};


export type MutationDeleteConfirmPhoneActionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConfirmPhoneActionsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOidcClientHistoryRecordArgs = {
  data?: Maybe<OidcClientHistoryRecordCreateInput>;
};


export type MutationCreateOidcClientHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OidcClientHistoryRecordsCreateInput>>>;
};


export type MutationUpdateOidcClientHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<OidcClientHistoryRecordUpdateInput>;
};


export type MutationUpdateOidcClientHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OidcClientHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteOidcClientHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOidcClientHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOidcClientArgs = {
  data?: Maybe<OidcClientCreateInput>;
};


export type MutationCreateOidcClientsArgs = {
  data?: Maybe<Array<Maybe<OidcClientsCreateInput>>>;
};


export type MutationUpdateOidcClientArgs = {
  id: Scalars['ID'];
  data?: Maybe<OidcClientUpdateInput>;
};


export type MutationUpdateOidcClientsArgs = {
  data?: Maybe<Array<Maybe<OidcClientsUpdateInput>>>;
};


export type MutationDeleteOidcClientArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOidcClientsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserExternalIdentityHistoryRecordArgs = {
  data?: Maybe<UserExternalIdentityHistoryRecordCreateInput>;
};


export type MutationCreateUserExternalIdentityHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserExternalIdentityHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserExternalIdentityHistoryRecordUpdateInput>;
};


export type MutationUpdateUserExternalIdentityHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserExternalIdentityHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserExternalIdentityHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserExternalIdentityArgs = {
  data?: Maybe<UserExternalIdentityCreateInput>;
};


export type MutationCreateUserExternalIdentitiesArgs = {
  data?: Maybe<Array<Maybe<UserExternalIdentitiesCreateInput>>>;
};


export type MutationUpdateUserExternalIdentityArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserExternalIdentityUpdateInput>;
};


export type MutationUpdateUserExternalIdentitiesArgs = {
  data?: Maybe<Array<Maybe<UserExternalIdentitiesUpdateInput>>>;
};


export type MutationDeleteUserExternalIdentityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserExternalIdentitiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateExternalTokenAccessRightHistoryRecordArgs = {
  data?: Maybe<ExternalTokenAccessRightHistoryRecordCreateInput>;
};


export type MutationCreateExternalTokenAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecordsCreateInput>>>;
};


export type MutationUpdateExternalTokenAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ExternalTokenAccessRightHistoryRecordUpdateInput>;
};


export type MutationUpdateExternalTokenAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteExternalTokenAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExternalTokenAccessRightHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateExternalTokenAccessRightArgs = {
  data?: Maybe<ExternalTokenAccessRightCreateInput>;
};


export type MutationCreateExternalTokenAccessRightsArgs = {
  data?: Maybe<Array<Maybe<ExternalTokenAccessRightsCreateInput>>>;
};


export type MutationUpdateExternalTokenAccessRightArgs = {
  id: Scalars['ID'];
  data?: Maybe<ExternalTokenAccessRightUpdateInput>;
};


export type MutationUpdateExternalTokenAccessRightsArgs = {
  data?: Maybe<Array<Maybe<ExternalTokenAccessRightsUpdateInput>>>;
};


export type MutationDeleteExternalTokenAccessRightArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExternalTokenAccessRightsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserRightsSetHistoryRecordArgs = {
  data?: Maybe<UserRightsSetHistoryRecordCreateInput>;
};


export type MutationCreateUserRightsSetHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserRightsSetHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserRightsSetHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserRightsSetHistoryRecordUpdateInput>;
};


export type MutationUpdateUserRightsSetHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserRightsSetHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserRightsSetHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserRightsSetHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserRightsSetArgs = {
  data?: Maybe<UserRightsSetCreateInput>;
};


export type MutationCreateUserRightsSetsArgs = {
  data?: Maybe<Array<Maybe<UserRightsSetsCreateInput>>>;
};


export type MutationUpdateUserRightsSetArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserRightsSetUpdateInput>;
};


export type MutationUpdateUserRightsSetsArgs = {
  data?: Maybe<Array<Maybe<UserRightsSetsUpdateInput>>>;
};


export type MutationDeleteUserRightsSetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserRightsSetsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationHistoryRecordArgs = {
  data?: Maybe<OrganizationHistoryRecordCreateInput>;
};


export type MutationCreateOrganizationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationHistoryRecordsCreateInput>>>;
};


export type MutationUpdateOrganizationHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationHistoryRecordUpdateInput>;
};


export type MutationUpdateOrganizationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteOrganizationHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationArgs = {
  data?: Maybe<OrganizationCreateInput>;
};


export type MutationCreateOrganizationsArgs = {
  data?: Maybe<Array<Maybe<OrganizationsCreateInput>>>;
};


export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationUpdateInput>;
};


export type MutationUpdateOrganizationsArgs = {
  data?: Maybe<Array<Maybe<OrganizationsUpdateInput>>>;
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationEmployeeHistoryRecordArgs = {
  data?: Maybe<OrganizationEmployeeHistoryRecordCreateInput>;
};


export type MutationCreateOrganizationEmployeeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecordsCreateInput>>>;
};


export type MutationUpdateOrganizationEmployeeHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeHistoryRecordUpdateInput>;
};


export type MutationUpdateOrganizationEmployeeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteOrganizationEmployeeHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationEmployeeHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationEmployeeArgs = {
  data?: Maybe<OrganizationEmployeeCreateInput>;
};


export type MutationCreateOrganizationEmployeesArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeesCreateInput>>>;
};


export type MutationUpdateOrganizationEmployeeArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeUpdateInput>;
};


export type MutationUpdateOrganizationEmployeesArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeesUpdateInput>>>;
};


export type MutationDeleteOrganizationEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationEmployeesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationEmployeeRoleHistoryRecordArgs = {
  data?: Maybe<OrganizationEmployeeRoleHistoryRecordCreateInput>;
};


export type MutationCreateOrganizationEmployeeRoleHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecordsCreateInput>>>;
};


export type MutationUpdateOrganizationEmployeeRoleHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeRoleHistoryRecordUpdateInput>;
};


export type MutationUpdateOrganizationEmployeeRoleHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteOrganizationEmployeeRoleHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationEmployeeRoleHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationEmployeeRoleArgs = {
  data?: Maybe<OrganizationEmployeeRoleCreateInput>;
};


export type MutationCreateOrganizationEmployeeRolesArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeRolesCreateInput>>>;
};


export type MutationUpdateOrganizationEmployeeRoleArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeRoleUpdateInput>;
};


export type MutationUpdateOrganizationEmployeeRolesArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeRolesUpdateInput>>>;
};


export type MutationDeleteOrganizationEmployeeRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationEmployeeRolesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationLinkHistoryRecordArgs = {
  data?: Maybe<OrganizationLinkHistoryRecordCreateInput>;
};


export type MutationCreateOrganizationLinkHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationLinkHistoryRecordsCreateInput>>>;
};


export type MutationUpdateOrganizationLinkHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationLinkHistoryRecordUpdateInput>;
};


export type MutationUpdateOrganizationLinkHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationLinkHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteOrganizationLinkHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationLinkHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationLinkArgs = {
  data?: Maybe<OrganizationLinkCreateInput>;
};


export type MutationCreateOrganizationLinksArgs = {
  data?: Maybe<Array<Maybe<OrganizationLinksCreateInput>>>;
};


export type MutationUpdateOrganizationLinkArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationLinkUpdateInput>;
};


export type MutationUpdateOrganizationLinksArgs = {
  data?: Maybe<Array<Maybe<OrganizationLinksUpdateInput>>>;
};


export type MutationDeleteOrganizationLinkArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationLinksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationEmployeeSpecializationHistoryRecordArgs = {
  data?: Maybe<OrganizationEmployeeSpecializationHistoryRecordCreateInput>;
};


export type MutationCreateOrganizationEmployeeSpecializationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecordsCreateInput>>>;
};


export type MutationUpdateOrganizationEmployeeSpecializationHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeSpecializationHistoryRecordUpdateInput>;
};


export type MutationUpdateOrganizationEmployeeSpecializationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteOrganizationEmployeeSpecializationHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationEmployeeSpecializationHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateOrganizationEmployeeSpecializationArgs = {
  data?: Maybe<OrganizationEmployeeSpecializationCreateInput>;
};


export type MutationCreateOrganizationEmployeeSpecializationsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationsCreateInput>>>;
};


export type MutationUpdateOrganizationEmployeeSpecializationArgs = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeSpecializationUpdateInput>;
};


export type MutationUpdateOrganizationEmployeeSpecializationsArgs = {
  data?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationsUpdateInput>>>;
};


export type MutationDeleteOrganizationEmployeeSpecializationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationEmployeeSpecializationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyHistoryRecordArgs = {
  data?: Maybe<PropertyHistoryRecordCreateInput>;
};


export type MutationCreatePropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePropertyHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyHistoryRecordUpdateInput>;
};


export type MutationUpdatePropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePropertyHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyArgs = {
  data?: Maybe<PropertyCreateInput>;
};


export type MutationCreatePropertiesArgs = {
  data?: Maybe<Array<Maybe<PropertiesCreateInput>>>;
};


export type MutationUpdatePropertyArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyUpdateInput>;
};


export type MutationUpdatePropertiesArgs = {
  data?: Maybe<Array<Maybe<PropertiesUpdateInput>>>;
};


export type MutationDeletePropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationHistoryRecordArgs = {
  data?: Maybe<BillingIntegrationHistoryRecordCreateInput>;
};


export type MutationCreateBillingIntegrationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingIntegrationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationArgs = {
  data?: Maybe<BillingIntegrationCreateInput>;
};


export type MutationCreateBillingIntegrationsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationUpdateInput>;
};


export type MutationUpdateBillingIntegrationsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationAccessRightHistoryRecordArgs = {
  data?: Maybe<BillingIntegrationAccessRightHistoryRecordCreateInput>;
};


export type MutationCreateBillingIntegrationAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationAccessRightHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingIntegrationAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationAccessRightHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationAccessRightArgs = {
  data?: Maybe<BillingIntegrationAccessRightCreateInput>;
};


export type MutationCreateBillingIntegrationAccessRightsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationAccessRightsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationAccessRightArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationAccessRightUpdateInput>;
};


export type MutationUpdateBillingIntegrationAccessRightsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationAccessRightsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationAccessRightArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationAccessRightsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationOrganizationContextHistoryRecordArgs = {
  data?: Maybe<BillingIntegrationOrganizationContextHistoryRecordCreateInput>;
};


export type MutationCreateBillingIntegrationOrganizationContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationOrganizationContextHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationOrganizationContextHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingIntegrationOrganizationContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationOrganizationContextHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationOrganizationContextHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationOrganizationContextArgs = {
  data?: Maybe<BillingIntegrationOrganizationContextCreateInput>;
};


export type MutationCreateBillingIntegrationOrganizationContextsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationOrganizationContextArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationOrganizationContextUpdateInput>;
};


export type MutationUpdateBillingIntegrationOrganizationContextsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationOrganizationContextArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationOrganizationContextsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingIntegrationProblemArgs = {
  data?: Maybe<BillingIntegrationProblemCreateInput>;
};


export type MutationCreateBillingIntegrationProblemsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationProblemsCreateInput>>>;
};


export type MutationUpdateBillingIntegrationProblemArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingIntegrationProblemUpdateInput>;
};


export type MutationUpdateBillingIntegrationProblemsArgs = {
  data?: Maybe<Array<Maybe<BillingIntegrationProblemsUpdateInput>>>;
};


export type MutationDeleteBillingIntegrationProblemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingIntegrationProblemsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingPropertyHistoryRecordArgs = {
  data?: Maybe<BillingPropertyHistoryRecordCreateInput>;
};


export type MutationCreateBillingPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingPropertyHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingPropertyHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingPropertyHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingPropertyHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingPropertyArgs = {
  data?: Maybe<BillingPropertyCreateInput>;
};


export type MutationCreateBillingPropertiesArgs = {
  data?: Maybe<Array<Maybe<BillingPropertiesCreateInput>>>;
};


export type MutationUpdateBillingPropertyArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingPropertyUpdateInput>;
};


export type MutationUpdateBillingPropertiesArgs = {
  data?: Maybe<Array<Maybe<BillingPropertiesUpdateInput>>>;
};


export type MutationDeleteBillingPropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingPropertiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingAccountHistoryRecordArgs = {
  data?: Maybe<BillingAccountHistoryRecordCreateInput>;
};


export type MutationCreateBillingAccountHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingAccountHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingAccountHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingAccountHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingAccountHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingAccountHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingAccountHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingAccountHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingAccountArgs = {
  data?: Maybe<BillingAccountCreateInput>;
};


export type MutationCreateBillingAccountsArgs = {
  data?: Maybe<Array<Maybe<BillingAccountsCreateInput>>>;
};


export type MutationUpdateBillingAccountArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingAccountUpdateInput>;
};


export type MutationUpdateBillingAccountsArgs = {
  data?: Maybe<Array<Maybe<BillingAccountsUpdateInput>>>;
};


export type MutationDeleteBillingAccountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingAccountsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingReceiptHistoryRecordArgs = {
  data?: Maybe<BillingReceiptHistoryRecordCreateInput>;
};


export type MutationCreateBillingReceiptHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingReceiptHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingReceiptHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingReceiptHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingReceiptHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingReceiptArgs = {
  data?: Maybe<BillingReceiptCreateInput>;
};


export type MutationCreateBillingReceiptsArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptsCreateInput>>>;
};


export type MutationUpdateBillingReceiptArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptUpdateInput>;
};


export type MutationUpdateBillingReceiptsArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptsUpdateInput>>>;
};


export type MutationDeleteBillingReceiptArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingReceiptsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingRecipientHistoryRecordArgs = {
  data?: Maybe<BillingRecipientHistoryRecordCreateInput>;
};


export type MutationCreateBillingRecipientHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingRecipientHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingRecipientHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingRecipientHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingRecipientHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingRecipientHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingRecipientHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingRecipientHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingRecipientArgs = {
  data?: Maybe<BillingRecipientCreateInput>;
};


export type MutationCreateBillingRecipientsArgs = {
  data?: Maybe<Array<Maybe<BillingRecipientsCreateInput>>>;
};


export type MutationUpdateBillingRecipientArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingRecipientUpdateInput>;
};


export type MutationUpdateBillingRecipientsArgs = {
  data?: Maybe<Array<Maybe<BillingRecipientsUpdateInput>>>;
};


export type MutationDeleteBillingRecipientArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingRecipientsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingCategoryHistoryRecordArgs = {
  data?: Maybe<BillingCategoryHistoryRecordCreateInput>;
};


export type MutationCreateBillingCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingCategoryHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingCategoryHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingCategoryHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingCategoryHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingCategoryArgs = {
  data?: Maybe<BillingCategoryCreateInput>;
};


export type MutationCreateBillingCategoriesArgs = {
  data?: Maybe<Array<Maybe<BillingCategoriesCreateInput>>>;
};


export type MutationUpdateBillingCategoryArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingCategoryUpdateInput>;
};


export type MutationUpdateBillingCategoriesArgs = {
  data?: Maybe<Array<Maybe<BillingCategoriesUpdateInput>>>;
};


export type MutationDeleteBillingCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingCategoriesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingReceiptFileHistoryRecordArgs = {
  data?: Maybe<BillingReceiptFileHistoryRecordCreateInput>;
};


export type MutationCreateBillingReceiptFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBillingReceiptFileHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptFileHistoryRecordUpdateInput>;
};


export type MutationUpdateBillingReceiptFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBillingReceiptFileHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingReceiptFileHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBillingReceiptFileArgs = {
  data?: Maybe<BillingReceiptFileCreateInput>;
};


export type MutationCreateBillingReceiptFilesArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptFilesCreateInput>>>;
};


export type MutationUpdateBillingReceiptFileArgs = {
  id: Scalars['ID'];
  data?: Maybe<BillingReceiptFileUpdateInput>;
};


export type MutationUpdateBillingReceiptFilesArgs = {
  data?: Maybe<Array<Maybe<BillingReceiptFilesUpdateInput>>>;
};


export type MutationDeleteBillingReceiptFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBillingReceiptFilesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankAccountHistoryRecordArgs = {
  data?: Maybe<BankAccountHistoryRecordCreateInput>;
};


export type MutationCreateBankAccountHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankAccountHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankAccountHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountHistoryRecordUpdateInput>;
};


export type MutationUpdateBankAccountHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankAccountHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankAccountHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankAccountHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankAccountArgs = {
  data?: Maybe<BankAccountCreateInput>;
};


export type MutationCreateBankAccountsArgs = {
  data?: Maybe<Array<Maybe<BankAccountsCreateInput>>>;
};


export type MutationUpdateBankAccountArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountUpdateInput>;
};


export type MutationUpdateBankAccountsArgs = {
  data?: Maybe<Array<Maybe<BankAccountsUpdateInput>>>;
};


export type MutationDeleteBankAccountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankAccountsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankCategoryHistoryRecordArgs = {
  data?: Maybe<BankCategoryHistoryRecordCreateInput>;
};


export type MutationCreateBankCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankCategoryHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankCategoryHistoryRecordUpdateInput>;
};


export type MutationUpdateBankCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankCategoryHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankCategoryHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankCategoryArgs = {
  data?: Maybe<BankCategoryCreateInput>;
};


export type MutationCreateBankCategoriesArgs = {
  data?: Maybe<Array<Maybe<BankCategoriesCreateInput>>>;
};


export type MutationUpdateBankCategoryArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankCategoryUpdateInput>;
};


export type MutationUpdateBankCategoriesArgs = {
  data?: Maybe<Array<Maybe<BankCategoriesUpdateInput>>>;
};


export type MutationDeleteBankCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankCategoriesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankCostItemHistoryRecordArgs = {
  data?: Maybe<BankCostItemHistoryRecordCreateInput>;
};


export type MutationCreateBankCostItemHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankCostItemHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankCostItemHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankCostItemHistoryRecordUpdateInput>;
};


export type MutationUpdateBankCostItemHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankCostItemHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankCostItemHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankCostItemHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankCostItemArgs = {
  data?: Maybe<BankCostItemCreateInput>;
};


export type MutationCreateBankCostItemsArgs = {
  data?: Maybe<Array<Maybe<BankCostItemsCreateInput>>>;
};


export type MutationUpdateBankCostItemArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankCostItemUpdateInput>;
};


export type MutationUpdateBankCostItemsArgs = {
  data?: Maybe<Array<Maybe<BankCostItemsUpdateInput>>>;
};


export type MutationDeleteBankCostItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankCostItemsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankContractorAccountHistoryRecordArgs = {
  data?: Maybe<BankContractorAccountHistoryRecordCreateInput>;
};


export type MutationCreateBankContractorAccountHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankContractorAccountHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankContractorAccountHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankContractorAccountHistoryRecordUpdateInput>;
};


export type MutationUpdateBankContractorAccountHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankContractorAccountHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankContractorAccountHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankContractorAccountHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankContractorAccountArgs = {
  data?: Maybe<BankContractorAccountCreateInput>;
};


export type MutationCreateBankContractorAccountsArgs = {
  data?: Maybe<Array<Maybe<BankContractorAccountsCreateInput>>>;
};


export type MutationUpdateBankContractorAccountArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankContractorAccountUpdateInput>;
};


export type MutationUpdateBankContractorAccountsArgs = {
  data?: Maybe<Array<Maybe<BankContractorAccountsUpdateInput>>>;
};


export type MutationDeleteBankContractorAccountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankContractorAccountsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationHistoryRecordArgs = {
  data?: Maybe<BankIntegrationHistoryRecordCreateInput>;
};


export type MutationCreateBankIntegrationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankIntegrationHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationHistoryRecordUpdateInput>;
};


export type MutationUpdateBankIntegrationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationArgs = {
  data?: Maybe<BankIntegrationCreateInput>;
};


export type MutationCreateBankIntegrationsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationsCreateInput>>>;
};


export type MutationUpdateBankIntegrationArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationUpdateInput>;
};


export type MutationUpdateBankIntegrationsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationAccessRightHistoryRecordArgs = {
  data?: Maybe<BankIntegrationAccessRightHistoryRecordCreateInput>;
};


export type MutationCreateBankIntegrationAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankIntegrationAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccessRightHistoryRecordUpdateInput>;
};


export type MutationUpdateBankIntegrationAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationAccessRightHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationAccessRightArgs = {
  data?: Maybe<BankIntegrationAccessRightCreateInput>;
};


export type MutationCreateBankIntegrationAccessRightsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccessRightsCreateInput>>>;
};


export type MutationUpdateBankIntegrationAccessRightArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccessRightUpdateInput>;
};


export type MutationUpdateBankIntegrationAccessRightsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccessRightsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationAccessRightArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationAccessRightsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationAccountContextHistoryRecordArgs = {
  data?: Maybe<BankIntegrationAccountContextHistoryRecordCreateInput>;
};


export type MutationCreateBankIntegrationAccountContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankIntegrationAccountContextHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccountContextHistoryRecordUpdateInput>;
};


export type MutationUpdateBankIntegrationAccountContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationAccountContextHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationAccountContextHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationAccountContextArgs = {
  data?: Maybe<BankIntegrationAccountContextCreateInput>;
};


export type MutationCreateBankIntegrationAccountContextsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccountContextsCreateInput>>>;
};


export type MutationUpdateBankIntegrationAccountContextArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationAccountContextUpdateInput>;
};


export type MutationUpdateBankIntegrationAccountContextsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationAccountContextsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationAccountContextArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationAccountContextsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankTransactionHistoryRecordArgs = {
  data?: Maybe<BankTransactionHistoryRecordCreateInput>;
};


export type MutationCreateBankTransactionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankTransactionHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankTransactionHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankTransactionHistoryRecordUpdateInput>;
};


export type MutationUpdateBankTransactionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankTransactionHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankTransactionHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankTransactionHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankTransactionArgs = {
  data?: Maybe<BankTransactionCreateInput>;
};


export type MutationCreateBankTransactionsArgs = {
  data?: Maybe<Array<Maybe<BankTransactionsCreateInput>>>;
};


export type MutationUpdateBankTransactionArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankTransactionUpdateInput>;
};


export type MutationUpdateBankTransactionsArgs = {
  data?: Maybe<Array<Maybe<BankTransactionsUpdateInput>>>;
};


export type MutationDeleteBankTransactionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankTransactionsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankSyncTaskHistoryRecordArgs = {
  data?: Maybe<BankSyncTaskHistoryRecordCreateInput>;
};


export type MutationCreateBankSyncTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankSyncTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankSyncTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankSyncTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateBankSyncTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankSyncTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankSyncTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankSyncTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankSyncTaskArgs = {
  data?: Maybe<BankSyncTaskCreateInput>;
};


export type MutationCreateBankSyncTasksArgs = {
  data?: Maybe<Array<Maybe<BankSyncTasksCreateInput>>>;
};


export type MutationUpdateBankSyncTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankSyncTaskUpdateInput>;
};


export type MutationUpdateBankSyncTasksArgs = {
  data?: Maybe<Array<Maybe<BankSyncTasksUpdateInput>>>;
};


export type MutationDeleteBankSyncTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankSyncTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationOrganizationContextHistoryRecordArgs = {
  data?: Maybe<BankIntegrationOrganizationContextHistoryRecordCreateInput>;
};


export type MutationCreateBankIntegrationOrganizationContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankIntegrationOrganizationContextHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationOrganizationContextHistoryRecordUpdateInput>;
};


export type MutationUpdateBankIntegrationOrganizationContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationOrganizationContextHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationOrganizationContextHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankIntegrationOrganizationContextArgs = {
  data?: Maybe<BankIntegrationOrganizationContextCreateInput>;
};


export type MutationCreateBankIntegrationOrganizationContextsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationOrganizationContextsCreateInput>>>;
};


export type MutationUpdateBankIntegrationOrganizationContextArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankIntegrationOrganizationContextUpdateInput>;
};


export type MutationUpdateBankIntegrationOrganizationContextsArgs = {
  data?: Maybe<Array<Maybe<BankIntegrationOrganizationContextsUpdateInput>>>;
};


export type MutationDeleteBankIntegrationOrganizationContextArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankIntegrationOrganizationContextsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankAccountReportHistoryRecordArgs = {
  data?: Maybe<BankAccountReportHistoryRecordCreateInput>;
};


export type MutationCreateBankAccountReportHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankAccountReportHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportHistoryRecordUpdateInput>;
};


export type MutationUpdateBankAccountReportHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankAccountReportHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankAccountReportHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankAccountReportArgs = {
  data?: Maybe<BankAccountReportCreateInput>;
};


export type MutationCreateBankAccountReportsArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportsCreateInput>>>;
};


export type MutationUpdateBankAccountReportArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportUpdateInput>;
};


export type MutationUpdateBankAccountReportsArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportsUpdateInput>>>;
};


export type MutationDeleteBankAccountReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankAccountReportsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankAccountReportTaskHistoryRecordArgs = {
  data?: Maybe<BankAccountReportTaskHistoryRecordCreateInput>;
};


export type MutationCreateBankAccountReportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateBankAccountReportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateBankAccountReportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteBankAccountReportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankAccountReportTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateBankAccountReportTaskArgs = {
  data?: Maybe<BankAccountReportTaskCreateInput>;
};


export type MutationCreateBankAccountReportTasksArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportTasksCreateInput>>>;
};


export type MutationUpdateBankAccountReportTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<BankAccountReportTaskUpdateInput>;
};


export type MutationUpdateBankAccountReportTasksArgs = {
  data?: Maybe<Array<Maybe<BankAccountReportTasksUpdateInput>>>;
};


export type MutationDeleteBankAccountReportTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBankAccountReportTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketHistoryRecordArgs = {
  data?: Maybe<TicketHistoryRecordCreateInput>;
};


export type MutationCreateTicketHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketArgs = {
  data?: Maybe<TicketCreateInput>;
};


export type MutationCreateTicketsArgs = {
  data?: Maybe<Array<Maybe<TicketsCreateInput>>>;
};


export type MutationUpdateTicketArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketUpdateInput>;
};


export type MutationUpdateTicketsArgs = {
  data?: Maybe<Array<Maybe<TicketsUpdateInput>>>;
};


export type MutationDeleteTicketArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketSourceHistoryRecordArgs = {
  data?: Maybe<TicketSourceHistoryRecordCreateInput>;
};


export type MutationCreateTicketSourceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketSourceHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketSourceHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketSourceHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketSourceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketSourceHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketSourceHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketSourceHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketSourceArgs = {
  data?: Maybe<TicketSourceCreateInput>;
};


export type MutationCreateTicketSourcesArgs = {
  data?: Maybe<Array<Maybe<TicketSourcesCreateInput>>>;
};


export type MutationUpdateTicketSourceArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketSourceUpdateInput>;
};


export type MutationUpdateTicketSourcesArgs = {
  data?: Maybe<Array<Maybe<TicketSourcesUpdateInput>>>;
};


export type MutationDeleteTicketSourceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketSourcesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketStatusHistoryRecordArgs = {
  data?: Maybe<TicketStatusHistoryRecordCreateInput>;
};


export type MutationCreateTicketStatusHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketStatusHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketStatusHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketStatusHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketStatusHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketStatusHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketStatusHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketStatusHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketStatusArgs = {
  data?: Maybe<TicketStatusCreateInput>;
};


export type MutationCreateTicketStatusesArgs = {
  data?: Maybe<Array<Maybe<TicketStatusesCreateInput>>>;
};


export type MutationUpdateTicketStatusArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketStatusUpdateInput>;
};


export type MutationUpdateTicketStatusesArgs = {
  data?: Maybe<Array<Maybe<TicketStatusesUpdateInput>>>;
};


export type MutationDeleteTicketStatusArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketStatusesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketFileHistoryRecordArgs = {
  data?: Maybe<TicketFileHistoryRecordCreateInput>;
};


export type MutationCreateTicketFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketFileHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketFileHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketFileHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketFileHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketFileHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketFileHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketFileArgs = {
  data?: Maybe<TicketFileCreateInput>;
};


export type MutationCreateTicketFilesArgs = {
  data?: Maybe<Array<Maybe<TicketFilesCreateInput>>>;
};


export type MutationUpdateTicketFileArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketFileUpdateInput>;
};


export type MutationUpdateTicketFilesArgs = {
  data?: Maybe<Array<Maybe<TicketFilesUpdateInput>>>;
};


export type MutationDeleteTicketFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketFilesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketChangeArgs = {
  data?: Maybe<TicketChangeCreateInput>;
};


export type MutationCreateTicketChangesArgs = {
  data?: Maybe<Array<Maybe<TicketChangesCreateInput>>>;
};


export type MutationUpdateTicketChangeArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketChangeUpdateInput>;
};


export type MutationUpdateTicketChangesArgs = {
  data?: Maybe<Array<Maybe<TicketChangesUpdateInput>>>;
};


export type MutationDeleteTicketChangeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketChangesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketCommentHistoryRecordArgs = {
  data?: Maybe<TicketCommentHistoryRecordCreateInput>;
};


export type MutationCreateTicketCommentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketCommentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketCommentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketCommentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketCommentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketCommentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketCommentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketCommentArgs = {
  data?: Maybe<TicketCommentCreateInput>;
};


export type MutationCreateTicketCommentsArgs = {
  data?: Maybe<Array<Maybe<TicketCommentsCreateInput>>>;
};


export type MutationUpdateTicketCommentArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentUpdateInput>;
};


export type MutationUpdateTicketCommentsArgs = {
  data?: Maybe<Array<Maybe<TicketCommentsUpdateInput>>>;
};


export type MutationDeleteTicketCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketCommentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketPlaceClassifierHistoryRecordArgs = {
  data?: Maybe<TicketPlaceClassifierHistoryRecordCreateInput>;
};


export type MutationCreateTicketPlaceClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketPlaceClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketPlaceClassifierHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketPlaceClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketPlaceClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketPlaceClassifierHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketPlaceClassifierArgs = {
  data?: Maybe<TicketPlaceClassifierCreateInput>;
};


export type MutationCreateTicketPlaceClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketPlaceClassifiersCreateInput>>>;
};


export type MutationUpdateTicketPlaceClassifierArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketPlaceClassifierUpdateInput>;
};


export type MutationUpdateTicketPlaceClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketPlaceClassifiersUpdateInput>>>;
};


export type MutationDeleteTicketPlaceClassifierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketPlaceClassifiersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketCategoryClassifierHistoryRecordArgs = {
  data?: Maybe<TicketCategoryClassifierHistoryRecordCreateInput>;
};


export type MutationCreateTicketCategoryClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketCategoryClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketCategoryClassifierHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketCategoryClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketCategoryClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketCategoryClassifierHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketCategoryClassifierArgs = {
  data?: Maybe<TicketCategoryClassifierCreateInput>;
};


export type MutationCreateTicketCategoryClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketCategoryClassifiersCreateInput>>>;
};


export type MutationUpdateTicketCategoryClassifierArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketCategoryClassifierUpdateInput>;
};


export type MutationUpdateTicketCategoryClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketCategoryClassifiersUpdateInput>>>;
};


export type MutationDeleteTicketCategoryClassifierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketCategoryClassifiersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketProblemClassifierHistoryRecordArgs = {
  data?: Maybe<TicketProblemClassifierHistoryRecordCreateInput>;
};


export type MutationCreateTicketProblemClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketProblemClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketProblemClassifierHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketProblemClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketProblemClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketProblemClassifierHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketProblemClassifierArgs = {
  data?: Maybe<TicketProblemClassifierCreateInput>;
};


export type MutationCreateTicketProblemClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketProblemClassifiersCreateInput>>>;
};


export type MutationUpdateTicketProblemClassifierArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketProblemClassifierUpdateInput>;
};


export type MutationUpdateTicketProblemClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketProblemClassifiersUpdateInput>>>;
};


export type MutationDeleteTicketProblemClassifierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketProblemClassifiersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketClassifierHistoryRecordArgs = {
  data?: Maybe<TicketClassifierHistoryRecordCreateInput>;
};


export type MutationCreateTicketClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketClassifierHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketClassifierHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketClassifierHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketClassifierHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketClassifierArgs = {
  data?: Maybe<TicketClassifierCreateInput>;
};


export type MutationCreateTicketClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketClassifiersCreateInput>>>;
};


export type MutationUpdateTicketClassifierArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketClassifierUpdateInput>;
};


export type MutationUpdateTicketClassifiersArgs = {
  data?: Maybe<Array<Maybe<TicketClassifiersUpdateInput>>>;
};


export type MutationDeleteTicketClassifierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketClassifiersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketFilterTemplateHistoryRecordArgs = {
  data?: Maybe<TicketFilterTemplateHistoryRecordCreateInput>;
};


export type MutationCreateTicketFilterTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketFilterTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketFilterTemplateHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketFilterTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketFilterTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketFilterTemplateHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketFilterTemplateArgs = {
  data?: Maybe<TicketFilterTemplateCreateInput>;
};


export type MutationCreateTicketFilterTemplatesArgs = {
  data?: Maybe<Array<Maybe<TicketFilterTemplatesCreateInput>>>;
};


export type MutationUpdateTicketFilterTemplateArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketFilterTemplateUpdateInput>;
};


export type MutationUpdateTicketFilterTemplatesArgs = {
  data?: Maybe<Array<Maybe<TicketFilterTemplatesUpdateInput>>>;
};


export type MutationDeleteTicketFilterTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketFilterTemplatesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketCommentFileHistoryRecordArgs = {
  data?: Maybe<TicketCommentFileHistoryRecordCreateInput>;
};


export type MutationCreateTicketCommentFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketCommentFileHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketCommentFileHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentFileHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketCommentFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketCommentFileHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketCommentFileHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketCommentFileHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketCommentFileArgs = {
  data?: Maybe<TicketCommentFileCreateInput>;
};


export type MutationCreateTicketCommentFilesArgs = {
  data?: Maybe<Array<Maybe<TicketCommentFilesCreateInput>>>;
};


export type MutationUpdateTicketCommentFileArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentFileUpdateInput>;
};


export type MutationUpdateTicketCommentFilesArgs = {
  data?: Maybe<Array<Maybe<TicketCommentFilesUpdateInput>>>;
};


export type MutationDeleteTicketCommentFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketCommentFilesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserTicketCommentReadTimeHistoryRecordArgs = {
  data?: Maybe<UserTicketCommentReadTimeHistoryRecordCreateInput>;
};


export type MutationCreateUserTicketCommentReadTimeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserTicketCommentReadTimeHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserTicketCommentReadTimeHistoryRecordUpdateInput>;
};


export type MutationUpdateUserTicketCommentReadTimeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserTicketCommentReadTimeHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserTicketCommentReadTimeHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserTicketCommentReadTimeArgs = {
  data?: Maybe<UserTicketCommentReadTimeCreateInput>;
};


export type MutationCreateUserTicketCommentReadTimesArgs = {
  data?: Maybe<Array<Maybe<UserTicketCommentReadTimesCreateInput>>>;
};


export type MutationUpdateUserTicketCommentReadTimeArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserTicketCommentReadTimeUpdateInput>;
};


export type MutationUpdateUserTicketCommentReadTimesArgs = {
  data?: Maybe<Array<Maybe<UserTicketCommentReadTimesUpdateInput>>>;
};


export type MutationDeleteUserTicketCommentReadTimeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserTicketCommentReadTimesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketPropertyHintHistoryRecordArgs = {
  data?: Maybe<TicketPropertyHintHistoryRecordCreateInput>;
};


export type MutationCreateTicketPropertyHintHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketPropertyHintHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketPropertyHintHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketPropertyHintHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketPropertyHintHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketPropertyHintArgs = {
  data?: Maybe<TicketPropertyHintCreateInput>;
};


export type MutationCreateTicketPropertyHintsArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintsCreateInput>>>;
};


export type MutationUpdateTicketPropertyHintArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintUpdateInput>;
};


export type MutationUpdateTicketPropertyHintsArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintsUpdateInput>>>;
};


export type MutationDeleteTicketPropertyHintArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketPropertyHintsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketPropertyHintPropertyHistoryRecordArgs = {
  data?: Maybe<TicketPropertyHintPropertyHistoryRecordCreateInput>;
};


export type MutationCreateTicketPropertyHintPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketPropertyHintPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintPropertyHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketPropertyHintPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketPropertyHintPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketPropertyHintPropertyHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketPropertyHintPropertyArgs = {
  data?: Maybe<TicketPropertyHintPropertyCreateInput>;
};


export type MutationCreateTicketPropertyHintPropertiesArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintPropertiesCreateInput>>>;
};


export type MutationUpdateTicketPropertyHintPropertyArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintPropertyUpdateInput>;
};


export type MutationUpdateTicketPropertyHintPropertiesArgs = {
  data?: Maybe<Array<Maybe<TicketPropertyHintPropertiesUpdateInput>>>;
};


export type MutationDeleteTicketPropertyHintPropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketPropertyHintPropertiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketExportTaskHistoryRecordArgs = {
  data?: Maybe<TicketExportTaskHistoryRecordCreateInput>;
};


export type MutationCreateTicketExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketExportTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketExportTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketExportTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketExportTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketExportTaskArgs = {
  data?: Maybe<TicketExportTaskCreateInput>;
};


export type MutationCreateTicketExportTasksArgs = {
  data?: Maybe<Array<Maybe<TicketExportTasksCreateInput>>>;
};


export type MutationUpdateTicketExportTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketExportTaskUpdateInput>;
};


export type MutationUpdateTicketExportTasksArgs = {
  data?: Maybe<Array<Maybe<TicketExportTasksUpdateInput>>>;
};


export type MutationDeleteTicketExportTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketExportTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketOrganizationSettingHistoryRecordArgs = {
  data?: Maybe<TicketOrganizationSettingHistoryRecordCreateInput>;
};


export type MutationCreateTicketOrganizationSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketOrganizationSettingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketOrganizationSettingHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketOrganizationSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketOrganizationSettingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketOrganizationSettingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketOrganizationSettingArgs = {
  data?: Maybe<TicketOrganizationSettingCreateInput>;
};


export type MutationCreateTicketOrganizationSettingsArgs = {
  data?: Maybe<Array<Maybe<TicketOrganizationSettingsCreateInput>>>;
};


export type MutationUpdateTicketOrganizationSettingArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketOrganizationSettingUpdateInput>;
};


export type MutationUpdateTicketOrganizationSettingsArgs = {
  data?: Maybe<Array<Maybe<TicketOrganizationSettingsUpdateInput>>>;
};


export type MutationDeleteTicketOrganizationSettingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketOrganizationSettingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentHistoryRecordArgs = {
  data?: Maybe<IncidentHistoryRecordCreateInput>;
};


export type MutationCreateIncidentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateIncidentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentHistoryRecordUpdateInput>;
};


export type MutationUpdateIncidentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteIncidentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentArgs = {
  data?: Maybe<IncidentCreateInput>;
};


export type MutationCreateIncidentsArgs = {
  data?: Maybe<Array<Maybe<IncidentsCreateInput>>>;
};


export type MutationUpdateIncidentArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentUpdateInput>;
};


export type MutationUpdateIncidentsArgs = {
  data?: Maybe<Array<Maybe<IncidentsUpdateInput>>>;
};


export type MutationDeleteIncidentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentChangeArgs = {
  data?: Maybe<IncidentChangeCreateInput>;
};


export type MutationCreateIncidentChangesArgs = {
  data?: Maybe<Array<Maybe<IncidentChangesCreateInput>>>;
};


export type MutationUpdateIncidentChangeArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentChangeUpdateInput>;
};


export type MutationUpdateIncidentChangesArgs = {
  data?: Maybe<Array<Maybe<IncidentChangesUpdateInput>>>;
};


export type MutationDeleteIncidentChangeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentChangesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentPropertyHistoryRecordArgs = {
  data?: Maybe<IncidentPropertyHistoryRecordCreateInput>;
};


export type MutationCreateIncidentPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentPropertyHistoryRecordsCreateInput>>>;
};


export type MutationUpdateIncidentPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentPropertyHistoryRecordUpdateInput>;
};


export type MutationUpdateIncidentPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentPropertyHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteIncidentPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentPropertyHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentPropertyArgs = {
  data?: Maybe<IncidentPropertyCreateInput>;
};


export type MutationCreateIncidentPropertiesArgs = {
  data?: Maybe<Array<Maybe<IncidentPropertiesCreateInput>>>;
};


export type MutationUpdateIncidentPropertyArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentPropertyUpdateInput>;
};


export type MutationUpdateIncidentPropertiesArgs = {
  data?: Maybe<Array<Maybe<IncidentPropertiesUpdateInput>>>;
};


export type MutationDeleteIncidentPropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentPropertiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentClassifierHistoryRecordArgs = {
  data?: Maybe<IncidentClassifierHistoryRecordCreateInput>;
};


export type MutationCreateIncidentClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifierHistoryRecordsCreateInput>>>;
};


export type MutationUpdateIncidentClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierHistoryRecordUpdateInput>;
};


export type MutationUpdateIncidentClassifierHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifierHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteIncidentClassifierHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentClassifierHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentClassifierArgs = {
  data?: Maybe<IncidentClassifierCreateInput>;
};


export type MutationCreateIncidentClassifiersArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifiersCreateInput>>>;
};


export type MutationUpdateIncidentClassifierArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierUpdateInput>;
};


export type MutationUpdateIncidentClassifiersArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifiersUpdateInput>>>;
};


export type MutationDeleteIncidentClassifierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentClassifiersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentClassifierIncidentHistoryRecordArgs = {
  data?: Maybe<IncidentClassifierIncidentHistoryRecordCreateInput>;
};


export type MutationCreateIncidentClassifierIncidentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateIncidentClassifierIncidentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierIncidentHistoryRecordUpdateInput>;
};


export type MutationUpdateIncidentClassifierIncidentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteIncidentClassifierIncidentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentClassifierIncidentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentClassifierIncidentArgs = {
  data?: Maybe<IncidentClassifierIncidentCreateInput>;
};


export type MutationCreateIncidentClassifierIncidentsArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifierIncidentsCreateInput>>>;
};


export type MutationUpdateIncidentClassifierIncidentArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentClassifierIncidentUpdateInput>;
};


export type MutationUpdateIncidentClassifierIncidentsArgs = {
  data?: Maybe<Array<Maybe<IncidentClassifierIncidentsUpdateInput>>>;
};


export type MutationDeleteIncidentClassifierIncidentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentClassifierIncidentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserFavoriteTicketHistoryRecordArgs = {
  data?: Maybe<UserFavoriteTicketHistoryRecordCreateInput>;
};


export type MutationCreateUserFavoriteTicketHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserFavoriteTicketHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserFavoriteTicketHistoryRecordUpdateInput>;
};


export type MutationUpdateUserFavoriteTicketHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserFavoriteTicketHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserFavoriteTicketHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserFavoriteTicketArgs = {
  data?: Maybe<UserFavoriteTicketCreateInput>;
};


export type MutationCreateUserFavoriteTicketsArgs = {
  data?: Maybe<Array<Maybe<UserFavoriteTicketsCreateInput>>>;
};


export type MutationUpdateUserFavoriteTicketArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserFavoriteTicketUpdateInput>;
};


export type MutationUpdateUserFavoriteTicketsArgs = {
  data?: Maybe<Array<Maybe<UserFavoriteTicketsUpdateInput>>>;
};


export type MutationDeleteUserFavoriteTicketArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserFavoriteTicketsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentExportTaskHistoryRecordArgs = {
  data?: Maybe<IncidentExportTaskHistoryRecordCreateInput>;
};


export type MutationCreateIncidentExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateIncidentExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentExportTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateIncidentExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteIncidentExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentExportTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateIncidentExportTaskArgs = {
  data?: Maybe<IncidentExportTaskCreateInput>;
};


export type MutationCreateIncidentExportTasksArgs = {
  data?: Maybe<Array<Maybe<IncidentExportTasksCreateInput>>>;
};


export type MutationUpdateIncidentExportTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<IncidentExportTaskUpdateInput>;
};


export type MutationUpdateIncidentExportTasksArgs = {
  data?: Maybe<Array<Maybe<IncidentExportTasksUpdateInput>>>;
};


export type MutationDeleteIncidentExportTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncidentExportTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCallRecordHistoryRecordArgs = {
  data?: Maybe<CallRecordHistoryRecordCreateInput>;
};


export type MutationCreateCallRecordHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<CallRecordHistoryRecordsCreateInput>>>;
};


export type MutationUpdateCallRecordHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordHistoryRecordUpdateInput>;
};


export type MutationUpdateCallRecordHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<CallRecordHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteCallRecordHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCallRecordHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCallRecordArgs = {
  data?: Maybe<CallRecordCreateInput>;
};


export type MutationCreateCallRecordsArgs = {
  data?: Maybe<Array<Maybe<CallRecordsCreateInput>>>;
};


export type MutationUpdateCallRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordUpdateInput>;
};


export type MutationUpdateCallRecordsArgs = {
  data?: Maybe<Array<Maybe<CallRecordsUpdateInput>>>;
};


export type MutationDeleteCallRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCallRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCallRecordFragmentHistoryRecordArgs = {
  data?: Maybe<CallRecordFragmentHistoryRecordCreateInput>;
};


export type MutationCreateCallRecordFragmentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateCallRecordFragmentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordFragmentHistoryRecordUpdateInput>;
};


export type MutationUpdateCallRecordFragmentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteCallRecordFragmentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCallRecordFragmentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateCallRecordFragmentArgs = {
  data?: Maybe<CallRecordFragmentCreateInput>;
};


export type MutationCreateCallRecordFragmentsArgs = {
  data?: Maybe<Array<Maybe<CallRecordFragmentsCreateInput>>>;
};


export type MutationUpdateCallRecordFragmentArgs = {
  id: Scalars['ID'];
  data?: Maybe<CallRecordFragmentUpdateInput>;
};


export type MutationUpdateCallRecordFragmentsArgs = {
  data?: Maybe<Array<Maybe<CallRecordFragmentsUpdateInput>>>;
};


export type MutationDeleteCallRecordFragmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCallRecordFragmentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketAutoAssignmentHistoryRecordArgs = {
  data?: Maybe<TicketAutoAssignmentHistoryRecordCreateInput>;
};


export type MutationCreateTicketAutoAssignmentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketAutoAssignmentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketAutoAssignmentHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketAutoAssignmentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketAutoAssignmentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketAutoAssignmentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketAutoAssignmentArgs = {
  data?: Maybe<TicketAutoAssignmentCreateInput>;
};


export type MutationCreateTicketAutoAssignmentsArgs = {
  data?: Maybe<Array<Maybe<TicketAutoAssignmentsCreateInput>>>;
};


export type MutationUpdateTicketAutoAssignmentArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketAutoAssignmentUpdateInput>;
};


export type MutationUpdateTicketAutoAssignmentsArgs = {
  data?: Maybe<Array<Maybe<TicketAutoAssignmentsUpdateInput>>>;
};


export type MutationDeleteTicketAutoAssignmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketAutoAssignmentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketDocumentGenerationTaskHistoryRecordArgs = {
  data?: Maybe<TicketDocumentGenerationTaskHistoryRecordCreateInput>;
};


export type MutationCreateTicketDocumentGenerationTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTicketDocumentGenerationTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketDocumentGenerationTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateTicketDocumentGenerationTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTicketDocumentGenerationTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketDocumentGenerationTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTicketDocumentGenerationTaskArgs = {
  data?: Maybe<TicketDocumentGenerationTaskCreateInput>;
};


export type MutationCreateTicketDocumentGenerationTasksArgs = {
  data?: Maybe<Array<Maybe<TicketDocumentGenerationTasksCreateInput>>>;
};


export type MutationUpdateTicketDocumentGenerationTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<TicketDocumentGenerationTaskUpdateInput>;
};


export type MutationUpdateTicketDocumentGenerationTasksArgs = {
  data?: Maybe<Array<Maybe<TicketDocumentGenerationTasksUpdateInput>>>;
};


export type MutationDeleteTicketDocumentGenerationTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTicketDocumentGenerationTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageHistoryRecordArgs = {
  data?: Maybe<MessageHistoryRecordCreateInput>;
};


export type MutationCreateMessageHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMessageHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageHistoryRecordUpdateInput>;
};


export type MutationUpdateMessageHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMessageHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageArgs = {
  data?: Maybe<MessageCreateInput>;
};


export type MutationCreateMessagesArgs = {
  data?: Maybe<Array<Maybe<MessagesCreateInput>>>;
};


export type MutationUpdateMessageArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageUpdateInput>;
};


export type MutationUpdateMessagesArgs = {
  data?: Maybe<Array<Maybe<MessagesUpdateInput>>>;
};


export type MutationDeleteMessageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessagesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateRemoteClientHistoryRecordArgs = {
  data?: Maybe<RemoteClientHistoryRecordCreateInput>;
};


export type MutationCreateRemoteClientHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<RemoteClientHistoryRecordsCreateInput>>>;
};


export type MutationUpdateRemoteClientHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<RemoteClientHistoryRecordUpdateInput>;
};


export type MutationUpdateRemoteClientHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<RemoteClientHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteRemoteClientHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRemoteClientHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateRemoteClientArgs = {
  data?: Maybe<RemoteClientCreateInput>;
};


export type MutationCreateRemoteClientsArgs = {
  data?: Maybe<Array<Maybe<RemoteClientsCreateInput>>>;
};


export type MutationUpdateRemoteClientArgs = {
  id: Scalars['ID'];
  data?: Maybe<RemoteClientUpdateInput>;
};


export type MutationUpdateRemoteClientsArgs = {
  data?: Maybe<Array<Maybe<RemoteClientsUpdateInput>>>;
};


export type MutationDeleteRemoteClientArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRemoteClientsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageUserBlackListHistoryRecordArgs = {
  data?: Maybe<MessageUserBlackListHistoryRecordCreateInput>;
};


export type MutationCreateMessageUserBlackListHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMessageUserBlackListHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageUserBlackListHistoryRecordUpdateInput>;
};


export type MutationUpdateMessageUserBlackListHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMessageUserBlackListHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageUserBlackListHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageUserBlackListArgs = {
  data?: Maybe<MessageUserBlackListCreateInput>;
};


export type MutationCreateMessageUserBlackListsArgs = {
  data?: Maybe<Array<Maybe<MessageUserBlackListsCreateInput>>>;
};


export type MutationUpdateMessageUserBlackListArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageUserBlackListUpdateInput>;
};


export type MutationUpdateMessageUserBlackListsArgs = {
  data?: Maybe<Array<Maybe<MessageUserBlackListsUpdateInput>>>;
};


export type MutationDeleteMessageUserBlackListArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageUserBlackListsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageOrganizationBlackListHistoryRecordArgs = {
  data?: Maybe<MessageOrganizationBlackListHistoryRecordCreateInput>;
};


export type MutationCreateMessageOrganizationBlackListHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMessageOrganizationBlackListHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageOrganizationBlackListHistoryRecordUpdateInput>;
};


export type MutationUpdateMessageOrganizationBlackListHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMessageOrganizationBlackListHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageOrganizationBlackListHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageOrganizationBlackListArgs = {
  data?: Maybe<MessageOrganizationBlackListCreateInput>;
};


export type MutationCreateMessageOrganizationBlackListsArgs = {
  data?: Maybe<Array<Maybe<MessageOrganizationBlackListsCreateInput>>>;
};


export type MutationUpdateMessageOrganizationBlackListArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageOrganizationBlackListUpdateInput>;
};


export type MutationUpdateMessageOrganizationBlackListsArgs = {
  data?: Maybe<Array<Maybe<MessageOrganizationBlackListsUpdateInput>>>;
};


export type MutationDeleteMessageOrganizationBlackListArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageOrganizationBlackListsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageBatchHistoryRecordArgs = {
  data?: Maybe<MessageBatchHistoryRecordCreateInput>;
};


export type MutationCreateMessageBatchHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageBatchHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMessageBatchHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageBatchHistoryRecordUpdateInput>;
};


export type MutationUpdateMessageBatchHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageBatchHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMessageBatchHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageBatchHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageBatchArgs = {
  data?: Maybe<MessageBatchCreateInput>;
};


export type MutationCreateMessageBatchesArgs = {
  data?: Maybe<Array<Maybe<MessageBatchesCreateInput>>>;
};


export type MutationUpdateMessageBatchArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageBatchUpdateInput>;
};


export type MutationUpdateMessageBatchesArgs = {
  data?: Maybe<Array<Maybe<MessageBatchesUpdateInput>>>;
};


export type MutationDeleteMessageBatchArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageBatchesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNotificationUserSettingHistoryRecordArgs = {
  data?: Maybe<NotificationUserSettingHistoryRecordCreateInput>;
};


export type MutationCreateNotificationUserSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNotificationUserSettingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NotificationUserSettingHistoryRecordUpdateInput>;
};


export type MutationUpdateNotificationUserSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNotificationUserSettingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationUserSettingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNotificationUserSettingArgs = {
  data?: Maybe<NotificationUserSettingCreateInput>;
};


export type MutationCreateNotificationUserSettingsArgs = {
  data?: Maybe<Array<Maybe<NotificationUserSettingsCreateInput>>>;
};


export type MutationUpdateNotificationUserSettingArgs = {
  id: Scalars['ID'];
  data?: Maybe<NotificationUserSettingUpdateInput>;
};


export type MutationUpdateNotificationUserSettingsArgs = {
  data?: Maybe<Array<Maybe<NotificationUserSettingsUpdateInput>>>;
};


export type MutationDeleteNotificationUserSettingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationUserSettingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTelegramUserChatHistoryRecordArgs = {
  data?: Maybe<TelegramUserChatHistoryRecordCreateInput>;
};


export type MutationCreateTelegramUserChatHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TelegramUserChatHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTelegramUserChatHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TelegramUserChatHistoryRecordUpdateInput>;
};


export type MutationUpdateTelegramUserChatHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TelegramUserChatHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTelegramUserChatHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTelegramUserChatHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTelegramUserChatArgs = {
  data?: Maybe<TelegramUserChatCreateInput>;
};


export type MutationCreateTelegramUserChatsArgs = {
  data?: Maybe<Array<Maybe<TelegramUserChatsCreateInput>>>;
};


export type MutationUpdateTelegramUserChatArgs = {
  id: Scalars['ID'];
  data?: Maybe<TelegramUserChatUpdateInput>;
};


export type MutationUpdateTelegramUserChatsArgs = {
  data?: Maybe<Array<Maybe<TelegramUserChatsUpdateInput>>>;
};


export type MutationDeleteTelegramUserChatArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTelegramUserChatsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNotificationAnonymousSettingHistoryRecordArgs = {
  data?: Maybe<NotificationAnonymousSettingHistoryRecordCreateInput>;
};


export type MutationCreateNotificationAnonymousSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNotificationAnonymousSettingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NotificationAnonymousSettingHistoryRecordUpdateInput>;
};


export type MutationUpdateNotificationAnonymousSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNotificationAnonymousSettingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationAnonymousSettingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNotificationAnonymousSettingArgs = {
  data?: Maybe<NotificationAnonymousSettingCreateInput>;
};


export type MutationCreateNotificationAnonymousSettingsArgs = {
  data?: Maybe<Array<Maybe<NotificationAnonymousSettingsCreateInput>>>;
};


export type MutationUpdateNotificationAnonymousSettingArgs = {
  id: Scalars['ID'];
  data?: Maybe<NotificationAnonymousSettingUpdateInput>;
};


export type MutationUpdateNotificationAnonymousSettingsArgs = {
  data?: Maybe<Array<Maybe<NotificationAnonymousSettingsUpdateInput>>>;
};


export type MutationDeleteNotificationAnonymousSettingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationAnonymousSettingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateContactHistoryRecordArgs = {
  data?: Maybe<ContactHistoryRecordCreateInput>;
};


export type MutationCreateContactHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ContactHistoryRecordsCreateInput>>>;
};


export type MutationUpdateContactHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ContactHistoryRecordUpdateInput>;
};


export type MutationUpdateContactHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ContactHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteContactHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateContactArgs = {
  data?: Maybe<ContactCreateInput>;
};


export type MutationCreateContactsArgs = {
  data?: Maybe<Array<Maybe<ContactsCreateInput>>>;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID'];
  data?: Maybe<ContactUpdateInput>;
};


export type MutationUpdateContactsArgs = {
  data?: Maybe<Array<Maybe<ContactsUpdateInput>>>;
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateContactRoleHistoryRecordArgs = {
  data?: Maybe<ContactRoleHistoryRecordCreateInput>;
};


export type MutationCreateContactRoleHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ContactRoleHistoryRecordsCreateInput>>>;
};


export type MutationUpdateContactRoleHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ContactRoleHistoryRecordUpdateInput>;
};


export type MutationUpdateContactRoleHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ContactRoleHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteContactRoleHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactRoleHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateContactRoleArgs = {
  data?: Maybe<ContactRoleCreateInput>;
};


export type MutationCreateContactRolesArgs = {
  data?: Maybe<Array<Maybe<ContactRolesCreateInput>>>;
};


export type MutationUpdateContactRoleArgs = {
  id: Scalars['ID'];
  data?: Maybe<ContactRoleUpdateInput>;
};


export type MutationUpdateContactRolesArgs = {
  data?: Maybe<Array<Maybe<ContactRolesUpdateInput>>>;
};


export type MutationDeleteContactRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactRolesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateContactExportTaskHistoryRecordArgs = {
  data?: Maybe<ContactExportTaskHistoryRecordCreateInput>;
};


export type MutationCreateContactExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ContactExportTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateContactExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ContactExportTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateContactExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ContactExportTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteContactExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactExportTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateContactExportTaskArgs = {
  data?: Maybe<ContactExportTaskCreateInput>;
};


export type MutationCreateContactExportTasksArgs = {
  data?: Maybe<Array<Maybe<ContactExportTasksCreateInput>>>;
};


export type MutationUpdateContactExportTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<ContactExportTaskUpdateInput>;
};


export type MutationUpdateContactExportTasksArgs = {
  data?: Maybe<Array<Maybe<ContactExportTasksUpdateInput>>>;
};


export type MutationDeleteContactExportTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContactExportTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateResidentHistoryRecordArgs = {
  data?: Maybe<ResidentHistoryRecordCreateInput>;
};


export type MutationCreateResidentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ResidentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateResidentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ResidentHistoryRecordUpdateInput>;
};


export type MutationUpdateResidentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ResidentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteResidentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResidentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateResidentArgs = {
  data?: Maybe<ResidentCreateInput>;
};


export type MutationCreateResidentsArgs = {
  data?: Maybe<Array<Maybe<ResidentsCreateInput>>>;
};


export type MutationUpdateResidentArgs = {
  id: Scalars['ID'];
  data?: Maybe<ResidentUpdateInput>;
};


export type MutationUpdateResidentsArgs = {
  data?: Maybe<Array<Maybe<ResidentsUpdateInput>>>;
};


export type MutationDeleteResidentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResidentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateServiceConsumerHistoryRecordArgs = {
  data?: Maybe<ServiceConsumerHistoryRecordCreateInput>;
};


export type MutationCreateServiceConsumerHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ServiceConsumerHistoryRecordsCreateInput>>>;
};


export type MutationUpdateServiceConsumerHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ServiceConsumerHistoryRecordUpdateInput>;
};


export type MutationUpdateServiceConsumerHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ServiceConsumerHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteServiceConsumerHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServiceConsumerHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateServiceConsumerArgs = {
  data?: Maybe<ServiceConsumerCreateInput>;
};


export type MutationCreateServiceConsumersArgs = {
  data?: Maybe<Array<Maybe<ServiceConsumersCreateInput>>>;
};


export type MutationUpdateServiceConsumerArgs = {
  id: Scalars['ID'];
  data?: Maybe<ServiceConsumerUpdateInput>;
};


export type MutationUpdateServiceConsumersArgs = {
  data?: Maybe<Array<Maybe<ServiceConsumersUpdateInput>>>;
};


export type MutationDeleteServiceConsumerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServiceConsumersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTourStepHistoryRecordArgs = {
  data?: Maybe<TourStepHistoryRecordCreateInput>;
};


export type MutationCreateTourStepHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TourStepHistoryRecordsCreateInput>>>;
};


export type MutationUpdateTourStepHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<TourStepHistoryRecordUpdateInput>;
};


export type MutationUpdateTourStepHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<TourStepHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteTourStepHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTourStepHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateTourStepArgs = {
  data?: Maybe<TourStepCreateInput>;
};


export type MutationCreateTourStepsArgs = {
  data?: Maybe<Array<Maybe<TourStepsCreateInput>>>;
};


export type MutationUpdateTourStepArgs = {
  id: Scalars['ID'];
  data?: Maybe<TourStepUpdateInput>;
};


export type MutationUpdateTourStepsArgs = {
  data?: Maybe<Array<Maybe<TourStepsUpdateInput>>>;
};


export type MutationDeleteTourStepArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTourStepsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserHelpRequestHistoryRecordArgs = {
  data?: Maybe<UserHelpRequestHistoryRecordCreateInput>;
};


export type MutationCreateUserHelpRequestHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserHelpRequestHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestHistoryRecordUpdateInput>;
};


export type MutationUpdateUserHelpRequestHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserHelpRequestHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserHelpRequestHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserHelpRequestArgs = {
  data?: Maybe<UserHelpRequestCreateInput>;
};


export type MutationCreateUserHelpRequestsArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestsCreateInput>>>;
};


export type MutationUpdateUserHelpRequestArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestUpdateInput>;
};


export type MutationUpdateUserHelpRequestsArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestsUpdateInput>>>;
};


export type MutationDeleteUserHelpRequestArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserHelpRequestsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserHelpRequestFileHistoryRecordArgs = {
  data?: Maybe<UserHelpRequestFileHistoryRecordCreateInput>;
};


export type MutationCreateUserHelpRequestFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecordsCreateInput>>>;
};


export type MutationUpdateUserHelpRequestFileHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestFileHistoryRecordUpdateInput>;
};


export type MutationUpdateUserHelpRequestFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteUserHelpRequestFileHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserHelpRequestFileHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateUserHelpRequestFileArgs = {
  data?: Maybe<UserHelpRequestFileCreateInput>;
};


export type MutationCreateUserHelpRequestFilesArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestFilesCreateInput>>>;
};


export type MutationUpdateUserHelpRequestFileArgs = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestFileUpdateInput>;
};


export type MutationUpdateUserHelpRequestFilesArgs = {
  data?: Maybe<Array<Maybe<UserHelpRequestFilesUpdateInput>>>;
};


export type MutationDeleteUserHelpRequestFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserHelpRequestFilesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterResourceHistoryRecordArgs = {
  data?: Maybe<MeterResourceHistoryRecordCreateInput>;
};


export type MutationCreateMeterResourceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterResourceHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterResourceHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterResourceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterResourceHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterResourceHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterResourceHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterResourceArgs = {
  data?: Maybe<MeterResourceCreateInput>;
};


export type MutationCreateMeterResourcesArgs = {
  data?: Maybe<Array<Maybe<MeterResourcesCreateInput>>>;
};


export type MutationUpdateMeterResourceArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceUpdateInput>;
};


export type MutationUpdateMeterResourcesArgs = {
  data?: Maybe<Array<Maybe<MeterResourcesUpdateInput>>>;
};


export type MutationDeleteMeterResourceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterResourcesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingSourceHistoryRecordArgs = {
  data?: Maybe<MeterReadingSourceHistoryRecordCreateInput>;
};


export type MutationCreateMeterReadingSourceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterReadingSourceHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingSourceHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterReadingSourceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterReadingSourceHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingSourceHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingSourceArgs = {
  data?: Maybe<MeterReadingSourceCreateInput>;
};


export type MutationCreateMeterReadingSourcesArgs = {
  data?: Maybe<Array<Maybe<MeterReadingSourcesCreateInput>>>;
};


export type MutationUpdateMeterReadingSourceArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingSourceUpdateInput>;
};


export type MutationUpdateMeterReadingSourcesArgs = {
  data?: Maybe<Array<Maybe<MeterReadingSourcesUpdateInput>>>;
};


export type MutationDeleteMeterReadingSourceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingSourcesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingHistoryRecordArgs = {
  data?: Maybe<MeterReadingHistoryRecordCreateInput>;
};


export type MutationCreateMeterReadingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterReadingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterReadingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterReadingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingArgs = {
  data?: Maybe<MeterReadingCreateInput>;
};


export type MutationCreateMeterReadingsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingsCreateInput>>>;
};


export type MutationUpdateMeterReadingArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingUpdateInput>;
};


export type MutationUpdateMeterReadingsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingsUpdateInput>>>;
};


export type MutationDeleteMeterReadingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterHistoryRecordArgs = {
  data?: Maybe<MeterHistoryRecordCreateInput>;
};


export type MutationCreateMeterHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterArgs = {
  data?: Maybe<MeterCreateInput>;
};


export type MutationCreateMetersArgs = {
  data?: Maybe<Array<Maybe<MetersCreateInput>>>;
};


export type MutationUpdateMeterArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterUpdateInput>;
};


export type MutationUpdateMetersArgs = {
  data?: Maybe<Array<Maybe<MetersUpdateInput>>>;
};


export type MutationDeleteMeterArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMetersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingFilterTemplateHistoryRecordArgs = {
  data?: Maybe<MeterReadingFilterTemplateHistoryRecordCreateInput>;
};


export type MutationCreateMeterReadingFilterTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterReadingFilterTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingFilterTemplateHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterReadingFilterTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterReadingFilterTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingFilterTemplateHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingFilterTemplateArgs = {
  data?: Maybe<MeterReadingFilterTemplateCreateInput>;
};


export type MutationCreateMeterReadingFilterTemplatesArgs = {
  data?: Maybe<Array<Maybe<MeterReadingFilterTemplatesCreateInput>>>;
};


export type MutationUpdateMeterReadingFilterTemplateArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingFilterTemplateUpdateInput>;
};


export type MutationUpdateMeterReadingFilterTemplatesArgs = {
  data?: Maybe<Array<Maybe<MeterReadingFilterTemplatesUpdateInput>>>;
};


export type MutationDeleteMeterReadingFilterTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingFilterTemplatesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyMeterHistoryRecordArgs = {
  data?: Maybe<PropertyMeterHistoryRecordCreateInput>;
};


export type MutationCreatePropertyMeterHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyMeterHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePropertyMeterHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterHistoryRecordUpdateInput>;
};


export type MutationUpdatePropertyMeterHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyMeterHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePropertyMeterHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyMeterHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyMeterArgs = {
  data?: Maybe<PropertyMeterCreateInput>;
};


export type MutationCreatePropertyMetersArgs = {
  data?: Maybe<Array<Maybe<PropertyMetersCreateInput>>>;
};


export type MutationUpdatePropertyMeterArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterUpdateInput>;
};


export type MutationUpdatePropertyMetersArgs = {
  data?: Maybe<Array<Maybe<PropertyMetersUpdateInput>>>;
};


export type MutationDeletePropertyMeterArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyMetersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyMeterReadingHistoryRecordArgs = {
  data?: Maybe<PropertyMeterReadingHistoryRecordCreateInput>;
};


export type MutationCreatePropertyMeterReadingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePropertyMeterReadingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterReadingHistoryRecordUpdateInput>;
};


export type MutationUpdatePropertyMeterReadingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePropertyMeterReadingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyMeterReadingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyMeterReadingArgs = {
  data?: Maybe<PropertyMeterReadingCreateInput>;
};


export type MutationCreatePropertyMeterReadingsArgs = {
  data?: Maybe<Array<Maybe<PropertyMeterReadingsCreateInput>>>;
};


export type MutationUpdatePropertyMeterReadingArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterReadingUpdateInput>;
};


export type MutationUpdatePropertyMeterReadingsArgs = {
  data?: Maybe<Array<Maybe<PropertyMeterReadingsUpdateInput>>>;
};


export type MutationDeletePropertyMeterReadingArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyMeterReadingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReportingPeriodHistoryRecordArgs = {
  data?: Maybe<MeterReportingPeriodHistoryRecordCreateInput>;
};


export type MutationCreateMeterReportingPeriodHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterReportingPeriodHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReportingPeriodHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterReportingPeriodHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterReportingPeriodHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReportingPeriodHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReportingPeriodArgs = {
  data?: Maybe<MeterReportingPeriodCreateInput>;
};


export type MutationCreateMeterReportingPeriodsArgs = {
  data?: Maybe<Array<Maybe<MeterReportingPeriodsCreateInput>>>;
};


export type MutationUpdateMeterReportingPeriodArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReportingPeriodUpdateInput>;
};


export type MutationUpdateMeterReportingPeriodsArgs = {
  data?: Maybe<Array<Maybe<MeterReportingPeriodsUpdateInput>>>;
};


export type MutationDeleteMeterReportingPeriodArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReportingPeriodsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterResourceOwnerHistoryRecordArgs = {
  data?: Maybe<MeterResourceOwnerHistoryRecordCreateInput>;
};


export type MutationCreateMeterResourceOwnerHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterResourceOwnerHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceOwnerHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterResourceOwnerHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterResourceOwnerHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterResourceOwnerHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterResourceOwnerArgs = {
  data?: Maybe<MeterResourceOwnerCreateInput>;
};


export type MutationCreateMeterResourceOwnersArgs = {
  data?: Maybe<Array<Maybe<MeterResourceOwnersCreateInput>>>;
};


export type MutationUpdateMeterResourceOwnerArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterResourceOwnerUpdateInput>;
};


export type MutationUpdateMeterResourceOwnersArgs = {
  data?: Maybe<Array<Maybe<MeterResourceOwnersUpdateInput>>>;
};


export type MutationDeleteMeterResourceOwnerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterResourceOwnersArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingsImportTaskHistoryRecordArgs = {
  data?: Maybe<MeterReadingsImportTaskHistoryRecordCreateInput>;
};


export type MutationCreateMeterReadingsImportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMeterReadingsImportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingsImportTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateMeterReadingsImportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMeterReadingsImportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingsImportTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMeterReadingsImportTaskArgs = {
  data?: Maybe<MeterReadingsImportTaskCreateInput>;
};


export type MutationCreateMeterReadingsImportTasksArgs = {
  data?: Maybe<Array<Maybe<MeterReadingsImportTasksCreateInput>>>;
};


export type MutationUpdateMeterReadingsImportTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<MeterReadingsImportTaskUpdateInput>;
};


export type MutationUpdateMeterReadingsImportTasksArgs = {
  data?: Maybe<Array<Maybe<MeterReadingsImportTasksUpdateInput>>>;
};


export type MutationDeleteMeterReadingsImportTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeterReadingsImportTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateServiceSubscriptionHistoryRecordArgs = {
  data?: Maybe<ServiceSubscriptionHistoryRecordCreateInput>;
};


export type MutationCreateServiceSubscriptionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecordsCreateInput>>>;
};


export type MutationUpdateServiceSubscriptionHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ServiceSubscriptionHistoryRecordUpdateInput>;
};


export type MutationUpdateServiceSubscriptionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteServiceSubscriptionHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServiceSubscriptionHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateServiceSubscriptionArgs = {
  data?: Maybe<ServiceSubscriptionCreateInput>;
};


export type MutationCreateServiceSubscriptionsArgs = {
  data?: Maybe<Array<Maybe<ServiceSubscriptionsCreateInput>>>;
};


export type MutationUpdateServiceSubscriptionArgs = {
  id: Scalars['ID'];
  data?: Maybe<ServiceSubscriptionUpdateInput>;
};


export type MutationUpdateServiceSubscriptionsArgs = {
  data?: Maybe<Array<Maybe<ServiceSubscriptionsUpdateInput>>>;
};


export type MutationDeleteServiceSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteServiceSubscriptionsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateAcquiringIntegrationHistoryRecordArgs = {
  data?: Maybe<AcquiringIntegrationHistoryRecordCreateInput>;
};


export type MutationCreateAcquiringIntegrationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecordsCreateInput>>>;
};


export type MutationUpdateAcquiringIntegrationHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationHistoryRecordUpdateInput>;
};


export type MutationUpdateAcquiringIntegrationHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteAcquiringIntegrationHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAcquiringIntegrationHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateAcquiringIntegrationArgs = {
  data?: Maybe<AcquiringIntegrationCreateInput>;
};


export type MutationCreateAcquiringIntegrationsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationsCreateInput>>>;
};


export type MutationUpdateAcquiringIntegrationArgs = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationUpdateInput>;
};


export type MutationUpdateAcquiringIntegrationsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationsUpdateInput>>>;
};


export type MutationDeleteAcquiringIntegrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAcquiringIntegrationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateAcquiringIntegrationAccessRightHistoryRecordArgs = {
  data?: Maybe<AcquiringIntegrationAccessRightHistoryRecordCreateInput>;
};


export type MutationCreateAcquiringIntegrationAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecordsCreateInput>>>;
};


export type MutationUpdateAcquiringIntegrationAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationAccessRightHistoryRecordUpdateInput>;
};


export type MutationUpdateAcquiringIntegrationAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteAcquiringIntegrationAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAcquiringIntegrationAccessRightHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateAcquiringIntegrationAccessRightArgs = {
  data?: Maybe<AcquiringIntegrationAccessRightCreateInput>;
};


export type MutationCreateAcquiringIntegrationAccessRightsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightsCreateInput>>>;
};


export type MutationUpdateAcquiringIntegrationAccessRightArgs = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationAccessRightUpdateInput>;
};


export type MutationUpdateAcquiringIntegrationAccessRightsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightsUpdateInput>>>;
};


export type MutationDeleteAcquiringIntegrationAccessRightArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAcquiringIntegrationAccessRightsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateAcquiringIntegrationContextHistoryRecordArgs = {
  data?: Maybe<AcquiringIntegrationContextHistoryRecordCreateInput>;
};


export type MutationCreateAcquiringIntegrationContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecordsCreateInput>>>;
};


export type MutationUpdateAcquiringIntegrationContextHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationContextHistoryRecordUpdateInput>;
};


export type MutationUpdateAcquiringIntegrationContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteAcquiringIntegrationContextHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAcquiringIntegrationContextHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateAcquiringIntegrationContextArgs = {
  data?: Maybe<AcquiringIntegrationContextCreateInput>;
};


export type MutationCreateAcquiringIntegrationContextsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationContextsCreateInput>>>;
};


export type MutationUpdateAcquiringIntegrationContextArgs = {
  id: Scalars['ID'];
  data?: Maybe<AcquiringIntegrationContextUpdateInput>;
};


export type MutationUpdateAcquiringIntegrationContextsArgs = {
  data?: Maybe<Array<Maybe<AcquiringIntegrationContextsUpdateInput>>>;
};


export type MutationDeleteAcquiringIntegrationContextArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAcquiringIntegrationContextsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMultiPaymentHistoryRecordArgs = {
  data?: Maybe<MultiPaymentHistoryRecordCreateInput>;
};


export type MutationCreateMultiPaymentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MultiPaymentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMultiPaymentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MultiPaymentHistoryRecordUpdateInput>;
};


export type MutationUpdateMultiPaymentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MultiPaymentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMultiPaymentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMultiPaymentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMultiPaymentArgs = {
  data?: Maybe<MultiPaymentCreateInput>;
};


export type MutationCreateMultiPaymentsArgs = {
  data?: Maybe<Array<Maybe<MultiPaymentsCreateInput>>>;
};


export type MutationUpdateMultiPaymentArgs = {
  id: Scalars['ID'];
  data?: Maybe<MultiPaymentUpdateInput>;
};


export type MutationUpdateMultiPaymentsArgs = {
  data?: Maybe<Array<Maybe<MultiPaymentsUpdateInput>>>;
};


export type MutationDeleteMultiPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMultiPaymentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePaymentHistoryRecordArgs = {
  data?: Maybe<PaymentHistoryRecordCreateInput>;
};


export type MutationCreatePaymentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PaymentHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePaymentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PaymentHistoryRecordUpdateInput>;
};


export type MutationUpdatePaymentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PaymentHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePaymentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePaymentArgs = {
  data?: Maybe<PaymentCreateInput>;
};


export type MutationCreatePaymentsArgs = {
  data?: Maybe<Array<Maybe<PaymentsCreateInput>>>;
};


export type MutationUpdatePaymentArgs = {
  id: Scalars['ID'];
  data?: Maybe<PaymentUpdateInput>;
};


export type MutationUpdatePaymentsArgs = {
  data?: Maybe<Array<Maybe<PaymentsUpdateInput>>>;
};


export type MutationDeletePaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePaymentsFilterTemplateHistoryRecordArgs = {
  data?: Maybe<PaymentsFilterTemplateHistoryRecordCreateInput>;
};


export type MutationCreatePaymentsFilterTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePaymentsFilterTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PaymentsFilterTemplateHistoryRecordUpdateInput>;
};


export type MutationUpdatePaymentsFilterTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePaymentsFilterTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentsFilterTemplateHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePaymentsFilterTemplateArgs = {
  data?: Maybe<PaymentsFilterTemplateCreateInput>;
};


export type MutationCreatePaymentsFilterTemplatesArgs = {
  data?: Maybe<Array<Maybe<PaymentsFilterTemplatesCreateInput>>>;
};


export type MutationUpdatePaymentsFilterTemplateArgs = {
  id: Scalars['ID'];
  data?: Maybe<PaymentsFilterTemplateUpdateInput>;
};


export type MutationUpdatePaymentsFilterTemplatesArgs = {
  data?: Maybe<Array<Maybe<PaymentsFilterTemplatesUpdateInput>>>;
};


export type MutationDeletePaymentsFilterTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentsFilterTemplatesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateRecurrentPaymentContextHistoryRecordArgs = {
  data?: Maybe<RecurrentPaymentContextHistoryRecordCreateInput>;
};


export type MutationCreateRecurrentPaymentContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecordsCreateInput>>>;
};


export type MutationUpdateRecurrentPaymentContextHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentContextHistoryRecordUpdateInput>;
};


export type MutationUpdateRecurrentPaymentContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteRecurrentPaymentContextHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRecurrentPaymentContextHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateRecurrentPaymentContextArgs = {
  data?: Maybe<RecurrentPaymentContextCreateInput>;
};


export type MutationCreateRecurrentPaymentContextsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentContextsCreateInput>>>;
};


export type MutationUpdateRecurrentPaymentContextArgs = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentContextUpdateInput>;
};


export type MutationUpdateRecurrentPaymentContextsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentContextsUpdateInput>>>;
};


export type MutationDeleteRecurrentPaymentContextArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRecurrentPaymentContextsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateRecurrentPaymentHistoryRecordArgs = {
  data?: Maybe<RecurrentPaymentHistoryRecordCreateInput>;
};


export type MutationCreateRecurrentPaymentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateRecurrentPaymentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentHistoryRecordUpdateInput>;
};


export type MutationUpdateRecurrentPaymentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteRecurrentPaymentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRecurrentPaymentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateRecurrentPaymentArgs = {
  data?: Maybe<RecurrentPaymentCreateInput>;
};


export type MutationCreateRecurrentPaymentsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentsCreateInput>>>;
};


export type MutationUpdateRecurrentPaymentArgs = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentUpdateInput>;
};


export type MutationUpdateRecurrentPaymentsArgs = {
  data?: Maybe<Array<Maybe<RecurrentPaymentsUpdateInput>>>;
};


export type MutationDeleteRecurrentPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRecurrentPaymentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateExternalReportHistoryRecordArgs = {
  data?: Maybe<ExternalReportHistoryRecordCreateInput>;
};


export type MutationCreateExternalReportHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ExternalReportHistoryRecordsCreateInput>>>;
};


export type MutationUpdateExternalReportHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<ExternalReportHistoryRecordUpdateInput>;
};


export type MutationUpdateExternalReportHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<ExternalReportHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteExternalReportHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExternalReportHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateExternalReportArgs = {
  data?: Maybe<ExternalReportCreateInput>;
};


export type MutationCreateExternalReportsArgs = {
  data?: Maybe<Array<Maybe<ExternalReportsCreateInput>>>;
};


export type MutationUpdateExternalReportArgs = {
  id: Scalars['ID'];
  data?: Maybe<ExternalReportUpdateInput>;
};


export type MutationUpdateExternalReportsArgs = {
  data?: Maybe<Array<Maybe<ExternalReportsUpdateInput>>>;
};


export type MutationDeleteExternalReportArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExternalReportsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyScopeHistoryRecordArgs = {
  data?: Maybe<PropertyScopeHistoryRecordCreateInput>;
};


export type MutationCreatePropertyScopeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyScopeHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePropertyScopeHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeHistoryRecordUpdateInput>;
};


export type MutationUpdatePropertyScopeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyScopeHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePropertyScopeHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyScopeHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyScopeArgs = {
  data?: Maybe<PropertyScopeCreateInput>;
};


export type MutationCreatePropertyScopesArgs = {
  data?: Maybe<Array<Maybe<PropertyScopesCreateInput>>>;
};


export type MutationUpdatePropertyScopeArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeUpdateInput>;
};


export type MutationUpdatePropertyScopesArgs = {
  data?: Maybe<Array<Maybe<PropertyScopesUpdateInput>>>;
};


export type MutationDeletePropertyScopeArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyScopesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyScopeOrganizationEmployeeHistoryRecordArgs = {
  data?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordCreateInput>;
};


export type MutationCreatePropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePropertyScopeOrganizationEmployeeHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordUpdateInput>;
};


export type MutationUpdatePropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePropertyScopeOrganizationEmployeeHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyScopeOrganizationEmployeeArgs = {
  data?: Maybe<PropertyScopeOrganizationEmployeeCreateInput>;
};


export type MutationCreatePropertyScopeOrganizationEmployeesArgs = {
  data?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeesCreateInput>>>;
};


export type MutationUpdatePropertyScopeOrganizationEmployeeArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeOrganizationEmployeeUpdateInput>;
};


export type MutationUpdatePropertyScopeOrganizationEmployeesArgs = {
  data?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeesUpdateInput>>>;
};


export type MutationDeletePropertyScopeOrganizationEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyScopeOrganizationEmployeesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyScopePropertyHistoryRecordArgs = {
  data?: Maybe<PropertyScopePropertyHistoryRecordCreateInput>;
};


export type MutationCreatePropertyScopePropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecordsCreateInput>>>;
};


export type MutationUpdatePropertyScopePropertyHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopePropertyHistoryRecordUpdateInput>;
};


export type MutationUpdatePropertyScopePropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecordsUpdateInput>>>;
};


export type MutationDeletePropertyScopePropertyHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyScopePropertyHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreatePropertyScopePropertyArgs = {
  data?: Maybe<PropertyScopePropertyCreateInput>;
};


export type MutationCreatePropertyScopePropertiesArgs = {
  data?: Maybe<Array<Maybe<PropertyScopePropertiesCreateInput>>>;
};


export type MutationUpdatePropertyScopePropertyArgs = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopePropertyUpdateInput>;
};


export type MutationUpdatePropertyScopePropertiesArgs = {
  data?: Maybe<Array<Maybe<PropertyScopePropertiesUpdateInput>>>;
};


export type MutationDeletePropertyScopePropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePropertyScopePropertiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemHistoryRecordArgs = {
  data?: Maybe<NewsItemHistoryRecordCreateInput>;
};


export type MutationCreateNewsItemHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNewsItemHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemHistoryRecordUpdateInput>;
};


export type MutationUpdateNewsItemHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNewsItemHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemArgs = {
  data?: Maybe<NewsItemCreateInput>;
};


export type MutationCreateNewsItemsArgs = {
  data?: Maybe<Array<Maybe<NewsItemsCreateInput>>>;
};


export type MutationUpdateNewsItemArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemUpdateInput>;
};


export type MutationUpdateNewsItemsArgs = {
  data?: Maybe<Array<Maybe<NewsItemsUpdateInput>>>;
};


export type MutationDeleteNewsItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemScopeHistoryRecordArgs = {
  data?: Maybe<NewsItemScopeHistoryRecordCreateInput>;
};


export type MutationCreateNewsItemScopeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemScopeHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNewsItemScopeHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemScopeHistoryRecordUpdateInput>;
};


export type MutationUpdateNewsItemScopeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemScopeHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNewsItemScopeHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemScopeHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemScopeArgs = {
  data?: Maybe<NewsItemScopeCreateInput>;
};


export type MutationCreateNewsItemScopesArgs = {
  data?: Maybe<Array<Maybe<NewsItemScopesCreateInput>>>;
};


export type MutationUpdateNewsItemScopeArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemScopeUpdateInput>;
};


export type MutationUpdateNewsItemScopesArgs = {
  data?: Maybe<Array<Maybe<NewsItemScopesUpdateInput>>>;
};


export type MutationDeleteNewsItemScopeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemScopesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemTemplateHistoryRecordArgs = {
  data?: Maybe<NewsItemTemplateHistoryRecordCreateInput>;
};


export type MutationCreateNewsItemTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNewsItemTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemTemplateHistoryRecordUpdateInput>;
};


export type MutationUpdateNewsItemTemplateHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNewsItemTemplateHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemTemplateHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemTemplateArgs = {
  data?: Maybe<NewsItemTemplateCreateInput>;
};


export type MutationCreateNewsItemTemplatesArgs = {
  data?: Maybe<Array<Maybe<NewsItemTemplatesCreateInput>>>;
};


export type MutationUpdateNewsItemTemplateArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemTemplateUpdateInput>;
};


export type MutationUpdateNewsItemTemplatesArgs = {
  data?: Maybe<Array<Maybe<NewsItemTemplatesUpdateInput>>>;
};


export type MutationDeleteNewsItemTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemTemplatesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemUserReadHistoryRecordArgs = {
  data?: Maybe<NewsItemUserReadHistoryRecordCreateInput>;
};


export type MutationCreateNewsItemUserReadHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNewsItemUserReadHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemUserReadHistoryRecordUpdateInput>;
};


export type MutationUpdateNewsItemUserReadHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNewsItemUserReadHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemUserReadHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemUserReadArgs = {
  data?: Maybe<NewsItemUserReadCreateInput>;
};


export type MutationCreateNewsItemUserReadsArgs = {
  data?: Maybe<Array<Maybe<NewsItemUserReadsCreateInput>>>;
};


export type MutationUpdateNewsItemUserReadArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemUserReadUpdateInput>;
};


export type MutationUpdateNewsItemUserReadsArgs = {
  data?: Maybe<Array<Maybe<NewsItemUserReadsUpdateInput>>>;
};


export type MutationDeleteNewsItemUserReadArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemUserReadsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemRecipientsExportTaskHistoryRecordArgs = {
  data?: Maybe<NewsItemRecipientsExportTaskHistoryRecordCreateInput>;
};


export type MutationCreateNewsItemRecipientsExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNewsItemRecipientsExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemRecipientsExportTaskHistoryRecordUpdateInput>;
};


export type MutationUpdateNewsItemRecipientsExportTaskHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNewsItemRecipientsExportTaskHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemRecipientsExportTaskHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemRecipientsExportTaskArgs = {
  data?: Maybe<NewsItemRecipientsExportTaskCreateInput>;
};


export type MutationCreateNewsItemRecipientsExportTasksArgs = {
  data?: Maybe<Array<Maybe<NewsItemRecipientsExportTasksCreateInput>>>;
};


export type MutationUpdateNewsItemRecipientsExportTaskArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemRecipientsExportTaskUpdateInput>;
};


export type MutationUpdateNewsItemRecipientsExportTasksArgs = {
  data?: Maybe<Array<Maybe<NewsItemRecipientsExportTasksUpdateInput>>>;
};


export type MutationDeleteNewsItemRecipientsExportTaskArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemRecipientsExportTasksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemSharingHistoryRecordArgs = {
  data?: Maybe<NewsItemSharingHistoryRecordCreateInput>;
};


export type MutationCreateNewsItemSharingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemSharingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateNewsItemSharingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemSharingHistoryRecordUpdateInput>;
};


export type MutationUpdateNewsItemSharingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<NewsItemSharingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteNewsItemSharingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemSharingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateNewsItemSharingArgs = {
  data?: Maybe<NewsItemSharingCreateInput>;
};


export type MutationCreateNewsItemSharingsArgs = {
  data?: Maybe<Array<Maybe<NewsItemSharingsCreateInput>>>;
};


export type MutationUpdateNewsItemSharingArgs = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemSharingUpdateInput>;
};


export type MutationUpdateNewsItemSharingsArgs = {
  data?: Maybe<Array<Maybe<NewsItemSharingsUpdateInput>>>;
};


export type MutationDeleteNewsItemSharingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsItemSharingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppHistoryRecordArgs = {
  data?: Maybe<B2BAppHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppArgs = {
  data?: Maybe<B2BAppCreateInput>;
};


export type MutationCreateB2BAppsArgs = {
  data?: Maybe<Array<Maybe<B2BAppsCreateInput>>>;
};


export type MutationUpdateB2BAppArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppUpdateInput>;
};


export type MutationUpdateB2BAppsArgs = {
  data?: Maybe<Array<Maybe<B2BAppsUpdateInput>>>;
};


export type MutationDeleteB2BAppArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppContextHistoryRecordArgs = {
  data?: Maybe<B2BAppContextHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppContextHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppContextHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppContextHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppContextHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppContextHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppContextHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppContextHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppContextArgs = {
  data?: Maybe<B2BAppContextCreateInput>;
};


export type MutationCreateB2BAppContextsArgs = {
  data?: Maybe<Array<Maybe<B2BAppContextsCreateInput>>>;
};


export type MutationUpdateB2BAppContextArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppContextUpdateInput>;
};


export type MutationUpdateB2BAppContextsArgs = {
  data?: Maybe<Array<Maybe<B2BAppContextsUpdateInput>>>;
};


export type MutationDeleteB2BAppContextArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppContextsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppAccessRightHistoryRecordArgs = {
  data?: Maybe<B2BAppAccessRightHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppAccessRightHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppAccessRightArgs = {
  data?: Maybe<B2BAppAccessRightCreateInput>;
};


export type MutationCreateB2BAppAccessRightsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightsCreateInput>>>;
};


export type MutationUpdateB2BAppAccessRightArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightUpdateInput>;
};


export type MutationUpdateB2BAppAccessRightsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightsUpdateInput>>>;
};


export type MutationDeleteB2BAppAccessRightArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppAccessRightsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppHistoryRecordArgs = {
  data?: Maybe<B2CAppHistoryRecordCreateInput>;
};


export type MutationCreateB2CAppHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2CAppHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppHistoryRecordUpdateInput>;
};


export type MutationUpdateB2CAppHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2CAppHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppArgs = {
  data?: Maybe<B2CAppCreateInput>;
};


export type MutationCreateB2CAppsArgs = {
  data?: Maybe<Array<Maybe<B2CAppsCreateInput>>>;
};


export type MutationUpdateB2CAppArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppUpdateInput>;
};


export type MutationUpdateB2CAppsArgs = {
  data?: Maybe<Array<Maybe<B2CAppsUpdateInput>>>;
};


export type MutationDeleteB2CAppArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppAccessRightHistoryRecordArgs = {
  data?: Maybe<B2CAppAccessRightHistoryRecordCreateInput>;
};


export type MutationCreateB2CAppAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2CAppAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppAccessRightHistoryRecordUpdateInput>;
};


export type MutationUpdateB2CAppAccessRightHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2CAppAccessRightHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppAccessRightHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppAccessRightArgs = {
  data?: Maybe<B2CAppAccessRightCreateInput>;
};


export type MutationCreateB2CAppAccessRightsArgs = {
  data?: Maybe<Array<Maybe<B2CAppAccessRightsCreateInput>>>;
};


export type MutationUpdateB2CAppAccessRightArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppAccessRightUpdateInput>;
};


export type MutationUpdateB2CAppAccessRightsArgs = {
  data?: Maybe<Array<Maybe<B2CAppAccessRightsUpdateInput>>>;
};


export type MutationDeleteB2CAppAccessRightArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppAccessRightsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppBuildHistoryRecordArgs = {
  data?: Maybe<B2CAppBuildHistoryRecordCreateInput>;
};


export type MutationCreateB2CAppBuildHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppBuildHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2CAppBuildHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppBuildHistoryRecordUpdateInput>;
};


export type MutationUpdateB2CAppBuildHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppBuildHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2CAppBuildHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppBuildHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppBuildArgs = {
  data?: Maybe<B2CAppBuildCreateInput>;
};


export type MutationCreateB2CAppBuildsArgs = {
  data?: Maybe<Array<Maybe<B2CAppBuildsCreateInput>>>;
};


export type MutationUpdateB2CAppBuildArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppBuildUpdateInput>;
};


export type MutationUpdateB2CAppBuildsArgs = {
  data?: Maybe<Array<Maybe<B2CAppBuildsUpdateInput>>>;
};


export type MutationDeleteB2CAppBuildArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppBuildsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppPropertyHistoryRecordArgs = {
  data?: Maybe<B2CAppPropertyHistoryRecordCreateInput>;
};


export type MutationCreateB2CAppPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2CAppPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppPropertyHistoryRecordUpdateInput>;
};


export type MutationUpdateB2CAppPropertyHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2CAppPropertyHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppPropertyHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppPropertyArgs = {
  data?: Maybe<B2CAppPropertyCreateInput>;
};


export type MutationCreateB2CAppPropertiesArgs = {
  data?: Maybe<Array<Maybe<B2CAppPropertiesCreateInput>>>;
};


export type MutationUpdateB2CAppPropertyArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppPropertyUpdateInput>;
};


export type MutationUpdateB2CAppPropertiesArgs = {
  data?: Maybe<Array<Maybe<B2CAppPropertiesUpdateInput>>>;
};


export type MutationDeleteB2CAppPropertyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppPropertiesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppPromoBlockHistoryRecordArgs = {
  data?: Maybe<B2BAppPromoBlockHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppPromoBlockHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppPromoBlockHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPromoBlockHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppPromoBlockHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppPromoBlockHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppPromoBlockHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppPromoBlockArgs = {
  data?: Maybe<B2BAppPromoBlockCreateInput>;
};


export type MutationCreateB2BAppPromoBlocksArgs = {
  data?: Maybe<Array<Maybe<B2BAppPromoBlocksCreateInput>>>;
};


export type MutationUpdateB2BAppPromoBlockArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPromoBlockUpdateInput>;
};


export type MutationUpdateB2BAppPromoBlocksArgs = {
  data?: Maybe<Array<Maybe<B2BAppPromoBlocksUpdateInput>>>;
};


export type MutationDeleteB2BAppPromoBlockArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppPromoBlocksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageAppBlackListHistoryRecordArgs = {
  data?: Maybe<MessageAppBlackListHistoryRecordCreateInput>;
};


export type MutationCreateMessageAppBlackListHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMessageAppBlackListHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageAppBlackListHistoryRecordUpdateInput>;
};


export type MutationUpdateMessageAppBlackListHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMessageAppBlackListHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageAppBlackListHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMessageAppBlackListArgs = {
  data?: Maybe<MessageAppBlackListCreateInput>;
};


export type MutationCreateMessageAppBlackListsArgs = {
  data?: Maybe<Array<Maybe<MessageAppBlackListsCreateInput>>>;
};


export type MutationUpdateMessageAppBlackListArgs = {
  id: Scalars['ID'];
  data?: Maybe<MessageAppBlackListUpdateInput>;
};


export type MutationUpdateMessageAppBlackListsArgs = {
  data?: Maybe<Array<Maybe<MessageAppBlackListsUpdateInput>>>;
};


export type MutationDeleteMessageAppBlackListArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMessageAppBlackListsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppPermissionHistoryRecordArgs = {
  data?: Maybe<B2BAppPermissionHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppPermissionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppPermissionHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPermissionHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppPermissionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppPermissionHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppPermissionHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppPermissionArgs = {
  data?: Maybe<B2BAppPermissionCreateInput>;
};


export type MutationCreateB2BAppPermissionsArgs = {
  data?: Maybe<Array<Maybe<B2BAppPermissionsCreateInput>>>;
};


export type MutationUpdateB2BAppPermissionArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppPermissionUpdateInput>;
};


export type MutationUpdateB2BAppPermissionsArgs = {
  data?: Maybe<Array<Maybe<B2BAppPermissionsUpdateInput>>>;
};


export type MutationDeleteB2BAppPermissionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppPermissionsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppRoleHistoryRecordArgs = {
  data?: Maybe<B2BAppRoleHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppRoleHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppRoleHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppRoleHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppRoleHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppRoleHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppRoleHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppRoleHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppRoleHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppRoleArgs = {
  data?: Maybe<B2BAppRoleCreateInput>;
};


export type MutationCreateB2BAppRolesArgs = {
  data?: Maybe<Array<Maybe<B2BAppRolesCreateInput>>>;
};


export type MutationUpdateB2BAppRoleArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppRoleUpdateInput>;
};


export type MutationUpdateB2BAppRolesArgs = {
  data?: Maybe<Array<Maybe<B2BAppRolesUpdateInput>>>;
};


export type MutationDeleteB2BAppRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppRolesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppAccessRightSetHistoryRecordArgs = {
  data?: Maybe<B2BAppAccessRightSetHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppAccessRightSetHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppAccessRightSetHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightSetHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppAccessRightSetHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppAccessRightSetHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppAccessRightSetHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppAccessRightSetArgs = {
  data?: Maybe<B2BAppAccessRightSetCreateInput>;
};


export type MutationCreateB2BAppAccessRightSetsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightSetsCreateInput>>>;
};


export type MutationUpdateB2BAppAccessRightSetArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppAccessRightSetUpdateInput>;
};


export type MutationUpdateB2BAppAccessRightSetsArgs = {
  data?: Maybe<Array<Maybe<B2BAppAccessRightSetsUpdateInput>>>;
};


export type MutationDeleteB2BAppAccessRightSetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppAccessRightSetsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppNewsSharingConfigHistoryRecordArgs = {
  data?: Maybe<B2BAppNewsSharingConfigHistoryRecordCreateInput>;
};


export type MutationCreateB2BAppNewsSharingConfigHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2BAppNewsSharingConfigHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppNewsSharingConfigHistoryRecordUpdateInput>;
};


export type MutationUpdateB2BAppNewsSharingConfigHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2BAppNewsSharingConfigHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppNewsSharingConfigHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2BAppNewsSharingConfigArgs = {
  data?: Maybe<B2BAppNewsSharingConfigCreateInput>;
};


export type MutationCreateB2BAppNewsSharingConfigsArgs = {
  data?: Maybe<Array<Maybe<B2BAppNewsSharingConfigsCreateInput>>>;
};


export type MutationUpdateB2BAppNewsSharingConfigArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2BAppNewsSharingConfigUpdateInput>;
};


export type MutationUpdateB2BAppNewsSharingConfigsArgs = {
  data?: Maybe<Array<Maybe<B2BAppNewsSharingConfigsUpdateInput>>>;
};


export type MutationDeleteB2BAppNewsSharingConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2BAppNewsSharingConfigsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppMessageSettingHistoryRecordArgs = {
  data?: Maybe<B2CAppMessageSettingHistoryRecordCreateInput>;
};


export type MutationCreateB2CAppMessageSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateB2CAppMessageSettingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppMessageSettingHistoryRecordUpdateInput>;
};


export type MutationUpdateB2CAppMessageSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteB2CAppMessageSettingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppMessageSettingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateB2CAppMessageSettingArgs = {
  data?: Maybe<B2CAppMessageSettingCreateInput>;
};


export type MutationCreateB2CAppMessageSettingsArgs = {
  data?: Maybe<Array<Maybe<B2CAppMessageSettingsCreateInput>>>;
};


export type MutationUpdateB2CAppMessageSettingArgs = {
  id: Scalars['ID'];
  data?: Maybe<B2CAppMessageSettingUpdateInput>;
};


export type MutationUpdateB2CAppMessageSettingsArgs = {
  data?: Maybe<Array<Maybe<B2CAppMessageSettingsUpdateInput>>>;
};


export type MutationDeleteB2CAppMessageSettingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteB2CAppMessageSettingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMobileFeatureConfigHistoryRecordArgs = {
  data?: Maybe<MobileFeatureConfigHistoryRecordCreateInput>;
};


export type MutationCreateMobileFeatureConfigHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMobileFeatureConfigHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MobileFeatureConfigHistoryRecordUpdateInput>;
};


export type MutationUpdateMobileFeatureConfigHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMobileFeatureConfigHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMobileFeatureConfigHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMobileFeatureConfigArgs = {
  data?: Maybe<MobileFeatureConfigCreateInput>;
};


export type MutationCreateMobileFeatureConfigsArgs = {
  data?: Maybe<Array<Maybe<MobileFeatureConfigsCreateInput>>>;
};


export type MutationUpdateMobileFeatureConfigArgs = {
  id: Scalars['ID'];
  data?: Maybe<MobileFeatureConfigUpdateInput>;
};


export type MutationUpdateMobileFeatureConfigsArgs = {
  data?: Maybe<Array<Maybe<MobileFeatureConfigsUpdateInput>>>;
};


export type MutationDeleteMobileFeatureConfigArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMobileFeatureConfigsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketCategoryHistoryRecordArgs = {
  data?: Maybe<MarketCategoryHistoryRecordCreateInput>;
};


export type MutationCreateMarketCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketCategoryHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMarketCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketCategoryHistoryRecordUpdateInput>;
};


export type MutationUpdateMarketCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketCategoryHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMarketCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketCategoryHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketCategoryArgs = {
  data?: Maybe<MarketCategoryCreateInput>;
};


export type MutationCreateMarketCategoriesArgs = {
  data?: Maybe<Array<Maybe<MarketCategoriesCreateInput>>>;
};


export type MutationUpdateMarketCategoryArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketCategoryUpdateInput>;
};


export type MutationUpdateMarketCategoriesArgs = {
  data?: Maybe<Array<Maybe<MarketCategoriesUpdateInput>>>;
};


export type MutationDeleteMarketCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketCategoriesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketItemHistoryRecordArgs = {
  data?: Maybe<MarketItemHistoryRecordCreateInput>;
};


export type MutationCreateMarketItemHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketItemHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMarketItemHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemHistoryRecordUpdateInput>;
};


export type MutationUpdateMarketItemHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketItemHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMarketItemHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketItemHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketItemArgs = {
  data?: Maybe<MarketItemCreateInput>;
};


export type MutationCreateMarketItemsArgs = {
  data?: Maybe<Array<Maybe<MarketItemsCreateInput>>>;
};


export type MutationUpdateMarketItemArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemUpdateInput>;
};


export type MutationUpdateMarketItemsArgs = {
  data?: Maybe<Array<Maybe<MarketItemsUpdateInput>>>;
};


export type MutationDeleteMarketItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketItemsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateInvoiceHistoryRecordArgs = {
  data?: Maybe<InvoiceHistoryRecordCreateInput>;
};


export type MutationCreateInvoiceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<InvoiceHistoryRecordsCreateInput>>>;
};


export type MutationUpdateInvoiceHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<InvoiceHistoryRecordUpdateInput>;
};


export type MutationUpdateInvoiceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<InvoiceHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteInvoiceHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInvoiceHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateInvoiceArgs = {
  data?: Maybe<InvoiceCreateInput>;
};


export type MutationCreateInvoicesArgs = {
  data?: Maybe<Array<Maybe<InvoicesCreateInput>>>;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID'];
  data?: Maybe<InvoiceUpdateInput>;
};


export type MutationUpdateInvoicesArgs = {
  data?: Maybe<Array<Maybe<InvoicesUpdateInput>>>;
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInvoicesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketItemFileHistoryRecordArgs = {
  data?: Maybe<MarketItemFileHistoryRecordCreateInput>;
};


export type MutationCreateMarketItemFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketItemFileHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMarketItemFileHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemFileHistoryRecordUpdateInput>;
};


export type MutationUpdateMarketItemFileHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketItemFileHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMarketItemFileHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketItemFileHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketItemFileArgs = {
  data?: Maybe<MarketItemFileCreateInput>;
};


export type MutationCreateMarketItemFilesArgs = {
  data?: Maybe<Array<Maybe<MarketItemFilesCreateInput>>>;
};


export type MutationUpdateMarketItemFileArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemFileUpdateInput>;
};


export type MutationUpdateMarketItemFilesArgs = {
  data?: Maybe<Array<Maybe<MarketItemFilesUpdateInput>>>;
};


export type MutationDeleteMarketItemFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketItemFilesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketItemPriceHistoryRecordArgs = {
  data?: Maybe<MarketItemPriceHistoryRecordCreateInput>;
};


export type MutationCreateMarketItemPriceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketItemPriceHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMarketItemPriceHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemPriceHistoryRecordUpdateInput>;
};


export type MutationUpdateMarketItemPriceHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketItemPriceHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMarketItemPriceHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketItemPriceHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketItemPriceArgs = {
  data?: Maybe<MarketItemPriceCreateInput>;
};


export type MutationCreateMarketItemPricesArgs = {
  data?: Maybe<Array<Maybe<MarketItemPricesCreateInput>>>;
};


export type MutationUpdateMarketItemPriceArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketItemPriceUpdateInput>;
};


export type MutationUpdateMarketItemPricesArgs = {
  data?: Maybe<Array<Maybe<MarketItemPricesUpdateInput>>>;
};


export type MutationDeleteMarketItemPriceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketItemPricesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketPriceScopeHistoryRecordArgs = {
  data?: Maybe<MarketPriceScopeHistoryRecordCreateInput>;
};


export type MutationCreateMarketPriceScopeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMarketPriceScopeHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketPriceScopeHistoryRecordUpdateInput>;
};


export type MutationUpdateMarketPriceScopeHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMarketPriceScopeHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketPriceScopeHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketPriceScopeArgs = {
  data?: Maybe<MarketPriceScopeCreateInput>;
};


export type MutationCreateMarketPriceScopesArgs = {
  data?: Maybe<Array<Maybe<MarketPriceScopesCreateInput>>>;
};


export type MutationUpdateMarketPriceScopeArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketPriceScopeUpdateInput>;
};


export type MutationUpdateMarketPriceScopesArgs = {
  data?: Maybe<Array<Maybe<MarketPriceScopesUpdateInput>>>;
};


export type MutationDeleteMarketPriceScopeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketPriceScopesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketSettingHistoryRecordArgs = {
  data?: Maybe<MarketSettingHistoryRecordCreateInput>;
};


export type MutationCreateMarketSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketSettingHistoryRecordsCreateInput>>>;
};


export type MutationUpdateMarketSettingHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketSettingHistoryRecordUpdateInput>;
};


export type MutationUpdateMarketSettingHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<MarketSettingHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteMarketSettingHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketSettingHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateMarketSettingArgs = {
  data?: Maybe<MarketSettingCreateInput>;
};


export type MutationCreateMarketSettingsArgs = {
  data?: Maybe<Array<Maybe<MarketSettingsCreateInput>>>;
};


export type MutationUpdateMarketSettingArgs = {
  id: Scalars['ID'];
  data?: Maybe<MarketSettingUpdateInput>;
};


export type MutationUpdateMarketSettingsArgs = {
  data?: Maybe<Array<Maybe<MarketSettingsUpdateInput>>>;
};


export type MutationDeleteMarketSettingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketSettingsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateDocumentCategoryHistoryRecordArgs = {
  data?: Maybe<DocumentCategoryHistoryRecordCreateInput>;
};


export type MutationCreateDocumentCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<DocumentCategoryHistoryRecordsCreateInput>>>;
};


export type MutationUpdateDocumentCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<DocumentCategoryHistoryRecordUpdateInput>;
};


export type MutationUpdateDocumentCategoryHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<DocumentCategoryHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteDocumentCategoryHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDocumentCategoryHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateDocumentCategoryArgs = {
  data?: Maybe<DocumentCategoryCreateInput>;
};


export type MutationCreateDocumentCategoriesArgs = {
  data?: Maybe<Array<Maybe<DocumentCategoriesCreateInput>>>;
};


export type MutationUpdateDocumentCategoryArgs = {
  id: Scalars['ID'];
  data?: Maybe<DocumentCategoryUpdateInput>;
};


export type MutationUpdateDocumentCategoriesArgs = {
  data?: Maybe<Array<Maybe<DocumentCategoriesUpdateInput>>>;
};


export type MutationDeleteDocumentCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDocumentCategoriesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateDocumentHistoryRecordArgs = {
  data?: Maybe<DocumentHistoryRecordCreateInput>;
};


export type MutationCreateDocumentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<DocumentHistoryRecordsCreateInput>>>;
};


export type MutationUpdateDocumentHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<DocumentHistoryRecordUpdateInput>;
};


export type MutationUpdateDocumentHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<DocumentHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteDocumentHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDocumentHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateDocumentArgs = {
  data?: Maybe<DocumentCreateInput>;
};


export type MutationCreateDocumentsArgs = {
  data?: Maybe<Array<Maybe<DocumentsCreateInput>>>;
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['ID'];
  data?: Maybe<DocumentUpdateInput>;
};


export type MutationUpdateDocumentsArgs = {
  data?: Maybe<Array<Maybe<DocumentsUpdateInput>>>;
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDocumentsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateWebhookHistoryRecordArgs = {
  data?: Maybe<WebhookHistoryRecordCreateInput>;
};


export type MutationCreateWebhookHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<WebhookHistoryRecordsCreateInput>>>;
};


export type MutationUpdateWebhookHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<WebhookHistoryRecordUpdateInput>;
};


export type MutationUpdateWebhookHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<WebhookHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteWebhookHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWebhookHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateWebhookArgs = {
  data?: Maybe<WebhookCreateInput>;
};


export type MutationCreateWebhooksArgs = {
  data?: Maybe<Array<Maybe<WebhooksCreateInput>>>;
};


export type MutationUpdateWebhookArgs = {
  id: Scalars['ID'];
  data?: Maybe<WebhookUpdateInput>;
};


export type MutationUpdateWebhooksArgs = {
  data?: Maybe<Array<Maybe<WebhooksUpdateInput>>>;
};


export type MutationDeleteWebhookArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWebhooksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateWebhookSubscriptionHistoryRecordArgs = {
  data?: Maybe<WebhookSubscriptionHistoryRecordCreateInput>;
};


export type MutationCreateWebhookSubscriptionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecordsCreateInput>>>;
};


export type MutationUpdateWebhookSubscriptionHistoryRecordArgs = {
  id: Scalars['ID'];
  data?: Maybe<WebhookSubscriptionHistoryRecordUpdateInput>;
};


export type MutationUpdateWebhookSubscriptionHistoryRecordsArgs = {
  data?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecordsUpdateInput>>>;
};


export type MutationDeleteWebhookSubscriptionHistoryRecordArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWebhookSubscriptionHistoryRecordsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type MutationCreateWebhookSubscriptionArgs = {
  data?: Maybe<WebhookSubscriptionCreateInput>;
};


export type MutationCreateWebhookSubscriptionsArgs = {
  data?: Maybe<Array<Maybe<WebhookSubscriptionsCreateInput>>>;
};


export type MutationUpdateWebhookSubscriptionArgs = {
  id: Scalars['ID'];
  data?: Maybe<WebhookSubscriptionUpdateInput>;
};


export type MutationUpdateWebhookSubscriptionsArgs = {
  data?: Maybe<Array<Maybe<WebhookSubscriptionsUpdateInput>>>;
};


export type MutationDeleteWebhookSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWebhookSubscriptionsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type Mutation_InternalScheduleTaskByNameArgs = {
  data: _InternalScheduleTaskByNameInput;
};


export type MutationRegisterNewUserArgs = {
  data: RegisterNewUserInput;
};


export type MutationAuthenticateUserWithPhoneAndPasswordArgs = {
  data: AuthenticateUserWithPhoneAndPasswordInput;
};


export type MutationChangePasswordWithTokenArgs = {
  data: ChangePasswordWithTokenInput;
};


export type MutationStartConfirmPhoneActionArgs = {
  data: StartConfirmPhoneActionInput;
};


export type MutationResendConfirmPhoneActionSmsArgs = {
  data: ResendConfirmPhoneActionSmsInput;
};


export type MutationCompleteConfirmPhoneActionArgs = {
  data: CompleteConfirmPhoneActionInput;
};


export type MutationSigninResidentUserArgs = {
  data: SigninResidentUserInput;
};


export type MutationChangePhoneNumberResidentUserArgs = {
  data: ChangePhoneNumberResidentUserInput;
};


export type MutationSigninAsUserArgs = {
  data: SigninAsUserInput;
};


export type MutationRegisterNewServiceUserArgs = {
  data: RegisterNewServiceUserInput;
};


export type MutationSendMessageToSupportArgs = {
  data: SendMessageToSupportInput;
};


export type MutationResetUserArgs = {
  data: ResetUserInput;
};


export type MutationRegisterNewOrganizationArgs = {
  data: RegisterNewOrganizationInput;
};


export type MutationInviteNewOrganizationEmployeeArgs = {
  data: InviteNewOrganizationEmployeeInput;
};


export type MutationReInviteOrganizationEmployeeArgs = {
  data: ReInviteOrganizationEmployeeInput;
};


export type MutationAcceptOrRejectOrganizationInviteByIdArgs = {
  id: Scalars['ID'];
  data: AcceptOrRejectOrganizationInviteInput;
};


export type MutationAcceptOrRejectOrganizationInviteByCodeArgs = {
  inviteCode: Scalars['String'];
  data: AcceptOrRejectOrganizationInviteInput;
};


export type MutationResetOrganizationArgs = {
  data: ResetOrganizationInput;
};


export type MutationReplaceOrganizationEmployeeRoleArgs = {
  data: ReplaceOrganizationEmployeeRoleInput;
};


export type MutationRegisterBillingReceiptsArgs = {
  data: RegisterBillingReceiptsInput;
};


export type MutationSendNewBillingReceiptFilesNotificationsArgs = {
  data: SendNewBillingReceiptFilesNotificationsInput;
};


export type MutationSendNewReceiptMessagesToResidentScopesArgs = {
  data: SendNewReceiptMessagesToResidentScopesInput;
};


export type MutationValidateQrCodeArgs = {
  data: ValidateQrCodeInput;
};


export type MutationRegisterBillingReceiptFileArgs = {
  data: RegisterBillingReceiptFileInput;
};


export type MutationCreateBankAccountRequestArgs = {
  data: CreateBankAccountRequestInput;
};


export type MutationShareTicketArgs = {
  data: ShareTicketInput;
};


export type MutationTicketMultipleUpdateArgs = {
  data: TicketMultipleUpdateInput;
};


export type MutationSendMessageArgs = {
  data: SendMessageInput;
};


export type MutationResendMessageArgs = {
  data: ResendMessageInput;
};


export type MutationSyncRemoteClientArgs = {
  data: SyncRemoteClientInput;
};


export type MutationDisconnectUserFromRemoteClientArgs = {
  data: DisconnectUserFromRemoteClientInput;
};


export type MutationSetMessageStatusArgs = {
  data: SetMessageStatusInput;
};


export type Mutation_InternalSendNotificationNewMobileAppVersionArgs = {
  data: _InternalSendNotificationNewMobileAppVersionInput;
};


export type Mutation_InternalSendHashedResidentPhonesArgs = {
  data: _InternalSendHashedResidentPhonesInput;
};


export type Mutation_InternalSyncContactsWithResidentsForOrganizationArgs = {
  data: _InternalSyncContactsWithResidentsForOrganizationInput;
};


export type MutationRegisterResidentArgs = {
  data: RegisterResidentInput;
};


export type MutationRegisterResidentServiceConsumersArgs = {
  data: RegisterResidentServiceConsumersInput;
};


export type MutationRegisterServiceConsumerArgs = {
  data: RegisterServiceConsumerInput;
};


export type MutationSendMessageToResidentScopesArgs = {
  data: SendMessageToResidentScopesServiceInput;
};


export type MutationDiscoverServiceConsumersArgs = {
  data: DiscoverServiceConsumersInput;
};


export type MutationRegisterResidentInvoiceArgs = {
  data: RegisterResidentInvoiceInput;
};


export type MutationSyncTourStepsArgs = {
  data: SyncTourStepsInput;
};


export type Mutation_InternalDeleteMeterAndMeterReadingsArgs = {
  data: _InternalDeleteMeterAndMeterReadingsInput;
};


export type Mutation_InternalDeleteMeterReadingsArgs = {
  data: _InternalDeleteMeterReadingsInput;
};


export type MutationRegisterMetersReadingsArgs = {
  data: RegisterMetersReadingsInput;
};


export type MutationRegisterMultiPaymentArgs = {
  data: RegisterMultiPaymentInput;
};


export type MutationRegisterMultiPaymentForOneReceiptArgs = {
  data: RegisterMultiPaymentForOneReceiptInput;
};


export type MutationRegisterMultiPaymentForVirtualReceiptArgs = {
  data: RegisterMultiPaymentForVirtualReceiptInput;
};


export type MutationCreatePaymentByLinkArgs = {
  data: CreatePaymentByLinkInput;
};


export type MutationRegisterMultiPaymentForInvoicesArgs = {
  data: RegisterMultiPaymentForInvoicesInput;
};


export type MutationSendB2CAppPushMessageArgs = {
  data: SendB2CAppPushMessageInput;
};


export type MutationAuthenticateUserWithPasswordArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationUpdateAuthenticatedUserArgs = {
  data?: Maybe<UserUpdateInput>;
};

/**  The news item created by the organization to show on resident's mobile devices  */
export type NewsItem = {
  __typename?: 'NewsItem';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItem List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItem List config, or
   *  3. As an alias to a 'name' field on the NewsItem List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItem List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  The news item number  */
  number?: Maybe<Scalars['Int']>;
  /**  The news item title  */
  title?: Maybe<Scalars['String']>;
  /**  The news item main body  */
  body?: Maybe<Scalars['String']>;
  /**  The news item type. The `common` type generates push messages no more than 1 per hour per single user. Also, the resident may disable push messages for this type. The `emergency` type will always be accompanied by a push message and shown at the top of the news list. Also, this type always has a news item validity date.  */
  type?: Maybe<NewsItemTypeType>;
  /**  Date before which the news item makes sense  */
  validBefore?: Maybe<Scalars['String']>;
  /**
   *  Start time for sending notifications.
   * If the value is null, but the “isPublished” flag is true, then the "sendAt" value will be automatically set to "publishedAt" + 15 sec
   */
  sendAt?: Maybe<Scalars['String']>;
  scopes: Array<NewsItemScope>;
  _scopesMeta?: Maybe<_QueryMeta>;
  /**
   *  Returns the number of scopes that are specified for sending the news, and also the first two of them.
   * Used to reduce requests for get of scopes in the UI
   */
  compactScopes?: Maybe<ShortScopesField>;
  /**  The date when newsItem was sent to residents. This is an internal field used to detect was the message has already been sent or not.  */
  sentAt?: Maybe<Scalars['String']>;
  /**  Shows if the news item is ready to be shown and send to residents  */
  isPublished?: Maybe<Scalars['Boolean']>;
  /**  The date when the news item was published. It is an auto-Calculated field.  */
  publishedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  The news item created by the organization to show on resident's mobile devices  */
export type NewsItemScopesArgs = {
  where?: Maybe<NewsItemScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  The news item created by the organization to show on resident's mobile devices  */
export type NewsItem_ScopesMetaArgs = {
  where?: Maybe<NewsItemScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type NewsItemCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  type?: Maybe<NewsItemTypeType>;
  validBefore?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  scopes?: Maybe<NewsItemScopeRelateToManyInput>;
  sentAt?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NewsItemHistoryRecord = {
  __typename?: 'NewsItemHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  validBefore?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  validBefore?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NewsItemHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NewsItemHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['JSON']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  validBefore?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['JSON']>;
  number_not?: Maybe<Scalars['JSON']>;
  number_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body?: Maybe<Scalars['String']>;
  body_not?: Maybe<Scalars['String']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  body_starts_with?: Maybe<Scalars['String']>;
  body_not_starts_with?: Maybe<Scalars['String']>;
  body_ends_with?: Maybe<Scalars['String']>;
  body_not_ends_with?: Maybe<Scalars['String']>;
  body_i?: Maybe<Scalars['String']>;
  body_not_i?: Maybe<Scalars['String']>;
  body_contains_i?: Maybe<Scalars['String']>;
  body_not_contains_i?: Maybe<Scalars['String']>;
  body_starts_with_i?: Maybe<Scalars['String']>;
  body_not_starts_with_i?: Maybe<Scalars['String']>;
  body_ends_with_i?: Maybe<Scalars['String']>;
  body_not_ends_with_i?: Maybe<Scalars['String']>;
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  validBefore?: Maybe<Scalars['String']>;
  validBefore_not?: Maybe<Scalars['String']>;
  validBefore_lt?: Maybe<Scalars['String']>;
  validBefore_lte?: Maybe<Scalars['String']>;
  validBefore_gt?: Maybe<Scalars['String']>;
  validBefore_gte?: Maybe<Scalars['String']>;
  validBefore_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  validBefore_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendAt?: Maybe<Scalars['String']>;
  sendAt_not?: Maybe<Scalars['String']>;
  sendAt_lt?: Maybe<Scalars['String']>;
  sendAt_lte?: Maybe<Scalars['String']>;
  sendAt_gt?: Maybe<Scalars['String']>;
  sendAt_gte?: Maybe<Scalars['String']>;
  sendAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt?: Maybe<Scalars['String']>;
  sentAt_not?: Maybe<Scalars['String']>;
  sentAt_lt?: Maybe<Scalars['String']>;
  sentAt_lte?: Maybe<Scalars['String']>;
  sentAt_gt?: Maybe<Scalars['String']>;
  sentAt_gte?: Maybe<Scalars['String']>;
  sentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isPublished_not?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedAt_not?: Maybe<Scalars['String']>;
  publishedAt_lt?: Maybe<Scalars['String']>;
  publishedAt_lte?: Maybe<Scalars['String']>;
  publishedAt_gt?: Maybe<Scalars['String']>;
  publishedAt_gte?: Maybe<Scalars['String']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NewsItemHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NewsItemHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NewsItemHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NewsItemHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewsItemHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemHistoryRecordsCreateInput = {
  data?: Maybe<NewsItemHistoryRecordCreateInput>;
};

export type NewsItemHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemHistoryRecordUpdateInput>;
};

/**  Stores data about the exporting  */
export type NewsItemRecipientsExportTask = {
  __typename?: 'NewsItemRecipientsExportTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemRecipientsExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemRecipientsExportTask List config, or
   *  3. As an alias to a 'name' field on the NewsItemRecipientsExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemRecipientsExportTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The user who has started the exporting  */
  user?: Maybe<User>;
  /**  The organization from which the recipients will be exported from  */
  organization?: Maybe<Organization>;
  /**  The array of recipients' scopes  */
  scopes?: Maybe<Scalars['JSON']>;
  /**  Status of export job  */
  status?: Maybe<NewsItemRecipientsExportTaskStatusType>;
  /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  file?: Maybe<File>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NewsItemRecipientsExportTaskCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  scopes?: Maybe<Scalars['JSON']>;
  status?: Maybe<NewsItemRecipientsExportTaskStatusType>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NewsItemRecipientsExportTaskHistoryRecord = {
  __typename?: 'NewsItemRecipientsExportTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemRecipientsExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemRecipientsExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemRecipientsExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemRecipientsExportTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemRecipientsExportTaskHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NewsItemRecipientsExportTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NewsItemRecipientsExportTaskHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  scopes?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemRecipientsExportTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  scopes?: Maybe<Scalars['JSON']>;
  scopes_not?: Maybe<Scalars['JSON']>;
  scopes_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  scopes_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewsItemRecipientsExportTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemRecipientsExportTaskHistoryRecordsCreateInput = {
  data?: Maybe<NewsItemRecipientsExportTaskHistoryRecordCreateInput>;
};

export type NewsItemRecipientsExportTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemRecipientsExportTaskHistoryRecordUpdateInput>;
};

export enum NewsItemRecipientsExportTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type NewsItemRecipientsExportTaskUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  scopes?: Maybe<Scalars['JSON']>;
  status?: Maybe<NewsItemRecipientsExportTaskStatusType>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NewsItemRecipientsExportTaskWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  scopes?: Maybe<Scalars['JSON']>;
  scopes_not?: Maybe<Scalars['JSON']>;
  scopes_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  scopes_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<NewsItemRecipientsExportTaskStatusType>;
  status_not?: Maybe<NewsItemRecipientsExportTaskStatusType>;
  status_in?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskStatusType>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NewsItemRecipientsExportTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemRecipientsExportTasksCreateInput = {
  data?: Maybe<NewsItemRecipientsExportTaskCreateInput>;
};

export type NewsItemRecipientsExportTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemRecipientsExportTaskUpdateInput>;
};

export type NewsItemRelateToOneInput = {
  create?: Maybe<NewsItemCreateInput>;
  connect?: Maybe<NewsItemWhereUniqueInput>;
  disconnect?: Maybe<NewsItemWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  Which residents can see the particular news item  */
export type NewsItemScope = {
  __typename?: 'NewsItemScope';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemScope List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemScope List config, or
   *  3. As an alias to a 'name' field on the NewsItemScope List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemScope List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The scope type. This is an auto-calculated field. Used to find news items by scopes filled with some set of attributes.  */
  type?: Maybe<NewsItemScopeTypeType>;
  /**  The news item to control access for  */
  newsItem?: Maybe<NewsItem>;
  /**  Filter on Resident by property, who can read news  */
  property?: Maybe<Property>;
  /**  Filter on Resident by unit type, who can read news  */
  unitType?: Maybe<NewsItemScopeUnitTypeType>;
  /**  Filter on Resident by unit name, who can read news. Because in case when a Property will have several units of different types, NewsItem can go to wrong recipients. In practice, when an organization needs to address specific residents it is usually assumed, that they are from units of some specific type, for example, a flat. Without this restriction, a NewsItem, targeted only to unitName 1 can potentially be received by residents of flat 1, parking 1 etc.  */
  unitName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NewsItemScopeCreateInput = {
  type?: Maybe<NewsItemScopeTypeType>;
  newsItem?: Maybe<NewsItemRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitType?: Maybe<NewsItemScopeUnitTypeType>;
  unitName?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NewsItemScopeHistoryRecord = {
  __typename?: 'NewsItemScopeHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemScopeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemScopeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemScopeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemScopeHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemScopeHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NewsItemScopeHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NewsItemScopeHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemScopeHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemScopeHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemScopeHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsItem?: Maybe<Scalars['String']>;
  newsItem_not?: Maybe<Scalars['String']>;
  newsItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NewsItemScopeHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NewsItemScopeHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NewsItemScopeHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NewsItemScopeHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewsItemScopeHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemScopeHistoryRecordsCreateInput = {
  data?: Maybe<NewsItemScopeHistoryRecordCreateInput>;
};

export type NewsItemScopeHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemScopeHistoryRecordUpdateInput>;
};

export type NewsItemScopeRelateToManyInput = {
  create?: Maybe<Array<Maybe<NewsItemScopeCreateInput>>>;
  connect?: Maybe<Array<Maybe<NewsItemScopeWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<NewsItemScopeWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum NewsItemScopeTypeType {
  Unknown = 'unknown',
  Organization = 'organization',
  Property = 'property',
  PropertyUnitType = 'property_unitType',
  PropertyUnitTypeUnitName = 'property_unitType_unitName'
}

export enum NewsItemScopeUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type NewsItemScopeUpdateInput = {
  type?: Maybe<NewsItemScopeTypeType>;
  newsItem?: Maybe<NewsItemRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitType?: Maybe<NewsItemScopeUnitTypeType>;
  unitName?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NewsItemScopeWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemScopeWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemScopeWhereInput>>>;
  type?: Maybe<NewsItemScopeTypeType>;
  type_not?: Maybe<NewsItemScopeTypeType>;
  type_in?: Maybe<Array<Maybe<NewsItemScopeTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<NewsItemScopeTypeType>>>;
  newsItem?: Maybe<NewsItemWhereInput>;
  newsItem_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  unitType?: Maybe<NewsItemScopeUnitTypeType>;
  unitType_not?: Maybe<NewsItemScopeUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<NewsItemScopeUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<NewsItemScopeUnitTypeType>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NewsItemScopeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemScopesCreateInput = {
  data?: Maybe<NewsItemScopeCreateInput>;
};

export type NewsItemScopesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemScopeUpdateInput>;
};

/**  Existence of this models means that certain NewsItem should published in certain B2BApp that implements NewsSharing API.  */
export type NewsItemSharing = {
  __typename?: 'NewsItemSharing';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemSharing List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemSharing List config, or
   *  3. As an alias to a 'name' field on the NewsItemSharing List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemSharing List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Connection to the miniapp that is responsible for publishing this news item  */
  b2bAppContext?: Maybe<B2BAppContext>;
  /**  Connection to the published news item  */
  newsItem?: Maybe<NewsItem>;
  /**  Sending parameters specific to a particular mini-app  */
  sharingParams?: Maybe<Scalars['JSON']>;
  /**  Publication status of the news: updated automatically  */
  status?: Maybe<NewsItemSharingStatusType>;
  /**  Explanations regarding the publication status. Might be shown to user  */
  statusMessage?: Maybe<Scalars['String']>;
  /**  The outcome from the most recent invocation of the lastPostRequest  */
  lastPostRequest?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NewsItemSharingCreateInput = {
  b2bAppContext?: Maybe<B2BAppContextRelateToOneInput>;
  newsItem?: Maybe<NewsItemRelateToOneInput>;
  sharingParams?: Maybe<Scalars['JSON']>;
  status?: Maybe<NewsItemSharingStatusType>;
  statusMessage?: Maybe<Scalars['String']>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NewsItemSharingHistoryRecord = {
  __typename?: 'NewsItemSharingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemSharingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemSharingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemSharingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemSharingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  b2bAppContext?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  sharingParams?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemSharingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemSharingHistoryRecordCreateInput = {
  b2bAppContext?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  sharingParams?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemSharingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NewsItemSharingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NewsItemSharingHistoryRecordUpdateInput = {
  b2bAppContext?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  sharingParams?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemSharingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemSharingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemSharingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemSharingHistoryRecordWhereInput>>>;
  b2bAppContext?: Maybe<Scalars['String']>;
  b2bAppContext_not?: Maybe<Scalars['String']>;
  b2bAppContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  b2bAppContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsItem?: Maybe<Scalars['String']>;
  newsItem_not?: Maybe<Scalars['String']>;
  newsItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sharingParams?: Maybe<Scalars['JSON']>;
  sharingParams_not?: Maybe<Scalars['JSON']>;
  sharingParams_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sharingParams_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMessage?: Maybe<Scalars['String']>;
  statusMessage_not?: Maybe<Scalars['String']>;
  statusMessage_contains?: Maybe<Scalars['String']>;
  statusMessage_not_contains?: Maybe<Scalars['String']>;
  statusMessage_starts_with?: Maybe<Scalars['String']>;
  statusMessage_not_starts_with?: Maybe<Scalars['String']>;
  statusMessage_ends_with?: Maybe<Scalars['String']>;
  statusMessage_not_ends_with?: Maybe<Scalars['String']>;
  statusMessage_i?: Maybe<Scalars['String']>;
  statusMessage_not_i?: Maybe<Scalars['String']>;
  statusMessage_contains_i?: Maybe<Scalars['String']>;
  statusMessage_not_contains_i?: Maybe<Scalars['String']>;
  statusMessage_starts_with_i?: Maybe<Scalars['String']>;
  statusMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  statusMessage_ends_with_i?: Maybe<Scalars['String']>;
  statusMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  statusMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  lastPostRequest_not?: Maybe<Scalars['JSON']>;
  lastPostRequest_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lastPostRequest_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NewsItemSharingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NewsItemSharingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NewsItemSharingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NewsItemSharingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewsItemSharingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemSharingHistoryRecordsCreateInput = {
  data?: Maybe<NewsItemSharingHistoryRecordCreateInput>;
};

export type NewsItemSharingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemSharingHistoryRecordUpdateInput>;
};

export enum NewsItemSharingStatusType {
  Scheduled = 'scheduled',
  Processing = 'processing',
  Published = 'published',
  Error = 'error'
}

export type NewsItemSharingUpdateInput = {
  b2bAppContext?: Maybe<B2BAppContextRelateToOneInput>;
  newsItem?: Maybe<NewsItemRelateToOneInput>;
  sharingParams?: Maybe<Scalars['JSON']>;
  status?: Maybe<NewsItemSharingStatusType>;
  statusMessage?: Maybe<Scalars['String']>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NewsItemSharingWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemSharingWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemSharingWhereInput>>>;
  b2bAppContext?: Maybe<B2BAppContextWhereInput>;
  b2bAppContext_is_null?: Maybe<Scalars['Boolean']>;
  newsItem?: Maybe<NewsItemWhereInput>;
  newsItem_is_null?: Maybe<Scalars['Boolean']>;
  sharingParams?: Maybe<Scalars['JSON']>;
  sharingParams_not?: Maybe<Scalars['JSON']>;
  sharingParams_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sharingParams_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<NewsItemSharingStatusType>;
  status_not?: Maybe<NewsItemSharingStatusType>;
  status_in?: Maybe<Array<Maybe<NewsItemSharingStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<NewsItemSharingStatusType>>>;
  statusMessage?: Maybe<Scalars['String']>;
  statusMessage_not?: Maybe<Scalars['String']>;
  statusMessage_contains?: Maybe<Scalars['String']>;
  statusMessage_not_contains?: Maybe<Scalars['String']>;
  statusMessage_starts_with?: Maybe<Scalars['String']>;
  statusMessage_not_starts_with?: Maybe<Scalars['String']>;
  statusMessage_ends_with?: Maybe<Scalars['String']>;
  statusMessage_not_ends_with?: Maybe<Scalars['String']>;
  statusMessage_i?: Maybe<Scalars['String']>;
  statusMessage_not_i?: Maybe<Scalars['String']>;
  statusMessage_contains_i?: Maybe<Scalars['String']>;
  statusMessage_not_contains_i?: Maybe<Scalars['String']>;
  statusMessage_starts_with_i?: Maybe<Scalars['String']>;
  statusMessage_not_starts_with_i?: Maybe<Scalars['String']>;
  statusMessage_ends_with_i?: Maybe<Scalars['String']>;
  statusMessage_not_ends_with_i?: Maybe<Scalars['String']>;
  statusMessage_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusMessage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastPostRequest?: Maybe<Scalars['JSON']>;
  lastPostRequest_not?: Maybe<Scalars['JSON']>;
  lastPostRequest_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  lastPostRequest_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NewsItemSharingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemSharingsCreateInput = {
  data?: Maybe<NewsItemSharingCreateInput>;
};

export type NewsItemSharingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemSharingUpdateInput>;
};

/**  The news item template for quick composing a news item  */
export type NewsItemTemplate = {
  __typename?: 'NewsItemTemplate';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemTemplate List config, or
   *  3. As an alias to a 'name' field on the NewsItemTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemTemplate List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The news item template type.  */
  type?: Maybe<NewsItemTemplateTypeType>;
  /**  Organization who creates the template. A common template if there is no organization  */
  organization?: Maybe<Organization>;
  /**  The title of a future news item  */
  title?: Maybe<Scalars['String']>;
  /**  A future news item's body  */
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NewsItemTemplateCreateInput = {
  type?: Maybe<NewsItemTemplateTypeType>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NewsItemTemplateHistoryRecord = {
  __typename?: 'NewsItemTemplateHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemTemplateHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemTemplateHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NewsItemTemplateHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NewsItemTemplateHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemTemplateHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body?: Maybe<Scalars['String']>;
  body_not?: Maybe<Scalars['String']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  body_starts_with?: Maybe<Scalars['String']>;
  body_not_starts_with?: Maybe<Scalars['String']>;
  body_ends_with?: Maybe<Scalars['String']>;
  body_not_ends_with?: Maybe<Scalars['String']>;
  body_i?: Maybe<Scalars['String']>;
  body_not_i?: Maybe<Scalars['String']>;
  body_contains_i?: Maybe<Scalars['String']>;
  body_not_contains_i?: Maybe<Scalars['String']>;
  body_starts_with_i?: Maybe<Scalars['String']>;
  body_not_starts_with_i?: Maybe<Scalars['String']>;
  body_ends_with_i?: Maybe<Scalars['String']>;
  body_not_ends_with_i?: Maybe<Scalars['String']>;
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NewsItemTemplateHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NewsItemTemplateHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewsItemTemplateHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemTemplateHistoryRecordsCreateInput = {
  data?: Maybe<NewsItemTemplateHistoryRecordCreateInput>;
};

export type NewsItemTemplateHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemTemplateHistoryRecordUpdateInput>;
};

export enum NewsItemTemplateTypeType {
  Common = 'common',
  Emergency = 'emergency'
}

export type NewsItemTemplateUpdateInput = {
  type?: Maybe<NewsItemTemplateTypeType>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NewsItemTemplateWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemTemplateWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemTemplateWhereInput>>>;
  type?: Maybe<NewsItemTemplateTypeType>;
  type_not?: Maybe<NewsItemTemplateTypeType>;
  type_in?: Maybe<Array<Maybe<NewsItemTemplateTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<NewsItemTemplateTypeType>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body?: Maybe<Scalars['String']>;
  body_not?: Maybe<Scalars['String']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  body_starts_with?: Maybe<Scalars['String']>;
  body_not_starts_with?: Maybe<Scalars['String']>;
  body_ends_with?: Maybe<Scalars['String']>;
  body_not_ends_with?: Maybe<Scalars['String']>;
  body_i?: Maybe<Scalars['String']>;
  body_not_i?: Maybe<Scalars['String']>;
  body_contains_i?: Maybe<Scalars['String']>;
  body_not_contains_i?: Maybe<Scalars['String']>;
  body_starts_with_i?: Maybe<Scalars['String']>;
  body_not_starts_with_i?: Maybe<Scalars['String']>;
  body_ends_with_i?: Maybe<Scalars['String']>;
  body_not_ends_with_i?: Maybe<Scalars['String']>;
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NewsItemTemplateWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemTemplatesCreateInput = {
  data?: Maybe<NewsItemTemplateCreateInput>;
};

export type NewsItemTemplatesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemTemplateUpdateInput>;
};

export enum NewsItemTypeType {
  Common = 'common',
  Emergency = 'emergency'
}

export type NewsItemUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  number?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  type?: Maybe<NewsItemTypeType>;
  validBefore?: Maybe<Scalars['String']>;
  sendAt?: Maybe<Scalars['String']>;
  scopes?: Maybe<NewsItemScopeRelateToManyInput>;
  sentAt?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  The fact the user has read the particular news item  */
export type NewsItemUserRead = {
  __typename?: 'NewsItemUserRead';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemUserRead List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemUserRead List config, or
   *  3. As an alias to a 'name' field on the NewsItemUserRead List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemUserRead List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The news item the user has read  */
  newsItem?: Maybe<NewsItem>;
  /**  The user who has read the particular news item  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NewsItemUserReadCreateInput = {
  newsItem?: Maybe<NewsItemRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NewsItemUserReadHistoryRecord = {
  __typename?: 'NewsItemUserReadHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NewsItemUserReadHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NewsItemUserReadHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NewsItemUserReadHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NewsItemUserReadHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  newsItem?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemUserReadHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemUserReadHistoryRecordCreateInput = {
  newsItem?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemUserReadHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NewsItemUserReadHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NewsItemUserReadHistoryRecordUpdateInput = {
  newsItem?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NewsItemUserReadHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NewsItemUserReadHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecordWhereInput>>>;
  newsItem?: Maybe<Scalars['String']>;
  newsItem_not?: Maybe<Scalars['String']>;
  newsItem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newsItem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NewsItemUserReadHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NewsItemUserReadHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NewsItemUserReadHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemUserReadHistoryRecordsCreateInput = {
  data?: Maybe<NewsItemUserReadHistoryRecordCreateInput>;
};

export type NewsItemUserReadHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemUserReadHistoryRecordUpdateInput>;
};

export type NewsItemUserReadUpdateInput = {
  newsItem?: Maybe<NewsItemRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NewsItemUserReadWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemUserReadWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemUserReadWhereInput>>>;
  newsItem?: Maybe<NewsItemWhereInput>;
  newsItem_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NewsItemUserReadWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemUserReadsCreateInput = {
  data?: Maybe<NewsItemUserReadCreateInput>;
};

export type NewsItemUserReadsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemUserReadUpdateInput>;
};

export type NewsItemWhereInput = {
  AND?: Maybe<Array<Maybe<NewsItemWhereInput>>>;
  OR?: Maybe<Array<Maybe<NewsItemWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  title?: Maybe<Scalars['String']>;
  title_not?: Maybe<Scalars['String']>;
  title_contains?: Maybe<Scalars['String']>;
  title_not_contains?: Maybe<Scalars['String']>;
  title_starts_with?: Maybe<Scalars['String']>;
  title_not_starts_with?: Maybe<Scalars['String']>;
  title_ends_with?: Maybe<Scalars['String']>;
  title_not_ends_with?: Maybe<Scalars['String']>;
  title_i?: Maybe<Scalars['String']>;
  title_not_i?: Maybe<Scalars['String']>;
  title_contains_i?: Maybe<Scalars['String']>;
  title_not_contains_i?: Maybe<Scalars['String']>;
  title_starts_with_i?: Maybe<Scalars['String']>;
  title_not_starts_with_i?: Maybe<Scalars['String']>;
  title_ends_with_i?: Maybe<Scalars['String']>;
  title_not_ends_with_i?: Maybe<Scalars['String']>;
  title_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body?: Maybe<Scalars['String']>;
  body_not?: Maybe<Scalars['String']>;
  body_contains?: Maybe<Scalars['String']>;
  body_not_contains?: Maybe<Scalars['String']>;
  body_starts_with?: Maybe<Scalars['String']>;
  body_not_starts_with?: Maybe<Scalars['String']>;
  body_ends_with?: Maybe<Scalars['String']>;
  body_not_ends_with?: Maybe<Scalars['String']>;
  body_i?: Maybe<Scalars['String']>;
  body_not_i?: Maybe<Scalars['String']>;
  body_contains_i?: Maybe<Scalars['String']>;
  body_not_contains_i?: Maybe<Scalars['String']>;
  body_starts_with_i?: Maybe<Scalars['String']>;
  body_not_starts_with_i?: Maybe<Scalars['String']>;
  body_ends_with_i?: Maybe<Scalars['String']>;
  body_not_ends_with_i?: Maybe<Scalars['String']>;
  body_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<NewsItemTypeType>;
  type_not?: Maybe<NewsItemTypeType>;
  type_in?: Maybe<Array<Maybe<NewsItemTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<NewsItemTypeType>>>;
  validBefore?: Maybe<Scalars['String']>;
  validBefore_not?: Maybe<Scalars['String']>;
  validBefore_lt?: Maybe<Scalars['String']>;
  validBefore_lte?: Maybe<Scalars['String']>;
  validBefore_gt?: Maybe<Scalars['String']>;
  validBefore_gte?: Maybe<Scalars['String']>;
  validBefore_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  validBefore_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendAt?: Maybe<Scalars['String']>;
  sendAt_not?: Maybe<Scalars['String']>;
  sendAt_lt?: Maybe<Scalars['String']>;
  sendAt_lte?: Maybe<Scalars['String']>;
  sendAt_gt?: Maybe<Scalars['String']>;
  sendAt_gte?: Maybe<Scalars['String']>;
  sendAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  condition must be true for all nodes  */
  scopes_every?: Maybe<NewsItemScopeWhereInput>;
  /**  condition must be true for at least 1 node  */
  scopes_some?: Maybe<NewsItemScopeWhereInput>;
  /**  condition must be false for all nodes  */
  scopes_none?: Maybe<NewsItemScopeWhereInput>;
  sentAt?: Maybe<Scalars['String']>;
  sentAt_not?: Maybe<Scalars['String']>;
  sentAt_lt?: Maybe<Scalars['String']>;
  sentAt_lte?: Maybe<Scalars['String']>;
  sentAt_gt?: Maybe<Scalars['String']>;
  sentAt_gte?: Maybe<Scalars['String']>;
  sentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPublished?: Maybe<Scalars['Boolean']>;
  isPublished_not?: Maybe<Scalars['Boolean']>;
  publishedAt?: Maybe<Scalars['String']>;
  publishedAt_not?: Maybe<Scalars['String']>;
  publishedAt_lt?: Maybe<Scalars['String']>;
  publishedAt_lte?: Maybe<Scalars['String']>;
  publishedAt_gt?: Maybe<Scalars['String']>;
  publishedAt_gte?: Maybe<Scalars['String']>;
  publishedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NewsItemWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NewsItemsCreateInput = {
  data?: Maybe<NewsItemCreateInput>;
};

export type NewsItemsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NewsItemUpdateInput>;
};

/**  Anonymous contact notifications settings  */
export type NotificationAnonymousSetting = {
  __typename?: 'NotificationAnonymousSetting';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationAnonymousSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationAnonymousSetting List config, or
   *  3. As an alias to a 'name' field on the NotificationAnonymousSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationAnonymousSetting List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The settings will applied for  */
  email?: Maybe<Scalars['String']>;
  /**  Phone. In international E.164 format without spaces  */
  phone?: Maybe<Scalars['String']>;
  /**  Affected message type  */
  messageType?: Maybe<MessageType>;
  /**  Affected message transport. Possible values are: email, sms  */
  messageTransport?: Maybe<NotificationAnonymousSettingMessageTransportType>;
  /**  Is notification enabled  */
  isEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NotificationAnonymousSettingCreateInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  messageTransport?: Maybe<NotificationAnonymousSettingMessageTransportType>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NotificationAnonymousSettingHistoryRecord = {
  __typename?: 'NotificationAnonymousSettingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationAnonymousSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationAnonymousSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NotificationAnonymousSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationAnonymousSettingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  messageTransport?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NotificationAnonymousSettingHistoryRecordCreateInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  messageTransport?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NotificationAnonymousSettingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NotificationAnonymousSettingHistoryRecordUpdateInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  messageTransport?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NotificationAnonymousSettingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecordWhereInput>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType?: Maybe<Scalars['String']>;
  messageType_not?: Maybe<Scalars['String']>;
  messageType_contains?: Maybe<Scalars['String']>;
  messageType_not_contains?: Maybe<Scalars['String']>;
  messageType_starts_with?: Maybe<Scalars['String']>;
  messageType_not_starts_with?: Maybe<Scalars['String']>;
  messageType_ends_with?: Maybe<Scalars['String']>;
  messageType_not_ends_with?: Maybe<Scalars['String']>;
  messageType_i?: Maybe<Scalars['String']>;
  messageType_not_i?: Maybe<Scalars['String']>;
  messageType_contains_i?: Maybe<Scalars['String']>;
  messageType_not_contains_i?: Maybe<Scalars['String']>;
  messageType_starts_with_i?: Maybe<Scalars['String']>;
  messageType_not_starts_with_i?: Maybe<Scalars['String']>;
  messageType_ends_with_i?: Maybe<Scalars['String']>;
  messageType_not_ends_with_i?: Maybe<Scalars['String']>;
  messageType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageTransport?: Maybe<Scalars['String']>;
  messageTransport_not?: Maybe<Scalars['String']>;
  messageTransport_contains?: Maybe<Scalars['String']>;
  messageTransport_not_contains?: Maybe<Scalars['String']>;
  messageTransport_starts_with?: Maybe<Scalars['String']>;
  messageTransport_not_starts_with?: Maybe<Scalars['String']>;
  messageTransport_ends_with?: Maybe<Scalars['String']>;
  messageTransport_not_ends_with?: Maybe<Scalars['String']>;
  messageTransport_i?: Maybe<Scalars['String']>;
  messageTransport_not_i?: Maybe<Scalars['String']>;
  messageTransport_contains_i?: Maybe<Scalars['String']>;
  messageTransport_not_contains_i?: Maybe<Scalars['String']>;
  messageTransport_starts_with_i?: Maybe<Scalars['String']>;
  messageTransport_not_starts_with_i?: Maybe<Scalars['String']>;
  messageTransport_ends_with_i?: Maybe<Scalars['String']>;
  messageTransport_not_ends_with_i?: Maybe<Scalars['String']>;
  messageTransport_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageTransport_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEnabled_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationAnonymousSettingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NotificationAnonymousSettingHistoryRecordsCreateInput = {
  data?: Maybe<NotificationAnonymousSettingHistoryRecordCreateInput>;
};

export type NotificationAnonymousSettingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NotificationAnonymousSettingHistoryRecordUpdateInput>;
};

export enum NotificationAnonymousSettingMessageTransportType {
  Email = 'email',
  Sms = 'sms'
}

export type NotificationAnonymousSettingUpdateInput = {
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  messageType?: Maybe<MessageType>;
  messageTransport?: Maybe<NotificationAnonymousSettingMessageTransportType>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NotificationAnonymousSettingWhereInput = {
  AND?: Maybe<Array<Maybe<NotificationAnonymousSettingWhereInput>>>;
  OR?: Maybe<Array<Maybe<NotificationAnonymousSettingWhereInput>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType?: Maybe<MessageType>;
  messageType_not?: Maybe<MessageType>;
  messageType_in?: Maybe<Array<Maybe<MessageType>>>;
  messageType_not_in?: Maybe<Array<Maybe<MessageType>>>;
  messageTransport?: Maybe<NotificationAnonymousSettingMessageTransportType>;
  messageTransport_not?: Maybe<NotificationAnonymousSettingMessageTransportType>;
  messageTransport_in?: Maybe<Array<Maybe<NotificationAnonymousSettingMessageTransportType>>>;
  messageTransport_not_in?: Maybe<Array<Maybe<NotificationAnonymousSettingMessageTransportType>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEnabled_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NotificationAnonymousSettingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NotificationAnonymousSettingsCreateInput = {
  data?: Maybe<NotificationAnonymousSettingCreateInput>;
};

export type NotificationAnonymousSettingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NotificationAnonymousSettingUpdateInput>;
};

/**  User controlled notifications settings  */
export type NotificationUserSetting = {
  __typename?: 'NotificationUserSetting';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationUserSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationUserSetting List config, or
   *  3. As an alias to a 'name' field on the NotificationUserSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationUserSetting List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The user the settings will apply for  */
  user?: Maybe<User>;
  /**  Affected message type  */
  messageType?: Maybe<MessageType>;
  /**  Affected message transport. Possible values are: sms,email,telegram,push  */
  messageTransport?: Maybe<NotificationUserSettingMessageTransportType>;
  /**  Is notification enabled  */
  isEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type NotificationUserSettingCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  messageType?: Maybe<MessageType>;
  messageTransport?: Maybe<NotificationUserSettingMessageTransportType>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type NotificationUserSettingHistoryRecord = {
  __typename?: 'NotificationUserSettingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the NotificationUserSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the NotificationUserSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the NotificationUserSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the NotificationUserSettingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  messageTransport?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NotificationUserSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NotificationUserSettingHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  messageTransport?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NotificationUserSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum NotificationUserSettingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type NotificationUserSettingHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  messageType?: Maybe<Scalars['String']>;
  messageTransport?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<NotificationUserSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type NotificationUserSettingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType?: Maybe<Scalars['String']>;
  messageType_not?: Maybe<Scalars['String']>;
  messageType_contains?: Maybe<Scalars['String']>;
  messageType_not_contains?: Maybe<Scalars['String']>;
  messageType_starts_with?: Maybe<Scalars['String']>;
  messageType_not_starts_with?: Maybe<Scalars['String']>;
  messageType_ends_with?: Maybe<Scalars['String']>;
  messageType_not_ends_with?: Maybe<Scalars['String']>;
  messageType_i?: Maybe<Scalars['String']>;
  messageType_not_i?: Maybe<Scalars['String']>;
  messageType_contains_i?: Maybe<Scalars['String']>;
  messageType_not_contains_i?: Maybe<Scalars['String']>;
  messageType_starts_with_i?: Maybe<Scalars['String']>;
  messageType_not_starts_with_i?: Maybe<Scalars['String']>;
  messageType_ends_with_i?: Maybe<Scalars['String']>;
  messageType_not_ends_with_i?: Maybe<Scalars['String']>;
  messageType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageTransport?: Maybe<Scalars['String']>;
  messageTransport_not?: Maybe<Scalars['String']>;
  messageTransport_contains?: Maybe<Scalars['String']>;
  messageTransport_not_contains?: Maybe<Scalars['String']>;
  messageTransport_starts_with?: Maybe<Scalars['String']>;
  messageTransport_not_starts_with?: Maybe<Scalars['String']>;
  messageTransport_ends_with?: Maybe<Scalars['String']>;
  messageTransport_not_ends_with?: Maybe<Scalars['String']>;
  messageTransport_i?: Maybe<Scalars['String']>;
  messageTransport_not_i?: Maybe<Scalars['String']>;
  messageTransport_contains_i?: Maybe<Scalars['String']>;
  messageTransport_not_contains_i?: Maybe<Scalars['String']>;
  messageTransport_starts_with_i?: Maybe<Scalars['String']>;
  messageTransport_not_starts_with_i?: Maybe<Scalars['String']>;
  messageTransport_ends_with_i?: Maybe<Scalars['String']>;
  messageTransport_not_ends_with_i?: Maybe<Scalars['String']>;
  messageTransport_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  messageTransport_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEnabled_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<NotificationUserSettingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<NotificationUserSettingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationUserSettingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NotificationUserSettingHistoryRecordsCreateInput = {
  data?: Maybe<NotificationUserSettingHistoryRecordCreateInput>;
};

export type NotificationUserSettingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NotificationUserSettingHistoryRecordUpdateInput>;
};

export enum NotificationUserSettingMessageTransportType {
  Sms = 'sms',
  Email = 'email',
  Telegram = 'telegram',
  Push = 'push'
}

export type NotificationUserSettingUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  messageType?: Maybe<MessageType>;
  messageTransport?: Maybe<NotificationUserSettingMessageTransportType>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type NotificationUserSettingWhereInput = {
  AND?: Maybe<Array<Maybe<NotificationUserSettingWhereInput>>>;
  OR?: Maybe<Array<Maybe<NotificationUserSettingWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  messageType?: Maybe<MessageType>;
  messageType_not?: Maybe<MessageType>;
  messageType_in?: Maybe<Array<Maybe<MessageType>>>;
  messageType_not_in?: Maybe<Array<Maybe<MessageType>>>;
  messageTransport?: Maybe<NotificationUserSettingMessageTransportType>;
  messageTransport_not?: Maybe<NotificationUserSettingMessageTransportType>;
  messageTransport_in?: Maybe<Array<Maybe<NotificationUserSettingMessageTransportType>>>;
  messageTransport_not_in?: Maybe<Array<Maybe<NotificationUserSettingMessageTransportType>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEnabled_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type NotificationUserSettingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type NotificationUserSettingsCreateInput = {
  data?: Maybe<NotificationUserSettingCreateInput>;
};

export type NotificationUserSettingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<NotificationUserSettingUpdateInput>;
};

/**  The OIDC clients list  */
export type OidcClient = {
  __typename?: 'OidcClient';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OidcClient List config, or
   *  2. As an alias to the field set on 'labelField' in the OidcClient List config, or
   *  3. As an alias to a 'name' field on the OidcClient List (if one exists), or
   *  4. As an alias to the 'id' field on the OidcClient List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The clientId  */
  clientId?: Maybe<Scalars['String']>;
  /**  The payload of the client (clientId, clientSecret, callbackUrl, ...)  */
  payload?: Maybe<Scalars['JSON']>;
  /**  The human readable name for client  */
  name?: Maybe<Scalars['String']>;
  /**  A switch that allows you to disable some OIDC clients. If an OIDC client is disabled, it cannot be used for OIDC authorization. Used mainly by developers portal to create OIDC client before publishing an application, and enable OIDC after publishing.  */
  isEnabled?: Maybe<Scalars['Boolean']>;
  /**  A switch that allows this OIDC client to authorize users with privileges, such as admins, support, or users with a special rights set. Temporary solution to allow OIDCs to be used to access the admin panel of mini-applications from condo ecosystem. Tokens with scopes should solve the logic separation problem and remove this checkbox  */
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  /**  The additional client data  */
  meta?: Maybe<Scalars['JSON']>;
  /**  The timestamp of the client expiration  */
  expiresAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  /**  ID of the object in the system from which it was imported  */
  importId?: Maybe<Scalars['String']>;
  /**  Name of the system from which object was imported  */
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type OidcClientCreateInput = {
  clientId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type OidcClientHistoryRecord = {
  __typename?: 'OidcClientHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OidcClientHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OidcClientHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OidcClientHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OidcClientHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OidcClientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OidcClientHistoryRecordCreateInput = {
  clientId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OidcClientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum OidcClientHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type OidcClientHistoryRecordUpdateInput = {
  clientId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OidcClientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OidcClientHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<OidcClientHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<OidcClientHistoryRecordWhereInput>>>;
  clientId?: Maybe<Scalars['String']>;
  clientId_not?: Maybe<Scalars['String']>;
  clientId_contains?: Maybe<Scalars['String']>;
  clientId_not_contains?: Maybe<Scalars['String']>;
  clientId_starts_with?: Maybe<Scalars['String']>;
  clientId_not_starts_with?: Maybe<Scalars['String']>;
  clientId_ends_with?: Maybe<Scalars['String']>;
  clientId_not_ends_with?: Maybe<Scalars['String']>;
  clientId_i?: Maybe<Scalars['String']>;
  clientId_not_i?: Maybe<Scalars['String']>;
  clientId_contains_i?: Maybe<Scalars['String']>;
  clientId_not_contains_i?: Maybe<Scalars['String']>;
  clientId_starts_with_i?: Maybe<Scalars['String']>;
  clientId_not_starts_with_i?: Maybe<Scalars['String']>;
  clientId_ends_with_i?: Maybe<Scalars['String']>;
  clientId_not_ends_with_i?: Maybe<Scalars['String']>;
  clientId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payload?: Maybe<Scalars['JSON']>;
  payload_not?: Maybe<Scalars['JSON']>;
  payload_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  payload_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEnabled_not?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  expiresAt?: Maybe<Scalars['String']>;
  expiresAt_not?: Maybe<Scalars['String']>;
  expiresAt_lt?: Maybe<Scalars['String']>;
  expiresAt_lte?: Maybe<Scalars['String']>;
  expiresAt_gt?: Maybe<Scalars['String']>;
  expiresAt_gte?: Maybe<Scalars['String']>;
  expiresAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiresAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<OidcClientHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<OidcClientHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<OidcClientHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<OidcClientHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OidcClientHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OidcClientHistoryRecordsCreateInput = {
  data?: Maybe<OidcClientHistoryRecordCreateInput>;
};

export type OidcClientHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OidcClientHistoryRecordUpdateInput>;
};

export type OidcClientUpdateInput = {
  clientId?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  importId?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
};

export type OidcClientWhereInput = {
  AND?: Maybe<Array<Maybe<OidcClientWhereInput>>>;
  OR?: Maybe<Array<Maybe<OidcClientWhereInput>>>;
  clientId?: Maybe<Scalars['String']>;
  clientId_not?: Maybe<Scalars['String']>;
  clientId_contains?: Maybe<Scalars['String']>;
  clientId_not_contains?: Maybe<Scalars['String']>;
  clientId_starts_with?: Maybe<Scalars['String']>;
  clientId_not_starts_with?: Maybe<Scalars['String']>;
  clientId_ends_with?: Maybe<Scalars['String']>;
  clientId_not_ends_with?: Maybe<Scalars['String']>;
  clientId_i?: Maybe<Scalars['String']>;
  clientId_not_i?: Maybe<Scalars['String']>;
  clientId_contains_i?: Maybe<Scalars['String']>;
  clientId_not_contains_i?: Maybe<Scalars['String']>;
  clientId_starts_with_i?: Maybe<Scalars['String']>;
  clientId_not_starts_with_i?: Maybe<Scalars['String']>;
  clientId_ends_with_i?: Maybe<Scalars['String']>;
  clientId_not_ends_with_i?: Maybe<Scalars['String']>;
  clientId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payload?: Maybe<Scalars['JSON']>;
  payload_not?: Maybe<Scalars['JSON']>;
  payload_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  payload_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  isEnabled_not?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers?: Maybe<Scalars['Boolean']>;
  canAuthorizeSuperUsers_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  expiresAt?: Maybe<Scalars['String']>;
  expiresAt_not?: Maybe<Scalars['String']>;
  expiresAt_lt?: Maybe<Scalars['String']>;
  expiresAt_lte?: Maybe<Scalars['String']>;
  expiresAt_gt?: Maybe<Scalars['String']>;
  expiresAt_gte?: Maybe<Scalars['String']>;
  expiresAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  expiresAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OidcClientWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OidcClientsCreateInput = {
  data?: Maybe<OidcClientCreateInput>;
};

export type OidcClientsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OidcClientUpdateInput>;
};

/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type Organization = {
  __typename?: 'Organization';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Organization List config, or
   *  2. As an alias to the field set on 'labelField' in the Organization List config, or
   *  3. As an alias to a 'name' field on the Organization List (if one exists), or
   *  4. As an alias to the 'id' field on the Organization List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Country level specific  */
  country?: Maybe<OrganizationCountryType>;
  /**  Customer-friendly name  */
  name?: Maybe<Scalars['String']>;
  /**  Type of organization. Organizations with different types see slightly different interfaces. In addition, some of the logic depends on this field: 1. Residents can be connected to only "MANAGING_COMPANY" organization2. OrganizationLink cannot be created if parent organization is not "HOLDING"  */
  type?: Maybe<OrganizationTypeType>;
  /**  Taxpayer identification number. Every country has its own identification. Examples: INN for Russia, IIN for Kazakhstan and so on  */
  tin?: Maybe<Scalars['String']>;
  /**  Customer-friendly description. Friendly text for employee and resident users  */
  description?: Maybe<Scalars['String']>;
  /**  Customer-friendly avatar  */
  avatar?: Maybe<File>;
  /**  Organization metadata. Depends on country level specificExample of data key: `kpp`  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Normalized organization phone in E.164 format without spaces  */
  phone?: Maybe<Scalars['String']>;
  /**
   *  Numeric identifier assigned to a specific line in software for calling.
   * Used when outgoing call before the number to be called.
   * For example phoneNumberPrefix = 01, then the result phone number to be called = 01{phone number}.
   */
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  employees: Array<OrganizationEmployee>;
  _employeesMeta?: Maybe<_QueryMeta>;
  relatedOrganizations: Array<OrganizationLink>;
  _relatedOrganizationsMeta?: Maybe<_QueryMeta>;
  /**  Graph of possible transitions for statuses. If there is no transition in this graph, it is impossible to change status if the user in the role has the right to do so.  */
  statusTransitions?: Maybe<Scalars['JSON']>;
  /**  Default employee role status transitions map which will be used as fallback for status transition validationif user dont have OrganizationEmployeeRole  */
  defaultEmployeeRoleStatusTransitions?: Maybe<Scalars['JSON']>;
  /**  External provider for organization  */
  importRemoteSystem?: Maybe<Scalars['String']>;
  /**  External system organization id. Used for integrations  */
  importId?: Maybe<Scalars['String']>;
  /**  List of the organization's features connected by a particular integration  */
  features: Array<OrganizationFeature>;
  /**
   *  @deprecated
   * Indicates that organization is real.
   * This is a service field, you should not implement logic using it.
   */
  isApproved?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type OrganizationEmployeesArgs = {
  where?: Maybe<OrganizationEmployeeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type Organization_EmployeesMetaArgs = {
  where?: Maybe<OrganizationEmployeeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type OrganizationRelatedOrganizationsArgs = {
  where?: Maybe<OrganizationLinkWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationLinksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


/**  B2B customer of the service, a legal entity or an association of legal entities (holding/group)  */
export type Organization_RelatedOrganizationsMetaArgs = {
  where?: Maybe<OrganizationLinkWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationLinksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export enum OrganizationCountryType {
  En = 'en',
  Ru = 'ru'
}

export type OrganizationCreateInput = {
  country?: Maybe<OrganizationCountryType>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<OrganizationTypeType>;
  tin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  employees?: Maybe<OrganizationEmployeeRelateToManyInput>;
  relatedOrganizations?: Maybe<OrganizationLinkRelateToManyInput>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  features?: Maybe<Array<OrganizationFeature>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  B2B customer employees. For invite employee should use inviteNewOrganizationEmployee and reInviteOrganizationEmployee  */
export type OrganizationEmployee = {
  __typename?: 'OrganizationEmployee';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployee List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployee List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployee List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployee List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  If user exists => invite is matched by email/phone (user can reject or accept it)  */
  user?: Maybe<User>;
  /**  Secret invite code (used for accept invite verification)  */
  inviteCode?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<OrganizationEmployeeRole>;
  /**  Free-form description of the employee's position  */
  position?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  /**  Employee is blocked status, used in permissions functions, isBlocked has Free-form description of the employee's position over all permissions  */
  isBlocked?: Maybe<Scalars['Boolean']>;
  /**  True if employee has all specializations  */
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type OrganizationEmployeeCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  inviteCode?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<OrganizationEmployeeRoleRelateToOneInput>;
  position?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type OrganizationEmployeeHistoryRecord = {
  __typename?: 'OrganizationEmployeeHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  inviteCode?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  inviteCode?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum OrganizationEmployeeHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type OrganizationEmployeeHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  inviteCode?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  inviteCode?: Maybe<Scalars['ID']>;
  inviteCode_not?: Maybe<Scalars['ID']>;
  inviteCode_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inviteCode_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
  role_not?: Maybe<Scalars['String']>;
  role_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  position_i?: Maybe<Scalars['String']>;
  position_not_i?: Maybe<Scalars['String']>;
  position_contains_i?: Maybe<Scalars['String']>;
  position_not_contains_i?: Maybe<Scalars['String']>;
  position_starts_with_i?: Maybe<Scalars['String']>;
  position_not_starts_with_i?: Maybe<Scalars['String']>;
  position_ends_with_i?: Maybe<Scalars['String']>;
  position_not_ends_with_i?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  position_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isAccepted_not?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isRejected_not?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBlocked_not?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationEmployeeHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationEmployeeHistoryRecordsCreateInput = {
  data?: Maybe<OrganizationEmployeeHistoryRecordCreateInput>;
};

export type OrganizationEmployeeHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeHistoryRecordUpdateInput>;
};

export type OrganizationEmployeeRelateToManyInput = {
  create?: Maybe<Array<Maybe<OrganizationEmployeeCreateInput>>>;
  connect?: Maybe<Array<Maybe<OrganizationEmployeeWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrganizationEmployeeWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type OrganizationEmployeeRelateToOneInput = {
  create?: Maybe<OrganizationEmployeeCreateInput>;
  connect?: Maybe<OrganizationEmployeeWhereUniqueInput>;
  disconnect?: Maybe<OrganizationEmployeeWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  Employee role name and access permissions  */
export type OrganizationEmployeeRole = {
  __typename?: 'OrganizationEmployeeRole';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeRole List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeRole List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeRole List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeRole List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**
   *  (Read-only) Indicates whether the role was added by default when the organization was created.
   * Such roles cannot be deleted and their name, description and “ticketVisibilityType” cannot be changed.
   */
  isDefault?: Maybe<Scalars['Boolean']>;
  /**  (Read-only) Indicates whether the role can be edited  */
  isEditable?: Maybe<Scalars['Boolean']>;
  /**  Role name. Cannot be changed for default roles  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  /**  Role description. Cannot be changed for default roles  */
  description?: Maybe<Scalars['String']>;
  descriptionNonLocalized?: Maybe<Scalars['String']>;
  /**  Employee status transitions map  */
  statusTransitions?: Maybe<Scalars['JSON']>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  /**  Allows employees with this role to be assigned to tickets as responsible  */
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  /**  Allows employees with this role to be assigned to tickets as executor  */
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  /**
   *  Which tickets the employee sees:
   * 1) organization - sees all tickets in the organization.
   * 2) property - Sees tickets in PropertyScope that have this employee
   * 3) propertyAndSpecialization - Sees tickets by employee specialization + PropertyScope
   * 4) assigned - sees only those tickets in which he is the executor or responsible
   */
  ticketVisibilityType?: Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeRoleCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type OrganizationEmployeeRoleHistoryRecord = {
  __typename?: 'OrganizationEmployeeRoleHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeRoleHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeRoleHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeRoleHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeRoleHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<Scalars['String']>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeRoleHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<Scalars['String']>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum OrganizationEmployeeRoleHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type OrganizationEmployeeRoleHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<Scalars['String']>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeRoleHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_not?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isEditable_not?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canManageOrganization_not?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canReadEmployees_not?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees_not?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageRoles_not?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canManageIntegrations_not?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canReadProperties_not?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties_not?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canReadDocuments_not?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments_not?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canReadTickets_not?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets_not?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters_not?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings_not?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canReadContacts_not?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts_not?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageContactRoles_not?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments_not?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canShareTickets_not?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts_not?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts_not?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadPayments_not?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees_not?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible_not?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor_not?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints_not?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<Scalars['String']>;
  ticketVisibilityType_not?: Maybe<Scalars['String']>;
  ticketVisibilityType_contains?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_contains?: Maybe<Scalars['String']>;
  ticketVisibilityType_starts_with?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_starts_with?: Maybe<Scalars['String']>;
  ticketVisibilityType_ends_with?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_ends_with?: Maybe<Scalars['String']>;
  ticketVisibilityType_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_contains_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_contains_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_starts_with_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_starts_with_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_ends_with_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_not_ends_with_i?: Maybe<Scalars['String']>;
  ticketVisibilityType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticketVisibilityType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManagePropertyScopes_not?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts_not?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks_not?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts_not?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts_not?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts_not?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions_not?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports_not?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canReadIncidents_not?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents_not?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canReadNewsItems_not?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems_not?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates_not?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canManageCallRecords_not?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords_not?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs_not?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps_not?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadAnalytics_not?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canReadInvoices_not?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices_not?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMarketItems_not?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems_not?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadMeters_not?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadSettings_not?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadExternalReports_not?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadServices_not?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadCallRecords_not?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices_not?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices_not?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes_not?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes_not?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices_not?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canReadMarketplace_not?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace_not?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canReadTour_not?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canManageTour_not?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting_not?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting_not?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationEmployeeRoleHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationEmployeeRoleHistoryRecordsCreateInput = {
  data?: Maybe<OrganizationEmployeeRoleHistoryRecordCreateInput>;
};

export type OrganizationEmployeeRoleHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeRoleHistoryRecordUpdateInput>;
};

export type OrganizationEmployeeRoleRelateToOneInput = {
  create?: Maybe<OrganizationEmployeeRoleCreateInput>;
  connect?: Maybe<OrganizationEmployeeRoleWhereUniqueInput>;
  disconnect?: Maybe<OrganizationEmployeeRoleWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum OrganizationEmployeeRoleTicketVisibilityTypeType {
  Organization = 'organization',
  Property = 'property',
  PropertyAndSpecialization = 'propertyAndSpecialization',
  Assigned = 'assigned'
}

export type OrganizationEmployeeRoleUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeRoleWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationEmployeeRoleWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationEmployeeRoleWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isDefault_not?: Maybe<Scalars['Boolean']>;
  isEditable?: Maybe<Scalars['Boolean']>;
  isEditable_not?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canManageOrganization?: Maybe<Scalars['Boolean']>;
  canManageOrganization_not?: Maybe<Scalars['Boolean']>;
  canReadEmployees?: Maybe<Scalars['Boolean']>;
  canReadEmployees_not?: Maybe<Scalars['Boolean']>;
  canManageEmployees?: Maybe<Scalars['Boolean']>;
  canManageEmployees_not?: Maybe<Scalars['Boolean']>;
  canManageRoles?: Maybe<Scalars['Boolean']>;
  canManageRoles_not?: Maybe<Scalars['Boolean']>;
  canManageIntegrations?: Maybe<Scalars['Boolean']>;
  canManageIntegrations_not?: Maybe<Scalars['Boolean']>;
  canReadProperties?: Maybe<Scalars['Boolean']>;
  canReadProperties_not?: Maybe<Scalars['Boolean']>;
  canManageProperties?: Maybe<Scalars['Boolean']>;
  canManageProperties_not?: Maybe<Scalars['Boolean']>;
  canReadDocuments?: Maybe<Scalars['Boolean']>;
  canReadDocuments_not?: Maybe<Scalars['Boolean']>;
  canManageDocuments?: Maybe<Scalars['Boolean']>;
  canManageDocuments_not?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canReadTickets_not?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets_not?: Maybe<Scalars['Boolean']>;
  canManageMeters?: Maybe<Scalars['Boolean']>;
  canManageMeters_not?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings?: Maybe<Scalars['Boolean']>;
  canManageMeterReadings_not?: Maybe<Scalars['Boolean']>;
  canReadContacts?: Maybe<Scalars['Boolean']>;
  canReadContacts_not?: Maybe<Scalars['Boolean']>;
  canManageContacts?: Maybe<Scalars['Boolean']>;
  canManageContacts_not?: Maybe<Scalars['Boolean']>;
  canManageContactRoles?: Maybe<Scalars['Boolean']>;
  canManageContactRoles_not?: Maybe<Scalars['Boolean']>;
  canManageTicketComments?: Maybe<Scalars['Boolean']>;
  canManageTicketComments_not?: Maybe<Scalars['Boolean']>;
  canShareTickets?: Maybe<Scalars['Boolean']>;
  canShareTickets_not?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts_not?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts?: Maybe<Scalars['Boolean']>;
  canImportBillingReceipts_not?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadPayments_not?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees?: Maybe<Scalars['Boolean']>;
  canInviteNewOrganizationEmployees_not?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsResponsible_not?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor?: Maybe<Scalars['Boolean']>;
  canBeAssignedAsExecutor_not?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints?: Maybe<Scalars['Boolean']>;
  canManageTicketPropertyHints_not?: Maybe<Scalars['Boolean']>;
  ticketVisibilityType?: Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>;
  ticketVisibilityType_not?: Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>;
  ticketVisibilityType_in?: Maybe<Array<Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>>>;
  ticketVisibilityType_not_in?: Maybe<Array<Maybe<OrganizationEmployeeRoleTicketVisibilityTypeType>>>;
  canManagePropertyScopes?: Maybe<Scalars['Boolean']>;
  canManagePropertyScopes_not?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankAccounts_not?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReportTasks_not?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationAccountContexts_not?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts?: Maybe<Scalars['Boolean']>;
  canManageBankIntegrationOrganizationContexts_not?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts?: Maybe<Scalars['Boolean']>;
  canManageBankContractorAccounts_not?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions?: Maybe<Scalars['Boolean']>;
  canManageBankTransactions_not?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports?: Maybe<Scalars['Boolean']>;
  canManageBankAccountReports_not?: Maybe<Scalars['Boolean']>;
  canReadIncidents?: Maybe<Scalars['Boolean']>;
  canReadIncidents_not?: Maybe<Scalars['Boolean']>;
  canManageIncidents?: Maybe<Scalars['Boolean']>;
  canManageIncidents_not?: Maybe<Scalars['Boolean']>;
  canReadNewsItems?: Maybe<Scalars['Boolean']>;
  canReadNewsItems_not?: Maybe<Scalars['Boolean']>;
  canManageNewsItems?: Maybe<Scalars['Boolean']>;
  canManageNewsItems_not?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates?: Maybe<Scalars['Boolean']>;
  canManageNewsItemTemplates_not?: Maybe<Scalars['Boolean']>;
  canManageCallRecords?: Maybe<Scalars['Boolean']>;
  canManageCallRecords_not?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords?: Maybe<Scalars['Boolean']>;
  canDownloadCallRecords_not?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs?: Maybe<Scalars['Boolean']>;
  canManageMobileFeatureConfigs_not?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps_not?: Maybe<Scalars['Boolean']>;
  canReadAnalytics?: Maybe<Scalars['Boolean']>;
  canReadAnalytics_not?: Maybe<Scalars['Boolean']>;
  canReadInvoices?: Maybe<Scalars['Boolean']>;
  canReadInvoices_not?: Maybe<Scalars['Boolean']>;
  canManageInvoices?: Maybe<Scalars['Boolean']>;
  canManageInvoices_not?: Maybe<Scalars['Boolean']>;
  canReadMarketItems?: Maybe<Scalars['Boolean']>;
  canReadMarketItems_not?: Maybe<Scalars['Boolean']>;
  canManageMarketItems?: Maybe<Scalars['Boolean']>;
  canManageMarketItems_not?: Maybe<Scalars['Boolean']>;
  canReadMeters?: Maybe<Scalars['Boolean']>;
  canReadMeters_not?: Maybe<Scalars['Boolean']>;
  canReadSettings?: Maybe<Scalars['Boolean']>;
  canReadSettings_not?: Maybe<Scalars['Boolean']>;
  canReadExternalReports?: Maybe<Scalars['Boolean']>;
  canReadExternalReports_not?: Maybe<Scalars['Boolean']>;
  canReadServices?: Maybe<Scalars['Boolean']>;
  canReadServices_not?: Maybe<Scalars['Boolean']>;
  canReadCallRecords?: Maybe<Scalars['Boolean']>;
  canReadCallRecords_not?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canReadMarketItemPrices_not?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices?: Maybe<Scalars['Boolean']>;
  canManageMarketItemPrices_not?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canReadMarketPriceScopes_not?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes?: Maybe<Scalars['Boolean']>;
  canManageMarketPriceScopes_not?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices?: Maybe<Scalars['Boolean']>;
  canReadPaymentsWithInvoices_not?: Maybe<Scalars['Boolean']>;
  canReadMarketplace?: Maybe<Scalars['Boolean']>;
  canReadMarketplace_not?: Maybe<Scalars['Boolean']>;
  canManageMarketplace?: Maybe<Scalars['Boolean']>;
  canManageMarketplace_not?: Maybe<Scalars['Boolean']>;
  canReadTour?: Maybe<Scalars['Boolean']>;
  canReadTour_not?: Maybe<Scalars['Boolean']>;
  canManageTour?: Maybe<Scalars['Boolean']>;
  canManageTour_not?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting?: Maybe<Scalars['Boolean']>;
  canReadMarketSetting_not?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting?: Maybe<Scalars['Boolean']>;
  canManageMarketSetting_not?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationEmployeeRoleWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationEmployeeRolesCreateInput = {
  data?: Maybe<OrganizationEmployeeRoleCreateInput>;
};

export type OrganizationEmployeeRolesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeRoleUpdateInput>;
};

/**  A work category, that employee can perform. Used to limit the visibility of tickets for employees with "propertyAndSpecialization" ticketVisibilityType  */
export type OrganizationEmployeeSpecialization = {
  __typename?: 'OrganizationEmployeeSpecialization';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeSpecialization List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeSpecialization List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeSpecialization List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeSpecialization List.
   */
  _label_?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployee>;
  /**  Specific work category, that this employee can perform  */
  specialization?: Maybe<TicketCategoryClassifier>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type OrganizationEmployeeSpecializationCreateInput = {
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  specialization?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type OrganizationEmployeeSpecializationHistoryRecord = {
  __typename?: 'OrganizationEmployeeSpecializationHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationEmployeeSpecializationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationEmployeeSpecializationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationEmployeeSpecializationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationEmployeeSpecializationHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeSpecializationHistoryRecordCreateInput = {
  employee?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum OrganizationEmployeeSpecializationHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type OrganizationEmployeeSpecializationHistoryRecordUpdateInput = {
  employee?: Maybe<Scalars['String']>;
  specialization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationEmployeeSpecializationHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>>>;
  employee?: Maybe<Scalars['String']>;
  employee_not?: Maybe<Scalars['String']>;
  employee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  specialization?: Maybe<Scalars['String']>;
  specialization_not?: Maybe<Scalars['String']>;
  specialization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  specialization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationEmployeeSpecializationHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationEmployeeSpecializationHistoryRecordsCreateInput = {
  data?: Maybe<OrganizationEmployeeSpecializationHistoryRecordCreateInput>;
};

export type OrganizationEmployeeSpecializationHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeSpecializationHistoryRecordUpdateInput>;
};

export type OrganizationEmployeeSpecializationUpdateInput = {
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  specialization?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type OrganizationEmployeeSpecializationWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationWhereInput>>>;
  employee?: Maybe<OrganizationEmployeeWhereInput>;
  employee_is_null?: Maybe<Scalars['Boolean']>;
  specialization?: Maybe<TicketCategoryClassifierWhereInput>;
  specialization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type OrganizationEmployeeSpecializationWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationEmployeeSpecializationsCreateInput = {
  data?: Maybe<OrganizationEmployeeSpecializationCreateInput>;
};

export type OrganizationEmployeeSpecializationsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeSpecializationUpdateInput>;
};

export type OrganizationEmployeeUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  inviteCode?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<OrganizationEmployeeRoleRelateToOneInput>;
  position?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type OrganizationEmployeeWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationEmployeeWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationEmployeeWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  inviteCode?: Maybe<Scalars['ID']>;
  inviteCode_not?: Maybe<Scalars['ID']>;
  inviteCode_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  inviteCode_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<OrganizationEmployeeRoleWhereInput>;
  role_is_null?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['String']>;
  position_not?: Maybe<Scalars['String']>;
  position_contains?: Maybe<Scalars['String']>;
  position_not_contains?: Maybe<Scalars['String']>;
  position_starts_with?: Maybe<Scalars['String']>;
  position_not_starts_with?: Maybe<Scalars['String']>;
  position_ends_with?: Maybe<Scalars['String']>;
  position_not_ends_with?: Maybe<Scalars['String']>;
  position_i?: Maybe<Scalars['String']>;
  position_not_i?: Maybe<Scalars['String']>;
  position_contains_i?: Maybe<Scalars['String']>;
  position_not_contains_i?: Maybe<Scalars['String']>;
  position_starts_with_i?: Maybe<Scalars['String']>;
  position_not_starts_with_i?: Maybe<Scalars['String']>;
  position_ends_with_i?: Maybe<Scalars['String']>;
  position_not_ends_with_i?: Maybe<Scalars['String']>;
  position_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  position_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isAccepted_not?: Maybe<Scalars['Boolean']>;
  isRejected?: Maybe<Scalars['Boolean']>;
  isRejected_not?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isBlocked_not?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations?: Maybe<Scalars['Boolean']>;
  hasAllSpecializations_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type OrganizationEmployeeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationEmployeesCreateInput = {
  data?: Maybe<OrganizationEmployeeCreateInput>;
};

export type OrganizationEmployeesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationEmployeeUpdateInput>;
};

export enum OrganizationFeature {
  Spp = 'SPP'
}

export type OrganizationFeatures = {
  __typename?: 'OrganizationFeatures';
  hasBillingData: Scalars['Boolean'];
  hasMeters: Scalars['Boolean'];
};

/**  A keystone list  */
export type OrganizationHistoryRecord = {
  __typename?: 'OrganizationHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  avatar?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['JSON']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationHistoryRecordCreateInput = {
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  avatar?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['JSON']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum OrganizationHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type OrganizationHistoryRecordUpdateInput = {
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JSON']>;
  avatar?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  features?: Maybe<Scalars['JSON']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationHistoryRecordWhereInput>>>;
  country?: Maybe<Scalars['String']>;
  country_not?: Maybe<Scalars['String']>;
  country_contains?: Maybe<Scalars['String']>;
  country_not_contains?: Maybe<Scalars['String']>;
  country_starts_with?: Maybe<Scalars['String']>;
  country_not_starts_with?: Maybe<Scalars['String']>;
  country_ends_with?: Maybe<Scalars['String']>;
  country_not_ends_with?: Maybe<Scalars['String']>;
  country_i?: Maybe<Scalars['String']>;
  country_not_i?: Maybe<Scalars['String']>;
  country_contains_i?: Maybe<Scalars['String']>;
  country_not_contains_i?: Maybe<Scalars['String']>;
  country_starts_with_i?: Maybe<Scalars['String']>;
  country_not_starts_with_i?: Maybe<Scalars['String']>;
  country_ends_with_i?: Maybe<Scalars['String']>;
  country_not_ends_with_i?: Maybe<Scalars['String']>;
  country_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  country_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['JSON']>;
  description_not?: Maybe<Scalars['JSON']>;
  description_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  avatar?: Maybe<Scalars['JSON']>;
  avatar_not?: Maybe<Scalars['JSON']>;
  avatar_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  avatar_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not?: Maybe<Scalars['String']>;
  phoneNumberPrefix_contains?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_contains?: Maybe<Scalars['String']>;
  phoneNumberPrefix_starts_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_starts_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_ends_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_ends_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_contains_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_contains_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_starts_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_starts_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_ends_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_ends_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumberPrefix_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  features?: Maybe<Scalars['JSON']>;
  features_not?: Maybe<Scalars['JSON']>;
  features_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  features_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isApproved_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<OrganizationHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<OrganizationHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<OrganizationHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<OrganizationHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationHistoryRecordsCreateInput = {
  data?: Maybe<OrganizationHistoryRecordCreateInput>;
};

export type OrganizationHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationHistoryRecordUpdateInput>;
};

/**  Relationship between Organization that allows an employee of a "parent" (or "holding", you name it) Organization to interact as an employee of another "child" Organizations. If the relationship is created via OrganizationLink between "parent" and "child" Organization, a user does not need to be employee in all "child" Organization to have the abilities, required to perform some actions there. It is enough to be an employee in just one "parent" Organization. "Child" and "parent" means logical connection, not a structural from real world. Example use case of OrganizationLink is to be able to read Ticket from many organization when current user is an employee of Organization that act as a supervisor, like call center, that servers many "client" Organizations. Similar use cases are implemented for abilities to read MeterReading, ExternalReport, Contact, Division, Property, Ticket, TicketComment and other entities.  */
export type OrganizationLink = {
  __typename?: 'OrganizationLink';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationLink List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationLink List config, or
   *  3. As an alias to a 'name' field on the OrganizationLink List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationLink List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  "Parent" Organization that gains its employee an access to all its "child" Organizations  */
  from?: Maybe<Organization>;
  /**  "Child" Organization that is getting accessible by employee of "parent" Organization, also, some entities of "Child" Organization are becoming accessible for CRUD operations.  */
  to?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type OrganizationLinkCreateInput = {
  from?: Maybe<OrganizationRelateToOneInput>;
  to?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type OrganizationLinkHistoryRecord = {
  __typename?: 'OrganizationLinkHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the OrganizationLinkHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the OrganizationLinkHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the OrganizationLinkHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the OrganizationLinkHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationLinkHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationLinkHistoryRecordCreateInput = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationLinkHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum OrganizationLinkHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type OrganizationLinkHistoryRecordUpdateInput = {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<OrganizationLinkHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type OrganizationLinkHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationLinkHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationLinkHistoryRecordWhereInput>>>;
  from?: Maybe<Scalars['String']>;
  from_not?: Maybe<Scalars['String']>;
  from_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  from_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  to?: Maybe<Scalars['String']>;
  to_not?: Maybe<Scalars['String']>;
  to_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  to_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<OrganizationLinkHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<OrganizationLinkHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<OrganizationLinkHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<OrganizationLinkHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationLinkHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationLinkHistoryRecordsCreateInput = {
  data?: Maybe<OrganizationLinkHistoryRecordCreateInput>;
};

export type OrganizationLinkHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationLinkHistoryRecordUpdateInput>;
};

export type OrganizationLinkRelateToManyInput = {
  create?: Maybe<Array<Maybe<OrganizationLinkCreateInput>>>;
  connect?: Maybe<Array<Maybe<OrganizationLinkWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrganizationLinkWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type OrganizationLinkUpdateInput = {
  from?: Maybe<OrganizationRelateToOneInput>;
  to?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type OrganizationLinkWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationLinkWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationLinkWhereInput>>>;
  from?: Maybe<OrganizationWhereInput>;
  from_is_null?: Maybe<Scalars['Boolean']>;
  to?: Maybe<OrganizationWhereInput>;
  to_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type OrganizationLinkWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationLinksCreateInput = {
  data?: Maybe<OrganizationLinkCreateInput>;
};

export type OrganizationLinksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationLinkUpdateInput>;
};

export type OrganizationRelateToOneInput = {
  create?: Maybe<OrganizationCreateInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  disconnect?: Maybe<OrganizationWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum OrganizationType {
  ManagingCompany = 'MANAGING_COMPANY',
  Holding = 'HOLDING',
  ServiceProvider = 'SERVICE_PROVIDER'
}

export enum OrganizationTypeType {
  ManagingCompany = 'MANAGING_COMPANY',
  Holding = 'HOLDING',
  ServiceProvider = 'SERVICE_PROVIDER'
}

export type OrganizationUpdateInput = {
  country?: Maybe<OrganizationCountryType>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<OrganizationTypeType>;
  tin?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  employees?: Maybe<OrganizationEmployeeRelateToManyInput>;
  relatedOrganizations?: Maybe<OrganizationLinkRelateToManyInput>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  features?: Maybe<Array<OrganizationFeature>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type OrganizationWhereInput = {
  AND?: Maybe<Array<Maybe<OrganizationWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrganizationWhereInput>>>;
  country?: Maybe<OrganizationCountryType>;
  country_not?: Maybe<OrganizationCountryType>;
  country_in?: Maybe<Array<Maybe<OrganizationCountryType>>>;
  country_not_in?: Maybe<Array<Maybe<OrganizationCountryType>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<OrganizationTypeType>;
  type_not?: Maybe<OrganizationTypeType>;
  type_in?: Maybe<Array<Maybe<OrganizationTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<OrganizationTypeType>>>;
  tin?: Maybe<Scalars['String']>;
  tin_not?: Maybe<Scalars['String']>;
  tin_contains?: Maybe<Scalars['String']>;
  tin_not_contains?: Maybe<Scalars['String']>;
  tin_starts_with?: Maybe<Scalars['String']>;
  tin_not_starts_with?: Maybe<Scalars['String']>;
  tin_ends_with?: Maybe<Scalars['String']>;
  tin_not_ends_with?: Maybe<Scalars['String']>;
  tin_i?: Maybe<Scalars['String']>;
  tin_not_i?: Maybe<Scalars['String']>;
  tin_contains_i?: Maybe<Scalars['String']>;
  tin_not_contains_i?: Maybe<Scalars['String']>;
  tin_starts_with_i?: Maybe<Scalars['String']>;
  tin_not_starts_with_i?: Maybe<Scalars['String']>;
  tin_ends_with_i?: Maybe<Scalars['String']>;
  tin_not_ends_with_i?: Maybe<Scalars['String']>;
  tin_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tin_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  avatar?: Maybe<Scalars['String']>;
  avatar_not?: Maybe<Scalars['String']>;
  avatar_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  avatar_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumberPrefix?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not?: Maybe<Scalars['String']>;
  phoneNumberPrefix_contains?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_contains?: Maybe<Scalars['String']>;
  phoneNumberPrefix_starts_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_starts_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_ends_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_ends_with?: Maybe<Scalars['String']>;
  phoneNumberPrefix_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_contains_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_contains_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_starts_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_starts_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_ends_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_not_ends_with_i?: Maybe<Scalars['String']>;
  phoneNumberPrefix_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phoneNumberPrefix_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**  condition must be true for all nodes  */
  employees_every?: Maybe<OrganizationEmployeeWhereInput>;
  /**  condition must be true for at least 1 node  */
  employees_some?: Maybe<OrganizationEmployeeWhereInput>;
  /**  condition must be false for all nodes  */
  employees_none?: Maybe<OrganizationEmployeeWhereInput>;
  /**  condition must be true for all nodes  */
  relatedOrganizations_every?: Maybe<OrganizationLinkWhereInput>;
  /**  condition must be true for at least 1 node  */
  relatedOrganizations_some?: Maybe<OrganizationLinkWhereInput>;
  /**  condition must be false for all nodes  */
  relatedOrganizations_none?: Maybe<OrganizationLinkWhereInput>;
  importRemoteSystem?: Maybe<Scalars['String']>;
  importRemoteSystem_not?: Maybe<Scalars['String']>;
  importRemoteSystem_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with?: Maybe<Scalars['String']>;
  importRemoteSystem_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_i?: Maybe<Scalars['String']>;
  importRemoteSystem_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_contains_i?: Maybe<Scalars['String']>;
  importRemoteSystem_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_starts_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_not_ends_with_i?: Maybe<Scalars['String']>;
  importRemoteSystem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importRemoteSystem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  features?: Maybe<Array<OrganizationFeature>>;
  features_not?: Maybe<Array<OrganizationFeature>>;
  features_in?: Maybe<Array<Maybe<Array<OrganizationFeature>>>>;
  features_not_in?: Maybe<Array<Maybe<Array<OrganizationFeature>>>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isApproved_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type OrganizationWhereUniqueInput = {
  id: Scalars['ID'];
};

export type OrganizationsCreateInput = {
  data?: Maybe<OrganizationCreateInput>;
};

export type OrganizationsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<OrganizationUpdateInput>;
};

export enum OverviewDashboardEntities {
  TicketByDay = 'ticketByDay',
  TicketByProperty = 'ticketByProperty',
  TicketByCategory = 'ticketByCategory',
  TicketByExecutor = 'ticketByExecutor',
  TicketQualityControlValue = 'ticketQualityControlValue',
  Payment = 'payment',
  Receipt = 'receipt',
  Resident = 'resident',
  Property = 'property',
  Incident = 'incident'
}

export type OverviewData = {
  __typename?: 'OverviewData';
  ticketByProperty?: Maybe<TicketOverviewResult>;
  ticketByDay?: Maybe<TicketOverviewResult>;
  ticketByCategory?: Maybe<TicketOverviewResult>;
  ticketByExecutor?: Maybe<TicketOverviewResult>;
  ticketQualityControlValue?: Maybe<TicketOverviewResult>;
  payment?: Maybe<PaymentOverviewResult>;
  receipt?: Maybe<ReceiptOverviewResult>;
  resident?: Maybe<ResidentOverviewResult>;
  property?: Maybe<PropertyOverviewResult>;
  incident?: Maybe<IncidentOverviewResult>;
};

/**  Information about completed transaction from user to a specific organization  */
export type Payment = {
  __typename?: 'Payment';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Payment List config, or
   *  2. As an alias to the field set on 'labelField' in the Payment List config, or
   *  3. As an alias to a 'name' field on the Payment List (if one exists), or
   *  4. As an alias to the 'id' field on the Payment List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Amount of money from MultiPayment.amountWithOutExplicitFee to pay for billing receipt  */
  amount?: Maybe<Scalars['String']>;
  /**  Amount of money which payer pays on top of initial "amount", which counts as fee for every service which is not housing and communal services  */
  explicitFee?: Maybe<Scalars['String']>;
  /**  Amount of money which payer pays on top of initial "amount", which counts as internal service charge for all payments from housing and communal services category  */
  explicitServiceCharge?: Maybe<Scalars['String']>;
  /**  Amount of money which recipient pays from initial amount for transaction  */
  implicitFee?: Maybe<Scalars['String']>;
  /**  The amount of money charged by our service for the provision of service after subtracting from it the shares of all participants in the process. Can be part of explicit fee, implicit fee or explicit service charge  */
  serviceFee?: Maybe<Scalars['String']>;
  /**  Code of currency in ISO-4217 format  */
  currencyCode?: Maybe<PaymentCurrencyCodeType>;
  /**  Time at which money was advanced to recipient's account  */
  advancedAt?: Maybe<Scalars['String']>;
  /**  Time at which the payment was made by client  */
  depositedDate?: Maybe<Scalars['String']>;
  /**  Time at which money was transfered to recipient's account  */
  transferDate?: Maybe<Scalars['String']>;
  /**  Payer's account number  */
  accountNumber?: Maybe<Scalars['String']>;
  /**  Period date: Generated on template <year>-<month>-01  */
  period?: Maybe<Scalars['String']>;
  /**  Purpose of payment. Mostly used as title such as "Payment by agreement №123"  */
  purpose?: Maybe<Scalars['String']>;
  /**  Link to a billing receipt that the user paid for. Can be null in cases of getting payments out of our system  */
  receipt?: Maybe<BillingReceipt>;
  /**  Frozen billing receipt, used to resolving conflicts  */
  frozenReceipt?: Maybe<Scalars['JSON']>;
  /**  Link to the invoice that user paid for.  */
  invoice?: Maybe<Invoice>;
  /**  Frozen invoice, used to resolving conflicts  */
  frozenInvoice?: Maybe<Scalars['JSON']>;
  /**  Link to a payment related MultiPayment. Required field to update, but initially created unlinked  */
  multiPayment?: Maybe<MultiPayment>;
  /**  Acquiring context, which used to link organization and acquiring integration and provide storage for organization-acquiring-specific settings / state  */
  context?: Maybe<AcquiringIntegrationContext>;
  /**  Direct link to organization, since acquiring context cannot be defined for some payments  */
  organization?: Maybe<Organization>;
  /**  Status of payment. Can be: "CREATED", "PROCESSING", "DONE", "ERROR", "WITHDRAWN"  */
  status?: Maybe<PaymentStatusType>;
  /**  Payment order. A directive to a bank from a bank account holder instructing the bank to make a payment or series of payments to a third party  */
  order?: Maybe<Scalars['String']>;
  /**  Bic of recipient organization, used for matching payments with receipts in case of multiple receipts per account + address  */
  recipientBic?: Maybe<Scalars['String']>;
  /**  Bank account number of recipient organization, used for matching payments with receipts in case of multiple receipts per account + address  */
  recipientBankAccount?: Maybe<Scalars['String']>;
  /**  Id of object in external service which represents current item. Mostly used for internal needs of integration services for matching our objects with theirs  */
  importId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PaymentCategory = {
  __typename?: 'PaymentCategory';
  id: Scalars['String'];
  categoryName: Scalars['String'];
  billingName: Scalars['String'];
  acquiringName: Scalars['String'];
};

export type PaymentCreateInput = {
  amount?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<PaymentCurrencyCodeType>;
  advancedAt?: Maybe<Scalars['String']>;
  depositedDate?: Maybe<Scalars['String']>;
  transferDate?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  receipt?: Maybe<BillingReceiptRelateToOneInput>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  invoice?: Maybe<InvoiceRelateToOneInput>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  multiPayment?: Maybe<MultiPaymentRelateToOneInput>;
  context?: Maybe<AcquiringIntegrationContextRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  status?: Maybe<PaymentStatusType>;
  order?: Maybe<Scalars['String']>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum PaymentCurrencyCodeType {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL'
}

export type PaymentGroupedCounter = {
  __typename?: 'PaymentGroupedCounter';
  count: Scalars['String'];
  sum: Scalars['String'];
  createdBy: Scalars['ID'];
  dayGroup: Scalars['String'];
};

/**  A keystone list  */
export type PaymentHistoryRecord = {
  __typename?: 'PaymentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PaymentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PaymentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PaymentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PaymentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  advancedAt?: Maybe<Scalars['String']>;
  depositedDate?: Maybe<Scalars['String']>;
  transferDate?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  invoice?: Maybe<Scalars['String']>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  multiPayment?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PaymentHistoryRecordCreateInput = {
  amount?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  advancedAt?: Maybe<Scalars['String']>;
  depositedDate?: Maybe<Scalars['String']>;
  transferDate?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  invoice?: Maybe<Scalars['String']>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  multiPayment?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PaymentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PaymentHistoryRecordUpdateInput = {
  amount?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  advancedAt?: Maybe<Scalars['String']>;
  depositedDate?: Maybe<Scalars['String']>;
  transferDate?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  receipt?: Maybe<Scalars['String']>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  invoice?: Maybe<Scalars['String']>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  multiPayment?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PaymentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PaymentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PaymentHistoryRecordWhereInput>>>;
  amount?: Maybe<Scalars['String']>;
  amount_not?: Maybe<Scalars['String']>;
  amount_lt?: Maybe<Scalars['String']>;
  amount_lte?: Maybe<Scalars['String']>;
  amount_gt?: Maybe<Scalars['String']>;
  amount_gte?: Maybe<Scalars['String']>;
  amount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitFee_not?: Maybe<Scalars['String']>;
  explicitFee_lt?: Maybe<Scalars['String']>;
  explicitFee_lte?: Maybe<Scalars['String']>;
  explicitFee_gt?: Maybe<Scalars['String']>;
  explicitFee_gte?: Maybe<Scalars['String']>;
  explicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  explicitServiceCharge_not?: Maybe<Scalars['String']>;
  explicitServiceCharge_lt?: Maybe<Scalars['String']>;
  explicitServiceCharge_lte?: Maybe<Scalars['String']>;
  explicitServiceCharge_gt?: Maybe<Scalars['String']>;
  explicitServiceCharge_gte?: Maybe<Scalars['String']>;
  explicitServiceCharge_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee?: Maybe<Scalars['String']>;
  implicitFee_not?: Maybe<Scalars['String']>;
  implicitFee_lt?: Maybe<Scalars['String']>;
  implicitFee_lte?: Maybe<Scalars['String']>;
  implicitFee_gt?: Maybe<Scalars['String']>;
  implicitFee_gte?: Maybe<Scalars['String']>;
  implicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee?: Maybe<Scalars['String']>;
  serviceFee_not?: Maybe<Scalars['String']>;
  serviceFee_lt?: Maybe<Scalars['String']>;
  serviceFee_lte?: Maybe<Scalars['String']>;
  serviceFee_gt?: Maybe<Scalars['String']>;
  serviceFee_gte?: Maybe<Scalars['String']>;
  serviceFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyCode_not?: Maybe<Scalars['String']>;
  currencyCode_contains?: Maybe<Scalars['String']>;
  currencyCode_not_contains?: Maybe<Scalars['String']>;
  currencyCode_starts_with?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with?: Maybe<Scalars['String']>;
  currencyCode_ends_with?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with?: Maybe<Scalars['String']>;
  currencyCode_i?: Maybe<Scalars['String']>;
  currencyCode_not_i?: Maybe<Scalars['String']>;
  currencyCode_contains_i?: Maybe<Scalars['String']>;
  currencyCode_not_contains_i?: Maybe<Scalars['String']>;
  currencyCode_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_starts_with_i?: Maybe<Scalars['String']>;
  currencyCode_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_not_ends_with_i?: Maybe<Scalars['String']>;
  currencyCode_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  advancedAt?: Maybe<Scalars['String']>;
  advancedAt_not?: Maybe<Scalars['String']>;
  advancedAt_lt?: Maybe<Scalars['String']>;
  advancedAt_lte?: Maybe<Scalars['String']>;
  advancedAt_gt?: Maybe<Scalars['String']>;
  advancedAt_gte?: Maybe<Scalars['String']>;
  advancedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  advancedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  depositedDate?: Maybe<Scalars['String']>;
  depositedDate_not?: Maybe<Scalars['String']>;
  depositedDate_lt?: Maybe<Scalars['String']>;
  depositedDate_lte?: Maybe<Scalars['String']>;
  depositedDate_gt?: Maybe<Scalars['String']>;
  depositedDate_gte?: Maybe<Scalars['String']>;
  depositedDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  depositedDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transferDate?: Maybe<Scalars['String']>;
  transferDate_not?: Maybe<Scalars['String']>;
  transferDate_lt?: Maybe<Scalars['String']>;
  transferDate_lte?: Maybe<Scalars['String']>;
  transferDate_gt?: Maybe<Scalars['String']>;
  transferDate_gte?: Maybe<Scalars['String']>;
  transferDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transferDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_lt?: Maybe<Scalars['String']>;
  period_lte?: Maybe<Scalars['String']>;
  period_gt?: Maybe<Scalars['String']>;
  period_gte?: Maybe<Scalars['String']>;
  period_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose?: Maybe<Scalars['String']>;
  purpose_not?: Maybe<Scalars['String']>;
  purpose_contains?: Maybe<Scalars['String']>;
  purpose_not_contains?: Maybe<Scalars['String']>;
  purpose_starts_with?: Maybe<Scalars['String']>;
  purpose_not_starts_with?: Maybe<Scalars['String']>;
  purpose_ends_with?: Maybe<Scalars['String']>;
  purpose_not_ends_with?: Maybe<Scalars['String']>;
  purpose_i?: Maybe<Scalars['String']>;
  purpose_not_i?: Maybe<Scalars['String']>;
  purpose_contains_i?: Maybe<Scalars['String']>;
  purpose_not_contains_i?: Maybe<Scalars['String']>;
  purpose_starts_with_i?: Maybe<Scalars['String']>;
  purpose_not_starts_with_i?: Maybe<Scalars['String']>;
  purpose_ends_with_i?: Maybe<Scalars['String']>;
  purpose_not_ends_with_i?: Maybe<Scalars['String']>;
  purpose_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt?: Maybe<Scalars['String']>;
  receipt_not?: Maybe<Scalars['String']>;
  receipt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  frozenReceipt_not?: Maybe<Scalars['JSON']>;
  frozenReceipt_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  frozenReceipt_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  invoice?: Maybe<Scalars['String']>;
  invoice_not?: Maybe<Scalars['String']>;
  invoice_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoice_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  frozenInvoice_not?: Maybe<Scalars['JSON']>;
  frozenInvoice_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  frozenInvoice_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  multiPayment?: Maybe<Scalars['String']>;
  multiPayment_not?: Maybe<Scalars['String']>;
  multiPayment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  multiPayment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context?: Maybe<Scalars['String']>;
  context_not?: Maybe<Scalars['String']>;
  context_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  context_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['String']>;
  order_not?: Maybe<Scalars['String']>;
  order_contains?: Maybe<Scalars['String']>;
  order_not_contains?: Maybe<Scalars['String']>;
  order_starts_with?: Maybe<Scalars['String']>;
  order_not_starts_with?: Maybe<Scalars['String']>;
  order_ends_with?: Maybe<Scalars['String']>;
  order_not_ends_with?: Maybe<Scalars['String']>;
  order_i?: Maybe<Scalars['String']>;
  order_not_i?: Maybe<Scalars['String']>;
  order_contains_i?: Maybe<Scalars['String']>;
  order_not_contains_i?: Maybe<Scalars['String']>;
  order_starts_with_i?: Maybe<Scalars['String']>;
  order_not_starts_with_i?: Maybe<Scalars['String']>;
  order_ends_with_i?: Maybe<Scalars['String']>;
  order_not_ends_with_i?: Maybe<Scalars['String']>;
  order_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBic_not?: Maybe<Scalars['String']>;
  recipientBic_contains?: Maybe<Scalars['String']>;
  recipientBic_not_contains?: Maybe<Scalars['String']>;
  recipientBic_starts_with?: Maybe<Scalars['String']>;
  recipientBic_not_starts_with?: Maybe<Scalars['String']>;
  recipientBic_ends_with?: Maybe<Scalars['String']>;
  recipientBic_not_ends_with?: Maybe<Scalars['String']>;
  recipientBic_i?: Maybe<Scalars['String']>;
  recipientBic_not_i?: Maybe<Scalars['String']>;
  recipientBic_contains_i?: Maybe<Scalars['String']>;
  recipientBic_not_contains_i?: Maybe<Scalars['String']>;
  recipientBic_starts_with_i?: Maybe<Scalars['String']>;
  recipientBic_not_starts_with_i?: Maybe<Scalars['String']>;
  recipientBic_ends_with_i?: Maybe<Scalars['String']>;
  recipientBic_not_ends_with_i?: Maybe<Scalars['String']>;
  recipientBic_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBic_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  recipientBankAccount_not?: Maybe<Scalars['String']>;
  recipientBankAccount_contains?: Maybe<Scalars['String']>;
  recipientBankAccount_not_contains?: Maybe<Scalars['String']>;
  recipientBankAccount_starts_with?: Maybe<Scalars['String']>;
  recipientBankAccount_not_starts_with?: Maybe<Scalars['String']>;
  recipientBankAccount_ends_with?: Maybe<Scalars['String']>;
  recipientBankAccount_not_ends_with?: Maybe<Scalars['String']>;
  recipientBankAccount_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_i?: Maybe<Scalars['String']>;
  recipientBankAccount_contains_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_contains_i?: Maybe<Scalars['String']>;
  recipientBankAccount_starts_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_starts_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_ends_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_ends_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBankAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PaymentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PaymentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PaymentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PaymentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PaymentHistoryRecordsCreateInput = {
  data?: Maybe<PaymentHistoryRecordCreateInput>;
};

export type PaymentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PaymentHistoryRecordUpdateInput>;
};

export type PaymentOverviewResult = {
  __typename?: 'PaymentOverviewResult';
  payments?: Maybe<Array<PaymentGroupedCounter>>;
  sum: Scalars['String'];
};

export type PaymentRelateToManyInput = {
  create?: Maybe<Array<Maybe<PaymentCreateInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum PaymentStatusType {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Done = 'DONE',
  Error = 'ERROR',
  Withdrawn = 'WITHDRAWN'
}

export type PaymentUpdateInput = {
  amount?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  implicitFee?: Maybe<Scalars['String']>;
  serviceFee?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<PaymentCurrencyCodeType>;
  advancedAt?: Maybe<Scalars['String']>;
  depositedDate?: Maybe<Scalars['String']>;
  transferDate?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  receipt?: Maybe<BillingReceiptRelateToOneInput>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  invoice?: Maybe<InvoiceRelateToOneInput>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  multiPayment?: Maybe<MultiPaymentRelateToOneInput>;
  context?: Maybe<AcquiringIntegrationContextRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  status?: Maybe<PaymentStatusType>;
  order?: Maybe<Scalars['String']>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PaymentWhereInput = {
  AND?: Maybe<Array<Maybe<PaymentWhereInput>>>;
  OR?: Maybe<Array<Maybe<PaymentWhereInput>>>;
  amount?: Maybe<Scalars['String']>;
  amount_not?: Maybe<Scalars['String']>;
  amount_lt?: Maybe<Scalars['String']>;
  amount_lte?: Maybe<Scalars['String']>;
  amount_gt?: Maybe<Scalars['String']>;
  amount_gte?: Maybe<Scalars['String']>;
  amount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  amount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFee?: Maybe<Scalars['String']>;
  explicitFee_not?: Maybe<Scalars['String']>;
  explicitFee_lt?: Maybe<Scalars['String']>;
  explicitFee_lte?: Maybe<Scalars['String']>;
  explicitFee_gt?: Maybe<Scalars['String']>;
  explicitFee_gte?: Maybe<Scalars['String']>;
  explicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge?: Maybe<Scalars['String']>;
  explicitServiceCharge_not?: Maybe<Scalars['String']>;
  explicitServiceCharge_lt?: Maybe<Scalars['String']>;
  explicitServiceCharge_lte?: Maybe<Scalars['String']>;
  explicitServiceCharge_gt?: Maybe<Scalars['String']>;
  explicitServiceCharge_gte?: Maybe<Scalars['String']>;
  explicitServiceCharge_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  explicitServiceCharge_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee?: Maybe<Scalars['String']>;
  implicitFee_not?: Maybe<Scalars['String']>;
  implicitFee_lt?: Maybe<Scalars['String']>;
  implicitFee_lte?: Maybe<Scalars['String']>;
  implicitFee_gt?: Maybe<Scalars['String']>;
  implicitFee_gte?: Maybe<Scalars['String']>;
  implicitFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  implicitFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee?: Maybe<Scalars['String']>;
  serviceFee_not?: Maybe<Scalars['String']>;
  serviceFee_lt?: Maybe<Scalars['String']>;
  serviceFee_lte?: Maybe<Scalars['String']>;
  serviceFee_gt?: Maybe<Scalars['String']>;
  serviceFee_gte?: Maybe<Scalars['String']>;
  serviceFee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceFee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currencyCode?: Maybe<PaymentCurrencyCodeType>;
  currencyCode_not?: Maybe<PaymentCurrencyCodeType>;
  currencyCode_in?: Maybe<Array<Maybe<PaymentCurrencyCodeType>>>;
  currencyCode_not_in?: Maybe<Array<Maybe<PaymentCurrencyCodeType>>>;
  advancedAt?: Maybe<Scalars['String']>;
  advancedAt_not?: Maybe<Scalars['String']>;
  advancedAt_lt?: Maybe<Scalars['String']>;
  advancedAt_lte?: Maybe<Scalars['String']>;
  advancedAt_gt?: Maybe<Scalars['String']>;
  advancedAt_gte?: Maybe<Scalars['String']>;
  advancedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  advancedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  depositedDate?: Maybe<Scalars['String']>;
  depositedDate_not?: Maybe<Scalars['String']>;
  depositedDate_lt?: Maybe<Scalars['String']>;
  depositedDate_lte?: Maybe<Scalars['String']>;
  depositedDate_gt?: Maybe<Scalars['String']>;
  depositedDate_gte?: Maybe<Scalars['String']>;
  depositedDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  depositedDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transferDate?: Maybe<Scalars['String']>;
  transferDate_not?: Maybe<Scalars['String']>;
  transferDate_lt?: Maybe<Scalars['String']>;
  transferDate_lte?: Maybe<Scalars['String']>;
  transferDate_gt?: Maybe<Scalars['String']>;
  transferDate_gte?: Maybe<Scalars['String']>;
  transferDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  transferDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_lt?: Maybe<Scalars['String']>;
  period_lte?: Maybe<Scalars['String']>;
  period_gt?: Maybe<Scalars['String']>;
  period_gte?: Maybe<Scalars['String']>;
  period_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  period_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose?: Maybe<Scalars['String']>;
  purpose_not?: Maybe<Scalars['String']>;
  purpose_contains?: Maybe<Scalars['String']>;
  purpose_not_contains?: Maybe<Scalars['String']>;
  purpose_starts_with?: Maybe<Scalars['String']>;
  purpose_not_starts_with?: Maybe<Scalars['String']>;
  purpose_ends_with?: Maybe<Scalars['String']>;
  purpose_not_ends_with?: Maybe<Scalars['String']>;
  purpose_i?: Maybe<Scalars['String']>;
  purpose_not_i?: Maybe<Scalars['String']>;
  purpose_contains_i?: Maybe<Scalars['String']>;
  purpose_not_contains_i?: Maybe<Scalars['String']>;
  purpose_starts_with_i?: Maybe<Scalars['String']>;
  purpose_not_starts_with_i?: Maybe<Scalars['String']>;
  purpose_ends_with_i?: Maybe<Scalars['String']>;
  purpose_not_ends_with_i?: Maybe<Scalars['String']>;
  purpose_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  purpose_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  receipt?: Maybe<BillingReceiptWhereInput>;
  receipt_is_null?: Maybe<Scalars['Boolean']>;
  frozenReceipt?: Maybe<Scalars['JSON']>;
  frozenReceipt_not?: Maybe<Scalars['JSON']>;
  frozenReceipt_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  frozenReceipt_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  invoice?: Maybe<InvoiceWhereInput>;
  invoice_is_null?: Maybe<Scalars['Boolean']>;
  frozenInvoice?: Maybe<Scalars['JSON']>;
  frozenInvoice_not?: Maybe<Scalars['JSON']>;
  frozenInvoice_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  frozenInvoice_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  multiPayment?: Maybe<MultiPaymentWhereInput>;
  multiPayment_is_null?: Maybe<Scalars['Boolean']>;
  context?: Maybe<AcquiringIntegrationContextWhereInput>;
  context_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  status?: Maybe<PaymentStatusType>;
  status_not?: Maybe<PaymentStatusType>;
  status_in?: Maybe<Array<Maybe<PaymentStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<PaymentStatusType>>>;
  order?: Maybe<Scalars['String']>;
  order_not?: Maybe<Scalars['String']>;
  order_contains?: Maybe<Scalars['String']>;
  order_not_contains?: Maybe<Scalars['String']>;
  order_starts_with?: Maybe<Scalars['String']>;
  order_not_starts_with?: Maybe<Scalars['String']>;
  order_ends_with?: Maybe<Scalars['String']>;
  order_not_ends_with?: Maybe<Scalars['String']>;
  order_i?: Maybe<Scalars['String']>;
  order_not_i?: Maybe<Scalars['String']>;
  order_contains_i?: Maybe<Scalars['String']>;
  order_not_contains_i?: Maybe<Scalars['String']>;
  order_starts_with_i?: Maybe<Scalars['String']>;
  order_not_starts_with_i?: Maybe<Scalars['String']>;
  order_ends_with_i?: Maybe<Scalars['String']>;
  order_not_ends_with_i?: Maybe<Scalars['String']>;
  order_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBic?: Maybe<Scalars['String']>;
  recipientBic_not?: Maybe<Scalars['String']>;
  recipientBic_contains?: Maybe<Scalars['String']>;
  recipientBic_not_contains?: Maybe<Scalars['String']>;
  recipientBic_starts_with?: Maybe<Scalars['String']>;
  recipientBic_not_starts_with?: Maybe<Scalars['String']>;
  recipientBic_ends_with?: Maybe<Scalars['String']>;
  recipientBic_not_ends_with?: Maybe<Scalars['String']>;
  recipientBic_i?: Maybe<Scalars['String']>;
  recipientBic_not_i?: Maybe<Scalars['String']>;
  recipientBic_contains_i?: Maybe<Scalars['String']>;
  recipientBic_not_contains_i?: Maybe<Scalars['String']>;
  recipientBic_starts_with_i?: Maybe<Scalars['String']>;
  recipientBic_not_starts_with_i?: Maybe<Scalars['String']>;
  recipientBic_ends_with_i?: Maybe<Scalars['String']>;
  recipientBic_not_ends_with_i?: Maybe<Scalars['String']>;
  recipientBic_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBic_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBankAccount?: Maybe<Scalars['String']>;
  recipientBankAccount_not?: Maybe<Scalars['String']>;
  recipientBankAccount_contains?: Maybe<Scalars['String']>;
  recipientBankAccount_not_contains?: Maybe<Scalars['String']>;
  recipientBankAccount_starts_with?: Maybe<Scalars['String']>;
  recipientBankAccount_not_starts_with?: Maybe<Scalars['String']>;
  recipientBankAccount_ends_with?: Maybe<Scalars['String']>;
  recipientBankAccount_not_ends_with?: Maybe<Scalars['String']>;
  recipientBankAccount_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_i?: Maybe<Scalars['String']>;
  recipientBankAccount_contains_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_contains_i?: Maybe<Scalars['String']>;
  recipientBankAccount_starts_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_starts_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_ends_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_not_ends_with_i?: Maybe<Scalars['String']>;
  recipientBankAccount_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recipientBankAccount_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId?: Maybe<Scalars['String']>;
  importId_not?: Maybe<Scalars['String']>;
  importId_contains?: Maybe<Scalars['String']>;
  importId_not_contains?: Maybe<Scalars['String']>;
  importId_starts_with?: Maybe<Scalars['String']>;
  importId_not_starts_with?: Maybe<Scalars['String']>;
  importId_ends_with?: Maybe<Scalars['String']>;
  importId_not_ends_with?: Maybe<Scalars['String']>;
  importId_i?: Maybe<Scalars['String']>;
  importId_not_i?: Maybe<Scalars['String']>;
  importId_contains_i?: Maybe<Scalars['String']>;
  importId_not_contains_i?: Maybe<Scalars['String']>;
  importId_starts_with_i?: Maybe<Scalars['String']>;
  importId_not_starts_with_i?: Maybe<Scalars['String']>;
  importId_ends_with_i?: Maybe<Scalars['String']>;
  importId_not_ends_with_i?: Maybe<Scalars['String']>;
  importId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  importId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PaymentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PaymentsCreateInput = {
  data?: Maybe<PaymentCreateInput>;
};

export type PaymentsFilter = {
  __typename?: 'PaymentsFilter';
  advancedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  depositedDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  transferDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentsFilterInput = {
  advancedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  depositedDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  transferDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**  The payments filter preset  */
export type PaymentsFilterTemplate = {
  __typename?: 'PaymentsFilterTemplate';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PaymentsFilterTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the PaymentsFilterTemplate List config, or
   *  3. As an alias to a 'name' field on the PaymentsFilterTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the PaymentsFilterTemplate List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Preset name  */
  name?: Maybe<Scalars['String']>;
  /**  Preset owner  */
  employee?: Maybe<OrganizationEmployee>;
  /**  Filter that match the given template  */
  fields?: Maybe<PaymentsFilter>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PaymentsFilterTemplateCreateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  fields?: Maybe<PaymentsFilterInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type PaymentsFilterTemplateHistoryRecord = {
  __typename?: 'PaymentsFilterTemplateHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PaymentsFilterTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PaymentsFilterTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PaymentsFilterTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PaymentsFilterTemplateHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PaymentsFilterTemplateHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PaymentsFilterTemplateHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PaymentsFilterTemplateHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PaymentsFilterTemplateHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Scalars['String']>;
  employee_not?: Maybe<Scalars['String']>;
  employee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<Scalars['JSON']>;
  fields_not?: Maybe<Scalars['JSON']>;
  fields_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentsFilterTemplateHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PaymentsFilterTemplateHistoryRecordsCreateInput = {
  data?: Maybe<PaymentsFilterTemplateHistoryRecordCreateInput>;
};

export type PaymentsFilterTemplateHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PaymentsFilterTemplateHistoryRecordUpdateInput>;
};

export type PaymentsFilterTemplateUpdateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  fields?: Maybe<PaymentsFilterInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PaymentsFilterTemplateWhereInput = {
  AND?: Maybe<Array<Maybe<PaymentsFilterTemplateWhereInput>>>;
  OR?: Maybe<Array<Maybe<PaymentsFilterTemplateWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<OrganizationEmployeeWhereInput>;
  employee_is_null?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<PaymentsFilterInput>;
  fields_not?: Maybe<PaymentsFilterInput>;
  fields_in?: Maybe<Array<Maybe<PaymentsFilterInput>>>;
  fields_not_in?: Maybe<Array<Maybe<PaymentsFilterInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PaymentsFilterTemplateWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PaymentsFilterTemplatesCreateInput = {
  data?: Maybe<PaymentsFilterTemplateCreateInput>;
};

export type PaymentsFilterTemplatesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PaymentsFilterTemplateUpdateInput>;
};

export type PaymentsSumOutput = {
  __typename?: 'PaymentsSumOutput';
  sum: Scalars['String'];
};

export type PaymentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PaymentUpdateInput>;
};

export type PredictTicketClassificationInput = {
  details: Scalars['String'];
};

export type PredictTransactionClassificationInput = {
  purpose: Scalars['String'];
  isOutcome: Scalars['Boolean'];
};

export type PredictTransactionClassificationOutput = {
  __typename?: 'PredictTransactionClassificationOutput';
  id: Scalars['ID'];
  name: Scalars['String'];
  isOutcome: Scalars['Boolean'];
  category: Scalars['ID'];
};

export type PropertiesCreateInput = {
  data?: Maybe<PropertyCreateInput>;
};

export type PropertiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyUpdateInput>;
};

/**  Common property. The property is divided into separate `unit` parts, each of which can be owned by an independent owner. Community farm, residential buildings, or a cottage settlement  */
export type Property = {
  __typename?: 'Property';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Property List config, or
   *  2. As an alias to the field set on 'labelField' in the Property List config, or
   *  3. As an alias to a 'name' field on the Property List (if one exists), or
   *  4. As an alias to the 'id' field on the Property List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Client understandable Property name. A well-known property name for the client  */
  name?: Maybe<Scalars['String']>;
  /**  Common property type  */
  type?: Maybe<PropertyTypeType>;
  /**  Property map/schema  */
  map?: Maybe<BuildingMap>;
  /**  A number of parts in the property. The number of flats for property.type = house. The number of garden houses for property.type = village.  */
  unitsCount?: Maybe<Scalars['Int']>;
  /**  A number of non-residential units. Number of parking places for unit.unitType = parking, apartment, commercial & warehouse  */
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  /**  Counter for closed tickets  */
  ticketsClosed?: Maybe<Scalars['String']>;
  /**  Counter for deferred tickets  */
  ticketsDeferred?: Maybe<Scalars['String']>;
  /**  Counter for not closed tickets  */
  ticketsInWork?: Maybe<Scalars['String']>;
  /**  Whether or not this organization can manage this property. Usually set by support. Defaults to False. Field is dropped to false if address is updated  */
  isApproved?: Maybe<Scalars['Boolean']>;
  /**  Year of the property was built  */
  yearOfConstruction?: Maybe<Scalars['String']>;
  /**  Property area in square meters  */
  area?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /**  Normalized address  */
  address?: Maybe<Scalars['String']>;
  /**  The unique key of the address  */
  addressKey?: Maybe<Scalars['String']>;
  /**  Property address components  */
  addressMeta?: Maybe<AddressMetaField>;
  /**  The origins of the address (some strings which may looks like real address or some id)  */
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PropertyCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PropertyTypeType>;
  map?: Maybe<Scalars['JSON']>;
  unitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type PropertyHistoryRecord = {
  __typename?: 'PropertyHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  map?: Maybe<Scalars['JSON']>;
  unitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  map?: Maybe<Scalars['JSON']>;
  unitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PropertyHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PropertyHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  map?: Maybe<Scalars['JSON']>;
  unitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  map?: Maybe<Scalars['JSON']>;
  map_not?: Maybe<Scalars['JSON']>;
  map_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  map_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitsCount_not?: Maybe<Scalars['Int']>;
  unitsCount_lt?: Maybe<Scalars['Int']>;
  unitsCount_lte?: Maybe<Scalars['Int']>;
  unitsCount_gt?: Maybe<Scalars['Int']>;
  unitsCount_gte?: Maybe<Scalars['Int']>;
  unitsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unitsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_not?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_lt?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_lte?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_gt?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_gte?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uninhabitedUnitsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isApproved_not?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  yearOfConstruction_not?: Maybe<Scalars['String']>;
  yearOfConstruction_lt?: Maybe<Scalars['String']>;
  yearOfConstruction_lte?: Maybe<Scalars['String']>;
  yearOfConstruction_gt?: Maybe<Scalars['String']>;
  yearOfConstruction_gte?: Maybe<Scalars['String']>;
  yearOfConstruction_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  yearOfConstruction_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  area?: Maybe<Scalars['String']>;
  area_not?: Maybe<Scalars['String']>;
  area_lt?: Maybe<Scalars['String']>;
  area_lte?: Maybe<Scalars['String']>;
  area_gt?: Maybe<Scalars['String']>;
  area_gte?: Maybe<Scalars['String']>;
  area_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  area_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PropertyHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PropertyHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PropertyHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PropertyHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyHistoryRecordsCreateInput = {
  data?: Maybe<PropertyHistoryRecordCreateInput>;
};

export type PropertyHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyHistoryRecordUpdateInput>;
};

export type PropertyMap = BuildingMap | VillageMap;

/**  Resource meter installed on the entire apartment building  */
export type PropertyMeter = {
  __typename?: 'PropertyMeter';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeter List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeter List config, or
   *  3. As an alias to a 'name' field on the PropertyMeter List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeter List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  /**  Date when the meter was installed  */
  installationDate?: Maybe<Scalars['String']>;
  /**  Date when the meter was commissioned.Commissioning - documentation of the meter as a billing meter  */
  commissioningDate?: Maybe<Scalars['String']>;
  /**  The date when the employee came and checked how accurately the meter counts the resource  */
  verificationDate?: Maybe<Scalars['String']>;
  /**  The date of the next meter verification.For example, for a cold water meter - usually 6 years after the verification date  */
  nextVerificationDate?: Maybe<Scalars['String']>;
  /**  The date when the employee came and took readings from the meter  */
  controlReadingsDate?: Maybe<Scalars['String']>;
  /**  The date when meter was sealed.Sealing is the installation of a unique single-use device (directly a seal and a sealing rope)on the metering device, which is designed to control unauthorized access to the equipment.  */
  sealingDate?: Maybe<Scalars['String']>;
  /**  The date when the meter was disabled and no longer in use  */
  archiveDate?: Maybe<Scalars['String']>;
  /**  Determines, if Meter is automatic or not. False by default. If set to True - prevents user with type "resident" from creating MeterReading.So MeterReadings only be acquired through external integration or adjusted by organization employee  */
  isAutomatic?: Maybe<Scalars['Boolean']>;
  /**  Meter resource, such as hot water or electricity  */
  resource?: Maybe<MeterResource>;
  /**  Ref to B2BApp, which is used as a master system for this meter. Specified organization must connect this app.  */
  b2bApp?: Maybe<B2BApp>;
  /**  Number of resource meter, such as "А03 9908"  */
  number?: Maybe<Scalars['String']>;
  /**  Link to property which contains this meter  */
  property?: Maybe<Property>;
  /**  Meter metadata. Can be used to store additional settings from external sources, such as billing integrations or mini apps  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PropertyMeterCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceRelateToOneInput>;
  b2bApp?: Maybe<B2BAppRelateToOneInput>;
  number?: Maybe<Scalars['String']>;
  property?: Maybe<PropertyRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type PropertyMeterHistoryRecord = {
  __typename?: 'PropertyMeterHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeterHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeterHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyMeterHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeterHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  b2bApp?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyMeterHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyMeterHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  b2bApp?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyMeterHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PropertyMeterHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PropertyMeterHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  b2bApp?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyMeterHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyMeterHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyMeterHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyMeterHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  numberOfTariffs_not?: Maybe<Scalars['Int']>;
  numberOfTariffs_lt?: Maybe<Scalars['Int']>;
  numberOfTariffs_lte?: Maybe<Scalars['Int']>;
  numberOfTariffs_gt?: Maybe<Scalars['Int']>;
  numberOfTariffs_gte?: Maybe<Scalars['Int']>;
  numberOfTariffs_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfTariffs_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  installationDate?: Maybe<Scalars['String']>;
  installationDate_not?: Maybe<Scalars['String']>;
  installationDate_lt?: Maybe<Scalars['String']>;
  installationDate_lte?: Maybe<Scalars['String']>;
  installationDate_gt?: Maybe<Scalars['String']>;
  installationDate_gte?: Maybe<Scalars['String']>;
  installationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  installationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate?: Maybe<Scalars['String']>;
  commissioningDate_not?: Maybe<Scalars['String']>;
  commissioningDate_lt?: Maybe<Scalars['String']>;
  commissioningDate_lte?: Maybe<Scalars['String']>;
  commissioningDate_gt?: Maybe<Scalars['String']>;
  commissioningDate_gte?: Maybe<Scalars['String']>;
  commissioningDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate?: Maybe<Scalars['String']>;
  verificationDate_not?: Maybe<Scalars['String']>;
  verificationDate_lt?: Maybe<Scalars['String']>;
  verificationDate_lte?: Maybe<Scalars['String']>;
  verificationDate_gt?: Maybe<Scalars['String']>;
  verificationDate_gte?: Maybe<Scalars['String']>;
  verificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate_not?: Maybe<Scalars['String']>;
  nextVerificationDate_lt?: Maybe<Scalars['String']>;
  nextVerificationDate_lte?: Maybe<Scalars['String']>;
  nextVerificationDate_gt?: Maybe<Scalars['String']>;
  nextVerificationDate_gte?: Maybe<Scalars['String']>;
  nextVerificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  controlReadingsDate_not?: Maybe<Scalars['String']>;
  controlReadingsDate_lt?: Maybe<Scalars['String']>;
  controlReadingsDate_lte?: Maybe<Scalars['String']>;
  controlReadingsDate_gt?: Maybe<Scalars['String']>;
  controlReadingsDate_gte?: Maybe<Scalars['String']>;
  controlReadingsDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate?: Maybe<Scalars['String']>;
  sealingDate_not?: Maybe<Scalars['String']>;
  sealingDate_lt?: Maybe<Scalars['String']>;
  sealingDate_lte?: Maybe<Scalars['String']>;
  sealingDate_gt?: Maybe<Scalars['String']>;
  sealingDate_gte?: Maybe<Scalars['String']>;
  sealingDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate?: Maybe<Scalars['String']>;
  archiveDate_not?: Maybe<Scalars['String']>;
  archiveDate_lt?: Maybe<Scalars['String']>;
  archiveDate_lte?: Maybe<Scalars['String']>;
  archiveDate_gt?: Maybe<Scalars['String']>;
  archiveDate_gte?: Maybe<Scalars['String']>;
  archiveDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  isAutomatic_not?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<Scalars['String']>;
  resource_not?: Maybe<Scalars['String']>;
  resource_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resource_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  b2bApp?: Maybe<Scalars['String']>;
  b2bApp_not?: Maybe<Scalars['String']>;
  b2bApp_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  b2bApp_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PropertyMeterHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PropertyMeterHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PropertyMeterHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PropertyMeterHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyMeterHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyMeterHistoryRecordsCreateInput = {
  data?: Maybe<PropertyMeterHistoryRecordCreateInput>;
};

export type PropertyMeterHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterHistoryRecordUpdateInput>;
};

/**  Meter reading taken from a client or billing  */
export type PropertyMeterReading = {
  __typename?: 'PropertyMeterReading';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeterReading List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeterReading List config, or
   *  3. As an alias to a 'name' field on the PropertyMeterReading List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeterReading List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Date when the readings were taken  */
  date?: Maybe<Scalars['String']>;
  /**  Meter from which readings were taken  */
  meter?: Maybe<PropertyMeter>;
  /**  If the meter is single-tariff, then only this value will be filled in;If multi-tariff, then the value of the first tariff will be in this field  */
  value1?: Maybe<Scalars['String']>;
  /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
  value2?: Maybe<Scalars['String']>;
  /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
  value3?: Maybe<Scalars['String']>;
  /**  If the meter is multi-tariff, then the value of the second tariff is stored here  */
  value4?: Maybe<Scalars['String']>;
  /**  Meter reading source channel/system. Examples: call, mobile_app, billing, ...  */
  source?: Maybe<MeterReadingSource>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
};

export type PropertyMeterReadingCreateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<PropertyMeterRelateToOneInput>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  source?: Maybe<MeterReadingSourceRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
};

/**  A keystone list  */
export type PropertyMeterReadingHistoryRecord = {
  __typename?: 'PropertyMeterReadingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyMeterReadingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyMeterReadingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyMeterReadingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyMeterReadingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyMeterReadingHistoryRecordCreateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PropertyMeterReadingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PropertyMeterReadingHistoryRecordUpdateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<Scalars['String']>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  organization?: Maybe<Scalars['String']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyMeterReadingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecordWhereInput>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meter?: Maybe<Scalars['String']>;
  meter_not?: Maybe<Scalars['String']>;
  meter_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meter_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value1?: Maybe<Scalars['String']>;
  value1_not?: Maybe<Scalars['String']>;
  value1_lt?: Maybe<Scalars['String']>;
  value1_lte?: Maybe<Scalars['String']>;
  value1_gt?: Maybe<Scalars['String']>;
  value1_gte?: Maybe<Scalars['String']>;
  value1_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2?: Maybe<Scalars['String']>;
  value2_not?: Maybe<Scalars['String']>;
  value2_lt?: Maybe<Scalars['String']>;
  value2_lte?: Maybe<Scalars['String']>;
  value2_gt?: Maybe<Scalars['String']>;
  value2_gte?: Maybe<Scalars['String']>;
  value2_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3?: Maybe<Scalars['String']>;
  value3_not?: Maybe<Scalars['String']>;
  value3_lt?: Maybe<Scalars['String']>;
  value3_lte?: Maybe<Scalars['String']>;
  value3_gt?: Maybe<Scalars['String']>;
  value3_gte?: Maybe<Scalars['String']>;
  value3_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4?: Maybe<Scalars['String']>;
  value4_not?: Maybe<Scalars['String']>;
  value4_lt?: Maybe<Scalars['String']>;
  value4_lte?: Maybe<Scalars['String']>;
  value4_gt?: Maybe<Scalars['String']>;
  value4_gte?: Maybe<Scalars['String']>;
  value4_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Scalars['String']>;
  source_not?: Maybe<Scalars['String']>;
  source_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyMeterReadingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyMeterReadingHistoryRecordsCreateInput = {
  data?: Maybe<PropertyMeterReadingHistoryRecordCreateInput>;
};

export type PropertyMeterReadingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterReadingHistoryRecordUpdateInput>;
};

export type PropertyMeterReadingUpdateInput = {
  date?: Maybe<Scalars['String']>;
  meter?: Maybe<PropertyMeterRelateToOneInput>;
  value1?: Maybe<Scalars['String']>;
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  source?: Maybe<MeterReadingSourceRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
};

export type PropertyMeterReadingWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyMeterReadingWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyMeterReadingWhereInput>>>;
  date?: Maybe<Scalars['String']>;
  date_not?: Maybe<Scalars['String']>;
  date_lt?: Maybe<Scalars['String']>;
  date_lte?: Maybe<Scalars['String']>;
  date_gt?: Maybe<Scalars['String']>;
  date_gte?: Maybe<Scalars['String']>;
  date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meter?: Maybe<PropertyMeterWhereInput>;
  meter_is_null?: Maybe<Scalars['Boolean']>;
  value1?: Maybe<Scalars['String']>;
  value1_not?: Maybe<Scalars['String']>;
  value1_lt?: Maybe<Scalars['String']>;
  value1_lte?: Maybe<Scalars['String']>;
  value1_gt?: Maybe<Scalars['String']>;
  value1_gte?: Maybe<Scalars['String']>;
  value1_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value1_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2?: Maybe<Scalars['String']>;
  value2_not?: Maybe<Scalars['String']>;
  value2_lt?: Maybe<Scalars['String']>;
  value2_lte?: Maybe<Scalars['String']>;
  value2_gt?: Maybe<Scalars['String']>;
  value2_gte?: Maybe<Scalars['String']>;
  value2_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3?: Maybe<Scalars['String']>;
  value3_not?: Maybe<Scalars['String']>;
  value3_lt?: Maybe<Scalars['String']>;
  value3_lte?: Maybe<Scalars['String']>;
  value3_gt?: Maybe<Scalars['String']>;
  value3_gte?: Maybe<Scalars['String']>;
  value3_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4?: Maybe<Scalars['String']>;
  value4_not?: Maybe<Scalars['String']>;
  value4_lt?: Maybe<Scalars['String']>;
  value4_lte?: Maybe<Scalars['String']>;
  value4_gt?: Maybe<Scalars['String']>;
  value4_gte?: Maybe<Scalars['String']>;
  value4_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  value4_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<MeterReadingSourceWhereInput>;
  source_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
};

export type PropertyMeterReadingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyMeterReadingsCreateInput = {
  data?: Maybe<PropertyMeterReadingCreateInput>;
};

export type PropertyMeterReadingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterReadingUpdateInput>;
};

export type PropertyMeterRelateToOneInput = {
  create?: Maybe<PropertyMeterCreateInput>;
  connect?: Maybe<PropertyMeterWhereUniqueInput>;
  disconnect?: Maybe<PropertyMeterWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type PropertyMeterUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  archiveDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceRelateToOneInput>;
  b2bApp?: Maybe<B2BAppRelateToOneInput>;
  number?: Maybe<Scalars['String']>;
  property?: Maybe<PropertyRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PropertyMeterWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyMeterWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyMeterWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  numberOfTariffs?: Maybe<Scalars['Int']>;
  numberOfTariffs_not?: Maybe<Scalars['Int']>;
  numberOfTariffs_lt?: Maybe<Scalars['Int']>;
  numberOfTariffs_lte?: Maybe<Scalars['Int']>;
  numberOfTariffs_gt?: Maybe<Scalars['Int']>;
  numberOfTariffs_gte?: Maybe<Scalars['Int']>;
  numberOfTariffs_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  numberOfTariffs_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  installationDate?: Maybe<Scalars['String']>;
  installationDate_not?: Maybe<Scalars['String']>;
  installationDate_lt?: Maybe<Scalars['String']>;
  installationDate_lte?: Maybe<Scalars['String']>;
  installationDate_gt?: Maybe<Scalars['String']>;
  installationDate_gte?: Maybe<Scalars['String']>;
  installationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  installationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate?: Maybe<Scalars['String']>;
  commissioningDate_not?: Maybe<Scalars['String']>;
  commissioningDate_lt?: Maybe<Scalars['String']>;
  commissioningDate_lte?: Maybe<Scalars['String']>;
  commissioningDate_gt?: Maybe<Scalars['String']>;
  commissioningDate_gte?: Maybe<Scalars['String']>;
  commissioningDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  commissioningDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate?: Maybe<Scalars['String']>;
  verificationDate_not?: Maybe<Scalars['String']>;
  verificationDate_lt?: Maybe<Scalars['String']>;
  verificationDate_lte?: Maybe<Scalars['String']>;
  verificationDate_gt?: Maybe<Scalars['String']>;
  verificationDate_gte?: Maybe<Scalars['String']>;
  verificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  verificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate_not?: Maybe<Scalars['String']>;
  nextVerificationDate_lt?: Maybe<Scalars['String']>;
  nextVerificationDate_lte?: Maybe<Scalars['String']>;
  nextVerificationDate_gt?: Maybe<Scalars['String']>;
  nextVerificationDate_gte?: Maybe<Scalars['String']>;
  nextVerificationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nextVerificationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  controlReadingsDate_not?: Maybe<Scalars['String']>;
  controlReadingsDate_lt?: Maybe<Scalars['String']>;
  controlReadingsDate_lte?: Maybe<Scalars['String']>;
  controlReadingsDate_gt?: Maybe<Scalars['String']>;
  controlReadingsDate_gte?: Maybe<Scalars['String']>;
  controlReadingsDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  controlReadingsDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate?: Maybe<Scalars['String']>;
  sealingDate_not?: Maybe<Scalars['String']>;
  sealingDate_lt?: Maybe<Scalars['String']>;
  sealingDate_lte?: Maybe<Scalars['String']>;
  sealingDate_gt?: Maybe<Scalars['String']>;
  sealingDate_gte?: Maybe<Scalars['String']>;
  sealingDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sealingDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate?: Maybe<Scalars['String']>;
  archiveDate_not?: Maybe<Scalars['String']>;
  archiveDate_lt?: Maybe<Scalars['String']>;
  archiveDate_lte?: Maybe<Scalars['String']>;
  archiveDate_gt?: Maybe<Scalars['String']>;
  archiveDate_gte?: Maybe<Scalars['String']>;
  archiveDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  archiveDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
  isAutomatic_not?: Maybe<Scalars['Boolean']>;
  resource?: Maybe<MeterResourceWhereInput>;
  resource_is_null?: Maybe<Scalars['Boolean']>;
  b2bApp?: Maybe<B2BAppWhereInput>;
  b2bApp_is_null?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  number_not?: Maybe<Scalars['String']>;
  number_contains?: Maybe<Scalars['String']>;
  number_not_contains?: Maybe<Scalars['String']>;
  number_starts_with?: Maybe<Scalars['String']>;
  number_not_starts_with?: Maybe<Scalars['String']>;
  number_ends_with?: Maybe<Scalars['String']>;
  number_not_ends_with?: Maybe<Scalars['String']>;
  number_i?: Maybe<Scalars['String']>;
  number_not_i?: Maybe<Scalars['String']>;
  number_contains_i?: Maybe<Scalars['String']>;
  number_not_contains_i?: Maybe<Scalars['String']>;
  number_starts_with_i?: Maybe<Scalars['String']>;
  number_not_starts_with_i?: Maybe<Scalars['String']>;
  number_ends_with_i?: Maybe<Scalars['String']>;
  number_not_ends_with_i?: Maybe<Scalars['String']>;
  number_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PropertyMeterWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyMetersCreateInput = {
  data?: Maybe<PropertyMeterCreateInput>;
};

export type PropertyMetersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyMeterUpdateInput>;
};

export type PropertyOverviewResult = {
  __typename?: 'PropertyOverviewResult';
  sum: Scalars['String'];
};

export type PropertyRelateToOneInput = {
  create?: Maybe<PropertyCreateInput>;
  connect?: Maybe<PropertyWhereUniqueInput>;
  disconnect?: Maybe<PropertyWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  A set of properties that limits the visibility of the organization's objects to the specified employees  */
export type PropertyScope = {
  __typename?: 'PropertyScope';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScope List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScope List config, or
   *  3. As an alias to a 'name' field on the PropertyScope List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScope List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The name of the zone that limits the visibility of employees by properties  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  True if PropertyScope includes all properties in organization  */
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  /**  True if PropertyScope includes all employees in organization  */
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PropertyScopeCreateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type PropertyScopeHistoryRecord = {
  __typename?: 'PropertyScopeHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyScopeHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PropertyScopeHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PropertyScopeHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyScopeHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyScopeHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyScopeHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllProperties_not?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  hasAllEmployees_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PropertyScopeHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PropertyScopeHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PropertyScopeHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PropertyScopeHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyScopeHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyScopeHistoryRecordsCreateInput = {
  data?: Maybe<PropertyScopeHistoryRecordCreateInput>;
};

export type PropertyScopeHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeHistoryRecordUpdateInput>;
};

/**  Many-to-many relationship between PropertyScope and OrganizationEmployee  */
export type PropertyScopeOrganizationEmployee = {
  __typename?: 'PropertyScopeOrganizationEmployee';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeOrganizationEmployee List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeOrganizationEmployee List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeOrganizationEmployee List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeOrganizationEmployee List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Property scope which has an employee  */
  propertyScope?: Maybe<PropertyScope>;
  /**  An employee who is in the property scope  */
  employee?: Maybe<OrganizationEmployee>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PropertyScopeOrganizationEmployeeCreateInput = {
  propertyScope?: Maybe<PropertyScopeRelateToOneInput>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type PropertyScopeOrganizationEmployeeHistoryRecord = {
  __typename?: 'PropertyScopeOrganizationEmployeeHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeOrganizationEmployeeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeOrganizationEmployeeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeOrganizationEmployeeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeOrganizationEmployeeHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  propertyScope?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyScopeOrganizationEmployeeHistoryRecordCreateInput = {
  propertyScope?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PropertyScopeOrganizationEmployeeHistoryRecordUpdateInput = {
  propertyScope?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyScopeOrganizationEmployeeHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>>>;
  propertyScope?: Maybe<Scalars['String']>;
  propertyScope_not?: Maybe<Scalars['String']>;
  propertyScope_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyScope_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Scalars['String']>;
  employee_not?: Maybe<Scalars['String']>;
  employee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyScopeOrganizationEmployeeHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyScopeOrganizationEmployeeHistoryRecordsCreateInput = {
  data?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordCreateInput>;
};

export type PropertyScopeOrganizationEmployeeHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordUpdateInput>;
};

export type PropertyScopeOrganizationEmployeeUpdateInput = {
  propertyScope?: Maybe<PropertyScopeRelateToOneInput>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PropertyScopeOrganizationEmployeeWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeWhereInput>>>;
  propertyScope?: Maybe<PropertyScopeWhereInput>;
  propertyScope_is_null?: Maybe<Scalars['Boolean']>;
  employee?: Maybe<OrganizationEmployeeWhereInput>;
  employee_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PropertyScopeOrganizationEmployeeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyScopeOrganizationEmployeesCreateInput = {
  data?: Maybe<PropertyScopeOrganizationEmployeeCreateInput>;
};

export type PropertyScopeOrganizationEmployeesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeOrganizationEmployeeUpdateInput>;
};

export type PropertyScopePropertiesCreateInput = {
  data?: Maybe<PropertyScopePropertyCreateInput>;
};

export type PropertyScopePropertiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopePropertyUpdateInput>;
};

/**  Many-to-many relationship between PropertyScope and Property  */
export type PropertyScopeProperty = {
  __typename?: 'PropertyScopeProperty';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopeProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopeProperty List config, or
   *  3. As an alias to a 'name' field on the PropertyScopeProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopeProperty List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Property scope which has a property  */
  propertyScope?: Maybe<PropertyScope>;
  /**  A property which is in the property scope  */
  property?: Maybe<Property>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type PropertyScopePropertyCreateInput = {
  propertyScope?: Maybe<PropertyScopeRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type PropertyScopePropertyHistoryRecord = {
  __typename?: 'PropertyScopePropertyHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the PropertyScopePropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the PropertyScopePropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the PropertyScopePropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the PropertyScopePropertyHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  propertyScope?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyScopePropertyHistoryRecordCreateInput = {
  propertyScope?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum PropertyScopePropertyHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type PropertyScopePropertyHistoryRecordUpdateInput = {
  propertyScope?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type PropertyScopePropertyHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecordWhereInput>>>;
  propertyScope?: Maybe<Scalars['String']>;
  propertyScope_not?: Maybe<Scalars['String']>;
  propertyScope_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyScope_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyScopePropertyHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyScopePropertyHistoryRecordsCreateInput = {
  data?: Maybe<PropertyScopePropertyHistoryRecordCreateInput>;
};

export type PropertyScopePropertyHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopePropertyHistoryRecordUpdateInput>;
};

export type PropertyScopePropertyUpdateInput = {
  propertyScope?: Maybe<PropertyScopeRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PropertyScopePropertyWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyScopePropertyWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyScopePropertyWhereInput>>>;
  propertyScope?: Maybe<PropertyScopeWhereInput>;
  propertyScope_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PropertyScopePropertyWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyScopeRelateToOneInput = {
  create?: Maybe<PropertyScopeCreateInput>;
  connect?: Maybe<PropertyScopeWhereUniqueInput>;
  disconnect?: Maybe<PropertyScopeWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type PropertyScopeUpdateInput = {
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PropertyScopeWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyScopeWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyScopeWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  hasAllProperties?: Maybe<Scalars['Boolean']>;
  hasAllProperties_not?: Maybe<Scalars['Boolean']>;
  hasAllEmployees?: Maybe<Scalars['Boolean']>;
  hasAllEmployees_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PropertyScopeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type PropertyScopesCreateInput = {
  data?: Maybe<PropertyScopeCreateInput>;
};

export type PropertyScopesInput = {
  billingProperty: BillingPropertyWhereUniqueInput;
  units?: Maybe<Array<Maybe<PropertyUnitInput>>>;
  accountNumbers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  skipUnits?: Maybe<Array<Maybe<PropertyUnitInput>>>;
  skipAccountNumbers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type PropertyScopesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<PropertyScopeUpdateInput>;
};

export enum PropertyTypeType {
  Building = 'building',
  Village = 'village'
}

export type PropertyUnitInput = {
  unitType: Scalars['String'];
  unitName: Scalars['String'];
};

export type PropertyUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<PropertyTypeType>;
  map?: Maybe<Scalars['JSON']>;
  unitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  isApproved?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type PropertyWhereInput = {
  AND?: Maybe<Array<Maybe<PropertyWhereInput>>>;
  OR?: Maybe<Array<Maybe<PropertyWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<PropertyTypeType>;
  type_not?: Maybe<PropertyTypeType>;
  type_in?: Maybe<Array<Maybe<PropertyTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<PropertyTypeType>>>;
  map?: Maybe<Scalars['JSON']>;
  map_not?: Maybe<Scalars['JSON']>;
  map_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  map_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitsCount_not?: Maybe<Scalars['Int']>;
  unitsCount_lt?: Maybe<Scalars['Int']>;
  unitsCount_lte?: Maybe<Scalars['Int']>;
  unitsCount_gt?: Maybe<Scalars['Int']>;
  unitsCount_gte?: Maybe<Scalars['Int']>;
  unitsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unitsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uninhabitedUnitsCount?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_not?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_lt?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_lte?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_gt?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_gte?: Maybe<Scalars['Int']>;
  uninhabitedUnitsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uninhabitedUnitsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  isApproved?: Maybe<Scalars['Boolean']>;
  isApproved_not?: Maybe<Scalars['Boolean']>;
  yearOfConstruction?: Maybe<Scalars['String']>;
  yearOfConstruction_not?: Maybe<Scalars['String']>;
  yearOfConstruction_lt?: Maybe<Scalars['String']>;
  yearOfConstruction_lte?: Maybe<Scalars['String']>;
  yearOfConstruction_gt?: Maybe<Scalars['String']>;
  yearOfConstruction_gte?: Maybe<Scalars['String']>;
  yearOfConstruction_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  yearOfConstruction_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  area?: Maybe<Scalars['String']>;
  area_not?: Maybe<Scalars['String']>;
  area_lt?: Maybe<Scalars['String']>;
  area_lte?: Maybe<Scalars['String']>;
  area_gt?: Maybe<Scalars['String']>;
  area_gte?: Maybe<Scalars['String']>;
  area_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  area_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type PropertyWhereUniqueInput = {
  id: Scalars['ID'];
};

export enum PushTransportType {
  Firebase = 'firebase',
  Apple = 'apple',
  Huawei = 'huawei'
}

export enum PushType {
  Default = 'default',
  SilentData = 'silent_data'
}

export enum QualityControlAdditionalOptionsType {
  LowQuality = 'lowQuality',
  HighQuality = 'highQuality',
  Slowly = 'slowly',
  Quickly = 'quickly'
}

export type Query = {
  __typename?: 'Query';
  /**  Search for all UserHistoryRecord items which match the where clause.  */
  allUserHistoryRecords?: Maybe<Array<Maybe<UserHistoryRecord>>>;
  /**  Search for the UserHistoryRecord item with the matching ID.  */
  UserHistoryRecord?: Maybe<UserHistoryRecord>;
  /**  Perform a meta-query on all UserHistoryRecord items which match the where clause.  */
  _allUserHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserHistoryRecord list.  */
  _UserHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all User items which match the where clause.  */
  allUsers?: Maybe<Array<Maybe<User>>>;
  /**  Search for the User item with the matching ID.  */
  User?: Maybe<User>;
  /**  Perform a meta-query on all User items which match the where clause.  */
  _allUsersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the User list.  */
  _UsersMeta?: Maybe<_ListMeta>;
  /**  Search for all ConfirmPhoneActionHistoryRecord items which match the where clause.  */
  allConfirmPhoneActionHistoryRecords?: Maybe<Array<Maybe<ConfirmPhoneActionHistoryRecord>>>;
  /**  Search for the ConfirmPhoneActionHistoryRecord item with the matching ID.  */
  ConfirmPhoneActionHistoryRecord?: Maybe<ConfirmPhoneActionHistoryRecord>;
  /**  Perform a meta-query on all ConfirmPhoneActionHistoryRecord items which match the where clause.  */
  _allConfirmPhoneActionHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ConfirmPhoneActionHistoryRecord list.  */
  _ConfirmPhoneActionHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ConfirmPhoneAction items which match the where clause.  */
  allConfirmPhoneActions?: Maybe<Array<Maybe<ConfirmPhoneAction>>>;
  /**  Search for the ConfirmPhoneAction item with the matching ID.  */
  ConfirmPhoneAction?: Maybe<ConfirmPhoneAction>;
  /**  Perform a meta-query on all ConfirmPhoneAction items which match the where clause.  */
  _allConfirmPhoneActionsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ConfirmPhoneAction list.  */
  _ConfirmPhoneActionsMeta?: Maybe<_ListMeta>;
  /**  Search for all OidcClientHistoryRecord items which match the where clause.  */
  allOidcClientHistoryRecords?: Maybe<Array<Maybe<OidcClientHistoryRecord>>>;
  /**  Search for the OidcClientHistoryRecord item with the matching ID.  */
  OidcClientHistoryRecord?: Maybe<OidcClientHistoryRecord>;
  /**  Perform a meta-query on all OidcClientHistoryRecord items which match the where clause.  */
  _allOidcClientHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OidcClientHistoryRecord list.  */
  _OidcClientHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all OidcClient items which match the where clause.  */
  allOidcClients?: Maybe<Array<Maybe<OidcClient>>>;
  /**  Search for the OidcClient item with the matching ID.  */
  OidcClient?: Maybe<OidcClient>;
  /**  Perform a meta-query on all OidcClient items which match the where clause.  */
  _allOidcClientsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OidcClient list.  */
  _OidcClientsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserExternalIdentityHistoryRecord items which match the where clause.  */
  allUserExternalIdentityHistoryRecords?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecord>>>;
  /**  Search for the UserExternalIdentityHistoryRecord item with the matching ID.  */
  UserExternalIdentityHistoryRecord?: Maybe<UserExternalIdentityHistoryRecord>;
  /**  Perform a meta-query on all UserExternalIdentityHistoryRecord items which match the where clause.  */
  _allUserExternalIdentityHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserExternalIdentityHistoryRecord list.  */
  _UserExternalIdentityHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserExternalIdentity items which match the where clause.  */
  allUserExternalIdentities?: Maybe<Array<Maybe<UserExternalIdentity>>>;
  /**  Search for the UserExternalIdentity item with the matching ID.  */
  UserExternalIdentity?: Maybe<UserExternalIdentity>;
  /**  Perform a meta-query on all UserExternalIdentity items which match the where clause.  */
  _allUserExternalIdentitiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserExternalIdentity list.  */
  _UserExternalIdentitiesMeta?: Maybe<_ListMeta>;
  /**  Search for all ExternalTokenAccessRightHistoryRecord items which match the where clause.  */
  allExternalTokenAccessRightHistoryRecords?: Maybe<Array<Maybe<ExternalTokenAccessRightHistoryRecord>>>;
  /**  Search for the ExternalTokenAccessRightHistoryRecord item with the matching ID.  */
  ExternalTokenAccessRightHistoryRecord?: Maybe<ExternalTokenAccessRightHistoryRecord>;
  /**  Perform a meta-query on all ExternalTokenAccessRightHistoryRecord items which match the where clause.  */
  _allExternalTokenAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ExternalTokenAccessRightHistoryRecord list.  */
  _ExternalTokenAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ExternalTokenAccessRight items which match the where clause.  */
  allExternalTokenAccessRights?: Maybe<Array<Maybe<ExternalTokenAccessRight>>>;
  /**  Search for the ExternalTokenAccessRight item with the matching ID.  */
  ExternalTokenAccessRight?: Maybe<ExternalTokenAccessRight>;
  /**  Perform a meta-query on all ExternalTokenAccessRight items which match the where clause.  */
  _allExternalTokenAccessRightsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ExternalTokenAccessRight list.  */
  _ExternalTokenAccessRightsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserRightsSetHistoryRecord items which match the where clause.  */
  allUserRightsSetHistoryRecords?: Maybe<Array<Maybe<UserRightsSetHistoryRecord>>>;
  /**  Search for the UserRightsSetHistoryRecord item with the matching ID.  */
  UserRightsSetHistoryRecord?: Maybe<UserRightsSetHistoryRecord>;
  /**  Perform a meta-query on all UserRightsSetHistoryRecord items which match the where clause.  */
  _allUserRightsSetHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserRightsSetHistoryRecord list.  */
  _UserRightsSetHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserRightsSet items which match the where clause.  */
  allUserRightsSets?: Maybe<Array<Maybe<UserRightsSet>>>;
  /**  Search for the UserRightsSet item with the matching ID.  */
  UserRightsSet?: Maybe<UserRightsSet>;
  /**  Perform a meta-query on all UserRightsSet items which match the where clause.  */
  _allUserRightsSetsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserRightsSet list.  */
  _UserRightsSetsMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationHistoryRecord items which match the where clause.  */
  allOrganizationHistoryRecords?: Maybe<Array<Maybe<OrganizationHistoryRecord>>>;
  /**  Search for the OrganizationHistoryRecord item with the matching ID.  */
  OrganizationHistoryRecord?: Maybe<OrganizationHistoryRecord>;
  /**  Perform a meta-query on all OrganizationHistoryRecord items which match the where clause.  */
  _allOrganizationHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationHistoryRecord list.  */
  _OrganizationHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Organization items which match the where clause.  */
  allOrganizations?: Maybe<Array<Maybe<Organization>>>;
  /**  Search for the Organization item with the matching ID.  */
  Organization?: Maybe<Organization>;
  /**  Perform a meta-query on all Organization items which match the where clause.  */
  _allOrganizationsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Organization list.  */
  _OrganizationsMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationEmployeeHistoryRecord items which match the where clause.  */
  allOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeHistoryRecord>>>;
  /**  Search for the OrganizationEmployeeHistoryRecord item with the matching ID.  */
  OrganizationEmployeeHistoryRecord?: Maybe<OrganizationEmployeeHistoryRecord>;
  /**  Perform a meta-query on all OrganizationEmployeeHistoryRecord items which match the where clause.  */
  _allOrganizationEmployeeHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationEmployeeHistoryRecord list.  */
  _OrganizationEmployeeHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationEmployee items which match the where clause.  */
  allOrganizationEmployees?: Maybe<Array<Maybe<OrganizationEmployee>>>;
  /**  Search for the OrganizationEmployee item with the matching ID.  */
  OrganizationEmployee?: Maybe<OrganizationEmployee>;
  /**  Perform a meta-query on all OrganizationEmployee items which match the where clause.  */
  _allOrganizationEmployeesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationEmployee list.  */
  _OrganizationEmployeesMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationEmployeeRoleHistoryRecord items which match the where clause.  */
  allOrganizationEmployeeRoleHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeRoleHistoryRecord>>>;
  /**  Search for the OrganizationEmployeeRoleHistoryRecord item with the matching ID.  */
  OrganizationEmployeeRoleHistoryRecord?: Maybe<OrganizationEmployeeRoleHistoryRecord>;
  /**  Perform a meta-query on all OrganizationEmployeeRoleHistoryRecord items which match the where clause.  */
  _allOrganizationEmployeeRoleHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationEmployeeRoleHistoryRecord list.  */
  _OrganizationEmployeeRoleHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationEmployeeRole items which match the where clause.  */
  allOrganizationEmployeeRoles?: Maybe<Array<Maybe<OrganizationEmployeeRole>>>;
  /**  Search for the OrganizationEmployeeRole item with the matching ID.  */
  OrganizationEmployeeRole?: Maybe<OrganizationEmployeeRole>;
  /**  Perform a meta-query on all OrganizationEmployeeRole items which match the where clause.  */
  _allOrganizationEmployeeRolesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationEmployeeRole list.  */
  _OrganizationEmployeeRolesMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationLinkHistoryRecord items which match the where clause.  */
  allOrganizationLinkHistoryRecords?: Maybe<Array<Maybe<OrganizationLinkHistoryRecord>>>;
  /**  Search for the OrganizationLinkHistoryRecord item with the matching ID.  */
  OrganizationLinkHistoryRecord?: Maybe<OrganizationLinkHistoryRecord>;
  /**  Perform a meta-query on all OrganizationLinkHistoryRecord items which match the where clause.  */
  _allOrganizationLinkHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationLinkHistoryRecord list.  */
  _OrganizationLinkHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationLink items which match the where clause.  */
  allOrganizationLinks?: Maybe<Array<Maybe<OrganizationLink>>>;
  /**  Search for the OrganizationLink item with the matching ID.  */
  OrganizationLink?: Maybe<OrganizationLink>;
  /**  Perform a meta-query on all OrganizationLink items which match the where clause.  */
  _allOrganizationLinksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationLink list.  */
  _OrganizationLinksMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationEmployeeSpecializationHistoryRecord items which match the where clause.  */
  allOrganizationEmployeeSpecializationHistoryRecords?: Maybe<Array<Maybe<OrganizationEmployeeSpecializationHistoryRecord>>>;
  /**  Search for the OrganizationEmployeeSpecializationHistoryRecord item with the matching ID.  */
  OrganizationEmployeeSpecializationHistoryRecord?: Maybe<OrganizationEmployeeSpecializationHistoryRecord>;
  /**  Perform a meta-query on all OrganizationEmployeeSpecializationHistoryRecord items which match the where clause.  */
  _allOrganizationEmployeeSpecializationHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationEmployeeSpecializationHistoryRecord list.  */
  _OrganizationEmployeeSpecializationHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all OrganizationEmployeeSpecialization items which match the where clause.  */
  allOrganizationEmployeeSpecializations?: Maybe<Array<Maybe<OrganizationEmployeeSpecialization>>>;
  /**  Search for the OrganizationEmployeeSpecialization item with the matching ID.  */
  OrganizationEmployeeSpecialization?: Maybe<OrganizationEmployeeSpecialization>;
  /**  Perform a meta-query on all OrganizationEmployeeSpecialization items which match the where clause.  */
  _allOrganizationEmployeeSpecializationsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the OrganizationEmployeeSpecialization list.  */
  _OrganizationEmployeeSpecializationsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyHistoryRecord items which match the where clause.  */
  allPropertyHistoryRecords?: Maybe<Array<Maybe<PropertyHistoryRecord>>>;
  /**  Search for the PropertyHistoryRecord item with the matching ID.  */
  PropertyHistoryRecord?: Maybe<PropertyHistoryRecord>;
  /**  Perform a meta-query on all PropertyHistoryRecord items which match the where clause.  */
  _allPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyHistoryRecord list.  */
  _PropertyHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Property items which match the where clause.  */
  allProperties?: Maybe<Array<Maybe<Property>>>;
  /**  Search for the Property item with the matching ID.  */
  Property?: Maybe<Property>;
  /**  Perform a meta-query on all Property items which match the where clause.  */
  _allPropertiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Property list.  */
  _PropertiesMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegrationHistoryRecord items which match the where clause.  */
  allBillingIntegrationHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationHistoryRecord>>>;
  /**  Search for the BillingIntegrationHistoryRecord item with the matching ID.  */
  BillingIntegrationHistoryRecord?: Maybe<BillingIntegrationHistoryRecord>;
  /**  Perform a meta-query on all BillingIntegrationHistoryRecord items which match the where clause.  */
  _allBillingIntegrationHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegrationHistoryRecord list.  */
  _BillingIntegrationHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegration items which match the where clause.  */
  allBillingIntegrations?: Maybe<Array<Maybe<BillingIntegration>>>;
  /**  Search for the BillingIntegration item with the matching ID.  */
  BillingIntegration?: Maybe<BillingIntegration>;
  /**  Perform a meta-query on all BillingIntegration items which match the where clause.  */
  _allBillingIntegrationsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegration list.  */
  _BillingIntegrationsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegrationAccessRightHistoryRecord items which match the where clause.  */
  allBillingIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationAccessRightHistoryRecord>>>;
  /**  Search for the BillingIntegrationAccessRightHistoryRecord item with the matching ID.  */
  BillingIntegrationAccessRightHistoryRecord?: Maybe<BillingIntegrationAccessRightHistoryRecord>;
  /**  Perform a meta-query on all BillingIntegrationAccessRightHistoryRecord items which match the where clause.  */
  _allBillingIntegrationAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegrationAccessRightHistoryRecord list.  */
  _BillingIntegrationAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegrationAccessRight items which match the where clause.  */
  allBillingIntegrationAccessRights?: Maybe<Array<Maybe<BillingIntegrationAccessRight>>>;
  /**  Search for the BillingIntegrationAccessRight item with the matching ID.  */
  BillingIntegrationAccessRight?: Maybe<BillingIntegrationAccessRight>;
  /**  Perform a meta-query on all BillingIntegrationAccessRight items which match the where clause.  */
  _allBillingIntegrationAccessRightsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegrationAccessRight list.  */
  _BillingIntegrationAccessRightsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
  allBillingIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BillingIntegrationOrganizationContextHistoryRecord>>>;
  /**  Search for the BillingIntegrationOrganizationContextHistoryRecord item with the matching ID.  */
  BillingIntegrationOrganizationContextHistoryRecord?: Maybe<BillingIntegrationOrganizationContextHistoryRecord>;
  /**  Perform a meta-query on all BillingIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
  _allBillingIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegrationOrganizationContextHistoryRecord list.  */
  _BillingIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegrationOrganizationContext items which match the where clause.  */
  allBillingIntegrationOrganizationContexts?: Maybe<Array<Maybe<BillingIntegrationOrganizationContext>>>;
  /**  Search for the BillingIntegrationOrganizationContext item with the matching ID.  */
  BillingIntegrationOrganizationContext?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Perform a meta-query on all BillingIntegrationOrganizationContext items which match the where clause.  */
  _allBillingIntegrationOrganizationContextsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegrationOrganizationContext list.  */
  _BillingIntegrationOrganizationContextsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingIntegrationProblem items which match the where clause.  */
  allBillingIntegrationProblems?: Maybe<Array<Maybe<BillingIntegrationProblem>>>;
  /**  Search for the BillingIntegrationProblem item with the matching ID.  */
  BillingIntegrationProblem?: Maybe<BillingIntegrationProblem>;
  /**  Perform a meta-query on all BillingIntegrationProblem items which match the where clause.  */
  _allBillingIntegrationProblemsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingIntegrationProblem list.  */
  _BillingIntegrationProblemsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingPropertyHistoryRecord items which match the where clause.  */
  allBillingPropertyHistoryRecords?: Maybe<Array<Maybe<BillingPropertyHistoryRecord>>>;
  /**  Search for the BillingPropertyHistoryRecord item with the matching ID.  */
  BillingPropertyHistoryRecord?: Maybe<BillingPropertyHistoryRecord>;
  /**  Perform a meta-query on all BillingPropertyHistoryRecord items which match the where clause.  */
  _allBillingPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingPropertyHistoryRecord list.  */
  _BillingPropertyHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingProperty items which match the where clause.  */
  allBillingProperties?: Maybe<Array<Maybe<BillingProperty>>>;
  /**  Search for the BillingProperty item with the matching ID.  */
  BillingProperty?: Maybe<BillingProperty>;
  /**  Perform a meta-query on all BillingProperty items which match the where clause.  */
  _allBillingPropertiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingProperty list.  */
  _BillingPropertiesMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingAccountHistoryRecord items which match the where clause.  */
  allBillingAccountHistoryRecords?: Maybe<Array<Maybe<BillingAccountHistoryRecord>>>;
  /**  Search for the BillingAccountHistoryRecord item with the matching ID.  */
  BillingAccountHistoryRecord?: Maybe<BillingAccountHistoryRecord>;
  /**  Perform a meta-query on all BillingAccountHistoryRecord items which match the where clause.  */
  _allBillingAccountHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingAccountHistoryRecord list.  */
  _BillingAccountHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingAccount items which match the where clause.  */
  allBillingAccounts?: Maybe<Array<Maybe<BillingAccount>>>;
  /**  Search for the BillingAccount item with the matching ID.  */
  BillingAccount?: Maybe<BillingAccount>;
  /**  Perform a meta-query on all BillingAccount items which match the where clause.  */
  _allBillingAccountsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingAccount list.  */
  _BillingAccountsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingReceiptHistoryRecord items which match the where clause.  */
  allBillingReceiptHistoryRecords?: Maybe<Array<Maybe<BillingReceiptHistoryRecord>>>;
  /**  Search for the BillingReceiptHistoryRecord item with the matching ID.  */
  BillingReceiptHistoryRecord?: Maybe<BillingReceiptHistoryRecord>;
  /**  Perform a meta-query on all BillingReceiptHistoryRecord items which match the where clause.  */
  _allBillingReceiptHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingReceiptHistoryRecord list.  */
  _BillingReceiptHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingReceipt items which match the where clause.  */
  allBillingReceipts?: Maybe<Array<Maybe<BillingReceipt>>>;
  /**  Search for the BillingReceipt item with the matching ID.  */
  BillingReceipt?: Maybe<BillingReceipt>;
  /**  Perform a meta-query on all BillingReceipt items which match the where clause.  */
  _allBillingReceiptsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingReceipt list.  */
  _BillingReceiptsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingRecipientHistoryRecord items which match the where clause.  */
  allBillingRecipientHistoryRecords?: Maybe<Array<Maybe<BillingRecipientHistoryRecord>>>;
  /**  Search for the BillingRecipientHistoryRecord item with the matching ID.  */
  BillingRecipientHistoryRecord?: Maybe<BillingRecipientHistoryRecord>;
  /**  Perform a meta-query on all BillingRecipientHistoryRecord items which match the where clause.  */
  _allBillingRecipientHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingRecipientHistoryRecord list.  */
  _BillingRecipientHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingRecipient items which match the where clause.  */
  allBillingRecipients?: Maybe<Array<Maybe<BillingRecipient>>>;
  /**  Search for the BillingRecipient item with the matching ID.  */
  BillingRecipient?: Maybe<BillingRecipient>;
  /**  Perform a meta-query on all BillingRecipient items which match the where clause.  */
  _allBillingRecipientsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingRecipient list.  */
  _BillingRecipientsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingCategoryHistoryRecord items which match the where clause.  */
  allBillingCategoryHistoryRecords?: Maybe<Array<Maybe<BillingCategoryHistoryRecord>>>;
  /**  Search for the BillingCategoryHistoryRecord item with the matching ID.  */
  BillingCategoryHistoryRecord?: Maybe<BillingCategoryHistoryRecord>;
  /**  Perform a meta-query on all BillingCategoryHistoryRecord items which match the where clause.  */
  _allBillingCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingCategoryHistoryRecord list.  */
  _BillingCategoryHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingCategory items which match the where clause.  */
  allBillingCategories?: Maybe<Array<Maybe<BillingCategory>>>;
  /**  Search for the BillingCategory item with the matching ID.  */
  BillingCategory?: Maybe<BillingCategory>;
  /**  Perform a meta-query on all BillingCategory items which match the where clause.  */
  _allBillingCategoriesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingCategory list.  */
  _BillingCategoriesMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingReceiptFileHistoryRecord items which match the where clause.  */
  allBillingReceiptFileHistoryRecords?: Maybe<Array<Maybe<BillingReceiptFileHistoryRecord>>>;
  /**  Search for the BillingReceiptFileHistoryRecord item with the matching ID.  */
  BillingReceiptFileHistoryRecord?: Maybe<BillingReceiptFileHistoryRecord>;
  /**  Perform a meta-query on all BillingReceiptFileHistoryRecord items which match the where clause.  */
  _allBillingReceiptFileHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingReceiptFileHistoryRecord list.  */
  _BillingReceiptFileHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BillingReceiptFile items which match the where clause.  */
  allBillingReceiptFiles?: Maybe<Array<Maybe<BillingReceiptFile>>>;
  /**  Search for the BillingReceiptFile item with the matching ID.  */
  BillingReceiptFile?: Maybe<BillingReceiptFile>;
  /**  Perform a meta-query on all BillingReceiptFile items which match the where clause.  */
  _allBillingReceiptFilesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BillingReceiptFile list.  */
  _BillingReceiptFilesMeta?: Maybe<_ListMeta>;
  /**  Search for all BankAccountHistoryRecord items which match the where clause.  */
  allBankAccountHistoryRecords?: Maybe<Array<Maybe<BankAccountHistoryRecord>>>;
  /**  Search for the BankAccountHistoryRecord item with the matching ID.  */
  BankAccountHistoryRecord?: Maybe<BankAccountHistoryRecord>;
  /**  Perform a meta-query on all BankAccountHistoryRecord items which match the where clause.  */
  _allBankAccountHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankAccountHistoryRecord list.  */
  _BankAccountHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankAccount items which match the where clause.  */
  allBankAccounts?: Maybe<Array<Maybe<BankAccount>>>;
  /**  Search for the BankAccount item with the matching ID.  */
  BankAccount?: Maybe<BankAccount>;
  /**  Perform a meta-query on all BankAccount items which match the where clause.  */
  _allBankAccountsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankAccount list.  */
  _BankAccountsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankCategoryHistoryRecord items which match the where clause.  */
  allBankCategoryHistoryRecords?: Maybe<Array<Maybe<BankCategoryHistoryRecord>>>;
  /**  Search for the BankCategoryHistoryRecord item with the matching ID.  */
  BankCategoryHistoryRecord?: Maybe<BankCategoryHistoryRecord>;
  /**  Perform a meta-query on all BankCategoryHistoryRecord items which match the where clause.  */
  _allBankCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankCategoryHistoryRecord list.  */
  _BankCategoryHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankCategory items which match the where clause.  */
  allBankCategories?: Maybe<Array<Maybe<BankCategory>>>;
  /**  Search for the BankCategory item with the matching ID.  */
  BankCategory?: Maybe<BankCategory>;
  /**  Perform a meta-query on all BankCategory items which match the where clause.  */
  _allBankCategoriesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankCategory list.  */
  _BankCategoriesMeta?: Maybe<_ListMeta>;
  /**  Search for all BankCostItemHistoryRecord items which match the where clause.  */
  allBankCostItemHistoryRecords?: Maybe<Array<Maybe<BankCostItemHistoryRecord>>>;
  /**  Search for the BankCostItemHistoryRecord item with the matching ID.  */
  BankCostItemHistoryRecord?: Maybe<BankCostItemHistoryRecord>;
  /**  Perform a meta-query on all BankCostItemHistoryRecord items which match the where clause.  */
  _allBankCostItemHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankCostItemHistoryRecord list.  */
  _BankCostItemHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankCostItem items which match the where clause.  */
  allBankCostItems?: Maybe<Array<Maybe<BankCostItem>>>;
  /**  Search for the BankCostItem item with the matching ID.  */
  BankCostItem?: Maybe<BankCostItem>;
  /**  Perform a meta-query on all BankCostItem items which match the where clause.  */
  _allBankCostItemsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankCostItem list.  */
  _BankCostItemsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankContractorAccountHistoryRecord items which match the where clause.  */
  allBankContractorAccountHistoryRecords?: Maybe<Array<Maybe<BankContractorAccountHistoryRecord>>>;
  /**  Search for the BankContractorAccountHistoryRecord item with the matching ID.  */
  BankContractorAccountHistoryRecord?: Maybe<BankContractorAccountHistoryRecord>;
  /**  Perform a meta-query on all BankContractorAccountHistoryRecord items which match the where clause.  */
  _allBankContractorAccountHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankContractorAccountHistoryRecord list.  */
  _BankContractorAccountHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankContractorAccount items which match the where clause.  */
  allBankContractorAccounts?: Maybe<Array<Maybe<BankContractorAccount>>>;
  /**  Search for the BankContractorAccount item with the matching ID.  */
  BankContractorAccount?: Maybe<BankContractorAccount>;
  /**  Perform a meta-query on all BankContractorAccount items which match the where clause.  */
  _allBankContractorAccountsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankContractorAccount list.  */
  _BankContractorAccountsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationHistoryRecord items which match the where clause.  */
  allBankIntegrationHistoryRecords?: Maybe<Array<Maybe<BankIntegrationHistoryRecord>>>;
  /**  Search for the BankIntegrationHistoryRecord item with the matching ID.  */
  BankIntegrationHistoryRecord?: Maybe<BankIntegrationHistoryRecord>;
  /**  Perform a meta-query on all BankIntegrationHistoryRecord items which match the where clause.  */
  _allBankIntegrationHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationHistoryRecord list.  */
  _BankIntegrationHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegration items which match the where clause.  */
  allBankIntegrations?: Maybe<Array<Maybe<BankIntegration>>>;
  /**  Search for the BankIntegration item with the matching ID.  */
  BankIntegration?: Maybe<BankIntegration>;
  /**  Perform a meta-query on all BankIntegration items which match the where clause.  */
  _allBankIntegrationsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegration list.  */
  _BankIntegrationsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationAccessRightHistoryRecord items which match the where clause.  */
  allBankIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccessRightHistoryRecord>>>;
  /**  Search for the BankIntegrationAccessRightHistoryRecord item with the matching ID.  */
  BankIntegrationAccessRightHistoryRecord?: Maybe<BankIntegrationAccessRightHistoryRecord>;
  /**  Perform a meta-query on all BankIntegrationAccessRightHistoryRecord items which match the where clause.  */
  _allBankIntegrationAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationAccessRightHistoryRecord list.  */
  _BankIntegrationAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationAccessRight items which match the where clause.  */
  allBankIntegrationAccessRights?: Maybe<Array<Maybe<BankIntegrationAccessRight>>>;
  /**  Search for the BankIntegrationAccessRight item with the matching ID.  */
  BankIntegrationAccessRight?: Maybe<BankIntegrationAccessRight>;
  /**  Perform a meta-query on all BankIntegrationAccessRight items which match the where clause.  */
  _allBankIntegrationAccessRightsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationAccessRight list.  */
  _BankIntegrationAccessRightsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationAccountContextHistoryRecord items which match the where clause.  */
  allBankIntegrationAccountContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationAccountContextHistoryRecord>>>;
  /**  Search for the BankIntegrationAccountContextHistoryRecord item with the matching ID.  */
  BankIntegrationAccountContextHistoryRecord?: Maybe<BankIntegrationAccountContextHistoryRecord>;
  /**  Perform a meta-query on all BankIntegrationAccountContextHistoryRecord items which match the where clause.  */
  _allBankIntegrationAccountContextHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationAccountContextHistoryRecord list.  */
  _BankIntegrationAccountContextHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationAccountContext items which match the where clause.  */
  allBankIntegrationAccountContexts?: Maybe<Array<Maybe<BankIntegrationAccountContext>>>;
  /**  Search for the BankIntegrationAccountContext item with the matching ID.  */
  BankIntegrationAccountContext?: Maybe<BankIntegrationAccountContext>;
  /**  Perform a meta-query on all BankIntegrationAccountContext items which match the where clause.  */
  _allBankIntegrationAccountContextsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationAccountContext list.  */
  _BankIntegrationAccountContextsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankTransactionHistoryRecord items which match the where clause.  */
  allBankTransactionHistoryRecords?: Maybe<Array<Maybe<BankTransactionHistoryRecord>>>;
  /**  Search for the BankTransactionHistoryRecord item with the matching ID.  */
  BankTransactionHistoryRecord?: Maybe<BankTransactionHistoryRecord>;
  /**  Perform a meta-query on all BankTransactionHistoryRecord items which match the where clause.  */
  _allBankTransactionHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankTransactionHistoryRecord list.  */
  _BankTransactionHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankTransaction items which match the where clause.  */
  allBankTransactions?: Maybe<Array<Maybe<BankTransaction>>>;
  /**  Search for the BankTransaction item with the matching ID.  */
  BankTransaction?: Maybe<BankTransaction>;
  /**  Perform a meta-query on all BankTransaction items which match the where clause.  */
  _allBankTransactionsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankTransaction list.  */
  _BankTransactionsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankSyncTaskHistoryRecord items which match the where clause.  */
  allBankSyncTaskHistoryRecords?: Maybe<Array<Maybe<BankSyncTaskHistoryRecord>>>;
  /**  Search for the BankSyncTaskHistoryRecord item with the matching ID.  */
  BankSyncTaskHistoryRecord?: Maybe<BankSyncTaskHistoryRecord>;
  /**  Perform a meta-query on all BankSyncTaskHistoryRecord items which match the where clause.  */
  _allBankSyncTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankSyncTaskHistoryRecord list.  */
  _BankSyncTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankSyncTask items which match the where clause.  */
  allBankSyncTasks?: Maybe<Array<Maybe<BankSyncTask>>>;
  /**  Search for the BankSyncTask item with the matching ID.  */
  BankSyncTask?: Maybe<BankSyncTask>;
  /**  Perform a meta-query on all BankSyncTask items which match the where clause.  */
  _allBankSyncTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankSyncTask list.  */
  _BankSyncTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
  allBankIntegrationOrganizationContextHistoryRecords?: Maybe<Array<Maybe<BankIntegrationOrganizationContextHistoryRecord>>>;
  /**  Search for the BankIntegrationOrganizationContextHistoryRecord item with the matching ID.  */
  BankIntegrationOrganizationContextHistoryRecord?: Maybe<BankIntegrationOrganizationContextHistoryRecord>;
  /**  Perform a meta-query on all BankIntegrationOrganizationContextHistoryRecord items which match the where clause.  */
  _allBankIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationOrganizationContextHistoryRecord list.  */
  _BankIntegrationOrganizationContextHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankIntegrationOrganizationContext items which match the where clause.  */
  allBankIntegrationOrganizationContexts?: Maybe<Array<Maybe<BankIntegrationOrganizationContext>>>;
  /**  Search for the BankIntegrationOrganizationContext item with the matching ID.  */
  BankIntegrationOrganizationContext?: Maybe<BankIntegrationOrganizationContext>;
  /**  Perform a meta-query on all BankIntegrationOrganizationContext items which match the where clause.  */
  _allBankIntegrationOrganizationContextsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankIntegrationOrganizationContext list.  */
  _BankIntegrationOrganizationContextsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankAccountReportHistoryRecord items which match the where clause.  */
  allBankAccountReportHistoryRecords?: Maybe<Array<Maybe<BankAccountReportHistoryRecord>>>;
  /**  Search for the BankAccountReportHistoryRecord item with the matching ID.  */
  BankAccountReportHistoryRecord?: Maybe<BankAccountReportHistoryRecord>;
  /**  Perform a meta-query on all BankAccountReportHistoryRecord items which match the where clause.  */
  _allBankAccountReportHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankAccountReportHistoryRecord list.  */
  _BankAccountReportHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankAccountReport items which match the where clause.  */
  allBankAccountReports?: Maybe<Array<Maybe<BankAccountReport>>>;
  /**  Search for the BankAccountReport item with the matching ID.  */
  BankAccountReport?: Maybe<BankAccountReport>;
  /**  Perform a meta-query on all BankAccountReport items which match the where clause.  */
  _allBankAccountReportsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankAccountReport list.  */
  _BankAccountReportsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankAccountReportTaskHistoryRecord items which match the where clause.  */
  allBankAccountReportTaskHistoryRecords?: Maybe<Array<Maybe<BankAccountReportTaskHistoryRecord>>>;
  /**  Search for the BankAccountReportTaskHistoryRecord item with the matching ID.  */
  BankAccountReportTaskHistoryRecord?: Maybe<BankAccountReportTaskHistoryRecord>;
  /**  Perform a meta-query on all BankAccountReportTaskHistoryRecord items which match the where clause.  */
  _allBankAccountReportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankAccountReportTaskHistoryRecord list.  */
  _BankAccountReportTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all BankAccountReportTask items which match the where clause.  */
  allBankAccountReportTasks?: Maybe<Array<Maybe<BankAccountReportTask>>>;
  /**  Search for the BankAccountReportTask item with the matching ID.  */
  BankAccountReportTask?: Maybe<BankAccountReportTask>;
  /**  Perform a meta-query on all BankAccountReportTask items which match the where clause.  */
  _allBankAccountReportTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the BankAccountReportTask list.  */
  _BankAccountReportTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketHistoryRecord items which match the where clause.  */
  allTicketHistoryRecords?: Maybe<Array<Maybe<TicketHistoryRecord>>>;
  /**  Search for the TicketHistoryRecord item with the matching ID.  */
  TicketHistoryRecord?: Maybe<TicketHistoryRecord>;
  /**  Perform a meta-query on all TicketHistoryRecord items which match the where clause.  */
  _allTicketHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketHistoryRecord list.  */
  _TicketHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Ticket items which match the where clause.  */
  allTickets?: Maybe<Array<Maybe<Ticket>>>;
  /**  Search for the Ticket item with the matching ID.  */
  Ticket?: Maybe<Ticket>;
  /**  Perform a meta-query on all Ticket items which match the where clause.  */
  _allTicketsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Ticket list.  */
  _TicketsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketSourceHistoryRecord items which match the where clause.  */
  allTicketSourceHistoryRecords?: Maybe<Array<Maybe<TicketSourceHistoryRecord>>>;
  /**  Search for the TicketSourceHistoryRecord item with the matching ID.  */
  TicketSourceHistoryRecord?: Maybe<TicketSourceHistoryRecord>;
  /**  Perform a meta-query on all TicketSourceHistoryRecord items which match the where clause.  */
  _allTicketSourceHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketSourceHistoryRecord list.  */
  _TicketSourceHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketSource items which match the where clause.  */
  allTicketSources?: Maybe<Array<Maybe<TicketSource>>>;
  /**  Search for the TicketSource item with the matching ID.  */
  TicketSource?: Maybe<TicketSource>;
  /**  Perform a meta-query on all TicketSource items which match the where clause.  */
  _allTicketSourcesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketSource list.  */
  _TicketSourcesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketStatusHistoryRecord items which match the where clause.  */
  allTicketStatusHistoryRecords?: Maybe<Array<Maybe<TicketStatusHistoryRecord>>>;
  /**  Search for the TicketStatusHistoryRecord item with the matching ID.  */
  TicketStatusHistoryRecord?: Maybe<TicketStatusHistoryRecord>;
  /**  Perform a meta-query on all TicketStatusHistoryRecord items which match the where clause.  */
  _allTicketStatusHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketStatusHistoryRecord list.  */
  _TicketStatusHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketStatus items which match the where clause.  */
  allTicketStatuses?: Maybe<Array<Maybe<TicketStatus>>>;
  /**  Search for the TicketStatus item with the matching ID.  */
  TicketStatus?: Maybe<TicketStatus>;
  /**  Perform a meta-query on all TicketStatus items which match the where clause.  */
  _allTicketStatusesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketStatus list.  */
  _TicketStatusesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketFileHistoryRecord items which match the where clause.  */
  allTicketFileHistoryRecords?: Maybe<Array<Maybe<TicketFileHistoryRecord>>>;
  /**  Search for the TicketFileHistoryRecord item with the matching ID.  */
  TicketFileHistoryRecord?: Maybe<TicketFileHistoryRecord>;
  /**  Perform a meta-query on all TicketFileHistoryRecord items which match the where clause.  */
  _allTicketFileHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketFileHistoryRecord list.  */
  _TicketFileHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketFile items which match the where clause.  */
  allTicketFiles?: Maybe<Array<Maybe<TicketFile>>>;
  /**  Search for the TicketFile item with the matching ID.  */
  TicketFile?: Maybe<TicketFile>;
  /**  Perform a meta-query on all TicketFile items which match the where clause.  */
  _allTicketFilesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketFile list.  */
  _TicketFilesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketChange items which match the where clause.  */
  allTicketChanges?: Maybe<Array<Maybe<TicketChange>>>;
  /**  Search for the TicketChange item with the matching ID.  */
  TicketChange?: Maybe<TicketChange>;
  /**  Perform a meta-query on all TicketChange items which match the where clause.  */
  _allTicketChangesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketChange list.  */
  _TicketChangesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketCommentHistoryRecord items which match the where clause.  */
  allTicketCommentHistoryRecords?: Maybe<Array<Maybe<TicketCommentHistoryRecord>>>;
  /**  Search for the TicketCommentHistoryRecord item with the matching ID.  */
  TicketCommentHistoryRecord?: Maybe<TicketCommentHistoryRecord>;
  /**  Perform a meta-query on all TicketCommentHistoryRecord items which match the where clause.  */
  _allTicketCommentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketCommentHistoryRecord list.  */
  _TicketCommentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketComment items which match the where clause.  */
  allTicketComments?: Maybe<Array<Maybe<TicketComment>>>;
  /**  Search for the TicketComment item with the matching ID.  */
  TicketComment?: Maybe<TicketComment>;
  /**  Perform a meta-query on all TicketComment items which match the where clause.  */
  _allTicketCommentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketComment list.  */
  _TicketCommentsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketPlaceClassifierHistoryRecord items which match the where clause.  */
  allTicketPlaceClassifierHistoryRecords?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecord>>>;
  /**  Search for the TicketPlaceClassifierHistoryRecord item with the matching ID.  */
  TicketPlaceClassifierHistoryRecord?: Maybe<TicketPlaceClassifierHistoryRecord>;
  /**  Perform a meta-query on all TicketPlaceClassifierHistoryRecord items which match the where clause.  */
  _allTicketPlaceClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketPlaceClassifierHistoryRecord list.  */
  _TicketPlaceClassifierHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketPlaceClassifier items which match the where clause.  */
  allTicketPlaceClassifiers?: Maybe<Array<Maybe<TicketPlaceClassifier>>>;
  /**  Search for the TicketPlaceClassifier item with the matching ID.  */
  TicketPlaceClassifier?: Maybe<TicketPlaceClassifier>;
  /**  Perform a meta-query on all TicketPlaceClassifier items which match the where clause.  */
  _allTicketPlaceClassifiersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketPlaceClassifier list.  */
  _TicketPlaceClassifiersMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketCategoryClassifierHistoryRecord items which match the where clause.  */
  allTicketCategoryClassifierHistoryRecords?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecord>>>;
  /**  Search for the TicketCategoryClassifierHistoryRecord item with the matching ID.  */
  TicketCategoryClassifierHistoryRecord?: Maybe<TicketCategoryClassifierHistoryRecord>;
  /**  Perform a meta-query on all TicketCategoryClassifierHistoryRecord items which match the where clause.  */
  _allTicketCategoryClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketCategoryClassifierHistoryRecord list.  */
  _TicketCategoryClassifierHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketCategoryClassifier items which match the where clause.  */
  allTicketCategoryClassifiers?: Maybe<Array<Maybe<TicketCategoryClassifier>>>;
  /**  Search for the TicketCategoryClassifier item with the matching ID.  */
  TicketCategoryClassifier?: Maybe<TicketCategoryClassifier>;
  /**  Perform a meta-query on all TicketCategoryClassifier items which match the where clause.  */
  _allTicketCategoryClassifiersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketCategoryClassifier list.  */
  _TicketCategoryClassifiersMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketProblemClassifierHistoryRecord items which match the where clause.  */
  allTicketProblemClassifierHistoryRecords?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecord>>>;
  /**  Search for the TicketProblemClassifierHistoryRecord item with the matching ID.  */
  TicketProblemClassifierHistoryRecord?: Maybe<TicketProblemClassifierHistoryRecord>;
  /**  Perform a meta-query on all TicketProblemClassifierHistoryRecord items which match the where clause.  */
  _allTicketProblemClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketProblemClassifierHistoryRecord list.  */
  _TicketProblemClassifierHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketProblemClassifier items which match the where clause.  */
  allTicketProblemClassifiers?: Maybe<Array<Maybe<TicketProblemClassifier>>>;
  /**  Search for the TicketProblemClassifier item with the matching ID.  */
  TicketProblemClassifier?: Maybe<TicketProblemClassifier>;
  /**  Perform a meta-query on all TicketProblemClassifier items which match the where clause.  */
  _allTicketProblemClassifiersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketProblemClassifier list.  */
  _TicketProblemClassifiersMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketClassifierHistoryRecord items which match the where clause.  */
  allTicketClassifierHistoryRecords?: Maybe<Array<Maybe<TicketClassifierHistoryRecord>>>;
  /**  Search for the TicketClassifierHistoryRecord item with the matching ID.  */
  TicketClassifierHistoryRecord?: Maybe<TicketClassifierHistoryRecord>;
  /**  Perform a meta-query on all TicketClassifierHistoryRecord items which match the where clause.  */
  _allTicketClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketClassifierHistoryRecord list.  */
  _TicketClassifierHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketClassifier items which match the where clause.  */
  allTicketClassifiers?: Maybe<Array<Maybe<TicketClassifier>>>;
  /**  Search for the TicketClassifier item with the matching ID.  */
  TicketClassifier?: Maybe<TicketClassifier>;
  /**  Perform a meta-query on all TicketClassifier items which match the where clause.  */
  _allTicketClassifiersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketClassifier list.  */
  _TicketClassifiersMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketFilterTemplateHistoryRecord items which match the where clause.  */
  allTicketFilterTemplateHistoryRecords?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecord>>>;
  /**  Search for the TicketFilterTemplateHistoryRecord item with the matching ID.  */
  TicketFilterTemplateHistoryRecord?: Maybe<TicketFilterTemplateHistoryRecord>;
  /**  Perform a meta-query on all TicketFilterTemplateHistoryRecord items which match the where clause.  */
  _allTicketFilterTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketFilterTemplateHistoryRecord list.  */
  _TicketFilterTemplateHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketFilterTemplate items which match the where clause.  */
  allTicketFilterTemplates?: Maybe<Array<Maybe<TicketFilterTemplate>>>;
  /**  Search for the TicketFilterTemplate item with the matching ID.  */
  TicketFilterTemplate?: Maybe<TicketFilterTemplate>;
  /**  Perform a meta-query on all TicketFilterTemplate items which match the where clause.  */
  _allTicketFilterTemplatesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketFilterTemplate list.  */
  _TicketFilterTemplatesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketCommentFileHistoryRecord items which match the where clause.  */
  allTicketCommentFileHistoryRecords?: Maybe<Array<Maybe<TicketCommentFileHistoryRecord>>>;
  /**  Search for the TicketCommentFileHistoryRecord item with the matching ID.  */
  TicketCommentFileHistoryRecord?: Maybe<TicketCommentFileHistoryRecord>;
  /**  Perform a meta-query on all TicketCommentFileHistoryRecord items which match the where clause.  */
  _allTicketCommentFileHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketCommentFileHistoryRecord list.  */
  _TicketCommentFileHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketCommentFile items which match the where clause.  */
  allTicketCommentFiles?: Maybe<Array<Maybe<TicketCommentFile>>>;
  /**  Search for the TicketCommentFile item with the matching ID.  */
  TicketCommentFile?: Maybe<TicketCommentFile>;
  /**  Perform a meta-query on all TicketCommentFile items which match the where clause.  */
  _allTicketCommentFilesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketCommentFile list.  */
  _TicketCommentFilesMeta?: Maybe<_ListMeta>;
  /**  Search for all UserTicketCommentReadTimeHistoryRecord items which match the where clause.  */
  allUserTicketCommentReadTimeHistoryRecords?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecord>>>;
  /**  Search for the UserTicketCommentReadTimeHistoryRecord item with the matching ID.  */
  UserTicketCommentReadTimeHistoryRecord?: Maybe<UserTicketCommentReadTimeHistoryRecord>;
  /**  Perform a meta-query on all UserTicketCommentReadTimeHistoryRecord items which match the where clause.  */
  _allUserTicketCommentReadTimeHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserTicketCommentReadTimeHistoryRecord list.  */
  _UserTicketCommentReadTimeHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserTicketCommentReadTime items which match the where clause.  */
  allUserTicketCommentReadTimes?: Maybe<Array<Maybe<UserTicketCommentReadTime>>>;
  /**  Search for the UserTicketCommentReadTime item with the matching ID.  */
  UserTicketCommentReadTime?: Maybe<UserTicketCommentReadTime>;
  /**  Perform a meta-query on all UserTicketCommentReadTime items which match the where clause.  */
  _allUserTicketCommentReadTimesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserTicketCommentReadTime list.  */
  _UserTicketCommentReadTimesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketPropertyHintHistoryRecord items which match the where clause.  */
  allTicketPropertyHintHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecord>>>;
  /**  Search for the TicketPropertyHintHistoryRecord item with the matching ID.  */
  TicketPropertyHintHistoryRecord?: Maybe<TicketPropertyHintHistoryRecord>;
  /**  Perform a meta-query on all TicketPropertyHintHistoryRecord items which match the where clause.  */
  _allTicketPropertyHintHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketPropertyHintHistoryRecord list.  */
  _TicketPropertyHintHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketPropertyHint items which match the where clause.  */
  allTicketPropertyHints?: Maybe<Array<Maybe<TicketPropertyHint>>>;
  /**  Search for the TicketPropertyHint item with the matching ID.  */
  TicketPropertyHint?: Maybe<TicketPropertyHint>;
  /**  Perform a meta-query on all TicketPropertyHint items which match the where clause.  */
  _allTicketPropertyHintsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketPropertyHint list.  */
  _TicketPropertyHintsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketPropertyHintPropertyHistoryRecord items which match the where clause.  */
  allTicketPropertyHintPropertyHistoryRecords?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecord>>>;
  /**  Search for the TicketPropertyHintPropertyHistoryRecord item with the matching ID.  */
  TicketPropertyHintPropertyHistoryRecord?: Maybe<TicketPropertyHintPropertyHistoryRecord>;
  /**  Perform a meta-query on all TicketPropertyHintPropertyHistoryRecord items which match the where clause.  */
  _allTicketPropertyHintPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketPropertyHintPropertyHistoryRecord list.  */
  _TicketPropertyHintPropertyHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketPropertyHintProperty items which match the where clause.  */
  allTicketPropertyHintProperties?: Maybe<Array<Maybe<TicketPropertyHintProperty>>>;
  /**  Search for the TicketPropertyHintProperty item with the matching ID.  */
  TicketPropertyHintProperty?: Maybe<TicketPropertyHintProperty>;
  /**  Perform a meta-query on all TicketPropertyHintProperty items which match the where clause.  */
  _allTicketPropertyHintPropertiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketPropertyHintProperty list.  */
  _TicketPropertyHintPropertiesMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketExportTaskHistoryRecord items which match the where clause.  */
  allTicketExportTaskHistoryRecords?: Maybe<Array<Maybe<TicketExportTaskHistoryRecord>>>;
  /**  Search for the TicketExportTaskHistoryRecord item with the matching ID.  */
  TicketExportTaskHistoryRecord?: Maybe<TicketExportTaskHistoryRecord>;
  /**  Perform a meta-query on all TicketExportTaskHistoryRecord items which match the where clause.  */
  _allTicketExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketExportTaskHistoryRecord list.  */
  _TicketExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketExportTask items which match the where clause.  */
  allTicketExportTasks?: Maybe<Array<Maybe<TicketExportTask>>>;
  /**  Search for the TicketExportTask item with the matching ID.  */
  TicketExportTask?: Maybe<TicketExportTask>;
  /**  Perform a meta-query on all TicketExportTask items which match the where clause.  */
  _allTicketExportTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketExportTask list.  */
  _TicketExportTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketOrganizationSettingHistoryRecord items which match the where clause.  */
  allTicketOrganizationSettingHistoryRecords?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecord>>>;
  /**  Search for the TicketOrganizationSettingHistoryRecord item with the matching ID.  */
  TicketOrganizationSettingHistoryRecord?: Maybe<TicketOrganizationSettingHistoryRecord>;
  /**  Perform a meta-query on all TicketOrganizationSettingHistoryRecord items which match the where clause.  */
  _allTicketOrganizationSettingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketOrganizationSettingHistoryRecord list.  */
  _TicketOrganizationSettingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketOrganizationSetting items which match the where clause.  */
  allTicketOrganizationSettings?: Maybe<Array<Maybe<TicketOrganizationSetting>>>;
  /**  Search for the TicketOrganizationSetting item with the matching ID.  */
  TicketOrganizationSetting?: Maybe<TicketOrganizationSetting>;
  /**  Perform a meta-query on all TicketOrganizationSetting items which match the where clause.  */
  _allTicketOrganizationSettingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketOrganizationSetting list.  */
  _TicketOrganizationSettingsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentHistoryRecord items which match the where clause.  */
  allIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentHistoryRecord>>>;
  /**  Search for the IncidentHistoryRecord item with the matching ID.  */
  IncidentHistoryRecord?: Maybe<IncidentHistoryRecord>;
  /**  Perform a meta-query on all IncidentHistoryRecord items which match the where clause.  */
  _allIncidentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentHistoryRecord list.  */
  _IncidentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Incident items which match the where clause.  */
  allIncidents?: Maybe<Array<Maybe<Incident>>>;
  /**  Search for the Incident item with the matching ID.  */
  Incident?: Maybe<Incident>;
  /**  Perform a meta-query on all Incident items which match the where clause.  */
  _allIncidentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Incident list.  */
  _IncidentsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentChange items which match the where clause.  */
  allIncidentChanges?: Maybe<Array<Maybe<IncidentChange>>>;
  /**  Search for the IncidentChange item with the matching ID.  */
  IncidentChange?: Maybe<IncidentChange>;
  /**  Perform a meta-query on all IncidentChange items which match the where clause.  */
  _allIncidentChangesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentChange list.  */
  _IncidentChangesMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentPropertyHistoryRecord items which match the where clause.  */
  allIncidentPropertyHistoryRecords?: Maybe<Array<Maybe<IncidentPropertyHistoryRecord>>>;
  /**  Search for the IncidentPropertyHistoryRecord item with the matching ID.  */
  IncidentPropertyHistoryRecord?: Maybe<IncidentPropertyHistoryRecord>;
  /**  Perform a meta-query on all IncidentPropertyHistoryRecord items which match the where clause.  */
  _allIncidentPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentPropertyHistoryRecord list.  */
  _IncidentPropertyHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentProperty items which match the where clause.  */
  allIncidentProperties?: Maybe<Array<Maybe<IncidentProperty>>>;
  /**  Search for the IncidentProperty item with the matching ID.  */
  IncidentProperty?: Maybe<IncidentProperty>;
  /**  Perform a meta-query on all IncidentProperty items which match the where clause.  */
  _allIncidentPropertiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentProperty list.  */
  _IncidentPropertiesMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentClassifierHistoryRecord items which match the where clause.  */
  allIncidentClassifierHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierHistoryRecord>>>;
  /**  Search for the IncidentClassifierHistoryRecord item with the matching ID.  */
  IncidentClassifierHistoryRecord?: Maybe<IncidentClassifierHistoryRecord>;
  /**  Perform a meta-query on all IncidentClassifierHistoryRecord items which match the where clause.  */
  _allIncidentClassifierHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentClassifierHistoryRecord list.  */
  _IncidentClassifierHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentClassifier items which match the where clause.  */
  allIncidentClassifiers?: Maybe<Array<Maybe<IncidentClassifier>>>;
  /**  Search for the IncidentClassifier item with the matching ID.  */
  IncidentClassifier?: Maybe<IncidentClassifier>;
  /**  Perform a meta-query on all IncidentClassifier items which match the where clause.  */
  _allIncidentClassifiersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentClassifier list.  */
  _IncidentClassifiersMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentClassifierIncidentHistoryRecord items which match the where clause.  */
  allIncidentClassifierIncidentHistoryRecords?: Maybe<Array<Maybe<IncidentClassifierIncidentHistoryRecord>>>;
  /**  Search for the IncidentClassifierIncidentHistoryRecord item with the matching ID.  */
  IncidentClassifierIncidentHistoryRecord?: Maybe<IncidentClassifierIncidentHistoryRecord>;
  /**  Perform a meta-query on all IncidentClassifierIncidentHistoryRecord items which match the where clause.  */
  _allIncidentClassifierIncidentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentClassifierIncidentHistoryRecord list.  */
  _IncidentClassifierIncidentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentClassifierIncident items which match the where clause.  */
  allIncidentClassifierIncidents?: Maybe<Array<Maybe<IncidentClassifierIncident>>>;
  /**  Search for the IncidentClassifierIncident item with the matching ID.  */
  IncidentClassifierIncident?: Maybe<IncidentClassifierIncident>;
  /**  Perform a meta-query on all IncidentClassifierIncident items which match the where clause.  */
  _allIncidentClassifierIncidentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentClassifierIncident list.  */
  _IncidentClassifierIncidentsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserFavoriteTicketHistoryRecord items which match the where clause.  */
  allUserFavoriteTicketHistoryRecords?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecord>>>;
  /**  Search for the UserFavoriteTicketHistoryRecord item with the matching ID.  */
  UserFavoriteTicketHistoryRecord?: Maybe<UserFavoriteTicketHistoryRecord>;
  /**  Perform a meta-query on all UserFavoriteTicketHistoryRecord items which match the where clause.  */
  _allUserFavoriteTicketHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserFavoriteTicketHistoryRecord list.  */
  _UserFavoriteTicketHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserFavoriteTicket items which match the where clause.  */
  allUserFavoriteTickets?: Maybe<Array<Maybe<UserFavoriteTicket>>>;
  /**  Search for the UserFavoriteTicket item with the matching ID.  */
  UserFavoriteTicket?: Maybe<UserFavoriteTicket>;
  /**  Perform a meta-query on all UserFavoriteTicket items which match the where clause.  */
  _allUserFavoriteTicketsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserFavoriteTicket list.  */
  _UserFavoriteTicketsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentExportTaskHistoryRecord items which match the where clause.  */
  allIncidentExportTaskHistoryRecords?: Maybe<Array<Maybe<IncidentExportTaskHistoryRecord>>>;
  /**  Search for the IncidentExportTaskHistoryRecord item with the matching ID.  */
  IncidentExportTaskHistoryRecord?: Maybe<IncidentExportTaskHistoryRecord>;
  /**  Perform a meta-query on all IncidentExportTaskHistoryRecord items which match the where clause.  */
  _allIncidentExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentExportTaskHistoryRecord list.  */
  _IncidentExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all IncidentExportTask items which match the where clause.  */
  allIncidentExportTasks?: Maybe<Array<Maybe<IncidentExportTask>>>;
  /**  Search for the IncidentExportTask item with the matching ID.  */
  IncidentExportTask?: Maybe<IncidentExportTask>;
  /**  Perform a meta-query on all IncidentExportTask items which match the where clause.  */
  _allIncidentExportTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the IncidentExportTask list.  */
  _IncidentExportTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all CallRecordHistoryRecord items which match the where clause.  */
  allCallRecordHistoryRecords?: Maybe<Array<Maybe<CallRecordHistoryRecord>>>;
  /**  Search for the CallRecordHistoryRecord item with the matching ID.  */
  CallRecordHistoryRecord?: Maybe<CallRecordHistoryRecord>;
  /**  Perform a meta-query on all CallRecordHistoryRecord items which match the where clause.  */
  _allCallRecordHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the CallRecordHistoryRecord list.  */
  _CallRecordHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all CallRecord items which match the where clause.  */
  allCallRecords?: Maybe<Array<Maybe<CallRecord>>>;
  /**  Search for the CallRecord item with the matching ID.  */
  CallRecord?: Maybe<CallRecord>;
  /**  Perform a meta-query on all CallRecord items which match the where clause.  */
  _allCallRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the CallRecord list.  */
  _CallRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all CallRecordFragmentHistoryRecord items which match the where clause.  */
  allCallRecordFragmentHistoryRecords?: Maybe<Array<Maybe<CallRecordFragmentHistoryRecord>>>;
  /**  Search for the CallRecordFragmentHistoryRecord item with the matching ID.  */
  CallRecordFragmentHistoryRecord?: Maybe<CallRecordFragmentHistoryRecord>;
  /**  Perform a meta-query on all CallRecordFragmentHistoryRecord items which match the where clause.  */
  _allCallRecordFragmentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the CallRecordFragmentHistoryRecord list.  */
  _CallRecordFragmentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all CallRecordFragment items which match the where clause.  */
  allCallRecordFragments?: Maybe<Array<Maybe<CallRecordFragment>>>;
  /**  Search for the CallRecordFragment item with the matching ID.  */
  CallRecordFragment?: Maybe<CallRecordFragment>;
  /**  Perform a meta-query on all CallRecordFragment items which match the where clause.  */
  _allCallRecordFragmentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the CallRecordFragment list.  */
  _CallRecordFragmentsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketAutoAssignmentHistoryRecord items which match the where clause.  */
  allTicketAutoAssignmentHistoryRecords?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecord>>>;
  /**  Search for the TicketAutoAssignmentHistoryRecord item with the matching ID.  */
  TicketAutoAssignmentHistoryRecord?: Maybe<TicketAutoAssignmentHistoryRecord>;
  /**  Perform a meta-query on all TicketAutoAssignmentHistoryRecord items which match the where clause.  */
  _allTicketAutoAssignmentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketAutoAssignmentHistoryRecord list.  */
  _TicketAutoAssignmentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketAutoAssignment items which match the where clause.  */
  allTicketAutoAssignments?: Maybe<Array<Maybe<TicketAutoAssignment>>>;
  /**  Search for the TicketAutoAssignment item with the matching ID.  */
  TicketAutoAssignment?: Maybe<TicketAutoAssignment>;
  /**  Perform a meta-query on all TicketAutoAssignment items which match the where clause.  */
  _allTicketAutoAssignmentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketAutoAssignment list.  */
  _TicketAutoAssignmentsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketDocumentGenerationTaskHistoryRecord items which match the where clause.  */
  allTicketDocumentGenerationTaskHistoryRecords?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecord>>>;
  /**  Search for the TicketDocumentGenerationTaskHistoryRecord item with the matching ID.  */
  TicketDocumentGenerationTaskHistoryRecord?: Maybe<TicketDocumentGenerationTaskHistoryRecord>;
  /**  Perform a meta-query on all TicketDocumentGenerationTaskHistoryRecord items which match the where clause.  */
  _allTicketDocumentGenerationTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketDocumentGenerationTaskHistoryRecord list.  */
  _TicketDocumentGenerationTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TicketDocumentGenerationTask items which match the where clause.  */
  allTicketDocumentGenerationTasks?: Maybe<Array<Maybe<TicketDocumentGenerationTask>>>;
  /**  Search for the TicketDocumentGenerationTask item with the matching ID.  */
  TicketDocumentGenerationTask?: Maybe<TicketDocumentGenerationTask>;
  /**  Perform a meta-query on all TicketDocumentGenerationTask items which match the where clause.  */
  _allTicketDocumentGenerationTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TicketDocumentGenerationTask list.  */
  _TicketDocumentGenerationTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageHistoryRecord items which match the where clause.  */
  allMessageHistoryRecords?: Maybe<Array<Maybe<MessageHistoryRecord>>>;
  /**  Search for the MessageHistoryRecord item with the matching ID.  */
  MessageHistoryRecord?: Maybe<MessageHistoryRecord>;
  /**  Perform a meta-query on all MessageHistoryRecord items which match the where clause.  */
  _allMessageHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageHistoryRecord list.  */
  _MessageHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Message items which match the where clause.  */
  allMessages?: Maybe<Array<Maybe<Message>>>;
  /**  Search for the Message item with the matching ID.  */
  Message?: Maybe<Message>;
  /**  Perform a meta-query on all Message items which match the where clause.  */
  _allMessagesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Message list.  */
  _MessagesMeta?: Maybe<_ListMeta>;
  /**  Search for all RemoteClientHistoryRecord items which match the where clause.  */
  allRemoteClientHistoryRecords?: Maybe<Array<Maybe<RemoteClientHistoryRecord>>>;
  /**  Search for the RemoteClientHistoryRecord item with the matching ID.  */
  RemoteClientHistoryRecord?: Maybe<RemoteClientHistoryRecord>;
  /**  Perform a meta-query on all RemoteClientHistoryRecord items which match the where clause.  */
  _allRemoteClientHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the RemoteClientHistoryRecord list.  */
  _RemoteClientHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all RemoteClient items which match the where clause.  */
  allRemoteClients?: Maybe<Array<Maybe<RemoteClient>>>;
  /**  Search for the RemoteClient item with the matching ID.  */
  RemoteClient?: Maybe<RemoteClient>;
  /**  Perform a meta-query on all RemoteClient items which match the where clause.  */
  _allRemoteClientsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the RemoteClient list.  */
  _RemoteClientsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageUserBlackListHistoryRecord items which match the where clause.  */
  allMessageUserBlackListHistoryRecords?: Maybe<Array<Maybe<MessageUserBlackListHistoryRecord>>>;
  /**  Search for the MessageUserBlackListHistoryRecord item with the matching ID.  */
  MessageUserBlackListHistoryRecord?: Maybe<MessageUserBlackListHistoryRecord>;
  /**  Perform a meta-query on all MessageUserBlackListHistoryRecord items which match the where clause.  */
  _allMessageUserBlackListHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageUserBlackListHistoryRecord list.  */
  _MessageUserBlackListHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageUserBlackList items which match the where clause.  */
  allMessageUserBlackLists?: Maybe<Array<Maybe<MessageUserBlackList>>>;
  /**  Search for the MessageUserBlackList item with the matching ID.  */
  MessageUserBlackList?: Maybe<MessageUserBlackList>;
  /**  Perform a meta-query on all MessageUserBlackList items which match the where clause.  */
  _allMessageUserBlackListsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageUserBlackList list.  */
  _MessageUserBlackListsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageOrganizationBlackListHistoryRecord items which match the where clause.  */
  allMessageOrganizationBlackListHistoryRecords?: Maybe<Array<Maybe<MessageOrganizationBlackListHistoryRecord>>>;
  /**  Search for the MessageOrganizationBlackListHistoryRecord item with the matching ID.  */
  MessageOrganizationBlackListHistoryRecord?: Maybe<MessageOrganizationBlackListHistoryRecord>;
  /**  Perform a meta-query on all MessageOrganizationBlackListHistoryRecord items which match the where clause.  */
  _allMessageOrganizationBlackListHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageOrganizationBlackListHistoryRecord list.  */
  _MessageOrganizationBlackListHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageOrganizationBlackList items which match the where clause.  */
  allMessageOrganizationBlackLists?: Maybe<Array<Maybe<MessageOrganizationBlackList>>>;
  /**  Search for the MessageOrganizationBlackList item with the matching ID.  */
  MessageOrganizationBlackList?: Maybe<MessageOrganizationBlackList>;
  /**  Perform a meta-query on all MessageOrganizationBlackList items which match the where clause.  */
  _allMessageOrganizationBlackListsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageOrganizationBlackList list.  */
  _MessageOrganizationBlackListsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageBatchHistoryRecord items which match the where clause.  */
  allMessageBatchHistoryRecords?: Maybe<Array<Maybe<MessageBatchHistoryRecord>>>;
  /**  Search for the MessageBatchHistoryRecord item with the matching ID.  */
  MessageBatchHistoryRecord?: Maybe<MessageBatchHistoryRecord>;
  /**  Perform a meta-query on all MessageBatchHistoryRecord items which match the where clause.  */
  _allMessageBatchHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageBatchHistoryRecord list.  */
  _MessageBatchHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageBatch items which match the where clause.  */
  allMessageBatches?: Maybe<Array<Maybe<MessageBatch>>>;
  /**  Search for the MessageBatch item with the matching ID.  */
  MessageBatch?: Maybe<MessageBatch>;
  /**  Perform a meta-query on all MessageBatch items which match the where clause.  */
  _allMessageBatchesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageBatch list.  */
  _MessageBatchesMeta?: Maybe<_ListMeta>;
  /**  Search for all NotificationUserSettingHistoryRecord items which match the where clause.  */
  allNotificationUserSettingHistoryRecords?: Maybe<Array<Maybe<NotificationUserSettingHistoryRecord>>>;
  /**  Search for the NotificationUserSettingHistoryRecord item with the matching ID.  */
  NotificationUserSettingHistoryRecord?: Maybe<NotificationUserSettingHistoryRecord>;
  /**  Perform a meta-query on all NotificationUserSettingHistoryRecord items which match the where clause.  */
  _allNotificationUserSettingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NotificationUserSettingHistoryRecord list.  */
  _NotificationUserSettingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NotificationUserSetting items which match the where clause.  */
  allNotificationUserSettings?: Maybe<Array<Maybe<NotificationUserSetting>>>;
  /**  Search for the NotificationUserSetting item with the matching ID.  */
  NotificationUserSetting?: Maybe<NotificationUserSetting>;
  /**  Perform a meta-query on all NotificationUserSetting items which match the where clause.  */
  _allNotificationUserSettingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NotificationUserSetting list.  */
  _NotificationUserSettingsMeta?: Maybe<_ListMeta>;
  /**  Search for all TelegramUserChatHistoryRecord items which match the where clause.  */
  allTelegramUserChatHistoryRecords?: Maybe<Array<Maybe<TelegramUserChatHistoryRecord>>>;
  /**  Search for the TelegramUserChatHistoryRecord item with the matching ID.  */
  TelegramUserChatHistoryRecord?: Maybe<TelegramUserChatHistoryRecord>;
  /**  Perform a meta-query on all TelegramUserChatHistoryRecord items which match the where clause.  */
  _allTelegramUserChatHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TelegramUserChatHistoryRecord list.  */
  _TelegramUserChatHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TelegramUserChat items which match the where clause.  */
  allTelegramUserChats?: Maybe<Array<Maybe<TelegramUserChat>>>;
  /**  Search for the TelegramUserChat item with the matching ID.  */
  TelegramUserChat?: Maybe<TelegramUserChat>;
  /**  Perform a meta-query on all TelegramUserChat items which match the where clause.  */
  _allTelegramUserChatsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TelegramUserChat list.  */
  _TelegramUserChatsMeta?: Maybe<_ListMeta>;
  /**  Search for all NotificationAnonymousSettingHistoryRecord items which match the where clause.  */
  allNotificationAnonymousSettingHistoryRecords?: Maybe<Array<Maybe<NotificationAnonymousSettingHistoryRecord>>>;
  /**  Search for the NotificationAnonymousSettingHistoryRecord item with the matching ID.  */
  NotificationAnonymousSettingHistoryRecord?: Maybe<NotificationAnonymousSettingHistoryRecord>;
  /**  Perform a meta-query on all NotificationAnonymousSettingHistoryRecord items which match the where clause.  */
  _allNotificationAnonymousSettingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NotificationAnonymousSettingHistoryRecord list.  */
  _NotificationAnonymousSettingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NotificationAnonymousSetting items which match the where clause.  */
  allNotificationAnonymousSettings?: Maybe<Array<Maybe<NotificationAnonymousSetting>>>;
  /**  Search for the NotificationAnonymousSetting item with the matching ID.  */
  NotificationAnonymousSetting?: Maybe<NotificationAnonymousSetting>;
  /**  Perform a meta-query on all NotificationAnonymousSetting items which match the where clause.  */
  _allNotificationAnonymousSettingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NotificationAnonymousSetting list.  */
  _NotificationAnonymousSettingsMeta?: Maybe<_ListMeta>;
  /**  Search for all ContactHistoryRecord items which match the where clause.  */
  allContactHistoryRecords?: Maybe<Array<Maybe<ContactHistoryRecord>>>;
  /**  Search for the ContactHistoryRecord item with the matching ID.  */
  ContactHistoryRecord?: Maybe<ContactHistoryRecord>;
  /**  Perform a meta-query on all ContactHistoryRecord items which match the where clause.  */
  _allContactHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ContactHistoryRecord list.  */
  _ContactHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Contact items which match the where clause.  */
  allContacts?: Maybe<Array<Maybe<Contact>>>;
  /**  Search for the Contact item with the matching ID.  */
  Contact?: Maybe<Contact>;
  /**  Perform a meta-query on all Contact items which match the where clause.  */
  _allContactsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Contact list.  */
  _ContactsMeta?: Maybe<_ListMeta>;
  /**  Search for all ContactRoleHistoryRecord items which match the where clause.  */
  allContactRoleHistoryRecords?: Maybe<Array<Maybe<ContactRoleHistoryRecord>>>;
  /**  Search for the ContactRoleHistoryRecord item with the matching ID.  */
  ContactRoleHistoryRecord?: Maybe<ContactRoleHistoryRecord>;
  /**  Perform a meta-query on all ContactRoleHistoryRecord items which match the where clause.  */
  _allContactRoleHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ContactRoleHistoryRecord list.  */
  _ContactRoleHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ContactRole items which match the where clause.  */
  allContactRoles?: Maybe<Array<Maybe<ContactRole>>>;
  /**  Search for the ContactRole item with the matching ID.  */
  ContactRole?: Maybe<ContactRole>;
  /**  Perform a meta-query on all ContactRole items which match the where clause.  */
  _allContactRolesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ContactRole list.  */
  _ContactRolesMeta?: Maybe<_ListMeta>;
  /**  Search for all ContactExportTaskHistoryRecord items which match the where clause.  */
  allContactExportTaskHistoryRecords?: Maybe<Array<Maybe<ContactExportTaskHistoryRecord>>>;
  /**  Search for the ContactExportTaskHistoryRecord item with the matching ID.  */
  ContactExportTaskHistoryRecord?: Maybe<ContactExportTaskHistoryRecord>;
  /**  Perform a meta-query on all ContactExportTaskHistoryRecord items which match the where clause.  */
  _allContactExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ContactExportTaskHistoryRecord list.  */
  _ContactExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ContactExportTask items which match the where clause.  */
  allContactExportTasks?: Maybe<Array<Maybe<ContactExportTask>>>;
  /**  Search for the ContactExportTask item with the matching ID.  */
  ContactExportTask?: Maybe<ContactExportTask>;
  /**  Perform a meta-query on all ContactExportTask items which match the where clause.  */
  _allContactExportTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ContactExportTask list.  */
  _ContactExportTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all ResidentHistoryRecord items which match the where clause.  */
  allResidentHistoryRecords?: Maybe<Array<Maybe<ResidentHistoryRecord>>>;
  /**  Search for the ResidentHistoryRecord item with the matching ID.  */
  ResidentHistoryRecord?: Maybe<ResidentHistoryRecord>;
  /**  Perform a meta-query on all ResidentHistoryRecord items which match the where clause.  */
  _allResidentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ResidentHistoryRecord list.  */
  _ResidentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Resident items which match the where clause.  */
  allResidents?: Maybe<Array<Maybe<Resident>>>;
  /**  Search for the Resident item with the matching ID.  */
  Resident?: Maybe<Resident>;
  /**  Perform a meta-query on all Resident items which match the where clause.  */
  _allResidentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Resident list.  */
  _ResidentsMeta?: Maybe<_ListMeta>;
  /**  Search for all ServiceConsumerHistoryRecord items which match the where clause.  */
  allServiceConsumerHistoryRecords?: Maybe<Array<Maybe<ServiceConsumerHistoryRecord>>>;
  /**  Search for the ServiceConsumerHistoryRecord item with the matching ID.  */
  ServiceConsumerHistoryRecord?: Maybe<ServiceConsumerHistoryRecord>;
  /**  Perform a meta-query on all ServiceConsumerHistoryRecord items which match the where clause.  */
  _allServiceConsumerHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ServiceConsumerHistoryRecord list.  */
  _ServiceConsumerHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ServiceConsumer items which match the where clause.  */
  allServiceConsumers?: Maybe<Array<Maybe<ServiceConsumer>>>;
  /**  Search for the ServiceConsumer item with the matching ID.  */
  ServiceConsumer?: Maybe<ServiceConsumer>;
  /**  Perform a meta-query on all ServiceConsumer items which match the where clause.  */
  _allServiceConsumersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ServiceConsumer list.  */
  _ServiceConsumersMeta?: Maybe<_ListMeta>;
  /**  Search for all TourStepHistoryRecord items which match the where clause.  */
  allTourStepHistoryRecords?: Maybe<Array<Maybe<TourStepHistoryRecord>>>;
  /**  Search for the TourStepHistoryRecord item with the matching ID.  */
  TourStepHistoryRecord?: Maybe<TourStepHistoryRecord>;
  /**  Perform a meta-query on all TourStepHistoryRecord items which match the where clause.  */
  _allTourStepHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TourStepHistoryRecord list.  */
  _TourStepHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all TourStep items which match the where clause.  */
  allTourSteps?: Maybe<Array<Maybe<TourStep>>>;
  /**  Search for the TourStep item with the matching ID.  */
  TourStep?: Maybe<TourStep>;
  /**  Perform a meta-query on all TourStep items which match the where clause.  */
  _allTourStepsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the TourStep list.  */
  _TourStepsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserHelpRequestHistoryRecord items which match the where clause.  */
  allUserHelpRequestHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestHistoryRecord>>>;
  /**  Search for the UserHelpRequestHistoryRecord item with the matching ID.  */
  UserHelpRequestHistoryRecord?: Maybe<UserHelpRequestHistoryRecord>;
  /**  Perform a meta-query on all UserHelpRequestHistoryRecord items which match the where clause.  */
  _allUserHelpRequestHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserHelpRequestHistoryRecord list.  */
  _UserHelpRequestHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserHelpRequest items which match the where clause.  */
  allUserHelpRequests?: Maybe<Array<Maybe<UserHelpRequest>>>;
  /**  Search for the UserHelpRequest item with the matching ID.  */
  UserHelpRequest?: Maybe<UserHelpRequest>;
  /**  Perform a meta-query on all UserHelpRequest items which match the where clause.  */
  _allUserHelpRequestsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserHelpRequest list.  */
  _UserHelpRequestsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserHelpRequestFileHistoryRecord items which match the where clause.  */
  allUserHelpRequestFileHistoryRecords?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecord>>>;
  /**  Search for the UserHelpRequestFileHistoryRecord item with the matching ID.  */
  UserHelpRequestFileHistoryRecord?: Maybe<UserHelpRequestFileHistoryRecord>;
  /**  Perform a meta-query on all UserHelpRequestFileHistoryRecord items which match the where clause.  */
  _allUserHelpRequestFileHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserHelpRequestFileHistoryRecord list.  */
  _UserHelpRequestFileHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all UserHelpRequestFile items which match the where clause.  */
  allUserHelpRequestFiles?: Maybe<Array<Maybe<UserHelpRequestFile>>>;
  /**  Search for the UserHelpRequestFile item with the matching ID.  */
  UserHelpRequestFile?: Maybe<UserHelpRequestFile>;
  /**  Perform a meta-query on all UserHelpRequestFile items which match the where clause.  */
  _allUserHelpRequestFilesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the UserHelpRequestFile list.  */
  _UserHelpRequestFilesMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterResourceHistoryRecord items which match the where clause.  */
  allMeterResourceHistoryRecords?: Maybe<Array<Maybe<MeterResourceHistoryRecord>>>;
  /**  Search for the MeterResourceHistoryRecord item with the matching ID.  */
  MeterResourceHistoryRecord?: Maybe<MeterResourceHistoryRecord>;
  /**  Perform a meta-query on all MeterResourceHistoryRecord items which match the where clause.  */
  _allMeterResourceHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterResourceHistoryRecord list.  */
  _MeterResourceHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterResource items which match the where clause.  */
  allMeterResources?: Maybe<Array<Maybe<MeterResource>>>;
  /**  Search for the MeterResource item with the matching ID.  */
  MeterResource?: Maybe<MeterResource>;
  /**  Perform a meta-query on all MeterResource items which match the where clause.  */
  _allMeterResourcesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterResource list.  */
  _MeterResourcesMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingSourceHistoryRecord items which match the where clause.  */
  allMeterReadingSourceHistoryRecords?: Maybe<Array<Maybe<MeterReadingSourceHistoryRecord>>>;
  /**  Search for the MeterReadingSourceHistoryRecord item with the matching ID.  */
  MeterReadingSourceHistoryRecord?: Maybe<MeterReadingSourceHistoryRecord>;
  /**  Perform a meta-query on all MeterReadingSourceHistoryRecord items which match the where clause.  */
  _allMeterReadingSourceHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingSourceHistoryRecord list.  */
  _MeterReadingSourceHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingSource items which match the where clause.  */
  allMeterReadingSources?: Maybe<Array<Maybe<MeterReadingSource>>>;
  /**  Search for the MeterReadingSource item with the matching ID.  */
  MeterReadingSource?: Maybe<MeterReadingSource>;
  /**  Perform a meta-query on all MeterReadingSource items which match the where clause.  */
  _allMeterReadingSourcesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingSource list.  */
  _MeterReadingSourcesMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingHistoryRecord items which match the where clause.  */
  allMeterReadingHistoryRecords?: Maybe<Array<Maybe<MeterReadingHistoryRecord>>>;
  /**  Search for the MeterReadingHistoryRecord item with the matching ID.  */
  MeterReadingHistoryRecord?: Maybe<MeterReadingHistoryRecord>;
  /**  Perform a meta-query on all MeterReadingHistoryRecord items which match the where clause.  */
  _allMeterReadingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingHistoryRecord list.  */
  _MeterReadingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReading items which match the where clause.  */
  allMeterReadings?: Maybe<Array<Maybe<MeterReading>>>;
  /**  Search for the MeterReading item with the matching ID.  */
  MeterReading?: Maybe<MeterReading>;
  /**  Perform a meta-query on all MeterReading items which match the where clause.  */
  _allMeterReadingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReading list.  */
  _MeterReadingsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterHistoryRecord items which match the where clause.  */
  allMeterHistoryRecords?: Maybe<Array<Maybe<MeterHistoryRecord>>>;
  /**  Search for the MeterHistoryRecord item with the matching ID.  */
  MeterHistoryRecord?: Maybe<MeterHistoryRecord>;
  /**  Perform a meta-query on all MeterHistoryRecord items which match the where clause.  */
  _allMeterHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterHistoryRecord list.  */
  _MeterHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Meter items which match the where clause.  */
  allMeters?: Maybe<Array<Maybe<Meter>>>;
  /**  Search for the Meter item with the matching ID.  */
  Meter?: Maybe<Meter>;
  /**  Perform a meta-query on all Meter items which match the where clause.  */
  _allMetersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Meter list.  */
  _MetersMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingFilterTemplateHistoryRecord items which match the where clause.  */
  allMeterReadingFilterTemplateHistoryRecords?: Maybe<Array<Maybe<MeterReadingFilterTemplateHistoryRecord>>>;
  /**  Search for the MeterReadingFilterTemplateHistoryRecord item with the matching ID.  */
  MeterReadingFilterTemplateHistoryRecord?: Maybe<MeterReadingFilterTemplateHistoryRecord>;
  /**  Perform a meta-query on all MeterReadingFilterTemplateHistoryRecord items which match the where clause.  */
  _allMeterReadingFilterTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingFilterTemplateHistoryRecord list.  */
  _MeterReadingFilterTemplateHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingFilterTemplate items which match the where clause.  */
  allMeterReadingFilterTemplates?: Maybe<Array<Maybe<MeterReadingFilterTemplate>>>;
  /**  Search for the MeterReadingFilterTemplate item with the matching ID.  */
  MeterReadingFilterTemplate?: Maybe<MeterReadingFilterTemplate>;
  /**  Perform a meta-query on all MeterReadingFilterTemplate items which match the where clause.  */
  _allMeterReadingFilterTemplatesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingFilterTemplate list.  */
  _MeterReadingFilterTemplatesMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyMeterHistoryRecord items which match the where clause.  */
  allPropertyMeterHistoryRecords?: Maybe<Array<Maybe<PropertyMeterHistoryRecord>>>;
  /**  Search for the PropertyMeterHistoryRecord item with the matching ID.  */
  PropertyMeterHistoryRecord?: Maybe<PropertyMeterHistoryRecord>;
  /**  Perform a meta-query on all PropertyMeterHistoryRecord items which match the where clause.  */
  _allPropertyMeterHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyMeterHistoryRecord list.  */
  _PropertyMeterHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyMeter items which match the where clause.  */
  allPropertyMeters?: Maybe<Array<Maybe<PropertyMeter>>>;
  /**  Search for the PropertyMeter item with the matching ID.  */
  PropertyMeter?: Maybe<PropertyMeter>;
  /**  Perform a meta-query on all PropertyMeter items which match the where clause.  */
  _allPropertyMetersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyMeter list.  */
  _PropertyMetersMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyMeterReadingHistoryRecord items which match the where clause.  */
  allPropertyMeterReadingHistoryRecords?: Maybe<Array<Maybe<PropertyMeterReadingHistoryRecord>>>;
  /**  Search for the PropertyMeterReadingHistoryRecord item with the matching ID.  */
  PropertyMeterReadingHistoryRecord?: Maybe<PropertyMeterReadingHistoryRecord>;
  /**  Perform a meta-query on all PropertyMeterReadingHistoryRecord items which match the where clause.  */
  _allPropertyMeterReadingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyMeterReadingHistoryRecord list.  */
  _PropertyMeterReadingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyMeterReading items which match the where clause.  */
  allPropertyMeterReadings?: Maybe<Array<Maybe<PropertyMeterReading>>>;
  /**  Search for the PropertyMeterReading item with the matching ID.  */
  PropertyMeterReading?: Maybe<PropertyMeterReading>;
  /**  Perform a meta-query on all PropertyMeterReading items which match the where clause.  */
  _allPropertyMeterReadingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyMeterReading list.  */
  _PropertyMeterReadingsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReportingPeriodHistoryRecord items which match the where clause.  */
  allMeterReportingPeriodHistoryRecords?: Maybe<Array<Maybe<MeterReportingPeriodHistoryRecord>>>;
  /**  Search for the MeterReportingPeriodHistoryRecord item with the matching ID.  */
  MeterReportingPeriodHistoryRecord?: Maybe<MeterReportingPeriodHistoryRecord>;
  /**  Perform a meta-query on all MeterReportingPeriodHistoryRecord items which match the where clause.  */
  _allMeterReportingPeriodHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReportingPeriodHistoryRecord list.  */
  _MeterReportingPeriodHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReportingPeriod items which match the where clause.  */
  allMeterReportingPeriods?: Maybe<Array<Maybe<MeterReportingPeriod>>>;
  /**  Search for the MeterReportingPeriod item with the matching ID.  */
  MeterReportingPeriod?: Maybe<MeterReportingPeriod>;
  /**  Perform a meta-query on all MeterReportingPeriod items which match the where clause.  */
  _allMeterReportingPeriodsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReportingPeriod list.  */
  _MeterReportingPeriodsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterResourceOwnerHistoryRecord items which match the where clause.  */
  allMeterResourceOwnerHistoryRecords?: Maybe<Array<Maybe<MeterResourceOwnerHistoryRecord>>>;
  /**  Search for the MeterResourceOwnerHistoryRecord item with the matching ID.  */
  MeterResourceOwnerHistoryRecord?: Maybe<MeterResourceOwnerHistoryRecord>;
  /**  Perform a meta-query on all MeterResourceOwnerHistoryRecord items which match the where clause.  */
  _allMeterResourceOwnerHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterResourceOwnerHistoryRecord list.  */
  _MeterResourceOwnerHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterResourceOwner items which match the where clause.  */
  allMeterResourceOwners?: Maybe<Array<Maybe<MeterResourceOwner>>>;
  /**  Search for the MeterResourceOwner item with the matching ID.  */
  MeterResourceOwner?: Maybe<MeterResourceOwner>;
  /**  Perform a meta-query on all MeterResourceOwner items which match the where clause.  */
  _allMeterResourceOwnersMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterResourceOwner list.  */
  _MeterResourceOwnersMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingsImportTaskHistoryRecord items which match the where clause.  */
  allMeterReadingsImportTaskHistoryRecords?: Maybe<Array<Maybe<MeterReadingsImportTaskHistoryRecord>>>;
  /**  Search for the MeterReadingsImportTaskHistoryRecord item with the matching ID.  */
  MeterReadingsImportTaskHistoryRecord?: Maybe<MeterReadingsImportTaskHistoryRecord>;
  /**  Perform a meta-query on all MeterReadingsImportTaskHistoryRecord items which match the where clause.  */
  _allMeterReadingsImportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingsImportTaskHistoryRecord list.  */
  _MeterReadingsImportTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MeterReadingsImportTask items which match the where clause.  */
  allMeterReadingsImportTasks?: Maybe<Array<Maybe<MeterReadingsImportTask>>>;
  /**  Search for the MeterReadingsImportTask item with the matching ID.  */
  MeterReadingsImportTask?: Maybe<MeterReadingsImportTask>;
  /**  Perform a meta-query on all MeterReadingsImportTask items which match the where clause.  */
  _allMeterReadingsImportTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MeterReadingsImportTask list.  */
  _MeterReadingsImportTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all ServiceSubscriptionHistoryRecord items which match the where clause.  */
  allServiceSubscriptionHistoryRecords?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecord>>>;
  /**  Search for the ServiceSubscriptionHistoryRecord item with the matching ID.  */
  ServiceSubscriptionHistoryRecord?: Maybe<ServiceSubscriptionHistoryRecord>;
  /**  Perform a meta-query on all ServiceSubscriptionHistoryRecord items which match the where clause.  */
  _allServiceSubscriptionHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ServiceSubscriptionHistoryRecord list.  */
  _ServiceSubscriptionHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ServiceSubscription items which match the where clause.  */
  allServiceSubscriptions?: Maybe<Array<Maybe<ServiceSubscription>>>;
  /**  Search for the ServiceSubscription item with the matching ID.  */
  ServiceSubscription?: Maybe<ServiceSubscription>;
  /**  Perform a meta-query on all ServiceSubscription items which match the where clause.  */
  _allServiceSubscriptionsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ServiceSubscription list.  */
  _ServiceSubscriptionsMeta?: Maybe<_ListMeta>;
  /**  Search for all AcquiringIntegrationHistoryRecord items which match the where clause.  */
  allAcquiringIntegrationHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationHistoryRecord>>>;
  /**  Search for the AcquiringIntegrationHistoryRecord item with the matching ID.  */
  AcquiringIntegrationHistoryRecord?: Maybe<AcquiringIntegrationHistoryRecord>;
  /**  Perform a meta-query on all AcquiringIntegrationHistoryRecord items which match the where clause.  */
  _allAcquiringIntegrationHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the AcquiringIntegrationHistoryRecord list.  */
  _AcquiringIntegrationHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all AcquiringIntegration items which match the where clause.  */
  allAcquiringIntegrations?: Maybe<Array<Maybe<AcquiringIntegration>>>;
  /**  Search for the AcquiringIntegration item with the matching ID.  */
  AcquiringIntegration?: Maybe<AcquiringIntegration>;
  /**  Perform a meta-query on all AcquiringIntegration items which match the where clause.  */
  _allAcquiringIntegrationsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the AcquiringIntegration list.  */
  _AcquiringIntegrationsMeta?: Maybe<_ListMeta>;
  /**  Search for all AcquiringIntegrationAccessRightHistoryRecord items which match the where clause.  */
  allAcquiringIntegrationAccessRightHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationAccessRightHistoryRecord>>>;
  /**  Search for the AcquiringIntegrationAccessRightHistoryRecord item with the matching ID.  */
  AcquiringIntegrationAccessRightHistoryRecord?: Maybe<AcquiringIntegrationAccessRightHistoryRecord>;
  /**  Perform a meta-query on all AcquiringIntegrationAccessRightHistoryRecord items which match the where clause.  */
  _allAcquiringIntegrationAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the AcquiringIntegrationAccessRightHistoryRecord list.  */
  _AcquiringIntegrationAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all AcquiringIntegrationAccessRight items which match the where clause.  */
  allAcquiringIntegrationAccessRights?: Maybe<Array<Maybe<AcquiringIntegrationAccessRight>>>;
  /**  Search for the AcquiringIntegrationAccessRight item with the matching ID.  */
  AcquiringIntegrationAccessRight?: Maybe<AcquiringIntegrationAccessRight>;
  /**  Perform a meta-query on all AcquiringIntegrationAccessRight items which match the where clause.  */
  _allAcquiringIntegrationAccessRightsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the AcquiringIntegrationAccessRight list.  */
  _AcquiringIntegrationAccessRightsMeta?: Maybe<_ListMeta>;
  /**  Search for all AcquiringIntegrationContextHistoryRecord items which match the where clause.  */
  allAcquiringIntegrationContextHistoryRecords?: Maybe<Array<Maybe<AcquiringIntegrationContextHistoryRecord>>>;
  /**  Search for the AcquiringIntegrationContextHistoryRecord item with the matching ID.  */
  AcquiringIntegrationContextHistoryRecord?: Maybe<AcquiringIntegrationContextHistoryRecord>;
  /**  Perform a meta-query on all AcquiringIntegrationContextHistoryRecord items which match the where clause.  */
  _allAcquiringIntegrationContextHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the AcquiringIntegrationContextHistoryRecord list.  */
  _AcquiringIntegrationContextHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all AcquiringIntegrationContext items which match the where clause.  */
  allAcquiringIntegrationContexts?: Maybe<Array<Maybe<AcquiringIntegrationContext>>>;
  /**  Search for the AcquiringIntegrationContext item with the matching ID.  */
  AcquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>;
  /**  Perform a meta-query on all AcquiringIntegrationContext items which match the where clause.  */
  _allAcquiringIntegrationContextsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the AcquiringIntegrationContext list.  */
  _AcquiringIntegrationContextsMeta?: Maybe<_ListMeta>;
  /**  Search for all MultiPaymentHistoryRecord items which match the where clause.  */
  allMultiPaymentHistoryRecords?: Maybe<Array<Maybe<MultiPaymentHistoryRecord>>>;
  /**  Search for the MultiPaymentHistoryRecord item with the matching ID.  */
  MultiPaymentHistoryRecord?: Maybe<MultiPaymentHistoryRecord>;
  /**  Perform a meta-query on all MultiPaymentHistoryRecord items which match the where clause.  */
  _allMultiPaymentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MultiPaymentHistoryRecord list.  */
  _MultiPaymentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MultiPayment items which match the where clause.  */
  allMultiPayments?: Maybe<Array<Maybe<MultiPayment>>>;
  /**  Search for the MultiPayment item with the matching ID.  */
  MultiPayment?: Maybe<MultiPayment>;
  /**  Perform a meta-query on all MultiPayment items which match the where clause.  */
  _allMultiPaymentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MultiPayment list.  */
  _MultiPaymentsMeta?: Maybe<_ListMeta>;
  /**  Search for all PaymentHistoryRecord items which match the where clause.  */
  allPaymentHistoryRecords?: Maybe<Array<Maybe<PaymentHistoryRecord>>>;
  /**  Search for the PaymentHistoryRecord item with the matching ID.  */
  PaymentHistoryRecord?: Maybe<PaymentHistoryRecord>;
  /**  Perform a meta-query on all PaymentHistoryRecord items which match the where clause.  */
  _allPaymentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PaymentHistoryRecord list.  */
  _PaymentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Payment items which match the where clause.  */
  allPayments?: Maybe<Array<Maybe<Payment>>>;
  /**  Search for the Payment item with the matching ID.  */
  Payment?: Maybe<Payment>;
  /**  Perform a meta-query on all Payment items which match the where clause.  */
  _allPaymentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Payment list.  */
  _PaymentsMeta?: Maybe<_ListMeta>;
  /**  Search for all PaymentsFilterTemplateHistoryRecord items which match the where clause.  */
  allPaymentsFilterTemplateHistoryRecords?: Maybe<Array<Maybe<PaymentsFilterTemplateHistoryRecord>>>;
  /**  Search for the PaymentsFilterTemplateHistoryRecord item with the matching ID.  */
  PaymentsFilterTemplateHistoryRecord?: Maybe<PaymentsFilterTemplateHistoryRecord>;
  /**  Perform a meta-query on all PaymentsFilterTemplateHistoryRecord items which match the where clause.  */
  _allPaymentsFilterTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PaymentsFilterTemplateHistoryRecord list.  */
  _PaymentsFilterTemplateHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all PaymentsFilterTemplate items which match the where clause.  */
  allPaymentsFilterTemplates?: Maybe<Array<Maybe<PaymentsFilterTemplate>>>;
  /**  Search for the PaymentsFilterTemplate item with the matching ID.  */
  PaymentsFilterTemplate?: Maybe<PaymentsFilterTemplate>;
  /**  Perform a meta-query on all PaymentsFilterTemplate items which match the where clause.  */
  _allPaymentsFilterTemplatesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PaymentsFilterTemplate list.  */
  _PaymentsFilterTemplatesMeta?: Maybe<_ListMeta>;
  /**  Search for all RecurrentPaymentContextHistoryRecord items which match the where clause.  */
  allRecurrentPaymentContextHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecord>>>;
  /**  Search for the RecurrentPaymentContextHistoryRecord item with the matching ID.  */
  RecurrentPaymentContextHistoryRecord?: Maybe<RecurrentPaymentContextHistoryRecord>;
  /**  Perform a meta-query on all RecurrentPaymentContextHistoryRecord items which match the where clause.  */
  _allRecurrentPaymentContextHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the RecurrentPaymentContextHistoryRecord list.  */
  _RecurrentPaymentContextHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all RecurrentPaymentContext items which match the where clause.  */
  allRecurrentPaymentContexts?: Maybe<Array<Maybe<RecurrentPaymentContext>>>;
  /**  Search for the RecurrentPaymentContext item with the matching ID.  */
  RecurrentPaymentContext?: Maybe<RecurrentPaymentContext>;
  /**  Perform a meta-query on all RecurrentPaymentContext items which match the where clause.  */
  _allRecurrentPaymentContextsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the RecurrentPaymentContext list.  */
  _RecurrentPaymentContextsMeta?: Maybe<_ListMeta>;
  /**  Search for all RecurrentPaymentHistoryRecord items which match the where clause.  */
  allRecurrentPaymentHistoryRecords?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecord>>>;
  /**  Search for the RecurrentPaymentHistoryRecord item with the matching ID.  */
  RecurrentPaymentHistoryRecord?: Maybe<RecurrentPaymentHistoryRecord>;
  /**  Perform a meta-query on all RecurrentPaymentHistoryRecord items which match the where clause.  */
  _allRecurrentPaymentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the RecurrentPaymentHistoryRecord list.  */
  _RecurrentPaymentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all RecurrentPayment items which match the where clause.  */
  allRecurrentPayments?: Maybe<Array<Maybe<RecurrentPayment>>>;
  /**  Search for the RecurrentPayment item with the matching ID.  */
  RecurrentPayment?: Maybe<RecurrentPayment>;
  /**  Perform a meta-query on all RecurrentPayment items which match the where clause.  */
  _allRecurrentPaymentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the RecurrentPayment list.  */
  _RecurrentPaymentsMeta?: Maybe<_ListMeta>;
  /**  Search for all ExternalReportHistoryRecord items which match the where clause.  */
  allExternalReportHistoryRecords?: Maybe<Array<Maybe<ExternalReportHistoryRecord>>>;
  /**  Search for the ExternalReportHistoryRecord item with the matching ID.  */
  ExternalReportHistoryRecord?: Maybe<ExternalReportHistoryRecord>;
  /**  Perform a meta-query on all ExternalReportHistoryRecord items which match the where clause.  */
  _allExternalReportHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ExternalReportHistoryRecord list.  */
  _ExternalReportHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all ExternalReport items which match the where clause.  */
  allExternalReports?: Maybe<Array<Maybe<ExternalReport>>>;
  /**  Search for the ExternalReport item with the matching ID.  */
  ExternalReport?: Maybe<ExternalReport>;
  /**  Perform a meta-query on all ExternalReport items which match the where clause.  */
  _allExternalReportsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the ExternalReport list.  */
  _ExternalReportsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyScopeHistoryRecord items which match the where clause.  */
  allPropertyScopeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeHistoryRecord>>>;
  /**  Search for the PropertyScopeHistoryRecord item with the matching ID.  */
  PropertyScopeHistoryRecord?: Maybe<PropertyScopeHistoryRecord>;
  /**  Perform a meta-query on all PropertyScopeHistoryRecord items which match the where clause.  */
  _allPropertyScopeHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyScopeHistoryRecord list.  */
  _PropertyScopeHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyScope items which match the where clause.  */
  allPropertyScopes?: Maybe<Array<Maybe<PropertyScope>>>;
  /**  Search for the PropertyScope item with the matching ID.  */
  PropertyScope?: Maybe<PropertyScope>;
  /**  Perform a meta-query on all PropertyScope items which match the where clause.  */
  _allPropertyScopesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyScope list.  */
  _PropertyScopesMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyScopeOrganizationEmployeeHistoryRecord items which match the where clause.  */
  allPropertyScopeOrganizationEmployeeHistoryRecords?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>>>;
  /**  Search for the PropertyScopeOrganizationEmployeeHistoryRecord item with the matching ID.  */
  PropertyScopeOrganizationEmployeeHistoryRecord?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecord>;
  /**  Perform a meta-query on all PropertyScopeOrganizationEmployeeHistoryRecord items which match the where clause.  */
  _allPropertyScopeOrganizationEmployeeHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyScopeOrganizationEmployeeHistoryRecord list.  */
  _PropertyScopeOrganizationEmployeeHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyScopeOrganizationEmployee items which match the where clause.  */
  allPropertyScopeOrganizationEmployees?: Maybe<Array<Maybe<PropertyScopeOrganizationEmployee>>>;
  /**  Search for the PropertyScopeOrganizationEmployee item with the matching ID.  */
  PropertyScopeOrganizationEmployee?: Maybe<PropertyScopeOrganizationEmployee>;
  /**  Perform a meta-query on all PropertyScopeOrganizationEmployee items which match the where clause.  */
  _allPropertyScopeOrganizationEmployeesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyScopeOrganizationEmployee list.  */
  _PropertyScopeOrganizationEmployeesMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyScopePropertyHistoryRecord items which match the where clause.  */
  allPropertyScopePropertyHistoryRecords?: Maybe<Array<Maybe<PropertyScopePropertyHistoryRecord>>>;
  /**  Search for the PropertyScopePropertyHistoryRecord item with the matching ID.  */
  PropertyScopePropertyHistoryRecord?: Maybe<PropertyScopePropertyHistoryRecord>;
  /**  Perform a meta-query on all PropertyScopePropertyHistoryRecord items which match the where clause.  */
  _allPropertyScopePropertyHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyScopePropertyHistoryRecord list.  */
  _PropertyScopePropertyHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all PropertyScopeProperty items which match the where clause.  */
  allPropertyScopeProperties?: Maybe<Array<Maybe<PropertyScopeProperty>>>;
  /**  Search for the PropertyScopeProperty item with the matching ID.  */
  PropertyScopeProperty?: Maybe<PropertyScopeProperty>;
  /**  Perform a meta-query on all PropertyScopeProperty items which match the where clause.  */
  _allPropertyScopePropertiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the PropertyScopeProperty list.  */
  _PropertyScopePropertiesMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemHistoryRecord items which match the where clause.  */
  allNewsItemHistoryRecords?: Maybe<Array<Maybe<NewsItemHistoryRecord>>>;
  /**  Search for the NewsItemHistoryRecord item with the matching ID.  */
  NewsItemHistoryRecord?: Maybe<NewsItemHistoryRecord>;
  /**  Perform a meta-query on all NewsItemHistoryRecord items which match the where clause.  */
  _allNewsItemHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemHistoryRecord list.  */
  _NewsItemHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItem items which match the where clause.  */
  allNewsItems?: Maybe<Array<Maybe<NewsItem>>>;
  /**  Search for the NewsItem item with the matching ID.  */
  NewsItem?: Maybe<NewsItem>;
  /**  Perform a meta-query on all NewsItem items which match the where clause.  */
  _allNewsItemsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItem list.  */
  _NewsItemsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemScopeHistoryRecord items which match the where clause.  */
  allNewsItemScopeHistoryRecords?: Maybe<Array<Maybe<NewsItemScopeHistoryRecord>>>;
  /**  Search for the NewsItemScopeHistoryRecord item with the matching ID.  */
  NewsItemScopeHistoryRecord?: Maybe<NewsItemScopeHistoryRecord>;
  /**  Perform a meta-query on all NewsItemScopeHistoryRecord items which match the where clause.  */
  _allNewsItemScopeHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemScopeHistoryRecord list.  */
  _NewsItemScopeHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemScope items which match the where clause.  */
  allNewsItemScopes?: Maybe<Array<Maybe<NewsItemScope>>>;
  /**  Search for the NewsItemScope item with the matching ID.  */
  NewsItemScope?: Maybe<NewsItemScope>;
  /**  Perform a meta-query on all NewsItemScope items which match the where clause.  */
  _allNewsItemScopesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemScope list.  */
  _NewsItemScopesMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemTemplateHistoryRecord items which match the where clause.  */
  allNewsItemTemplateHistoryRecords?: Maybe<Array<Maybe<NewsItemTemplateHistoryRecord>>>;
  /**  Search for the NewsItemTemplateHistoryRecord item with the matching ID.  */
  NewsItemTemplateHistoryRecord?: Maybe<NewsItemTemplateHistoryRecord>;
  /**  Perform a meta-query on all NewsItemTemplateHistoryRecord items which match the where clause.  */
  _allNewsItemTemplateHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemTemplateHistoryRecord list.  */
  _NewsItemTemplateHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemTemplate items which match the where clause.  */
  allNewsItemTemplates?: Maybe<Array<Maybe<NewsItemTemplate>>>;
  /**  Search for the NewsItemTemplate item with the matching ID.  */
  NewsItemTemplate?: Maybe<NewsItemTemplate>;
  /**  Perform a meta-query on all NewsItemTemplate items which match the where clause.  */
  _allNewsItemTemplatesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemTemplate list.  */
  _NewsItemTemplatesMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemUserReadHistoryRecord items which match the where clause.  */
  allNewsItemUserReadHistoryRecords?: Maybe<Array<Maybe<NewsItemUserReadHistoryRecord>>>;
  /**  Search for the NewsItemUserReadHistoryRecord item with the matching ID.  */
  NewsItemUserReadHistoryRecord?: Maybe<NewsItemUserReadHistoryRecord>;
  /**  Perform a meta-query on all NewsItemUserReadHistoryRecord items which match the where clause.  */
  _allNewsItemUserReadHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemUserReadHistoryRecord list.  */
  _NewsItemUserReadHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemUserRead items which match the where clause.  */
  allNewsItemUserReads?: Maybe<Array<Maybe<NewsItemUserRead>>>;
  /**  Search for the NewsItemUserRead item with the matching ID.  */
  NewsItemUserRead?: Maybe<NewsItemUserRead>;
  /**  Perform a meta-query on all NewsItemUserRead items which match the where clause.  */
  _allNewsItemUserReadsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemUserRead list.  */
  _NewsItemUserReadsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemRecipientsExportTaskHistoryRecord items which match the where clause.  */
  allNewsItemRecipientsExportTaskHistoryRecords?: Maybe<Array<Maybe<NewsItemRecipientsExportTaskHistoryRecord>>>;
  /**  Search for the NewsItemRecipientsExportTaskHistoryRecord item with the matching ID.  */
  NewsItemRecipientsExportTaskHistoryRecord?: Maybe<NewsItemRecipientsExportTaskHistoryRecord>;
  /**  Perform a meta-query on all NewsItemRecipientsExportTaskHistoryRecord items which match the where clause.  */
  _allNewsItemRecipientsExportTaskHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemRecipientsExportTaskHistoryRecord list.  */
  _NewsItemRecipientsExportTaskHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemRecipientsExportTask items which match the where clause.  */
  allNewsItemRecipientsExportTasks?: Maybe<Array<Maybe<NewsItemRecipientsExportTask>>>;
  /**  Search for the NewsItemRecipientsExportTask item with the matching ID.  */
  NewsItemRecipientsExportTask?: Maybe<NewsItemRecipientsExportTask>;
  /**  Perform a meta-query on all NewsItemRecipientsExportTask items which match the where clause.  */
  _allNewsItemRecipientsExportTasksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemRecipientsExportTask list.  */
  _NewsItemRecipientsExportTasksMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemSharingHistoryRecord items which match the where clause.  */
  allNewsItemSharingHistoryRecords?: Maybe<Array<Maybe<NewsItemSharingHistoryRecord>>>;
  /**  Search for the NewsItemSharingHistoryRecord item with the matching ID.  */
  NewsItemSharingHistoryRecord?: Maybe<NewsItemSharingHistoryRecord>;
  /**  Perform a meta-query on all NewsItemSharingHistoryRecord items which match the where clause.  */
  _allNewsItemSharingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemSharingHistoryRecord list.  */
  _NewsItemSharingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all NewsItemSharing items which match the where clause.  */
  allNewsItemSharings?: Maybe<Array<Maybe<NewsItemSharing>>>;
  /**  Search for the NewsItemSharing item with the matching ID.  */
  NewsItemSharing?: Maybe<NewsItemSharing>;
  /**  Perform a meta-query on all NewsItemSharing items which match the where clause.  */
  _allNewsItemSharingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the NewsItemSharing list.  */
  _NewsItemSharingsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppHistoryRecord items which match the where clause.  */
  allB2BAppHistoryRecords?: Maybe<Array<Maybe<B2BAppHistoryRecord>>>;
  /**  Search for the B2BAppHistoryRecord item with the matching ID.  */
  B2BAppHistoryRecord?: Maybe<B2BAppHistoryRecord>;
  /**  Perform a meta-query on all B2BAppHistoryRecord items which match the where clause.  */
  _allB2BAppHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppHistoryRecord list.  */
  _B2BAppHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BApp items which match the where clause.  */
  allB2BApps?: Maybe<Array<Maybe<B2BApp>>>;
  /**  Search for the B2BApp item with the matching ID.  */
  B2BApp?: Maybe<B2BApp>;
  /**  Perform a meta-query on all B2BApp items which match the where clause.  */
  _allB2BAppsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BApp list.  */
  _B2BAppsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppContextHistoryRecord items which match the where clause.  */
  allB2BAppContextHistoryRecords?: Maybe<Array<Maybe<B2BAppContextHistoryRecord>>>;
  /**  Search for the B2BAppContextHistoryRecord item with the matching ID.  */
  B2BAppContextHistoryRecord?: Maybe<B2BAppContextHistoryRecord>;
  /**  Perform a meta-query on all B2BAppContextHistoryRecord items which match the where clause.  */
  _allB2BAppContextHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppContextHistoryRecord list.  */
  _B2BAppContextHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppContext items which match the where clause.  */
  allB2BAppContexts?: Maybe<Array<Maybe<B2BAppContext>>>;
  /**  Search for the B2BAppContext item with the matching ID.  */
  B2BAppContext?: Maybe<B2BAppContext>;
  /**  Perform a meta-query on all B2BAppContext items which match the where clause.  */
  _allB2BAppContextsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppContext list.  */
  _B2BAppContextsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppAccessRightHistoryRecord items which match the where clause.  */
  allB2BAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightHistoryRecord>>>;
  /**  Search for the B2BAppAccessRightHistoryRecord item with the matching ID.  */
  B2BAppAccessRightHistoryRecord?: Maybe<B2BAppAccessRightHistoryRecord>;
  /**  Perform a meta-query on all B2BAppAccessRightHistoryRecord items which match the where clause.  */
  _allB2BAppAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppAccessRightHistoryRecord list.  */
  _B2BAppAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppAccessRight items which match the where clause.  */
  allB2BAppAccessRights?: Maybe<Array<Maybe<B2BAppAccessRight>>>;
  /**  Search for the B2BAppAccessRight item with the matching ID.  */
  B2BAppAccessRight?: Maybe<B2BAppAccessRight>;
  /**  Perform a meta-query on all B2BAppAccessRight items which match the where clause.  */
  _allB2BAppAccessRightsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppAccessRight list.  */
  _B2BAppAccessRightsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppHistoryRecord items which match the where clause.  */
  allB2CAppHistoryRecords?: Maybe<Array<Maybe<B2CAppHistoryRecord>>>;
  /**  Search for the B2CAppHistoryRecord item with the matching ID.  */
  B2CAppHistoryRecord?: Maybe<B2CAppHistoryRecord>;
  /**  Perform a meta-query on all B2CAppHistoryRecord items which match the where clause.  */
  _allB2CAppHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppHistoryRecord list.  */
  _B2CAppHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CApp items which match the where clause.  */
  allB2CApps?: Maybe<Array<Maybe<B2CApp>>>;
  /**  Search for the B2CApp item with the matching ID.  */
  B2CApp?: Maybe<B2CApp>;
  /**  Perform a meta-query on all B2CApp items which match the where clause.  */
  _allB2CAppsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CApp list.  */
  _B2CAppsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppAccessRightHistoryRecord items which match the where clause.  */
  allB2CAppAccessRightHistoryRecords?: Maybe<Array<Maybe<B2CAppAccessRightHistoryRecord>>>;
  /**  Search for the B2CAppAccessRightHistoryRecord item with the matching ID.  */
  B2CAppAccessRightHistoryRecord?: Maybe<B2CAppAccessRightHistoryRecord>;
  /**  Perform a meta-query on all B2CAppAccessRightHistoryRecord items which match the where clause.  */
  _allB2CAppAccessRightHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppAccessRightHistoryRecord list.  */
  _B2CAppAccessRightHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppAccessRight items which match the where clause.  */
  allB2CAppAccessRights?: Maybe<Array<Maybe<B2CAppAccessRight>>>;
  /**  Search for the B2CAppAccessRight item with the matching ID.  */
  B2CAppAccessRight?: Maybe<B2CAppAccessRight>;
  /**  Perform a meta-query on all B2CAppAccessRight items which match the where clause.  */
  _allB2CAppAccessRightsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppAccessRight list.  */
  _B2CAppAccessRightsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppBuildHistoryRecord items which match the where clause.  */
  allB2CAppBuildHistoryRecords?: Maybe<Array<Maybe<B2CAppBuildHistoryRecord>>>;
  /**  Search for the B2CAppBuildHistoryRecord item with the matching ID.  */
  B2CAppBuildHistoryRecord?: Maybe<B2CAppBuildHistoryRecord>;
  /**  Perform a meta-query on all B2CAppBuildHistoryRecord items which match the where clause.  */
  _allB2CAppBuildHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppBuildHistoryRecord list.  */
  _B2CAppBuildHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppBuild items which match the where clause.  */
  allB2CAppBuilds?: Maybe<Array<Maybe<B2CAppBuild>>>;
  /**  Search for the B2CAppBuild item with the matching ID.  */
  B2CAppBuild?: Maybe<B2CAppBuild>;
  /**  Perform a meta-query on all B2CAppBuild items which match the where clause.  */
  _allB2CAppBuildsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppBuild list.  */
  _B2CAppBuildsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppPropertyHistoryRecord items which match the where clause.  */
  allB2CAppPropertyHistoryRecords?: Maybe<Array<Maybe<B2CAppPropertyHistoryRecord>>>;
  /**  Search for the B2CAppPropertyHistoryRecord item with the matching ID.  */
  B2CAppPropertyHistoryRecord?: Maybe<B2CAppPropertyHistoryRecord>;
  /**  Perform a meta-query on all B2CAppPropertyHistoryRecord items which match the where clause.  */
  _allB2CAppPropertyHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppPropertyHistoryRecord list.  */
  _B2CAppPropertyHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppProperty items which match the where clause.  */
  allB2CAppProperties?: Maybe<Array<Maybe<B2CAppProperty>>>;
  /**  Search for the B2CAppProperty item with the matching ID.  */
  B2CAppProperty?: Maybe<B2CAppProperty>;
  /**  Perform a meta-query on all B2CAppProperty items which match the where clause.  */
  _allB2CAppPropertiesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppProperty list.  */
  _B2CAppPropertiesMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppPromoBlockHistoryRecord items which match the where clause.  */
  allB2BAppPromoBlockHistoryRecords?: Maybe<Array<Maybe<B2BAppPromoBlockHistoryRecord>>>;
  /**  Search for the B2BAppPromoBlockHistoryRecord item with the matching ID.  */
  B2BAppPromoBlockHistoryRecord?: Maybe<B2BAppPromoBlockHistoryRecord>;
  /**  Perform a meta-query on all B2BAppPromoBlockHistoryRecord items which match the where clause.  */
  _allB2BAppPromoBlockHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppPromoBlockHistoryRecord list.  */
  _B2BAppPromoBlockHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppPromoBlock items which match the where clause.  */
  allB2BAppPromoBlocks?: Maybe<Array<Maybe<B2BAppPromoBlock>>>;
  /**  Search for the B2BAppPromoBlock item with the matching ID.  */
  B2BAppPromoBlock?: Maybe<B2BAppPromoBlock>;
  /**  Perform a meta-query on all B2BAppPromoBlock items which match the where clause.  */
  _allB2BAppPromoBlocksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppPromoBlock list.  */
  _B2BAppPromoBlocksMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageAppBlackListHistoryRecord items which match the where clause.  */
  allMessageAppBlackListHistoryRecords?: Maybe<Array<Maybe<MessageAppBlackListHistoryRecord>>>;
  /**  Search for the MessageAppBlackListHistoryRecord item with the matching ID.  */
  MessageAppBlackListHistoryRecord?: Maybe<MessageAppBlackListHistoryRecord>;
  /**  Perform a meta-query on all MessageAppBlackListHistoryRecord items which match the where clause.  */
  _allMessageAppBlackListHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageAppBlackListHistoryRecord list.  */
  _MessageAppBlackListHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MessageAppBlackList items which match the where clause.  */
  allMessageAppBlackLists?: Maybe<Array<Maybe<MessageAppBlackList>>>;
  /**  Search for the MessageAppBlackList item with the matching ID.  */
  MessageAppBlackList?: Maybe<MessageAppBlackList>;
  /**  Perform a meta-query on all MessageAppBlackList items which match the where clause.  */
  _allMessageAppBlackListsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MessageAppBlackList list.  */
  _MessageAppBlackListsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppPermissionHistoryRecord items which match the where clause.  */
  allB2BAppPermissionHistoryRecords?: Maybe<Array<Maybe<B2BAppPermissionHistoryRecord>>>;
  /**  Search for the B2BAppPermissionHistoryRecord item with the matching ID.  */
  B2BAppPermissionHistoryRecord?: Maybe<B2BAppPermissionHistoryRecord>;
  /**  Perform a meta-query on all B2BAppPermissionHistoryRecord items which match the where clause.  */
  _allB2BAppPermissionHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppPermissionHistoryRecord list.  */
  _B2BAppPermissionHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppPermission items which match the where clause.  */
  allB2BAppPermissions?: Maybe<Array<Maybe<B2BAppPermission>>>;
  /**  Search for the B2BAppPermission item with the matching ID.  */
  B2BAppPermission?: Maybe<B2BAppPermission>;
  /**  Perform a meta-query on all B2BAppPermission items which match the where clause.  */
  _allB2BAppPermissionsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppPermission list.  */
  _B2BAppPermissionsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppRoleHistoryRecord items which match the where clause.  */
  allB2BAppRoleHistoryRecords?: Maybe<Array<Maybe<B2BAppRoleHistoryRecord>>>;
  /**  Search for the B2BAppRoleHistoryRecord item with the matching ID.  */
  B2BAppRoleHistoryRecord?: Maybe<B2BAppRoleHistoryRecord>;
  /**  Perform a meta-query on all B2BAppRoleHistoryRecord items which match the where clause.  */
  _allB2BAppRoleHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppRoleHistoryRecord list.  */
  _B2BAppRoleHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppRole items which match the where clause.  */
  allB2BAppRoles?: Maybe<Array<Maybe<B2BAppRole>>>;
  /**  Search for the B2BAppRole item with the matching ID.  */
  B2BAppRole?: Maybe<B2BAppRole>;
  /**  Perform a meta-query on all B2BAppRole items which match the where clause.  */
  _allB2BAppRolesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppRole list.  */
  _B2BAppRolesMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppAccessRightSetHistoryRecord items which match the where clause.  */
  allB2BAppAccessRightSetHistoryRecords?: Maybe<Array<Maybe<B2BAppAccessRightSetHistoryRecord>>>;
  /**  Search for the B2BAppAccessRightSetHistoryRecord item with the matching ID.  */
  B2BAppAccessRightSetHistoryRecord?: Maybe<B2BAppAccessRightSetHistoryRecord>;
  /**  Perform a meta-query on all B2BAppAccessRightSetHistoryRecord items which match the where clause.  */
  _allB2BAppAccessRightSetHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppAccessRightSetHistoryRecord list.  */
  _B2BAppAccessRightSetHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppAccessRightSet items which match the where clause.  */
  allB2BAppAccessRightSets?: Maybe<Array<Maybe<B2BAppAccessRightSet>>>;
  /**  Search for the B2BAppAccessRightSet item with the matching ID.  */
  B2BAppAccessRightSet?: Maybe<B2BAppAccessRightSet>;
  /**  Perform a meta-query on all B2BAppAccessRightSet items which match the where clause.  */
  _allB2BAppAccessRightSetsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppAccessRightSet list.  */
  _B2BAppAccessRightSetsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppNewsSharingConfigHistoryRecord items which match the where clause.  */
  allB2BAppNewsSharingConfigHistoryRecords?: Maybe<Array<Maybe<B2BAppNewsSharingConfigHistoryRecord>>>;
  /**  Search for the B2BAppNewsSharingConfigHistoryRecord item with the matching ID.  */
  B2BAppNewsSharingConfigHistoryRecord?: Maybe<B2BAppNewsSharingConfigHistoryRecord>;
  /**  Perform a meta-query on all B2BAppNewsSharingConfigHistoryRecord items which match the where clause.  */
  _allB2BAppNewsSharingConfigHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppNewsSharingConfigHistoryRecord list.  */
  _B2BAppNewsSharingConfigHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2BAppNewsSharingConfig items which match the where clause.  */
  allB2BAppNewsSharingConfigs?: Maybe<Array<Maybe<B2BAppNewsSharingConfig>>>;
  /**  Search for the B2BAppNewsSharingConfig item with the matching ID.  */
  B2BAppNewsSharingConfig?: Maybe<B2BAppNewsSharingConfig>;
  /**  Perform a meta-query on all B2BAppNewsSharingConfig items which match the where clause.  */
  _allB2BAppNewsSharingConfigsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2BAppNewsSharingConfig list.  */
  _B2BAppNewsSharingConfigsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppMessageSettingHistoryRecord items which match the where clause.  */
  allB2CAppMessageSettingHistoryRecords?: Maybe<Array<Maybe<B2CAppMessageSettingHistoryRecord>>>;
  /**  Search for the B2CAppMessageSettingHistoryRecord item with the matching ID.  */
  B2CAppMessageSettingHistoryRecord?: Maybe<B2CAppMessageSettingHistoryRecord>;
  /**  Perform a meta-query on all B2CAppMessageSettingHistoryRecord items which match the where clause.  */
  _allB2CAppMessageSettingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppMessageSettingHistoryRecord list.  */
  _B2CAppMessageSettingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all B2CAppMessageSetting items which match the where clause.  */
  allB2CAppMessageSettings?: Maybe<Array<Maybe<B2CAppMessageSetting>>>;
  /**  Search for the B2CAppMessageSetting item with the matching ID.  */
  B2CAppMessageSetting?: Maybe<B2CAppMessageSetting>;
  /**  Perform a meta-query on all B2CAppMessageSetting items which match the where clause.  */
  _allB2CAppMessageSettingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the B2CAppMessageSetting list.  */
  _B2CAppMessageSettingsMeta?: Maybe<_ListMeta>;
  /**  Search for all MobileFeatureConfigHistoryRecord items which match the where clause.  */
  allMobileFeatureConfigHistoryRecords?: Maybe<Array<Maybe<MobileFeatureConfigHistoryRecord>>>;
  /**  Search for the MobileFeatureConfigHistoryRecord item with the matching ID.  */
  MobileFeatureConfigHistoryRecord?: Maybe<MobileFeatureConfigHistoryRecord>;
  /**  Perform a meta-query on all MobileFeatureConfigHistoryRecord items which match the where clause.  */
  _allMobileFeatureConfigHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MobileFeatureConfigHistoryRecord list.  */
  _MobileFeatureConfigHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MobileFeatureConfig items which match the where clause.  */
  allMobileFeatureConfigs?: Maybe<Array<Maybe<MobileFeatureConfig>>>;
  /**  Search for the MobileFeatureConfig item with the matching ID.  */
  MobileFeatureConfig?: Maybe<MobileFeatureConfig>;
  /**  Perform a meta-query on all MobileFeatureConfig items which match the where clause.  */
  _allMobileFeatureConfigsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MobileFeatureConfig list.  */
  _MobileFeatureConfigsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketCategoryHistoryRecord items which match the where clause.  */
  allMarketCategoryHistoryRecords?: Maybe<Array<Maybe<MarketCategoryHistoryRecord>>>;
  /**  Search for the MarketCategoryHistoryRecord item with the matching ID.  */
  MarketCategoryHistoryRecord?: Maybe<MarketCategoryHistoryRecord>;
  /**  Perform a meta-query on all MarketCategoryHistoryRecord items which match the where clause.  */
  _allMarketCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketCategoryHistoryRecord list.  */
  _MarketCategoryHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketCategory items which match the where clause.  */
  allMarketCategories?: Maybe<Array<Maybe<MarketCategory>>>;
  /**  Search for the MarketCategory item with the matching ID.  */
  MarketCategory?: Maybe<MarketCategory>;
  /**  Perform a meta-query on all MarketCategory items which match the where clause.  */
  _allMarketCategoriesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketCategory list.  */
  _MarketCategoriesMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketItemHistoryRecord items which match the where clause.  */
  allMarketItemHistoryRecords?: Maybe<Array<Maybe<MarketItemHistoryRecord>>>;
  /**  Search for the MarketItemHistoryRecord item with the matching ID.  */
  MarketItemHistoryRecord?: Maybe<MarketItemHistoryRecord>;
  /**  Perform a meta-query on all MarketItemHistoryRecord items which match the where clause.  */
  _allMarketItemHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketItemHistoryRecord list.  */
  _MarketItemHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketItem items which match the where clause.  */
  allMarketItems?: Maybe<Array<Maybe<MarketItem>>>;
  /**  Search for the MarketItem item with the matching ID.  */
  MarketItem?: Maybe<MarketItem>;
  /**  Perform a meta-query on all MarketItem items which match the where clause.  */
  _allMarketItemsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketItem list.  */
  _MarketItemsMeta?: Maybe<_ListMeta>;
  /**  Search for all InvoiceHistoryRecord items which match the where clause.  */
  allInvoiceHistoryRecords?: Maybe<Array<Maybe<InvoiceHistoryRecord>>>;
  /**  Search for the InvoiceHistoryRecord item with the matching ID.  */
  InvoiceHistoryRecord?: Maybe<InvoiceHistoryRecord>;
  /**  Perform a meta-query on all InvoiceHistoryRecord items which match the where clause.  */
  _allInvoiceHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the InvoiceHistoryRecord list.  */
  _InvoiceHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Invoice items which match the where clause.  */
  allInvoices?: Maybe<Array<Maybe<Invoice>>>;
  /**  Search for the Invoice item with the matching ID.  */
  Invoice?: Maybe<Invoice>;
  /**  Perform a meta-query on all Invoice items which match the where clause.  */
  _allInvoicesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Invoice list.  */
  _InvoicesMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketItemFileHistoryRecord items which match the where clause.  */
  allMarketItemFileHistoryRecords?: Maybe<Array<Maybe<MarketItemFileHistoryRecord>>>;
  /**  Search for the MarketItemFileHistoryRecord item with the matching ID.  */
  MarketItemFileHistoryRecord?: Maybe<MarketItemFileHistoryRecord>;
  /**  Perform a meta-query on all MarketItemFileHistoryRecord items which match the where clause.  */
  _allMarketItemFileHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketItemFileHistoryRecord list.  */
  _MarketItemFileHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketItemFile items which match the where clause.  */
  allMarketItemFiles?: Maybe<Array<Maybe<MarketItemFile>>>;
  /**  Search for the MarketItemFile item with the matching ID.  */
  MarketItemFile?: Maybe<MarketItemFile>;
  /**  Perform a meta-query on all MarketItemFile items which match the where clause.  */
  _allMarketItemFilesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketItemFile list.  */
  _MarketItemFilesMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketItemPriceHistoryRecord items which match the where clause.  */
  allMarketItemPriceHistoryRecords?: Maybe<Array<Maybe<MarketItemPriceHistoryRecord>>>;
  /**  Search for the MarketItemPriceHistoryRecord item with the matching ID.  */
  MarketItemPriceHistoryRecord?: Maybe<MarketItemPriceHistoryRecord>;
  /**  Perform a meta-query on all MarketItemPriceHistoryRecord items which match the where clause.  */
  _allMarketItemPriceHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketItemPriceHistoryRecord list.  */
  _MarketItemPriceHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketItemPrice items which match the where clause.  */
  allMarketItemPrices?: Maybe<Array<Maybe<MarketItemPrice>>>;
  /**  Search for the MarketItemPrice item with the matching ID.  */
  MarketItemPrice?: Maybe<MarketItemPrice>;
  /**  Perform a meta-query on all MarketItemPrice items which match the where clause.  */
  _allMarketItemPricesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketItemPrice list.  */
  _MarketItemPricesMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketPriceScopeHistoryRecord items which match the where clause.  */
  allMarketPriceScopeHistoryRecords?: Maybe<Array<Maybe<MarketPriceScopeHistoryRecord>>>;
  /**  Search for the MarketPriceScopeHistoryRecord item with the matching ID.  */
  MarketPriceScopeHistoryRecord?: Maybe<MarketPriceScopeHistoryRecord>;
  /**  Perform a meta-query on all MarketPriceScopeHistoryRecord items which match the where clause.  */
  _allMarketPriceScopeHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketPriceScopeHistoryRecord list.  */
  _MarketPriceScopeHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketPriceScope items which match the where clause.  */
  allMarketPriceScopes?: Maybe<Array<Maybe<MarketPriceScope>>>;
  /**  Search for the MarketPriceScope item with the matching ID.  */
  MarketPriceScope?: Maybe<MarketPriceScope>;
  /**  Perform a meta-query on all MarketPriceScope items which match the where clause.  */
  _allMarketPriceScopesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketPriceScope list.  */
  _MarketPriceScopesMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketSettingHistoryRecord items which match the where clause.  */
  allMarketSettingHistoryRecords?: Maybe<Array<Maybe<MarketSettingHistoryRecord>>>;
  /**  Search for the MarketSettingHistoryRecord item with the matching ID.  */
  MarketSettingHistoryRecord?: Maybe<MarketSettingHistoryRecord>;
  /**  Perform a meta-query on all MarketSettingHistoryRecord items which match the where clause.  */
  _allMarketSettingHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketSettingHistoryRecord list.  */
  _MarketSettingHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all MarketSetting items which match the where clause.  */
  allMarketSettings?: Maybe<Array<Maybe<MarketSetting>>>;
  /**  Search for the MarketSetting item with the matching ID.  */
  MarketSetting?: Maybe<MarketSetting>;
  /**  Perform a meta-query on all MarketSetting items which match the where clause.  */
  _allMarketSettingsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the MarketSetting list.  */
  _MarketSettingsMeta?: Maybe<_ListMeta>;
  /**  Search for all DocumentCategoryHistoryRecord items which match the where clause.  */
  allDocumentCategoryHistoryRecords?: Maybe<Array<Maybe<DocumentCategoryHistoryRecord>>>;
  /**  Search for the DocumentCategoryHistoryRecord item with the matching ID.  */
  DocumentCategoryHistoryRecord?: Maybe<DocumentCategoryHistoryRecord>;
  /**  Perform a meta-query on all DocumentCategoryHistoryRecord items which match the where clause.  */
  _allDocumentCategoryHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the DocumentCategoryHistoryRecord list.  */
  _DocumentCategoryHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all DocumentCategory items which match the where clause.  */
  allDocumentCategories?: Maybe<Array<Maybe<DocumentCategory>>>;
  /**  Search for the DocumentCategory item with the matching ID.  */
  DocumentCategory?: Maybe<DocumentCategory>;
  /**  Perform a meta-query on all DocumentCategory items which match the where clause.  */
  _allDocumentCategoriesMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the DocumentCategory list.  */
  _DocumentCategoriesMeta?: Maybe<_ListMeta>;
  /**  Search for all DocumentHistoryRecord items which match the where clause.  */
  allDocumentHistoryRecords?: Maybe<Array<Maybe<DocumentHistoryRecord>>>;
  /**  Search for the DocumentHistoryRecord item with the matching ID.  */
  DocumentHistoryRecord?: Maybe<DocumentHistoryRecord>;
  /**  Perform a meta-query on all DocumentHistoryRecord items which match the where clause.  */
  _allDocumentHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the DocumentHistoryRecord list.  */
  _DocumentHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Document items which match the where clause.  */
  allDocuments?: Maybe<Array<Maybe<Document>>>;
  /**  Search for the Document item with the matching ID.  */
  Document?: Maybe<Document>;
  /**  Perform a meta-query on all Document items which match the where clause.  */
  _allDocumentsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Document list.  */
  _DocumentsMeta?: Maybe<_ListMeta>;
  /**  Search for all WebhookHistoryRecord items which match the where clause.  */
  allWebhookHistoryRecords?: Maybe<Array<Maybe<WebhookHistoryRecord>>>;
  /**  Search for the WebhookHistoryRecord item with the matching ID.  */
  WebhookHistoryRecord?: Maybe<WebhookHistoryRecord>;
  /**  Perform a meta-query on all WebhookHistoryRecord items which match the where clause.  */
  _allWebhookHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the WebhookHistoryRecord list.  */
  _WebhookHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all Webhook items which match the where clause.  */
  allWebhooks?: Maybe<Array<Maybe<Webhook>>>;
  /**  Search for the Webhook item with the matching ID.  */
  Webhook?: Maybe<Webhook>;
  /**  Perform a meta-query on all Webhook items which match the where clause.  */
  _allWebhooksMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the Webhook list.  */
  _WebhooksMeta?: Maybe<_ListMeta>;
  /**  Search for all WebhookSubscriptionHistoryRecord items which match the where clause.  */
  allWebhookSubscriptionHistoryRecords?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecord>>>;
  /**  Search for the WebhookSubscriptionHistoryRecord item with the matching ID.  */
  WebhookSubscriptionHistoryRecord?: Maybe<WebhookSubscriptionHistoryRecord>;
  /**  Perform a meta-query on all WebhookSubscriptionHistoryRecord items which match the where clause.  */
  _allWebhookSubscriptionHistoryRecordsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the WebhookSubscriptionHistoryRecord list.  */
  _WebhookSubscriptionHistoryRecordsMeta?: Maybe<_ListMeta>;
  /**  Search for all WebhookSubscription items which match the where clause.  */
  allWebhookSubscriptions?: Maybe<Array<Maybe<WebhookSubscription>>>;
  /**  Search for the WebhookSubscription item with the matching ID.  */
  WebhookSubscription?: Maybe<WebhookSubscription>;
  /**  Perform a meta-query on all WebhookSubscription items which match the where clause.  */
  _allWebhookSubscriptionsMeta?: Maybe<_QueryMeta>;
  /**  Retrieve the meta-data for the WebhookSubscription list.  */
  _WebhookSubscriptionsMeta?: Maybe<_ListMeta>;
  /**  Retrieve the meta-data for all lists.  */
  _ksListsMeta?: Maybe<Array<Maybe<_ListMeta>>>;
  /**
   * Returns phone number information from ConfirmPhoneAction, that matches provided search conditions
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "variable": [
   *     "data",
   *     "captcha"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "CAPTCHA_CHECK_FAILED",
   *   "message": "Failed to check CAPTCHA",
   *   "messageForUser": "api.user.CAPTCHA_CHECK_FAILED"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "UNABLE_TO_FIND_CONFIRM_PHONE_ACTION",
   *   "message": "Confirm phone action was expired or it could not be found. Try to initiate phone confirmation again",
   *   "messageForUser": "api.user.UNABLE_TO_FIND_CONFIRM_PHONE_ACTION"
   * }`
   */
  getPhoneByConfirmPhoneActionToken?: Maybe<GetPhoneByConfirmPhoneActionTokenOutput>;
  /**
   * To get a token for a specific user, you need to call this query, specifying the required integration type and userId in the parameters. To pass the rights check, you need to request on behalf of the service user, and also have an entry in the ExternalTokenAccessRight table that regulates access to tokens of different integrations
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "query": "GetAccessTokenByUserIdService",
   *   "code": "INTERNAL_ERROR",
   *   "type": "REFRESH_TOKEN_EXPIRED",
   *   "message": "SBBOL user refreshToken expired",
   *   "messageForUser": "api.user.getAccessTokenByUserIdService.REFRESH_TOKEN_EXPIRED"
   * }`
   *
   * `{
   *   "query": "GetAccessTokenByUserIdService",
   *   "code": "BAD_USER_INPUT",
   *   "type": "INVALID_USER_ID",
   *   "message": "Received userId must be uuid",
   *   "messageForUser": "api.user.getAccessTokenByUserIdService.INVALID_USER_ID"
   * }`
   *
   * `{
   *   "query": "GetAccessTokenByUserIdService",
   *   "code": "INTERNAL_ERROR",
   *   "type": "ERROR_GETTING_ACCESS_TOKEN",
   *   "message": "Unresolved error in getAccessTokenForUser",
   *   "messageForUser": "api.user.getAccessTokenByUserIdService.ERROR_GETTING_ACCESS_TOKEN"
   * }`
   */
  getAccessTokenByUserId?: Maybe<GetAccessTokenByUserIdOutput>;
  /**
   * Using an action token with a verified phone number, checks whether a user with the specified type (resident, staff, service) is registered.
   * As a result, information about the completion of some important fields (name, email, phone, password) is also returned.
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "query": "checkUserExistence",
   *   "variable": [
   *     "data",
   *     "token"
   *   ],
   *   "code": "NOT_FOUND",
   *   "type": "TOKEN_NOT_FOUND",
   *   "message": "Token not found"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number",
   *   "query": "checkUserExistence"
   * }`
   *
   * `{
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}",
   *   "query": "checkUserExistence"
   * }`
   */
  checkUserExistence?: Maybe<CheckUserExistenceOutput>;
  exportPropertiesToExcel?: Maybe<ExportPropertiesToExcelOutput>;
  allResidentBillingReceipts?: Maybe<Array<Maybe<ResidentBillingReceiptOutput>>>;
  /**
   * Sum of organizations billing receipts
   *
   * Calculate sum of organizations billing receipts by organizationId or tin and period
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "_allBillingReceiptsSum",
   *   "variable": [
   *     "data"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "You must specify one of two values: tin or organization"
   * }`
   *
   * `{
   *   "mutation": "_allBillingReceiptsSum",
   *   "variable": [
   *     "data",
   *     "period"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_VALUE",
   *   "message": "Bad period format, must be YYYY-MM-01. Example: 2022-02-01"
   * }`
   */
  _allBillingReceiptsSum?: Maybe<BillingReceiptsSumOutput>;
  allResidentBillingVirtualReceipts?: Maybe<Array<Maybe<ResidentBillingReceiptOutput>>>;
  /**
   * Returns id of BankCostItem corresponding to specified payment purpose string
   *
   * Matching is performed by empirical model, implemented in external microservice "condo-classifier-api"
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "query": "predictTransactionClassification",
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "Bank cost item not found",
   *   "messageForUser": "api.user.predictTransactionClassification.COST_ITEM_NOT_FOUND"
   * }`
   *
   * `{
   *   "query": "predictTransactionClassification",
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "Bank cost item isOutcome field not equal isOutcome field from transaction",
   *   "messageForUser": "api.user.predictTransactionClassification.COST_ITEM_IS_OUTCOME_NOT_EQUAL"
   * }`
   *
   * `{
   *   "query": "predictTransactionClassification",
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "ML server response is not successful",
   *   "messageForUser": "api.user.predictTransactionClassification.COST_ITEM_NOT_FOUND"
   * }`
   *
   * `{
   *   "query": "predictTransactionClassification",
   *   "code": "INTERNAL_ERROR",
   *   "type": "NOT_FOUND",
   *   "message": "ML_SPACE_TRANSACTION_CLASSIFIER env variable needs to have endpoint, authKey, workspace",
   *   "messageForUser": "api.user.predictTransactionClassification.ML_SPACE_NOT_CONFIGURED"
   * }`
   */
  predictTransactionClassification?: Maybe<PredictTransactionClassificationOutput>;
  predictTicketClassification?: Maybe<TicketClassifier>;
  getResidentExistenceByPhoneAndAddress?: Maybe<GetResidentExistenceByPhoneAndAddressOutput>;
  findOrganizationsForAddress?: Maybe<Array<Maybe<FindOrganizationForAddressOutput>>>;
  suggestServiceProvider?: Maybe<Array<Maybe<SuggestServiceProviderOutput>>>;
  exportMeterReadings?: Maybe<ExportMeterReadingsOutput>;
  /**
   * Export of property meters readings. A link to the file is returned
   *
   *
   *
   * **Errors**
   *
   * Following objects will be presented in `extensions` property of thrown error
   *
   * `{
   *   "mutation": "exportPropertyMeterReadings",
   *   "variable": [
   *     "data",
   *     "dv"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "DV_VERSION_MISMATCH",
   *   "message": "Wrong value for data version number"
   * }`
   *
   * `{
   *   "mutation": "exportPropertyMeterReadings",
   *   "variable": [
   *     "data",
   *     "sender"
   *   ],
   *   "code": "BAD_USER_INPUT",
   *   "type": "WRONG_FORMAT",
   *   "message": "Invalid format of \"sender\" field value",
   *   "correctExample": "{ dv: 1, fingerprint: 'example-fingerprint-alphanumeric-value'}"
   * }`
   *
   * `{
   *   "query": "exportPropertyMeterReadings",
   *   "code": "BAD_USER_INPUT",
   *   "type": "NOTHING_TO_EXPORT",
   *   "message": "Could not found property meter readings to export for specified organization",
   *   "messageForUser": "api.meter.exportMeterReadings.NOTHING_TO_EXPORT"
   * }`
   */
  exportPropertyMeterReadings?: Maybe<ExportPropertyMeterReadingsOutput>;
  exportPaymentsToExcel?: Maybe<ExportPaymentsToExcelOutput>;
  generatePaymentLink?: Maybe<GeneratePaymentLinkOutput>;
  _allPaymentsSum?: Maybe<PaymentsSumOutput>;
  calculateFeeForReceipt?: Maybe<CalculateFeeForReceiptOutput>;
  ticketReportWidgetData?: Maybe<TicketReportWidgetOutput>;
  ticketAnalyticsReport?: Maybe<TicketAnalyticsReportOutput>;
  exportTicketAnalyticsToExcel?: Maybe<ExportTicketAnalyticsToExcelOutput>;
  getExternalReportIframeUrl?: Maybe<GetExternalReportIframeUrlOutput>;
  getOverviewDashboard?: Maybe<GetOverviewDashboardOutput>;
  exportPropertyScopesToExcel?: Maybe<ExportPropertyScopeToExcelOutput>;
  getNewsItemsRecipientsCounters?: Maybe<GetNewsItemsRecipientsCountersOutput>;
  getNewsSharingRecipients?: Maybe<Array<Maybe<GetNewsSharingRecipientsOutput>>>;
  getNewsSharingRecipientsCounters?: Maybe<GetNewsSharingRecipientsCountersOutput>;
  allMiniApps?: Maybe<Array<MiniAppOutput>>;
  /** The version of the Keystone application serving this API. */
  appVersion?: Maybe<Scalars['String']>;
  authenticatedUser?: Maybe<User>;
};


export type QueryAllUserHistoryRecordsArgs = {
  where?: Maybe<UserHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserHistoryRecordArgs = {
  where: UserHistoryRecordWhereUniqueInput;
};


export type Query_AllUserHistoryRecordsMetaArgs = {
  where?: Maybe<UserHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUsersArgs = {
  where?: Maybe<UserWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUsersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type Query_AllUsersMetaArgs = {
  where?: Maybe<UserWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUsersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllConfirmPhoneActionHistoryRecordsArgs = {
  where?: Maybe<ConfirmPhoneActionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortConfirmPhoneActionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryConfirmPhoneActionHistoryRecordArgs = {
  where: ConfirmPhoneActionHistoryRecordWhereUniqueInput;
};


export type Query_AllConfirmPhoneActionHistoryRecordsMetaArgs = {
  where?: Maybe<ConfirmPhoneActionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortConfirmPhoneActionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllConfirmPhoneActionsArgs = {
  where?: Maybe<ConfirmPhoneActionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortConfirmPhoneActionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryConfirmPhoneActionArgs = {
  where: ConfirmPhoneActionWhereUniqueInput;
};


export type Query_AllConfirmPhoneActionsMetaArgs = {
  where?: Maybe<ConfirmPhoneActionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortConfirmPhoneActionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOidcClientHistoryRecordsArgs = {
  where?: Maybe<OidcClientHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOidcClientHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOidcClientHistoryRecordArgs = {
  where: OidcClientHistoryRecordWhereUniqueInput;
};


export type Query_AllOidcClientHistoryRecordsMetaArgs = {
  where?: Maybe<OidcClientHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOidcClientHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOidcClientsArgs = {
  where?: Maybe<OidcClientWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOidcClientsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOidcClientArgs = {
  where: OidcClientWhereUniqueInput;
};


export type Query_AllOidcClientsMetaArgs = {
  where?: Maybe<OidcClientWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOidcClientsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserExternalIdentityHistoryRecordsArgs = {
  where?: Maybe<UserExternalIdentityHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserExternalIdentityHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserExternalIdentityHistoryRecordArgs = {
  where: UserExternalIdentityHistoryRecordWhereUniqueInput;
};


export type Query_AllUserExternalIdentityHistoryRecordsMetaArgs = {
  where?: Maybe<UserExternalIdentityHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserExternalIdentityHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserExternalIdentitiesArgs = {
  where?: Maybe<UserExternalIdentityWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserExternalIdentitiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserExternalIdentityArgs = {
  where: UserExternalIdentityWhereUniqueInput;
};


export type Query_AllUserExternalIdentitiesMetaArgs = {
  where?: Maybe<UserExternalIdentityWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserExternalIdentitiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllExternalTokenAccessRightHistoryRecordsArgs = {
  where?: Maybe<ExternalTokenAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalTokenAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryExternalTokenAccessRightHistoryRecordArgs = {
  where: ExternalTokenAccessRightHistoryRecordWhereUniqueInput;
};


export type Query_AllExternalTokenAccessRightHistoryRecordsMetaArgs = {
  where?: Maybe<ExternalTokenAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalTokenAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllExternalTokenAccessRightsArgs = {
  where?: Maybe<ExternalTokenAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalTokenAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryExternalTokenAccessRightArgs = {
  where: ExternalTokenAccessRightWhereUniqueInput;
};


export type Query_AllExternalTokenAccessRightsMetaArgs = {
  where?: Maybe<ExternalTokenAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalTokenAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserRightsSetHistoryRecordsArgs = {
  where?: Maybe<UserRightsSetHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserRightsSetHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserRightsSetHistoryRecordArgs = {
  where: UserRightsSetHistoryRecordWhereUniqueInput;
};


export type Query_AllUserRightsSetHistoryRecordsMetaArgs = {
  where?: Maybe<UserRightsSetHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserRightsSetHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserRightsSetsArgs = {
  where?: Maybe<UserRightsSetWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserRightsSetsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserRightsSetArgs = {
  where: UserRightsSetWhereUniqueInput;
};


export type Query_AllUserRightsSetsMetaArgs = {
  where?: Maybe<UserRightsSetWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserRightsSetsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationHistoryRecordsArgs = {
  where?: Maybe<OrganizationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationHistoryRecordArgs = {
  where: OrganizationHistoryRecordWhereUniqueInput;
};


export type Query_AllOrganizationHistoryRecordsMetaArgs = {
  where?: Maybe<OrganizationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationsArgs = {
  where?: Maybe<OrganizationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type Query_AllOrganizationsMetaArgs = {
  where?: Maybe<OrganizationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationEmployeeHistoryRecordsArgs = {
  where?: Maybe<OrganizationEmployeeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationEmployeeHistoryRecordArgs = {
  where: OrganizationEmployeeHistoryRecordWhereUniqueInput;
};


export type Query_AllOrganizationEmployeeHistoryRecordsMetaArgs = {
  where?: Maybe<OrganizationEmployeeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationEmployeesArgs = {
  where?: Maybe<OrganizationEmployeeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationEmployeeArgs = {
  where: OrganizationEmployeeWhereUniqueInput;
};


export type Query_AllOrganizationEmployeesMetaArgs = {
  where?: Maybe<OrganizationEmployeeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationEmployeeRoleHistoryRecordsArgs = {
  where?: Maybe<OrganizationEmployeeRoleHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeRoleHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationEmployeeRoleHistoryRecordArgs = {
  where: OrganizationEmployeeRoleHistoryRecordWhereUniqueInput;
};


export type Query_AllOrganizationEmployeeRoleHistoryRecordsMetaArgs = {
  where?: Maybe<OrganizationEmployeeRoleHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeRoleHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationEmployeeRolesArgs = {
  where?: Maybe<OrganizationEmployeeRoleWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeRolesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationEmployeeRoleArgs = {
  where: OrganizationEmployeeRoleWhereUniqueInput;
};


export type Query_AllOrganizationEmployeeRolesMetaArgs = {
  where?: Maybe<OrganizationEmployeeRoleWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeRolesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationLinkHistoryRecordsArgs = {
  where?: Maybe<OrganizationLinkHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationLinkHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationLinkHistoryRecordArgs = {
  where: OrganizationLinkHistoryRecordWhereUniqueInput;
};


export type Query_AllOrganizationLinkHistoryRecordsMetaArgs = {
  where?: Maybe<OrganizationLinkHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationLinkHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationLinksArgs = {
  where?: Maybe<OrganizationLinkWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationLinksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationLinkArgs = {
  where: OrganizationLinkWhereUniqueInput;
};


export type Query_AllOrganizationLinksMetaArgs = {
  where?: Maybe<OrganizationLinkWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationLinksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationEmployeeSpecializationHistoryRecordsArgs = {
  where?: Maybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeSpecializationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationEmployeeSpecializationHistoryRecordArgs = {
  where: OrganizationEmployeeSpecializationHistoryRecordWhereUniqueInput;
};


export type Query_AllOrganizationEmployeeSpecializationHistoryRecordsMetaArgs = {
  where?: Maybe<OrganizationEmployeeSpecializationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeSpecializationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllOrganizationEmployeeSpecializationsArgs = {
  where?: Maybe<OrganizationEmployeeSpecializationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeSpecializationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationEmployeeSpecializationArgs = {
  where: OrganizationEmployeeSpecializationWhereUniqueInput;
};


export type Query_AllOrganizationEmployeeSpecializationsMetaArgs = {
  where?: Maybe<OrganizationEmployeeSpecializationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortOrganizationEmployeeSpecializationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyHistoryRecordsArgs = {
  where?: Maybe<PropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyHistoryRecordArgs = {
  where: PropertyHistoryRecordWhereUniqueInput;
};


export type Query_AllPropertyHistoryRecordsMetaArgs = {
  where?: Maybe<PropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertiesArgs = {
  where?: Maybe<PropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyArgs = {
  where: PropertyWhereUniqueInput;
};


export type Query_AllPropertiesMetaArgs = {
  where?: Maybe<PropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationHistoryRecordsArgs = {
  where?: Maybe<BillingIntegrationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationHistoryRecordArgs = {
  where: BillingIntegrationHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingIntegrationHistoryRecordsMetaArgs = {
  where?: Maybe<BillingIntegrationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationsArgs = {
  where?: Maybe<BillingIntegrationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationArgs = {
  where: BillingIntegrationWhereUniqueInput;
};


export type Query_AllBillingIntegrationsMetaArgs = {
  where?: Maybe<BillingIntegrationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationAccessRightHistoryRecordsArgs = {
  where?: Maybe<BillingIntegrationAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationAccessRightHistoryRecordArgs = {
  where: BillingIntegrationAccessRightHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingIntegrationAccessRightHistoryRecordsMetaArgs = {
  where?: Maybe<BillingIntegrationAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationAccessRightsArgs = {
  where?: Maybe<BillingIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationAccessRightArgs = {
  where: BillingIntegrationAccessRightWhereUniqueInput;
};


export type Query_AllBillingIntegrationAccessRightsMetaArgs = {
  where?: Maybe<BillingIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationOrganizationContextHistoryRecordsArgs = {
  where?: Maybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationOrganizationContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationOrganizationContextHistoryRecordArgs = {
  where: BillingIntegrationOrganizationContextHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingIntegrationOrganizationContextHistoryRecordsMetaArgs = {
  where?: Maybe<BillingIntegrationOrganizationContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationOrganizationContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationOrganizationContextsArgs = {
  where?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationOrganizationContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationOrganizationContextArgs = {
  where: BillingIntegrationOrganizationContextWhereUniqueInput;
};


export type Query_AllBillingIntegrationOrganizationContextsMetaArgs = {
  where?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationOrganizationContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingIntegrationProblemsArgs = {
  where?: Maybe<BillingIntegrationProblemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationProblemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingIntegrationProblemArgs = {
  where: BillingIntegrationProblemWhereUniqueInput;
};


export type Query_AllBillingIntegrationProblemsMetaArgs = {
  where?: Maybe<BillingIntegrationProblemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingIntegrationProblemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingPropertyHistoryRecordsArgs = {
  where?: Maybe<BillingPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingPropertyHistoryRecordArgs = {
  where: BillingPropertyHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingPropertyHistoryRecordsMetaArgs = {
  where?: Maybe<BillingPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingPropertiesArgs = {
  where?: Maybe<BillingPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingPropertyArgs = {
  where: BillingPropertyWhereUniqueInput;
};


export type Query_AllBillingPropertiesMetaArgs = {
  where?: Maybe<BillingPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingAccountHistoryRecordsArgs = {
  where?: Maybe<BillingAccountHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingAccountHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingAccountHistoryRecordArgs = {
  where: BillingAccountHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingAccountHistoryRecordsMetaArgs = {
  where?: Maybe<BillingAccountHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingAccountHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingAccountsArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingAccountsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingAccountArgs = {
  where: BillingAccountWhereUniqueInput;
};


export type Query_AllBillingAccountsMetaArgs = {
  where?: Maybe<BillingAccountWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingAccountsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingReceiptHistoryRecordsArgs = {
  where?: Maybe<BillingReceiptHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingReceiptHistoryRecordArgs = {
  where: BillingReceiptHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingReceiptHistoryRecordsMetaArgs = {
  where?: Maybe<BillingReceiptHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingReceiptsArgs = {
  where?: Maybe<BillingReceiptWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingReceiptArgs = {
  where: BillingReceiptWhereUniqueInput;
};


export type Query_AllBillingReceiptsMetaArgs = {
  where?: Maybe<BillingReceiptWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingRecipientHistoryRecordsArgs = {
  where?: Maybe<BillingRecipientHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingRecipientHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingRecipientHistoryRecordArgs = {
  where: BillingRecipientHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingRecipientHistoryRecordsMetaArgs = {
  where?: Maybe<BillingRecipientHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingRecipientHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingRecipientsArgs = {
  where?: Maybe<BillingRecipientWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingRecipientsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingRecipientArgs = {
  where: BillingRecipientWhereUniqueInput;
};


export type Query_AllBillingRecipientsMetaArgs = {
  where?: Maybe<BillingRecipientWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingRecipientsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingCategoryHistoryRecordsArgs = {
  where?: Maybe<BillingCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingCategoryHistoryRecordArgs = {
  where: BillingCategoryHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingCategoryHistoryRecordsMetaArgs = {
  where?: Maybe<BillingCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingCategoriesArgs = {
  where?: Maybe<BillingCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingCategoryArgs = {
  where: BillingCategoryWhereUniqueInput;
};


export type Query_AllBillingCategoriesMetaArgs = {
  where?: Maybe<BillingCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingReceiptFileHistoryRecordsArgs = {
  where?: Maybe<BillingReceiptFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingReceiptFileHistoryRecordArgs = {
  where: BillingReceiptFileHistoryRecordWhereUniqueInput;
};


export type Query_AllBillingReceiptFileHistoryRecordsMetaArgs = {
  where?: Maybe<BillingReceiptFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBillingReceiptFilesArgs = {
  where?: Maybe<BillingReceiptFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBillingReceiptFileArgs = {
  where: BillingReceiptFileWhereUniqueInput;
};


export type Query_AllBillingReceiptFilesMetaArgs = {
  where?: Maybe<BillingReceiptFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBillingReceiptFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankAccountHistoryRecordsArgs = {
  where?: Maybe<BankAccountHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankAccountHistoryRecordArgs = {
  where: BankAccountHistoryRecordWhereUniqueInput;
};


export type Query_AllBankAccountHistoryRecordsMetaArgs = {
  where?: Maybe<BankAccountHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankAccountsArgs = {
  where?: Maybe<BankAccountWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankAccountArgs = {
  where: BankAccountWhereUniqueInput;
};


export type Query_AllBankAccountsMetaArgs = {
  where?: Maybe<BankAccountWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankCategoryHistoryRecordsArgs = {
  where?: Maybe<BankCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankCategoryHistoryRecordArgs = {
  where: BankCategoryHistoryRecordWhereUniqueInput;
};


export type Query_AllBankCategoryHistoryRecordsMetaArgs = {
  where?: Maybe<BankCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankCategoriesArgs = {
  where?: Maybe<BankCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankCategoryArgs = {
  where: BankCategoryWhereUniqueInput;
};


export type Query_AllBankCategoriesMetaArgs = {
  where?: Maybe<BankCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankCostItemHistoryRecordsArgs = {
  where?: Maybe<BankCostItemHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCostItemHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankCostItemHistoryRecordArgs = {
  where: BankCostItemHistoryRecordWhereUniqueInput;
};


export type Query_AllBankCostItemHistoryRecordsMetaArgs = {
  where?: Maybe<BankCostItemHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCostItemHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankCostItemsArgs = {
  where?: Maybe<BankCostItemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCostItemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankCostItemArgs = {
  where: BankCostItemWhereUniqueInput;
};


export type Query_AllBankCostItemsMetaArgs = {
  where?: Maybe<BankCostItemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankCostItemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankContractorAccountHistoryRecordsArgs = {
  where?: Maybe<BankContractorAccountHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankContractorAccountHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankContractorAccountHistoryRecordArgs = {
  where: BankContractorAccountHistoryRecordWhereUniqueInput;
};


export type Query_AllBankContractorAccountHistoryRecordsMetaArgs = {
  where?: Maybe<BankContractorAccountHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankContractorAccountHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankContractorAccountsArgs = {
  where?: Maybe<BankContractorAccountWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankContractorAccountsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankContractorAccountArgs = {
  where: BankContractorAccountWhereUniqueInput;
};


export type Query_AllBankContractorAccountsMetaArgs = {
  where?: Maybe<BankContractorAccountWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankContractorAccountsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationHistoryRecordsArgs = {
  where?: Maybe<BankIntegrationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationHistoryRecordArgs = {
  where: BankIntegrationHistoryRecordWhereUniqueInput;
};


export type Query_AllBankIntegrationHistoryRecordsMetaArgs = {
  where?: Maybe<BankIntegrationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationsArgs = {
  where?: Maybe<BankIntegrationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationArgs = {
  where: BankIntegrationWhereUniqueInput;
};


export type Query_AllBankIntegrationsMetaArgs = {
  where?: Maybe<BankIntegrationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationAccessRightHistoryRecordsArgs = {
  where?: Maybe<BankIntegrationAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationAccessRightHistoryRecordArgs = {
  where: BankIntegrationAccessRightHistoryRecordWhereUniqueInput;
};


export type Query_AllBankIntegrationAccessRightHistoryRecordsMetaArgs = {
  where?: Maybe<BankIntegrationAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationAccessRightsArgs = {
  where?: Maybe<BankIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationAccessRightArgs = {
  where: BankIntegrationAccessRightWhereUniqueInput;
};


export type Query_AllBankIntegrationAccessRightsMetaArgs = {
  where?: Maybe<BankIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationAccountContextHistoryRecordsArgs = {
  where?: Maybe<BankIntegrationAccountContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccountContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationAccountContextHistoryRecordArgs = {
  where: BankIntegrationAccountContextHistoryRecordWhereUniqueInput;
};


export type Query_AllBankIntegrationAccountContextHistoryRecordsMetaArgs = {
  where?: Maybe<BankIntegrationAccountContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccountContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationAccountContextsArgs = {
  where?: Maybe<BankIntegrationAccountContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccountContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationAccountContextArgs = {
  where: BankIntegrationAccountContextWhereUniqueInput;
};


export type Query_AllBankIntegrationAccountContextsMetaArgs = {
  where?: Maybe<BankIntegrationAccountContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationAccountContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankTransactionHistoryRecordsArgs = {
  where?: Maybe<BankTransactionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankTransactionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankTransactionHistoryRecordArgs = {
  where: BankTransactionHistoryRecordWhereUniqueInput;
};


export type Query_AllBankTransactionHistoryRecordsMetaArgs = {
  where?: Maybe<BankTransactionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankTransactionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankTransactionsArgs = {
  where?: Maybe<BankTransactionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankTransactionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankTransactionArgs = {
  where: BankTransactionWhereUniqueInput;
};


export type Query_AllBankTransactionsMetaArgs = {
  where?: Maybe<BankTransactionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankTransactionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankSyncTaskHistoryRecordsArgs = {
  where?: Maybe<BankSyncTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankSyncTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankSyncTaskHistoryRecordArgs = {
  where: BankSyncTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllBankSyncTaskHistoryRecordsMetaArgs = {
  where?: Maybe<BankSyncTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankSyncTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankSyncTasksArgs = {
  where?: Maybe<BankSyncTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankSyncTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankSyncTaskArgs = {
  where: BankSyncTaskWhereUniqueInput;
};


export type Query_AllBankSyncTasksMetaArgs = {
  where?: Maybe<BankSyncTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankSyncTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationOrganizationContextHistoryRecordsArgs = {
  where?: Maybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationOrganizationContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationOrganizationContextHistoryRecordArgs = {
  where: BankIntegrationOrganizationContextHistoryRecordWhereUniqueInput;
};


export type Query_AllBankIntegrationOrganizationContextHistoryRecordsMetaArgs = {
  where?: Maybe<BankIntegrationOrganizationContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationOrganizationContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankIntegrationOrganizationContextsArgs = {
  where?: Maybe<BankIntegrationOrganizationContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationOrganizationContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankIntegrationOrganizationContextArgs = {
  where: BankIntegrationOrganizationContextWhereUniqueInput;
};


export type Query_AllBankIntegrationOrganizationContextsMetaArgs = {
  where?: Maybe<BankIntegrationOrganizationContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankIntegrationOrganizationContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankAccountReportHistoryRecordsArgs = {
  where?: Maybe<BankAccountReportHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankAccountReportHistoryRecordArgs = {
  where: BankAccountReportHistoryRecordWhereUniqueInput;
};


export type Query_AllBankAccountReportHistoryRecordsMetaArgs = {
  where?: Maybe<BankAccountReportHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankAccountReportsArgs = {
  where?: Maybe<BankAccountReportWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankAccountReportArgs = {
  where: BankAccountReportWhereUniqueInput;
};


export type Query_AllBankAccountReportsMetaArgs = {
  where?: Maybe<BankAccountReportWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankAccountReportTaskHistoryRecordsArgs = {
  where?: Maybe<BankAccountReportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankAccountReportTaskHistoryRecordArgs = {
  where: BankAccountReportTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllBankAccountReportTaskHistoryRecordsMetaArgs = {
  where?: Maybe<BankAccountReportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllBankAccountReportTasksArgs = {
  where?: Maybe<BankAccountReportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBankAccountReportTaskArgs = {
  where: BankAccountReportTaskWhereUniqueInput;
};


export type Query_AllBankAccountReportTasksMetaArgs = {
  where?: Maybe<BankAccountReportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortBankAccountReportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketHistoryRecordsArgs = {
  where?: Maybe<TicketHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketHistoryRecordArgs = {
  where: TicketHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketHistoryRecordsMetaArgs = {
  where?: Maybe<TicketHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketsArgs = {
  where?: Maybe<TicketWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketArgs = {
  where: TicketWhereUniqueInput;
};


export type Query_AllTicketsMetaArgs = {
  where?: Maybe<TicketWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketSourceHistoryRecordsArgs = {
  where?: Maybe<TicketSourceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketSourceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketSourceHistoryRecordArgs = {
  where: TicketSourceHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketSourceHistoryRecordsMetaArgs = {
  where?: Maybe<TicketSourceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketSourceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketSourcesArgs = {
  where?: Maybe<TicketSourceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketSourcesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketSourceArgs = {
  where: TicketSourceWhereUniqueInput;
};


export type Query_AllTicketSourcesMetaArgs = {
  where?: Maybe<TicketSourceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketSourcesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketStatusHistoryRecordsArgs = {
  where?: Maybe<TicketStatusHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketStatusHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketStatusHistoryRecordArgs = {
  where: TicketStatusHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketStatusHistoryRecordsMetaArgs = {
  where?: Maybe<TicketStatusHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketStatusHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketStatusesArgs = {
  where?: Maybe<TicketStatusWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketStatusesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketStatusArgs = {
  where: TicketStatusWhereUniqueInput;
};


export type Query_AllTicketStatusesMetaArgs = {
  where?: Maybe<TicketStatusWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketStatusesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketFileHistoryRecordsArgs = {
  where?: Maybe<TicketFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketFileHistoryRecordArgs = {
  where: TicketFileHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketFileHistoryRecordsMetaArgs = {
  where?: Maybe<TicketFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketFilesArgs = {
  where?: Maybe<TicketFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketFileArgs = {
  where: TicketFileWhereUniqueInput;
};


export type Query_AllTicketFilesMetaArgs = {
  where?: Maybe<TicketFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketChangesArgs = {
  where?: Maybe<TicketChangeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketChangesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketChangeArgs = {
  where: TicketChangeWhereUniqueInput;
};


export type Query_AllTicketChangesMetaArgs = {
  where?: Maybe<TicketChangeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketChangesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketCommentHistoryRecordsArgs = {
  where?: Maybe<TicketCommentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketCommentHistoryRecordArgs = {
  where: TicketCommentHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketCommentHistoryRecordsMetaArgs = {
  where?: Maybe<TicketCommentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketCommentsArgs = {
  where?: Maybe<TicketCommentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketCommentArgs = {
  where: TicketCommentWhereUniqueInput;
};


export type Query_AllTicketCommentsMetaArgs = {
  where?: Maybe<TicketCommentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketPlaceClassifierHistoryRecordsArgs = {
  where?: Maybe<TicketPlaceClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPlaceClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketPlaceClassifierHistoryRecordArgs = {
  where: TicketPlaceClassifierHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketPlaceClassifierHistoryRecordsMetaArgs = {
  where?: Maybe<TicketPlaceClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPlaceClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketPlaceClassifiersArgs = {
  where?: Maybe<TicketPlaceClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPlaceClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketPlaceClassifierArgs = {
  where: TicketPlaceClassifierWhereUniqueInput;
};


export type Query_AllTicketPlaceClassifiersMetaArgs = {
  where?: Maybe<TicketPlaceClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPlaceClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketCategoryClassifierHistoryRecordsArgs = {
  where?: Maybe<TicketCategoryClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCategoryClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketCategoryClassifierHistoryRecordArgs = {
  where: TicketCategoryClassifierHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketCategoryClassifierHistoryRecordsMetaArgs = {
  where?: Maybe<TicketCategoryClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCategoryClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketCategoryClassifiersArgs = {
  where?: Maybe<TicketCategoryClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCategoryClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketCategoryClassifierArgs = {
  where: TicketCategoryClassifierWhereUniqueInput;
};


export type Query_AllTicketCategoryClassifiersMetaArgs = {
  where?: Maybe<TicketCategoryClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCategoryClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketProblemClassifierHistoryRecordsArgs = {
  where?: Maybe<TicketProblemClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketProblemClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketProblemClassifierHistoryRecordArgs = {
  where: TicketProblemClassifierHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketProblemClassifierHistoryRecordsMetaArgs = {
  where?: Maybe<TicketProblemClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketProblemClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketProblemClassifiersArgs = {
  where?: Maybe<TicketProblemClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketProblemClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketProblemClassifierArgs = {
  where: TicketProblemClassifierWhereUniqueInput;
};


export type Query_AllTicketProblemClassifiersMetaArgs = {
  where?: Maybe<TicketProblemClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketProblemClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketClassifierHistoryRecordsArgs = {
  where?: Maybe<TicketClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketClassifierHistoryRecordArgs = {
  where: TicketClassifierHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketClassifierHistoryRecordsMetaArgs = {
  where?: Maybe<TicketClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketClassifiersArgs = {
  where?: Maybe<TicketClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketClassifierArgs = {
  where: TicketClassifierWhereUniqueInput;
};


export type Query_AllTicketClassifiersMetaArgs = {
  where?: Maybe<TicketClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketFilterTemplateHistoryRecordsArgs = {
  where?: Maybe<TicketFilterTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFilterTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketFilterTemplateHistoryRecordArgs = {
  where: TicketFilterTemplateHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketFilterTemplateHistoryRecordsMetaArgs = {
  where?: Maybe<TicketFilterTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFilterTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketFilterTemplatesArgs = {
  where?: Maybe<TicketFilterTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFilterTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketFilterTemplateArgs = {
  where: TicketFilterTemplateWhereUniqueInput;
};


export type Query_AllTicketFilterTemplatesMetaArgs = {
  where?: Maybe<TicketFilterTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketFilterTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketCommentFileHistoryRecordsArgs = {
  where?: Maybe<TicketCommentFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketCommentFileHistoryRecordArgs = {
  where: TicketCommentFileHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketCommentFileHistoryRecordsMetaArgs = {
  where?: Maybe<TicketCommentFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketCommentFilesArgs = {
  where?: Maybe<TicketCommentFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketCommentFileArgs = {
  where: TicketCommentFileWhereUniqueInput;
};


export type Query_AllTicketCommentFilesMetaArgs = {
  where?: Maybe<TicketCommentFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketCommentFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserTicketCommentReadTimeHistoryRecordsArgs = {
  where?: Maybe<UserTicketCommentReadTimeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserTicketCommentReadTimeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserTicketCommentReadTimeHistoryRecordArgs = {
  where: UserTicketCommentReadTimeHistoryRecordWhereUniqueInput;
};


export type Query_AllUserTicketCommentReadTimeHistoryRecordsMetaArgs = {
  where?: Maybe<UserTicketCommentReadTimeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserTicketCommentReadTimeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserTicketCommentReadTimesArgs = {
  where?: Maybe<UserTicketCommentReadTimeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserTicketCommentReadTimesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserTicketCommentReadTimeArgs = {
  where: UserTicketCommentReadTimeWhereUniqueInput;
};


export type Query_AllUserTicketCommentReadTimesMetaArgs = {
  where?: Maybe<UserTicketCommentReadTimeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserTicketCommentReadTimesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketPropertyHintHistoryRecordsArgs = {
  where?: Maybe<TicketPropertyHintHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketPropertyHintHistoryRecordArgs = {
  where: TicketPropertyHintHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketPropertyHintHistoryRecordsMetaArgs = {
  where?: Maybe<TicketPropertyHintHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketPropertyHintsArgs = {
  where?: Maybe<TicketPropertyHintWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketPropertyHintArgs = {
  where: TicketPropertyHintWhereUniqueInput;
};


export type Query_AllTicketPropertyHintsMetaArgs = {
  where?: Maybe<TicketPropertyHintWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketPropertyHintPropertyHistoryRecordsArgs = {
  where?: Maybe<TicketPropertyHintPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketPropertyHintPropertyHistoryRecordArgs = {
  where: TicketPropertyHintPropertyHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketPropertyHintPropertyHistoryRecordsMetaArgs = {
  where?: Maybe<TicketPropertyHintPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketPropertyHintPropertiesArgs = {
  where?: Maybe<TicketPropertyHintPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketPropertyHintPropertyArgs = {
  where: TicketPropertyHintPropertyWhereUniqueInput;
};


export type Query_AllTicketPropertyHintPropertiesMetaArgs = {
  where?: Maybe<TicketPropertyHintPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketPropertyHintPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketExportTaskHistoryRecordsArgs = {
  where?: Maybe<TicketExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketExportTaskHistoryRecordArgs = {
  where: TicketExportTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketExportTaskHistoryRecordsMetaArgs = {
  where?: Maybe<TicketExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketExportTasksArgs = {
  where?: Maybe<TicketExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketExportTaskArgs = {
  where: TicketExportTaskWhereUniqueInput;
};


export type Query_AllTicketExportTasksMetaArgs = {
  where?: Maybe<TicketExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketOrganizationSettingHistoryRecordsArgs = {
  where?: Maybe<TicketOrganizationSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketOrganizationSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketOrganizationSettingHistoryRecordArgs = {
  where: TicketOrganizationSettingHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketOrganizationSettingHistoryRecordsMetaArgs = {
  where?: Maybe<TicketOrganizationSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketOrganizationSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketOrganizationSettingsArgs = {
  where?: Maybe<TicketOrganizationSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketOrganizationSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketOrganizationSettingArgs = {
  where: TicketOrganizationSettingWhereUniqueInput;
};


export type Query_AllTicketOrganizationSettingsMetaArgs = {
  where?: Maybe<TicketOrganizationSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketOrganizationSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentHistoryRecordsArgs = {
  where?: Maybe<IncidentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentHistoryRecordArgs = {
  where: IncidentHistoryRecordWhereUniqueInput;
};


export type Query_AllIncidentHistoryRecordsMetaArgs = {
  where?: Maybe<IncidentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentsArgs = {
  where?: Maybe<IncidentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentArgs = {
  where: IncidentWhereUniqueInput;
};


export type Query_AllIncidentsMetaArgs = {
  where?: Maybe<IncidentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentChangesArgs = {
  where?: Maybe<IncidentChangeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentChangesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentChangeArgs = {
  where: IncidentChangeWhereUniqueInput;
};


export type Query_AllIncidentChangesMetaArgs = {
  where?: Maybe<IncidentChangeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentChangesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentPropertyHistoryRecordsArgs = {
  where?: Maybe<IncidentPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentPropertyHistoryRecordArgs = {
  where: IncidentPropertyHistoryRecordWhereUniqueInput;
};


export type Query_AllIncidentPropertyHistoryRecordsMetaArgs = {
  where?: Maybe<IncidentPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentPropertiesArgs = {
  where?: Maybe<IncidentPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentPropertyArgs = {
  where: IncidentPropertyWhereUniqueInput;
};


export type Query_AllIncidentPropertiesMetaArgs = {
  where?: Maybe<IncidentPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentClassifierHistoryRecordsArgs = {
  where?: Maybe<IncidentClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentClassifierHistoryRecordArgs = {
  where: IncidentClassifierHistoryRecordWhereUniqueInput;
};


export type Query_AllIncidentClassifierHistoryRecordsMetaArgs = {
  where?: Maybe<IncidentClassifierHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifierHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentClassifiersArgs = {
  where?: Maybe<IncidentClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentClassifierArgs = {
  where: IncidentClassifierWhereUniqueInput;
};


export type Query_AllIncidentClassifiersMetaArgs = {
  where?: Maybe<IncidentClassifierWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifiersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentClassifierIncidentHistoryRecordsArgs = {
  where?: Maybe<IncidentClassifierIncidentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifierIncidentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentClassifierIncidentHistoryRecordArgs = {
  where: IncidentClassifierIncidentHistoryRecordWhereUniqueInput;
};


export type Query_AllIncidentClassifierIncidentHistoryRecordsMetaArgs = {
  where?: Maybe<IncidentClassifierIncidentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifierIncidentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentClassifierIncidentsArgs = {
  where?: Maybe<IncidentClassifierIncidentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifierIncidentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentClassifierIncidentArgs = {
  where: IncidentClassifierIncidentWhereUniqueInput;
};


export type Query_AllIncidentClassifierIncidentsMetaArgs = {
  where?: Maybe<IncidentClassifierIncidentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentClassifierIncidentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserFavoriteTicketHistoryRecordsArgs = {
  where?: Maybe<UserFavoriteTicketHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserFavoriteTicketHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserFavoriteTicketHistoryRecordArgs = {
  where: UserFavoriteTicketHistoryRecordWhereUniqueInput;
};


export type Query_AllUserFavoriteTicketHistoryRecordsMetaArgs = {
  where?: Maybe<UserFavoriteTicketHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserFavoriteTicketHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserFavoriteTicketsArgs = {
  where?: Maybe<UserFavoriteTicketWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserFavoriteTicketsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserFavoriteTicketArgs = {
  where: UserFavoriteTicketWhereUniqueInput;
};


export type Query_AllUserFavoriteTicketsMetaArgs = {
  where?: Maybe<UserFavoriteTicketWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserFavoriteTicketsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentExportTaskHistoryRecordsArgs = {
  where?: Maybe<IncidentExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentExportTaskHistoryRecordArgs = {
  where: IncidentExportTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllIncidentExportTaskHistoryRecordsMetaArgs = {
  where?: Maybe<IncidentExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllIncidentExportTasksArgs = {
  where?: Maybe<IncidentExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryIncidentExportTaskArgs = {
  where: IncidentExportTaskWhereUniqueInput;
};


export type Query_AllIncidentExportTasksMetaArgs = {
  where?: Maybe<IncidentExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortIncidentExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllCallRecordHistoryRecordsArgs = {
  where?: Maybe<CallRecordHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCallRecordHistoryRecordArgs = {
  where: CallRecordHistoryRecordWhereUniqueInput;
};


export type Query_AllCallRecordHistoryRecordsMetaArgs = {
  where?: Maybe<CallRecordHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllCallRecordsArgs = {
  where?: Maybe<CallRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCallRecordArgs = {
  where: CallRecordWhereUniqueInput;
};


export type Query_AllCallRecordsMetaArgs = {
  where?: Maybe<CallRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllCallRecordFragmentHistoryRecordsArgs = {
  where?: Maybe<CallRecordFragmentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordFragmentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCallRecordFragmentHistoryRecordArgs = {
  where: CallRecordFragmentHistoryRecordWhereUniqueInput;
};


export type Query_AllCallRecordFragmentHistoryRecordsMetaArgs = {
  where?: Maybe<CallRecordFragmentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordFragmentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllCallRecordFragmentsArgs = {
  where?: Maybe<CallRecordFragmentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordFragmentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCallRecordFragmentArgs = {
  where: CallRecordFragmentWhereUniqueInput;
};


export type Query_AllCallRecordFragmentsMetaArgs = {
  where?: Maybe<CallRecordFragmentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortCallRecordFragmentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketAutoAssignmentHistoryRecordsArgs = {
  where?: Maybe<TicketAutoAssignmentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketAutoAssignmentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketAutoAssignmentHistoryRecordArgs = {
  where: TicketAutoAssignmentHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketAutoAssignmentHistoryRecordsMetaArgs = {
  where?: Maybe<TicketAutoAssignmentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketAutoAssignmentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketAutoAssignmentsArgs = {
  where?: Maybe<TicketAutoAssignmentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketAutoAssignmentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketAutoAssignmentArgs = {
  where: TicketAutoAssignmentWhereUniqueInput;
};


export type Query_AllTicketAutoAssignmentsMetaArgs = {
  where?: Maybe<TicketAutoAssignmentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketAutoAssignmentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketDocumentGenerationTaskHistoryRecordsArgs = {
  where?: Maybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketDocumentGenerationTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketDocumentGenerationTaskHistoryRecordArgs = {
  where: TicketDocumentGenerationTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllTicketDocumentGenerationTaskHistoryRecordsMetaArgs = {
  where?: Maybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketDocumentGenerationTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTicketDocumentGenerationTasksArgs = {
  where?: Maybe<TicketDocumentGenerationTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketDocumentGenerationTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTicketDocumentGenerationTaskArgs = {
  where: TicketDocumentGenerationTaskWhereUniqueInput;
};


export type Query_AllTicketDocumentGenerationTasksMetaArgs = {
  where?: Maybe<TicketDocumentGenerationTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTicketDocumentGenerationTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageHistoryRecordsArgs = {
  where?: Maybe<MessageHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageHistoryRecordArgs = {
  where: MessageHistoryRecordWhereUniqueInput;
};


export type Query_AllMessageHistoryRecordsMetaArgs = {
  where?: Maybe<MessageHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessagesArgs = {
  where?: Maybe<MessageWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessagesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageArgs = {
  where: MessageWhereUniqueInput;
};


export type Query_AllMessagesMetaArgs = {
  where?: Maybe<MessageWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessagesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllRemoteClientHistoryRecordsArgs = {
  where?: Maybe<RemoteClientHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRemoteClientHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRemoteClientHistoryRecordArgs = {
  where: RemoteClientHistoryRecordWhereUniqueInput;
};


export type Query_AllRemoteClientHistoryRecordsMetaArgs = {
  where?: Maybe<RemoteClientHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRemoteClientHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllRemoteClientsArgs = {
  where?: Maybe<RemoteClientWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRemoteClientsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRemoteClientArgs = {
  where: RemoteClientWhereUniqueInput;
};


export type Query_AllRemoteClientsMetaArgs = {
  where?: Maybe<RemoteClientWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRemoteClientsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageUserBlackListHistoryRecordsArgs = {
  where?: Maybe<MessageUserBlackListHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageUserBlackListHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageUserBlackListHistoryRecordArgs = {
  where: MessageUserBlackListHistoryRecordWhereUniqueInput;
};


export type Query_AllMessageUserBlackListHistoryRecordsMetaArgs = {
  where?: Maybe<MessageUserBlackListHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageUserBlackListHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageUserBlackListsArgs = {
  where?: Maybe<MessageUserBlackListWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageUserBlackListsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageUserBlackListArgs = {
  where: MessageUserBlackListWhereUniqueInput;
};


export type Query_AllMessageUserBlackListsMetaArgs = {
  where?: Maybe<MessageUserBlackListWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageUserBlackListsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageOrganizationBlackListHistoryRecordsArgs = {
  where?: Maybe<MessageOrganizationBlackListHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageOrganizationBlackListHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageOrganizationBlackListHistoryRecordArgs = {
  where: MessageOrganizationBlackListHistoryRecordWhereUniqueInput;
};


export type Query_AllMessageOrganizationBlackListHistoryRecordsMetaArgs = {
  where?: Maybe<MessageOrganizationBlackListHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageOrganizationBlackListHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageOrganizationBlackListsArgs = {
  where?: Maybe<MessageOrganizationBlackListWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageOrganizationBlackListsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageOrganizationBlackListArgs = {
  where: MessageOrganizationBlackListWhereUniqueInput;
};


export type Query_AllMessageOrganizationBlackListsMetaArgs = {
  where?: Maybe<MessageOrganizationBlackListWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageOrganizationBlackListsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageBatchHistoryRecordsArgs = {
  where?: Maybe<MessageBatchHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageBatchHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageBatchHistoryRecordArgs = {
  where: MessageBatchHistoryRecordWhereUniqueInput;
};


export type Query_AllMessageBatchHistoryRecordsMetaArgs = {
  where?: Maybe<MessageBatchHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageBatchHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageBatchesArgs = {
  where?: Maybe<MessageBatchWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageBatchesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageBatchArgs = {
  where: MessageBatchWhereUniqueInput;
};


export type Query_AllMessageBatchesMetaArgs = {
  where?: Maybe<MessageBatchWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageBatchesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNotificationUserSettingHistoryRecordsArgs = {
  where?: Maybe<NotificationUserSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationUserSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNotificationUserSettingHistoryRecordArgs = {
  where: NotificationUserSettingHistoryRecordWhereUniqueInput;
};


export type Query_AllNotificationUserSettingHistoryRecordsMetaArgs = {
  where?: Maybe<NotificationUserSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationUserSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNotificationUserSettingsArgs = {
  where?: Maybe<NotificationUserSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationUserSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNotificationUserSettingArgs = {
  where: NotificationUserSettingWhereUniqueInput;
};


export type Query_AllNotificationUserSettingsMetaArgs = {
  where?: Maybe<NotificationUserSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationUserSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTelegramUserChatHistoryRecordsArgs = {
  where?: Maybe<TelegramUserChatHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTelegramUserChatHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTelegramUserChatHistoryRecordArgs = {
  where: TelegramUserChatHistoryRecordWhereUniqueInput;
};


export type Query_AllTelegramUserChatHistoryRecordsMetaArgs = {
  where?: Maybe<TelegramUserChatHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTelegramUserChatHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTelegramUserChatsArgs = {
  where?: Maybe<TelegramUserChatWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTelegramUserChatsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTelegramUserChatArgs = {
  where: TelegramUserChatWhereUniqueInput;
};


export type Query_AllTelegramUserChatsMetaArgs = {
  where?: Maybe<TelegramUserChatWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTelegramUserChatsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNotificationAnonymousSettingHistoryRecordsArgs = {
  where?: Maybe<NotificationAnonymousSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationAnonymousSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNotificationAnonymousSettingHistoryRecordArgs = {
  where: NotificationAnonymousSettingHistoryRecordWhereUniqueInput;
};


export type Query_AllNotificationAnonymousSettingHistoryRecordsMetaArgs = {
  where?: Maybe<NotificationAnonymousSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationAnonymousSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNotificationAnonymousSettingsArgs = {
  where?: Maybe<NotificationAnonymousSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationAnonymousSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNotificationAnonymousSettingArgs = {
  where: NotificationAnonymousSettingWhereUniqueInput;
};


export type Query_AllNotificationAnonymousSettingsMetaArgs = {
  where?: Maybe<NotificationAnonymousSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNotificationAnonymousSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllContactHistoryRecordsArgs = {
  where?: Maybe<ContactHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContactHistoryRecordArgs = {
  where: ContactHistoryRecordWhereUniqueInput;
};


export type Query_AllContactHistoryRecordsMetaArgs = {
  where?: Maybe<ContactHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllContactsArgs = {
  where?: Maybe<ContactWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContactArgs = {
  where: ContactWhereUniqueInput;
};


export type Query_AllContactsMetaArgs = {
  where?: Maybe<ContactWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllContactRoleHistoryRecordsArgs = {
  where?: Maybe<ContactRoleHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactRoleHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContactRoleHistoryRecordArgs = {
  where: ContactRoleHistoryRecordWhereUniqueInput;
};


export type Query_AllContactRoleHistoryRecordsMetaArgs = {
  where?: Maybe<ContactRoleHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactRoleHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllContactRolesArgs = {
  where?: Maybe<ContactRoleWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactRolesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContactRoleArgs = {
  where: ContactRoleWhereUniqueInput;
};


export type Query_AllContactRolesMetaArgs = {
  where?: Maybe<ContactRoleWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactRolesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllContactExportTaskHistoryRecordsArgs = {
  where?: Maybe<ContactExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContactExportTaskHistoryRecordArgs = {
  where: ContactExportTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllContactExportTaskHistoryRecordsMetaArgs = {
  where?: Maybe<ContactExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllContactExportTasksArgs = {
  where?: Maybe<ContactExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContactExportTaskArgs = {
  where: ContactExportTaskWhereUniqueInput;
};


export type Query_AllContactExportTasksMetaArgs = {
  where?: Maybe<ContactExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortContactExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllResidentHistoryRecordsArgs = {
  where?: Maybe<ResidentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortResidentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryResidentHistoryRecordArgs = {
  where: ResidentHistoryRecordWhereUniqueInput;
};


export type Query_AllResidentHistoryRecordsMetaArgs = {
  where?: Maybe<ResidentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortResidentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllResidentsArgs = {
  where?: Maybe<ResidentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortResidentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryResidentArgs = {
  where: ResidentWhereUniqueInput;
};


export type Query_AllResidentsMetaArgs = {
  where?: Maybe<ResidentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortResidentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllServiceConsumerHistoryRecordsArgs = {
  where?: Maybe<ServiceConsumerHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceConsumerHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryServiceConsumerHistoryRecordArgs = {
  where: ServiceConsumerHistoryRecordWhereUniqueInput;
};


export type Query_AllServiceConsumerHistoryRecordsMetaArgs = {
  where?: Maybe<ServiceConsumerHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceConsumerHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllServiceConsumersArgs = {
  where?: Maybe<ServiceConsumerWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceConsumersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryServiceConsumerArgs = {
  where: ServiceConsumerWhereUniqueInput;
};


export type Query_AllServiceConsumersMetaArgs = {
  where?: Maybe<ServiceConsumerWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceConsumersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTourStepHistoryRecordsArgs = {
  where?: Maybe<TourStepHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTourStepHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTourStepHistoryRecordArgs = {
  where: TourStepHistoryRecordWhereUniqueInput;
};


export type Query_AllTourStepHistoryRecordsMetaArgs = {
  where?: Maybe<TourStepHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTourStepHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllTourStepsArgs = {
  where?: Maybe<TourStepWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTourStepsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryTourStepArgs = {
  where: TourStepWhereUniqueInput;
};


export type Query_AllTourStepsMetaArgs = {
  where?: Maybe<TourStepWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortTourStepsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserHelpRequestHistoryRecordsArgs = {
  where?: Maybe<UserHelpRequestHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserHelpRequestHistoryRecordArgs = {
  where: UserHelpRequestHistoryRecordWhereUniqueInput;
};


export type Query_AllUserHelpRequestHistoryRecordsMetaArgs = {
  where?: Maybe<UserHelpRequestHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserHelpRequestsArgs = {
  where?: Maybe<UserHelpRequestWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserHelpRequestArgs = {
  where: UserHelpRequestWhereUniqueInput;
};


export type Query_AllUserHelpRequestsMetaArgs = {
  where?: Maybe<UserHelpRequestWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserHelpRequestFileHistoryRecordsArgs = {
  where?: Maybe<UserHelpRequestFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserHelpRequestFileHistoryRecordArgs = {
  where: UserHelpRequestFileHistoryRecordWhereUniqueInput;
};


export type Query_AllUserHelpRequestFileHistoryRecordsMetaArgs = {
  where?: Maybe<UserHelpRequestFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllUserHelpRequestFilesArgs = {
  where?: Maybe<UserHelpRequestFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryUserHelpRequestFileArgs = {
  where: UserHelpRequestFileWhereUniqueInput;
};


export type Query_AllUserHelpRequestFilesMetaArgs = {
  where?: Maybe<UserHelpRequestFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortUserHelpRequestFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterResourceHistoryRecordsArgs = {
  where?: Maybe<MeterResourceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterResourceHistoryRecordArgs = {
  where: MeterResourceHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterResourceHistoryRecordsMetaArgs = {
  where?: Maybe<MeterResourceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterResourcesArgs = {
  where?: Maybe<MeterResourceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourcesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterResourceArgs = {
  where: MeterResourceWhereUniqueInput;
};


export type Query_AllMeterResourcesMetaArgs = {
  where?: Maybe<MeterResourceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourcesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingSourceHistoryRecordsArgs = {
  where?: Maybe<MeterReadingSourceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingSourceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingSourceHistoryRecordArgs = {
  where: MeterReadingSourceHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterReadingSourceHistoryRecordsMetaArgs = {
  where?: Maybe<MeterReadingSourceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingSourceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingSourcesArgs = {
  where?: Maybe<MeterReadingSourceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingSourcesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingSourceArgs = {
  where: MeterReadingSourceWhereUniqueInput;
};


export type Query_AllMeterReadingSourcesMetaArgs = {
  where?: Maybe<MeterReadingSourceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingSourcesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingHistoryRecordsArgs = {
  where?: Maybe<MeterReadingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingHistoryRecordArgs = {
  where: MeterReadingHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterReadingHistoryRecordsMetaArgs = {
  where?: Maybe<MeterReadingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingsArgs = {
  where?: Maybe<MeterReadingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingArgs = {
  where: MeterReadingWhereUniqueInput;
};


export type Query_AllMeterReadingsMetaArgs = {
  where?: Maybe<MeterReadingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterHistoryRecordsArgs = {
  where?: Maybe<MeterHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterHistoryRecordArgs = {
  where: MeterHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterHistoryRecordsMetaArgs = {
  where?: Maybe<MeterHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMetersArgs = {
  where?: Maybe<MeterWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMetersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterArgs = {
  where: MeterWhereUniqueInput;
};


export type Query_AllMetersMetaArgs = {
  where?: Maybe<MeterWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMetersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingFilterTemplateHistoryRecordsArgs = {
  where?: Maybe<MeterReadingFilterTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingFilterTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingFilterTemplateHistoryRecordArgs = {
  where: MeterReadingFilterTemplateHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterReadingFilterTemplateHistoryRecordsMetaArgs = {
  where?: Maybe<MeterReadingFilterTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingFilterTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingFilterTemplatesArgs = {
  where?: Maybe<MeterReadingFilterTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingFilterTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingFilterTemplateArgs = {
  where: MeterReadingFilterTemplateWhereUniqueInput;
};


export type Query_AllMeterReadingFilterTemplatesMetaArgs = {
  where?: Maybe<MeterReadingFilterTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingFilterTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyMeterHistoryRecordsArgs = {
  where?: Maybe<PropertyMeterHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMeterHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyMeterHistoryRecordArgs = {
  where: PropertyMeterHistoryRecordWhereUniqueInput;
};


export type Query_AllPropertyMeterHistoryRecordsMetaArgs = {
  where?: Maybe<PropertyMeterHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMeterHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyMetersArgs = {
  where?: Maybe<PropertyMeterWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMetersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyMeterArgs = {
  where: PropertyMeterWhereUniqueInput;
};


export type Query_AllPropertyMetersMetaArgs = {
  where?: Maybe<PropertyMeterWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMetersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyMeterReadingHistoryRecordsArgs = {
  where?: Maybe<PropertyMeterReadingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMeterReadingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyMeterReadingHistoryRecordArgs = {
  where: PropertyMeterReadingHistoryRecordWhereUniqueInput;
};


export type Query_AllPropertyMeterReadingHistoryRecordsMetaArgs = {
  where?: Maybe<PropertyMeterReadingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMeterReadingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyMeterReadingsArgs = {
  where?: Maybe<PropertyMeterReadingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMeterReadingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyMeterReadingArgs = {
  where: PropertyMeterReadingWhereUniqueInput;
};


export type Query_AllPropertyMeterReadingsMetaArgs = {
  where?: Maybe<PropertyMeterReadingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyMeterReadingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReportingPeriodHistoryRecordsArgs = {
  where?: Maybe<MeterReportingPeriodHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReportingPeriodHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReportingPeriodHistoryRecordArgs = {
  where: MeterReportingPeriodHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterReportingPeriodHistoryRecordsMetaArgs = {
  where?: Maybe<MeterReportingPeriodHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReportingPeriodHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReportingPeriodsArgs = {
  where?: Maybe<MeterReportingPeriodWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReportingPeriodsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReportingPeriodArgs = {
  where: MeterReportingPeriodWhereUniqueInput;
};


export type Query_AllMeterReportingPeriodsMetaArgs = {
  where?: Maybe<MeterReportingPeriodWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReportingPeriodsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterResourceOwnerHistoryRecordsArgs = {
  where?: Maybe<MeterResourceOwnerHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourceOwnerHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterResourceOwnerHistoryRecordArgs = {
  where: MeterResourceOwnerHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterResourceOwnerHistoryRecordsMetaArgs = {
  where?: Maybe<MeterResourceOwnerHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourceOwnerHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterResourceOwnersArgs = {
  where?: Maybe<MeterResourceOwnerWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourceOwnersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterResourceOwnerArgs = {
  where: MeterResourceOwnerWhereUniqueInput;
};


export type Query_AllMeterResourceOwnersMetaArgs = {
  where?: Maybe<MeterResourceOwnerWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterResourceOwnersBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingsImportTaskHistoryRecordsArgs = {
  where?: Maybe<MeterReadingsImportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingsImportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingsImportTaskHistoryRecordArgs = {
  where: MeterReadingsImportTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllMeterReadingsImportTaskHistoryRecordsMetaArgs = {
  where?: Maybe<MeterReadingsImportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingsImportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMeterReadingsImportTasksArgs = {
  where?: Maybe<MeterReadingsImportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingsImportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMeterReadingsImportTaskArgs = {
  where: MeterReadingsImportTaskWhereUniqueInput;
};


export type Query_AllMeterReadingsImportTasksMetaArgs = {
  where?: Maybe<MeterReadingsImportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMeterReadingsImportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllServiceSubscriptionHistoryRecordsArgs = {
  where?: Maybe<ServiceSubscriptionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceSubscriptionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryServiceSubscriptionHistoryRecordArgs = {
  where: ServiceSubscriptionHistoryRecordWhereUniqueInput;
};


export type Query_AllServiceSubscriptionHistoryRecordsMetaArgs = {
  where?: Maybe<ServiceSubscriptionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceSubscriptionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllServiceSubscriptionsArgs = {
  where?: Maybe<ServiceSubscriptionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceSubscriptionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryServiceSubscriptionArgs = {
  where: ServiceSubscriptionWhereUniqueInput;
};


export type Query_AllServiceSubscriptionsMetaArgs = {
  where?: Maybe<ServiceSubscriptionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortServiceSubscriptionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllAcquiringIntegrationHistoryRecordsArgs = {
  where?: Maybe<AcquiringIntegrationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAcquiringIntegrationHistoryRecordArgs = {
  where: AcquiringIntegrationHistoryRecordWhereUniqueInput;
};


export type Query_AllAcquiringIntegrationHistoryRecordsMetaArgs = {
  where?: Maybe<AcquiringIntegrationHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllAcquiringIntegrationsArgs = {
  where?: Maybe<AcquiringIntegrationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAcquiringIntegrationArgs = {
  where: AcquiringIntegrationWhereUniqueInput;
};


export type Query_AllAcquiringIntegrationsMetaArgs = {
  where?: Maybe<AcquiringIntegrationWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllAcquiringIntegrationAccessRightHistoryRecordsArgs = {
  where?: Maybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAcquiringIntegrationAccessRightHistoryRecordArgs = {
  where: AcquiringIntegrationAccessRightHistoryRecordWhereUniqueInput;
};


export type Query_AllAcquiringIntegrationAccessRightHistoryRecordsMetaArgs = {
  where?: Maybe<AcquiringIntegrationAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllAcquiringIntegrationAccessRightsArgs = {
  where?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAcquiringIntegrationAccessRightArgs = {
  where: AcquiringIntegrationAccessRightWhereUniqueInput;
};


export type Query_AllAcquiringIntegrationAccessRightsMetaArgs = {
  where?: Maybe<AcquiringIntegrationAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllAcquiringIntegrationContextHistoryRecordsArgs = {
  where?: Maybe<AcquiringIntegrationContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAcquiringIntegrationContextHistoryRecordArgs = {
  where: AcquiringIntegrationContextHistoryRecordWhereUniqueInput;
};


export type Query_AllAcquiringIntegrationContextHistoryRecordsMetaArgs = {
  where?: Maybe<AcquiringIntegrationContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllAcquiringIntegrationContextsArgs = {
  where?: Maybe<AcquiringIntegrationContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAcquiringIntegrationContextArgs = {
  where: AcquiringIntegrationContextWhereUniqueInput;
};


export type Query_AllAcquiringIntegrationContextsMetaArgs = {
  where?: Maybe<AcquiringIntegrationContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortAcquiringIntegrationContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMultiPaymentHistoryRecordsArgs = {
  where?: Maybe<MultiPaymentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMultiPaymentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMultiPaymentHistoryRecordArgs = {
  where: MultiPaymentHistoryRecordWhereUniqueInput;
};


export type Query_AllMultiPaymentHistoryRecordsMetaArgs = {
  where?: Maybe<MultiPaymentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMultiPaymentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMultiPaymentsArgs = {
  where?: Maybe<MultiPaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMultiPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMultiPaymentArgs = {
  where: MultiPaymentWhereUniqueInput;
};


export type Query_AllMultiPaymentsMetaArgs = {
  where?: Maybe<MultiPaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMultiPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPaymentHistoryRecordsArgs = {
  where?: Maybe<PaymentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPaymentHistoryRecordArgs = {
  where: PaymentHistoryRecordWhereUniqueInput;
};


export type Query_AllPaymentHistoryRecordsMetaArgs = {
  where?: Maybe<PaymentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPaymentArgs = {
  where: PaymentWhereUniqueInput;
};


export type Query_AllPaymentsMetaArgs = {
  where?: Maybe<PaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPaymentsFilterTemplateHistoryRecordsArgs = {
  where?: Maybe<PaymentsFilterTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsFilterTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPaymentsFilterTemplateHistoryRecordArgs = {
  where: PaymentsFilterTemplateHistoryRecordWhereUniqueInput;
};


export type Query_AllPaymentsFilterTemplateHistoryRecordsMetaArgs = {
  where?: Maybe<PaymentsFilterTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsFilterTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPaymentsFilterTemplatesArgs = {
  where?: Maybe<PaymentsFilterTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsFilterTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPaymentsFilterTemplateArgs = {
  where: PaymentsFilterTemplateWhereUniqueInput;
};


export type Query_AllPaymentsFilterTemplatesMetaArgs = {
  where?: Maybe<PaymentsFilterTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPaymentsFilterTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllRecurrentPaymentContextHistoryRecordsArgs = {
  where?: Maybe<RecurrentPaymentContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRecurrentPaymentContextHistoryRecordArgs = {
  where: RecurrentPaymentContextHistoryRecordWhereUniqueInput;
};


export type Query_AllRecurrentPaymentContextHistoryRecordsMetaArgs = {
  where?: Maybe<RecurrentPaymentContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllRecurrentPaymentContextsArgs = {
  where?: Maybe<RecurrentPaymentContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRecurrentPaymentContextArgs = {
  where: RecurrentPaymentContextWhereUniqueInput;
};


export type Query_AllRecurrentPaymentContextsMetaArgs = {
  where?: Maybe<RecurrentPaymentContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllRecurrentPaymentHistoryRecordsArgs = {
  where?: Maybe<RecurrentPaymentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRecurrentPaymentHistoryRecordArgs = {
  where: RecurrentPaymentHistoryRecordWhereUniqueInput;
};


export type Query_AllRecurrentPaymentHistoryRecordsMetaArgs = {
  where?: Maybe<RecurrentPaymentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllRecurrentPaymentsArgs = {
  where?: Maybe<RecurrentPaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRecurrentPaymentArgs = {
  where: RecurrentPaymentWhereUniqueInput;
};


export type Query_AllRecurrentPaymentsMetaArgs = {
  where?: Maybe<RecurrentPaymentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortRecurrentPaymentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllExternalReportHistoryRecordsArgs = {
  where?: Maybe<ExternalReportHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalReportHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryExternalReportHistoryRecordArgs = {
  where: ExternalReportHistoryRecordWhereUniqueInput;
};


export type Query_AllExternalReportHistoryRecordsMetaArgs = {
  where?: Maybe<ExternalReportHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalReportHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllExternalReportsArgs = {
  where?: Maybe<ExternalReportWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalReportsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryExternalReportArgs = {
  where: ExternalReportWhereUniqueInput;
};


export type Query_AllExternalReportsMetaArgs = {
  where?: Maybe<ExternalReportWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortExternalReportsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyScopeHistoryRecordsArgs = {
  where?: Maybe<PropertyScopeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyScopeHistoryRecordArgs = {
  where: PropertyScopeHistoryRecordWhereUniqueInput;
};


export type Query_AllPropertyScopeHistoryRecordsMetaArgs = {
  where?: Maybe<PropertyScopeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyScopesArgs = {
  where?: Maybe<PropertyScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyScopeArgs = {
  where: PropertyScopeWhereUniqueInput;
};


export type Query_AllPropertyScopesMetaArgs = {
  where?: Maybe<PropertyScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyScopeOrganizationEmployeeHistoryRecordsArgs = {
  where?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopeOrganizationEmployeeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyScopeOrganizationEmployeeHistoryRecordArgs = {
  where: PropertyScopeOrganizationEmployeeHistoryRecordWhereUniqueInput;
};


export type Query_AllPropertyScopeOrganizationEmployeeHistoryRecordsMetaArgs = {
  where?: Maybe<PropertyScopeOrganizationEmployeeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopeOrganizationEmployeeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyScopeOrganizationEmployeesArgs = {
  where?: Maybe<PropertyScopeOrganizationEmployeeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopeOrganizationEmployeesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyScopeOrganizationEmployeeArgs = {
  where: PropertyScopeOrganizationEmployeeWhereUniqueInput;
};


export type Query_AllPropertyScopeOrganizationEmployeesMetaArgs = {
  where?: Maybe<PropertyScopeOrganizationEmployeeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopeOrganizationEmployeesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyScopePropertyHistoryRecordsArgs = {
  where?: Maybe<PropertyScopePropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopePropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyScopePropertyHistoryRecordArgs = {
  where: PropertyScopePropertyHistoryRecordWhereUniqueInput;
};


export type Query_AllPropertyScopePropertyHistoryRecordsMetaArgs = {
  where?: Maybe<PropertyScopePropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopePropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllPropertyScopePropertiesArgs = {
  where?: Maybe<PropertyScopePropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopePropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPropertyScopePropertyArgs = {
  where: PropertyScopePropertyWhereUniqueInput;
};


export type Query_AllPropertyScopePropertiesMetaArgs = {
  where?: Maybe<PropertyScopePropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortPropertyScopePropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemHistoryRecordsArgs = {
  where?: Maybe<NewsItemHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemHistoryRecordArgs = {
  where: NewsItemHistoryRecordWhereUniqueInput;
};


export type Query_AllNewsItemHistoryRecordsMetaArgs = {
  where?: Maybe<NewsItemHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemsArgs = {
  where?: Maybe<NewsItemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemArgs = {
  where: NewsItemWhereUniqueInput;
};


export type Query_AllNewsItemsMetaArgs = {
  where?: Maybe<NewsItemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemScopeHistoryRecordsArgs = {
  where?: Maybe<NewsItemScopeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemScopeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemScopeHistoryRecordArgs = {
  where: NewsItemScopeHistoryRecordWhereUniqueInput;
};


export type Query_AllNewsItemScopeHistoryRecordsMetaArgs = {
  where?: Maybe<NewsItemScopeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemScopeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemScopesArgs = {
  where?: Maybe<NewsItemScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemScopeArgs = {
  where: NewsItemScopeWhereUniqueInput;
};


export type Query_AllNewsItemScopesMetaArgs = {
  where?: Maybe<NewsItemScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemTemplateHistoryRecordsArgs = {
  where?: Maybe<NewsItemTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemTemplateHistoryRecordArgs = {
  where: NewsItemTemplateHistoryRecordWhereUniqueInput;
};


export type Query_AllNewsItemTemplateHistoryRecordsMetaArgs = {
  where?: Maybe<NewsItemTemplateHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemTemplateHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemTemplatesArgs = {
  where?: Maybe<NewsItemTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemTemplateArgs = {
  where: NewsItemTemplateWhereUniqueInput;
};


export type Query_AllNewsItemTemplatesMetaArgs = {
  where?: Maybe<NewsItemTemplateWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemTemplatesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemUserReadHistoryRecordsArgs = {
  where?: Maybe<NewsItemUserReadHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemUserReadHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemUserReadHistoryRecordArgs = {
  where: NewsItemUserReadHistoryRecordWhereUniqueInput;
};


export type Query_AllNewsItemUserReadHistoryRecordsMetaArgs = {
  where?: Maybe<NewsItemUserReadHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemUserReadHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemUserReadsArgs = {
  where?: Maybe<NewsItemUserReadWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemUserReadsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemUserReadArgs = {
  where: NewsItemUserReadWhereUniqueInput;
};


export type Query_AllNewsItemUserReadsMetaArgs = {
  where?: Maybe<NewsItemUserReadWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemUserReadsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemRecipientsExportTaskHistoryRecordsArgs = {
  where?: Maybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemRecipientsExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemRecipientsExportTaskHistoryRecordArgs = {
  where: NewsItemRecipientsExportTaskHistoryRecordWhereUniqueInput;
};


export type Query_AllNewsItemRecipientsExportTaskHistoryRecordsMetaArgs = {
  where?: Maybe<NewsItemRecipientsExportTaskHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemRecipientsExportTaskHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemRecipientsExportTasksArgs = {
  where?: Maybe<NewsItemRecipientsExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemRecipientsExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemRecipientsExportTaskArgs = {
  where: NewsItemRecipientsExportTaskWhereUniqueInput;
};


export type Query_AllNewsItemRecipientsExportTasksMetaArgs = {
  where?: Maybe<NewsItemRecipientsExportTaskWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemRecipientsExportTasksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemSharingHistoryRecordsArgs = {
  where?: Maybe<NewsItemSharingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemSharingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemSharingHistoryRecordArgs = {
  where: NewsItemSharingHistoryRecordWhereUniqueInput;
};


export type Query_AllNewsItemSharingHistoryRecordsMetaArgs = {
  where?: Maybe<NewsItemSharingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemSharingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllNewsItemSharingsArgs = {
  where?: Maybe<NewsItemSharingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemSharingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryNewsItemSharingArgs = {
  where: NewsItemSharingWhereUniqueInput;
};


export type Query_AllNewsItemSharingsMetaArgs = {
  where?: Maybe<NewsItemSharingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortNewsItemSharingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppHistoryRecordsArgs = {
  where?: Maybe<B2BAppHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppHistoryRecordArgs = {
  where: B2BAppHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppsArgs = {
  where?: Maybe<B2BAppWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppArgs = {
  where: B2BAppWhereUniqueInput;
};


export type Query_AllB2BAppsMetaArgs = {
  where?: Maybe<B2BAppWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppContextHistoryRecordsArgs = {
  where?: Maybe<B2BAppContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppContextHistoryRecordArgs = {
  where: B2BAppContextHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppContextHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppContextHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppContextHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppContextsArgs = {
  where?: Maybe<B2BAppContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppContextArgs = {
  where: B2BAppContextWhereUniqueInput;
};


export type Query_AllB2BAppContextsMetaArgs = {
  where?: Maybe<B2BAppContextWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppContextsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppAccessRightHistoryRecordsArgs = {
  where?: Maybe<B2BAppAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppAccessRightHistoryRecordArgs = {
  where: B2BAppAccessRightHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppAccessRightHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppAccessRightsArgs = {
  where?: Maybe<B2BAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppAccessRightArgs = {
  where: B2BAppAccessRightWhereUniqueInput;
};


export type Query_AllB2BAppAccessRightsMetaArgs = {
  where?: Maybe<B2BAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppHistoryRecordsArgs = {
  where?: Maybe<B2CAppHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppHistoryRecordArgs = {
  where: B2CAppHistoryRecordWhereUniqueInput;
};


export type Query_AllB2CAppHistoryRecordsMetaArgs = {
  where?: Maybe<B2CAppHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppsArgs = {
  where?: Maybe<B2CAppWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppArgs = {
  where: B2CAppWhereUniqueInput;
};


export type Query_AllB2CAppsMetaArgs = {
  where?: Maybe<B2CAppWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppAccessRightHistoryRecordsArgs = {
  where?: Maybe<B2CAppAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppAccessRightHistoryRecordArgs = {
  where: B2CAppAccessRightHistoryRecordWhereUniqueInput;
};


export type Query_AllB2CAppAccessRightHistoryRecordsMetaArgs = {
  where?: Maybe<B2CAppAccessRightHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppAccessRightHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppAccessRightsArgs = {
  where?: Maybe<B2CAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppAccessRightArgs = {
  where: B2CAppAccessRightWhereUniqueInput;
};


export type Query_AllB2CAppAccessRightsMetaArgs = {
  where?: Maybe<B2CAppAccessRightWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppAccessRightsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppBuildHistoryRecordsArgs = {
  where?: Maybe<B2CAppBuildHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppBuildHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppBuildHistoryRecordArgs = {
  where: B2CAppBuildHistoryRecordWhereUniqueInput;
};


export type Query_AllB2CAppBuildHistoryRecordsMetaArgs = {
  where?: Maybe<B2CAppBuildHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppBuildHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppBuildsArgs = {
  where?: Maybe<B2CAppBuildWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppBuildsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppBuildArgs = {
  where: B2CAppBuildWhereUniqueInput;
};


export type Query_AllB2CAppBuildsMetaArgs = {
  where?: Maybe<B2CAppBuildWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppBuildsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppPropertyHistoryRecordsArgs = {
  where?: Maybe<B2CAppPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppPropertyHistoryRecordArgs = {
  where: B2CAppPropertyHistoryRecordWhereUniqueInput;
};


export type Query_AllB2CAppPropertyHistoryRecordsMetaArgs = {
  where?: Maybe<B2CAppPropertyHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppPropertyHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppPropertiesArgs = {
  where?: Maybe<B2CAppPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppPropertyArgs = {
  where: B2CAppPropertyWhereUniqueInput;
};


export type Query_AllB2CAppPropertiesMetaArgs = {
  where?: Maybe<B2CAppPropertyWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppPropertiesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppPromoBlockHistoryRecordsArgs = {
  where?: Maybe<B2BAppPromoBlockHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPromoBlockHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppPromoBlockHistoryRecordArgs = {
  where: B2BAppPromoBlockHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppPromoBlockHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppPromoBlockHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPromoBlockHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppPromoBlocksArgs = {
  where?: Maybe<B2BAppPromoBlockWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPromoBlocksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppPromoBlockArgs = {
  where: B2BAppPromoBlockWhereUniqueInput;
};


export type Query_AllB2BAppPromoBlocksMetaArgs = {
  where?: Maybe<B2BAppPromoBlockWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPromoBlocksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageAppBlackListHistoryRecordsArgs = {
  where?: Maybe<MessageAppBlackListHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageAppBlackListHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageAppBlackListHistoryRecordArgs = {
  where: MessageAppBlackListHistoryRecordWhereUniqueInput;
};


export type Query_AllMessageAppBlackListHistoryRecordsMetaArgs = {
  where?: Maybe<MessageAppBlackListHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageAppBlackListHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMessageAppBlackListsArgs = {
  where?: Maybe<MessageAppBlackListWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageAppBlackListsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMessageAppBlackListArgs = {
  where: MessageAppBlackListWhereUniqueInput;
};


export type Query_AllMessageAppBlackListsMetaArgs = {
  where?: Maybe<MessageAppBlackListWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMessageAppBlackListsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppPermissionHistoryRecordsArgs = {
  where?: Maybe<B2BAppPermissionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPermissionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppPermissionHistoryRecordArgs = {
  where: B2BAppPermissionHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppPermissionHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppPermissionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPermissionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppPermissionsArgs = {
  where?: Maybe<B2BAppPermissionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPermissionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppPermissionArgs = {
  where: B2BAppPermissionWhereUniqueInput;
};


export type Query_AllB2BAppPermissionsMetaArgs = {
  where?: Maybe<B2BAppPermissionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppPermissionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppRoleHistoryRecordsArgs = {
  where?: Maybe<B2BAppRoleHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppRoleHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppRoleHistoryRecordArgs = {
  where: B2BAppRoleHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppRoleHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppRoleHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppRoleHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppRolesArgs = {
  where?: Maybe<B2BAppRoleWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppRolesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppRoleArgs = {
  where: B2BAppRoleWhereUniqueInput;
};


export type Query_AllB2BAppRolesMetaArgs = {
  where?: Maybe<B2BAppRoleWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppRolesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppAccessRightSetHistoryRecordsArgs = {
  where?: Maybe<B2BAppAccessRightSetHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightSetHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppAccessRightSetHistoryRecordArgs = {
  where: B2BAppAccessRightSetHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppAccessRightSetHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppAccessRightSetHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightSetHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppAccessRightSetsArgs = {
  where?: Maybe<B2BAppAccessRightSetWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightSetsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppAccessRightSetArgs = {
  where: B2BAppAccessRightSetWhereUniqueInput;
};


export type Query_AllB2BAppAccessRightSetsMetaArgs = {
  where?: Maybe<B2BAppAccessRightSetWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppAccessRightSetsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppNewsSharingConfigHistoryRecordsArgs = {
  where?: Maybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppNewsSharingConfigHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppNewsSharingConfigHistoryRecordArgs = {
  where: B2BAppNewsSharingConfigHistoryRecordWhereUniqueInput;
};


export type Query_AllB2BAppNewsSharingConfigHistoryRecordsMetaArgs = {
  where?: Maybe<B2BAppNewsSharingConfigHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppNewsSharingConfigHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2BAppNewsSharingConfigsArgs = {
  where?: Maybe<B2BAppNewsSharingConfigWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppNewsSharingConfigsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2BAppNewsSharingConfigArgs = {
  where: B2BAppNewsSharingConfigWhereUniqueInput;
};


export type Query_AllB2BAppNewsSharingConfigsMetaArgs = {
  where?: Maybe<B2BAppNewsSharingConfigWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2BAppNewsSharingConfigsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppMessageSettingHistoryRecordsArgs = {
  where?: Maybe<B2CAppMessageSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppMessageSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppMessageSettingHistoryRecordArgs = {
  where: B2CAppMessageSettingHistoryRecordWhereUniqueInput;
};


export type Query_AllB2CAppMessageSettingHistoryRecordsMetaArgs = {
  where?: Maybe<B2CAppMessageSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppMessageSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllB2CAppMessageSettingsArgs = {
  where?: Maybe<B2CAppMessageSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppMessageSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryB2CAppMessageSettingArgs = {
  where: B2CAppMessageSettingWhereUniqueInput;
};


export type Query_AllB2CAppMessageSettingsMetaArgs = {
  where?: Maybe<B2CAppMessageSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortB2CAppMessageSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMobileFeatureConfigHistoryRecordsArgs = {
  where?: Maybe<MobileFeatureConfigHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMobileFeatureConfigHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMobileFeatureConfigHistoryRecordArgs = {
  where: MobileFeatureConfigHistoryRecordWhereUniqueInput;
};


export type Query_AllMobileFeatureConfigHistoryRecordsMetaArgs = {
  where?: Maybe<MobileFeatureConfigHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMobileFeatureConfigHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMobileFeatureConfigsArgs = {
  where?: Maybe<MobileFeatureConfigWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMobileFeatureConfigsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMobileFeatureConfigArgs = {
  where: MobileFeatureConfigWhereUniqueInput;
};


export type Query_AllMobileFeatureConfigsMetaArgs = {
  where?: Maybe<MobileFeatureConfigWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMobileFeatureConfigsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketCategoryHistoryRecordsArgs = {
  where?: Maybe<MarketCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketCategoryHistoryRecordArgs = {
  where: MarketCategoryHistoryRecordWhereUniqueInput;
};


export type Query_AllMarketCategoryHistoryRecordsMetaArgs = {
  where?: Maybe<MarketCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketCategoriesArgs = {
  where?: Maybe<MarketCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketCategoryArgs = {
  where: MarketCategoryWhereUniqueInput;
};


export type Query_AllMarketCategoriesMetaArgs = {
  where?: Maybe<MarketCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketItemHistoryRecordsArgs = {
  where?: Maybe<MarketItemHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketItemHistoryRecordArgs = {
  where: MarketItemHistoryRecordWhereUniqueInput;
};


export type Query_AllMarketItemHistoryRecordsMetaArgs = {
  where?: Maybe<MarketItemHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketItemsArgs = {
  where?: Maybe<MarketItemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketItemArgs = {
  where: MarketItemWhereUniqueInput;
};


export type Query_AllMarketItemsMetaArgs = {
  where?: Maybe<MarketItemWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllInvoiceHistoryRecordsArgs = {
  where?: Maybe<InvoiceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortInvoiceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceHistoryRecordArgs = {
  where: InvoiceHistoryRecordWhereUniqueInput;
};


export type Query_AllInvoiceHistoryRecordsMetaArgs = {
  where?: Maybe<InvoiceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortInvoiceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllInvoicesArgs = {
  where?: Maybe<InvoiceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortInvoicesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type Query_AllInvoicesMetaArgs = {
  where?: Maybe<InvoiceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortInvoicesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketItemFileHistoryRecordsArgs = {
  where?: Maybe<MarketItemFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketItemFileHistoryRecordArgs = {
  where: MarketItemFileHistoryRecordWhereUniqueInput;
};


export type Query_AllMarketItemFileHistoryRecordsMetaArgs = {
  where?: Maybe<MarketItemFileHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemFileHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketItemFilesArgs = {
  where?: Maybe<MarketItemFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketItemFileArgs = {
  where: MarketItemFileWhereUniqueInput;
};


export type Query_AllMarketItemFilesMetaArgs = {
  where?: Maybe<MarketItemFileWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemFilesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketItemPriceHistoryRecordsArgs = {
  where?: Maybe<MarketItemPriceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemPriceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketItemPriceHistoryRecordArgs = {
  where: MarketItemPriceHistoryRecordWhereUniqueInput;
};


export type Query_AllMarketItemPriceHistoryRecordsMetaArgs = {
  where?: Maybe<MarketItemPriceHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemPriceHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketItemPricesArgs = {
  where?: Maybe<MarketItemPriceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemPricesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketItemPriceArgs = {
  where: MarketItemPriceWhereUniqueInput;
};


export type Query_AllMarketItemPricesMetaArgs = {
  where?: Maybe<MarketItemPriceWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketItemPricesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketPriceScopeHistoryRecordsArgs = {
  where?: Maybe<MarketPriceScopeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketPriceScopeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketPriceScopeHistoryRecordArgs = {
  where: MarketPriceScopeHistoryRecordWhereUniqueInput;
};


export type Query_AllMarketPriceScopeHistoryRecordsMetaArgs = {
  where?: Maybe<MarketPriceScopeHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketPriceScopeHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketPriceScopesArgs = {
  where?: Maybe<MarketPriceScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketPriceScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketPriceScopeArgs = {
  where: MarketPriceScopeWhereUniqueInput;
};


export type Query_AllMarketPriceScopesMetaArgs = {
  where?: Maybe<MarketPriceScopeWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketPriceScopesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketSettingHistoryRecordsArgs = {
  where?: Maybe<MarketSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketSettingHistoryRecordArgs = {
  where: MarketSettingHistoryRecordWhereUniqueInput;
};


export type Query_AllMarketSettingHistoryRecordsMetaArgs = {
  where?: Maybe<MarketSettingHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketSettingHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllMarketSettingsArgs = {
  where?: Maybe<MarketSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMarketSettingArgs = {
  where: MarketSettingWhereUniqueInput;
};


export type Query_AllMarketSettingsMetaArgs = {
  where?: Maybe<MarketSettingWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortMarketSettingsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllDocumentCategoryHistoryRecordsArgs = {
  where?: Maybe<DocumentCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDocumentCategoryHistoryRecordArgs = {
  where: DocumentCategoryHistoryRecordWhereUniqueInput;
};


export type Query_AllDocumentCategoryHistoryRecordsMetaArgs = {
  where?: Maybe<DocumentCategoryHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentCategoryHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllDocumentCategoriesArgs = {
  where?: Maybe<DocumentCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDocumentCategoryArgs = {
  where: DocumentCategoryWhereUniqueInput;
};


export type Query_AllDocumentCategoriesMetaArgs = {
  where?: Maybe<DocumentCategoryWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentCategoriesBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllDocumentHistoryRecordsArgs = {
  where?: Maybe<DocumentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDocumentHistoryRecordArgs = {
  where: DocumentHistoryRecordWhereUniqueInput;
};


export type Query_AllDocumentHistoryRecordsMetaArgs = {
  where?: Maybe<DocumentHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllDocumentsArgs = {
  where?: Maybe<DocumentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type Query_AllDocumentsMetaArgs = {
  where?: Maybe<DocumentWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortDocumentsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllWebhookHistoryRecordsArgs = {
  where?: Maybe<WebhookHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhookHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryWebhookHistoryRecordArgs = {
  where: WebhookHistoryRecordWhereUniqueInput;
};


export type Query_AllWebhookHistoryRecordsMetaArgs = {
  where?: Maybe<WebhookHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhookHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllWebhooksArgs = {
  where?: Maybe<WebhookWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhooksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryWebhookArgs = {
  where: WebhookWhereUniqueInput;
};


export type Query_AllWebhooksMetaArgs = {
  where?: Maybe<WebhookWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhooksBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllWebhookSubscriptionHistoryRecordsArgs = {
  where?: Maybe<WebhookSubscriptionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhookSubscriptionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryWebhookSubscriptionHistoryRecordArgs = {
  where: WebhookSubscriptionHistoryRecordWhereUniqueInput;
};


export type Query_AllWebhookSubscriptionHistoryRecordsMetaArgs = {
  where?: Maybe<WebhookSubscriptionHistoryRecordWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhookSubscriptionHistoryRecordsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryAllWebhookSubscriptionsArgs = {
  where?: Maybe<WebhookSubscriptionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhookSubscriptionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryWebhookSubscriptionArgs = {
  where: WebhookSubscriptionWhereUniqueInput;
};


export type Query_AllWebhookSubscriptionsMetaArgs = {
  where?: Maybe<WebhookSubscriptionWhereInput>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Array<SortWebhookSubscriptionsBy>>;
  orderBy?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type Query_KsListsMetaArgs = {
  where?: Maybe<_KsListsMetaInput>;
};


export type QueryGetPhoneByConfirmPhoneActionTokenArgs = {
  data: GetPhoneByConfirmPhoneActionTokenInput;
};


export type QueryGetAccessTokenByUserIdArgs = {
  data: GetAccessTokenByUserIdInput;
};


export type QueryCheckUserExistenceArgs = {
  data: CheckUserExistenceInput;
};


export type QueryExportPropertiesToExcelArgs = {
  data: ExportPropertiesToExcelInput;
};


export type QueryAllResidentBillingReceiptsArgs = {
  where?: Maybe<ResidentBillingReceiptWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Array<SortResidentBillingReceiptsBy>>;
};


export type Query_AllBillingReceiptsSumArgs = {
  data: BillingReceiptsSumInput;
};


export type QueryAllResidentBillingVirtualReceiptsArgs = {
  where?: Maybe<ResidentBillingVirtualReceiptWhereInput>;
  first?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Array<SortResidentBillingVirtualReceiptsBy>>;
};


export type QueryPredictTransactionClassificationArgs = {
  data: PredictTransactionClassificationInput;
};


export type QueryPredictTicketClassificationArgs = {
  data: PredictTicketClassificationInput;
};


export type QueryGetResidentExistenceByPhoneAndAddressArgs = {
  data: GetResidentExistenceByPhoneAndAddressInput;
};


export type QueryFindOrganizationsForAddressArgs = {
  data: FindOrganizationsForAddressInput;
};


export type QuerySuggestServiceProviderArgs = {
  data: SuggestServiceProviderInput;
};


export type QueryExportMeterReadingsArgs = {
  data: ExportMeterReadingsInput;
};


export type QueryExportPropertyMeterReadingsArgs = {
  data: ExportPropertyMeterReadingsInput;
};


export type QueryExportPaymentsToExcelArgs = {
  data: ExportPaymentsToExcelInput;
};


export type QueryGeneratePaymentLinkArgs = {
  data: GeneratePaymentLinkInput;
};


export type Query_AllPaymentsSumArgs = {
  where: PaymentWhereInput;
};


export type QueryCalculateFeeForReceiptArgs = {
  data: CalculateFeeForReceiptInput;
};


export type QueryTicketReportWidgetDataArgs = {
  data: TicketReportWidgetInput;
};


export type QueryTicketAnalyticsReportArgs = {
  data?: Maybe<TicketAnalyticsReportInput>;
};


export type QueryExportTicketAnalyticsToExcelArgs = {
  data?: Maybe<ExportTicketAnalyticsToExcelInput>;
};


export type QueryGetExternalReportIframeUrlArgs = {
  data: GetExternalReportIframeUrlInput;
};


export type QueryGetOverviewDashboardArgs = {
  data: GetOverviewDashboardInput;
};


export type QueryExportPropertyScopesToExcelArgs = {
  data: ExportPropertyScopeToExcelInput;
};


export type QueryGetNewsItemsRecipientsCountersArgs = {
  data: GetNewsItemsRecipientsCountersInput;
};


export type QueryGetNewsSharingRecipientsArgs = {
  data: GetNewsSharingRecipientsInput;
};


export type QueryGetNewsSharingRecipientsCountersArgs = {
  data: GetNewsSharingRecipientsCountersInput;
};


export type QueryAllMiniAppsArgs = {
  data: AllMiniAppsInput;
};

export type ReInviteOrganizationEmployeeInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
  email?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type ReceiptGroupedCounter = {
  __typename?: 'ReceiptGroupedCounter';
  count: Scalars['String'];
  sum: Scalars['String'];
  dayGroup: Scalars['String'];
};

export type ReceiptOverviewResult = {
  __typename?: 'ReceiptOverviewResult';
  receipts?: Maybe<Array<ReceiptGroupedCounter>>;
  sum: Scalars['String'];
};

export type RecipientField = {
  __typename?: 'RecipientField';
  name?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  tin: Scalars['String'];
  iec?: Maybe<Scalars['String']>;
  bic: Scalars['String'];
  bankAccount: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
};

export type RecipientFieldInput = {
  name?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  territoryCode?: Maybe<Scalars['String']>;
  offsettingAccount?: Maybe<Scalars['String']>;
  tin: Scalars['String'];
  iec?: Maybe<Scalars['String']>;
  bic: Scalars['String'];
  bankAccount: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
};

/**  Hold a snapshot of monthly payment data. Used by background recurrent payment process.  */
export type RecurrentPayment = {
  __typename?: 'RecurrentPayment';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPayment List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPayment List config, or
   *  3. As an alias to a 'name' field on the RecurrentPayment List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPayment List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Payment proceeding status  */
  status?: Maybe<RecurrentPaymentStatusType>;
  /**  Payment proceeding should start after date.  */
  payAfter?: Maybe<Scalars['String']>;
  /**  Count of proceeding tries.  */
  tryCount?: Maybe<Scalars['Int']>;
  /**  Hold current state of payment proceeding.  */
  state?: Maybe<Scalars['JSON']>;
  /**  Contains information about the distribution of a certain type of fee  */
  billingReceipts?: Maybe<Array<BillingReceiptsField>>;
  /**  Link to RecurrentPaymentContext  */
  recurrentPaymentContext?: Maybe<RecurrentPaymentContext>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

/**  Recurrent payments context configuration. Holding settings, limits and etc, configured by resident for background process that proceeding resident`s receipts on monthly basis.  */
export type RecurrentPaymentContext = {
  __typename?: 'RecurrentPaymentContext';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPaymentContext List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPaymentContext List config, or
   *  3. As an alias to a 'name' field on the RecurrentPaymentContext List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPaymentContext List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Enable or Disable recurrent payment proceeding.  */
  enabled?: Maybe<Scalars['Boolean']>;
  /**  Maximal `toPay` amount of multi payment that can be proceeded. Otherwise payment should be interrupted.  */
  limit?: Maybe<Scalars['String']>;
  /**  Pay for billing receipts right after they was created. Only one trigger should be configured: autoPayReceipts or paymentDay.  */
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  /**  The day of month when resident`s receipts going to be proceeded. Only one trigger should be configured: autoPayReceipts or paymentDay.  */
  paymentDay?: Maybe<Scalars['Int']>;
  /**  Settings. Should contain recurrent payment context configuration.  */
  settings?: Maybe<SettingsField>;
  /**  Link to ServiceConsumer  */
  serviceConsumer?: Maybe<ServiceConsumer>;
  /**  Link to BillingCategory  */
  billingCategory?: Maybe<BillingCategory>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type RecurrentPaymentContextCreateInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  settings?: Maybe<SettingsFieldInput>;
  serviceConsumer?: Maybe<ServiceConsumerRelateToOneInput>;
  billingCategory?: Maybe<BillingCategoryRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type RecurrentPaymentContextHistoryRecord = {
  __typename?: 'RecurrentPaymentContextHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPaymentContextHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPaymentContextHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the RecurrentPaymentContextHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPaymentContextHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSON']>;
  serviceConsumer?: Maybe<Scalars['String']>;
  billingCategory?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type RecurrentPaymentContextHistoryRecordCreateInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSON']>;
  serviceConsumer?: Maybe<Scalars['String']>;
  billingCategory?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum RecurrentPaymentContextHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type RecurrentPaymentContextHistoryRecordUpdateInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['JSON']>;
  serviceConsumer?: Maybe<Scalars['String']>;
  billingCategory?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type RecurrentPaymentContextHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecordWhereInput>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabled_not?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  limit_not?: Maybe<Scalars['String']>;
  limit_lt?: Maybe<Scalars['String']>;
  limit_lte?: Maybe<Scalars['String']>;
  limit_gt?: Maybe<Scalars['String']>;
  limit_gte?: Maybe<Scalars['String']>;
  limit_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  autoPayReceipts_not?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  paymentDay_not?: Maybe<Scalars['Int']>;
  paymentDay_lt?: Maybe<Scalars['Int']>;
  paymentDay_lte?: Maybe<Scalars['Int']>;
  paymentDay_gt?: Maybe<Scalars['Int']>;
  paymentDay_gte?: Maybe<Scalars['Int']>;
  paymentDay_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  paymentDay_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  settings?: Maybe<Scalars['JSON']>;
  settings_not?: Maybe<Scalars['JSON']>;
  settings_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  settings_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  serviceConsumer?: Maybe<Scalars['String']>;
  serviceConsumer_not?: Maybe<Scalars['String']>;
  serviceConsumer_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  serviceConsumer_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingCategory?: Maybe<Scalars['String']>;
  billingCategory_not?: Maybe<Scalars['String']>;
  billingCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<RecurrentPaymentContextHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecurrentPaymentContextHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RecurrentPaymentContextHistoryRecordsCreateInput = {
  data?: Maybe<RecurrentPaymentContextHistoryRecordCreateInput>;
};

export type RecurrentPaymentContextHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentContextHistoryRecordUpdateInput>;
};

export type RecurrentPaymentContextRelateToOneInput = {
  create?: Maybe<RecurrentPaymentContextCreateInput>;
  connect?: Maybe<RecurrentPaymentContextWhereUniqueInput>;
  disconnect?: Maybe<RecurrentPaymentContextWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type RecurrentPaymentContextUpdateInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  settings?: Maybe<SettingsFieldInput>;
  serviceConsumer?: Maybe<ServiceConsumerRelateToOneInput>;
  billingCategory?: Maybe<BillingCategoryRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type RecurrentPaymentContextWhereInput = {
  AND?: Maybe<Array<Maybe<RecurrentPaymentContextWhereInput>>>;
  OR?: Maybe<Array<Maybe<RecurrentPaymentContextWhereInput>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  enabled_not?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['String']>;
  limit_not?: Maybe<Scalars['String']>;
  limit_lt?: Maybe<Scalars['String']>;
  limit_lte?: Maybe<Scalars['String']>;
  limit_gt?: Maybe<Scalars['String']>;
  limit_gte?: Maybe<Scalars['String']>;
  limit_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  autoPayReceipts?: Maybe<Scalars['Boolean']>;
  autoPayReceipts_not?: Maybe<Scalars['Boolean']>;
  paymentDay?: Maybe<Scalars['Int']>;
  paymentDay_not?: Maybe<Scalars['Int']>;
  paymentDay_lt?: Maybe<Scalars['Int']>;
  paymentDay_lte?: Maybe<Scalars['Int']>;
  paymentDay_gt?: Maybe<Scalars['Int']>;
  paymentDay_gte?: Maybe<Scalars['Int']>;
  paymentDay_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  paymentDay_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  settings?: Maybe<SettingsFieldInput>;
  settings_not?: Maybe<SettingsFieldInput>;
  settings_in?: Maybe<Array<Maybe<SettingsFieldInput>>>;
  settings_not_in?: Maybe<Array<Maybe<SettingsFieldInput>>>;
  serviceConsumer?: Maybe<ServiceConsumerWhereInput>;
  serviceConsumer_is_null?: Maybe<Scalars['Boolean']>;
  billingCategory?: Maybe<BillingCategoryWhereInput>;
  billingCategory_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type RecurrentPaymentContextWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RecurrentPaymentContextsCreateInput = {
  data?: Maybe<RecurrentPaymentContextCreateInput>;
};

export type RecurrentPaymentContextsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentContextUpdateInput>;
};

export type RecurrentPaymentCreateInput = {
  status?: Maybe<RecurrentPaymentStatusType>;
  payAfter?: Maybe<Scalars['String']>;
  tryCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['JSON']>;
  billingReceipts?: Maybe<Array<BillingReceiptsFieldInput>>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type RecurrentPaymentHistoryRecord = {
  __typename?: 'RecurrentPaymentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RecurrentPaymentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the RecurrentPaymentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the RecurrentPaymentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the RecurrentPaymentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payAfter?: Maybe<Scalars['String']>;
  tryCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['JSON']>;
  billingReceipts?: Maybe<Scalars['JSON']>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RecurrentPaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type RecurrentPaymentHistoryRecordCreateInput = {
  status?: Maybe<Scalars['String']>;
  payAfter?: Maybe<Scalars['String']>;
  tryCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['JSON']>;
  billingReceipts?: Maybe<Scalars['JSON']>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RecurrentPaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum RecurrentPaymentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type RecurrentPaymentHistoryRecordUpdateInput = {
  status?: Maybe<Scalars['String']>;
  payAfter?: Maybe<Scalars['String']>;
  tryCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['JSON']>;
  billingReceipts?: Maybe<Scalars['JSON']>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RecurrentPaymentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type RecurrentPaymentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecordWhereInput>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payAfter?: Maybe<Scalars['String']>;
  payAfter_not?: Maybe<Scalars['String']>;
  payAfter_lt?: Maybe<Scalars['String']>;
  payAfter_lte?: Maybe<Scalars['String']>;
  payAfter_gt?: Maybe<Scalars['String']>;
  payAfter_gte?: Maybe<Scalars['String']>;
  payAfter_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payAfter_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tryCount?: Maybe<Scalars['Int']>;
  tryCount_not?: Maybe<Scalars['Int']>;
  tryCount_lt?: Maybe<Scalars['Int']>;
  tryCount_lte?: Maybe<Scalars['Int']>;
  tryCount_gt?: Maybe<Scalars['Int']>;
  tryCount_gte?: Maybe<Scalars['Int']>;
  tryCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tryCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  state?: Maybe<Scalars['JSON']>;
  state_not?: Maybe<Scalars['JSON']>;
  state_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  billingReceipts?: Maybe<Scalars['JSON']>;
  billingReceipts_not?: Maybe<Scalars['JSON']>;
  billingReceipts_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  billingReceipts_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  recurrentPaymentContext?: Maybe<Scalars['String']>;
  recurrentPaymentContext_not?: Maybe<Scalars['String']>;
  recurrentPaymentContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  recurrentPaymentContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<RecurrentPaymentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<RecurrentPaymentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<RecurrentPaymentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecurrentPaymentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RecurrentPaymentHistoryRecordsCreateInput = {
  data?: Maybe<RecurrentPaymentHistoryRecordCreateInput>;
};

export type RecurrentPaymentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentHistoryRecordUpdateInput>;
};

export enum RecurrentPaymentStatusType {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Done = 'DONE',
  ErrorNeedRetry = 'ERROR_NEED_RETRY',
  Error = 'ERROR',
  Cancel = 'CANCEL'
}

export type RecurrentPaymentUpdateInput = {
  status?: Maybe<RecurrentPaymentStatusType>;
  payAfter?: Maybe<Scalars['String']>;
  tryCount?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['JSON']>;
  billingReceipts?: Maybe<Array<BillingReceiptsFieldInput>>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type RecurrentPaymentWhereInput = {
  AND?: Maybe<Array<Maybe<RecurrentPaymentWhereInput>>>;
  OR?: Maybe<Array<Maybe<RecurrentPaymentWhereInput>>>;
  status?: Maybe<RecurrentPaymentStatusType>;
  status_not?: Maybe<RecurrentPaymentStatusType>;
  status_in?: Maybe<Array<Maybe<RecurrentPaymentStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<RecurrentPaymentStatusType>>>;
  payAfter?: Maybe<Scalars['String']>;
  payAfter_not?: Maybe<Scalars['String']>;
  payAfter_lt?: Maybe<Scalars['String']>;
  payAfter_lte?: Maybe<Scalars['String']>;
  payAfter_gt?: Maybe<Scalars['String']>;
  payAfter_gte?: Maybe<Scalars['String']>;
  payAfter_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  payAfter_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  tryCount?: Maybe<Scalars['Int']>;
  tryCount_not?: Maybe<Scalars['Int']>;
  tryCount_lt?: Maybe<Scalars['Int']>;
  tryCount_lte?: Maybe<Scalars['Int']>;
  tryCount_gt?: Maybe<Scalars['Int']>;
  tryCount_gte?: Maybe<Scalars['Int']>;
  tryCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tryCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  state?: Maybe<Scalars['JSON']>;
  state_not?: Maybe<Scalars['JSON']>;
  state_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  state_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  billingReceipts?: Maybe<Array<BillingReceiptsFieldInput>>;
  billingReceipts_not?: Maybe<Array<BillingReceiptsFieldInput>>;
  billingReceipts_in?: Maybe<Array<Maybe<Array<BillingReceiptsFieldInput>>>>;
  billingReceipts_not_in?: Maybe<Array<Maybe<Array<BillingReceiptsFieldInput>>>>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextWhereInput>;
  recurrentPaymentContext_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type RecurrentPaymentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RecurrentPaymentsCreateInput = {
  data?: Maybe<RecurrentPaymentCreateInput>;
};

export type RecurrentPaymentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<RecurrentPaymentUpdateInput>;
};

export type RegisterBillingReceiptAccountMetaInput = {
  globalId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  ownerType?: Maybe<BillingAccountOwnerTypeType>;
};

export type RegisterBillingReceiptAddressMetaInput = {
  globalId?: Maybe<Scalars['String']>;
  importId?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export type RegisterBillingReceiptFileInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  context: BillingIntegrationOrganizationContextWhereUniqueInput;
  receipt: BillingReceiptWhereInput;
  base64EncodedPDF: Scalars['String'];
};

export type RegisterBillingReceiptFileOutput = {
  __typename?: 'RegisterBillingReceiptFileOutput';
  id: Scalars['ID'];
  status: RegisterBillingReceiptFileResultStatus;
};

export enum RegisterBillingReceiptFileResultStatus {
  Created = 'CREATED',
  Updated = 'UPDATED',
  Skipped = 'SKIPPED'
}

export type RegisterBillingReceiptInput = {
  importId?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  addressMeta?: Maybe<RegisterBillingReceiptAddressMetaInput>;
  accountNumber: Scalars['String'];
  accountMeta?: Maybe<RegisterBillingReceiptAccountMetaInput>;
  toPay: Scalars['String'];
  toPayDetails?: Maybe<BillingReceiptServiceToPayDetailsFieldInput>;
  services?: Maybe<Array<Maybe<BillingReceiptServiceFieldInput>>>;
  category?: Maybe<BillingCategoryWhereUniqueInput>;
  month: Scalars['Int'];
  year: Scalars['Int'];
  tin: Scalars['String'];
  routingNumber: Scalars['String'];
  bankAccount: Scalars['String'];
  raw?: Maybe<Scalars['JSON']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  normalizedAddress?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  tinMeta?: Maybe<Scalars['JSON']>;
  routingNumberMeta?: Maybe<Scalars['JSON']>;
};

export type RegisterBillingReceiptsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  context?: Maybe<BillingIntegrationOrganizationContextWhereUniqueInput>;
  receipts: Array<RegisterBillingReceiptInput>;
};

export type RegisterMetersReadingsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
  readings: Array<RegisterMetersReadingsReadingInput>;
};

export type RegisterMetersReadingsMeterMetaInput = {
  numberOfTariffs?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['String']>;
  verificationDate?: Maybe<Scalars['String']>;
  nextVerificationDate?: Maybe<Scalars['String']>;
  installationDate?: Maybe<Scalars['String']>;
  commissioningDate?: Maybe<Scalars['String']>;
  sealingDate?: Maybe<Scalars['String']>;
  controlReadingsDate?: Maybe<Scalars['String']>;
  isAutomatic?: Maybe<Scalars['Boolean']>;
};

export type RegisterMetersReadingsMeterOutput = {
  __typename?: 'RegisterMetersReadingsMeterOutput';
  id: Scalars['ID'];
  property: RegisterMetersReadingsMeterPropertyOutput;
  unitType: Scalars['String'];
  unitName: Scalars['String'];
  accountNumber: Scalars['String'];
  number: Scalars['String'];
};

export type RegisterMetersReadingsMeterPropertyOutput = {
  __typename?: 'RegisterMetersReadingsMeterPropertyOutput';
  id: Scalars['ID'];
  address: Scalars['String'];
  addressKey: Scalars['String'];
};

export type RegisterMetersReadingsOutput = {
  __typename?: 'RegisterMetersReadingsOutput';
  id: Scalars['ID'];
  meter: RegisterMetersReadingsMeterOutput;
};

export type RegisterMetersReadingsReadingAddressInfoInput = {
  unitType?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  globalId?: Maybe<Scalars['String']>;
};

export type RegisterMetersReadingsReadingInput = {
  address: Scalars['String'];
  addressInfo: RegisterMetersReadingsReadingAddressInfoInput;
  accountNumber: Scalars['String'];
  meterNumber: Scalars['String'];
  meterResource: MeterResourceWhereUniqueInput;
  date: Scalars['String'];
  value1: Scalars['String'];
  value2?: Maybe<Scalars['String']>;
  value3?: Maybe<Scalars['String']>;
  value4?: Maybe<Scalars['String']>;
  meterMeta?: Maybe<RegisterMetersReadingsMeterMetaInput>;
  readingSource?: Maybe<MeterReadingSourceWhereUniqueInput>;
};

export type RegisterMultiPaymentForInvoicesInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  invoices: Array<InvoiceWhereUniqueInput>;
};

export type RegisterMultiPaymentForInvoicesOutput = {
  __typename?: 'RegisterMultiPaymentForInvoicesOutput';
  dv: Scalars['Int'];
  multiPaymentId: Scalars['String'];
  webViewUrl: Scalars['String'];
  feeCalculationUrl: Scalars['String'];
  directPaymentUrl: Scalars['String'];
  anonymousPaymentUrl: Scalars['String'];
};

export type RegisterMultiPaymentForOneReceiptInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  receipt: BillingReceiptWhereUniqueInput;
  acquiringIntegrationContext: AcquiringIntegrationContextWhereUniqueInput;
};

export type RegisterMultiPaymentForOneReceiptOutput = {
  __typename?: 'RegisterMultiPaymentForOneReceiptOutput';
  dv: Scalars['Int'];
  multiPaymentId: Scalars['String'];
  webViewUrl: Scalars['String'];
  feeCalculationUrl: Scalars['String'];
  directPaymentUrl: Scalars['String'];
  anonymousPaymentUrl: Scalars['String'];
};

export type RegisterMultiPaymentForVirtualReceiptInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  receipt: RegisterMultiPaymentVirtualReceiptInput;
  acquiringIntegrationContext: AcquiringIntegrationContextWhereUniqueInput;
};

export type RegisterMultiPaymentForVirtualReceiptOutput = {
  __typename?: 'RegisterMultiPaymentForVirtualReceiptOutput';
  dv: Scalars['Int'];
  multiPaymentId: Scalars['String'];
  webViewUrl: Scalars['String'];
  feeCalculationUrl: Scalars['String'];
  directPaymentUrl: Scalars['String'];
  anonymousPaymentUrl: Scalars['String'];
};

export type RegisterMultiPaymentInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  groupedReceipts?: Maybe<Array<RegisterMultiPaymentServiceConsumerInput>>;
  recurrentPaymentContext?: Maybe<RecurrentPaymentContextWhereUniqueInput>;
  invoices?: Maybe<Array<InvoiceWhereUniqueInput>>;
};

export type RegisterMultiPaymentOutput = {
  __typename?: 'RegisterMultiPaymentOutput';
  dv: Scalars['Int'];
  multiPaymentId: Scalars['String'];
  webViewUrl: Scalars['String'];
  feeCalculationUrl: Scalars['String'];
  directPaymentUrl: Scalars['String'];
  getCardTokensUrl: Scalars['String'];
};

export type RegisterMultiPaymentReceiptAmountInput = {
  receipt: BillingReceiptWhereUniqueInput;
  amount: Scalars['String'];
};

export type RegisterMultiPaymentServiceConsumerInput = {
  serviceConsumer: ServiceConsumerWhereUniqueInput;
  receipts: Array<BillingReceiptWhereUniqueInput>;
  amountDistribution?: Maybe<Array<RegisterMultiPaymentReceiptAmountInput>>;
};

export type RegisterMultiPaymentVirtualReceiptInput = {
  currencyCode: Scalars['String'];
  amount: Scalars['String'];
  period: Scalars['String'];
  recipient: RegisterMultiPaymentVirtualReceiptRecipientInput;
};

export type RegisterMultiPaymentVirtualReceiptRecipientInput = {
  routingNumber: Scalars['String'];
  bankAccount: Scalars['String'];
  accountNumber: Scalars['String'];
};

export type RegisterNewOrganizationInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  country: Scalars['String'];
  name: Scalars['String'];
  tin: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  meta: Scalars['JSON'];
  avatar?: Maybe<Scalars['Upload']>;
  type?: Maybe<OrganizationType>;
};

export type RegisterNewServiceUserInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  name: Scalars['String'];
  email: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
};

export type RegisterNewServiceUserOutput = {
  __typename?: 'RegisterNewServiceUserOutput';
  id: Scalars['ID'];
  email: Scalars['String'];
  password: Scalars['String'];
};

export type RegisterNewUserInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  name: Scalars['String'];
  password: Scalars['String'];
  confirmPhoneActionToken: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
};

export type RegisterResidentInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  address: Scalars['String'];
  addressMeta?: Maybe<AddressMetaFieldInput>;
  unitName: Scalars['String'];
  unitType?: Maybe<BuildingUnitSubType>;
};

export type RegisterResidentInvoiceInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  resident: ResidentWhereUniqueInput;
  invoiceRows: Array<InvoiceRowsInput>;
  paymentType: InvoicePaymentType;
};

export type RegisterResidentServiceConsumersInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  resident: ResidentWhereUniqueInput;
  accountNumber: Scalars['String'];
  paymentCategory?: Maybe<Scalars['String']>;
};

export type RegisterServiceConsumerInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  residentId: Scalars['ID'];
  accountNumber: Scalars['String'];
  organizationId: Scalars['ID'];
  extra?: Maybe<RegisterServiceConsumerInputExtra>;
};

export type RegisterServiceConsumerInputExtra = {
  paymentCategory?: Maybe<Scalars['String']>;
};

/**  Used to describe device in order to be able to send push notifications via corresponding transport, depending on pushTransport value. RemoteClient could be mobile or web based. RemoteClient could be registered (created by user, admin or anonymous) with or without token, and updated later on by admin (or a user within SyncRemoteClientService) by adding/changing token value and connecting device to user (whose authorization was passed within request). All such interactions should be done via SyncRemoteClientService.  */
export type RemoteClient = {
  __typename?: 'RemoteClient';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RemoteClient List config, or
   *  2. As an alias to the field set on 'labelField' in the RemoteClient List config, or
   *  3. As an alias to a 'name' field on the RemoteClient List (if one exists), or
   *  4. As an alias to the 'id' field on the RemoteClient List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Mobile/web device ID, which is used to identify a device. One user can have many devices, and one device can be used by many users once upon a time.  */
  deviceId?: Maybe<Scalars['String']>;
  /**  Application ID, which is used to identify app. Same device can have multiple apps installed for same user. It is also required for applying correct credentials for Firebase/Huawei/etc. API requests.  */
  appId?: Maybe<Scalars['String']>;
  /**  Used by transport services (FireBase, Apple, Huawei, etc.) to transfer push notifications to devices.  */
  pushToken?: Maybe<Scalars['String']>;
  /**  Transport service, that delivers push notifications to client device. Type of device requires specific transport service, e.g. Huawei devices can not receive notifications through FireBase.  */
  pushTransport?: Maybe<RemoteClientPushTransportType>;
  /**  Represents the platform type of the client application like android/ios/web.  */
  devicePlatform?: Maybe<RemoteClientDevicePlatformType>;
  /**  Represents the type of push to be sent to the remote client like default/silent-data/etc. Remote client can control structure of data sent via push to the device using this field.Some remote clients are able to show own notifications instead system ones. To do so they have to receive push, containing no notification part, which is sent if this field is equal to PUSH_TYPE_SILENT_DATA.  */
  pushType?: Maybe<RemoteClientPushTypeType>;
  /**  Used by transport services (FireBase, Apple, Huawei, etc.) to transfer VoIP push notifications to devices.  */
  pushTokenVoIP?: Maybe<Scalars['String']>;
  /**  Transport service, that delivers VoIP push notifications to client device. Type of device requires specific transport service, e.g. Huawei devices can not receive notifications through FireBase.  */
  pushTransportVoIP?: Maybe<RemoteClientPushTransportVoIpType>;
  /**  Represents the type of VoIP push to be sent to the remote client like default/silent-data/etc. Remote client can control structure of data sent via push to the device using this field.Some remote clients are able to show own notifications instead system ones. To do so they have to receive push, containing no notification part, which is sent if this field is equal to PUSH_TYPE_SILENT_DATA.  */
  pushTypeVoIP?: Maybe<RemoteClientPushTypeVoIpType>;
  /**  Owner user of a device and a push token. User, which is logged in on the device. Push token can be created by anonymous user and connected to authorized user later on.  */
  owner?: Maybe<User>;
  /**  RemoteClient metadata. OS type, OS version, etc.  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type RemoteClientCreateInput = {
  deviceId?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  pushTransport?: Maybe<RemoteClientPushTransportType>;
  devicePlatform?: Maybe<RemoteClientDevicePlatformType>;
  pushType?: Maybe<RemoteClientPushTypeType>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP?: Maybe<RemoteClientPushTransportVoIpType>;
  pushTypeVoIP?: Maybe<RemoteClientPushTypeVoIpType>;
  owner?: Maybe<UserRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum RemoteClientDevicePlatformType {
  Android = 'android',
  Ios = 'ios',
  Web = 'web'
}

/**  A keystone list  */
export type RemoteClientHistoryRecord = {
  __typename?: 'RemoteClientHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the RemoteClientHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the RemoteClientHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the RemoteClientHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the RemoteClientHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  pushTransport?: Maybe<Scalars['String']>;
  devicePlatform?: Maybe<Scalars['String']>;
  pushType?: Maybe<Scalars['String']>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP?: Maybe<Scalars['String']>;
  pushTypeVoIP?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RemoteClientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type RemoteClientHistoryRecordCreateInput = {
  deviceId?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  pushTransport?: Maybe<Scalars['String']>;
  devicePlatform?: Maybe<Scalars['String']>;
  pushType?: Maybe<Scalars['String']>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP?: Maybe<Scalars['String']>;
  pushTypeVoIP?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RemoteClientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum RemoteClientHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type RemoteClientHistoryRecordUpdateInput = {
  deviceId?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  pushTransport?: Maybe<Scalars['String']>;
  devicePlatform?: Maybe<Scalars['String']>;
  pushType?: Maybe<Scalars['String']>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP?: Maybe<Scalars['String']>;
  pushTypeVoIP?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<RemoteClientHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type RemoteClientHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<RemoteClientHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<RemoteClientHistoryRecordWhereInput>>>;
  deviceId?: Maybe<Scalars['String']>;
  deviceId_not?: Maybe<Scalars['String']>;
  deviceId_contains?: Maybe<Scalars['String']>;
  deviceId_not_contains?: Maybe<Scalars['String']>;
  deviceId_starts_with?: Maybe<Scalars['String']>;
  deviceId_not_starts_with?: Maybe<Scalars['String']>;
  deviceId_ends_with?: Maybe<Scalars['String']>;
  deviceId_not_ends_with?: Maybe<Scalars['String']>;
  deviceId_i?: Maybe<Scalars['String']>;
  deviceId_not_i?: Maybe<Scalars['String']>;
  deviceId_contains_i?: Maybe<Scalars['String']>;
  deviceId_not_contains_i?: Maybe<Scalars['String']>;
  deviceId_starts_with_i?: Maybe<Scalars['String']>;
  deviceId_not_starts_with_i?: Maybe<Scalars['String']>;
  deviceId_ends_with_i?: Maybe<Scalars['String']>;
  deviceId_not_ends_with_i?: Maybe<Scalars['String']>;
  deviceId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appId?: Maybe<Scalars['String']>;
  appId_not?: Maybe<Scalars['String']>;
  appId_contains?: Maybe<Scalars['String']>;
  appId_not_contains?: Maybe<Scalars['String']>;
  appId_starts_with?: Maybe<Scalars['String']>;
  appId_not_starts_with?: Maybe<Scalars['String']>;
  appId_ends_with?: Maybe<Scalars['String']>;
  appId_not_ends_with?: Maybe<Scalars['String']>;
  appId_i?: Maybe<Scalars['String']>;
  appId_not_i?: Maybe<Scalars['String']>;
  appId_contains_i?: Maybe<Scalars['String']>;
  appId_not_contains_i?: Maybe<Scalars['String']>;
  appId_starts_with_i?: Maybe<Scalars['String']>;
  appId_not_starts_with_i?: Maybe<Scalars['String']>;
  appId_ends_with_i?: Maybe<Scalars['String']>;
  appId_not_ends_with_i?: Maybe<Scalars['String']>;
  appId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushToken?: Maybe<Scalars['String']>;
  pushToken_not?: Maybe<Scalars['String']>;
  pushToken_contains?: Maybe<Scalars['String']>;
  pushToken_not_contains?: Maybe<Scalars['String']>;
  pushToken_starts_with?: Maybe<Scalars['String']>;
  pushToken_not_starts_with?: Maybe<Scalars['String']>;
  pushToken_ends_with?: Maybe<Scalars['String']>;
  pushToken_not_ends_with?: Maybe<Scalars['String']>;
  pushToken_i?: Maybe<Scalars['String']>;
  pushToken_not_i?: Maybe<Scalars['String']>;
  pushToken_contains_i?: Maybe<Scalars['String']>;
  pushToken_not_contains_i?: Maybe<Scalars['String']>;
  pushToken_starts_with_i?: Maybe<Scalars['String']>;
  pushToken_not_starts_with_i?: Maybe<Scalars['String']>;
  pushToken_ends_with_i?: Maybe<Scalars['String']>;
  pushToken_not_ends_with_i?: Maybe<Scalars['String']>;
  pushToken_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushToken_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTransport?: Maybe<Scalars['String']>;
  pushTransport_not?: Maybe<Scalars['String']>;
  pushTransport_contains?: Maybe<Scalars['String']>;
  pushTransport_not_contains?: Maybe<Scalars['String']>;
  pushTransport_starts_with?: Maybe<Scalars['String']>;
  pushTransport_not_starts_with?: Maybe<Scalars['String']>;
  pushTransport_ends_with?: Maybe<Scalars['String']>;
  pushTransport_not_ends_with?: Maybe<Scalars['String']>;
  pushTransport_i?: Maybe<Scalars['String']>;
  pushTransport_not_i?: Maybe<Scalars['String']>;
  pushTransport_contains_i?: Maybe<Scalars['String']>;
  pushTransport_not_contains_i?: Maybe<Scalars['String']>;
  pushTransport_starts_with_i?: Maybe<Scalars['String']>;
  pushTransport_not_starts_with_i?: Maybe<Scalars['String']>;
  pushTransport_ends_with_i?: Maybe<Scalars['String']>;
  pushTransport_not_ends_with_i?: Maybe<Scalars['String']>;
  pushTransport_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTransport_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  devicePlatform?: Maybe<Scalars['String']>;
  devicePlatform_not?: Maybe<Scalars['String']>;
  devicePlatform_contains?: Maybe<Scalars['String']>;
  devicePlatform_not_contains?: Maybe<Scalars['String']>;
  devicePlatform_starts_with?: Maybe<Scalars['String']>;
  devicePlatform_not_starts_with?: Maybe<Scalars['String']>;
  devicePlatform_ends_with?: Maybe<Scalars['String']>;
  devicePlatform_not_ends_with?: Maybe<Scalars['String']>;
  devicePlatform_i?: Maybe<Scalars['String']>;
  devicePlatform_not_i?: Maybe<Scalars['String']>;
  devicePlatform_contains_i?: Maybe<Scalars['String']>;
  devicePlatform_not_contains_i?: Maybe<Scalars['String']>;
  devicePlatform_starts_with_i?: Maybe<Scalars['String']>;
  devicePlatform_not_starts_with_i?: Maybe<Scalars['String']>;
  devicePlatform_ends_with_i?: Maybe<Scalars['String']>;
  devicePlatform_not_ends_with_i?: Maybe<Scalars['String']>;
  devicePlatform_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  devicePlatform_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushType?: Maybe<Scalars['String']>;
  pushType_not?: Maybe<Scalars['String']>;
  pushType_contains?: Maybe<Scalars['String']>;
  pushType_not_contains?: Maybe<Scalars['String']>;
  pushType_starts_with?: Maybe<Scalars['String']>;
  pushType_not_starts_with?: Maybe<Scalars['String']>;
  pushType_ends_with?: Maybe<Scalars['String']>;
  pushType_not_ends_with?: Maybe<Scalars['String']>;
  pushType_i?: Maybe<Scalars['String']>;
  pushType_not_i?: Maybe<Scalars['String']>;
  pushType_contains_i?: Maybe<Scalars['String']>;
  pushType_not_contains_i?: Maybe<Scalars['String']>;
  pushType_starts_with_i?: Maybe<Scalars['String']>;
  pushType_not_starts_with_i?: Maybe<Scalars['String']>;
  pushType_ends_with_i?: Maybe<Scalars['String']>;
  pushType_not_ends_with_i?: Maybe<Scalars['String']>;
  pushType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTokenVoIP_not?: Maybe<Scalars['String']>;
  pushTokenVoIP_contains?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_contains?: Maybe<Scalars['String']>;
  pushTokenVoIP_starts_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_starts_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_ends_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_ends_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_contains_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_contains_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_starts_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_starts_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_ends_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_ends_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTokenVoIP_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTransportVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP_not?: Maybe<Scalars['String']>;
  pushTransportVoIP_contains?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_contains?: Maybe<Scalars['String']>;
  pushTransportVoIP_starts_with?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_starts_with?: Maybe<Scalars['String']>;
  pushTransportVoIP_ends_with?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_ends_with?: Maybe<Scalars['String']>;
  pushTransportVoIP_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_contains_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_contains_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_starts_with_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_starts_with_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_ends_with_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_not_ends_with_i?: Maybe<Scalars['String']>;
  pushTransportVoIP_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTransportVoIP_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTypeVoIP?: Maybe<Scalars['String']>;
  pushTypeVoIP_not?: Maybe<Scalars['String']>;
  pushTypeVoIP_contains?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_contains?: Maybe<Scalars['String']>;
  pushTypeVoIP_starts_with?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_starts_with?: Maybe<Scalars['String']>;
  pushTypeVoIP_ends_with?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_ends_with?: Maybe<Scalars['String']>;
  pushTypeVoIP_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_contains_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_contains_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_starts_with_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_starts_with_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_ends_with_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_not_ends_with_i?: Maybe<Scalars['String']>;
  pushTypeVoIP_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTypeVoIP_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner?: Maybe<Scalars['String']>;
  owner_not?: Maybe<Scalars['String']>;
  owner_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  owner_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<RemoteClientHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<RemoteClientHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<RemoteClientHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<RemoteClientHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RemoteClientHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RemoteClientHistoryRecordsCreateInput = {
  data?: Maybe<RemoteClientHistoryRecordCreateInput>;
};

export type RemoteClientHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<RemoteClientHistoryRecordUpdateInput>;
};

export enum RemoteClientPushTransportType {
  Firebase = 'firebase',
  Apple = 'apple',
  Huawei = 'huawei'
}

export enum RemoteClientPushTransportVoIpType {
  Firebase = 'firebase',
  Apple = 'apple',
  Huawei = 'huawei'
}

export enum RemoteClientPushTypeType {
  Default = 'default',
  SilentData = 'silent_data'
}

export enum RemoteClientPushTypeVoIpType {
  Default = 'default',
  SilentData = 'silent_data'
}

export type RemoteClientRelateToOneInput = {
  create?: Maybe<RemoteClientCreateInput>;
  connect?: Maybe<RemoteClientWhereUniqueInput>;
  disconnect?: Maybe<RemoteClientWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type RemoteClientUpdateInput = {
  deviceId?: Maybe<Scalars['String']>;
  appId?: Maybe<Scalars['String']>;
  pushToken?: Maybe<Scalars['String']>;
  pushTransport?: Maybe<RemoteClientPushTransportType>;
  devicePlatform?: Maybe<RemoteClientDevicePlatformType>;
  pushType?: Maybe<RemoteClientPushTypeType>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP?: Maybe<RemoteClientPushTransportVoIpType>;
  pushTypeVoIP?: Maybe<RemoteClientPushTypeVoIpType>;
  owner?: Maybe<UserRelateToOneInput>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type RemoteClientWhereInput = {
  AND?: Maybe<Array<Maybe<RemoteClientWhereInput>>>;
  OR?: Maybe<Array<Maybe<RemoteClientWhereInput>>>;
  deviceId?: Maybe<Scalars['String']>;
  deviceId_not?: Maybe<Scalars['String']>;
  deviceId_contains?: Maybe<Scalars['String']>;
  deviceId_not_contains?: Maybe<Scalars['String']>;
  deviceId_starts_with?: Maybe<Scalars['String']>;
  deviceId_not_starts_with?: Maybe<Scalars['String']>;
  deviceId_ends_with?: Maybe<Scalars['String']>;
  deviceId_not_ends_with?: Maybe<Scalars['String']>;
  deviceId_i?: Maybe<Scalars['String']>;
  deviceId_not_i?: Maybe<Scalars['String']>;
  deviceId_contains_i?: Maybe<Scalars['String']>;
  deviceId_not_contains_i?: Maybe<Scalars['String']>;
  deviceId_starts_with_i?: Maybe<Scalars['String']>;
  deviceId_not_starts_with_i?: Maybe<Scalars['String']>;
  deviceId_ends_with_i?: Maybe<Scalars['String']>;
  deviceId_not_ends_with_i?: Maybe<Scalars['String']>;
  deviceId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appId?: Maybe<Scalars['String']>;
  appId_not?: Maybe<Scalars['String']>;
  appId_contains?: Maybe<Scalars['String']>;
  appId_not_contains?: Maybe<Scalars['String']>;
  appId_starts_with?: Maybe<Scalars['String']>;
  appId_not_starts_with?: Maybe<Scalars['String']>;
  appId_ends_with?: Maybe<Scalars['String']>;
  appId_not_ends_with?: Maybe<Scalars['String']>;
  appId_i?: Maybe<Scalars['String']>;
  appId_not_i?: Maybe<Scalars['String']>;
  appId_contains_i?: Maybe<Scalars['String']>;
  appId_not_contains_i?: Maybe<Scalars['String']>;
  appId_starts_with_i?: Maybe<Scalars['String']>;
  appId_not_starts_with_i?: Maybe<Scalars['String']>;
  appId_ends_with_i?: Maybe<Scalars['String']>;
  appId_not_ends_with_i?: Maybe<Scalars['String']>;
  appId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  appId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushToken?: Maybe<Scalars['String']>;
  pushToken_not?: Maybe<Scalars['String']>;
  pushToken_contains?: Maybe<Scalars['String']>;
  pushToken_not_contains?: Maybe<Scalars['String']>;
  pushToken_starts_with?: Maybe<Scalars['String']>;
  pushToken_not_starts_with?: Maybe<Scalars['String']>;
  pushToken_ends_with?: Maybe<Scalars['String']>;
  pushToken_not_ends_with?: Maybe<Scalars['String']>;
  pushToken_i?: Maybe<Scalars['String']>;
  pushToken_not_i?: Maybe<Scalars['String']>;
  pushToken_contains_i?: Maybe<Scalars['String']>;
  pushToken_not_contains_i?: Maybe<Scalars['String']>;
  pushToken_starts_with_i?: Maybe<Scalars['String']>;
  pushToken_not_starts_with_i?: Maybe<Scalars['String']>;
  pushToken_ends_with_i?: Maybe<Scalars['String']>;
  pushToken_not_ends_with_i?: Maybe<Scalars['String']>;
  pushToken_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushToken_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTransport?: Maybe<RemoteClientPushTransportType>;
  pushTransport_not?: Maybe<RemoteClientPushTransportType>;
  pushTransport_in?: Maybe<Array<Maybe<RemoteClientPushTransportType>>>;
  pushTransport_not_in?: Maybe<Array<Maybe<RemoteClientPushTransportType>>>;
  devicePlatform?: Maybe<RemoteClientDevicePlatformType>;
  devicePlatform_not?: Maybe<RemoteClientDevicePlatformType>;
  devicePlatform_in?: Maybe<Array<Maybe<RemoteClientDevicePlatformType>>>;
  devicePlatform_not_in?: Maybe<Array<Maybe<RemoteClientDevicePlatformType>>>;
  pushType?: Maybe<RemoteClientPushTypeType>;
  pushType_not?: Maybe<RemoteClientPushTypeType>;
  pushType_in?: Maybe<Array<Maybe<RemoteClientPushTypeType>>>;
  pushType_not_in?: Maybe<Array<Maybe<RemoteClientPushTypeType>>>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTokenVoIP_not?: Maybe<Scalars['String']>;
  pushTokenVoIP_contains?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_contains?: Maybe<Scalars['String']>;
  pushTokenVoIP_starts_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_starts_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_ends_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_ends_with?: Maybe<Scalars['String']>;
  pushTokenVoIP_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_contains_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_contains_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_starts_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_starts_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_ends_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_not_ends_with_i?: Maybe<Scalars['String']>;
  pushTokenVoIP_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTokenVoIP_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  pushTransportVoIP?: Maybe<RemoteClientPushTransportVoIpType>;
  pushTransportVoIP_not?: Maybe<RemoteClientPushTransportVoIpType>;
  pushTransportVoIP_in?: Maybe<Array<Maybe<RemoteClientPushTransportVoIpType>>>;
  pushTransportVoIP_not_in?: Maybe<Array<Maybe<RemoteClientPushTransportVoIpType>>>;
  pushTypeVoIP?: Maybe<RemoteClientPushTypeVoIpType>;
  pushTypeVoIP_not?: Maybe<RemoteClientPushTypeVoIpType>;
  pushTypeVoIP_in?: Maybe<Array<Maybe<RemoteClientPushTypeVoIpType>>>;
  pushTypeVoIP_not_in?: Maybe<Array<Maybe<RemoteClientPushTypeVoIpType>>>;
  owner?: Maybe<UserWhereInput>;
  owner_is_null?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type RemoteClientWhereUniqueInput = {
  id: Scalars['ID'];
};

export type RemoteClientsCreateInput = {
  data?: Maybe<RemoteClientCreateInput>;
};

export type RemoteClientsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<RemoteClientUpdateInput>;
};

export type ReplaceOrganizationEmployeeRoleInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
  oldRole: OrganizationEmployeeRoleWhereUniqueInput;
  newRole: OrganizationEmployeeRoleWhereUniqueInput;
  withDeletionOldRole: Scalars['Boolean'];
};

export type ReplaceOrganizationEmployeeRoleOutput = {
  __typename?: 'ReplaceOrganizationEmployeeRoleOutput';
  status: Scalars['String'];
};

export type ResendConfirmPhoneActionSmsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  captcha: Scalars['String'];
  token: Scalars['String'];
};

export type ResendConfirmPhoneActionSmsOutput = {
  __typename?: 'ResendConfirmPhoneActionSmsOutput';
  status: Scalars['String'];
};

export type ResendMessageInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  message: MessageWhereUniqueInput;
};

export type ResendMessageOutput = {
  __typename?: 'ResendMessageOutput';
  status: Scalars['String'];
  id: Scalars['String'];
};

export type ResetOrganizationInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  organizationId: Scalars['ID'];
};

export type ResetOrganizationOutput = {
  __typename?: 'ResetOrganizationOutput';
  status: Scalars['String'];
};

export type ResetUserInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  user: UserWhereUniqueInput;
  saveName?: Maybe<Scalars['Boolean']>;
};

export type ResetUserOutput = {
  __typename?: 'ResetUserOutput';
  status: Scalars['String'];
};

/**  Person, that resides in a specified property and unit  */
export type Resident = {
  __typename?: 'Resident';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Resident List config, or
   *  2. As an alias to the field set on 'labelField' in the Resident List config, or
   *  3. As an alias to a 'name' field on the Resident List (if one exists), or
   *  4. As an alias to the 'id' field on the Resident List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Mobile user account  */
  user?: Maybe<User>;
  /**  Organization data, that is returned for current resident in mobile client  */
  residentOrganization?: Maybe<ResidentOrganization>;
  /**  Property, in which this person resides. Can be missing, when a resident has been registered, but there is no Property in our system yet  */
  property?: Maybe<Property>;
  /**  Property data, that is returned for current resident in mobile client  */
  residentProperty?: Maybe<ResidentProperty>;
  /**  Contains features that are enabled for user organization  */
  organizationFeatures?: Maybe<OrganizationFeatures>;
  /**  Contains billing and acquiring integration names that are enabled for this resident per category  */
  paymentCategories?: Maybe<Array<Maybe<PaymentCategory>>>;
  /**  Unit of the property, in which this person resides  */
  unitName?: Maybe<Scalars['String']>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitType?: Maybe<ResidentUnitTypeType>;
  isVerifiedByManagingCompany?: Maybe<Scalars['Boolean']>;
  managingCompanyContactRole?: Maybe<ResidentContactRole>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  /**  Normalized address  */
  address?: Maybe<Scalars['String']>;
  /**  The unique key of the address  */
  addressKey?: Maybe<Scalars['String']>;
  /**  Property address components  */
  addressMeta?: Maybe<AddressMetaField>;
  /**  The origins of the address (some strings which may looks like real address or some id)  */
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ResidentAcquiringIntegrationContext = {
  __typename?: 'ResidentAcquiringIntegrationContext';
  id: Scalars['ID'];
  integration?: Maybe<AcquiringIntegration>;
};

export type ResidentBillingAccount = {
  __typename?: 'ResidentBillingAccount';
  id: Scalars['ID'];
};

export type ResidentBillingReceiptFile = {
  __typename?: 'ResidentBillingReceiptFile';
  file?: Maybe<File>;
  controlSum?: Maybe<Scalars['String']>;
};

export type ResidentBillingReceiptOutput = {
  __typename?: 'ResidentBillingReceiptOutput';
  dv: Scalars['String'];
  recipient: BillingReceiptsRecipientField;
  id: Scalars['ID'];
  period: Scalars['String'];
  toPay: Scalars['String'];
  paid: Scalars['String'];
  explicitFee: Scalars['String'];
  printableNumber?: Maybe<Scalars['String']>;
  toPayDetails?: Maybe<BillingReceiptToPayDetailsField>;
  services?: Maybe<Array<BillingReceiptServiceField>>;
  serviceConsumer: ServiceConsumer;
  currencyCode: Scalars['String'];
  category: BillingCategory;
  isPayable: Scalars['Boolean'];
  file?: Maybe<ResidentBillingReceiptFile>;
};

export type ResidentBillingReceiptWhereInput = {
  AND?: Maybe<Array<Maybe<ResidentBillingReceiptWhereInput>>>;
  OR?: Maybe<Array<Maybe<ResidentBillingReceiptWhereInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_contains?: Maybe<Scalars['String']>;
  period_not_contains?: Maybe<Scalars['String']>;
  period_starts_with?: Maybe<Scalars['String']>;
  period_not_starts_with?: Maybe<Scalars['String']>;
  period_ends_with?: Maybe<Scalars['String']>;
  period_not_ends_with?: Maybe<Scalars['String']>;
  period_i?: Maybe<Scalars['String']>;
  period_not_i?: Maybe<Scalars['String']>;
  period_contains_i?: Maybe<Scalars['String']>;
  period_not_contains_i?: Maybe<Scalars['String']>;
  period_starts_with_i?: Maybe<Scalars['String']>;
  period_not_starts_with_i?: Maybe<Scalars['String']>;
  period_ends_with_i?: Maybe<Scalars['String']>;
  period_not_ends_with_i?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  toPay_not?: Maybe<Scalars['String']>;
  toPay_contains?: Maybe<Scalars['String']>;
  toPay_not_contains?: Maybe<Scalars['String']>;
  toPay_starts_with?: Maybe<Scalars['String']>;
  toPay_not_starts_with?: Maybe<Scalars['String']>;
  toPay_ends_with?: Maybe<Scalars['String']>;
  toPay_not_ends_with?: Maybe<Scalars['String']>;
  toPay_i?: Maybe<Scalars['String']>;
  toPay_not_i?: Maybe<Scalars['String']>;
  toPay_contains_i?: Maybe<Scalars['String']>;
  toPay_not_contains_i?: Maybe<Scalars['String']>;
  toPay_starts_with_i?: Maybe<Scalars['String']>;
  toPay_not_starts_with_i?: Maybe<Scalars['String']>;
  toPay_ends_with_i?: Maybe<Scalars['String']>;
  toPay_not_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber?: Maybe<Scalars['String']>;
  printableNumber_not?: Maybe<Scalars['String']>;
  printableNumber_contains?: Maybe<Scalars['String']>;
  printableNumber_not_contains?: Maybe<Scalars['String']>;
  printableNumber_starts_with?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with?: Maybe<Scalars['String']>;
  printableNumber_ends_with?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with?: Maybe<Scalars['String']>;
  printableNumber_i?: Maybe<Scalars['String']>;
  printableNumber_not_i?: Maybe<Scalars['String']>;
  printableNumber_contains_i?: Maybe<Scalars['String']>;
  printableNumber_not_contains_i?: Maybe<Scalars['String']>;
  printableNumber_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  serviceConsumer?: Maybe<ServiceConsumerWhereInput>;
  serviceConsumer_is_null?: Maybe<Scalars['Boolean']>;
};

export type ResidentBillingVirtualReceiptWhereInput = {
  AND?: Maybe<Array<Maybe<ResidentBillingVirtualReceiptWhereInput>>>;
  OR?: Maybe<Array<Maybe<ResidentBillingVirtualReceiptWhereInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  period?: Maybe<Scalars['String']>;
  period_not?: Maybe<Scalars['String']>;
  period_contains?: Maybe<Scalars['String']>;
  period_not_contains?: Maybe<Scalars['String']>;
  period_starts_with?: Maybe<Scalars['String']>;
  period_not_starts_with?: Maybe<Scalars['String']>;
  period_ends_with?: Maybe<Scalars['String']>;
  period_not_ends_with?: Maybe<Scalars['String']>;
  period_i?: Maybe<Scalars['String']>;
  period_not_i?: Maybe<Scalars['String']>;
  period_contains_i?: Maybe<Scalars['String']>;
  period_not_contains_i?: Maybe<Scalars['String']>;
  period_starts_with_i?: Maybe<Scalars['String']>;
  period_not_starts_with_i?: Maybe<Scalars['String']>;
  period_ends_with_i?: Maybe<Scalars['String']>;
  period_not_ends_with_i?: Maybe<Scalars['String']>;
  toPay?: Maybe<Scalars['String']>;
  toPay_not?: Maybe<Scalars['String']>;
  toPay_contains?: Maybe<Scalars['String']>;
  toPay_not_contains?: Maybe<Scalars['String']>;
  toPay_starts_with?: Maybe<Scalars['String']>;
  toPay_not_starts_with?: Maybe<Scalars['String']>;
  toPay_ends_with?: Maybe<Scalars['String']>;
  toPay_not_ends_with?: Maybe<Scalars['String']>;
  toPay_i?: Maybe<Scalars['String']>;
  toPay_not_i?: Maybe<Scalars['String']>;
  toPay_contains_i?: Maybe<Scalars['String']>;
  toPay_not_contains_i?: Maybe<Scalars['String']>;
  toPay_starts_with_i?: Maybe<Scalars['String']>;
  toPay_not_starts_with_i?: Maybe<Scalars['String']>;
  toPay_ends_with_i?: Maybe<Scalars['String']>;
  toPay_not_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber?: Maybe<Scalars['String']>;
  printableNumber_not?: Maybe<Scalars['String']>;
  printableNumber_contains?: Maybe<Scalars['String']>;
  printableNumber_not_contains?: Maybe<Scalars['String']>;
  printableNumber_starts_with?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with?: Maybe<Scalars['String']>;
  printableNumber_ends_with?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with?: Maybe<Scalars['String']>;
  printableNumber_i?: Maybe<Scalars['String']>;
  printableNumber_not_i?: Maybe<Scalars['String']>;
  printableNumber_contains_i?: Maybe<Scalars['String']>;
  printableNumber_not_contains_i?: Maybe<Scalars['String']>;
  printableNumber_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  printableNumber_ends_with_i?: Maybe<Scalars['String']>;
  printableNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  serviceConsumer?: Maybe<ServiceConsumerWhereInput>;
  serviceConsumer_is_null?: Maybe<Scalars['Boolean']>;
};

export type ResidentContactRole = {
  __typename?: 'ResidentContactRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization?: Maybe<Scalars['ID']>;
};

export type ResidentCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<ResidentUnitTypeType>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ResidentGroupedCounter = {
  __typename?: 'ResidentGroupedCounter';
  count: Scalars['String'];
  address: Scalars['String'];
};

/**  A keystone list  */
export type ResidentHistoryRecord = {
  __typename?: 'ResidentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ResidentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ResidentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ResidentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ResidentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ResidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ResidentHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ResidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ResidentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ResidentHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ResidentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ResidentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ResidentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ResidentHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ResidentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ResidentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ResidentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ResidentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ResidentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ResidentHistoryRecordsCreateInput = {
  data?: Maybe<ResidentHistoryRecordCreateInput>;
};

export type ResidentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ResidentHistoryRecordUpdateInput>;
};

export type ResidentOverviewResult = {
  __typename?: 'ResidentOverviewResult';
  residents?: Maybe<Array<ResidentGroupedCounter>>;
};

export type ResidentProperty = {
  __typename?: 'ResidentProperty';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  addressKey?: Maybe<Scalars['String']>;
};

export type ResidentRelateToOneInput = {
  create?: Maybe<ResidentCreateInput>;
  connect?: Maybe<ResidentWhereUniqueInput>;
  disconnect?: Maybe<ResidentWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type ResidentScopesInput = {
  property: PropertyWhereUniqueInput;
  units?: Maybe<Array<Maybe<PropertyUnitInput>>>;
  billingAccountNumbers?: Maybe<Array<Maybe<Scalars['ID']>>>;
  skipUnits?: Maybe<Array<Maybe<PropertyUnitInput>>>;
  skipBillingAccountNumbers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum ResidentUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type ResidentUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<ResidentUnitTypeType>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  address?: Maybe<Scalars['String']>;
  addressKey?: Maybe<Scalars['String']>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressSources?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ResidentWhereInput = {
  AND?: Maybe<Array<Maybe<ResidentWhereInput>>>;
  OR?: Maybe<Array<Maybe<ResidentWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<ResidentUnitTypeType>;
  unitType_not?: Maybe<ResidentUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<ResidentUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<ResidentUnitTypeType>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  address?: Maybe<Scalars['String']>;
  address_not?: Maybe<Scalars['String']>;
  address_contains?: Maybe<Scalars['String']>;
  address_not_contains?: Maybe<Scalars['String']>;
  address_starts_with?: Maybe<Scalars['String']>;
  address_not_starts_with?: Maybe<Scalars['String']>;
  address_ends_with?: Maybe<Scalars['String']>;
  address_not_ends_with?: Maybe<Scalars['String']>;
  address_i?: Maybe<Scalars['String']>;
  address_not_i?: Maybe<Scalars['String']>;
  address_contains_i?: Maybe<Scalars['String']>;
  address_not_contains_i?: Maybe<Scalars['String']>;
  address_starts_with_i?: Maybe<Scalars['String']>;
  address_not_starts_with_i?: Maybe<Scalars['String']>;
  address_ends_with_i?: Maybe<Scalars['String']>;
  address_not_ends_with_i?: Maybe<Scalars['String']>;
  address_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  address_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey?: Maybe<Scalars['String']>;
  addressKey_not?: Maybe<Scalars['String']>;
  addressKey_contains?: Maybe<Scalars['String']>;
  addressKey_not_contains?: Maybe<Scalars['String']>;
  addressKey_starts_with?: Maybe<Scalars['String']>;
  addressKey_not_starts_with?: Maybe<Scalars['String']>;
  addressKey_ends_with?: Maybe<Scalars['String']>;
  addressKey_not_ends_with?: Maybe<Scalars['String']>;
  addressKey_i?: Maybe<Scalars['String']>;
  addressKey_not_i?: Maybe<Scalars['String']>;
  addressKey_contains_i?: Maybe<Scalars['String']>;
  addressKey_not_contains_i?: Maybe<Scalars['String']>;
  addressKey_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_not_starts_with_i?: Maybe<Scalars['String']>;
  addressKey_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_not_ends_with_i?: Maybe<Scalars['String']>;
  addressKey_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressKey_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  addressMeta?: Maybe<Scalars['JSON']>;
  addressMeta_not?: Maybe<Scalars['JSON']>;
  addressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources?: Maybe<Scalars['JSON']>;
  addressSources_not?: Maybe<Scalars['JSON']>;
  addressSources_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  addressSources_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ResidentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ResidentsCreateInput = {
  data?: Maybe<ResidentCreateInput>;
};

export type ResidentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ResidentUpdateInput>;
};

export type SendB2CAppPushMessageData = {
  body: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  B2CAppContext?: Maybe<Scalars['String']>;
  callId?: Maybe<Scalars['String']>;
  voipType?: Maybe<Scalars['String']>;
  voipAddress?: Maybe<Scalars['String']>;
  voipLogin?: Maybe<Scalars['String']>;
  voipPassword?: Maybe<Scalars['String']>;
  voipDtfmCommand?: Maybe<Scalars['String']>;
  stun?: Maybe<Scalars['String']>;
  codec?: Maybe<Scalars['String']>;
};

export type SendB2CAppPushMessageInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  app: B2CAppWhereUniqueInput;
  user: UserWhereUniqueInput;
  resident: ResidentWhereUniqueInput;
  type: SendB2CAppPushMessageType;
  data: SendB2CAppPushMessageData;
};

export type SendB2CAppPushMessageOutput = {
  __typename?: 'SendB2CAppPushMessageOutput';
  id: Scalars['String'];
  status: Scalars['String'];
};

export enum SendB2CAppPushMessageType {
  VoipIncomingCallMessage = 'VOIP_INCOMING_CALL_MESSAGE',
  B2CAppMessagePush = 'B2C_APP_MESSAGE_PUSH'
}

export type SendMessageInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  to: SendMessageToInput;
  emailFrom?: Maybe<Scalars['String']>;
  type: MessageType;
  lang: SendMessageLang;
  meta: Scalars['JSON'];
  organization?: Maybe<OrganizationWhereUniqueInput>;
  uniqKey?: Maybe<Scalars['String']>;
};

export enum SendMessageLang {
  Ru = 'ru',
  En = 'en'
}

export type SendMessageOutput = {
  __typename?: 'SendMessageOutput';
  status: Scalars['String'];
  id: Scalars['String'];
  isDuplicateMessage?: Maybe<Scalars['Boolean']>;
};

export type SendMessageToInput = {
  user?: Maybe<UserWhereUniqueInput>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  remoteClient?: Maybe<RemoteClientWhereInput>;
};

export type SendMessageToResidentScopesServiceInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  type: MessageType;
  lang?: Maybe<SendMessageLang>;
  uniqKeyTemplate?: Maybe<Scalars['String']>;
  scopes: Array<ResidentScopesInput>;
  meta?: Maybe<Scalars['JSON']>;
};

export type SendMessageToResidentScopesServiceOutput = {
  __typename?: 'SendMessageToResidentScopesServiceOutput';
  status: Scalars['String'];
};

export type SendMessageToSupportInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  text: Scalars['String'];
  emailFrom?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  os: Scalars['String'];
  appVersion: Scalars['String'];
  lang: SendMessageToSupportLang;
  meta: Scalars['JSON'];
};

export enum SendMessageToSupportLang {
  Ru = 'ru',
  En = 'en'
}

export type SendMessageToSupportOutput = {
  __typename?: 'SendMessageToSupportOutput';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type SendNewBillingReceiptFilesNotificationsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
  createdAfter: Scalars['String'];
  period: Scalars['String'];
};

export type SendNewBillingReceiptFilesNotificationsOutput = {
  __typename?: 'SendNewBillingReceiptFilesNotificationsOutput';
  taskStarted: Scalars['Boolean'];
};

export type SendNewReceiptMessagesToResidentScopesInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  context: BillingIntegrationOrganizationContextWhereUniqueInput;
  category: BillingCategoryWhereUniqueInput;
  period: Scalars['String'];
  lang?: Maybe<SendMessageLang>;
  scopes: Array<Maybe<PropertyScopesInput>>;
  meta?: Maybe<Scalars['JSON']>;
};

export type SendNewReceiptMessagesToResidentScopesOutput = {
  __typename?: 'SendNewReceiptMessagesToResidentScopesOutput';
  status: Scalars['String'];
};

export type SenderField = {
  __typename?: 'SenderField';
  dv: Scalars['Int'];
  fingerprint: Scalars['String'];
};

export type SenderFieldInput = {
  dv: Scalars['Int'];
  fingerprint: Scalars['String'];
};

/**  Service Consumer object. Existence of this object means that the resident is willing to pay for certain services  */
export type ServiceConsumer = {
  __typename?: 'ServiceConsumer';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceConsumer List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceConsumer List config, or
   *  3. As an alias to a 'name' field on the ServiceConsumer List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceConsumer List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  A payment category for this resident  */
  paymentCategory?: Maybe<Scalars['String']>;
  /**  Resident object  */
  resident?: Maybe<Resident>;
  /**  [DEPRECATED] BillingAccount id, that is returned for current serviceConsumer in mobile client  */
  residentBillingAccount?: Maybe<ResidentBillingAccount>;
  /**  [DEPRECATED] Billing integration context, that this serviceConsumer is connected to  */
  billingIntegrationContext?: Maybe<BillingIntegrationOrganizationContext>;
  /**  Acquiring integration context, that this serviceConsumer is connected to  */
  acquiringIntegrationContext?: Maybe<AcquiringIntegrationContext>;
  /**  AcquiringIntegration, that is returned for current serviceConsumer in mobile client  */
  residentAcquiringIntegrationContext?: Maybe<ResidentAcquiringIntegrationContext>;
  /**  Account number taken from resident. This is what resident think his account number is  */
  accountNumber?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Organization data, that is returned for current resident in mobile client  */
  residentOrganization?: Maybe<ResidentOrganization>;
  /**  Determines if the resident created this service consumer providing the account number, or it was created automatically based on the house address and unit name with unit type  */
  isDiscovered?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ServiceConsumerCreateInput = {
  paymentCategory?: Maybe<Scalars['String']>;
  resident?: Maybe<ResidentRelateToOneInput>;
  billingIntegrationContext?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  acquiringIntegrationContext?: Maybe<AcquiringIntegrationContextRelateToOneInput>;
  accountNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type ServiceConsumerHistoryRecord = {
  __typename?: 'ServiceConsumerHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceConsumerHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceConsumerHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ServiceConsumerHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceConsumerHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  paymentCategory?: Maybe<Scalars['String']>;
  resident?: Maybe<Scalars['String']>;
  billingIntegrationContext?: Maybe<Scalars['String']>;
  acquiringIntegrationContext?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ServiceConsumerHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ServiceConsumerHistoryRecordCreateInput = {
  paymentCategory?: Maybe<Scalars['String']>;
  resident?: Maybe<Scalars['String']>;
  billingIntegrationContext?: Maybe<Scalars['String']>;
  acquiringIntegrationContext?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ServiceConsumerHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ServiceConsumerHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ServiceConsumerHistoryRecordUpdateInput = {
  paymentCategory?: Maybe<Scalars['String']>;
  resident?: Maybe<Scalars['String']>;
  billingIntegrationContext?: Maybe<Scalars['String']>;
  acquiringIntegrationContext?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ServiceConsumerHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ServiceConsumerHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ServiceConsumerHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ServiceConsumerHistoryRecordWhereInput>>>;
  paymentCategory?: Maybe<Scalars['String']>;
  paymentCategory_not?: Maybe<Scalars['String']>;
  paymentCategory_contains?: Maybe<Scalars['String']>;
  paymentCategory_not_contains?: Maybe<Scalars['String']>;
  paymentCategory_starts_with?: Maybe<Scalars['String']>;
  paymentCategory_not_starts_with?: Maybe<Scalars['String']>;
  paymentCategory_ends_with?: Maybe<Scalars['String']>;
  paymentCategory_not_ends_with?: Maybe<Scalars['String']>;
  paymentCategory_i?: Maybe<Scalars['String']>;
  paymentCategory_not_i?: Maybe<Scalars['String']>;
  paymentCategory_contains_i?: Maybe<Scalars['String']>;
  paymentCategory_not_contains_i?: Maybe<Scalars['String']>;
  paymentCategory_starts_with_i?: Maybe<Scalars['String']>;
  paymentCategory_not_starts_with_i?: Maybe<Scalars['String']>;
  paymentCategory_ends_with_i?: Maybe<Scalars['String']>;
  paymentCategory_not_ends_with_i?: Maybe<Scalars['String']>;
  paymentCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resident?: Maybe<Scalars['String']>;
  resident_not?: Maybe<Scalars['String']>;
  resident_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resident_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingIntegrationContext?: Maybe<Scalars['String']>;
  billingIntegrationContext_not?: Maybe<Scalars['String']>;
  billingIntegrationContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingIntegrationContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  acquiringIntegrationContext?: Maybe<Scalars['String']>;
  acquiringIntegrationContext_not?: Maybe<Scalars['String']>;
  acquiringIntegrationContext_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  acquiringIntegrationContext_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  isDiscovered_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ServiceConsumerHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ServiceConsumerHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ServiceConsumerHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ServiceConsumerHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ServiceConsumerHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ServiceConsumerHistoryRecordsCreateInput = {
  data?: Maybe<ServiceConsumerHistoryRecordCreateInput>;
};

export type ServiceConsumerHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ServiceConsumerHistoryRecordUpdateInput>;
};

export type ServiceConsumerRelateToOneInput = {
  create?: Maybe<ServiceConsumerCreateInput>;
  connect?: Maybe<ServiceConsumerWhereUniqueInput>;
  disconnect?: Maybe<ServiceConsumerWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type ServiceConsumerUpdateInput = {
  paymentCategory?: Maybe<Scalars['String']>;
  resident?: Maybe<ResidentRelateToOneInput>;
  billingIntegrationContext?: Maybe<BillingIntegrationOrganizationContextRelateToOneInput>;
  acquiringIntegrationContext?: Maybe<AcquiringIntegrationContextRelateToOneInput>;
  accountNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ServiceConsumerWhereInput = {
  AND?: Maybe<Array<Maybe<ServiceConsumerWhereInput>>>;
  OR?: Maybe<Array<Maybe<ServiceConsumerWhereInput>>>;
  paymentCategory?: Maybe<Scalars['String']>;
  paymentCategory_not?: Maybe<Scalars['String']>;
  paymentCategory_contains?: Maybe<Scalars['String']>;
  paymentCategory_not_contains?: Maybe<Scalars['String']>;
  paymentCategory_starts_with?: Maybe<Scalars['String']>;
  paymentCategory_not_starts_with?: Maybe<Scalars['String']>;
  paymentCategory_ends_with?: Maybe<Scalars['String']>;
  paymentCategory_not_ends_with?: Maybe<Scalars['String']>;
  paymentCategory_i?: Maybe<Scalars['String']>;
  paymentCategory_not_i?: Maybe<Scalars['String']>;
  paymentCategory_contains_i?: Maybe<Scalars['String']>;
  paymentCategory_not_contains_i?: Maybe<Scalars['String']>;
  paymentCategory_starts_with_i?: Maybe<Scalars['String']>;
  paymentCategory_not_starts_with_i?: Maybe<Scalars['String']>;
  paymentCategory_ends_with_i?: Maybe<Scalars['String']>;
  paymentCategory_not_ends_with_i?: Maybe<Scalars['String']>;
  paymentCategory_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paymentCategory_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  resident?: Maybe<ResidentWhereInput>;
  resident_is_null?: Maybe<Scalars['Boolean']>;
  billingIntegrationContext?: Maybe<BillingIntegrationOrganizationContextWhereInput>;
  billingIntegrationContext_is_null?: Maybe<Scalars['Boolean']>;
  acquiringIntegrationContext?: Maybe<AcquiringIntegrationContextWhereInput>;
  acquiringIntegrationContext_is_null?: Maybe<Scalars['Boolean']>;
  accountNumber?: Maybe<Scalars['String']>;
  accountNumber_not?: Maybe<Scalars['String']>;
  accountNumber_contains?: Maybe<Scalars['String']>;
  accountNumber_not_contains?: Maybe<Scalars['String']>;
  accountNumber_starts_with?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with?: Maybe<Scalars['String']>;
  accountNumber_ends_with?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with?: Maybe<Scalars['String']>;
  accountNumber_i?: Maybe<Scalars['String']>;
  accountNumber_not_i?: Maybe<Scalars['String']>;
  accountNumber_contains_i?: Maybe<Scalars['String']>;
  accountNumber_not_contains_i?: Maybe<Scalars['String']>;
  accountNumber_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_starts_with_i?: Maybe<Scalars['String']>;
  accountNumber_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_not_ends_with_i?: Maybe<Scalars['String']>;
  accountNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  accountNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  isDiscovered?: Maybe<Scalars['Boolean']>;
  isDiscovered_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ServiceConsumerWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ServiceConsumersCreateInput = {
  data?: Maybe<ServiceConsumerCreateInput>;
};

export type ServiceConsumersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ServiceConsumerUpdateInput>;
};

/**  Availability time period of service features for client organization. Can be trial or payed.  */
export type ServiceSubscription = {
  __typename?: 'ServiceSubscription';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceSubscription List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceSubscription List config, or
   *  3. As an alias to a 'name' field on the ServiceSubscription List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceSubscription List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  System, from where subscription was created (our or external)  */
  type?: Maybe<ServiceSubscriptionTypeType>;
  /**  Trial mode of subscription  */
  isTrial?: Maybe<Scalars['Boolean']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  When subscription was started  */
  startAt?: Maybe<Scalars['String']>;
  /**  When subscription should be ended  */
  finishAt?: Maybe<Scalars['String']>;
  /**  How much units are payed for this subscription  */
  unitsCount?: Maybe<Scalars['Int']>;
  /**  How much one unit cost in Rubles for this subscription  */
  unitPrice?: Maybe<Scalars['String']>;
  /**  Total price of this subscription, calculated as unitCost * unitsPayed  */
  totalPrice?: Maybe<Scalars['String']>;
  /**  Currency of values for all price fields  */
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type ServiceSubscriptionCreateInput = {
  type?: Maybe<ServiceSubscriptionTypeType>;
  isTrial?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  startAt?: Maybe<Scalars['String']>;
  finishAt?: Maybe<Scalars['String']>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type ServiceSubscriptionHistoryRecord = {
  __typename?: 'ServiceSubscriptionHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the ServiceSubscriptionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the ServiceSubscriptionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the ServiceSubscriptionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the ServiceSubscriptionHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  finishAt?: Maybe<Scalars['String']>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ServiceSubscriptionHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  finishAt?: Maybe<Scalars['String']>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum ServiceSubscriptionHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type ServiceSubscriptionHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  isTrial?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['String']>;
  finishAt?: Maybe<Scalars['String']>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type ServiceSubscriptionHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isTrial?: Maybe<Scalars['Boolean']>;
  isTrial_not?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startAt?: Maybe<Scalars['String']>;
  startAt_not?: Maybe<Scalars['String']>;
  startAt_lt?: Maybe<Scalars['String']>;
  startAt_lte?: Maybe<Scalars['String']>;
  startAt_gt?: Maybe<Scalars['String']>;
  startAt_gte?: Maybe<Scalars['String']>;
  startAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  finishAt?: Maybe<Scalars['String']>;
  finishAt_not?: Maybe<Scalars['String']>;
  finishAt_lt?: Maybe<Scalars['String']>;
  finishAt_lte?: Maybe<Scalars['String']>;
  finishAt_gt?: Maybe<Scalars['String']>;
  finishAt_gte?: Maybe<Scalars['String']>;
  finishAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  finishAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitsCount_not?: Maybe<Scalars['Int']>;
  unitsCount_lt?: Maybe<Scalars['Int']>;
  unitsCount_lte?: Maybe<Scalars['Int']>;
  unitsCount_gt?: Maybe<Scalars['Int']>;
  unitsCount_gte?: Maybe<Scalars['Int']>;
  unitsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unitsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unitPrice?: Maybe<Scalars['String']>;
  unitPrice_not?: Maybe<Scalars['String']>;
  unitPrice_lt?: Maybe<Scalars['String']>;
  unitPrice_lte?: Maybe<Scalars['String']>;
  unitPrice_gt?: Maybe<Scalars['String']>;
  unitPrice_gte?: Maybe<Scalars['String']>;
  unitPrice_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitPrice_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalPrice?: Maybe<Scalars['String']>;
  totalPrice_not?: Maybe<Scalars['String']>;
  totalPrice_lt?: Maybe<Scalars['String']>;
  totalPrice_lte?: Maybe<Scalars['String']>;
  totalPrice_gt?: Maybe<Scalars['String']>;
  totalPrice_gte?: Maybe<Scalars['String']>;
  totalPrice_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalPrice_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency?: Maybe<Scalars['String']>;
  currency_not?: Maybe<Scalars['String']>;
  currency_contains?: Maybe<Scalars['String']>;
  currency_not_contains?: Maybe<Scalars['String']>;
  currency_starts_with?: Maybe<Scalars['String']>;
  currency_not_starts_with?: Maybe<Scalars['String']>;
  currency_ends_with?: Maybe<Scalars['String']>;
  currency_not_ends_with?: Maybe<Scalars['String']>;
  currency_i?: Maybe<Scalars['String']>;
  currency_not_i?: Maybe<Scalars['String']>;
  currency_contains_i?: Maybe<Scalars['String']>;
  currency_not_contains_i?: Maybe<Scalars['String']>;
  currency_starts_with_i?: Maybe<Scalars['String']>;
  currency_not_starts_with_i?: Maybe<Scalars['String']>;
  currency_ends_with_i?: Maybe<Scalars['String']>;
  currency_not_ends_with_i?: Maybe<Scalars['String']>;
  currency_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<ServiceSubscriptionHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ServiceSubscriptionHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ServiceSubscriptionHistoryRecordsCreateInput = {
  data?: Maybe<ServiceSubscriptionHistoryRecordCreateInput>;
};

export type ServiceSubscriptionHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ServiceSubscriptionHistoryRecordUpdateInput>;
};

export enum ServiceSubscriptionTypeType {
  Default = 'default',
  Sbbol = 'sbbol'
}

export type ServiceSubscriptionUpdateInput = {
  type?: Maybe<ServiceSubscriptionTypeType>;
  isTrial?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  startAt?: Maybe<Scalars['String']>;
  finishAt?: Maybe<Scalars['String']>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['String']>;
  totalPrice?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type ServiceSubscriptionWhereInput = {
  AND?: Maybe<Array<Maybe<ServiceSubscriptionWhereInput>>>;
  OR?: Maybe<Array<Maybe<ServiceSubscriptionWhereInput>>>;
  type?: Maybe<ServiceSubscriptionTypeType>;
  type_not?: Maybe<ServiceSubscriptionTypeType>;
  type_in?: Maybe<Array<Maybe<ServiceSubscriptionTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<ServiceSubscriptionTypeType>>>;
  isTrial?: Maybe<Scalars['Boolean']>;
  isTrial_not?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  startAt?: Maybe<Scalars['String']>;
  startAt_not?: Maybe<Scalars['String']>;
  startAt_lt?: Maybe<Scalars['String']>;
  startAt_lte?: Maybe<Scalars['String']>;
  startAt_gt?: Maybe<Scalars['String']>;
  startAt_gte?: Maybe<Scalars['String']>;
  startAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  startAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  finishAt?: Maybe<Scalars['String']>;
  finishAt_not?: Maybe<Scalars['String']>;
  finishAt_lt?: Maybe<Scalars['String']>;
  finishAt_lte?: Maybe<Scalars['String']>;
  finishAt_gt?: Maybe<Scalars['String']>;
  finishAt_gte?: Maybe<Scalars['String']>;
  finishAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  finishAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitsCount?: Maybe<Scalars['Int']>;
  unitsCount_not?: Maybe<Scalars['Int']>;
  unitsCount_lt?: Maybe<Scalars['Int']>;
  unitsCount_lte?: Maybe<Scalars['Int']>;
  unitsCount_gt?: Maybe<Scalars['Int']>;
  unitsCount_gte?: Maybe<Scalars['Int']>;
  unitsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unitsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  unitPrice?: Maybe<Scalars['String']>;
  unitPrice_not?: Maybe<Scalars['String']>;
  unitPrice_lt?: Maybe<Scalars['String']>;
  unitPrice_lte?: Maybe<Scalars['String']>;
  unitPrice_gt?: Maybe<Scalars['String']>;
  unitPrice_gte?: Maybe<Scalars['String']>;
  unitPrice_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitPrice_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalPrice?: Maybe<Scalars['String']>;
  totalPrice_not?: Maybe<Scalars['String']>;
  totalPrice_lt?: Maybe<Scalars['String']>;
  totalPrice_lte?: Maybe<Scalars['String']>;
  totalPrice_gt?: Maybe<Scalars['String']>;
  totalPrice_gte?: Maybe<Scalars['String']>;
  totalPrice_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalPrice_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency?: Maybe<Scalars['String']>;
  currency_not?: Maybe<Scalars['String']>;
  currency_contains?: Maybe<Scalars['String']>;
  currency_not_contains?: Maybe<Scalars['String']>;
  currency_starts_with?: Maybe<Scalars['String']>;
  currency_not_starts_with?: Maybe<Scalars['String']>;
  currency_ends_with?: Maybe<Scalars['String']>;
  currency_not_ends_with?: Maybe<Scalars['String']>;
  currency_i?: Maybe<Scalars['String']>;
  currency_not_i?: Maybe<Scalars['String']>;
  currency_contains_i?: Maybe<Scalars['String']>;
  currency_not_contains_i?: Maybe<Scalars['String']>;
  currency_starts_with_i?: Maybe<Scalars['String']>;
  currency_not_starts_with_i?: Maybe<Scalars['String']>;
  currency_ends_with_i?: Maybe<Scalars['String']>;
  currency_not_ends_with_i?: Maybe<Scalars['String']>;
  currency_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  currency_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type ServiceSubscriptionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type ServiceSubscriptionsCreateInput = {
  data?: Maybe<ServiceSubscriptionCreateInput>;
};

export type ServiceSubscriptionsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<ServiceSubscriptionUpdateInput>;
};

export type SetMessageStatusInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  message: MessageWhereUniqueInput;
  deliveredAt?: Maybe<Scalars['String']>;
  readAt?: Maybe<Scalars['String']>;
};

export type SetMessageStatusOutput = {
  __typename?: 'SetMessageStatusOutput';
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type SettingsField = {
  __typename?: 'SettingsField';
  cardId: Scalars['String'];
};

export type SettingsFieldInput = {
  cardId: Scalars['String'];
};

export type ShareTicketInput = {
  sender: SenderFieldInput;
  employees: Array<Scalars['ID']>;
  ticketId: Scalars['ID'];
};

export type ShareTicketOutput = {
  __typename?: 'ShareTicketOutput';
  status: Scalars['String'];
};

export type ShortScopesField = {
  __typename?: 'ShortScopesField';
  count: Scalars['Int'];
  firstOnes: Array<Maybe<NewsItemScope>>;
};

export type SigninAsUserInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  id: Scalars['ID'];
};

export type SigninAsUserOutput = {
  __typename?: 'SigninAsUserOutput';
  user?: Maybe<User>;
  token: Scalars['String'];
};

export type SigninResidentUserInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  token: Scalars['String'];
};

export type SigninResidentUserOutput = {
  __typename?: 'SigninResidentUserOutput';
  user?: Maybe<User>;
  token: Scalars['String'];
};

export enum SortAcquiringIntegrationAccessRightHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortAcquiringIntegrationAccessRightsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortAcquiringIntegrationContextHistoryRecordsBy {
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  InvoiceReasonAsc = 'invoiceReason_ASC',
  InvoiceReasonDesc = 'invoiceReason_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  InvoiceEmailsAsc = 'invoiceEmails_ASC',
  InvoiceEmailsDesc = 'invoiceEmails_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  InvoiceStatusAsc = 'invoiceStatus_ASC',
  InvoiceStatusDesc = 'invoiceStatus_DESC',
  InvoiceTaxRegimeAsc = 'invoiceTaxRegime_ASC',
  InvoiceTaxRegimeDesc = 'invoiceTaxRegime_DESC',
  InvoiceVatPercentAsc = 'invoiceVatPercent_ASC',
  InvoiceVatPercentDesc = 'invoiceVatPercent_DESC',
  InvoiceSalesTaxPercentAsc = 'invoiceSalesTaxPercent_ASC',
  InvoiceSalesTaxPercentDesc = 'invoiceSalesTaxPercent_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortAcquiringIntegrationContextsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  InvoiceReasonAsc = 'invoiceReason_ASC',
  InvoiceReasonDesc = 'invoiceReason_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  InvoiceEmailsAsc = 'invoiceEmails_ASC',
  InvoiceEmailsDesc = 'invoiceEmails_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  InvoiceStatusAsc = 'invoiceStatus_ASC',
  InvoiceStatusDesc = 'invoiceStatus_DESC',
  InvoiceTaxRegimeAsc = 'invoiceTaxRegime_ASC',
  InvoiceTaxRegimeDesc = 'invoiceTaxRegime_DESC',
  InvoiceVatPercentAsc = 'invoiceVatPercent_ASC',
  InvoiceVatPercentDesc = 'invoiceVatPercent_DESC',
  InvoiceSalesTaxPercentAsc = 'invoiceSalesTaxPercent_ASC',
  InvoiceSalesTaxPercentDesc = 'invoiceSalesTaxPercent_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortAcquiringIntegrationHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SetupUrlAsc = 'setupUrl_ASC',
  SetupUrlDesc = 'setupUrl_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  CanGroupReceiptsAsc = 'canGroupReceipts_ASC',
  CanGroupReceiptsDesc = 'canGroupReceipts_DESC',
  HostUrlAsc = 'hostUrl_ASC',
  HostUrlDesc = 'hostUrl_DESC',
  SupportedBillingIntegrationsGroupAsc = 'supportedBillingIntegrationsGroup_ASC',
  SupportedBillingIntegrationsGroupDesc = 'supportedBillingIntegrationsGroup_DESC',
  MinimumPaymentAmountAsc = 'minimumPaymentAmount_ASC',
  MinimumPaymentAmountDesc = 'minimumPaymentAmount_DESC',
  ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
  ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
  VatPercentOptionsAsc = 'vatPercentOptions_ASC',
  VatPercentOptionsDesc = 'vatPercentOptions_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortAcquiringIntegrationsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SetupUrlAsc = 'setupUrl_ASC',
  SetupUrlDesc = 'setupUrl_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  AccessRightsAsc = 'accessRights_ASC',
  AccessRightsDesc = 'accessRights_DESC',
  CanGroupReceiptsAsc = 'canGroupReceipts_ASC',
  CanGroupReceiptsDesc = 'canGroupReceipts_DESC',
  HostUrlAsc = 'hostUrl_ASC',
  HostUrlDesc = 'hostUrl_DESC',
  SupportedBillingIntegrationsGroupAsc = 'supportedBillingIntegrationsGroup_ASC',
  SupportedBillingIntegrationsGroupDesc = 'supportedBillingIntegrationsGroup_DESC',
  MinimumPaymentAmountAsc = 'minimumPaymentAmount_ASC',
  MinimumPaymentAmountDesc = 'minimumPaymentAmount_DESC',
  ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
  ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
  VatPercentOptionsAsc = 'vatPercentOptions_ASC',
  VatPercentOptionsDesc = 'vatPercentOptions_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortAllMiniAppsBy {
  DisplayPriorityDesc = 'displayPriority_DESC',
  ConnectedAtAsc = 'connectedAt_ASC'
}

export enum SortB2BAppAccessRightHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppAccessRightSetHistoryRecordsBy {
  CanReadContactsAsc = 'canReadContacts_ASC',
  CanReadContactsDesc = 'canReadContacts_DESC',
  CanManageContactsAsc = 'canManageContacts_ASC',
  CanManageContactsDesc = 'canManageContacts_DESC',
  CanReadMetersAsc = 'canReadMeters_ASC',
  CanReadMetersDesc = 'canReadMeters_DESC',
  CanManageMetersAsc = 'canManageMeters_ASC',
  CanManageMetersDesc = 'canManageMeters_DESC',
  CanReadMeterReadingsAsc = 'canReadMeterReadings_ASC',
  CanReadMeterReadingsDesc = 'canReadMeterReadings_DESC',
  CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
  CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
  CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
  CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
  CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
  CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
  CanReadPropertiesAsc = 'canReadProperties_ASC',
  CanReadPropertiesDesc = 'canReadProperties_DESC',
  CanManagePropertiesAsc = 'canManageProperties_ASC',
  CanManagePropertiesDesc = 'canManageProperties_DESC',
  CanReadTicketsAsc = 'canReadTickets_ASC',
  CanReadTicketsDesc = 'canReadTickets_DESC',
  CanManageTicketsAsc = 'canManageTickets_ASC',
  CanManageTicketsDesc = 'canManageTickets_DESC',
  CanReadTicketCommentsAsc = 'canReadTicketComments_ASC',
  CanReadTicketCommentsDesc = 'canReadTicketComments_DESC',
  CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
  CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
  CanReadTicketFilesAsc = 'canReadTicketFiles_ASC',
  CanReadTicketFilesDesc = 'canReadTicketFiles_DESC',
  CanManageTicketFilesAsc = 'canManageTicketFiles_ASC',
  CanManageTicketFilesDesc = 'canManageTicketFiles_DESC',
  CanReadTicketCommentFilesAsc = 'canReadTicketCommentFiles_ASC',
  CanReadTicketCommentFilesDesc = 'canReadTicketCommentFiles_DESC',
  CanManageTicketCommentFilesAsc = 'canManageTicketCommentFiles_ASC',
  CanManageTicketCommentFilesDesc = 'canManageTicketCommentFiles_DESC',
  CanExecuteRegisterMetersReadingsAsc = 'canExecuteRegisterMetersReadings_ASC',
  CanExecuteRegisterMetersReadingsDesc = 'canExecuteRegisterMetersReadings_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppAccessRightSetsBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  CanReadContactsAsc = 'canReadContacts_ASC',
  CanReadContactsDesc = 'canReadContacts_DESC',
  CanManageContactsAsc = 'canManageContacts_ASC',
  CanManageContactsDesc = 'canManageContacts_DESC',
  CanReadMetersAsc = 'canReadMeters_ASC',
  CanReadMetersDesc = 'canReadMeters_DESC',
  CanManageMetersAsc = 'canManageMeters_ASC',
  CanManageMetersDesc = 'canManageMeters_DESC',
  CanReadMeterReadingsAsc = 'canReadMeterReadings_ASC',
  CanReadMeterReadingsDesc = 'canReadMeterReadings_DESC',
  CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
  CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
  CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
  CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
  CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
  CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
  CanReadPropertiesAsc = 'canReadProperties_ASC',
  CanReadPropertiesDesc = 'canReadProperties_DESC',
  CanManagePropertiesAsc = 'canManageProperties_ASC',
  CanManagePropertiesDesc = 'canManageProperties_DESC',
  CanReadTicketsAsc = 'canReadTickets_ASC',
  CanReadTicketsDesc = 'canReadTickets_DESC',
  CanManageTicketsAsc = 'canManageTickets_ASC',
  CanManageTicketsDesc = 'canManageTickets_DESC',
  CanReadTicketCommentsAsc = 'canReadTicketComments_ASC',
  CanReadTicketCommentsDesc = 'canReadTicketComments_DESC',
  CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
  CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
  CanReadTicketFilesAsc = 'canReadTicketFiles_ASC',
  CanReadTicketFilesDesc = 'canReadTicketFiles_DESC',
  CanManageTicketFilesAsc = 'canManageTicketFiles_ASC',
  CanManageTicketFilesDesc = 'canManageTicketFiles_DESC',
  CanReadTicketCommentFilesAsc = 'canReadTicketCommentFiles_ASC',
  CanReadTicketCommentFilesDesc = 'canReadTicketCommentFiles_DESC',
  CanManageTicketCommentFilesAsc = 'canManageTicketCommentFiles_ASC',
  CanManageTicketCommentFilesDesc = 'canManageTicketCommentFiles_DESC',
  CanExecuteRegisterMetersReadingsAsc = 'canExecuteRegisterMetersReadings_ASC',
  CanExecuteRegisterMetersReadingsDesc = 'canExecuteRegisterMetersReadings_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppAccessRightsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  AccessRightSetAsc = 'accessRightSet_ASC',
  AccessRightSetDesc = 'accessRightSet_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppContextHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppContextsBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  DeveloperAsc = 'developer_ASC',
  DeveloperDesc = 'developer_DESC',
  PartnerUrlAsc = 'partnerUrl_ASC',
  PartnerUrlDesc = 'partnerUrl_DESC',
  AppUrlAsc = 'appUrl_ASC',
  AppUrlDesc = 'appUrl_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  IsGlobalAsc = 'isGlobal_ASC',
  IsGlobalDesc = 'isGlobal_DESC',
  IsPublicAsc = 'isPublic_ASC',
  IsPublicDesc = 'isPublic_DESC',
  HasDynamicTitleAsc = 'hasDynamicTitle_ASC',
  HasDynamicTitleDesc = 'hasDynamicTitle_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  MenuCategoryAsc = 'menuCategory_ASC',
  MenuCategoryDesc = 'menuCategory_DESC',
  ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
  ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayPriorityAsc = 'displayPriority_ASC',
  DisplayPriorityDesc = 'displayPriority_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppNewsSharingConfigHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PushNotificationSettingsAsc = 'pushNotificationSettings_ASC',
  PushNotificationSettingsDesc = 'pushNotificationSettings_DESC',
  PublishUrlAsc = 'publishUrl_ASC',
  PublishUrlDesc = 'publishUrl_DESC',
  PreviewUrlAsc = 'previewUrl_ASC',
  PreviewUrlDesc = 'previewUrl_DESC',
  GetRecipientsUrlAsc = 'getRecipientsUrl_ASC',
  GetRecipientsUrlDesc = 'getRecipientsUrl_DESC',
  CustomFormUrlAsc = 'customFormUrl_ASC',
  CustomFormUrlDesc = 'customFormUrl_DESC',
  GetRecipientsCountersUrlAsc = 'getRecipientsCountersUrl_ASC',
  GetRecipientsCountersUrlDesc = 'getRecipientsCountersUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppNewsSharingConfigsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PushNotificationSettingsAsc = 'pushNotificationSettings_ASC',
  PushNotificationSettingsDesc = 'pushNotificationSettings_DESC',
  PublishUrlAsc = 'publishUrl_ASC',
  PublishUrlDesc = 'publishUrl_DESC',
  PreviewUrlAsc = 'previewUrl_ASC',
  PreviewUrlDesc = 'previewUrl_DESC',
  GetRecipientsUrlAsc = 'getRecipientsUrl_ASC',
  GetRecipientsUrlDesc = 'getRecipientsUrl_DESC',
  CustomFormUrlAsc = 'customFormUrl_ASC',
  CustomFormUrlDesc = 'customFormUrl_DESC',
  GetRecipientsCountersUrlAsc = 'getRecipientsCountersUrl_ASC',
  GetRecipientsCountersUrlDesc = 'getRecipientsCountersUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppPermissionHistoryRecordsBy {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppPermissionsBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppPromoBlockHistoryRecordsBy {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TextVariantAsc = 'textVariant_ASC',
  TextVariantDesc = 'textVariant_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  TargetUrlAsc = 'targetUrl_ASC',
  TargetUrlDesc = 'targetUrl_DESC',
  ExternalAsc = 'external_ASC',
  ExternalDesc = 'external_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppPromoBlocksBy {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  TextVariantAsc = 'textVariant_ASC',
  TextVariantDesc = 'textVariant_DESC',
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  TargetUrlAsc = 'targetUrl_ASC',
  TargetUrlDesc = 'targetUrl_DESC',
  ExternalAsc = 'external_ASC',
  ExternalDesc = 'external_DESC',
  PriorityAsc = 'priority_ASC',
  PriorityDesc = 'priority_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppRoleHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2BAppRolesBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2BAppsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  DeveloperAsc = 'developer_ASC',
  DeveloperDesc = 'developer_DESC',
  PartnerUrlAsc = 'partnerUrl_ASC',
  PartnerUrlDesc = 'partnerUrl_DESC',
  DetailedDescriptionAsc = 'detailedDescription_ASC',
  DetailedDescriptionDesc = 'detailedDescription_DESC',
  AppUrlAsc = 'appUrl_ASC',
  AppUrlDesc = 'appUrl_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  IsGlobalAsc = 'isGlobal_ASC',
  IsGlobalDesc = 'isGlobal_DESC',
  IsPublicAsc = 'isPublic_ASC',
  IsPublicDesc = 'isPublic_DESC',
  HasDynamicTitleAsc = 'hasDynamicTitle_ASC',
  HasDynamicTitleDesc = 'hasDynamicTitle_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  MenuCategoryAsc = 'menuCategory_ASC',
  MenuCategoryDesc = 'menuCategory_DESC',
  ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
  ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  AccessRightsAsc = 'accessRights_ASC',
  AccessRightsDesc = 'accessRights_DESC',
  DisplayPriorityAsc = 'displayPriority_ASC',
  DisplayPriorityDesc = 'displayPriority_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PriceAsc = 'price_ASC',
  PriceDesc = 'price_DESC',
  NewsSharingConfigAsc = 'newsSharingConfig_ASC',
  NewsSharingConfigDesc = 'newsSharingConfig_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2CAppAccessRightHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2CAppAccessRightsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC'
}

export enum SortB2CAppBuildHistoryRecordsBy {
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2CAppBuildsBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC'
}

export enum SortB2CAppHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  DeveloperAsc = 'developer_ASC',
  DeveloperDesc = 'developer_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2CAppMessageSettingHistoryRecordsBy {
  BlockReasonAsc = 'blockReason_ASC',
  BlockReasonDesc = 'blockReason_DESC',
  IsBlacklistedAsc = 'isBlacklisted_ASC',
  IsBlacklistedDesc = 'isBlacklisted_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NotificationWindowSizeAsc = 'notificationWindowSize_ASC',
  NotificationWindowSizeDesc = 'notificationWindowSize_DESC',
  NumberOfNotificationInWindowAsc = 'numberOfNotificationInWindow_ASC',
  NumberOfNotificationInWindowDesc = 'numberOfNotificationInWindow_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2CAppMessageSettingsBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  BlockReasonAsc = 'blockReason_ASC',
  BlockReasonDesc = 'blockReason_DESC',
  IsBlacklistedAsc = 'isBlacklisted_ASC',
  IsBlacklistedDesc = 'isBlacklisted_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NotificationWindowSizeAsc = 'notificationWindowSize_ASC',
  NotificationWindowSizeDesc = 'notificationWindowSize_DESC',
  NumberOfNotificationInWindowAsc = 'numberOfNotificationInWindow_ASC',
  NumberOfNotificationInWindowDesc = 'numberOfNotificationInWindow_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2CAppPropertiesBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortB2CAppPropertyHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortB2CAppsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  DeveloperAsc = 'developer_ASC',
  DeveloperDesc = 'developer_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  CurrentBuildAsc = 'currentBuild_ASC',
  CurrentBuildDesc = 'currentBuild_DESC',
  AccessRightsAsc = 'accessRights_ASC',
  AccessRightsDesc = 'accessRights_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC'
}

export enum SortBankAccountHistoryRecordsBy {
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  RoutingNumberAsc = 'routingNumber_ASC',
  RoutingNumberDesc = 'routingNumber_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  ApprovedAtAsc = 'approvedAt_ASC',
  ApprovedAtDesc = 'approvedAt_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  TerritoryCodeAsc = 'territoryCode_ASC',
  TerritoryCodeDesc = 'territoryCode_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  ClassificationCodeAsc = 'classificationCode_ASC',
  ClassificationCodeDesc = 'classificationCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankAccountReportHistoryRecordsBy {
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
  TemplateAsc = 'template_ASC',
  TemplateDesc = 'template_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AmountAtAsc = 'amountAt_ASC',
  AmountAtDesc = 'amountAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TotalIncomeAsc = 'totalIncome_ASC',
  TotalIncomeDesc = 'totalIncome_DESC',
  TotalOutcomeAsc = 'totalOutcome_ASC',
  TotalOutcomeDesc = 'totalOutcome_DESC',
  IsLatestAsc = 'isLatest_ASC',
  IsLatestDesc = 'isLatest_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankAccountReportTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ProgressAsc = 'progress_ASC',
  ProgressDesc = 'progress_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankAccountReportTasksBy {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  ProgressAsc = 'progress_ASC',
  ProgressDesc = 'progress_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankAccountReportsBy {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
  TemplateAsc = 'template_ASC',
  TemplateDesc = 'template_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  AmountAtAsc = 'amountAt_ASC',
  AmountAtDesc = 'amountAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TotalIncomeAsc = 'totalIncome_ASC',
  TotalIncomeDesc = 'totalIncome_DESC',
  TotalOutcomeAsc = 'totalOutcome_ASC',
  TotalOutcomeDesc = 'totalOutcome_DESC',
  IsLatestAsc = 'isLatest_ASC',
  IsLatestDesc = 'isLatest_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankAccountsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IntegrationContextAsc = 'integrationContext_ASC',
  IntegrationContextDesc = 'integrationContext_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  RoutingNumberAsc = 'routingNumber_ASC',
  RoutingNumberDesc = 'routingNumber_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  ApprovedAtAsc = 'approvedAt_ASC',
  ApprovedAtDesc = 'approvedAt_DESC',
  ApprovedByAsc = 'approvedBy_ASC',
  ApprovedByDesc = 'approvedBy_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  TerritoryCodeAsc = 'territoryCode_ASC',
  TerritoryCodeDesc = 'territoryCode_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  ClassificationCodeAsc = 'classificationCode_ASC',
  ClassificationCodeDesc = 'classificationCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankCategoriesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankCategoryHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankContractorAccountHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  RoutingNumberAsc = 'routingNumber_ASC',
  RoutingNumberDesc = 'routingNumber_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  TerritoryCodeAsc = 'territoryCode_ASC',
  TerritoryCodeDesc = 'territoryCode_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankContractorAccountsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  CostItemAsc = 'costItem_ASC',
  CostItemDesc = 'costItem_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  RoutingNumberAsc = 'routingNumber_ASC',
  RoutingNumberDesc = 'routingNumber_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  TerritoryCodeAsc = 'territoryCode_ASC',
  TerritoryCodeDesc = 'territoryCode_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankCostItemHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsOutcomeAsc = 'isOutcome_ASC',
  IsOutcomeDesc = 'isOutcome_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankCostItemsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsOutcomeAsc = 'isOutcome_ASC',
  IsOutcomeDesc = 'isOutcome_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankIntegrationAccessRightHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankIntegrationAccessRightsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankIntegrationAccountContextHistoryRecordsBy {
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankIntegrationAccountContextsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankIntegrationHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankIntegrationOrganizationContextHistoryRecordsBy {
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankIntegrationOrganizationContextsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankIntegrationsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  AccessRightsAsc = 'accessRights_ASC',
  AccessRightsDesc = 'accessRights_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankSyncTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TotalCountAsc = 'totalCount_ASC',
  TotalCountDesc = 'totalCount_DESC',
  ProcessedCountAsc = 'processedCount_ASC',
  ProcessedCountDesc = 'processedCount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankSyncTasksBy {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  IntegrationContextAsc = 'integrationContext_ASC',
  IntegrationContextDesc = 'integrationContext_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TotalCountAsc = 'totalCount_ASC',
  TotalCountDesc = 'totalCount_DESC',
  ProcessedCountAsc = 'processedCount_ASC',
  ProcessedCountDesc = 'processedCount_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBankTransactionHistoryRecordsBy {
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  IsOutcomeAsc = 'isOutcome_ASC',
  IsOutcomeDesc = 'isOutcome_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBankTransactionsBy {
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  IntegrationContextAsc = 'integrationContext_ASC',
  IntegrationContextDesc = 'integrationContext_DESC',
  ContractorAccountAsc = 'contractorAccount_ASC',
  ContractorAccountDesc = 'contractorAccount_DESC',
  CostItemAsc = 'costItem_ASC',
  CostItemDesc = 'costItem_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  IsOutcomeAsc = 'isOutcome_ASC',
  IsOutcomeDesc = 'isOutcome_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingAccountHistoryRecordsBy {
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  GlobalIdAsc = 'globalId_ASC',
  GlobalIdDesc = 'globalId_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  IsClosedAsc = 'isClosed_ASC',
  IsClosedDesc = 'isClosed_DESC',
  OwnerTypeAsc = 'ownerType_ASC',
  OwnerTypeDesc = 'ownerType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingAccountsBy {
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  GlobalIdAsc = 'globalId_ASC',
  GlobalIdDesc = 'globalId_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  IsClosedAsc = 'isClosed_ASC',
  IsClosedDesc = 'isClosed_DESC',
  OwnerTypeAsc = 'ownerType_ASC',
  OwnerTypeDesc = 'ownerType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingCategoriesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingCategoryHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingIntegrationAccessRightHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingIntegrationAccessRightsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export enum SortBillingIntegrationHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  TargetDescriptionAsc = 'targetDescription_ASC',
  TargetDescriptionDesc = 'targetDescription_DESC',
  BannerTextColorAsc = 'bannerTextColor_ASC',
  BannerTextColorDesc = 'bannerTextColor_DESC',
  BannerColorAsc = 'bannerColor_ASC',
  BannerColorDesc = 'bannerColor_DESC',
  ReceiptsLoadingTimeAsc = 'receiptsLoadingTime_ASC',
  ReceiptsLoadingTimeDesc = 'receiptsLoadingTime_DESC',
  SetupUrlAsc = 'setupUrl_ASC',
  SetupUrlDesc = 'setupUrl_DESC',
  CheckAccountNumberUrlAsc = 'checkAccountNumberUrl_ASC',
  CheckAccountNumberUrlDesc = 'checkAccountNumberUrl_DESC',
  CheckAddressUrlAsc = 'checkAddressUrl_ASC',
  CheckAddressUrlDesc = 'checkAddressUrl_DESC',
  InstructionExtraLinkAsc = 'instructionExtraLink_ASC',
  InstructionExtraLinkDesc = 'instructionExtraLink_DESC',
  AppUrlAsc = 'appUrl_ASC',
  AppUrlDesc = 'appUrl_DESC',
  ExtendsBillingPageAsc = 'extendsBillingPage_ASC',
  ExtendsBillingPageDesc = 'extendsBillingPage_DESC',
  BillingPageTitleAsc = 'billingPageTitle_ASC',
  BillingPageTitleDesc = 'billingPageTitle_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  ConnectedMessageAsc = 'connectedMessage_ASC',
  ConnectedMessageDesc = 'connectedMessage_DESC',
  UploadUrlAsc = 'uploadUrl_ASC',
  UploadUrlDesc = 'uploadUrl_DESC',
  UploadMessageAsc = 'uploadMessage_ASC',
  UploadMessageDesc = 'uploadMessage_DESC',
  ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
  ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  IsTrustedBankAccountSourceAsc = 'isTrustedBankAccountSource_ASC',
  IsTrustedBankAccountSourceDesc = 'isTrustedBankAccountSource_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  SkipNoAccountNotificationsAsc = 'skipNoAccountNotifications_ASC',
  SkipNoAccountNotificationsDesc = 'skipNoAccountNotifications_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingIntegrationOrganizationContextHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingIntegrationOrganizationContextsBy {
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  CurrentProblemAsc = 'currentProblem_ASC',
  CurrentProblemDesc = 'currentProblem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingIntegrationProblemsBy {
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingIntegrationsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShortDescriptionAsc = 'shortDescription_ASC',
  ShortDescriptionDesc = 'shortDescription_DESC',
  TargetDescriptionAsc = 'targetDescription_ASC',
  TargetDescriptionDesc = 'targetDescription_DESC',
  BannerTextColorAsc = 'bannerTextColor_ASC',
  BannerTextColorDesc = 'bannerTextColor_DESC',
  BannerColorAsc = 'bannerColor_ASC',
  BannerColorDesc = 'bannerColor_DESC',
  ReceiptsLoadingTimeAsc = 'receiptsLoadingTime_ASC',
  ReceiptsLoadingTimeDesc = 'receiptsLoadingTime_DESC',
  DetailedDescriptionAsc = 'detailedDescription_ASC',
  DetailedDescriptionDesc = 'detailedDescription_DESC',
  SetupUrlAsc = 'setupUrl_ASC',
  SetupUrlDesc = 'setupUrl_DESC',
  CheckAccountNumberUrlAsc = 'checkAccountNumberUrl_ASC',
  CheckAccountNumberUrlDesc = 'checkAccountNumberUrl_DESC',
  CheckAddressUrlAsc = 'checkAddressUrl_ASC',
  CheckAddressUrlDesc = 'checkAddressUrl_DESC',
  InstructionAsc = 'instruction_ASC',
  InstructionDesc = 'instruction_DESC',
  InstructionExtraLinkAsc = 'instructionExtraLink_ASC',
  InstructionExtraLinkDesc = 'instructionExtraLink_DESC',
  AppUrlAsc = 'appUrl_ASC',
  AppUrlDesc = 'appUrl_DESC',
  ExtendsBillingPageAsc = 'extendsBillingPage_ASC',
  ExtendsBillingPageDesc = 'extendsBillingPage_DESC',
  BillingPageTitleAsc = 'billingPageTitle_ASC',
  BillingPageTitleDesc = 'billingPageTitle_DESC',
  GroupAsc = 'group_ASC',
  GroupDesc = 'group_DESC',
  ConnectedMessageAsc = 'connectedMessage_ASC',
  ConnectedMessageDesc = 'connectedMessage_DESC',
  UploadUrlAsc = 'uploadUrl_ASC',
  UploadUrlDesc = 'uploadUrl_DESC',
  UploadMessageAsc = 'uploadMessage_ASC',
  UploadMessageDesc = 'uploadMessage_DESC',
  ContextDefaultStatusAsc = 'contextDefaultStatus_ASC',
  ContextDefaultStatusDesc = 'contextDefaultStatus_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  AccessRightsAsc = 'accessRights_ASC',
  AccessRightsDesc = 'accessRights_DESC',
  IsTrustedBankAccountSourceAsc = 'isTrustedBankAccountSource_ASC',
  IsTrustedBankAccountSourceDesc = 'isTrustedBankAccountSource_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  SkipNoAccountNotificationsAsc = 'skipNoAccountNotifications_ASC',
  SkipNoAccountNotificationsDesc = 'skipNoAccountNotifications_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingPropertiesBy {
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  GlobalIdAsc = 'globalId_ASC',
  GlobalIdDesc = 'globalId_DESC',
  NormalizedAddressAsc = 'normalizedAddress_ASC',
  NormalizedAddressDesc = 'normalizedAddress_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingPropertyHistoryRecordsBy {
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  GlobalIdAsc = 'globalId_ASC',
  GlobalIdDesc = 'globalId_DESC',
  NormalizedAddressAsc = 'normalizedAddress_ASC',
  NormalizedAddressDesc = 'normalizedAddress_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingReceiptFileHistoryRecordsBy {
  ControlSumAsc = 'controlSum_ASC',
  ControlSumDesc = 'controlSum_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingReceiptFilesBy {
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  ReceiptAsc = 'receipt_ASC',
  ReceiptDesc = 'receipt_DESC',
  ControlSumAsc = 'controlSum_ASC',
  ControlSumDesc = 'controlSum_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingReceiptHistoryRecordsBy {
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  PrintableNumberAsc = 'printableNumber_ASC',
  PrintableNumberDesc = 'printableNumber_DESC',
  ToPayAsc = 'toPay_ASC',
  ToPayDesc = 'toPay_DESC',
  FormulaAsc = 'formula_ASC',
  FormulaDesc = 'formula_DESC',
  ChargeAsc = 'charge_ASC',
  ChargeDesc = 'charge_DESC',
  BalanceAsc = 'balance_ASC',
  BalanceDesc = 'balance_DESC',
  RecalculationAsc = 'recalculation_ASC',
  RecalculationDesc = 'recalculation_DESC',
  PrivilegeAsc = 'privilege_ASC',
  PrivilegeDesc = 'privilege_DESC',
  PenaltyAsc = 'penalty_ASC',
  PenaltyDesc = 'penalty_DESC',
  PaidAsc = 'paid_ASC',
  PaidDesc = 'paid_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingReceiptsBy {
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  AccountAsc = 'account_ASC',
  AccountDesc = 'account_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  PrintableNumberAsc = 'printableNumber_ASC',
  PrintableNumberDesc = 'printableNumber_DESC',
  ToPayAsc = 'toPay_ASC',
  ToPayDesc = 'toPay_DESC',
  FormulaAsc = 'formula_ASC',
  FormulaDesc = 'formula_DESC',
  ChargeAsc = 'charge_ASC',
  ChargeDesc = 'charge_DESC',
  BalanceAsc = 'balance_ASC',
  BalanceDesc = 'balance_DESC',
  RecalculationAsc = 'recalculation_ASC',
  RecalculationDesc = 'recalculation_DESC',
  PrivilegeAsc = 'privilege_ASC',
  PrivilegeDesc = 'privilege_DESC',
  PenaltyAsc = 'penalty_ASC',
  PenaltyDesc = 'penalty_DESC',
  PaidAsc = 'paid_ASC',
  PaidDesc = 'paid_DESC',
  ReceiverAsc = 'receiver_ASC',
  ReceiverDesc = 'receiver_DESC',
  FileAsc = 'file_ASC',
  FileDesc = 'file_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortBillingRecipientHistoryRecordsBy {
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  IecAsc = 'iec_ASC',
  IecDesc = 'iec_DESC',
  BicAsc = 'bic_ASC',
  BicDesc = 'bic_DESC',
  BankAccountAsc = 'bankAccount_ASC',
  BankAccountDesc = 'bankAccount_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  OffsettingAccountAsc = 'offsettingAccount_ASC',
  OffsettingAccountDesc = 'offsettingAccount_DESC',
  TerritoryCodeAsc = 'territoryCode_ASC',
  TerritoryCodeDesc = 'territoryCode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ClassificationCodeAsc = 'classificationCode_ASC',
  ClassificationCodeDesc = 'classificationCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortBillingRecipientsBy {
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  IecAsc = 'iec_ASC',
  IecDesc = 'iec_DESC',
  BicAsc = 'bic_ASC',
  BicDesc = 'bic_DESC',
  BankAccountAsc = 'bankAccount_ASC',
  BankAccountDesc = 'bankAccount_DESC',
  BankNameAsc = 'bankName_ASC',
  BankNameDesc = 'bankName_DESC',
  OffsettingAccountAsc = 'offsettingAccount_ASC',
  OffsettingAccountDesc = 'offsettingAccount_DESC',
  TerritoryCodeAsc = 'territoryCode_ASC',
  TerritoryCodeDesc = 'territoryCode_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ClassificationCodeAsc = 'classificationCode_ASC',
  ClassificationCodeDesc = 'classificationCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortCallRecordFragmentHistoryRecordsBy {
  StartedAtAsc = 'startedAt_ASC',
  StartedAtDesc = 'startedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortCallRecordFragmentsBy {
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  CallRecordAsc = 'callRecord_ASC',
  CallRecordDesc = 'callRecord_DESC',
  StartedAtAsc = 'startedAt_ASC',
  StartedAtDesc = 'startedAt_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortCallRecordHistoryRecordsBy {
  CallerPhoneAsc = 'callerPhone_ASC',
  CallerPhoneDesc = 'callerPhone_DESC',
  DestCallerPhoneAsc = 'destCallerPhone_ASC',
  DestCallerPhoneDesc = 'destCallerPhone_DESC',
  TalkTimeAsc = 'talkTime_ASC',
  TalkTimeDesc = 'talkTime_DESC',
  StartedAtAsc = 'startedAt_ASC',
  StartedAtDesc = 'startedAt_DESC',
  IsIncomingCallAsc = 'isIncomingCall_ASC',
  IsIncomingCallDesc = 'isIncomingCall_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortCallRecordsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  CallerPhoneAsc = 'callerPhone_ASC',
  CallerPhoneDesc = 'callerPhone_DESC',
  DestCallerPhoneAsc = 'destCallerPhone_ASC',
  DestCallerPhoneDesc = 'destCallerPhone_DESC',
  TalkTimeAsc = 'talkTime_ASC',
  TalkTimeDesc = 'talkTime_DESC',
  StartedAtAsc = 'startedAt_ASC',
  StartedAtDesc = 'startedAt_DESC',
  IsIncomingCallAsc = 'isIncomingCall_ASC',
  IsIncomingCallDesc = 'isIncomingCall_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortConfirmPhoneActionHistoryRecordsBy {
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  TokenAsc = 'token_ASC',
  TokenDesc = 'token_DESC',
  SmsCodeAsc = 'smsCode_ASC',
  SmsCodeDesc = 'smsCode_DESC',
  SmsCodeRequestedAtAsc = 'smsCodeRequestedAt_ASC',
  SmsCodeRequestedAtDesc = 'smsCodeRequestedAt_DESC',
  SmsCodeExpiresAtAsc = 'smsCodeExpiresAt_ASC',
  SmsCodeExpiresAtDesc = 'smsCodeExpiresAt_DESC',
  RetriesAsc = 'retries_ASC',
  RetriesDesc = 'retries_DESC',
  IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
  IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
  RequestedAtAsc = 'requestedAt_ASC',
  RequestedAtDesc = 'requestedAt_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  CompletedAtAsc = 'completedAt_ASC',
  CompletedAtDesc = 'completedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortConfirmPhoneActionsBy {
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  TokenAsc = 'token_ASC',
  TokenDesc = 'token_DESC',
  SmsCodeAsc = 'smsCode_ASC',
  SmsCodeDesc = 'smsCode_DESC',
  SmsCodeRequestedAtAsc = 'smsCodeRequestedAt_ASC',
  SmsCodeRequestedAtDesc = 'smsCodeRequestedAt_DESC',
  SmsCodeExpiresAtAsc = 'smsCodeExpiresAt_ASC',
  SmsCodeExpiresAtDesc = 'smsCodeExpiresAt_DESC',
  RetriesAsc = 'retries_ASC',
  RetriesDesc = 'retries_DESC',
  IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
  IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
  RequestedAtAsc = 'requestedAt_ASC',
  RequestedAtDesc = 'requestedAt_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  CompletedAtAsc = 'completedAt_ASC',
  CompletedAtDesc = 'completedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortContactExportTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
  ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortContactExportTasksBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
  ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortContactHistoryRecordsBy {
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsVerifiedAsc = 'isVerified_ASC',
  IsVerifiedDesc = 'isVerified_DESC',
  QuotaAsc = 'quota_ASC',
  QuotaDesc = 'quota_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortContactRoleHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortContactRolesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortContactsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  IsVerifiedAsc = 'isVerified_ASC',
  IsVerifiedDesc = 'isVerified_DESC',
  QuotaAsc = 'quota_ASC',
  QuotaDesc = 'quota_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortDocumentCategoriesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortDocumentCategoryHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortDocumentHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortDocumentsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortExternalReportHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortExternalReportsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IsHiddenAsc = 'isHidden_ASC',
  IsHiddenDesc = 'isHidden_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortExternalTokenAccessRightHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortExternalTokenAccessRightsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortIncidentChangesBy {
  IncidentAsc = 'incident_ASC',
  IncidentDesc = 'incident_DESC',
  DetailsFromAsc = 'detailsFrom_ASC',
  DetailsFromDesc = 'detailsFrom_DESC',
  DetailsToAsc = 'detailsTo_ASC',
  DetailsToDesc = 'detailsTo_DESC',
  StatusFromAsc = 'statusFrom_ASC',
  StatusFromDesc = 'statusFrom_DESC',
  StatusToAsc = 'statusTo_ASC',
  StatusToDesc = 'statusTo_DESC',
  TextForResidentFromAsc = 'textForResidentFrom_ASC',
  TextForResidentFromDesc = 'textForResidentFrom_DESC',
  TextForResidentToAsc = 'textForResidentTo_ASC',
  TextForResidentToDesc = 'textForResidentTo_DESC',
  WorkStartFromAsc = 'workStartFrom_ASC',
  WorkStartFromDesc = 'workStartFrom_DESC',
  WorkStartToAsc = 'workStartTo_ASC',
  WorkStartToDesc = 'workStartTo_DESC',
  WorkFinishFromAsc = 'workFinishFrom_ASC',
  WorkFinishFromDesc = 'workFinishFrom_DESC',
  WorkFinishToAsc = 'workFinishTo_ASC',
  WorkFinishToDesc = 'workFinishTo_DESC',
  WorkTypeFromAsc = 'workTypeFrom_ASC',
  WorkTypeFromDesc = 'workTypeFrom_DESC',
  WorkTypeToAsc = 'workTypeTo_ASC',
  WorkTypeToDesc = 'workTypeTo_DESC',
  OrganizationIdFromAsc = 'organizationIdFrom_ASC',
  OrganizationIdFromDesc = 'organizationIdFrom_DESC',
  OrganizationIdToAsc = 'organizationIdTo_ASC',
  OrganizationIdToDesc = 'organizationIdTo_DESC',
  OrganizationDisplayNameFromAsc = 'organizationDisplayNameFrom_ASC',
  OrganizationDisplayNameFromDesc = 'organizationDisplayNameFrom_DESC',
  OrganizationDisplayNameToAsc = 'organizationDisplayNameTo_ASC',
  OrganizationDisplayNameToDesc = 'organizationDisplayNameTo_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortIncidentClassifierHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortIncidentClassifierIncidentHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortIncidentClassifierIncidentsBy {
  IncidentAsc = 'incident_ASC',
  IncidentDesc = 'incident_DESC',
  ClassifierAsc = 'classifier_ASC',
  ClassifierDesc = 'classifier_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortIncidentClassifiersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  ProblemAsc = 'problem_ASC',
  ProblemDesc = 'problem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortIncidentExportTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
  ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortIncidentExportTasksBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
  ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortIncidentHistoryRecordsBy {
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TextForResidentAsc = 'textForResident_ASC',
  TextForResidentDesc = 'textForResident_DESC',
  WorkStartAsc = 'workStart_ASC',
  WorkStartDesc = 'workStart_DESC',
  WorkFinishAsc = 'workFinish_ASC',
  WorkFinishDesc = 'workFinish_DESC',
  WorkTypeAsc = 'workType_ASC',
  WorkTypeDesc = 'workType_DESC',
  HasAllPropertiesAsc = 'hasAllProperties_ASC',
  HasAllPropertiesDesc = 'hasAllProperties_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortIncidentPropertiesBy {
  IncidentAsc = 'incident_ASC',
  IncidentDesc = 'incident_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  PropertyAddressAsc = 'propertyAddress_ASC',
  PropertyAddressDesc = 'propertyAddress_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortIncidentPropertyHistoryRecordsBy {
  PropertyAddressAsc = 'propertyAddress_ASC',
  PropertyAddressDesc = 'propertyAddress_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortIncidentsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TextForResidentAsc = 'textForResident_ASC',
  TextForResidentDesc = 'textForResident_DESC',
  WorkStartAsc = 'workStart_ASC',
  WorkStartDesc = 'workStart_DESC',
  WorkFinishAsc = 'workFinish_ASC',
  WorkFinishDesc = 'workFinish_DESC',
  WorkTypeAsc = 'workType_ASC',
  WorkTypeDesc = 'workType_DESC',
  HasAllPropertiesAsc = 'hasAllProperties_ASC',
  HasAllPropertiesDesc = 'hasAllProperties_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortInvoiceHistoryRecordsBy {
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  ToPayAsc = 'toPay_ASC',
  ToPayDesc = 'toPay_DESC',
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  ClientPhoneAsc = 'clientPhone_ASC',
  ClientPhoneDesc = 'clientPhone_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  PaymentTypeAsc = 'paymentType_ASC',
  PaymentTypeDesc = 'paymentType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PaidAtAsc = 'paidAt_ASC',
  PaidAtDesc = 'paidAt_DESC',
  CanceledAtAsc = 'canceledAt_ASC',
  CanceledAtDesc = 'canceledAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortInvoicesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  ToPayAsc = 'toPay_ASC',
  ToPayDesc = 'toPay_DESC',
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  ContactAsc = 'contact_ASC',
  ContactDesc = 'contact_DESC',
  ClientAsc = 'client_ASC',
  ClientDesc = 'client_DESC',
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  ClientPhoneAsc = 'clientPhone_ASC',
  ClientPhoneDesc = 'clientPhone_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  PaymentTypeAsc = 'paymentType_ASC',
  PaymentTypeDesc = 'paymentType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  PaidAtAsc = 'paidAt_ASC',
  PaidAtDesc = 'paidAt_DESC',
  CanceledAtAsc = 'canceledAt_ASC',
  CanceledAtDesc = 'canceledAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMarketCategoriesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ParentCategoryAsc = 'parentCategory_ASC',
  ParentCategoryDesc = 'parentCategory_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMarketCategoryHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMarketItemFileHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMarketItemFilesBy {
  MarketItemAsc = 'marketItem_ASC',
  MarketItemDesc = 'marketItem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMarketItemHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMarketItemPriceHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMarketItemPricesBy {
  MarketItemAsc = 'marketItem_ASC',
  MarketItemDesc = 'marketItem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMarketItemsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  MarketCategoryAsc = 'marketCategory_ASC',
  MarketCategoryDesc = 'marketCategory_DESC',
  SkuAsc = 'sku_ASC',
  SkuDesc = 'sku_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMarketPriceScopeHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMarketPriceScopesBy {
  MarketItemPriceAsc = 'marketItemPrice_ASC',
  MarketItemPriceDesc = 'marketItemPrice_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMarketSettingHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMarketSettingsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMessageAppBlackListHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMessageAppBlackListsBy {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMessageBatchHistoryRecordsBy {
  MessageTypeAsc = 'messageType_ASC',
  MessageTypeDesc = 'messageType_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  DeepLinkAsc = 'deepLink_ASC',
  DeepLinkDesc = 'deepLink_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMessageBatchesBy {
  MessageTypeAsc = 'messageType_ASC',
  MessageTypeDesc = 'messageType_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  MessageAsc = 'message_ASC',
  MessageDesc = 'message_DESC',
  DeepLinkAsc = 'deepLink_ASC',
  DeepLinkDesc = 'deepLink_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMessageHistoryRecordsBy {
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  EmailFromAsc = 'emailFrom_ASC',
  EmailFromDesc = 'emailFrom_DESC',
  LangAsc = 'lang_ASC',
  LangDesc = 'lang_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  DeliveredAtAsc = 'deliveredAt_ASC',
  DeliveredAtDesc = 'deliveredAt_DESC',
  SentAtAsc = 'sentAt_ASC',
  SentAtDesc = 'sentAt_DESC',
  ReadAtAsc = 'readAt_ASC',
  ReadAtDesc = 'readAt_DESC',
  UniqKeyAsc = 'uniqKey_ASC',
  UniqKeyDesc = 'uniqKey_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMessageOrganizationBlackListHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMessageOrganizationBlackListsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMessageUserBlackListHistoryRecordsBy {
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMessageUserBlackListsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMessagesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  RemoteClientAsc = 'remoteClient_ASC',
  RemoteClientDesc = 'remoteClient_DESC',
  EmailFromAsc = 'emailFrom_ASC',
  EmailFromDesc = 'emailFrom_DESC',
  LangAsc = 'lang_ASC',
  LangDesc = 'lang_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  DeliveredAtAsc = 'deliveredAt_ASC',
  DeliveredAtDesc = 'deliveredAt_DESC',
  SentAtAsc = 'sentAt_ASC',
  SentAtDesc = 'sentAt_DESC',
  ReadAtAsc = 'readAt_ASC',
  ReadAtDesc = 'readAt_DESC',
  UniqKeyAsc = 'uniqKey_ASC',
  UniqKeyDesc = 'uniqKey_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterHistoryRecordsBy {
  NumberOfTariffsAsc = 'numberOfTariffs_ASC',
  NumberOfTariffsDesc = 'numberOfTariffs_DESC',
  InstallationDateAsc = 'installationDate_ASC',
  InstallationDateDesc = 'installationDate_DESC',
  CommissioningDateAsc = 'commissioningDate_ASC',
  CommissioningDateDesc = 'commissioningDate_DESC',
  VerificationDateAsc = 'verificationDate_ASC',
  VerificationDateDesc = 'verificationDate_DESC',
  NextVerificationDateAsc = 'nextVerificationDate_ASC',
  NextVerificationDateDesc = 'nextVerificationDate_DESC',
  ControlReadingsDateAsc = 'controlReadingsDate_ASC',
  ControlReadingsDateDesc = 'controlReadingsDate_DESC',
  SealingDateAsc = 'sealingDate_ASC',
  SealingDateDesc = 'sealingDate_DESC',
  ArchiveDateAsc = 'archiveDate_ASC',
  ArchiveDateDesc = 'archiveDate_DESC',
  IsAutomaticAsc = 'isAutomatic_ASC',
  IsAutomaticDesc = 'isAutomatic_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterReadingFilterTemplateHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterReadingFilterTemplatesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmployeeAsc = 'employee_ASC',
  EmployeeDesc = 'employee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterReadingHistoryRecordsBy {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  Value1Asc = 'value1_ASC',
  Value1Desc = 'value1_DESC',
  Value2Asc = 'value2_ASC',
  Value2Desc = 'value2_DESC',
  Value3Asc = 'value3_ASC',
  Value3Desc = 'value3_DESC',
  Value4Asc = 'value4_ASC',
  Value4Desc = 'value4_DESC',
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  ClientEmailAsc = 'clientEmail_ASC',
  ClientEmailDesc = 'clientEmail_DESC',
  ClientPhoneAsc = 'clientPhone_ASC',
  ClientPhoneDesc = 'clientPhone_DESC',
  BillingStatusAsc = 'billingStatus_ASC',
  BillingStatusDesc = 'billingStatus_DESC',
  BillingStatusTextAsc = 'billingStatusText_ASC',
  BillingStatusTextDesc = 'billingStatusText_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterReadingSourceHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterReadingSourcesBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterReadingsBy {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  MeterAsc = 'meter_ASC',
  MeterDesc = 'meter_DESC',
  Value1Asc = 'value1_ASC',
  Value1Desc = 'value1_DESC',
  Value2Asc = 'value2_ASC',
  Value2Desc = 'value2_DESC',
  Value3Asc = 'value3_ASC',
  Value3Desc = 'value3_DESC',
  Value4Asc = 'value4_ASC',
  Value4Desc = 'value4_DESC',
  ClientAsc = 'client_ASC',
  ClientDesc = 'client_DESC',
  ContactAsc = 'contact_ASC',
  ContactDesc = 'contact_DESC',
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  ClientEmailAsc = 'clientEmail_ASC',
  ClientEmailDesc = 'clientEmail_DESC',
  ClientPhoneAsc = 'clientPhone_ASC',
  ClientPhoneDesc = 'clientPhone_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  BillingStatusAsc = 'billingStatus_ASC',
  BillingStatusDesc = 'billingStatus_DESC',
  BillingStatusTextAsc = 'billingStatusText_ASC',
  BillingStatusTextDesc = 'billingStatusText_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterReadingsImportTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ImportedRecordsCountAsc = 'importedRecordsCount_ASC',
  ImportedRecordsCountDesc = 'importedRecordsCount_DESC',
  ProcessedRecordsCountAsc = 'processedRecordsCount_ASC',
  ProcessedRecordsCountDesc = 'processedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterReadingsImportTasksBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ImportedRecordsCountAsc = 'importedRecordsCount_ASC',
  ImportedRecordsCountDesc = 'importedRecordsCount_DESC',
  ProcessedRecordsCountAsc = 'processedRecordsCount_ASC',
  ProcessedRecordsCountDesc = 'processedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterReportingPeriodHistoryRecordsBy {
  NotifyStartDayAsc = 'notifyStartDay_ASC',
  NotifyStartDayDesc = 'notifyStartDay_DESC',
  NotifyEndDayAsc = 'notifyEndDay_ASC',
  NotifyEndDayDesc = 'notifyEndDay_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterReportingPeriodsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  NotifyStartDayAsc = 'notifyStartDay_ASC',
  NotifyStartDayDesc = 'notifyStartDay_DESC',
  NotifyEndDayAsc = 'notifyEndDay_ASC',
  NotifyEndDayDesc = 'notifyEndDay_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterResourceHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  MeasureAsc = 'measure_ASC',
  MeasureDesc = 'measure_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterResourceOwnerHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMeterResourceOwnersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  ResourceAsc = 'resource_ASC',
  ResourceDesc = 'resource_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMeterResourcesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  MeasureAsc = 'measure_ASC',
  MeasureDesc = 'measure_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMetersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NumberOfTariffsAsc = 'numberOfTariffs_ASC',
  NumberOfTariffsDesc = 'numberOfTariffs_DESC',
  InstallationDateAsc = 'installationDate_ASC',
  InstallationDateDesc = 'installationDate_DESC',
  CommissioningDateAsc = 'commissioningDate_ASC',
  CommissioningDateDesc = 'commissioningDate_DESC',
  VerificationDateAsc = 'verificationDate_ASC',
  VerificationDateDesc = 'verificationDate_DESC',
  NextVerificationDateAsc = 'nextVerificationDate_ASC',
  NextVerificationDateDesc = 'nextVerificationDate_DESC',
  ControlReadingsDateAsc = 'controlReadingsDate_ASC',
  ControlReadingsDateDesc = 'controlReadingsDate_DESC',
  SealingDateAsc = 'sealingDate_ASC',
  SealingDateDesc = 'sealingDate_DESC',
  ArchiveDateAsc = 'archiveDate_ASC',
  ArchiveDateDesc = 'archiveDate_DESC',
  IsAutomaticAsc = 'isAutomatic_ASC',
  IsAutomaticDesc = 'isAutomatic_DESC',
  ResourceAsc = 'resource_ASC',
  ResourceDesc = 'resource_DESC',
  B2bAppAsc = 'b2bApp_ASC',
  B2bAppDesc = 'b2bApp_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  B2cAppAsc = 'b2cApp_ASC',
  B2cAppDesc = 'b2cApp_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMobileFeatureConfigHistoryRecordsBy {
  CommonPhoneAsc = 'commonPhone_ASC',
  CommonPhoneDesc = 'commonPhone_DESC',
  TicketSubmittingIsDisabledAsc = 'ticketSubmittingIsDisabled_ASC',
  TicketSubmittingIsDisabledDesc = 'ticketSubmittingIsDisabled_DESC',
  OnlyGreaterThanPreviousMeterReadingIsEnabledAsc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_ASC',
  OnlyGreaterThanPreviousMeterReadingIsEnabledDesc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMobileFeatureConfigsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  CommonPhoneAsc = 'commonPhone_ASC',
  CommonPhoneDesc = 'commonPhone_DESC',
  TicketSubmittingIsDisabledAsc = 'ticketSubmittingIsDisabled_ASC',
  TicketSubmittingIsDisabledDesc = 'ticketSubmittingIsDisabled_DESC',
  OnlyGreaterThanPreviousMeterReadingIsEnabledAsc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_ASC',
  OnlyGreaterThanPreviousMeterReadingIsEnabledDesc = 'onlyGreaterThanPreviousMeterReadingIsEnabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortMultiPaymentHistoryRecordsBy {
  ExplicitFeeAsc = 'explicitFee_ASC',
  ExplicitFeeDesc = 'explicitFee_DESC',
  ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
  ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
  ServiceFeeAsc = 'serviceFee_ASC',
  ServiceFeeDesc = 'serviceFee_DESC',
  ImplicitFeeAsc = 'implicitFee_ASC',
  ImplicitFeeDesc = 'implicitFee_DESC',
  AmountWithoutExplicitFeeAsc = 'amountWithoutExplicitFee_ASC',
  AmountWithoutExplicitFeeDesc = 'amountWithoutExplicitFee_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  WithdrawnAtAsc = 'withdrawnAt_ASC',
  WithdrawnAtDesc = 'withdrawnAt_DESC',
  CardNumberAsc = 'cardNumber_ASC',
  CardNumberDesc = 'cardNumber_DESC',
  PaymentWayAsc = 'paymentWay_ASC',
  PaymentWayDesc = 'paymentWay_DESC',
  PayerEmailAsc = 'payerEmail_ASC',
  PayerEmailDesc = 'payerEmail_DESC',
  ServiceCategoryAsc = 'serviceCategory_ASC',
  ServiceCategoryDesc = 'serviceCategory_DESC',
  TransactionIdAsc = 'transactionId_ASC',
  TransactionIdDesc = 'transactionId_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortMultiPaymentsBy {
  ExplicitFeeAsc = 'explicitFee_ASC',
  ExplicitFeeDesc = 'explicitFee_DESC',
  ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
  ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
  ServiceFeeAsc = 'serviceFee_ASC',
  ServiceFeeDesc = 'serviceFee_DESC',
  ImplicitFeeAsc = 'implicitFee_ASC',
  ImplicitFeeDesc = 'implicitFee_DESC',
  AmountWithoutExplicitFeeAsc = 'amountWithoutExplicitFee_ASC',
  AmountWithoutExplicitFeeDesc = 'amountWithoutExplicitFee_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  WithdrawnAtAsc = 'withdrawnAt_ASC',
  WithdrawnAtDesc = 'withdrawnAt_DESC',
  CardNumberAsc = 'cardNumber_ASC',
  CardNumberDesc = 'cardNumber_DESC',
  PaymentWayAsc = 'paymentWay_ASC',
  PaymentWayDesc = 'paymentWay_DESC',
  PayerEmailAsc = 'payerEmail_ASC',
  PayerEmailDesc = 'payerEmail_DESC',
  ServiceCategoryAsc = 'serviceCategory_ASC',
  ServiceCategoryDesc = 'serviceCategory_DESC',
  TransactionIdAsc = 'transactionId_ASC',
  TransactionIdDesc = 'transactionId_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  PaymentsAsc = 'payments_ASC',
  PaymentsDesc = 'payments_DESC',
  IntegrationAsc = 'integration_ASC',
  IntegrationDesc = 'integration_DESC',
  RecurrentPaymentContextAsc = 'recurrentPaymentContext_ASC',
  RecurrentPaymentContextDesc = 'recurrentPaymentContext_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNewsItemHistoryRecordsBy {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ValidBeforeAsc = 'validBefore_ASC',
  ValidBeforeDesc = 'validBefore_DESC',
  SendAtAsc = 'sendAt_ASC',
  SendAtDesc = 'sendAt_DESC',
  SentAtAsc = 'sentAt_ASC',
  SentAtDesc = 'sentAt_DESC',
  IsPublishedAsc = 'isPublished_ASC',
  IsPublishedDesc = 'isPublished_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNewsItemRecipientsExportTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNewsItemRecipientsExportTasksBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNewsItemScopeHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNewsItemScopesBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NewsItemAsc = 'newsItem_ASC',
  NewsItemDesc = 'newsItem_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNewsItemSharingHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  StatusMessageAsc = 'statusMessage_ASC',
  StatusMessageDesc = 'statusMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNewsItemSharingsBy {
  B2bAppContextAsc = 'b2bAppContext_ASC',
  B2bAppContextDesc = 'b2bAppContext_DESC',
  NewsItemAsc = 'newsItem_ASC',
  NewsItemDesc = 'newsItem_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  StatusMessageAsc = 'statusMessage_ASC',
  StatusMessageDesc = 'statusMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNewsItemTemplateHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNewsItemTemplatesBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNewsItemUserReadHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNewsItemUserReadsBy {
  NewsItemAsc = 'newsItem_ASC',
  NewsItemDesc = 'newsItem_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNewsItemsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  BodyAsc = 'body_ASC',
  BodyDesc = 'body_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ValidBeforeAsc = 'validBefore_ASC',
  ValidBeforeDesc = 'validBefore_DESC',
  SendAtAsc = 'sendAt_ASC',
  SendAtDesc = 'sendAt_DESC',
  ScopesAsc = 'scopes_ASC',
  ScopesDesc = 'scopes_DESC',
  SentAtAsc = 'sentAt_ASC',
  SentAtDesc = 'sentAt_DESC',
  IsPublishedAsc = 'isPublished_ASC',
  IsPublishedDesc = 'isPublished_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNotificationAnonymousSettingHistoryRecordsBy {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  MessageTypeAsc = 'messageType_ASC',
  MessageTypeDesc = 'messageType_DESC',
  MessageTransportAsc = 'messageTransport_ASC',
  MessageTransportDesc = 'messageTransport_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNotificationAnonymousSettingsBy {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  MessageTypeAsc = 'messageType_ASC',
  MessageTypeDesc = 'messageType_DESC',
  MessageTransportAsc = 'messageTransport_ASC',
  MessageTransportDesc = 'messageTransport_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortNotificationUserSettingHistoryRecordsBy {
  MessageTypeAsc = 'messageType_ASC',
  MessageTypeDesc = 'messageType_DESC',
  MessageTransportAsc = 'messageTransport_ASC',
  MessageTransportDesc = 'messageTransport_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortNotificationUserSettingsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  MessageTypeAsc = 'messageType_ASC',
  MessageTypeDesc = 'messageType_DESC',
  MessageTransportAsc = 'messageTransport_ASC',
  MessageTransportDesc = 'messageTransport_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortOidcClientHistoryRecordsBy {
  ClientIdAsc = 'clientId_ASC',
  ClientIdDesc = 'clientId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  CanAuthorizeSuperUsersAsc = 'canAuthorizeSuperUsers_ASC',
  CanAuthorizeSuperUsersDesc = 'canAuthorizeSuperUsers_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortOidcClientsBy {
  ClientIdAsc = 'clientId_ASC',
  ClientIdDesc = 'clientId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IsEnabledAsc = 'isEnabled_ASC',
  IsEnabledDesc = 'isEnabled_DESC',
  CanAuthorizeSuperUsersAsc = 'canAuthorizeSuperUsers_ASC',
  CanAuthorizeSuperUsersDesc = 'canAuthorizeSuperUsers_DESC',
  ExpiresAtAsc = 'expiresAt_ASC',
  ExpiresAtDesc = 'expiresAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC'
}

export enum SortOrganizationEmployeeHistoryRecordsBy {
  InviteCodeAsc = 'inviteCode_ASC',
  InviteCodeDesc = 'inviteCode_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  IsAcceptedAsc = 'isAccepted_ASC',
  IsAcceptedDesc = 'isAccepted_DESC',
  IsRejectedAsc = 'isRejected_ASC',
  IsRejectedDesc = 'isRejected_DESC',
  IsBlockedAsc = 'isBlocked_ASC',
  IsBlockedDesc = 'isBlocked_DESC',
  HasAllSpecializationsAsc = 'hasAllSpecializations_ASC',
  HasAllSpecializationsDesc = 'hasAllSpecializations_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortOrganizationEmployeeRoleHistoryRecordsBy {
  IsDefaultAsc = 'isDefault_ASC',
  IsDefaultDesc = 'isDefault_DESC',
  IsEditableAsc = 'isEditable_ASC',
  IsEditableDesc = 'isEditable_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CanManageOrganizationAsc = 'canManageOrganization_ASC',
  CanManageOrganizationDesc = 'canManageOrganization_DESC',
  CanReadEmployeesAsc = 'canReadEmployees_ASC',
  CanReadEmployeesDesc = 'canReadEmployees_DESC',
  CanManageEmployeesAsc = 'canManageEmployees_ASC',
  CanManageEmployeesDesc = 'canManageEmployees_DESC',
  CanManageRolesAsc = 'canManageRoles_ASC',
  CanManageRolesDesc = 'canManageRoles_DESC',
  CanManageIntegrationsAsc = 'canManageIntegrations_ASC',
  CanManageIntegrationsDesc = 'canManageIntegrations_DESC',
  CanReadPropertiesAsc = 'canReadProperties_ASC',
  CanReadPropertiesDesc = 'canReadProperties_DESC',
  CanManagePropertiesAsc = 'canManageProperties_ASC',
  CanManagePropertiesDesc = 'canManageProperties_DESC',
  CanReadDocumentsAsc = 'canReadDocuments_ASC',
  CanReadDocumentsDesc = 'canReadDocuments_DESC',
  CanManageDocumentsAsc = 'canManageDocuments_ASC',
  CanManageDocumentsDesc = 'canManageDocuments_DESC',
  CanReadTicketsAsc = 'canReadTickets_ASC',
  CanReadTicketsDesc = 'canReadTickets_DESC',
  CanManageTicketsAsc = 'canManageTickets_ASC',
  CanManageTicketsDesc = 'canManageTickets_DESC',
  CanManageMetersAsc = 'canManageMeters_ASC',
  CanManageMetersDesc = 'canManageMeters_DESC',
  CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
  CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
  CanReadContactsAsc = 'canReadContacts_ASC',
  CanReadContactsDesc = 'canReadContacts_DESC',
  CanManageContactsAsc = 'canManageContacts_ASC',
  CanManageContactsDesc = 'canManageContacts_DESC',
  CanManageContactRolesAsc = 'canManageContactRoles_ASC',
  CanManageContactRolesDesc = 'canManageContactRoles_DESC',
  CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
  CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
  CanShareTicketsAsc = 'canShareTickets_ASC',
  CanShareTicketsDesc = 'canShareTickets_DESC',
  CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
  CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
  CanImportBillingReceiptsAsc = 'canImportBillingReceipts_ASC',
  CanImportBillingReceiptsDesc = 'canImportBillingReceipts_DESC',
  CanReadPaymentsAsc = 'canReadPayments_ASC',
  CanReadPaymentsDesc = 'canReadPayments_DESC',
  CanInviteNewOrganizationEmployeesAsc = 'canInviteNewOrganizationEmployees_ASC',
  CanInviteNewOrganizationEmployeesDesc = 'canInviteNewOrganizationEmployees_DESC',
  CanBeAssignedAsResponsibleAsc = 'canBeAssignedAsResponsible_ASC',
  CanBeAssignedAsResponsibleDesc = 'canBeAssignedAsResponsible_DESC',
  CanBeAssignedAsExecutorAsc = 'canBeAssignedAsExecutor_ASC',
  CanBeAssignedAsExecutorDesc = 'canBeAssignedAsExecutor_DESC',
  CanManageTicketPropertyHintsAsc = 'canManageTicketPropertyHints_ASC',
  CanManageTicketPropertyHintsDesc = 'canManageTicketPropertyHints_DESC',
  TicketVisibilityTypeAsc = 'ticketVisibilityType_ASC',
  TicketVisibilityTypeDesc = 'ticketVisibilityType_DESC',
  CanManagePropertyScopesAsc = 'canManagePropertyScopes_ASC',
  CanManagePropertyScopesDesc = 'canManagePropertyScopes_DESC',
  CanManageBankAccountsAsc = 'canManageBankAccounts_ASC',
  CanManageBankAccountsDesc = 'canManageBankAccounts_DESC',
  CanManageBankAccountReportTasksAsc = 'canManageBankAccountReportTasks_ASC',
  CanManageBankAccountReportTasksDesc = 'canManageBankAccountReportTasks_DESC',
  CanManageBankIntegrationAccountContextsAsc = 'canManageBankIntegrationAccountContexts_ASC',
  CanManageBankIntegrationAccountContextsDesc = 'canManageBankIntegrationAccountContexts_DESC',
  CanManageBankIntegrationOrganizationContextsAsc = 'canManageBankIntegrationOrganizationContexts_ASC',
  CanManageBankIntegrationOrganizationContextsDesc = 'canManageBankIntegrationOrganizationContexts_DESC',
  CanManageBankContractorAccountsAsc = 'canManageBankContractorAccounts_ASC',
  CanManageBankContractorAccountsDesc = 'canManageBankContractorAccounts_DESC',
  CanManageBankTransactionsAsc = 'canManageBankTransactions_ASC',
  CanManageBankTransactionsDesc = 'canManageBankTransactions_DESC',
  CanManageBankAccountReportsAsc = 'canManageBankAccountReports_ASC',
  CanManageBankAccountReportsDesc = 'canManageBankAccountReports_DESC',
  CanReadIncidentsAsc = 'canReadIncidents_ASC',
  CanReadIncidentsDesc = 'canReadIncidents_DESC',
  CanManageIncidentsAsc = 'canManageIncidents_ASC',
  CanManageIncidentsDesc = 'canManageIncidents_DESC',
  CanReadNewsItemsAsc = 'canReadNewsItems_ASC',
  CanReadNewsItemsDesc = 'canReadNewsItems_DESC',
  CanManageNewsItemsAsc = 'canManageNewsItems_ASC',
  CanManageNewsItemsDesc = 'canManageNewsItems_DESC',
  CanManageNewsItemTemplatesAsc = 'canManageNewsItemTemplates_ASC',
  CanManageNewsItemTemplatesDesc = 'canManageNewsItemTemplates_DESC',
  CanManageCallRecordsAsc = 'canManageCallRecords_ASC',
  CanManageCallRecordsDesc = 'canManageCallRecords_DESC',
  CanDownloadCallRecordsAsc = 'canDownloadCallRecords_ASC',
  CanDownloadCallRecordsDesc = 'canDownloadCallRecords_DESC',
  CanManageMobileFeatureConfigsAsc = 'canManageMobileFeatureConfigs_ASC',
  CanManageMobileFeatureConfigsDesc = 'canManageMobileFeatureConfigs_DESC',
  CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
  CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
  CanReadAnalyticsAsc = 'canReadAnalytics_ASC',
  CanReadAnalyticsDesc = 'canReadAnalytics_DESC',
  CanReadInvoicesAsc = 'canReadInvoices_ASC',
  CanReadInvoicesDesc = 'canReadInvoices_DESC',
  CanManageInvoicesAsc = 'canManageInvoices_ASC',
  CanManageInvoicesDesc = 'canManageInvoices_DESC',
  CanReadMarketItemsAsc = 'canReadMarketItems_ASC',
  CanReadMarketItemsDesc = 'canReadMarketItems_DESC',
  CanManageMarketItemsAsc = 'canManageMarketItems_ASC',
  CanManageMarketItemsDesc = 'canManageMarketItems_DESC',
  CanReadMetersAsc = 'canReadMeters_ASC',
  CanReadMetersDesc = 'canReadMeters_DESC',
  CanReadSettingsAsc = 'canReadSettings_ASC',
  CanReadSettingsDesc = 'canReadSettings_DESC',
  CanReadExternalReportsAsc = 'canReadExternalReports_ASC',
  CanReadExternalReportsDesc = 'canReadExternalReports_DESC',
  CanReadServicesAsc = 'canReadServices_ASC',
  CanReadServicesDesc = 'canReadServices_DESC',
  CanReadCallRecordsAsc = 'canReadCallRecords_ASC',
  CanReadCallRecordsDesc = 'canReadCallRecords_DESC',
  CanReadMarketItemPricesAsc = 'canReadMarketItemPrices_ASC',
  CanReadMarketItemPricesDesc = 'canReadMarketItemPrices_DESC',
  CanManageMarketItemPricesAsc = 'canManageMarketItemPrices_ASC',
  CanManageMarketItemPricesDesc = 'canManageMarketItemPrices_DESC',
  CanReadMarketPriceScopesAsc = 'canReadMarketPriceScopes_ASC',
  CanReadMarketPriceScopesDesc = 'canReadMarketPriceScopes_DESC',
  CanManageMarketPriceScopesAsc = 'canManageMarketPriceScopes_ASC',
  CanManageMarketPriceScopesDesc = 'canManageMarketPriceScopes_DESC',
  CanReadPaymentsWithInvoicesAsc = 'canReadPaymentsWithInvoices_ASC',
  CanReadPaymentsWithInvoicesDesc = 'canReadPaymentsWithInvoices_DESC',
  CanReadMarketplaceAsc = 'canReadMarketplace_ASC',
  CanReadMarketplaceDesc = 'canReadMarketplace_DESC',
  CanManageMarketplaceAsc = 'canManageMarketplace_ASC',
  CanManageMarketplaceDesc = 'canManageMarketplace_DESC',
  CanReadTourAsc = 'canReadTour_ASC',
  CanReadTourDesc = 'canReadTour_DESC',
  CanManageTourAsc = 'canManageTour_ASC',
  CanManageTourDesc = 'canManageTour_DESC',
  CanReadMarketSettingAsc = 'canReadMarketSetting_ASC',
  CanReadMarketSettingDesc = 'canReadMarketSetting_DESC',
  CanManageMarketSettingAsc = 'canManageMarketSetting_ASC',
  CanManageMarketSettingDesc = 'canManageMarketSetting_DESC',
  CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
  CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortOrganizationEmployeeRolesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IsDefaultAsc = 'isDefault_ASC',
  IsDefaultDesc = 'isDefault_DESC',
  IsEditableAsc = 'isEditable_ASC',
  IsEditableDesc = 'isEditable_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  CanManageOrganizationAsc = 'canManageOrganization_ASC',
  CanManageOrganizationDesc = 'canManageOrganization_DESC',
  CanReadEmployeesAsc = 'canReadEmployees_ASC',
  CanReadEmployeesDesc = 'canReadEmployees_DESC',
  CanManageEmployeesAsc = 'canManageEmployees_ASC',
  CanManageEmployeesDesc = 'canManageEmployees_DESC',
  CanManageRolesAsc = 'canManageRoles_ASC',
  CanManageRolesDesc = 'canManageRoles_DESC',
  CanManageIntegrationsAsc = 'canManageIntegrations_ASC',
  CanManageIntegrationsDesc = 'canManageIntegrations_DESC',
  CanReadPropertiesAsc = 'canReadProperties_ASC',
  CanReadPropertiesDesc = 'canReadProperties_DESC',
  CanManagePropertiesAsc = 'canManageProperties_ASC',
  CanManagePropertiesDesc = 'canManageProperties_DESC',
  CanReadDocumentsAsc = 'canReadDocuments_ASC',
  CanReadDocumentsDesc = 'canReadDocuments_DESC',
  CanManageDocumentsAsc = 'canManageDocuments_ASC',
  CanManageDocumentsDesc = 'canManageDocuments_DESC',
  CanReadTicketsAsc = 'canReadTickets_ASC',
  CanReadTicketsDesc = 'canReadTickets_DESC',
  CanManageTicketsAsc = 'canManageTickets_ASC',
  CanManageTicketsDesc = 'canManageTickets_DESC',
  CanManageMetersAsc = 'canManageMeters_ASC',
  CanManageMetersDesc = 'canManageMeters_DESC',
  CanManageMeterReadingsAsc = 'canManageMeterReadings_ASC',
  CanManageMeterReadingsDesc = 'canManageMeterReadings_DESC',
  CanReadContactsAsc = 'canReadContacts_ASC',
  CanReadContactsDesc = 'canReadContacts_DESC',
  CanManageContactsAsc = 'canManageContacts_ASC',
  CanManageContactsDesc = 'canManageContacts_DESC',
  CanManageContactRolesAsc = 'canManageContactRoles_ASC',
  CanManageContactRolesDesc = 'canManageContactRoles_DESC',
  CanManageTicketCommentsAsc = 'canManageTicketComments_ASC',
  CanManageTicketCommentsDesc = 'canManageTicketComments_DESC',
  CanShareTicketsAsc = 'canShareTickets_ASC',
  CanShareTicketsDesc = 'canShareTickets_DESC',
  CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
  CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
  CanImportBillingReceiptsAsc = 'canImportBillingReceipts_ASC',
  CanImportBillingReceiptsDesc = 'canImportBillingReceipts_DESC',
  CanReadPaymentsAsc = 'canReadPayments_ASC',
  CanReadPaymentsDesc = 'canReadPayments_DESC',
  CanInviteNewOrganizationEmployeesAsc = 'canInviteNewOrganizationEmployees_ASC',
  CanInviteNewOrganizationEmployeesDesc = 'canInviteNewOrganizationEmployees_DESC',
  CanBeAssignedAsResponsibleAsc = 'canBeAssignedAsResponsible_ASC',
  CanBeAssignedAsResponsibleDesc = 'canBeAssignedAsResponsible_DESC',
  CanBeAssignedAsExecutorAsc = 'canBeAssignedAsExecutor_ASC',
  CanBeAssignedAsExecutorDesc = 'canBeAssignedAsExecutor_DESC',
  CanManageTicketPropertyHintsAsc = 'canManageTicketPropertyHints_ASC',
  CanManageTicketPropertyHintsDesc = 'canManageTicketPropertyHints_DESC',
  TicketVisibilityTypeAsc = 'ticketVisibilityType_ASC',
  TicketVisibilityTypeDesc = 'ticketVisibilityType_DESC',
  CanManagePropertyScopesAsc = 'canManagePropertyScopes_ASC',
  CanManagePropertyScopesDesc = 'canManagePropertyScopes_DESC',
  CanManageBankAccountsAsc = 'canManageBankAccounts_ASC',
  CanManageBankAccountsDesc = 'canManageBankAccounts_DESC',
  CanManageBankAccountReportTasksAsc = 'canManageBankAccountReportTasks_ASC',
  CanManageBankAccountReportTasksDesc = 'canManageBankAccountReportTasks_DESC',
  CanManageBankIntegrationAccountContextsAsc = 'canManageBankIntegrationAccountContexts_ASC',
  CanManageBankIntegrationAccountContextsDesc = 'canManageBankIntegrationAccountContexts_DESC',
  CanManageBankIntegrationOrganizationContextsAsc = 'canManageBankIntegrationOrganizationContexts_ASC',
  CanManageBankIntegrationOrganizationContextsDesc = 'canManageBankIntegrationOrganizationContexts_DESC',
  CanManageBankContractorAccountsAsc = 'canManageBankContractorAccounts_ASC',
  CanManageBankContractorAccountsDesc = 'canManageBankContractorAccounts_DESC',
  CanManageBankTransactionsAsc = 'canManageBankTransactions_ASC',
  CanManageBankTransactionsDesc = 'canManageBankTransactions_DESC',
  CanManageBankAccountReportsAsc = 'canManageBankAccountReports_ASC',
  CanManageBankAccountReportsDesc = 'canManageBankAccountReports_DESC',
  CanReadIncidentsAsc = 'canReadIncidents_ASC',
  CanReadIncidentsDesc = 'canReadIncidents_DESC',
  CanManageIncidentsAsc = 'canManageIncidents_ASC',
  CanManageIncidentsDesc = 'canManageIncidents_DESC',
  CanReadNewsItemsAsc = 'canReadNewsItems_ASC',
  CanReadNewsItemsDesc = 'canReadNewsItems_DESC',
  CanManageNewsItemsAsc = 'canManageNewsItems_ASC',
  CanManageNewsItemsDesc = 'canManageNewsItems_DESC',
  CanManageNewsItemTemplatesAsc = 'canManageNewsItemTemplates_ASC',
  CanManageNewsItemTemplatesDesc = 'canManageNewsItemTemplates_DESC',
  CanManageCallRecordsAsc = 'canManageCallRecords_ASC',
  CanManageCallRecordsDesc = 'canManageCallRecords_DESC',
  CanDownloadCallRecordsAsc = 'canDownloadCallRecords_ASC',
  CanDownloadCallRecordsDesc = 'canDownloadCallRecords_DESC',
  CanManageMobileFeatureConfigsAsc = 'canManageMobileFeatureConfigs_ASC',
  CanManageMobileFeatureConfigsDesc = 'canManageMobileFeatureConfigs_DESC',
  CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
  CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
  CanReadAnalyticsAsc = 'canReadAnalytics_ASC',
  CanReadAnalyticsDesc = 'canReadAnalytics_DESC',
  CanReadInvoicesAsc = 'canReadInvoices_ASC',
  CanReadInvoicesDesc = 'canReadInvoices_DESC',
  CanManageInvoicesAsc = 'canManageInvoices_ASC',
  CanManageInvoicesDesc = 'canManageInvoices_DESC',
  CanReadMarketItemsAsc = 'canReadMarketItems_ASC',
  CanReadMarketItemsDesc = 'canReadMarketItems_DESC',
  CanManageMarketItemsAsc = 'canManageMarketItems_ASC',
  CanManageMarketItemsDesc = 'canManageMarketItems_DESC',
  CanReadMetersAsc = 'canReadMeters_ASC',
  CanReadMetersDesc = 'canReadMeters_DESC',
  CanReadSettingsAsc = 'canReadSettings_ASC',
  CanReadSettingsDesc = 'canReadSettings_DESC',
  CanReadExternalReportsAsc = 'canReadExternalReports_ASC',
  CanReadExternalReportsDesc = 'canReadExternalReports_DESC',
  CanReadServicesAsc = 'canReadServices_ASC',
  CanReadServicesDesc = 'canReadServices_DESC',
  CanReadCallRecordsAsc = 'canReadCallRecords_ASC',
  CanReadCallRecordsDesc = 'canReadCallRecords_DESC',
  CanReadMarketItemPricesAsc = 'canReadMarketItemPrices_ASC',
  CanReadMarketItemPricesDesc = 'canReadMarketItemPrices_DESC',
  CanManageMarketItemPricesAsc = 'canManageMarketItemPrices_ASC',
  CanManageMarketItemPricesDesc = 'canManageMarketItemPrices_DESC',
  CanReadMarketPriceScopesAsc = 'canReadMarketPriceScopes_ASC',
  CanReadMarketPriceScopesDesc = 'canReadMarketPriceScopes_DESC',
  CanManageMarketPriceScopesAsc = 'canManageMarketPriceScopes_ASC',
  CanManageMarketPriceScopesDesc = 'canManageMarketPriceScopes_DESC',
  CanReadPaymentsWithInvoicesAsc = 'canReadPaymentsWithInvoices_ASC',
  CanReadPaymentsWithInvoicesDesc = 'canReadPaymentsWithInvoices_DESC',
  CanReadMarketplaceAsc = 'canReadMarketplace_ASC',
  CanReadMarketplaceDesc = 'canReadMarketplace_DESC',
  CanManageMarketplaceAsc = 'canManageMarketplace_ASC',
  CanManageMarketplaceDesc = 'canManageMarketplace_DESC',
  CanReadTourAsc = 'canReadTour_ASC',
  CanReadTourDesc = 'canReadTour_DESC',
  CanManageTourAsc = 'canManageTour_ASC',
  CanManageTourDesc = 'canManageTour_DESC',
  CanReadMarketSettingAsc = 'canReadMarketSetting_ASC',
  CanReadMarketSettingDesc = 'canReadMarketSetting_DESC',
  CanManageMarketSettingAsc = 'canManageMarketSetting_ASC',
  CanManageMarketSettingDesc = 'canManageMarketSetting_DESC',
  CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
  CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC'
}

export enum SortOrganizationEmployeeSpecializationHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortOrganizationEmployeeSpecializationsBy {
  EmployeeAsc = 'employee_ASC',
  EmployeeDesc = 'employee_DESC',
  SpecializationAsc = 'specialization_ASC',
  SpecializationDesc = 'specialization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortOrganizationEmployeesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  InviteCodeAsc = 'inviteCode_ASC',
  InviteCodeDesc = 'inviteCode_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  RoleAsc = 'role_ASC',
  RoleDesc = 'role_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  IsAcceptedAsc = 'isAccepted_ASC',
  IsAcceptedDesc = 'isAccepted_DESC',
  IsRejectedAsc = 'isRejected_ASC',
  IsRejectedDesc = 'isRejected_DESC',
  IsBlockedAsc = 'isBlocked_ASC',
  IsBlockedDesc = 'isBlocked_DESC',
  HasAllSpecializationsAsc = 'hasAllSpecializations_ASC',
  HasAllSpecializationsDesc = 'hasAllSpecializations_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortOrganizationHistoryRecordsBy {
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PhoneNumberPrefixAsc = 'phoneNumberPrefix_ASC',
  PhoneNumberPrefixDesc = 'phoneNumberPrefix_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortOrganizationLinkHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortOrganizationLinksBy {
  FromAsc = 'from_ASC',
  FromDesc = 'from_DESC',
  ToAsc = 'to_ASC',
  ToDesc = 'to_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortOrganizationsBy {
  CountryAsc = 'country_ASC',
  CountryDesc = 'country_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TinAsc = 'tin_ASC',
  TinDesc = 'tin_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  PhoneNumberPrefixAsc = 'phoneNumberPrefix_ASC',
  PhoneNumberPrefixDesc = 'phoneNumberPrefix_DESC',
  EmployeesAsc = 'employees_ASC',
  EmployeesDesc = 'employees_DESC',
  RelatedOrganizationsAsc = 'relatedOrganizations_ASC',
  RelatedOrganizationsDesc = 'relatedOrganizations_DESC',
  ImportRemoteSystemAsc = 'importRemoteSystem_ASC',
  ImportRemoteSystemDesc = 'importRemoteSystem_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPaymentHistoryRecordsBy {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ExplicitFeeAsc = 'explicitFee_ASC',
  ExplicitFeeDesc = 'explicitFee_DESC',
  ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
  ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
  ImplicitFeeAsc = 'implicitFee_ASC',
  ImplicitFeeDesc = 'implicitFee_DESC',
  ServiceFeeAsc = 'serviceFee_ASC',
  ServiceFeeDesc = 'serviceFee_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  AdvancedAtAsc = 'advancedAt_ASC',
  AdvancedAtDesc = 'advancedAt_DESC',
  DepositedDateAsc = 'depositedDate_ASC',
  DepositedDateDesc = 'depositedDate_DESC',
  TransferDateAsc = 'transferDate_ASC',
  TransferDateDesc = 'transferDate_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  RecipientBicAsc = 'recipientBic_ASC',
  RecipientBicDesc = 'recipientBic_DESC',
  RecipientBankAccountAsc = 'recipientBankAccount_ASC',
  RecipientBankAccountDesc = 'recipientBankAccount_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPaymentsBy {
  AmountAsc = 'amount_ASC',
  AmountDesc = 'amount_DESC',
  ExplicitFeeAsc = 'explicitFee_ASC',
  ExplicitFeeDesc = 'explicitFee_DESC',
  ExplicitServiceChargeAsc = 'explicitServiceCharge_ASC',
  ExplicitServiceChargeDesc = 'explicitServiceCharge_DESC',
  ImplicitFeeAsc = 'implicitFee_ASC',
  ImplicitFeeDesc = 'implicitFee_DESC',
  ServiceFeeAsc = 'serviceFee_ASC',
  ServiceFeeDesc = 'serviceFee_DESC',
  CurrencyCodeAsc = 'currencyCode_ASC',
  CurrencyCodeDesc = 'currencyCode_DESC',
  AdvancedAtAsc = 'advancedAt_ASC',
  AdvancedAtDesc = 'advancedAt_DESC',
  DepositedDateAsc = 'depositedDate_ASC',
  DepositedDateDesc = 'depositedDate_DESC',
  TransferDateAsc = 'transferDate_ASC',
  TransferDateDesc = 'transferDate_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  ReceiptAsc = 'receipt_ASC',
  ReceiptDesc = 'receipt_DESC',
  InvoiceAsc = 'invoice_ASC',
  InvoiceDesc = 'invoice_DESC',
  MultiPaymentAsc = 'multiPayment_ASC',
  MultiPaymentDesc = 'multiPayment_DESC',
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  RecipientBicAsc = 'recipientBic_ASC',
  RecipientBicDesc = 'recipientBic_DESC',
  RecipientBankAccountAsc = 'recipientBankAccount_ASC',
  RecipientBankAccountDesc = 'recipientBankAccount_DESC',
  ImportIdAsc = 'importId_ASC',
  ImportIdDesc = 'importId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPaymentsFilterTemplateHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPaymentsFilterTemplatesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmployeeAsc = 'employee_ASC',
  EmployeeDesc = 'employee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPropertiesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UnitsCountAsc = 'unitsCount_ASC',
  UnitsCountDesc = 'unitsCount_DESC',
  UninhabitedUnitsCountAsc = 'uninhabitedUnitsCount_ASC',
  UninhabitedUnitsCountDesc = 'uninhabitedUnitsCount_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  YearOfConstructionAsc = 'yearOfConstruction_ASC',
  YearOfConstructionDesc = 'yearOfConstruction_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPropertyHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UnitsCountAsc = 'unitsCount_ASC',
  UnitsCountDesc = 'unitsCount_DESC',
  UninhabitedUnitsCountAsc = 'uninhabitedUnitsCount_ASC',
  UninhabitedUnitsCountDesc = 'uninhabitedUnitsCount_DESC',
  IsApprovedAsc = 'isApproved_ASC',
  IsApprovedDesc = 'isApproved_DESC',
  YearOfConstructionAsc = 'yearOfConstruction_ASC',
  YearOfConstructionDesc = 'yearOfConstruction_DESC',
  AreaAsc = 'area_ASC',
  AreaDesc = 'area_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPropertyMeterHistoryRecordsBy {
  NumberOfTariffsAsc = 'numberOfTariffs_ASC',
  NumberOfTariffsDesc = 'numberOfTariffs_DESC',
  InstallationDateAsc = 'installationDate_ASC',
  InstallationDateDesc = 'installationDate_DESC',
  CommissioningDateAsc = 'commissioningDate_ASC',
  CommissioningDateDesc = 'commissioningDate_DESC',
  VerificationDateAsc = 'verificationDate_ASC',
  VerificationDateDesc = 'verificationDate_DESC',
  NextVerificationDateAsc = 'nextVerificationDate_ASC',
  NextVerificationDateDesc = 'nextVerificationDate_DESC',
  ControlReadingsDateAsc = 'controlReadingsDate_ASC',
  ControlReadingsDateDesc = 'controlReadingsDate_DESC',
  SealingDateAsc = 'sealingDate_ASC',
  SealingDateDesc = 'sealingDate_DESC',
  ArchiveDateAsc = 'archiveDate_ASC',
  ArchiveDateDesc = 'archiveDate_DESC',
  IsAutomaticAsc = 'isAutomatic_ASC',
  IsAutomaticDesc = 'isAutomatic_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPropertyMeterReadingHistoryRecordsBy {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  Value1Asc = 'value1_ASC',
  Value1Desc = 'value1_DESC',
  Value2Asc = 'value2_ASC',
  Value2Desc = 'value2_DESC',
  Value3Asc = 'value3_ASC',
  Value3Desc = 'value3_DESC',
  Value4Asc = 'value4_ASC',
  Value4Desc = 'value4_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPropertyMeterReadingsBy {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  MeterAsc = 'meter_ASC',
  MeterDesc = 'meter_DESC',
  Value1Asc = 'value1_ASC',
  Value1Desc = 'value1_DESC',
  Value2Asc = 'value2_ASC',
  Value2Desc = 'value2_DESC',
  Value3Asc = 'value3_ASC',
  Value3Desc = 'value3_DESC',
  Value4Asc = 'value4_ASC',
  Value4Desc = 'value4_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC'
}

export enum SortPropertyMetersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NumberOfTariffsAsc = 'numberOfTariffs_ASC',
  NumberOfTariffsDesc = 'numberOfTariffs_DESC',
  InstallationDateAsc = 'installationDate_ASC',
  InstallationDateDesc = 'installationDate_DESC',
  CommissioningDateAsc = 'commissioningDate_ASC',
  CommissioningDateDesc = 'commissioningDate_DESC',
  VerificationDateAsc = 'verificationDate_ASC',
  VerificationDateDesc = 'verificationDate_DESC',
  NextVerificationDateAsc = 'nextVerificationDate_ASC',
  NextVerificationDateDesc = 'nextVerificationDate_DESC',
  ControlReadingsDateAsc = 'controlReadingsDate_ASC',
  ControlReadingsDateDesc = 'controlReadingsDate_DESC',
  SealingDateAsc = 'sealingDate_ASC',
  SealingDateDesc = 'sealingDate_DESC',
  ArchiveDateAsc = 'archiveDate_ASC',
  ArchiveDateDesc = 'archiveDate_DESC',
  IsAutomaticAsc = 'isAutomatic_ASC',
  IsAutomaticDesc = 'isAutomatic_DESC',
  ResourceAsc = 'resource_ASC',
  ResourceDesc = 'resource_DESC',
  B2bAppAsc = 'b2bApp_ASC',
  B2bAppDesc = 'b2bApp_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPropertyScopeHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  HasAllPropertiesAsc = 'hasAllProperties_ASC',
  HasAllPropertiesDesc = 'hasAllProperties_DESC',
  HasAllEmployeesAsc = 'hasAllEmployees_ASC',
  HasAllEmployeesDesc = 'hasAllEmployees_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPropertyScopeOrganizationEmployeeHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPropertyScopeOrganizationEmployeesBy {
  PropertyScopeAsc = 'propertyScope_ASC',
  PropertyScopeDesc = 'propertyScope_DESC',
  EmployeeAsc = 'employee_ASC',
  EmployeeDesc = 'employee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPropertyScopePropertiesBy {
  PropertyScopeAsc = 'propertyScope_ASC',
  PropertyScopeDesc = 'propertyScope_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortPropertyScopePropertyHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortPropertyScopesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  HasAllPropertiesAsc = 'hasAllProperties_ASC',
  HasAllPropertiesDesc = 'hasAllProperties_DESC',
  HasAllEmployeesAsc = 'hasAllEmployees_ASC',
  HasAllEmployeesDesc = 'hasAllEmployees_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortRecurrentPaymentContextHistoryRecordsBy {
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  LimitAsc = 'limit_ASC',
  LimitDesc = 'limit_DESC',
  AutoPayReceiptsAsc = 'autoPayReceipts_ASC',
  AutoPayReceiptsDesc = 'autoPayReceipts_DESC',
  PaymentDayAsc = 'paymentDay_ASC',
  PaymentDayDesc = 'paymentDay_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortRecurrentPaymentContextsBy {
  EnabledAsc = 'enabled_ASC',
  EnabledDesc = 'enabled_DESC',
  LimitAsc = 'limit_ASC',
  LimitDesc = 'limit_DESC',
  AutoPayReceiptsAsc = 'autoPayReceipts_ASC',
  AutoPayReceiptsDesc = 'autoPayReceipts_DESC',
  PaymentDayAsc = 'paymentDay_ASC',
  PaymentDayDesc = 'paymentDay_DESC',
  ServiceConsumerAsc = 'serviceConsumer_ASC',
  ServiceConsumerDesc = 'serviceConsumer_DESC',
  BillingCategoryAsc = 'billingCategory_ASC',
  BillingCategoryDesc = 'billingCategory_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortRecurrentPaymentHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  PayAfterAsc = 'payAfter_ASC',
  PayAfterDesc = 'payAfter_DESC',
  TryCountAsc = 'tryCount_ASC',
  TryCountDesc = 'tryCount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortRecurrentPaymentsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  PayAfterAsc = 'payAfter_ASC',
  PayAfterDesc = 'payAfter_DESC',
  TryCountAsc = 'tryCount_ASC',
  TryCountDesc = 'tryCount_DESC',
  RecurrentPaymentContextAsc = 'recurrentPaymentContext_ASC',
  RecurrentPaymentContextDesc = 'recurrentPaymentContext_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortRemoteClientHistoryRecordsBy {
  DeviceIdAsc = 'deviceId_ASC',
  DeviceIdDesc = 'deviceId_DESC',
  AppIdAsc = 'appId_ASC',
  AppIdDesc = 'appId_DESC',
  PushTokenAsc = 'pushToken_ASC',
  PushTokenDesc = 'pushToken_DESC',
  PushTransportAsc = 'pushTransport_ASC',
  PushTransportDesc = 'pushTransport_DESC',
  DevicePlatformAsc = 'devicePlatform_ASC',
  DevicePlatformDesc = 'devicePlatform_DESC',
  PushTypeAsc = 'pushType_ASC',
  PushTypeDesc = 'pushType_DESC',
  PushTokenVoIpAsc = 'pushTokenVoIP_ASC',
  PushTokenVoIpDesc = 'pushTokenVoIP_DESC',
  PushTransportVoIpAsc = 'pushTransportVoIP_ASC',
  PushTransportVoIpDesc = 'pushTransportVoIP_DESC',
  PushTypeVoIpAsc = 'pushTypeVoIP_ASC',
  PushTypeVoIpDesc = 'pushTypeVoIP_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortRemoteClientsBy {
  DeviceIdAsc = 'deviceId_ASC',
  DeviceIdDesc = 'deviceId_DESC',
  AppIdAsc = 'appId_ASC',
  AppIdDesc = 'appId_DESC',
  PushTokenAsc = 'pushToken_ASC',
  PushTokenDesc = 'pushToken_DESC',
  PushTransportAsc = 'pushTransport_ASC',
  PushTransportDesc = 'pushTransport_DESC',
  DevicePlatformAsc = 'devicePlatform_ASC',
  DevicePlatformDesc = 'devicePlatform_DESC',
  PushTypeAsc = 'pushType_ASC',
  PushTypeDesc = 'pushType_DESC',
  PushTokenVoIpAsc = 'pushTokenVoIP_ASC',
  PushTokenVoIpDesc = 'pushTokenVoIP_DESC',
  PushTransportVoIpAsc = 'pushTransportVoIP_ASC',
  PushTransportVoIpDesc = 'pushTransportVoIP_DESC',
  PushTypeVoIpAsc = 'pushTypeVoIP_ASC',
  PushTypeVoIpDesc = 'pushTypeVoIP_DESC',
  OwnerAsc = 'owner_ASC',
  OwnerDesc = 'owner_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortResidentBillingReceiptsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  ToPayAsc = 'toPay_ASC',
  ToPayDesc = 'toPay_DESC',
  PrintableNumberAsc = 'printableNumber_ASC',
  PrintableNumberDesc = 'printableNumber_DESC',
  ServiceConsumerAsc = 'serviceConsumer_ASC',
  ServiceConsumerDesc = 'serviceConsumer_DESC'
}

export enum SortResidentBillingVirtualReceiptsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PeriodAsc = 'period_ASC',
  PeriodDesc = 'period_DESC',
  ToPayAsc = 'toPay_ASC',
  ToPayDesc = 'toPay_DESC',
  PrintableNumberAsc = 'printableNumber_ASC',
  PrintableNumberDesc = 'printableNumber_DESC',
  ServiceConsumerAsc = 'serviceConsumer_ASC',
  ServiceConsumerDesc = 'serviceConsumer_DESC'
}

export enum SortResidentHistoryRecordsBy {
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortResidentsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  AddressKeyAsc = 'addressKey_ASC',
  AddressKeyDesc = 'addressKey_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortServiceConsumerHistoryRecordsBy {
  PaymentCategoryAsc = 'paymentCategory_ASC',
  PaymentCategoryDesc = 'paymentCategory_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  IsDiscoveredAsc = 'isDiscovered_ASC',
  IsDiscoveredDesc = 'isDiscovered_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortServiceConsumersBy {
  PaymentCategoryAsc = 'paymentCategory_ASC',
  PaymentCategoryDesc = 'paymentCategory_DESC',
  ResidentAsc = 'resident_ASC',
  ResidentDesc = 'resident_DESC',
  BillingIntegrationContextAsc = 'billingIntegrationContext_ASC',
  BillingIntegrationContextDesc = 'billingIntegrationContext_DESC',
  AcquiringIntegrationContextAsc = 'acquiringIntegrationContext_ASC',
  AcquiringIntegrationContextDesc = 'acquiringIntegrationContext_DESC',
  AccountNumberAsc = 'accountNumber_ASC',
  AccountNumberDesc = 'accountNumber_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IsDiscoveredAsc = 'isDiscovered_ASC',
  IsDiscoveredDesc = 'isDiscovered_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortServiceSubscriptionHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IsTrialAsc = 'isTrial_ASC',
  IsTrialDesc = 'isTrial_DESC',
  StartAtAsc = 'startAt_ASC',
  StartAtDesc = 'startAt_DESC',
  FinishAtAsc = 'finishAt_ASC',
  FinishAtDesc = 'finishAt_DESC',
  UnitsCountAsc = 'unitsCount_ASC',
  UnitsCountDesc = 'unitsCount_DESC',
  UnitPriceAsc = 'unitPrice_ASC',
  UnitPriceDesc = 'unitPrice_DESC',
  TotalPriceAsc = 'totalPrice_ASC',
  TotalPriceDesc = 'totalPrice_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortServiceSubscriptionsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IsTrialAsc = 'isTrial_ASC',
  IsTrialDesc = 'isTrial_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StartAtAsc = 'startAt_ASC',
  StartAtDesc = 'startAt_DESC',
  FinishAtAsc = 'finishAt_ASC',
  FinishAtDesc = 'finishAt_DESC',
  UnitsCountAsc = 'unitsCount_ASC',
  UnitsCountDesc = 'unitsCount_DESC',
  UnitPriceAsc = 'unitPrice_ASC',
  UnitPriceDesc = 'unitPrice_DESC',
  TotalPriceAsc = 'totalPrice_ASC',
  TotalPriceDesc = 'totalPrice_DESC',
  CurrencyAsc = 'currency_ASC',
  CurrencyDesc = 'currency_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTelegramUserChatHistoryRecordsBy {
  TelegramChatIdAsc = 'telegramChatId_ASC',
  TelegramChatIdDesc = 'telegramChatId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTelegramUserChatsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  TelegramChatIdAsc = 'telegramChatId_ASC',
  TelegramChatIdDesc = 'telegramChatId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketAutoAssignmentHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketAutoAssignmentsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  AssigneeAsc = 'assignee_ASC',
  AssigneeDesc = 'assignee_DESC',
  ExecutorAsc = 'executor_ASC',
  ExecutorDesc = 'executor_DESC',
  ClassifierAsc = 'classifier_ASC',
  ClassifierDesc = 'classifier_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketCategoryClassifierHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketCategoryClassifiersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketChangesBy {
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  StatusReopenedCounterFromAsc = 'statusReopenedCounterFrom_ASC',
  StatusReopenedCounterFromDesc = 'statusReopenedCounterFrom_DESC',
  StatusReopenedCounterToAsc = 'statusReopenedCounterTo_ASC',
  StatusReopenedCounterToDesc = 'statusReopenedCounterTo_DESC',
  ReviewValueFromAsc = 'reviewValueFrom_ASC',
  ReviewValueFromDesc = 'reviewValueFrom_DESC',
  ReviewValueToAsc = 'reviewValueTo_ASC',
  ReviewValueToDesc = 'reviewValueTo_DESC',
  ReviewCommentFromAsc = 'reviewCommentFrom_ASC',
  ReviewCommentFromDesc = 'reviewCommentFrom_DESC',
  ReviewCommentToAsc = 'reviewCommentTo_ASC',
  ReviewCommentToDesc = 'reviewCommentTo_DESC',
  FeedbackValueFromAsc = 'feedbackValueFrom_ASC',
  FeedbackValueFromDesc = 'feedbackValueFrom_DESC',
  FeedbackValueToAsc = 'feedbackValueTo_ASC',
  FeedbackValueToDesc = 'feedbackValueTo_DESC',
  FeedbackCommentFromAsc = 'feedbackCommentFrom_ASC',
  FeedbackCommentFromDesc = 'feedbackCommentFrom_DESC',
  FeedbackCommentToAsc = 'feedbackCommentTo_ASC',
  FeedbackCommentToDesc = 'feedbackCommentTo_DESC',
  QualityControlValueFromAsc = 'qualityControlValueFrom_ASC',
  QualityControlValueFromDesc = 'qualityControlValueFrom_DESC',
  QualityControlValueToAsc = 'qualityControlValueTo_ASC',
  QualityControlValueToDesc = 'qualityControlValueTo_DESC',
  QualityControlCommentFromAsc = 'qualityControlCommentFrom_ASC',
  QualityControlCommentFromDesc = 'qualityControlCommentFrom_DESC',
  QualityControlCommentToAsc = 'qualityControlCommentTo_ASC',
  QualityControlCommentToDesc = 'qualityControlCommentTo_DESC',
  StatusReasonFromAsc = 'statusReasonFrom_ASC',
  StatusReasonFromDesc = 'statusReasonFrom_DESC',
  StatusReasonToAsc = 'statusReasonTo_ASC',
  StatusReasonToDesc = 'statusReasonTo_DESC',
  DeadlineFromAsc = 'deadlineFrom_ASC',
  DeadlineFromDesc = 'deadlineFrom_DESC',
  DeadlineToAsc = 'deadlineTo_ASC',
  DeadlineToDesc = 'deadlineTo_DESC',
  ClientNameFromAsc = 'clientNameFrom_ASC',
  ClientNameFromDesc = 'clientNameFrom_DESC',
  ClientNameToAsc = 'clientNameTo_ASC',
  ClientNameToDesc = 'clientNameTo_DESC',
  ClientEmailFromAsc = 'clientEmailFrom_ASC',
  ClientEmailFromDesc = 'clientEmailFrom_DESC',
  ClientEmailToAsc = 'clientEmailTo_ASC',
  ClientEmailToDesc = 'clientEmailTo_DESC',
  ClientPhoneFromAsc = 'clientPhoneFrom_ASC',
  ClientPhoneFromDesc = 'clientPhoneFrom_DESC',
  ClientPhoneToAsc = 'clientPhoneTo_ASC',
  ClientPhoneToDesc = 'clientPhoneTo_DESC',
  DetailsFromAsc = 'detailsFrom_ASC',
  DetailsFromDesc = 'detailsFrom_DESC',
  DetailsToAsc = 'detailsTo_ASC',
  DetailsToDesc = 'detailsTo_DESC',
  IsPaidFromAsc = 'isPaidFrom_ASC',
  IsPaidFromDesc = 'isPaidFrom_DESC',
  IsPaidToAsc = 'isPaidTo_ASC',
  IsPaidToDesc = 'isPaidTo_DESC',
  IsPayableFromAsc = 'isPayableFrom_ASC',
  IsPayableFromDesc = 'isPayableFrom_DESC',
  IsPayableToAsc = 'isPayableTo_ASC',
  IsPayableToDesc = 'isPayableTo_DESC',
  IsEmergencyFromAsc = 'isEmergencyFrom_ASC',
  IsEmergencyFromDesc = 'isEmergencyFrom_DESC',
  IsEmergencyToAsc = 'isEmergencyTo_ASC',
  IsEmergencyToDesc = 'isEmergencyTo_DESC',
  IsWarrantyFromAsc = 'isWarrantyFrom_ASC',
  IsWarrantyFromDesc = 'isWarrantyFrom_DESC',
  IsWarrantyToAsc = 'isWarrantyTo_ASC',
  IsWarrantyToDesc = 'isWarrantyTo_DESC',
  IsResidentTicketFromAsc = 'isResidentTicketFrom_ASC',
  IsResidentTicketFromDesc = 'isResidentTicketFrom_DESC',
  IsResidentTicketToAsc = 'isResidentTicketTo_ASC',
  IsResidentTicketToDesc = 'isResidentTicketTo_DESC',
  CanReadByResidentFromAsc = 'canReadByResidentFrom_ASC',
  CanReadByResidentFromDesc = 'canReadByResidentFrom_DESC',
  CanReadByResidentToAsc = 'canReadByResidentTo_ASC',
  CanReadByResidentToDesc = 'canReadByResidentTo_DESC',
  PropertyAddressFromAsc = 'propertyAddressFrom_ASC',
  PropertyAddressFromDesc = 'propertyAddressFrom_DESC',
  PropertyAddressToAsc = 'propertyAddressTo_ASC',
  PropertyAddressToDesc = 'propertyAddressTo_DESC',
  SectionNameFromAsc = 'sectionNameFrom_ASC',
  SectionNameFromDesc = 'sectionNameFrom_DESC',
  SectionNameToAsc = 'sectionNameTo_ASC',
  SectionNameToDesc = 'sectionNameTo_DESC',
  SectionTypeFromAsc = 'sectionTypeFrom_ASC',
  SectionTypeFromDesc = 'sectionTypeFrom_DESC',
  SectionTypeToAsc = 'sectionTypeTo_ASC',
  SectionTypeToDesc = 'sectionTypeTo_DESC',
  FloorNameFromAsc = 'floorNameFrom_ASC',
  FloorNameFromDesc = 'floorNameFrom_DESC',
  FloorNameToAsc = 'floorNameTo_ASC',
  FloorNameToDesc = 'floorNameTo_DESC',
  UnitNameFromAsc = 'unitNameFrom_ASC',
  UnitNameFromDesc = 'unitNameFrom_DESC',
  UnitNameToAsc = 'unitNameTo_ASC',
  UnitNameToDesc = 'unitNameTo_DESC',
  UnitTypeFromAsc = 'unitTypeFrom_ASC',
  UnitTypeFromDesc = 'unitTypeFrom_DESC',
  UnitTypeToAsc = 'unitTypeTo_ASC',
  UnitTypeToDesc = 'unitTypeTo_DESC',
  DeferredUntilFromAsc = 'deferredUntilFrom_ASC',
  DeferredUntilFromDesc = 'deferredUntilFrom_DESC',
  DeferredUntilToAsc = 'deferredUntilTo_ASC',
  DeferredUntilToDesc = 'deferredUntilTo_DESC',
  OrganizationIdFromAsc = 'organizationIdFrom_ASC',
  OrganizationIdFromDesc = 'organizationIdFrom_DESC',
  OrganizationIdToAsc = 'organizationIdTo_ASC',
  OrganizationIdToDesc = 'organizationIdTo_DESC',
  OrganizationDisplayNameFromAsc = 'organizationDisplayNameFrom_ASC',
  OrganizationDisplayNameFromDesc = 'organizationDisplayNameFrom_DESC',
  OrganizationDisplayNameToAsc = 'organizationDisplayNameTo_ASC',
  OrganizationDisplayNameToDesc = 'organizationDisplayNameTo_DESC',
  StatusIdFromAsc = 'statusIdFrom_ASC',
  StatusIdFromDesc = 'statusIdFrom_DESC',
  StatusIdToAsc = 'statusIdTo_ASC',
  StatusIdToDesc = 'statusIdTo_DESC',
  StatusDisplayNameFromAsc = 'statusDisplayNameFrom_ASC',
  StatusDisplayNameFromDesc = 'statusDisplayNameFrom_DESC',
  StatusDisplayNameToAsc = 'statusDisplayNameTo_ASC',
  StatusDisplayNameToDesc = 'statusDisplayNameTo_DESC',
  ClientIdFromAsc = 'clientIdFrom_ASC',
  ClientIdFromDesc = 'clientIdFrom_DESC',
  ClientIdToAsc = 'clientIdTo_ASC',
  ClientIdToDesc = 'clientIdTo_DESC',
  ClientDisplayNameFromAsc = 'clientDisplayNameFrom_ASC',
  ClientDisplayNameFromDesc = 'clientDisplayNameFrom_DESC',
  ClientDisplayNameToAsc = 'clientDisplayNameTo_ASC',
  ClientDisplayNameToDesc = 'clientDisplayNameTo_DESC',
  ContactIdFromAsc = 'contactIdFrom_ASC',
  ContactIdFromDesc = 'contactIdFrom_DESC',
  ContactIdToAsc = 'contactIdTo_ASC',
  ContactIdToDesc = 'contactIdTo_DESC',
  ContactDisplayNameFromAsc = 'contactDisplayNameFrom_ASC',
  ContactDisplayNameFromDesc = 'contactDisplayNameFrom_DESC',
  ContactDisplayNameToAsc = 'contactDisplayNameTo_ASC',
  ContactDisplayNameToDesc = 'contactDisplayNameTo_DESC',
  AssigneeIdFromAsc = 'assigneeIdFrom_ASC',
  AssigneeIdFromDesc = 'assigneeIdFrom_DESC',
  AssigneeIdToAsc = 'assigneeIdTo_ASC',
  AssigneeIdToDesc = 'assigneeIdTo_DESC',
  AssigneeDisplayNameFromAsc = 'assigneeDisplayNameFrom_ASC',
  AssigneeDisplayNameFromDesc = 'assigneeDisplayNameFrom_DESC',
  AssigneeDisplayNameToAsc = 'assigneeDisplayNameTo_ASC',
  AssigneeDisplayNameToDesc = 'assigneeDisplayNameTo_DESC',
  ExecutorIdFromAsc = 'executorIdFrom_ASC',
  ExecutorIdFromDesc = 'executorIdFrom_DESC',
  ExecutorIdToAsc = 'executorIdTo_ASC',
  ExecutorIdToDesc = 'executorIdTo_DESC',
  ExecutorDisplayNameFromAsc = 'executorDisplayNameFrom_ASC',
  ExecutorDisplayNameFromDesc = 'executorDisplayNameFrom_DESC',
  ExecutorDisplayNameToAsc = 'executorDisplayNameTo_ASC',
  ExecutorDisplayNameToDesc = 'executorDisplayNameTo_DESC',
  ClassifierIdFromAsc = 'classifierIdFrom_ASC',
  ClassifierIdFromDesc = 'classifierIdFrom_DESC',
  ClassifierIdToAsc = 'classifierIdTo_ASC',
  ClassifierIdToDesc = 'classifierIdTo_DESC',
  ClassifierDisplayNameFromAsc = 'classifierDisplayNameFrom_ASC',
  ClassifierDisplayNameFromDesc = 'classifierDisplayNameFrom_DESC',
  ClassifierDisplayNameToAsc = 'classifierDisplayNameTo_ASC',
  ClassifierDisplayNameToDesc = 'classifierDisplayNameTo_DESC',
  RelatedIdFromAsc = 'relatedIdFrom_ASC',
  RelatedIdFromDesc = 'relatedIdFrom_DESC',
  RelatedIdToAsc = 'relatedIdTo_ASC',
  RelatedIdToDesc = 'relatedIdTo_DESC',
  RelatedDisplayNameFromAsc = 'relatedDisplayNameFrom_ASC',
  RelatedDisplayNameFromDesc = 'relatedDisplayNameFrom_DESC',
  RelatedDisplayNameToAsc = 'relatedDisplayNameTo_ASC',
  RelatedDisplayNameToDesc = 'relatedDisplayNameTo_DESC',
  PropertyIdFromAsc = 'propertyIdFrom_ASC',
  PropertyIdFromDesc = 'propertyIdFrom_DESC',
  PropertyIdToAsc = 'propertyIdTo_ASC',
  PropertyIdToDesc = 'propertyIdTo_DESC',
  PropertyDisplayNameFromAsc = 'propertyDisplayNameFrom_ASC',
  PropertyDisplayNameFromDesc = 'propertyDisplayNameFrom_DESC',
  PropertyDisplayNameToAsc = 'propertyDisplayNameTo_ASC',
  PropertyDisplayNameToDesc = 'propertyDisplayNameTo_DESC',
  SourceIdFromAsc = 'sourceIdFrom_ASC',
  SourceIdFromDesc = 'sourceIdFrom_DESC',
  SourceIdToAsc = 'sourceIdTo_ASC',
  SourceIdToDesc = 'sourceIdTo_DESC',
  SourceDisplayNameFromAsc = 'sourceDisplayNameFrom_ASC',
  SourceDisplayNameFromDesc = 'sourceDisplayNameFrom_DESC',
  SourceDisplayNameToAsc = 'sourceDisplayNameTo_ASC',
  SourceDisplayNameToDesc = 'sourceDisplayNameTo_DESC',
  ActualCreationDateAsc = 'actualCreationDate_ASC',
  ActualCreationDateDesc = 'actualCreationDate_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketClassifierHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketClassifiersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  PlaceAsc = 'place_ASC',
  PlaceDesc = 'place_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  ProblemAsc = 'problem_ASC',
  ProblemDesc = 'problem_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketCommentFileHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketCommentFilesBy {
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  TicketCommentAsc = 'ticketComment_ASC',
  TicketCommentDesc = 'ticketComment_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketCommentHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketCommentsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketDocumentGenerationTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ProgressAsc = 'progress_ASC',
  ProgressDesc = 'progress_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  DocumentTypeAsc = 'documentType_ASC',
  DocumentTypeDesc = 'documentType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketDocumentGenerationTasksBy {
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ProgressAsc = 'progress_ASC',
  ProgressDesc = 'progress_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  DocumentTypeAsc = 'documentType_ASC',
  DocumentTypeDesc = 'documentType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketExportTaskHistoryRecordsBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
  ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketExportTasksBy {
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  ExportedRecordsCountAsc = 'exportedRecordsCount_ASC',
  ExportedRecordsCountDesc = 'exportedRecordsCount_DESC',
  TotalRecordsCountAsc = 'totalRecordsCount_ASC',
  TotalRecordsCountDesc = 'totalRecordsCount_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  TimeZoneAsc = 'timeZone_ASC',
  TimeZoneDesc = 'timeZone_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketFileHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketFilesBy {
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketFilterTemplateHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketFilterTemplatesBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  EmployeeAsc = 'employee_ASC',
  EmployeeDesc = 'employee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketHistoryRecordsBy {
  StatusReopenedCounterAsc = 'statusReopenedCounter_ASC',
  StatusReopenedCounterDesc = 'statusReopenedCounter_DESC',
  ReviewValueAsc = 'reviewValue_ASC',
  ReviewValueDesc = 'reviewValue_DESC',
  ReviewCommentAsc = 'reviewComment_ASC',
  ReviewCommentDesc = 'reviewComment_DESC',
  FeedbackValueAsc = 'feedbackValue_ASC',
  FeedbackValueDesc = 'feedbackValue_DESC',
  FeedbackCommentAsc = 'feedbackComment_ASC',
  FeedbackCommentDesc = 'feedbackComment_DESC',
  FeedbackUpdatedAtAsc = 'feedbackUpdatedAt_ASC',
  FeedbackUpdatedAtDesc = 'feedbackUpdatedAt_DESC',
  QualityControlValueAsc = 'qualityControlValue_ASC',
  QualityControlValueDesc = 'qualityControlValue_DESC',
  QualityControlCommentAsc = 'qualityControlComment_ASC',
  QualityControlCommentDesc = 'qualityControlComment_DESC',
  QualityControlUpdatedAtAsc = 'qualityControlUpdatedAt_ASC',
  QualityControlUpdatedAtDesc = 'qualityControlUpdatedAt_DESC',
  StatusUpdatedAtAsc = 'statusUpdatedAt_ASC',
  StatusUpdatedAtDesc = 'statusUpdatedAt_DESC',
  CompletedAtAsc = 'completedAt_ASC',
  CompletedAtDesc = 'completedAt_DESC',
  LastCommentAtAsc = 'lastCommentAt_ASC',
  LastCommentAtDesc = 'lastCommentAt_DESC',
  LastResidentCommentAtAsc = 'lastResidentCommentAt_ASC',
  LastResidentCommentAtDesc = 'lastResidentCommentAt_DESC',
  LastCommentWithResidentTypeAtAsc = 'lastCommentWithResidentTypeAt_ASC',
  LastCommentWithResidentTypeAtDesc = 'lastCommentWithResidentTypeAt_DESC',
  StatusReasonAsc = 'statusReason_ASC',
  StatusReasonDesc = 'statusReason_DESC',
  DeadlineAsc = 'deadline_ASC',
  DeadlineDesc = 'deadline_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  ClientEmailAsc = 'clientEmail_ASC',
  ClientEmailDesc = 'clientEmail_DESC',
  ClientPhoneAsc = 'clientPhone_ASC',
  ClientPhoneDesc = 'clientPhone_DESC',
  IsAutoClassifiedAsc = 'isAutoClassified_ASC',
  IsAutoClassifiedDesc = 'isAutoClassified_DESC',
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  IsPaidAsc = 'isPaid_ASC',
  IsPaidDesc = 'isPaid_DESC',
  IsPayableAsc = 'isPayable_ASC',
  IsPayableDesc = 'isPayable_DESC',
  IsEmergencyAsc = 'isEmergency_ASC',
  IsEmergencyDesc = 'isEmergency_DESC',
  IsWarrantyAsc = 'isWarranty_ASC',
  IsWarrantyDesc = 'isWarranty_DESC',
  IsResidentTicketAsc = 'isResidentTicket_ASC',
  IsResidentTicketDesc = 'isResidentTicket_DESC',
  CanReadByResidentAsc = 'canReadByResident_ASC',
  CanReadByResidentDesc = 'canReadByResident_DESC',
  PropertyAddressAsc = 'propertyAddress_ASC',
  PropertyAddressDesc = 'propertyAddress_DESC',
  SectionNameAsc = 'sectionName_ASC',
  SectionNameDesc = 'sectionName_DESC',
  SectionTypeAsc = 'sectionType_ASC',
  SectionTypeDesc = 'sectionType_DESC',
  FloorNameAsc = 'floorName_ASC',
  FloorNameDesc = 'floorName_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  DeferredUntilAsc = 'deferredUntil_ASC',
  DeferredUntilDesc = 'deferredUntil_DESC',
  IsCompletedAfterDeadlineAsc = 'isCompletedAfterDeadline_ASC',
  IsCompletedAfterDeadlineDesc = 'isCompletedAfterDeadline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketOrganizationSettingHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketOrganizationSettingsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  DefaultDeadlineDurationAsc = 'defaultDeadlineDuration_ASC',
  DefaultDeadlineDurationDesc = 'defaultDeadlineDuration_DESC',
  PaidDeadlineDurationAsc = 'paidDeadlineDuration_ASC',
  PaidDeadlineDurationDesc = 'paidDeadlineDuration_DESC',
  EmergencyDeadlineDurationAsc = 'emergencyDeadlineDuration_ASC',
  EmergencyDeadlineDurationDesc = 'emergencyDeadlineDuration_DESC',
  WarrantyDeadlineDurationAsc = 'warrantyDeadlineDuration_ASC',
  WarrantyDeadlineDurationDesc = 'warrantyDeadlineDuration_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketPlaceClassifierHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketPlaceClassifiersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketProblemClassifierHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketProblemClassifiersBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketPropertyHintHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketPropertyHintPropertiesBy {
  TicketPropertyHintAsc = 'ticketPropertyHint_ASC',
  TicketPropertyHintDesc = 'ticketPropertyHint_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketPropertyHintPropertyHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketPropertyHintsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketSourceHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketSourcesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketStatusHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTicketStatusesBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTicketsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  StatusReopenedCounterAsc = 'statusReopenedCounter_ASC',
  StatusReopenedCounterDesc = 'statusReopenedCounter_DESC',
  ReviewValueAsc = 'reviewValue_ASC',
  ReviewValueDesc = 'reviewValue_DESC',
  ReviewCommentAsc = 'reviewComment_ASC',
  ReviewCommentDesc = 'reviewComment_DESC',
  FeedbackValueAsc = 'feedbackValue_ASC',
  FeedbackValueDesc = 'feedbackValue_DESC',
  FeedbackCommentAsc = 'feedbackComment_ASC',
  FeedbackCommentDesc = 'feedbackComment_DESC',
  FeedbackUpdatedAtAsc = 'feedbackUpdatedAt_ASC',
  FeedbackUpdatedAtDesc = 'feedbackUpdatedAt_DESC',
  QualityControlValueAsc = 'qualityControlValue_ASC',
  QualityControlValueDesc = 'qualityControlValue_DESC',
  QualityControlCommentAsc = 'qualityControlComment_ASC',
  QualityControlCommentDesc = 'qualityControlComment_DESC',
  QualityControlUpdatedAtAsc = 'qualityControlUpdatedAt_ASC',
  QualityControlUpdatedAtDesc = 'qualityControlUpdatedAt_DESC',
  QualityControlUpdatedByAsc = 'qualityControlUpdatedBy_ASC',
  QualityControlUpdatedByDesc = 'qualityControlUpdatedBy_DESC',
  StatusUpdatedAtAsc = 'statusUpdatedAt_ASC',
  StatusUpdatedAtDesc = 'statusUpdatedAt_DESC',
  CompletedAtAsc = 'completedAt_ASC',
  CompletedAtDesc = 'completedAt_DESC',
  LastCommentAtAsc = 'lastCommentAt_ASC',
  LastCommentAtDesc = 'lastCommentAt_DESC',
  LastResidentCommentAtAsc = 'lastResidentCommentAt_ASC',
  LastResidentCommentAtDesc = 'lastResidentCommentAt_DESC',
  LastCommentWithResidentTypeAtAsc = 'lastCommentWithResidentTypeAt_ASC',
  LastCommentWithResidentTypeAtDesc = 'lastCommentWithResidentTypeAt_DESC',
  StatusReasonAsc = 'statusReason_ASC',
  StatusReasonDesc = 'statusReason_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  DeadlineAsc = 'deadline_ASC',
  DeadlineDesc = 'deadline_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  ClientAsc = 'client_ASC',
  ClientDesc = 'client_DESC',
  ContactAsc = 'contact_ASC',
  ContactDesc = 'contact_DESC',
  ClientNameAsc = 'clientName_ASC',
  ClientNameDesc = 'clientName_DESC',
  ClientEmailAsc = 'clientEmail_ASC',
  ClientEmailDesc = 'clientEmail_DESC',
  ClientPhoneAsc = 'clientPhone_ASC',
  ClientPhoneDesc = 'clientPhone_DESC',
  AssigneeAsc = 'assignee_ASC',
  AssigneeDesc = 'assignee_DESC',
  ExecutorAsc = 'executor_ASC',
  ExecutorDesc = 'executor_DESC',
  CategoryClassifierAsc = 'categoryClassifier_ASC',
  CategoryClassifierDesc = 'categoryClassifier_DESC',
  PlaceClassifierAsc = 'placeClassifier_ASC',
  PlaceClassifierDesc = 'placeClassifier_DESC',
  ProblemClassifierAsc = 'problemClassifier_ASC',
  ProblemClassifierDesc = 'problemClassifier_DESC',
  ClassifierAsc = 'classifier_ASC',
  ClassifierDesc = 'classifier_DESC',
  IsAutoClassifiedAsc = 'isAutoClassified_ASC',
  IsAutoClassifiedDesc = 'isAutoClassified_DESC',
  DetailsAsc = 'details_ASC',
  DetailsDesc = 'details_DESC',
  RelatedAsc = 'related_ASC',
  RelatedDesc = 'related_DESC',
  IsPaidAsc = 'isPaid_ASC',
  IsPaidDesc = 'isPaid_DESC',
  IsPayableAsc = 'isPayable_ASC',
  IsPayableDesc = 'isPayable_DESC',
  IsEmergencyAsc = 'isEmergency_ASC',
  IsEmergencyDesc = 'isEmergency_DESC',
  IsWarrantyAsc = 'isWarranty_ASC',
  IsWarrantyDesc = 'isWarranty_DESC',
  IsResidentTicketAsc = 'isResidentTicket_ASC',
  IsResidentTicketDesc = 'isResidentTicket_DESC',
  CanReadByResidentAsc = 'canReadByResident_ASC',
  CanReadByResidentDesc = 'canReadByResident_DESC',
  PropertyAsc = 'property_ASC',
  PropertyDesc = 'property_DESC',
  PropertyAddressAsc = 'propertyAddress_ASC',
  PropertyAddressDesc = 'propertyAddress_DESC',
  SectionNameAsc = 'sectionName_ASC',
  SectionNameDesc = 'sectionName_DESC',
  SectionTypeAsc = 'sectionType_ASC',
  SectionTypeDesc = 'sectionType_DESC',
  FloorNameAsc = 'floorName_ASC',
  FloorNameDesc = 'floorName_DESC',
  UnitNameAsc = 'unitName_ASC',
  UnitNameDesc = 'unitName_DESC',
  UnitTypeAsc = 'unitType_ASC',
  UnitTypeDesc = 'unitType_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  DeferredUntilAsc = 'deferredUntil_ASC',
  DeferredUntilDesc = 'deferredUntil_DESC',
  IsCompletedAfterDeadlineAsc = 'isCompletedAfterDeadline_ASC',
  IsCompletedAfterDeadlineDesc = 'isCompletedAfterDeadline_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortTourStepHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortTourStepsBy {
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  OrderAsc = 'order_ASC',
  OrderDesc = 'order_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUserExternalIdentitiesBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdentityIdAsc = 'identityId_ASC',
  IdentityIdDesc = 'identityId_DESC',
  IdentityTypeAsc = 'identityType_ASC',
  IdentityTypeDesc = 'identityType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUserExternalIdentityHistoryRecordsBy {
  IdentityIdAsc = 'identityId_ASC',
  IdentityIdDesc = 'identityId_DESC',
  IdentityTypeAsc = 'identityType_ASC',
  IdentityTypeDesc = 'identityType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserFavoriteTicketHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserFavoriteTicketsBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUserHelpRequestFileHistoryRecordsBy {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserHelpRequestFilesBy {
  UserHelpRequestAsc = 'userHelpRequest_ASC',
  UserHelpRequestDesc = 'userHelpRequest_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUserHelpRequestHistoryRecordsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  IsReadyToSendAsc = 'isReadyToSend_ASC',
  IsReadyToSendDesc = 'isReadyToSend_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserHelpRequestsBy {
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  OrganizationAsc = 'organization_ASC',
  OrganizationDesc = 'organization_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  IsReadyToSendAsc = 'isReadyToSend_ASC',
  IsReadyToSendDesc = 'isReadyToSend_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUserHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PasswordAsc = 'password_ASC',
  PasswordDesc = 'password_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IsAdminAsc = 'isAdmin_ASC',
  IsAdminDesc = 'isAdmin_DESC',
  IsSupportAsc = 'isSupport_ASC',
  IsSupportDesc = 'isSupport_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IsEmailVerifiedAsc = 'isEmailVerified_ASC',
  IsEmailVerifiedDesc = 'isEmailVerified_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
  IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  ShowGlobalHintsAsc = 'showGlobalHints_ASC',
  ShowGlobalHintsDesc = 'showGlobalHints_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserRightsSetHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CanReadB2BAppsAsc = 'canReadB2BApps_ASC',
  CanReadB2BAppsDesc = 'canReadB2BApps_DESC',
  CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
  CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
  CanReadB2BAppAccessRightsAsc = 'canReadB2BAppAccessRights_ASC',
  CanReadB2BAppAccessRightsDesc = 'canReadB2BAppAccessRights_DESC',
  CanManageB2BAppAccessRightsAsc = 'canManageB2BAppAccessRights_ASC',
  CanManageB2BAppAccessRightsDesc = 'canManageB2BAppAccessRights_DESC',
  CanReadB2BAppAccessRightSetsAsc = 'canReadB2BAppAccessRightSets_ASC',
  CanReadB2BAppAccessRightSetsDesc = 'canReadB2BAppAccessRightSets_DESC',
  CanManageB2BAppAccessRightSetsAsc = 'canManageB2BAppAccessRightSets_ASC',
  CanManageB2BAppAccessRightSetsDesc = 'canManageB2BAppAccessRightSets_DESC',
  CanReadB2BAppContextsAsc = 'canReadB2BAppContexts_ASC',
  CanReadB2BAppContextsDesc = 'canReadB2BAppContexts_DESC',
  CanManageB2BAppContextsAsc = 'canManageB2BAppContexts_ASC',
  CanManageB2BAppContextsDesc = 'canManageB2BAppContexts_DESC',
  CanReadB2BAppNewsSharingConfigsAsc = 'canReadB2BAppNewsSharingConfigs_ASC',
  CanReadB2BAppNewsSharingConfigsDesc = 'canReadB2BAppNewsSharingConfigs_DESC',
  CanManageB2BAppNewsSharingConfigsAsc = 'canManageB2BAppNewsSharingConfigs_ASC',
  CanManageB2BAppNewsSharingConfigsDesc = 'canManageB2BAppNewsSharingConfigs_DESC',
  CanReadB2BAppPermissionsAsc = 'canReadB2BAppPermissions_ASC',
  CanReadB2BAppPermissionsDesc = 'canReadB2BAppPermissions_DESC',
  CanManageB2BAppPermissionsAsc = 'canManageB2BAppPermissions_ASC',
  CanManageB2BAppPermissionsDesc = 'canManageB2BAppPermissions_DESC',
  CanReadB2BAppPromoBlocksAsc = 'canReadB2BAppPromoBlocks_ASC',
  CanReadB2BAppPromoBlocksDesc = 'canReadB2BAppPromoBlocks_DESC',
  CanManageB2BAppPromoBlocksAsc = 'canManageB2BAppPromoBlocks_ASC',
  CanManageB2BAppPromoBlocksDesc = 'canManageB2BAppPromoBlocks_DESC',
  CanReadB2CAppsAsc = 'canReadB2CApps_ASC',
  CanReadB2CAppsDesc = 'canReadB2CApps_DESC',
  CanManageB2CAppsAsc = 'canManageB2CApps_ASC',
  CanManageB2CAppsDesc = 'canManageB2CApps_DESC',
  CanReadB2CAppAccessRightsAsc = 'canReadB2CAppAccessRights_ASC',
  CanReadB2CAppAccessRightsDesc = 'canReadB2CAppAccessRights_DESC',
  CanManageB2CAppAccessRightsAsc = 'canManageB2CAppAccessRights_ASC',
  CanManageB2CAppAccessRightsDesc = 'canManageB2CAppAccessRights_DESC',
  CanReadB2CAppBuildsAsc = 'canReadB2CAppBuilds_ASC',
  CanReadB2CAppBuildsDesc = 'canReadB2CAppBuilds_DESC',
  CanManageB2CAppBuildsAsc = 'canManageB2CAppBuilds_ASC',
  CanManageB2CAppBuildsDesc = 'canManageB2CAppBuilds_DESC',
  CanReadB2CAppPropertiesAsc = 'canReadB2CAppProperties_ASC',
  CanReadB2CAppPropertiesDesc = 'canReadB2CAppProperties_DESC',
  CanManageB2CAppPropertiesAsc = 'canManageB2CAppProperties_ASC',
  CanManageB2CAppPropertiesDesc = 'canManageB2CAppProperties_DESC',
  CanReadMessagesAsc = 'canReadMessages_ASC',
  CanReadMessagesDesc = 'canReadMessages_DESC',
  CanReadMessageBatchesAsc = 'canReadMessageBatches_ASC',
  CanReadMessageBatchesDesc = 'canReadMessageBatches_DESC',
  CanManageMessageBatchesAsc = 'canManageMessageBatches_ASC',
  CanManageMessageBatchesDesc = 'canManageMessageBatches_DESC',
  CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
  CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
  CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
  CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
  CanReadTicketsAsc = 'canReadTickets_ASC',
  CanReadTicketsDesc = 'canReadTickets_DESC',
  CanManageTicketsAsc = 'canManageTickets_ASC',
  CanManageTicketsDesc = 'canManageTickets_DESC',
  CanReadTicketAutoAssignmentsAsc = 'canReadTicketAutoAssignments_ASC',
  CanReadTicketAutoAssignmentsDesc = 'canReadTicketAutoAssignments_DESC',
  CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
  CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
  CanReadOidcClientsAsc = 'canReadOidcClients_ASC',
  CanReadOidcClientsDesc = 'canReadOidcClients_DESC',
  CanManageOidcClientsAsc = 'canManageOidcClients_ASC',
  CanManageOidcClientsDesc = 'canManageOidcClients_DESC',
  CanReadUsersAsc = 'canReadUsers_ASC',
  CanReadUsersDesc = 'canReadUsers_DESC',
  CanReadUserRightsSetsAsc = 'canReadUserRightsSets_ASC',
  CanReadUserRightsSetsDesc = 'canReadUserRightsSets_DESC',
  CanManageUserRightsSetsAsc = 'canManageUserRightsSets_ASC',
  CanManageUserRightsSetsDesc = 'canManageUserRightsSets_DESC',
  CanReadPaymentsAsc = 'canReadPayments_ASC',
  CanReadPaymentsDesc = 'canReadPayments_DESC',
  CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
  CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
  CanReadBillingOrganizationIntegrationContextsAsc = 'canReadBillingOrganizationIntegrationContexts_ASC',
  CanReadBillingOrganizationIntegrationContextsDesc = 'canReadBillingOrganizationIntegrationContexts_DESC',
  CanExecuteRegisterNewServiceUserAsc = 'canExecuteRegisterNewServiceUser_ASC',
  CanExecuteRegisterNewServiceUserDesc = 'canExecuteRegisterNewServiceUser_DESC',
  CanExecuteSendMessageAsc = 'canExecuteSendMessage_ASC',
  CanExecuteSendMessageDesc = 'canExecuteSendMessage_DESC',
  CanExecuteInternalSendHashedResidentPhonesAsc = 'canExecute_internalSendHashedResidentPhones_ASC',
  CanExecuteInternalSendHashedResidentPhonesDesc = 'canExecute_internalSendHashedResidentPhones_DESC',
  CanExecuteAllPaymentsSumAsc = 'canExecute_allPaymentsSum_ASC',
  CanExecuteAllPaymentsSumDesc = 'canExecute_allPaymentsSum_DESC',
  CanExecuteAllBillingReceiptsSumAsc = 'canExecute_allBillingReceiptsSum_ASC',
  CanExecuteAllBillingReceiptsSumDesc = 'canExecute_allBillingReceiptsSum_DESC',
  CanManageOrganizationIsApprovedFieldAsc = 'canManageOrganizationIsApprovedField_ASC',
  CanManageOrganizationIsApprovedFieldDesc = 'canManageOrganizationIsApprovedField_DESC',
  CanReadUserEmailFieldAsc = 'canReadUserEmailField_ASC',
  CanReadUserEmailFieldDesc = 'canReadUserEmailField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserRightsSetsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CanReadB2BAppsAsc = 'canReadB2BApps_ASC',
  CanReadB2BAppsDesc = 'canReadB2BApps_DESC',
  CanManageB2BAppsAsc = 'canManageB2BApps_ASC',
  CanManageB2BAppsDesc = 'canManageB2BApps_DESC',
  CanReadB2BAppAccessRightsAsc = 'canReadB2BAppAccessRights_ASC',
  CanReadB2BAppAccessRightsDesc = 'canReadB2BAppAccessRights_DESC',
  CanManageB2BAppAccessRightsAsc = 'canManageB2BAppAccessRights_ASC',
  CanManageB2BAppAccessRightsDesc = 'canManageB2BAppAccessRights_DESC',
  CanReadB2BAppAccessRightSetsAsc = 'canReadB2BAppAccessRightSets_ASC',
  CanReadB2BAppAccessRightSetsDesc = 'canReadB2BAppAccessRightSets_DESC',
  CanManageB2BAppAccessRightSetsAsc = 'canManageB2BAppAccessRightSets_ASC',
  CanManageB2BAppAccessRightSetsDesc = 'canManageB2BAppAccessRightSets_DESC',
  CanReadB2BAppContextsAsc = 'canReadB2BAppContexts_ASC',
  CanReadB2BAppContextsDesc = 'canReadB2BAppContexts_DESC',
  CanManageB2BAppContextsAsc = 'canManageB2BAppContexts_ASC',
  CanManageB2BAppContextsDesc = 'canManageB2BAppContexts_DESC',
  CanReadB2BAppNewsSharingConfigsAsc = 'canReadB2BAppNewsSharingConfigs_ASC',
  CanReadB2BAppNewsSharingConfigsDesc = 'canReadB2BAppNewsSharingConfigs_DESC',
  CanManageB2BAppNewsSharingConfigsAsc = 'canManageB2BAppNewsSharingConfigs_ASC',
  CanManageB2BAppNewsSharingConfigsDesc = 'canManageB2BAppNewsSharingConfigs_DESC',
  CanReadB2BAppPermissionsAsc = 'canReadB2BAppPermissions_ASC',
  CanReadB2BAppPermissionsDesc = 'canReadB2BAppPermissions_DESC',
  CanManageB2BAppPermissionsAsc = 'canManageB2BAppPermissions_ASC',
  CanManageB2BAppPermissionsDesc = 'canManageB2BAppPermissions_DESC',
  CanReadB2BAppPromoBlocksAsc = 'canReadB2BAppPromoBlocks_ASC',
  CanReadB2BAppPromoBlocksDesc = 'canReadB2BAppPromoBlocks_DESC',
  CanManageB2BAppPromoBlocksAsc = 'canManageB2BAppPromoBlocks_ASC',
  CanManageB2BAppPromoBlocksDesc = 'canManageB2BAppPromoBlocks_DESC',
  CanReadB2CAppsAsc = 'canReadB2CApps_ASC',
  CanReadB2CAppsDesc = 'canReadB2CApps_DESC',
  CanManageB2CAppsAsc = 'canManageB2CApps_ASC',
  CanManageB2CAppsDesc = 'canManageB2CApps_DESC',
  CanReadB2CAppAccessRightsAsc = 'canReadB2CAppAccessRights_ASC',
  CanReadB2CAppAccessRightsDesc = 'canReadB2CAppAccessRights_DESC',
  CanManageB2CAppAccessRightsAsc = 'canManageB2CAppAccessRights_ASC',
  CanManageB2CAppAccessRightsDesc = 'canManageB2CAppAccessRights_DESC',
  CanReadB2CAppBuildsAsc = 'canReadB2CAppBuilds_ASC',
  CanReadB2CAppBuildsDesc = 'canReadB2CAppBuilds_DESC',
  CanManageB2CAppBuildsAsc = 'canManageB2CAppBuilds_ASC',
  CanManageB2CAppBuildsDesc = 'canManageB2CAppBuilds_DESC',
  CanReadB2CAppPropertiesAsc = 'canReadB2CAppProperties_ASC',
  CanReadB2CAppPropertiesDesc = 'canReadB2CAppProperties_DESC',
  CanManageB2CAppPropertiesAsc = 'canManageB2CAppProperties_ASC',
  CanManageB2CAppPropertiesDesc = 'canManageB2CAppProperties_DESC',
  CanReadMessagesAsc = 'canReadMessages_ASC',
  CanReadMessagesDesc = 'canReadMessages_DESC',
  CanReadMessageBatchesAsc = 'canReadMessageBatches_ASC',
  CanReadMessageBatchesDesc = 'canReadMessageBatches_DESC',
  CanManageMessageBatchesAsc = 'canManageMessageBatches_ASC',
  CanManageMessageBatchesDesc = 'canManageMessageBatches_DESC',
  CanReadOrganizationsAsc = 'canReadOrganizations_ASC',
  CanReadOrganizationsDesc = 'canReadOrganizations_DESC',
  CanManageOrganizationsAsc = 'canManageOrganizations_ASC',
  CanManageOrganizationsDesc = 'canManageOrganizations_DESC',
  CanReadTicketsAsc = 'canReadTickets_ASC',
  CanReadTicketsDesc = 'canReadTickets_DESC',
  CanManageTicketsAsc = 'canManageTickets_ASC',
  CanManageTicketsDesc = 'canManageTickets_DESC',
  CanReadTicketAutoAssignmentsAsc = 'canReadTicketAutoAssignments_ASC',
  CanReadTicketAutoAssignmentsDesc = 'canReadTicketAutoAssignments_DESC',
  CanManageTicketAutoAssignmentsAsc = 'canManageTicketAutoAssignments_ASC',
  CanManageTicketAutoAssignmentsDesc = 'canManageTicketAutoAssignments_DESC',
  CanReadOidcClientsAsc = 'canReadOidcClients_ASC',
  CanReadOidcClientsDesc = 'canReadOidcClients_DESC',
  CanManageOidcClientsAsc = 'canManageOidcClients_ASC',
  CanManageOidcClientsDesc = 'canManageOidcClients_DESC',
  CanReadUsersAsc = 'canReadUsers_ASC',
  CanReadUsersDesc = 'canReadUsers_DESC',
  CanReadUserRightsSetsAsc = 'canReadUserRightsSets_ASC',
  CanReadUserRightsSetsDesc = 'canReadUserRightsSets_DESC',
  CanManageUserRightsSetsAsc = 'canManageUserRightsSets_ASC',
  CanManageUserRightsSetsDesc = 'canManageUserRightsSets_DESC',
  CanReadPaymentsAsc = 'canReadPayments_ASC',
  CanReadPaymentsDesc = 'canReadPayments_DESC',
  CanReadBillingReceiptsAsc = 'canReadBillingReceipts_ASC',
  CanReadBillingReceiptsDesc = 'canReadBillingReceipts_DESC',
  CanReadBillingOrganizationIntegrationContextsAsc = 'canReadBillingOrganizationIntegrationContexts_ASC',
  CanReadBillingOrganizationIntegrationContextsDesc = 'canReadBillingOrganizationIntegrationContexts_DESC',
  CanExecuteRegisterNewServiceUserAsc = 'canExecuteRegisterNewServiceUser_ASC',
  CanExecuteRegisterNewServiceUserDesc = 'canExecuteRegisterNewServiceUser_DESC',
  CanExecuteSendMessageAsc = 'canExecuteSendMessage_ASC',
  CanExecuteSendMessageDesc = 'canExecuteSendMessage_DESC',
  CanExecuteInternalSendHashedResidentPhonesAsc = 'canExecute_internalSendHashedResidentPhones_ASC',
  CanExecuteInternalSendHashedResidentPhonesDesc = 'canExecute_internalSendHashedResidentPhones_DESC',
  CanExecuteAllPaymentsSumAsc = 'canExecute_allPaymentsSum_ASC',
  CanExecuteAllPaymentsSumDesc = 'canExecute_allPaymentsSum_DESC',
  CanExecuteAllBillingReceiptsSumAsc = 'canExecute_allBillingReceiptsSum_ASC',
  CanExecuteAllBillingReceiptsSumDesc = 'canExecute_allBillingReceiptsSum_DESC',
  CanManageOrganizationIsApprovedFieldAsc = 'canManageOrganizationIsApprovedField_ASC',
  CanManageOrganizationIsApprovedFieldDesc = 'canManageOrganizationIsApprovedField_DESC',
  CanReadUserEmailFieldAsc = 'canReadUserEmailField_ASC',
  CanReadUserEmailFieldDesc = 'canReadUserEmailField_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUserTicketCommentReadTimeHistoryRecordsBy {
  ReadCommentAtAsc = 'readCommentAt_ASC',
  ReadCommentAtDesc = 'readCommentAt_DESC',
  ReadResidentCommentAtAsc = 'readResidentCommentAt_ASC',
  ReadResidentCommentAtDesc = 'readResidentCommentAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortUserTicketCommentReadTimesBy {
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  TicketAsc = 'ticket_ASC',
  TicketDesc = 'ticket_DESC',
  ReadCommentAtAsc = 'readCommentAt_ASC',
  ReadCommentAtDesc = 'readCommentAt_DESC',
  ReadResidentCommentAtAsc = 'readResidentCommentAt_ASC',
  ReadResidentCommentAtDesc = 'readResidentCommentAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortUsersBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  IsAdminAsc = 'isAdmin_ASC',
  IsAdminDesc = 'isAdmin_DESC',
  IsSupportAsc = 'isSupport_ASC',
  IsSupportDesc = 'isSupport_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  IsEmailVerifiedAsc = 'isEmailVerified_ASC',
  IsEmailVerifiedDesc = 'isEmailVerified_DESC',
  PhoneAsc = 'phone_ASC',
  PhoneDesc = 'phone_DESC',
  IsPhoneVerifiedAsc = 'isPhoneVerified_ASC',
  IsPhoneVerifiedDesc = 'isPhoneVerified_DESC',
  LocaleAsc = 'locale_ASC',
  LocaleDesc = 'locale_DESC',
  ShowGlobalHintsAsc = 'showGlobalHints_ASC',
  ShowGlobalHintsDesc = 'showGlobalHints_DESC',
  RightsSetAsc = 'rightsSet_ASC',
  RightsSetDesc = 'rightsSet_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortWebhookHistoryRecordsBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortWebhookSubscriptionHistoryRecordsBy {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SyncedAtAsc = 'syncedAt_ASC',
  SyncedAtDesc = 'syncedAt_DESC',
  SyncedAmountAsc = 'syncedAmount_ASC',
  SyncedAmountDesc = 'syncedAmount_DESC',
  FailuresCountAsc = 'failuresCount_ASC',
  FailuresCountDesc = 'failuresCount_DESC',
  ModelAsc = 'model_ASC',
  ModelDesc = 'model_DESC',
  FieldsAsc = 'fields_ASC',
  FieldsDesc = 'fields_DESC',
  MaxPackSizeAsc = 'maxPackSize_ASC',
  MaxPackSizeDesc = 'maxPackSize_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC',
  HistoryDateAsc = 'history_date_ASC',
  HistoryDateDesc = 'history_date_DESC',
  HistoryActionAsc = 'history_action_ASC',
  HistoryActionDesc = 'history_action_DESC'
}

export enum SortWebhookSubscriptionsBy {
  WebhookAsc = 'webhook_ASC',
  WebhookDesc = 'webhook_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SyncedAtAsc = 'syncedAt_ASC',
  SyncedAtDesc = 'syncedAt_DESC',
  SyncedAmountAsc = 'syncedAmount_ASC',
  SyncedAmountDesc = 'syncedAmount_DESC',
  FailuresCountAsc = 'failuresCount_ASC',
  FailuresCountDesc = 'failuresCount_DESC',
  ModelAsc = 'model_ASC',
  ModelDesc = 'model_DESC',
  FieldsAsc = 'fields_ASC',
  FieldsDesc = 'fields_DESC',
  MaxPackSizeAsc = 'maxPackSize_ASC',
  MaxPackSizeDesc = 'maxPackSize_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export enum SortWebhooksBy {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  UserAsc = 'user_ASC',
  UserDesc = 'user_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VAsc = 'v_ASC',
  VDesc = 'v_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  CreatedByAsc = 'createdBy_ASC',
  CreatedByDesc = 'createdBy_DESC',
  UpdatedByAsc = 'updatedBy_ASC',
  UpdatedByDesc = 'updatedBy_DESC',
  DeletedAtAsc = 'deletedAt_ASC',
  DeletedAtDesc = 'deletedAt_DESC',
  DvAsc = 'dv_ASC',
  DvDesc = 'dv_DESC'
}

export type StartConfirmPhoneActionInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  captcha: Scalars['String'];
  phone: Scalars['String'];
};

export type StartConfirmPhoneActionOutput = {
  __typename?: 'StartConfirmPhoneActionOutput';
  token: Scalars['String'];
};

export enum Status {
  Success = 'success',
  Error = 'error'
}

export type SuggestServiceProviderInput = {
  search: Scalars['String'];
};

export type SuggestServiceProviderOutput = {
  __typename?: 'SuggestServiceProviderOutput';
  tin: Scalars['String'];
  name: Scalars['String'];
};

export type SyncRemoteClientInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  deviceId: Scalars['String'];
  appId: Scalars['String'];
  pushToken?: Maybe<Scalars['String']>;
  pushTransport?: Maybe<PushTransportType>;
  devicePlatform?: Maybe<DevicePlatformType>;
  pushType?: Maybe<PushType>;
  meta?: Maybe<Scalars['JSON']>;
  pushTokenVoIP?: Maybe<Scalars['String']>;
  pushTransportVoIP?: Maybe<PushTransportType>;
  pushTypeVoIP?: Maybe<PushType>;
};

export type SyncTourStepsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
};

export type SyncTourStepsOutput = {
  __typename?: 'SyncTourStepsOutput';
  ok: Scalars['String'];
};

/**  Link between condo user and user's telegram chat with bot  */
export type TelegramUserChat = {
  __typename?: 'TelegramUserChat';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TelegramUserChat List config, or
   *  2. As an alias to the field set on 'labelField' in the TelegramUserChat List config, or
   *  3. As an alias to a 'name' field on the TelegramUserChat List (if one exists), or
   *  4. As an alias to the 'id' field on the TelegramUserChat List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Condo user  */
  user?: Maybe<User>;
  /**  Id of user's telegram chat with bot  */
  telegramChatId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TelegramUserChatCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  telegramChatId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TelegramUserChatHistoryRecord = {
  __typename?: 'TelegramUserChatHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TelegramUserChatHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TelegramUserChatHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TelegramUserChatHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TelegramUserChatHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  telegramChatId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TelegramUserChatHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TelegramUserChatHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  telegramChatId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TelegramUserChatHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TelegramUserChatHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TelegramUserChatHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  telegramChatId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TelegramUserChatHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TelegramUserChatHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TelegramUserChatHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TelegramUserChatHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  telegramChatId?: Maybe<Scalars['String']>;
  telegramChatId_not?: Maybe<Scalars['String']>;
  telegramChatId_contains?: Maybe<Scalars['String']>;
  telegramChatId_not_contains?: Maybe<Scalars['String']>;
  telegramChatId_starts_with?: Maybe<Scalars['String']>;
  telegramChatId_not_starts_with?: Maybe<Scalars['String']>;
  telegramChatId_ends_with?: Maybe<Scalars['String']>;
  telegramChatId_not_ends_with?: Maybe<Scalars['String']>;
  telegramChatId_i?: Maybe<Scalars['String']>;
  telegramChatId_not_i?: Maybe<Scalars['String']>;
  telegramChatId_contains_i?: Maybe<Scalars['String']>;
  telegramChatId_not_contains_i?: Maybe<Scalars['String']>;
  telegramChatId_starts_with_i?: Maybe<Scalars['String']>;
  telegramChatId_not_starts_with_i?: Maybe<Scalars['String']>;
  telegramChatId_ends_with_i?: Maybe<Scalars['String']>;
  telegramChatId_not_ends_with_i?: Maybe<Scalars['String']>;
  telegramChatId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  telegramChatId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TelegramUserChatHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TelegramUserChatHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TelegramUserChatHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TelegramUserChatHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TelegramUserChatHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TelegramUserChatHistoryRecordsCreateInput = {
  data?: Maybe<TelegramUserChatHistoryRecordCreateInput>;
};

export type TelegramUserChatHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TelegramUserChatHistoryRecordUpdateInput>;
};

export type TelegramUserChatUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  telegramChatId?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TelegramUserChatWhereInput = {
  AND?: Maybe<Array<Maybe<TelegramUserChatWhereInput>>>;
  OR?: Maybe<Array<Maybe<TelegramUserChatWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  telegramChatId?: Maybe<Scalars['String']>;
  telegramChatId_not?: Maybe<Scalars['String']>;
  telegramChatId_contains?: Maybe<Scalars['String']>;
  telegramChatId_not_contains?: Maybe<Scalars['String']>;
  telegramChatId_starts_with?: Maybe<Scalars['String']>;
  telegramChatId_not_starts_with?: Maybe<Scalars['String']>;
  telegramChatId_ends_with?: Maybe<Scalars['String']>;
  telegramChatId_not_ends_with?: Maybe<Scalars['String']>;
  telegramChatId_i?: Maybe<Scalars['String']>;
  telegramChatId_not_i?: Maybe<Scalars['String']>;
  telegramChatId_contains_i?: Maybe<Scalars['String']>;
  telegramChatId_not_contains_i?: Maybe<Scalars['String']>;
  telegramChatId_starts_with_i?: Maybe<Scalars['String']>;
  telegramChatId_not_starts_with_i?: Maybe<Scalars['String']>;
  telegramChatId_ends_with_i?: Maybe<Scalars['String']>;
  telegramChatId_not_ends_with_i?: Maybe<Scalars['String']>;
  telegramChatId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  telegramChatId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TelegramUserChatWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TelegramUserChatsCreateInput = {
  data?: Maybe<TelegramUserChatCreateInput>;
};

export type TelegramUserChatsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TelegramUserChatUpdateInput>;
};

/**  Users request or contact with the user. It has fields `clientName`, `clientPhone`, `clientEmail`, which stores contact information at the moment of creating or updating. Values of these fields are independent from related entities, like Contact, Resident etc. If by some reason related entities will be deleted, unavailable or will change its contact information, these fields will stay unchanged.So, by creating a new ticket with connection to some contact entity (Contact, Resident), these fields will be populated by its contact information if other values are not explicitly provided.  */
export type Ticket = {
  __typename?: 'Ticket';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Ticket List config, or
   *  2. As an alias to the field set on 'labelField' in the Ticket List config, or
   *  3. As an alias to a 'name' field on the Ticket List (if one exists), or
   *  4. As an alias to the 'id' field on the Ticket List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Counter showing the number of changes `status` to `new_or_reopened`  */
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  /**  @deprecated - use "feedbackValue". This field will be removed soon. Review of the ticket by a resident on a 2-point scale. 0 – ticket returned, 1 – bad review, 2 – good review  */
  reviewValue?: Maybe<TicketReviewValueType>;
  /**  @deprecated - use "feedbackAdditionalOptions" and "feedbackComment". This field will be removed soon. Resident's comment on ticket review  */
  reviewComment?: Maybe<Scalars['String']>;
  /**  Feedback of the ticket by a resident on a 2-point scale (0 – ticket returned, 1 – bad review, 2 – good review)  */
  feedbackValue?: Maybe<TicketFeedbackValueType>;
  /**  Staff's comment on ticket feedback  */
  feedbackComment?: Maybe<Scalars['String']>;
  /**  Feedback additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
  feedbackAdditionalOptions?: Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>;
  /**  Feedback update time  */
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  /**  Review of the ticket by a staff on a 2-point scale (bad or good)  */
  qualityControlValue?: Maybe<TicketQualityControlValueType>;
  /**  Staff's comment on ticket review  */
  qualityControlComment?: Maybe<Scalars['String']>;
  /**  Quality control additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
  qualityControlAdditionalOptions?: Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>;
  /**  Quality control updated at time  */
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  /**  User who last updated quality control value/control/additional options  */
  qualityControlUpdatedBy?: Maybe<User>;
  /**  Status updated at time  */
  statusUpdatedAt?: Maybe<Scalars['String']>;
  /**  When status of the ticket was changed to completed  */
  completedAt?: Maybe<Scalars['String']>;
  /**  Last comment time in ticket  */
  lastCommentAt?: Maybe<Scalars['String']>;
  /**  Time of the last comment with resident author in ticket  */
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  /**  Time of the last comment with resident type (from staff or resident user) in ticket  */
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  /**  Text reason for status changes. Sometimes you should describe the reason why you change the `status`  */
  statusReason?: Maybe<Scalars['String']>;
  /**  Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
  status?: Maybe<TicketStatus>;
  /**  Time after which the ticket must be completed  */
  deadline?: Maybe<Scalars['String']>;
  /**  Field required for specific sorting of model objects  */
  order?: Maybe<Scalars['Int']>;
  /**  Autogenerated ticket human readable ID  */
  number?: Maybe<Scalars['Int']>;
  /**  Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
  client?: Maybe<User>;
  /**  Contact, that reported issue, described in this ticket  */
  contact?: Maybe<Contact>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientName?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientEmail?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientPhone?: Maybe<Scalars['String']>;
  /**  Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
  assignee?: Maybe<User>;
  /**  Executor employee/user who perform the issue  */
  executor?: Maybe<User>;
  /**  @deprecated  */
  categoryClassifier?: Maybe<TicketCategoryClassifier>;
  /**  @deprecated  */
  placeClassifier?: Maybe<TicketPlaceClassifier>;
  /**  @deprecated  */
  problemClassifier?: Maybe<TicketProblemClassifier>;
  /**  Valid combination of 3 classifiers  */
  classifier?: Maybe<TicketClassifier>;
  /**  Indicates that ticket has been classified automatically without human confirmation  */
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  /**  Text description of the issue. Maybe written by a user or an operator  */
  details?: Maybe<Scalars['String']>;
  /**  Sometimes, it is important for us to show related issues. For example, to show related issues  */
  related?: Maybe<Ticket>;
  /**
   *  @deprecated Please use "isPayable"
   * Indicates the ticket is payable
   */
  isPaid?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is payable  */
  isPayable?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is emergency  */
  isEmergency?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is warranty  */
  isWarranty?: Maybe<Scalars['Boolean']>;
  /**  Determines who the ticket was created for: for a resident or not for a resident  */
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  /**  Determines if a resident in the mobile app can see the ticket created in crm  */
  canReadByResident?: Maybe<Scalars['Boolean']>;
  /**  Extra analytics not related to remote system  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Property related to the Ticket  */
  property?: Maybe<Property>;
  /**  Address of property, which synced with property and displayed, if property is deleted  */
  propertyAddress?: Maybe<Scalars['String']>;
  /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
  propertyAddressMeta?: Maybe<AddressMetaField>;
  /**  Section name/number of an apartment building (property). You need to take from Property.map  */
  sectionName?: Maybe<Scalars['String']>;
  /**  Type of section, such as parking or section. Default value: "section"  */
  sectionType?: Maybe<TicketSectionTypeType>;
  /**  Floor of an apartment building (property). You need to take from Property.map  */
  floorName?: Maybe<Scalars['String']>;
  /**  Flat number / door number of an apartment building (property). You need to take from Property.map  */
  unitName?: Maybe<Scalars['String']>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitType?: Maybe<TicketUnitTypeType>;
  /**  Ticket source channel/system. Examples: call, email, visit, ...  */
  source?: Maybe<TicketSource>;
  /**  In the case of remote system sync, you can store some extra analytics. Examples: email, name, phone, ...  */
  sourceMeta?: Maybe<Scalars['JSON']>;
  /**  Date until which the ticket is deferred  */
  deferredUntil?: Maybe<Scalars['String']>;
  /**  (Auto-set) Used to filter tickets that were completed (,closed or cancelled) after the deadline.   */
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export enum TicketAnalyticsGroupBy {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Status = 'status',
  Property = 'property',
  CategoryClassifier = 'categoryClassifier',
  Executor = 'executor',
  Assignee = 'assignee',
  QualityControlValue = 'qualityControlValue'
}

export type TicketAnalyticsNullReplaces = {
  categoryClassifier: Scalars['String'];
  executor: Scalars['String'];
  assignee: Scalars['String'];
};

export type TicketAnalyticsReportInput = {
  where: TicketWhereInput;
  groupBy?: Maybe<Array<TicketAnalyticsGroupBy>>;
  nullReplaces: TicketAnalyticsNullReplaces;
};

export type TicketAnalyticsReportOutput = {
  __typename?: 'TicketAnalyticsReportOutput';
  groups?: Maybe<Array<TicketGroupedCounter>>;
  ticketLabels?: Maybe<Array<Maybe<TicketLabel>>>;
};

/**  This schema helps decides who should be assigned to ticket as executor and assignee  */
export type TicketAutoAssignment = {
  __typename?: 'TicketAutoAssignment';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketAutoAssignment List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketAutoAssignment List config, or
   *  3. As an alias to a 'name' field on the TicketAutoAssignment List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketAutoAssignment List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  An employee who will be appointed as assignee of ticket. If "null", then the field will remain empty and dispatcher will have to fill it in independently in UI  */
  assignee?: Maybe<OrganizationEmployee>;
  /**  An employee who will be appointed as executor of ticketIf "null", then the field will remain empty and dispatcher will have to fill it in independently in UI  */
  executor?: Maybe<OrganizationEmployee>;
  /**  Ticket classifier  */
  classifier?: Maybe<TicketClassifier>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketAutoAssignmentCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  assignee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  executor?: Maybe<OrganizationEmployeeRelateToOneInput>;
  classifier?: Maybe<TicketClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketAutoAssignmentHistoryRecord = {
  __typename?: 'TicketAutoAssignmentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketAutoAssignmentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketAutoAssignmentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketAutoAssignmentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketAutoAssignmentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketAutoAssignmentHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketAutoAssignmentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketAutoAssignmentHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketAutoAssignmentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignee?: Maybe<Scalars['String']>;
  assignee_not?: Maybe<Scalars['String']>;
  assignee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executor?: Maybe<Scalars['String']>;
  executor_not?: Maybe<Scalars['String']>;
  executor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifier?: Maybe<Scalars['String']>;
  classifier_not?: Maybe<Scalars['String']>;
  classifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketAutoAssignmentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketAutoAssignmentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketAutoAssignmentHistoryRecordsCreateInput = {
  data?: Maybe<TicketAutoAssignmentHistoryRecordCreateInput>;
};

export type TicketAutoAssignmentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketAutoAssignmentHistoryRecordUpdateInput>;
};

export type TicketAutoAssignmentUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  assignee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  executor?: Maybe<OrganizationEmployeeRelateToOneInput>;
  classifier?: Maybe<TicketClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketAutoAssignmentWhereInput = {
  AND?: Maybe<Array<Maybe<TicketAutoAssignmentWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketAutoAssignmentWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  assignee?: Maybe<OrganizationEmployeeWhereInput>;
  assignee_is_null?: Maybe<Scalars['Boolean']>;
  executor?: Maybe<OrganizationEmployeeWhereInput>;
  executor_is_null?: Maybe<Scalars['Boolean']>;
  classifier?: Maybe<TicketClassifierWhereInput>;
  classifier_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketAutoAssignmentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketAutoAssignmentsCreateInput = {
  data?: Maybe<TicketAutoAssignmentCreateInput>;
};

export type TicketAutoAssignmentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketAutoAssignmentUpdateInput>;
};

/**  Describes what type of work needs to be done to fix incident  */
export type TicketCategoryClassifier = {
  __typename?: 'TicketCategoryClassifier';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCategoryClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCategoryClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketCategoryClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCategoryClassifier List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  text description  */
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketCategoryClassifierCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketCategoryClassifierHistoryRecord = {
  __typename?: 'TicketCategoryClassifierHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCategoryClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCategoryClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketCategoryClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCategoryClassifierHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketCategoryClassifierHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketCategoryClassifierHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketCategoryClassifierHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketCategoryClassifierHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketCategoryClassifierHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketCategoryClassifierHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketCategoryClassifierHistoryRecordsCreateInput = {
  data?: Maybe<TicketCategoryClassifierHistoryRecordCreateInput>;
};

export type TicketCategoryClassifierHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketCategoryClassifierHistoryRecordUpdateInput>;
};

export type TicketCategoryClassifierRelateToOneInput = {
  create?: Maybe<TicketCategoryClassifierCreateInput>;
  connect?: Maybe<TicketCategoryClassifierWhereUniqueInput>;
  disconnect?: Maybe<TicketCategoryClassifierWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type TicketCategoryClassifierUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketCategoryClassifierWhereInput = {
  AND?: Maybe<Array<Maybe<TicketCategoryClassifierWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketCategoryClassifierWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketCategoryClassifierWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketCategoryClassifiersCreateInput = {
  data?: Maybe<TicketCategoryClassifierCreateInput>;
};

export type TicketCategoryClassifiersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketCategoryClassifierUpdateInput>;
};

/**  Incremental changes of Ticket  */
export type TicketChange = {
  __typename?: 'TicketChange';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketChange List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketChange List config, or
   *  3. As an alias to a 'name' field on the TicketChange List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketChange List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Related ticket, whose change is logged in this entity  */
  ticket?: Maybe<Ticket>;
  /**  Counter showing the number of changes `status` to `new_or_reopened`  */
  statusReopenedCounterFrom?: Maybe<Scalars['Int']>;
  /**  Counter showing the number of changes `status` to `new_or_reopened`  */
  statusReopenedCounterTo?: Maybe<Scalars['Int']>;
  /**  @deprecated - use "feedbackValue". This field will be removed soon. Review of the ticket by a resident on a 2-point scale. 0 – ticket returned, 1 – bad review, 2 – good review  */
  reviewValueFrom?: Maybe<TicketChangeReviewValueFromType>;
  /**  @deprecated - use "feedbackValue". This field will be removed soon. Review of the ticket by a resident on a 2-point scale. 0 – ticket returned, 1 – bad review, 2 – good review  */
  reviewValueTo?: Maybe<TicketChangeReviewValueToType>;
  /**  @deprecated - use "feedbackAdditionalOptions" and "feedbackComment". This field will be removed soon. Resident's comment on ticket review  */
  reviewCommentFrom?: Maybe<Scalars['String']>;
  /**  @deprecated - use "feedbackAdditionalOptions" and "feedbackComment". This field will be removed soon. Resident's comment on ticket review  */
  reviewCommentTo?: Maybe<Scalars['String']>;
  /**  Feedback of the ticket by a resident on a 2-point scale (0 – ticket returned, 1 – bad review, 2 – good review)  */
  feedbackValueFrom?: Maybe<TicketChangeFeedbackValueFromType>;
  /**  Feedback of the ticket by a resident on a 2-point scale (0 – ticket returned, 1 – bad review, 2 – good review)  */
  feedbackValueTo?: Maybe<TicketChangeFeedbackValueToType>;
  /**  Staff's comment on ticket feedback  */
  feedbackCommentFrom?: Maybe<Scalars['String']>;
  /**  Staff's comment on ticket feedback  */
  feedbackCommentTo?: Maybe<Scalars['String']>;
  /**  Feedback additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
  feedbackAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  /**  Feedback additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
  feedbackAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  /**  Review of the ticket by a staff on a 2-point scale (bad or good)  */
  qualityControlValueFrom?: Maybe<TicketChangeQualityControlValueFromType>;
  /**  Review of the ticket by a staff on a 2-point scale (bad or good)  */
  qualityControlValueTo?: Maybe<TicketChangeQualityControlValueToType>;
  /**  Staff's comment on ticket review  */
  qualityControlCommentFrom?: Maybe<Scalars['String']>;
  /**  Staff's comment on ticket review  */
  qualityControlCommentTo?: Maybe<Scalars['String']>;
  /**  Quality control additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
  qualityControlAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  /**  Quality control additional options that extend it.Duplicates are removed and empty arrays are converted to null.  */
  qualityControlAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  /**  Text reason for status changes. Sometimes you should describe the reason why you change the `status`  */
  statusReasonFrom?: Maybe<Scalars['String']>;
  /**  Text reason for status changes. Sometimes you should describe the reason why you change the `status`  */
  statusReasonTo?: Maybe<Scalars['String']>;
  /**  Time after which the ticket must be completed  */
  deadlineFrom?: Maybe<Scalars['String']>;
  /**  Time after which the ticket must be completed  */
  deadlineTo?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientNameFrom?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientNameTo?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientEmailFrom?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientEmailTo?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientPhoneFrom?: Maybe<Scalars['String']>;
  /**  Inhabitant/customer/person who has a problem. Sometimes we get a problem from an unregistered client, in such cases we have a null inside the `client` and just have something here. Or sometimes clients want to change it  */
  clientPhoneTo?: Maybe<Scalars['String']>;
  /**  Text description of the issue. Maybe written by a user or an operator  */
  detailsFrom?: Maybe<Scalars['String']>;
  /**  Text description of the issue. Maybe written by a user or an operator  */
  detailsTo?: Maybe<Scalars['String']>;
  /**
   *  @deprecated Please use "isPayable"
   * Indicates the ticket is payable
   */
  isPaidFrom?: Maybe<Scalars['Boolean']>;
  /**
   *  @deprecated Please use "isPayable"
   * Indicates the ticket is payable
   */
  isPaidTo?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is payable  */
  isPayableFrom?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is payable  */
  isPayableTo?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is emergency  */
  isEmergencyFrom?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is emergency  */
  isEmergencyTo?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is warranty  */
  isWarrantyFrom?: Maybe<Scalars['Boolean']>;
  /**  Indicates the ticket is warranty  */
  isWarrantyTo?: Maybe<Scalars['Boolean']>;
  /**  Determines who the ticket was created for: for a resident or not for a resident  */
  isResidentTicketFrom?: Maybe<Scalars['Boolean']>;
  /**  Determines who the ticket was created for: for a resident or not for a resident  */
  isResidentTicketTo?: Maybe<Scalars['Boolean']>;
  /**  Determines if a resident in the mobile app can see the ticket created in crm  */
  canReadByResidentFrom?: Maybe<Scalars['Boolean']>;
  /**  Determines if a resident in the mobile app can see the ticket created in crm  */
  canReadByResidentTo?: Maybe<Scalars['Boolean']>;
  /**  Extra analytics not related to remote system  */
  metaFrom?: Maybe<Scalars['JSON']>;
  /**  Extra analytics not related to remote system  */
  metaTo?: Maybe<Scalars['JSON']>;
  /**  Address of property, which synced with property and displayed, if property is deleted  */
  propertyAddressFrom?: Maybe<Scalars['String']>;
  /**  Address of property, which synced with property and displayed, if property is deleted  */
  propertyAddressTo?: Maybe<Scalars['String']>;
  /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
  propertyAddressMetaFrom?: Maybe<Scalars['JSON']>;
  /**  Address meta of property, which synced with property and used to form view of address, if property is deleted  */
  propertyAddressMetaTo?: Maybe<Scalars['JSON']>;
  /**  Section name/number of an apartment building (property). You need to take from Property.map  */
  sectionNameFrom?: Maybe<Scalars['String']>;
  /**  Section name/number of an apartment building (property). You need to take from Property.map  */
  sectionNameTo?: Maybe<Scalars['String']>;
  /**  Type of section, such as parking or section. Default value: "section"  */
  sectionTypeFrom?: Maybe<TicketChangeSectionTypeFromType>;
  /**  Type of section, such as parking or section. Default value: "section"  */
  sectionTypeTo?: Maybe<TicketChangeSectionTypeToType>;
  /**  Floor of an apartment building (property). You need to take from Property.map  */
  floorNameFrom?: Maybe<Scalars['String']>;
  /**  Floor of an apartment building (property). You need to take from Property.map  */
  floorNameTo?: Maybe<Scalars['String']>;
  /**  Flat number / door number of an apartment building (property). You need to take from Property.map  */
  unitNameFrom?: Maybe<Scalars['String']>;
  /**  Flat number / door number of an apartment building (property). You need to take from Property.map  */
  unitNameTo?: Maybe<Scalars['String']>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitTypeFrom?: Maybe<TicketChangeUnitTypeFromType>;
  /**  Type of unit, such as parking lot or flat. Default value: "flat"  */
  unitTypeTo?: Maybe<TicketChangeUnitTypeToType>;
  /**  In the case of remote system sync, you can store some extra analytics. Examples: email, name, phone, ...  */
  sourceMetaFrom?: Maybe<Scalars['JSON']>;
  /**  In the case of remote system sync, you can store some extra analytics. Examples: email, name, phone, ...  */
  sourceMetaTo?: Maybe<Scalars['JSON']>;
  /**  Date until which the ticket is deferred  */
  deferredUntilFrom?: Maybe<Scalars['String']>;
  /**  Date until which the ticket is deferred  */
  deferredUntilTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
  statusIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
  statusIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
  statusDisplayNameFrom?: Maybe<Scalars['String']>;
  statusDisplayNameFromNonLocalized?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Status is the step of the ticket processing workflow. Companies may have different ticket processing workflows  */
  statusDisplayNameTo?: Maybe<Scalars['String']>;
  statusDisplayNameToNonLocalized?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
  clientIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
  clientIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
  clientDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Inhabitant/customer/person who has a problem or want to improve/order something. Not null if we have a registered client. This field indicates, that the Ticket is visible to a Resident and it has access to it. This field will be set to User of corresponding Resident in following cases: 1) the Ticket was created by Resident from mobile app;2) the Ticket was created by OrganizationEmployee with phone number, that matches some Resident;3) a Resident was registered after this Ticket was created and the Resident have the same phone number as in this Ticket, so this Ticket will be automatically connected to it.  */
  clientDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Contact, that reported issue, described in this ticket  */
  contactIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Contact, that reported issue, described in this ticket  */
  contactIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Contact, that reported issue, described in this ticket  */
  contactDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Contact, that reported issue, described in this ticket  */
  contactDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
  assigneeIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
  assigneeIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
  assigneeDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Assignee/responsible employee/user who must ensure that the issue is fulfilled  */
  assigneeDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Executor employee/user who perform the issue  */
  executorIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Executor employee/user who perform the issue  */
  executorIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Executor employee/user who perform the issue  */
  executorDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Executor employee/user who perform the issue  */
  executorDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Valid combination of 3 classifiers  */
  classifierIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Valid combination of 3 classifiers  */
  classifierIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Valid combination of 3 classifiers  */
  classifierDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Valid combination of 3 classifiers  */
  classifierDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
  relatedIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
  relatedIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
  relatedDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Sometimes, it is important for us to show related issues. For example, to show related issues  */
  relatedDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Property related to the Ticket  */
  propertyIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Property related to the Ticket  */
  propertyIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Property related to the Ticket  */
  propertyDisplayNameFrom?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Property related to the Ticket  */
  propertyDisplayNameTo?: Maybe<Scalars['String']>;
  /**  Old id of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
  sourceIdFrom?: Maybe<Scalars['ID']>;
  /**  New id of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
  sourceIdTo?: Maybe<Scalars['ID']>;
  /**  Old display name of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
  sourceDisplayNameFrom?: Maybe<Scalars['String']>;
  sourceDisplayNameFromNonLocalized?: Maybe<Scalars['String']>;
  /**  New display name of related entity. Ticket source channel/system. Examples: call, email, visit, ...  */
  sourceDisplayNameTo?: Maybe<Scalars['String']>;
  sourceDisplayNameToNonLocalized?: Maybe<Scalars['String']>;
  /**  Actual creation ticket change date, for case when ticket updated from offline  */
  actualCreationDate?: Maybe<Scalars['String']>;
  /**  Type of user who changed the ticket, can be employee role from same organization or related, resident or deleted employee  */
  changedByRole?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketChangeCreateInput = {
  ticket?: Maybe<TicketRelateToOneInput>;
  statusReopenedCounterFrom?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo?: Maybe<Scalars['Int']>;
  reviewValueFrom?: Maybe<TicketChangeReviewValueFromType>;
  reviewValueTo?: Maybe<TicketChangeReviewValueToType>;
  reviewCommentFrom?: Maybe<Scalars['String']>;
  reviewCommentTo?: Maybe<Scalars['String']>;
  feedbackValueFrom?: Maybe<TicketChangeFeedbackValueFromType>;
  feedbackValueTo?: Maybe<TicketChangeFeedbackValueToType>;
  feedbackCommentFrom?: Maybe<Scalars['String']>;
  feedbackCommentTo?: Maybe<Scalars['String']>;
  feedbackAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  qualityControlValueFrom?: Maybe<TicketChangeQualityControlValueFromType>;
  qualityControlValueTo?: Maybe<TicketChangeQualityControlValueToType>;
  qualityControlCommentFrom?: Maybe<Scalars['String']>;
  qualityControlCommentTo?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  statusReasonFrom?: Maybe<Scalars['String']>;
  statusReasonTo?: Maybe<Scalars['String']>;
  deadlineFrom?: Maybe<Scalars['String']>;
  deadlineTo?: Maybe<Scalars['String']>;
  clientNameFrom?: Maybe<Scalars['String']>;
  clientNameTo?: Maybe<Scalars['String']>;
  clientEmailFrom?: Maybe<Scalars['String']>;
  clientEmailTo?: Maybe<Scalars['String']>;
  clientPhoneFrom?: Maybe<Scalars['String']>;
  clientPhoneTo?: Maybe<Scalars['String']>;
  detailsFrom?: Maybe<Scalars['String']>;
  detailsTo?: Maybe<Scalars['String']>;
  isPaidFrom?: Maybe<Scalars['Boolean']>;
  isPaidTo?: Maybe<Scalars['Boolean']>;
  isPayableFrom?: Maybe<Scalars['Boolean']>;
  isPayableTo?: Maybe<Scalars['Boolean']>;
  isEmergencyFrom?: Maybe<Scalars['Boolean']>;
  isEmergencyTo?: Maybe<Scalars['Boolean']>;
  isWarrantyFrom?: Maybe<Scalars['Boolean']>;
  isWarrantyTo?: Maybe<Scalars['Boolean']>;
  isResidentTicketFrom?: Maybe<Scalars['Boolean']>;
  isResidentTicketTo?: Maybe<Scalars['Boolean']>;
  canReadByResidentFrom?: Maybe<Scalars['Boolean']>;
  canReadByResidentTo?: Maybe<Scalars['Boolean']>;
  metaFrom?: Maybe<Scalars['JSON']>;
  metaTo?: Maybe<Scalars['JSON']>;
  propertyAddressFrom?: Maybe<Scalars['String']>;
  propertyAddressTo?: Maybe<Scalars['String']>;
  propertyAddressMetaFrom?: Maybe<Scalars['JSON']>;
  propertyAddressMetaTo?: Maybe<Scalars['JSON']>;
  sectionNameFrom?: Maybe<Scalars['String']>;
  sectionNameTo?: Maybe<Scalars['String']>;
  sectionTypeFrom?: Maybe<TicketChangeSectionTypeFromType>;
  sectionTypeTo?: Maybe<TicketChangeSectionTypeToType>;
  floorNameFrom?: Maybe<Scalars['String']>;
  floorNameTo?: Maybe<Scalars['String']>;
  unitNameFrom?: Maybe<Scalars['String']>;
  unitNameTo?: Maybe<Scalars['String']>;
  unitTypeFrom?: Maybe<TicketChangeUnitTypeFromType>;
  unitTypeTo?: Maybe<TicketChangeUnitTypeToType>;
  sourceMetaFrom?: Maybe<Scalars['JSON']>;
  sourceMetaTo?: Maybe<Scalars['JSON']>;
  deferredUntilFrom?: Maybe<Scalars['String']>;
  deferredUntilTo?: Maybe<Scalars['String']>;
  organizationIdFrom?: Maybe<Scalars['ID']>;
  organizationIdTo?: Maybe<Scalars['ID']>;
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  statusIdFrom?: Maybe<Scalars['ID']>;
  statusIdTo?: Maybe<Scalars['ID']>;
  statusDisplayNameFrom?: Maybe<Scalars['String']>;
  statusDisplayNameTo?: Maybe<Scalars['String']>;
  clientIdFrom?: Maybe<Scalars['ID']>;
  clientIdTo?: Maybe<Scalars['ID']>;
  clientDisplayNameFrom?: Maybe<Scalars['String']>;
  clientDisplayNameTo?: Maybe<Scalars['String']>;
  contactIdFrom?: Maybe<Scalars['ID']>;
  contactIdTo?: Maybe<Scalars['ID']>;
  contactDisplayNameFrom?: Maybe<Scalars['String']>;
  contactDisplayNameTo?: Maybe<Scalars['String']>;
  assigneeIdFrom?: Maybe<Scalars['ID']>;
  assigneeIdTo?: Maybe<Scalars['ID']>;
  assigneeDisplayNameFrom?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo?: Maybe<Scalars['String']>;
  executorIdFrom?: Maybe<Scalars['ID']>;
  executorIdTo?: Maybe<Scalars['ID']>;
  executorDisplayNameFrom?: Maybe<Scalars['String']>;
  executorDisplayNameTo?: Maybe<Scalars['String']>;
  classifierIdFrom?: Maybe<Scalars['ID']>;
  classifierIdTo?: Maybe<Scalars['ID']>;
  classifierDisplayNameFrom?: Maybe<Scalars['String']>;
  classifierDisplayNameTo?: Maybe<Scalars['String']>;
  relatedIdFrom?: Maybe<Scalars['ID']>;
  relatedIdTo?: Maybe<Scalars['ID']>;
  relatedDisplayNameFrom?: Maybe<Scalars['String']>;
  relatedDisplayNameTo?: Maybe<Scalars['String']>;
  propertyIdFrom?: Maybe<Scalars['ID']>;
  propertyIdTo?: Maybe<Scalars['ID']>;
  propertyDisplayNameFrom?: Maybe<Scalars['String']>;
  propertyDisplayNameTo?: Maybe<Scalars['String']>;
  sourceIdFrom?: Maybe<Scalars['ID']>;
  sourceIdTo?: Maybe<Scalars['ID']>;
  sourceDisplayNameFrom?: Maybe<Scalars['String']>;
  sourceDisplayNameTo?: Maybe<Scalars['String']>;
  actualCreationDate?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum TicketChangeFeedbackValueFromType {
  Bad = 'bad',
  Good = 'good',
  Returned = 'returned'
}

export enum TicketChangeFeedbackValueToType {
  Bad = 'bad',
  Good = 'good',
  Returned = 'returned'
}

export enum TicketChangeQualityControlValueFromType {
  Bad = 'bad',
  Good = 'good'
}

export enum TicketChangeQualityControlValueToType {
  Bad = 'bad',
  Good = 'good'
}

export enum TicketChangeReviewValueFromType {
  Bad = 'bad',
  Good = 'good',
  Returned = 'returned'
}

export enum TicketChangeReviewValueToType {
  Bad = 'bad',
  Good = 'good',
  Returned = 'returned'
}

export enum TicketChangeSectionTypeFromType {
  Parking = 'parking',
  Section = 'section'
}

export enum TicketChangeSectionTypeToType {
  Parking = 'parking',
  Section = 'section'
}

export enum TicketChangeUnitTypeFromType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export enum TicketChangeUnitTypeToType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type TicketChangeUpdateInput = {
  ticket?: Maybe<TicketRelateToOneInput>;
  statusReopenedCounterFrom?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo?: Maybe<Scalars['Int']>;
  reviewValueFrom?: Maybe<TicketChangeReviewValueFromType>;
  reviewValueTo?: Maybe<TicketChangeReviewValueToType>;
  reviewCommentFrom?: Maybe<Scalars['String']>;
  reviewCommentTo?: Maybe<Scalars['String']>;
  feedbackValueFrom?: Maybe<TicketChangeFeedbackValueFromType>;
  feedbackValueTo?: Maybe<TicketChangeFeedbackValueToType>;
  feedbackCommentFrom?: Maybe<Scalars['String']>;
  feedbackCommentTo?: Maybe<Scalars['String']>;
  feedbackAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  qualityControlValueFrom?: Maybe<TicketChangeQualityControlValueFromType>;
  qualityControlValueTo?: Maybe<TicketChangeQualityControlValueToType>;
  qualityControlCommentFrom?: Maybe<Scalars['String']>;
  qualityControlCommentTo?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  statusReasonFrom?: Maybe<Scalars['String']>;
  statusReasonTo?: Maybe<Scalars['String']>;
  deadlineFrom?: Maybe<Scalars['String']>;
  deadlineTo?: Maybe<Scalars['String']>;
  clientNameFrom?: Maybe<Scalars['String']>;
  clientNameTo?: Maybe<Scalars['String']>;
  clientEmailFrom?: Maybe<Scalars['String']>;
  clientEmailTo?: Maybe<Scalars['String']>;
  clientPhoneFrom?: Maybe<Scalars['String']>;
  clientPhoneTo?: Maybe<Scalars['String']>;
  detailsFrom?: Maybe<Scalars['String']>;
  detailsTo?: Maybe<Scalars['String']>;
  isPaidFrom?: Maybe<Scalars['Boolean']>;
  isPaidTo?: Maybe<Scalars['Boolean']>;
  isPayableFrom?: Maybe<Scalars['Boolean']>;
  isPayableTo?: Maybe<Scalars['Boolean']>;
  isEmergencyFrom?: Maybe<Scalars['Boolean']>;
  isEmergencyTo?: Maybe<Scalars['Boolean']>;
  isWarrantyFrom?: Maybe<Scalars['Boolean']>;
  isWarrantyTo?: Maybe<Scalars['Boolean']>;
  isResidentTicketFrom?: Maybe<Scalars['Boolean']>;
  isResidentTicketTo?: Maybe<Scalars['Boolean']>;
  canReadByResidentFrom?: Maybe<Scalars['Boolean']>;
  canReadByResidentTo?: Maybe<Scalars['Boolean']>;
  metaFrom?: Maybe<Scalars['JSON']>;
  metaTo?: Maybe<Scalars['JSON']>;
  propertyAddressFrom?: Maybe<Scalars['String']>;
  propertyAddressTo?: Maybe<Scalars['String']>;
  propertyAddressMetaFrom?: Maybe<Scalars['JSON']>;
  propertyAddressMetaTo?: Maybe<Scalars['JSON']>;
  sectionNameFrom?: Maybe<Scalars['String']>;
  sectionNameTo?: Maybe<Scalars['String']>;
  sectionTypeFrom?: Maybe<TicketChangeSectionTypeFromType>;
  sectionTypeTo?: Maybe<TicketChangeSectionTypeToType>;
  floorNameFrom?: Maybe<Scalars['String']>;
  floorNameTo?: Maybe<Scalars['String']>;
  unitNameFrom?: Maybe<Scalars['String']>;
  unitNameTo?: Maybe<Scalars['String']>;
  unitTypeFrom?: Maybe<TicketChangeUnitTypeFromType>;
  unitTypeTo?: Maybe<TicketChangeUnitTypeToType>;
  sourceMetaFrom?: Maybe<Scalars['JSON']>;
  sourceMetaTo?: Maybe<Scalars['JSON']>;
  deferredUntilFrom?: Maybe<Scalars['String']>;
  deferredUntilTo?: Maybe<Scalars['String']>;
  organizationIdFrom?: Maybe<Scalars['ID']>;
  organizationIdTo?: Maybe<Scalars['ID']>;
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  statusIdFrom?: Maybe<Scalars['ID']>;
  statusIdTo?: Maybe<Scalars['ID']>;
  statusDisplayNameFrom?: Maybe<Scalars['String']>;
  statusDisplayNameTo?: Maybe<Scalars['String']>;
  clientIdFrom?: Maybe<Scalars['ID']>;
  clientIdTo?: Maybe<Scalars['ID']>;
  clientDisplayNameFrom?: Maybe<Scalars['String']>;
  clientDisplayNameTo?: Maybe<Scalars['String']>;
  contactIdFrom?: Maybe<Scalars['ID']>;
  contactIdTo?: Maybe<Scalars['ID']>;
  contactDisplayNameFrom?: Maybe<Scalars['String']>;
  contactDisplayNameTo?: Maybe<Scalars['String']>;
  assigneeIdFrom?: Maybe<Scalars['ID']>;
  assigneeIdTo?: Maybe<Scalars['ID']>;
  assigneeDisplayNameFrom?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo?: Maybe<Scalars['String']>;
  executorIdFrom?: Maybe<Scalars['ID']>;
  executorIdTo?: Maybe<Scalars['ID']>;
  executorDisplayNameFrom?: Maybe<Scalars['String']>;
  executorDisplayNameTo?: Maybe<Scalars['String']>;
  classifierIdFrom?: Maybe<Scalars['ID']>;
  classifierIdTo?: Maybe<Scalars['ID']>;
  classifierDisplayNameFrom?: Maybe<Scalars['String']>;
  classifierDisplayNameTo?: Maybe<Scalars['String']>;
  relatedIdFrom?: Maybe<Scalars['ID']>;
  relatedIdTo?: Maybe<Scalars['ID']>;
  relatedDisplayNameFrom?: Maybe<Scalars['String']>;
  relatedDisplayNameTo?: Maybe<Scalars['String']>;
  propertyIdFrom?: Maybe<Scalars['ID']>;
  propertyIdTo?: Maybe<Scalars['ID']>;
  propertyDisplayNameFrom?: Maybe<Scalars['String']>;
  propertyDisplayNameTo?: Maybe<Scalars['String']>;
  sourceIdFrom?: Maybe<Scalars['ID']>;
  sourceIdTo?: Maybe<Scalars['ID']>;
  sourceDisplayNameFrom?: Maybe<Scalars['String']>;
  sourceDisplayNameTo?: Maybe<Scalars['String']>;
  actualCreationDate?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketChangeWhereInput = {
  AND?: Maybe<Array<Maybe<TicketChangeWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketChangeWhereInput>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  statusReopenedCounterFrom?: Maybe<Scalars['Int']>;
  statusReopenedCounterFrom_not?: Maybe<Scalars['Int']>;
  statusReopenedCounterFrom_lt?: Maybe<Scalars['Int']>;
  statusReopenedCounterFrom_lte?: Maybe<Scalars['Int']>;
  statusReopenedCounterFrom_gt?: Maybe<Scalars['Int']>;
  statusReopenedCounterFrom_gte?: Maybe<Scalars['Int']>;
  statusReopenedCounterFrom_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusReopenedCounterFrom_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusReopenedCounterTo?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo_not?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo_lt?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo_lte?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo_gt?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo_gte?: Maybe<Scalars['Int']>;
  statusReopenedCounterTo_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusReopenedCounterTo_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  reviewValueFrom?: Maybe<TicketChangeReviewValueFromType>;
  reviewValueFrom_not?: Maybe<TicketChangeReviewValueFromType>;
  reviewValueFrom_in?: Maybe<Array<Maybe<TicketChangeReviewValueFromType>>>;
  reviewValueFrom_not_in?: Maybe<Array<Maybe<TicketChangeReviewValueFromType>>>;
  reviewValueTo?: Maybe<TicketChangeReviewValueToType>;
  reviewValueTo_not?: Maybe<TicketChangeReviewValueToType>;
  reviewValueTo_in?: Maybe<Array<Maybe<TicketChangeReviewValueToType>>>;
  reviewValueTo_not_in?: Maybe<Array<Maybe<TicketChangeReviewValueToType>>>;
  reviewCommentFrom?: Maybe<Scalars['String']>;
  reviewCommentFrom_not?: Maybe<Scalars['String']>;
  reviewCommentFrom_contains?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_contains?: Maybe<Scalars['String']>;
  reviewCommentFrom_starts_with?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_starts_with?: Maybe<Scalars['String']>;
  reviewCommentFrom_ends_with?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_ends_with?: Maybe<Scalars['String']>;
  reviewCommentFrom_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_contains_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_contains_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_starts_with_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_ends_with_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  reviewCommentFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewCommentFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewCommentTo?: Maybe<Scalars['String']>;
  reviewCommentTo_not?: Maybe<Scalars['String']>;
  reviewCommentTo_contains?: Maybe<Scalars['String']>;
  reviewCommentTo_not_contains?: Maybe<Scalars['String']>;
  reviewCommentTo_starts_with?: Maybe<Scalars['String']>;
  reviewCommentTo_not_starts_with?: Maybe<Scalars['String']>;
  reviewCommentTo_ends_with?: Maybe<Scalars['String']>;
  reviewCommentTo_not_ends_with?: Maybe<Scalars['String']>;
  reviewCommentTo_i?: Maybe<Scalars['String']>;
  reviewCommentTo_not_i?: Maybe<Scalars['String']>;
  reviewCommentTo_contains_i?: Maybe<Scalars['String']>;
  reviewCommentTo_not_contains_i?: Maybe<Scalars['String']>;
  reviewCommentTo_starts_with_i?: Maybe<Scalars['String']>;
  reviewCommentTo_not_starts_with_i?: Maybe<Scalars['String']>;
  reviewCommentTo_ends_with_i?: Maybe<Scalars['String']>;
  reviewCommentTo_not_ends_with_i?: Maybe<Scalars['String']>;
  reviewCommentTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewCommentTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackValueFrom?: Maybe<TicketChangeFeedbackValueFromType>;
  feedbackValueFrom_not?: Maybe<TicketChangeFeedbackValueFromType>;
  feedbackValueFrom_in?: Maybe<Array<Maybe<TicketChangeFeedbackValueFromType>>>;
  feedbackValueFrom_not_in?: Maybe<Array<Maybe<TicketChangeFeedbackValueFromType>>>;
  feedbackValueTo?: Maybe<TicketChangeFeedbackValueToType>;
  feedbackValueTo_not?: Maybe<TicketChangeFeedbackValueToType>;
  feedbackValueTo_in?: Maybe<Array<Maybe<TicketChangeFeedbackValueToType>>>;
  feedbackValueTo_not_in?: Maybe<Array<Maybe<TicketChangeFeedbackValueToType>>>;
  feedbackCommentFrom?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not?: Maybe<Scalars['String']>;
  feedbackCommentFrom_contains?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_contains?: Maybe<Scalars['String']>;
  feedbackCommentFrom_starts_with?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_starts_with?: Maybe<Scalars['String']>;
  feedbackCommentFrom_ends_with?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_ends_with?: Maybe<Scalars['String']>;
  feedbackCommentFrom_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_contains_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_contains_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_starts_with_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_ends_with_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  feedbackCommentFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackCommentFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackCommentTo?: Maybe<Scalars['String']>;
  feedbackCommentTo_not?: Maybe<Scalars['String']>;
  feedbackCommentTo_contains?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_contains?: Maybe<Scalars['String']>;
  feedbackCommentTo_starts_with?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_starts_with?: Maybe<Scalars['String']>;
  feedbackCommentTo_ends_with?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_ends_with?: Maybe<Scalars['String']>;
  feedbackCommentTo_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_contains_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_contains_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_starts_with_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_starts_with_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_ends_with_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_not_ends_with_i?: Maybe<Scalars['String']>;
  feedbackCommentTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackCommentTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptionsFrom_not?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptionsFrom_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  feedbackAdditionalOptionsFrom_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  feedbackAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptionsTo_not?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptionsTo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  feedbackAdditionalOptionsTo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  qualityControlValueFrom?: Maybe<TicketChangeQualityControlValueFromType>;
  qualityControlValueFrom_not?: Maybe<TicketChangeQualityControlValueFromType>;
  qualityControlValueFrom_in?: Maybe<Array<Maybe<TicketChangeQualityControlValueFromType>>>;
  qualityControlValueFrom_not_in?: Maybe<Array<Maybe<TicketChangeQualityControlValueFromType>>>;
  qualityControlValueTo?: Maybe<TicketChangeQualityControlValueToType>;
  qualityControlValueTo_not?: Maybe<TicketChangeQualityControlValueToType>;
  qualityControlValueTo_in?: Maybe<Array<Maybe<TicketChangeQualityControlValueToType>>>;
  qualityControlValueTo_not_in?: Maybe<Array<Maybe<TicketChangeQualityControlValueToType>>>;
  qualityControlCommentFrom?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_contains?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_contains?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_starts_with?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_starts_with?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_ends_with?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_ends_with?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_contains_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_contains_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlCommentFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlCommentTo?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not?: Maybe<Scalars['String']>;
  qualityControlCommentTo_contains?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_contains?: Maybe<Scalars['String']>;
  qualityControlCommentTo_starts_with?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_starts_with?: Maybe<Scalars['String']>;
  qualityControlCommentTo_ends_with?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_ends_with?: Maybe<Scalars['String']>;
  qualityControlCommentTo_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_contains_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_contains_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_not_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlCommentTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlCommentTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlAdditionalOptionsFrom?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptionsFrom_not?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptionsFrom_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  qualityControlAdditionalOptionsFrom_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  qualityControlAdditionalOptionsTo?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptionsTo_not?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptionsTo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  qualityControlAdditionalOptionsTo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  statusReasonFrom?: Maybe<Scalars['String']>;
  statusReasonFrom_not?: Maybe<Scalars['String']>;
  statusReasonFrom_contains?: Maybe<Scalars['String']>;
  statusReasonFrom_not_contains?: Maybe<Scalars['String']>;
  statusReasonFrom_starts_with?: Maybe<Scalars['String']>;
  statusReasonFrom_not_starts_with?: Maybe<Scalars['String']>;
  statusReasonFrom_ends_with?: Maybe<Scalars['String']>;
  statusReasonFrom_not_ends_with?: Maybe<Scalars['String']>;
  statusReasonFrom_i?: Maybe<Scalars['String']>;
  statusReasonFrom_not_i?: Maybe<Scalars['String']>;
  statusReasonFrom_contains_i?: Maybe<Scalars['String']>;
  statusReasonFrom_not_contains_i?: Maybe<Scalars['String']>;
  statusReasonFrom_starts_with_i?: Maybe<Scalars['String']>;
  statusReasonFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  statusReasonFrom_ends_with_i?: Maybe<Scalars['String']>;
  statusReasonFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  statusReasonFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReasonFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReasonTo?: Maybe<Scalars['String']>;
  statusReasonTo_not?: Maybe<Scalars['String']>;
  statusReasonTo_contains?: Maybe<Scalars['String']>;
  statusReasonTo_not_contains?: Maybe<Scalars['String']>;
  statusReasonTo_starts_with?: Maybe<Scalars['String']>;
  statusReasonTo_not_starts_with?: Maybe<Scalars['String']>;
  statusReasonTo_ends_with?: Maybe<Scalars['String']>;
  statusReasonTo_not_ends_with?: Maybe<Scalars['String']>;
  statusReasonTo_i?: Maybe<Scalars['String']>;
  statusReasonTo_not_i?: Maybe<Scalars['String']>;
  statusReasonTo_contains_i?: Maybe<Scalars['String']>;
  statusReasonTo_not_contains_i?: Maybe<Scalars['String']>;
  statusReasonTo_starts_with_i?: Maybe<Scalars['String']>;
  statusReasonTo_not_starts_with_i?: Maybe<Scalars['String']>;
  statusReasonTo_ends_with_i?: Maybe<Scalars['String']>;
  statusReasonTo_not_ends_with_i?: Maybe<Scalars['String']>;
  statusReasonTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReasonTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadlineFrom?: Maybe<Scalars['String']>;
  deadlineFrom_not?: Maybe<Scalars['String']>;
  deadlineFrom_lt?: Maybe<Scalars['String']>;
  deadlineFrom_lte?: Maybe<Scalars['String']>;
  deadlineFrom_gt?: Maybe<Scalars['String']>;
  deadlineFrom_gte?: Maybe<Scalars['String']>;
  deadlineFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadlineFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadlineTo?: Maybe<Scalars['String']>;
  deadlineTo_not?: Maybe<Scalars['String']>;
  deadlineTo_lt?: Maybe<Scalars['String']>;
  deadlineTo_lte?: Maybe<Scalars['String']>;
  deadlineTo_gt?: Maybe<Scalars['String']>;
  deadlineTo_gte?: Maybe<Scalars['String']>;
  deadlineTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadlineTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientNameFrom?: Maybe<Scalars['String']>;
  clientNameFrom_not?: Maybe<Scalars['String']>;
  clientNameFrom_contains?: Maybe<Scalars['String']>;
  clientNameFrom_not_contains?: Maybe<Scalars['String']>;
  clientNameFrom_starts_with?: Maybe<Scalars['String']>;
  clientNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  clientNameFrom_ends_with?: Maybe<Scalars['String']>;
  clientNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  clientNameFrom_i?: Maybe<Scalars['String']>;
  clientNameFrom_not_i?: Maybe<Scalars['String']>;
  clientNameFrom_contains_i?: Maybe<Scalars['String']>;
  clientNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  clientNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  clientNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  clientNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  clientNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  clientNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientNameTo?: Maybe<Scalars['String']>;
  clientNameTo_not?: Maybe<Scalars['String']>;
  clientNameTo_contains?: Maybe<Scalars['String']>;
  clientNameTo_not_contains?: Maybe<Scalars['String']>;
  clientNameTo_starts_with?: Maybe<Scalars['String']>;
  clientNameTo_not_starts_with?: Maybe<Scalars['String']>;
  clientNameTo_ends_with?: Maybe<Scalars['String']>;
  clientNameTo_not_ends_with?: Maybe<Scalars['String']>;
  clientNameTo_i?: Maybe<Scalars['String']>;
  clientNameTo_not_i?: Maybe<Scalars['String']>;
  clientNameTo_contains_i?: Maybe<Scalars['String']>;
  clientNameTo_not_contains_i?: Maybe<Scalars['String']>;
  clientNameTo_starts_with_i?: Maybe<Scalars['String']>;
  clientNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  clientNameTo_ends_with_i?: Maybe<Scalars['String']>;
  clientNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  clientNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmailFrom?: Maybe<Scalars['String']>;
  clientEmailFrom_not?: Maybe<Scalars['String']>;
  clientEmailFrom_contains?: Maybe<Scalars['String']>;
  clientEmailFrom_not_contains?: Maybe<Scalars['String']>;
  clientEmailFrom_starts_with?: Maybe<Scalars['String']>;
  clientEmailFrom_not_starts_with?: Maybe<Scalars['String']>;
  clientEmailFrom_ends_with?: Maybe<Scalars['String']>;
  clientEmailFrom_not_ends_with?: Maybe<Scalars['String']>;
  clientEmailFrom_i?: Maybe<Scalars['String']>;
  clientEmailFrom_not_i?: Maybe<Scalars['String']>;
  clientEmailFrom_contains_i?: Maybe<Scalars['String']>;
  clientEmailFrom_not_contains_i?: Maybe<Scalars['String']>;
  clientEmailFrom_starts_with_i?: Maybe<Scalars['String']>;
  clientEmailFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  clientEmailFrom_ends_with_i?: Maybe<Scalars['String']>;
  clientEmailFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  clientEmailFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmailFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmailTo?: Maybe<Scalars['String']>;
  clientEmailTo_not?: Maybe<Scalars['String']>;
  clientEmailTo_contains?: Maybe<Scalars['String']>;
  clientEmailTo_not_contains?: Maybe<Scalars['String']>;
  clientEmailTo_starts_with?: Maybe<Scalars['String']>;
  clientEmailTo_not_starts_with?: Maybe<Scalars['String']>;
  clientEmailTo_ends_with?: Maybe<Scalars['String']>;
  clientEmailTo_not_ends_with?: Maybe<Scalars['String']>;
  clientEmailTo_i?: Maybe<Scalars['String']>;
  clientEmailTo_not_i?: Maybe<Scalars['String']>;
  clientEmailTo_contains_i?: Maybe<Scalars['String']>;
  clientEmailTo_not_contains_i?: Maybe<Scalars['String']>;
  clientEmailTo_starts_with_i?: Maybe<Scalars['String']>;
  clientEmailTo_not_starts_with_i?: Maybe<Scalars['String']>;
  clientEmailTo_ends_with_i?: Maybe<Scalars['String']>;
  clientEmailTo_not_ends_with_i?: Maybe<Scalars['String']>;
  clientEmailTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmailTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhoneFrom?: Maybe<Scalars['String']>;
  clientPhoneFrom_not?: Maybe<Scalars['String']>;
  clientPhoneFrom_contains?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_contains?: Maybe<Scalars['String']>;
  clientPhoneFrom_starts_with?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_starts_with?: Maybe<Scalars['String']>;
  clientPhoneFrom_ends_with?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_ends_with?: Maybe<Scalars['String']>;
  clientPhoneFrom_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_contains_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_contains_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_starts_with_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_ends_with_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhoneFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhoneFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhoneTo?: Maybe<Scalars['String']>;
  clientPhoneTo_not?: Maybe<Scalars['String']>;
  clientPhoneTo_contains?: Maybe<Scalars['String']>;
  clientPhoneTo_not_contains?: Maybe<Scalars['String']>;
  clientPhoneTo_starts_with?: Maybe<Scalars['String']>;
  clientPhoneTo_not_starts_with?: Maybe<Scalars['String']>;
  clientPhoneTo_ends_with?: Maybe<Scalars['String']>;
  clientPhoneTo_not_ends_with?: Maybe<Scalars['String']>;
  clientPhoneTo_i?: Maybe<Scalars['String']>;
  clientPhoneTo_not_i?: Maybe<Scalars['String']>;
  clientPhoneTo_contains_i?: Maybe<Scalars['String']>;
  clientPhoneTo_not_contains_i?: Maybe<Scalars['String']>;
  clientPhoneTo_starts_with_i?: Maybe<Scalars['String']>;
  clientPhoneTo_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhoneTo_ends_with_i?: Maybe<Scalars['String']>;
  clientPhoneTo_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhoneTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhoneTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsFrom?: Maybe<Scalars['String']>;
  detailsFrom_not?: Maybe<Scalars['String']>;
  detailsFrom_contains?: Maybe<Scalars['String']>;
  detailsFrom_not_contains?: Maybe<Scalars['String']>;
  detailsFrom_starts_with?: Maybe<Scalars['String']>;
  detailsFrom_not_starts_with?: Maybe<Scalars['String']>;
  detailsFrom_ends_with?: Maybe<Scalars['String']>;
  detailsFrom_not_ends_with?: Maybe<Scalars['String']>;
  detailsFrom_i?: Maybe<Scalars['String']>;
  detailsFrom_not_i?: Maybe<Scalars['String']>;
  detailsFrom_contains_i?: Maybe<Scalars['String']>;
  detailsFrom_not_contains_i?: Maybe<Scalars['String']>;
  detailsFrom_starts_with_i?: Maybe<Scalars['String']>;
  detailsFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  detailsFrom_ends_with_i?: Maybe<Scalars['String']>;
  detailsFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  detailsFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsTo?: Maybe<Scalars['String']>;
  detailsTo_not?: Maybe<Scalars['String']>;
  detailsTo_contains?: Maybe<Scalars['String']>;
  detailsTo_not_contains?: Maybe<Scalars['String']>;
  detailsTo_starts_with?: Maybe<Scalars['String']>;
  detailsTo_not_starts_with?: Maybe<Scalars['String']>;
  detailsTo_ends_with?: Maybe<Scalars['String']>;
  detailsTo_not_ends_with?: Maybe<Scalars['String']>;
  detailsTo_i?: Maybe<Scalars['String']>;
  detailsTo_not_i?: Maybe<Scalars['String']>;
  detailsTo_contains_i?: Maybe<Scalars['String']>;
  detailsTo_not_contains_i?: Maybe<Scalars['String']>;
  detailsTo_starts_with_i?: Maybe<Scalars['String']>;
  detailsTo_not_starts_with_i?: Maybe<Scalars['String']>;
  detailsTo_ends_with_i?: Maybe<Scalars['String']>;
  detailsTo_not_ends_with_i?: Maybe<Scalars['String']>;
  detailsTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  detailsTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPaidFrom?: Maybe<Scalars['Boolean']>;
  isPaidFrom_not?: Maybe<Scalars['Boolean']>;
  isPaidTo?: Maybe<Scalars['Boolean']>;
  isPaidTo_not?: Maybe<Scalars['Boolean']>;
  isPayableFrom?: Maybe<Scalars['Boolean']>;
  isPayableFrom_not?: Maybe<Scalars['Boolean']>;
  isPayableTo?: Maybe<Scalars['Boolean']>;
  isPayableTo_not?: Maybe<Scalars['Boolean']>;
  isEmergencyFrom?: Maybe<Scalars['Boolean']>;
  isEmergencyFrom_not?: Maybe<Scalars['Boolean']>;
  isEmergencyTo?: Maybe<Scalars['Boolean']>;
  isEmergencyTo_not?: Maybe<Scalars['Boolean']>;
  isWarrantyFrom?: Maybe<Scalars['Boolean']>;
  isWarrantyFrom_not?: Maybe<Scalars['Boolean']>;
  isWarrantyTo?: Maybe<Scalars['Boolean']>;
  isWarrantyTo_not?: Maybe<Scalars['Boolean']>;
  isResidentTicketFrom?: Maybe<Scalars['Boolean']>;
  isResidentTicketFrom_not?: Maybe<Scalars['Boolean']>;
  isResidentTicketTo?: Maybe<Scalars['Boolean']>;
  isResidentTicketTo_not?: Maybe<Scalars['Boolean']>;
  canReadByResidentFrom?: Maybe<Scalars['Boolean']>;
  canReadByResidentFrom_not?: Maybe<Scalars['Boolean']>;
  canReadByResidentTo?: Maybe<Scalars['Boolean']>;
  canReadByResidentTo_not?: Maybe<Scalars['Boolean']>;
  metaFrom?: Maybe<Scalars['JSON']>;
  metaFrom_not?: Maybe<Scalars['JSON']>;
  metaFrom_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  metaFrom_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  metaTo?: Maybe<Scalars['JSON']>;
  metaTo_not?: Maybe<Scalars['JSON']>;
  metaTo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  metaTo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressFrom?: Maybe<Scalars['String']>;
  propertyAddressFrom_not?: Maybe<Scalars['String']>;
  propertyAddressFrom_contains?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_contains?: Maybe<Scalars['String']>;
  propertyAddressFrom_starts_with?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_starts_with?: Maybe<Scalars['String']>;
  propertyAddressFrom_ends_with?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_ends_with?: Maybe<Scalars['String']>;
  propertyAddressFrom_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_contains_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_contains_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddressFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressTo?: Maybe<Scalars['String']>;
  propertyAddressTo_not?: Maybe<Scalars['String']>;
  propertyAddressTo_contains?: Maybe<Scalars['String']>;
  propertyAddressTo_not_contains?: Maybe<Scalars['String']>;
  propertyAddressTo_starts_with?: Maybe<Scalars['String']>;
  propertyAddressTo_not_starts_with?: Maybe<Scalars['String']>;
  propertyAddressTo_ends_with?: Maybe<Scalars['String']>;
  propertyAddressTo_not_ends_with?: Maybe<Scalars['String']>;
  propertyAddressTo_i?: Maybe<Scalars['String']>;
  propertyAddressTo_not_i?: Maybe<Scalars['String']>;
  propertyAddressTo_contains_i?: Maybe<Scalars['String']>;
  propertyAddressTo_not_contains_i?: Maybe<Scalars['String']>;
  propertyAddressTo_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddressTo_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddressTo_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddressTo_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddressTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressMetaFrom?: Maybe<Scalars['JSON']>;
  propertyAddressMetaFrom_not?: Maybe<Scalars['JSON']>;
  propertyAddressMetaFrom_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMetaFrom_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMetaTo?: Maybe<Scalars['JSON']>;
  propertyAddressMetaTo_not?: Maybe<Scalars['JSON']>;
  propertyAddressMetaTo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMetaTo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sectionNameFrom?: Maybe<Scalars['String']>;
  sectionNameFrom_not?: Maybe<Scalars['String']>;
  sectionNameFrom_contains?: Maybe<Scalars['String']>;
  sectionNameFrom_not_contains?: Maybe<Scalars['String']>;
  sectionNameFrom_starts_with?: Maybe<Scalars['String']>;
  sectionNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  sectionNameFrom_ends_with?: Maybe<Scalars['String']>;
  sectionNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  sectionNameFrom_i?: Maybe<Scalars['String']>;
  sectionNameFrom_not_i?: Maybe<Scalars['String']>;
  sectionNameFrom_contains_i?: Maybe<Scalars['String']>;
  sectionNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  sectionNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  sectionNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  sectionNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  sectionNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  sectionNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionNameTo?: Maybe<Scalars['String']>;
  sectionNameTo_not?: Maybe<Scalars['String']>;
  sectionNameTo_contains?: Maybe<Scalars['String']>;
  sectionNameTo_not_contains?: Maybe<Scalars['String']>;
  sectionNameTo_starts_with?: Maybe<Scalars['String']>;
  sectionNameTo_not_starts_with?: Maybe<Scalars['String']>;
  sectionNameTo_ends_with?: Maybe<Scalars['String']>;
  sectionNameTo_not_ends_with?: Maybe<Scalars['String']>;
  sectionNameTo_i?: Maybe<Scalars['String']>;
  sectionNameTo_not_i?: Maybe<Scalars['String']>;
  sectionNameTo_contains_i?: Maybe<Scalars['String']>;
  sectionNameTo_not_contains_i?: Maybe<Scalars['String']>;
  sectionNameTo_starts_with_i?: Maybe<Scalars['String']>;
  sectionNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  sectionNameTo_ends_with_i?: Maybe<Scalars['String']>;
  sectionNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  sectionNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionTypeFrom?: Maybe<TicketChangeSectionTypeFromType>;
  sectionTypeFrom_not?: Maybe<TicketChangeSectionTypeFromType>;
  sectionTypeFrom_in?: Maybe<Array<Maybe<TicketChangeSectionTypeFromType>>>;
  sectionTypeFrom_not_in?: Maybe<Array<Maybe<TicketChangeSectionTypeFromType>>>;
  sectionTypeTo?: Maybe<TicketChangeSectionTypeToType>;
  sectionTypeTo_not?: Maybe<TicketChangeSectionTypeToType>;
  sectionTypeTo_in?: Maybe<Array<Maybe<TicketChangeSectionTypeToType>>>;
  sectionTypeTo_not_in?: Maybe<Array<Maybe<TicketChangeSectionTypeToType>>>;
  floorNameFrom?: Maybe<Scalars['String']>;
  floorNameFrom_not?: Maybe<Scalars['String']>;
  floorNameFrom_contains?: Maybe<Scalars['String']>;
  floorNameFrom_not_contains?: Maybe<Scalars['String']>;
  floorNameFrom_starts_with?: Maybe<Scalars['String']>;
  floorNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  floorNameFrom_ends_with?: Maybe<Scalars['String']>;
  floorNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  floorNameFrom_i?: Maybe<Scalars['String']>;
  floorNameFrom_not_i?: Maybe<Scalars['String']>;
  floorNameFrom_contains_i?: Maybe<Scalars['String']>;
  floorNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  floorNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  floorNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  floorNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  floorNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  floorNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorNameTo?: Maybe<Scalars['String']>;
  floorNameTo_not?: Maybe<Scalars['String']>;
  floorNameTo_contains?: Maybe<Scalars['String']>;
  floorNameTo_not_contains?: Maybe<Scalars['String']>;
  floorNameTo_starts_with?: Maybe<Scalars['String']>;
  floorNameTo_not_starts_with?: Maybe<Scalars['String']>;
  floorNameTo_ends_with?: Maybe<Scalars['String']>;
  floorNameTo_not_ends_with?: Maybe<Scalars['String']>;
  floorNameTo_i?: Maybe<Scalars['String']>;
  floorNameTo_not_i?: Maybe<Scalars['String']>;
  floorNameTo_contains_i?: Maybe<Scalars['String']>;
  floorNameTo_not_contains_i?: Maybe<Scalars['String']>;
  floorNameTo_starts_with_i?: Maybe<Scalars['String']>;
  floorNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  floorNameTo_ends_with_i?: Maybe<Scalars['String']>;
  floorNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  floorNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitNameFrom?: Maybe<Scalars['String']>;
  unitNameFrom_not?: Maybe<Scalars['String']>;
  unitNameFrom_contains?: Maybe<Scalars['String']>;
  unitNameFrom_not_contains?: Maybe<Scalars['String']>;
  unitNameFrom_starts_with?: Maybe<Scalars['String']>;
  unitNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  unitNameFrom_ends_with?: Maybe<Scalars['String']>;
  unitNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  unitNameFrom_i?: Maybe<Scalars['String']>;
  unitNameFrom_not_i?: Maybe<Scalars['String']>;
  unitNameFrom_contains_i?: Maybe<Scalars['String']>;
  unitNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  unitNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  unitNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  unitNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  unitNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  unitNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitNameTo?: Maybe<Scalars['String']>;
  unitNameTo_not?: Maybe<Scalars['String']>;
  unitNameTo_contains?: Maybe<Scalars['String']>;
  unitNameTo_not_contains?: Maybe<Scalars['String']>;
  unitNameTo_starts_with?: Maybe<Scalars['String']>;
  unitNameTo_not_starts_with?: Maybe<Scalars['String']>;
  unitNameTo_ends_with?: Maybe<Scalars['String']>;
  unitNameTo_not_ends_with?: Maybe<Scalars['String']>;
  unitNameTo_i?: Maybe<Scalars['String']>;
  unitNameTo_not_i?: Maybe<Scalars['String']>;
  unitNameTo_contains_i?: Maybe<Scalars['String']>;
  unitNameTo_not_contains_i?: Maybe<Scalars['String']>;
  unitNameTo_starts_with_i?: Maybe<Scalars['String']>;
  unitNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  unitNameTo_ends_with_i?: Maybe<Scalars['String']>;
  unitNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  unitNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitTypeFrom?: Maybe<TicketChangeUnitTypeFromType>;
  unitTypeFrom_not?: Maybe<TicketChangeUnitTypeFromType>;
  unitTypeFrom_in?: Maybe<Array<Maybe<TicketChangeUnitTypeFromType>>>;
  unitTypeFrom_not_in?: Maybe<Array<Maybe<TicketChangeUnitTypeFromType>>>;
  unitTypeTo?: Maybe<TicketChangeUnitTypeToType>;
  unitTypeTo_not?: Maybe<TicketChangeUnitTypeToType>;
  unitTypeTo_in?: Maybe<Array<Maybe<TicketChangeUnitTypeToType>>>;
  unitTypeTo_not_in?: Maybe<Array<Maybe<TicketChangeUnitTypeToType>>>;
  sourceMetaFrom?: Maybe<Scalars['JSON']>;
  sourceMetaFrom_not?: Maybe<Scalars['JSON']>;
  sourceMetaFrom_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sourceMetaFrom_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sourceMetaTo?: Maybe<Scalars['JSON']>;
  sourceMetaTo_not?: Maybe<Scalars['JSON']>;
  sourceMetaTo_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sourceMetaTo_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  deferredUntilFrom?: Maybe<Scalars['String']>;
  deferredUntilFrom_not?: Maybe<Scalars['String']>;
  deferredUntilFrom_lt?: Maybe<Scalars['String']>;
  deferredUntilFrom_lte?: Maybe<Scalars['String']>;
  deferredUntilFrom_gt?: Maybe<Scalars['String']>;
  deferredUntilFrom_gte?: Maybe<Scalars['String']>;
  deferredUntilFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deferredUntilFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deferredUntilTo?: Maybe<Scalars['String']>;
  deferredUntilTo_not?: Maybe<Scalars['String']>;
  deferredUntilTo_lt?: Maybe<Scalars['String']>;
  deferredUntilTo_lte?: Maybe<Scalars['String']>;
  deferredUntilTo_gt?: Maybe<Scalars['String']>;
  deferredUntilTo_gte?: Maybe<Scalars['String']>;
  deferredUntilTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deferredUntilTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationIdFrom?: Maybe<Scalars['ID']>;
  organizationIdFrom_not?: Maybe<Scalars['ID']>;
  organizationIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationIdTo?: Maybe<Scalars['ID']>;
  organizationIdTo_not?: Maybe<Scalars['ID']>;
  organizationIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationDisplayNameFrom?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationDisplayNameTo?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  organizationDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organizationDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusIdFrom?: Maybe<Scalars['ID']>;
  statusIdFrom_not?: Maybe<Scalars['ID']>;
  statusIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  statusIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  statusIdTo?: Maybe<Scalars['ID']>;
  statusIdTo_not?: Maybe<Scalars['ID']>;
  statusIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  statusIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  statusDisplayNameFrom?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusDisplayNameTo?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not?: Maybe<Scalars['String']>;
  statusDisplayNameTo_contains?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  statusDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  statusDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  statusDisplayNameTo_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  statusDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientIdFrom?: Maybe<Scalars['ID']>;
  clientIdFrom_not?: Maybe<Scalars['ID']>;
  clientIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdTo?: Maybe<Scalars['ID']>;
  clientIdTo_not?: Maybe<Scalars['ID']>;
  clientIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  clientDisplayNameFrom?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientDisplayNameTo?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not?: Maybe<Scalars['String']>;
  clientDisplayNameTo_contains?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  clientDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  clientDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  clientDisplayNameTo_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  clientDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactIdFrom?: Maybe<Scalars['ID']>;
  contactIdFrom_not?: Maybe<Scalars['ID']>;
  contactIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contactIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contactIdTo?: Maybe<Scalars['ID']>;
  contactIdTo_not?: Maybe<Scalars['ID']>;
  contactIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contactIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  contactDisplayNameFrom?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactDisplayNameTo?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not?: Maybe<Scalars['String']>;
  contactDisplayNameTo_contains?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  contactDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  contactDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  contactDisplayNameTo_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  contactDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assigneeIdFrom?: Maybe<Scalars['ID']>;
  assigneeIdFrom_not?: Maybe<Scalars['ID']>;
  assigneeIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assigneeIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assigneeIdTo?: Maybe<Scalars['ID']>;
  assigneeIdTo_not?: Maybe<Scalars['ID']>;
  assigneeIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assigneeIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  assigneeDisplayNameFrom?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assigneeDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assigneeDisplayNameTo?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_contains?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  assigneeDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assigneeDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executorIdFrom?: Maybe<Scalars['ID']>;
  executorIdFrom_not?: Maybe<Scalars['ID']>;
  executorIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  executorIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  executorIdTo?: Maybe<Scalars['ID']>;
  executorIdTo_not?: Maybe<Scalars['ID']>;
  executorIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  executorIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  executorDisplayNameFrom?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executorDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executorDisplayNameTo?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not?: Maybe<Scalars['String']>;
  executorDisplayNameTo_contains?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  executorDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  executorDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  executorDisplayNameTo_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  executorDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executorDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifierIdFrom?: Maybe<Scalars['ID']>;
  classifierIdFrom_not?: Maybe<Scalars['ID']>;
  classifierIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classifierIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classifierIdTo?: Maybe<Scalars['ID']>;
  classifierIdTo_not?: Maybe<Scalars['ID']>;
  classifierIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classifierIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  classifierDisplayNameFrom?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifierDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifierDisplayNameTo?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_contains?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  classifierDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifierDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedIdFrom?: Maybe<Scalars['ID']>;
  relatedIdFrom_not?: Maybe<Scalars['ID']>;
  relatedIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  relatedIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  relatedIdTo?: Maybe<Scalars['ID']>;
  relatedIdTo_not?: Maybe<Scalars['ID']>;
  relatedIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  relatedIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  relatedDisplayNameFrom?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedDisplayNameTo?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_contains?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  relatedDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyIdFrom?: Maybe<Scalars['ID']>;
  propertyIdFrom_not?: Maybe<Scalars['ID']>;
  propertyIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  propertyIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  propertyIdTo?: Maybe<Scalars['ID']>;
  propertyIdTo_not?: Maybe<Scalars['ID']>;
  propertyIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  propertyIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  propertyDisplayNameFrom?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyDisplayNameTo?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_contains?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceIdFrom?: Maybe<Scalars['ID']>;
  sourceIdFrom_not?: Maybe<Scalars['ID']>;
  sourceIdFrom_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sourceIdFrom_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sourceIdTo?: Maybe<Scalars['ID']>;
  sourceIdTo_not?: Maybe<Scalars['ID']>;
  sourceIdTo_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sourceIdTo_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  sourceDisplayNameFrom?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_contains?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_contains?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_starts_with?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_starts_with?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_ends_with?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_ends_with?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_contains_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_contains_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_starts_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_starts_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_ends_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_not_ends_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameFrom_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceDisplayNameFrom_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceDisplayNameTo?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_contains?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_contains?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_starts_with?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_starts_with?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_ends_with?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_ends_with?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_contains_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_contains_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_starts_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_starts_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_ends_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_not_ends_with_i?: Maybe<Scalars['String']>;
  sourceDisplayNameTo_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceDisplayNameTo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  actualCreationDate?: Maybe<Scalars['String']>;
  actualCreationDate_not?: Maybe<Scalars['String']>;
  actualCreationDate_lt?: Maybe<Scalars['String']>;
  actualCreationDate_lte?: Maybe<Scalars['String']>;
  actualCreationDate_gt?: Maybe<Scalars['String']>;
  actualCreationDate_gte?: Maybe<Scalars['String']>;
  actualCreationDate_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  actualCreationDate_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketChangeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketChangesCreateInput = {
  data?: Maybe<TicketChangeCreateInput>;
};

export type TicketChangesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketChangeUpdateInput>;
};

/**  Rules for all possible valid combinations of classifiers  */
export type TicketClassifier = {
  __typename?: 'TicketClassifier';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketClassifier List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  Location of incident  */
  place?: Maybe<TicketPlaceClassifier>;
  /**  Type of work to fix incident  */
  category?: Maybe<TicketCategoryClassifier>;
  /**  What needs to be done  */
  problem?: Maybe<TicketProblemClassifier>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketClassifierCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  place?: Maybe<TicketPlaceClassifierRelateToOneInput>;
  category?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  problem?: Maybe<TicketProblemClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketClassifierHistoryRecord = {
  __typename?: 'TicketClassifierHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketClassifierHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketClassifierHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketClassifierHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketClassifierHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  problem?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketClassifierHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketClassifierHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketClassifierHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  place?: Maybe<Scalars['String']>;
  place_not?: Maybe<Scalars['String']>;
  place_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  place_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  category_not?: Maybe<Scalars['String']>;
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  problem?: Maybe<Scalars['String']>;
  problem_not?: Maybe<Scalars['String']>;
  problem_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  problem_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketClassifierHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketClassifierHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketClassifierHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketClassifierHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketClassifierHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketClassifierHistoryRecordsCreateInput = {
  data?: Maybe<TicketClassifierHistoryRecordCreateInput>;
};

export type TicketClassifierHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketClassifierHistoryRecordUpdateInput>;
};

export type TicketClassifierRelateToOneInput = {
  create?: Maybe<TicketClassifierCreateInput>;
  connect?: Maybe<TicketClassifierWhereUniqueInput>;
  disconnect?: Maybe<TicketClassifierWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type TicketClassifierUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  place?: Maybe<TicketPlaceClassifierRelateToOneInput>;
  category?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  problem?: Maybe<TicketProblemClassifierRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketClassifierWhereInput = {
  AND?: Maybe<Array<Maybe<TicketClassifierWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketClassifierWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  place?: Maybe<TicketPlaceClassifierWhereInput>;
  place_is_null?: Maybe<Scalars['Boolean']>;
  category?: Maybe<TicketCategoryClassifierWhereInput>;
  category_is_null?: Maybe<Scalars['Boolean']>;
  problem?: Maybe<TicketProblemClassifierWhereInput>;
  problem_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketClassifierWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketClassifiersCreateInput = {
  data?: Maybe<TicketClassifierCreateInput>;
};

export type TicketClassifiersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketClassifierUpdateInput>;
};

/**  Textual comment for tickets  */
export type TicketComment = {
  __typename?: 'TicketComment';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketComment List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketComment List config, or
   *  3. As an alias to a 'name' field on the TicketComment List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketComment List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Comment type (internal for an organization or with a resident)  */
  type?: Maybe<TicketCommentTypeType>;
  /**  Related ticket of the comment  */
  ticket?: Maybe<Ticket>;
  /**  User, who created the comment  */
  user?: Maybe<User>;
  /**  Plain text content  */
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketCommentCreateInput = {
  type?: Maybe<TicketCommentTypeType>;
  ticket?: Maybe<TicketRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  File attached to the ticket comment  */
export type TicketCommentFile = {
  __typename?: 'TicketCommentFile';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCommentFile List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCommentFile List config, or
   *  3. As an alias to a 'name' field on the TicketCommentFile List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCommentFile List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  File object with meta information and publicUrl  */
  file?: Maybe<File>;
  /**  Link to ticket  */
  ticket?: Maybe<Ticket>;
  /**  Link to ticket comment  */
  ticketComment?: Maybe<TicketComment>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketCommentFileCreateInput = {
  file?: Maybe<Scalars['Upload']>;
  ticket?: Maybe<TicketRelateToOneInput>;
  ticketComment?: Maybe<TicketCommentRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketCommentFileHistoryRecord = {
  __typename?: 'TicketCommentFileHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCommentFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCommentFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketCommentFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCommentFileHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  ticketComment?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCommentFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketCommentFileHistoryRecordCreateInput = {
  file?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  ticketComment?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCommentFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketCommentFileHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketCommentFileHistoryRecordUpdateInput = {
  file?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  ticketComment?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCommentFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketCommentFileHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketCommentFileHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketCommentFileHistoryRecordWhereInput>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticketComment?: Maybe<Scalars['String']>;
  ticketComment_not?: Maybe<Scalars['String']>;
  ticketComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticketComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketCommentFileHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketCommentFileHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketCommentFileHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketCommentFileHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketCommentFileHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketCommentFileHistoryRecordsCreateInput = {
  data?: Maybe<TicketCommentFileHistoryRecordCreateInput>;
};

export type TicketCommentFileHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentFileHistoryRecordUpdateInput>;
};

export type TicketCommentFileUpdateInput = {
  file?: Maybe<Scalars['Upload']>;
  ticket?: Maybe<TicketRelateToOneInput>;
  ticketComment?: Maybe<TicketCommentRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketCommentFileWhereInput = {
  AND?: Maybe<Array<Maybe<TicketCommentFileWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketCommentFileWhereInput>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  ticketComment?: Maybe<TicketCommentWhereInput>;
  ticketComment_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketCommentFileWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketCommentFilesCreateInput = {
  data?: Maybe<TicketCommentFileCreateInput>;
};

export type TicketCommentFilesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentFileUpdateInput>;
};

/**  A keystone list  */
export type TicketCommentHistoryRecord = {
  __typename?: 'TicketCommentHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketCommentHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketCommentHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketCommentHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketCommentHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCommentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketCommentHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCommentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketCommentHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketCommentHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketCommentHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketCommentHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketCommentHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketCommentHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  content_i?: Maybe<Scalars['String']>;
  content_not_i?: Maybe<Scalars['String']>;
  content_contains_i?: Maybe<Scalars['String']>;
  content_not_contains_i?: Maybe<Scalars['String']>;
  content_starts_with_i?: Maybe<Scalars['String']>;
  content_not_starts_with_i?: Maybe<Scalars['String']>;
  content_ends_with_i?: Maybe<Scalars['String']>;
  content_not_ends_with_i?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketCommentHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketCommentHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketCommentHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketCommentHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketCommentHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketCommentHistoryRecordsCreateInput = {
  data?: Maybe<TicketCommentHistoryRecordCreateInput>;
};

export type TicketCommentHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentHistoryRecordUpdateInput>;
};

export type TicketCommentRelateToOneInput = {
  create?: Maybe<TicketCommentCreateInput>;
  connect?: Maybe<TicketCommentWhereUniqueInput>;
  disconnect?: Maybe<TicketCommentWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum TicketCommentTypeType {
  Organization = 'organization',
  Resident = 'resident'
}

export type TicketCommentUpdateInput = {
  type?: Maybe<TicketCommentTypeType>;
  ticket?: Maybe<TicketRelateToOneInput>;
  user?: Maybe<UserRelateToOneInput>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketCommentWhereInput = {
  AND?: Maybe<Array<Maybe<TicketCommentWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketCommentWhereInput>>>;
  type?: Maybe<TicketCommentTypeType>;
  type_not?: Maybe<TicketCommentTypeType>;
  type_in?: Maybe<Array<Maybe<TicketCommentTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<TicketCommentTypeType>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  content_i?: Maybe<Scalars['String']>;
  content_not_i?: Maybe<Scalars['String']>;
  content_contains_i?: Maybe<Scalars['String']>;
  content_not_contains_i?: Maybe<Scalars['String']>;
  content_starts_with_i?: Maybe<Scalars['String']>;
  content_not_starts_with_i?: Maybe<Scalars['String']>;
  content_ends_with_i?: Maybe<Scalars['String']>;
  content_not_ends_with_i?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketCommentWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketCommentsCreateInput = {
  data?: Maybe<TicketCommentCreateInput>;
};

export type TicketCommentsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketCommentUpdateInput>;
};

export type TicketCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  reviewValue?: Maybe<TicketReviewValueType>;
  reviewComment?: Maybe<Scalars['String']>;
  feedbackValue?: Maybe<TicketFeedbackValueType>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackAdditionalOptions?: Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlValue?: Maybe<TicketQualityControlValueType>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptions?: Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy?: Maybe<UserRelateToOneInput>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  status?: Maybe<TicketStatusRelateToOneInput>;
  deadline?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  client?: Maybe<UserRelateToOneInput>;
  contact?: Maybe<ContactRelateToOneInput>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  assignee?: Maybe<UserRelateToOneInput>;
  executor?: Maybe<UserRelateToOneInput>;
  categoryClassifier?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  placeClassifier?: Maybe<TicketPlaceClassifierRelateToOneInput>;
  problemClassifier?: Maybe<TicketProblemClassifierRelateToOneInput>;
  classifier?: Maybe<TicketClassifierRelateToOneInput>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  related?: Maybe<TicketRelateToOneInput>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  property?: Maybe<PropertyRelateToOneInput>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  sectionName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<TicketSectionTypeType>;
  floorName?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<TicketUnitTypeType>;
  source?: Maybe<TicketSourceRelateToOneInput>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  deferredUntil?: Maybe<Scalars['String']>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  Information about generation process of ticket document  */
export type TicketDocumentGenerationTask = {
  __typename?: 'TicketDocumentGenerationTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketDocumentGenerationTask List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketDocumentGenerationTask List config, or
   *  3. As an alias to a 'name' field on the TicketDocumentGenerationTask List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketDocumentGenerationTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The application for which the document is generated  */
  ticket?: Maybe<Ticket>;
  /**  Status of current generation operation  */
  status?: Maybe<TicketDocumentGenerationTaskStatusType>;
  /**  Output file format  */
  format?: Maybe<TicketDocumentGenerationTaskFormatType>;
  /**  Progress of current generation operation  */
  progress?: Maybe<Scalars['Int']>;
  /**  User that requested this generation operation. Will be used for read access checks to display all generating tasks somewhere and to display progress indicator of ongoing generating task for current user  */
  user?: Maybe<User>;
  /**  All datetime fields in export template will be converted to specified tz  */
  timeZone?: Maybe<Scalars['String']>;
  /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  file?: Maybe<File>;
  /**  Type of document  */
  documentType?: Maybe<TicketDocumentGenerationTaskDocumentTypeType>;
  /**  Structured untyped metadata, can be used to store errors or anything else  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketDocumentGenerationTaskCreateInput = {
  ticket?: Maybe<TicketRelateToOneInput>;
  status?: Maybe<TicketDocumentGenerationTaskStatusType>;
  format?: Maybe<TicketDocumentGenerationTaskFormatType>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<UserRelateToOneInput>;
  timeZone?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  documentType?: Maybe<TicketDocumentGenerationTaskDocumentTypeType>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum TicketDocumentGenerationTaskDocumentTypeType {
  CompletionWorks = 'completionWorks'
}

export enum TicketDocumentGenerationTaskFormatType {
  Docx = 'docx'
}

/**  A keystone list  */
export type TicketDocumentGenerationTaskHistoryRecord = {
  __typename?: 'TicketDocumentGenerationTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketDocumentGenerationTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketDocumentGenerationTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketDocumentGenerationTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketDocumentGenerationTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  documentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketDocumentGenerationTaskHistoryRecordCreateInput = {
  ticket?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  documentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketDocumentGenerationTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketDocumentGenerationTaskHistoryRecordUpdateInput = {
  ticket?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  documentType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketDocumentGenerationTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecordWhereInput>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  format_i?: Maybe<Scalars['String']>;
  format_not_i?: Maybe<Scalars['String']>;
  format_contains_i?: Maybe<Scalars['String']>;
  format_not_contains_i?: Maybe<Scalars['String']>;
  format_starts_with_i?: Maybe<Scalars['String']>;
  format_not_starts_with_i?: Maybe<Scalars['String']>;
  format_ends_with_i?: Maybe<Scalars['String']>;
  format_not_ends_with_i?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  progress?: Maybe<Scalars['Int']>;
  progress_not?: Maybe<Scalars['Int']>;
  progress_lt?: Maybe<Scalars['Int']>;
  progress_lte?: Maybe<Scalars['Int']>;
  progress_gt?: Maybe<Scalars['Int']>;
  progress_gte?: Maybe<Scalars['Int']>;
  progress_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  progress_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  documentType?: Maybe<Scalars['String']>;
  documentType_not?: Maybe<Scalars['String']>;
  documentType_contains?: Maybe<Scalars['String']>;
  documentType_not_contains?: Maybe<Scalars['String']>;
  documentType_starts_with?: Maybe<Scalars['String']>;
  documentType_not_starts_with?: Maybe<Scalars['String']>;
  documentType_ends_with?: Maybe<Scalars['String']>;
  documentType_not_ends_with?: Maybe<Scalars['String']>;
  documentType_i?: Maybe<Scalars['String']>;
  documentType_not_i?: Maybe<Scalars['String']>;
  documentType_contains_i?: Maybe<Scalars['String']>;
  documentType_not_contains_i?: Maybe<Scalars['String']>;
  documentType_starts_with_i?: Maybe<Scalars['String']>;
  documentType_not_starts_with_i?: Maybe<Scalars['String']>;
  documentType_ends_with_i?: Maybe<Scalars['String']>;
  documentType_not_ends_with_i?: Maybe<Scalars['String']>;
  documentType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketDocumentGenerationTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketDocumentGenerationTaskHistoryRecordsCreateInput = {
  data?: Maybe<TicketDocumentGenerationTaskHistoryRecordCreateInput>;
};

export type TicketDocumentGenerationTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketDocumentGenerationTaskHistoryRecordUpdateInput>;
};

export enum TicketDocumentGenerationTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type TicketDocumentGenerationTaskUpdateInput = {
  ticket?: Maybe<TicketRelateToOneInput>;
  status?: Maybe<TicketDocumentGenerationTaskStatusType>;
  format?: Maybe<TicketDocumentGenerationTaskFormatType>;
  progress?: Maybe<Scalars['Int']>;
  user?: Maybe<UserRelateToOneInput>;
  timeZone?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  documentType?: Maybe<TicketDocumentGenerationTaskDocumentTypeType>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketDocumentGenerationTaskWhereInput = {
  AND?: Maybe<Array<Maybe<TicketDocumentGenerationTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketDocumentGenerationTaskWhereInput>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  status?: Maybe<TicketDocumentGenerationTaskStatusType>;
  status_not?: Maybe<TicketDocumentGenerationTaskStatusType>;
  status_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskStatusType>>>;
  format?: Maybe<TicketDocumentGenerationTaskFormatType>;
  format_not?: Maybe<TicketDocumentGenerationTaskFormatType>;
  format_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskFormatType>>>;
  format_not_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskFormatType>>>;
  progress?: Maybe<Scalars['Int']>;
  progress_not?: Maybe<Scalars['Int']>;
  progress_lt?: Maybe<Scalars['Int']>;
  progress_lte?: Maybe<Scalars['Int']>;
  progress_gt?: Maybe<Scalars['Int']>;
  progress_gte?: Maybe<Scalars['Int']>;
  progress_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  progress_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  documentType?: Maybe<TicketDocumentGenerationTaskDocumentTypeType>;
  documentType_not?: Maybe<TicketDocumentGenerationTaskDocumentTypeType>;
  documentType_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskDocumentTypeType>>>;
  documentType_not_in?: Maybe<Array<Maybe<TicketDocumentGenerationTaskDocumentTypeType>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketDocumentGenerationTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketDocumentGenerationTasksCreateInput = {
  data?: Maybe<TicketDocumentGenerationTaskCreateInput>;
};

export type TicketDocumentGenerationTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketDocumentGenerationTaskUpdateInput>;
};

export type TicketExportOptions = {
  __typename?: 'TicketExportOptions';
  commentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  haveAllComments?: Maybe<Scalars['Boolean']>;
  haveListCompletedWorks?: Maybe<Scalars['Boolean']>;
  haveConsumedMaterials?: Maybe<Scalars['Boolean']>;
  haveTotalCostWork?: Maybe<Scalars['Boolean']>;
};

export type TicketExportOptionsInput = {
  commentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  haveAllComments?: Maybe<Scalars['Boolean']>;
  haveListCompletedWorks?: Maybe<Scalars['Boolean']>;
  haveConsumedMaterials?: Maybe<Scalars['Boolean']>;
  haveTotalCostWork?: Maybe<Scalars['Boolean']>;
};

/**  Stores requested export format, status of export job, link to exported file and information about progress of export job  */
export type TicketExportTask = {
  __typename?: 'TicketExportTask';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketExportTask List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketExportTask List config, or
   *  3. As an alias to a 'name' field on the TicketExportTask List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketExportTask List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Status of export job  */
  status?: Maybe<TicketExportTaskStatusType>;
  /**  Requested export file format  */
  format?: Maybe<TicketExportTaskFormatType>;
  /**  How many records at the moment are exported  */
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  /**  Total records to export that will be determined at server side in export operation  */
  totalRecordsCount?: Maybe<Scalars['Int']>;
  /**  Meta information about file, saved outside of database somewhere. Shape of meta information JSON object is specific to file adapter, used by saving a file.  */
  file?: Maybe<File>;
  /**  Stores information about query and ids of exported and failed records  */
  meta?: Maybe<Scalars['JSON']>;
  /**  Filtering conditions for records to export  */
  where?: Maybe<Scalars['JSON']>;
  /**  Sorting parameters for records to export  */
  sortBy?: Maybe<Scalars['JSON']>;
  /**  Requested export locale, in that the resulting file will be rendered  */
  locale?: Maybe<Scalars['String']>;
  /**  To requested timeZone all datetime fields will be converted  */
  timeZone?: Maybe<Scalars['String']>;
  /**  User that requested this exporting operation. Will be used for read access checks to display all exported tasks somewhere and to display progress indicator of ongoing exporting task for current user  */
  user?: Maybe<User>;
  /**  Options for exporting tickets into PDF format  */
  options?: Maybe<TicketExportOptions>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketExportTaskCreateInput = {
  status?: Maybe<TicketExportTaskStatusType>;
  format?: Maybe<TicketExportTaskFormatType>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  options?: Maybe<TicketExportOptionsInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export enum TicketExportTaskFormatType {
  Excel = 'excel',
  Pdf = 'pdf'
}

/**  A keystone list  */
export type TicketExportTaskHistoryRecord = {
  __typename?: 'TicketExportTaskHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketExportTaskHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketExportTaskHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketExportTaskHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketExportTaskHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketExportTaskHistoryRecordCreateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketExportTaskHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketExportTaskHistoryRecordUpdateInput = {
  status?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketExportTaskHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketExportTaskHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketExportTaskHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketExportTaskHistoryRecordWhereInput>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format?: Maybe<Scalars['String']>;
  format_not?: Maybe<Scalars['String']>;
  format_contains?: Maybe<Scalars['String']>;
  format_not_contains?: Maybe<Scalars['String']>;
  format_starts_with?: Maybe<Scalars['String']>;
  format_not_starts_with?: Maybe<Scalars['String']>;
  format_ends_with?: Maybe<Scalars['String']>;
  format_not_ends_with?: Maybe<Scalars['String']>;
  format_i?: Maybe<Scalars['String']>;
  format_not_i?: Maybe<Scalars['String']>;
  format_contains_i?: Maybe<Scalars['String']>;
  format_not_contains_i?: Maybe<Scalars['String']>;
  format_starts_with_i?: Maybe<Scalars['String']>;
  format_not_starts_with_i?: Maybe<Scalars['String']>;
  format_ends_with_i?: Maybe<Scalars['String']>;
  format_not_ends_with_i?: Maybe<Scalars['String']>;
  format_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  format_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  exportedRecordsCount_not?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  exportedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where?: Maybe<Scalars['JSON']>;
  where_not?: Maybe<Scalars['JSON']>;
  where_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy?: Maybe<Scalars['JSON']>;
  sortBy_not?: Maybe<Scalars['JSON']>;
  sortBy_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  options?: Maybe<Scalars['JSON']>;
  options_not?: Maybe<Scalars['JSON']>;
  options_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  options_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketExportTaskHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketExportTaskHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketExportTaskHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketExportTaskHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketExportTaskHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketExportTaskHistoryRecordsCreateInput = {
  data?: Maybe<TicketExportTaskHistoryRecordCreateInput>;
};

export type TicketExportTaskHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketExportTaskHistoryRecordUpdateInput>;
};

export enum TicketExportTaskStatusType {
  Processing = 'processing',
  Completed = 'completed',
  Error = 'error',
  Cancelled = 'cancelled'
}

export type TicketExportTaskUpdateInput = {
  status?: Maybe<TicketExportTaskStatusType>;
  format?: Maybe<TicketExportTaskFormatType>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  where?: Maybe<Scalars['JSON']>;
  sortBy?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  options?: Maybe<TicketExportOptionsInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketExportTaskWhereInput = {
  AND?: Maybe<Array<Maybe<TicketExportTaskWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketExportTaskWhereInput>>>;
  status?: Maybe<TicketExportTaskStatusType>;
  status_not?: Maybe<TicketExportTaskStatusType>;
  status_in?: Maybe<Array<Maybe<TicketExportTaskStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<TicketExportTaskStatusType>>>;
  format?: Maybe<TicketExportTaskFormatType>;
  format_not?: Maybe<TicketExportTaskFormatType>;
  format_in?: Maybe<Array<Maybe<TicketExportTaskFormatType>>>;
  format_not_in?: Maybe<Array<Maybe<TicketExportTaskFormatType>>>;
  exportedRecordsCount?: Maybe<Scalars['Int']>;
  exportedRecordsCount_not?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_lte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gt?: Maybe<Scalars['Int']>;
  exportedRecordsCount_gte?: Maybe<Scalars['Int']>;
  exportedRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  exportedRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount?: Maybe<Scalars['Int']>;
  totalRecordsCount_not?: Maybe<Scalars['Int']>;
  totalRecordsCount_lt?: Maybe<Scalars['Int']>;
  totalRecordsCount_lte?: Maybe<Scalars['Int']>;
  totalRecordsCount_gt?: Maybe<Scalars['Int']>;
  totalRecordsCount_gte?: Maybe<Scalars['Int']>;
  totalRecordsCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalRecordsCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where?: Maybe<Scalars['JSON']>;
  where_not?: Maybe<Scalars['JSON']>;
  where_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  where_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy?: Maybe<Scalars['JSON']>;
  sortBy_not?: Maybe<Scalars['JSON']>;
  sortBy_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sortBy_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone?: Maybe<Scalars['String']>;
  timeZone_not?: Maybe<Scalars['String']>;
  timeZone_contains?: Maybe<Scalars['String']>;
  timeZone_not_contains?: Maybe<Scalars['String']>;
  timeZone_starts_with?: Maybe<Scalars['String']>;
  timeZone_not_starts_with?: Maybe<Scalars['String']>;
  timeZone_ends_with?: Maybe<Scalars['String']>;
  timeZone_not_ends_with?: Maybe<Scalars['String']>;
  timeZone_i?: Maybe<Scalars['String']>;
  timeZone_not_i?: Maybe<Scalars['String']>;
  timeZone_contains_i?: Maybe<Scalars['String']>;
  timeZone_not_contains_i?: Maybe<Scalars['String']>;
  timeZone_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_not_starts_with_i?: Maybe<Scalars['String']>;
  timeZone_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_not_ends_with_i?: Maybe<Scalars['String']>;
  timeZone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  timeZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  options?: Maybe<TicketExportOptionsInput>;
  options_not?: Maybe<TicketExportOptionsInput>;
  options_in?: Maybe<Array<Maybe<TicketExportOptionsInput>>>;
  options_not_in?: Maybe<Array<Maybe<TicketExportOptionsInput>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketExportTaskWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketExportTasksCreateInput = {
  data?: Maybe<TicketExportTaskCreateInput>;
};

export type TicketExportTasksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketExportTaskUpdateInput>;
};

export enum TicketFeedbackValueType {
  Bad = 'bad',
  Good = 'good',
  Returned = 'returned'
}

/**  File attached to the ticket  */
export type TicketFile = {
  __typename?: 'TicketFile';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFile List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFile List config, or
   *  3. As an alias to a 'name' field on the TicketFile List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFile List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  File object with meta information and publicUrl  */
  file?: Maybe<File>;
  /**  Link to ticket  */
  ticket?: Maybe<Ticket>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketFileCreateInput = {
  file?: Maybe<Scalars['Upload']>;
  ticket?: Maybe<TicketRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketFileHistoryRecord = {
  __typename?: 'TicketFileHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFileHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketFileHistoryRecordCreateInput = {
  file?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketFileHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketFileHistoryRecordUpdateInput = {
  file?: Maybe<Scalars['JSON']>;
  ticket?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketFileHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketFileHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketFileHistoryRecordWhereInput>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketFileHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketFileHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketFileHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketFileHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketFileHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketFileHistoryRecordsCreateInput = {
  data?: Maybe<TicketFileHistoryRecordCreateInput>;
};

export type TicketFileHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketFileHistoryRecordUpdateInput>;
};

export type TicketFileUpdateInput = {
  file?: Maybe<Scalars['Upload']>;
  ticket?: Maybe<TicketRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketFileWhereInput = {
  AND?: Maybe<Array<Maybe<TicketFileWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketFileWhereInput>>>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketFileWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketFilesCreateInput = {
  data?: Maybe<TicketFileCreateInput>;
};

export type TicketFilesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketFileUpdateInput>;
};

export type TicketFilter = {
  __typename?: 'TicketFilter';
  organization?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  details?: Maybe<Scalars['String']>;
  property?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyScope?: Maybe<Array<Maybe<Scalars['String']>>>;
  address?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  executor?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignee?: Maybe<Array<Maybe<Scalars['String']>>>;
  executorName?: Maybe<Scalars['String']>;
  deadline?: Maybe<Array<Maybe<Scalars['String']>>>;
  assigneeName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionName?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorName?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeClassifier?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryClassifier?: Maybe<Array<Maybe<Scalars['String']>>>;
  problemClassifier?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlValue?: Maybe<Array<Maybe<Scalars['String']>>>;
  contactIsNull?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentAt?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  isCompletedAfterDeadline?: Maybe<Scalars['String']>;
};

/**  Employee specific ticket filter template  */
export type TicketFilterTemplate = {
  __typename?: 'TicketFilterTemplate';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFilterTemplate List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFilterTemplate List config, or
   *  3. As an alias to a 'name' field on the TicketFilterTemplate List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFilterTemplate List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ticket filter template name  */
  name?: Maybe<Scalars['String']>;
  /**  Link to employee, who created this template  */
  employee?: Maybe<OrganizationEmployee>;
  /**  Filter that match the given template  */
  fields?: Maybe<TicketFilter>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketFilterTemplateCreateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketFilterTemplateHistoryRecord = {
  __typename?: 'TicketFilterTemplateHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketFilterTemplateHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketFilterTemplateHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketFilterTemplateHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketFilterTemplateHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketFilterTemplateHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketFilterTemplateHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketFilterTemplateHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketFilterTemplateHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<Scalars['String']>;
  employee_not?: Maybe<Scalars['String']>;
  employee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<Scalars['JSON']>;
  fields_not?: Maybe<Scalars['JSON']>;
  fields_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketFilterTemplateHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketFilterTemplateHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketFilterTemplateHistoryRecordsCreateInput = {
  data?: Maybe<TicketFilterTemplateHistoryRecordCreateInput>;
};

export type TicketFilterTemplateHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketFilterTemplateHistoryRecordUpdateInput>;
};

export type TicketFilterTemplateUpdateInput = {
  name?: Maybe<Scalars['String']>;
  employee?: Maybe<OrganizationEmployeeRelateToOneInput>;
  fields?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketFilterTemplateWhereInput = {
  AND?: Maybe<Array<Maybe<TicketFilterTemplateWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketFilterTemplateWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  employee?: Maybe<OrganizationEmployeeWhereInput>;
  employee_is_null?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Scalars['JSON']>;
  fields_not?: Maybe<Scalars['JSON']>;
  fields_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketFilterTemplateWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketFilterTemplatesCreateInput = {
  data?: Maybe<TicketFilterTemplateCreateInput>;
};

export type TicketFilterTemplatesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketFilterTemplateUpdateInput>;
};

export type TicketGroupedCounter = {
  __typename?: 'TicketGroupedCounter';
  count: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  dayGroup: Scalars['String'];
  categoryClassifier?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  qualityControlValue?: Maybe<Scalars['String']>;
};

/**  A keystone list  */
export type TicketHistoryRecord = {
  __typename?: 'TicketHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  reviewValue?: Maybe<Scalars['String']>;
  reviewComment?: Maybe<Scalars['String']>;
  feedbackValue?: Maybe<Scalars['String']>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackAdditionalOptions?: Maybe<Scalars['JSON']>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlValue?: Maybe<Scalars['String']>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptions?: Maybe<Scalars['JSON']>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['JSON']>;
  client?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  categoryClassifier?: Maybe<Scalars['String']>;
  placeClassifier?: Maybe<Scalars['String']>;
  problemClassifier?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  related?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  sectionName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<Scalars['String']>;
  floorName?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  deferredUntil?: Maybe<Scalars['String']>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  reviewValue?: Maybe<Scalars['String']>;
  reviewComment?: Maybe<Scalars['String']>;
  feedbackValue?: Maybe<Scalars['String']>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackAdditionalOptions?: Maybe<Scalars['JSON']>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlValue?: Maybe<Scalars['String']>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptions?: Maybe<Scalars['JSON']>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['JSON']>;
  client?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  categoryClassifier?: Maybe<Scalars['String']>;
  placeClassifier?: Maybe<Scalars['String']>;
  problemClassifier?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  related?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  sectionName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<Scalars['String']>;
  floorName?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  deferredUntil?: Maybe<Scalars['String']>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  reviewValue?: Maybe<Scalars['String']>;
  reviewComment?: Maybe<Scalars['String']>;
  feedbackValue?: Maybe<Scalars['String']>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackAdditionalOptions?: Maybe<Scalars['JSON']>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlValue?: Maybe<Scalars['String']>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptions?: Maybe<Scalars['JSON']>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy?: Maybe<Scalars['String']>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  deadline?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['JSON']>;
  client?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  executor?: Maybe<Scalars['String']>;
  categoryClassifier?: Maybe<Scalars['String']>;
  placeClassifier?: Maybe<Scalars['String']>;
  problemClassifier?: Maybe<Scalars['String']>;
  classifier?: Maybe<Scalars['String']>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  related?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  property?: Maybe<Scalars['String']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  sectionName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<Scalars['String']>;
  floorName?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  deferredUntil?: Maybe<Scalars['String']>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  statusReopenedCounter_not?: Maybe<Scalars['Int']>;
  statusReopenedCounter_lt?: Maybe<Scalars['Int']>;
  statusReopenedCounter_lte?: Maybe<Scalars['Int']>;
  statusReopenedCounter_gt?: Maybe<Scalars['Int']>;
  statusReopenedCounter_gte?: Maybe<Scalars['Int']>;
  statusReopenedCounter_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusReopenedCounter_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  reviewValue?: Maybe<Scalars['String']>;
  reviewValue_not?: Maybe<Scalars['String']>;
  reviewValue_contains?: Maybe<Scalars['String']>;
  reviewValue_not_contains?: Maybe<Scalars['String']>;
  reviewValue_starts_with?: Maybe<Scalars['String']>;
  reviewValue_not_starts_with?: Maybe<Scalars['String']>;
  reviewValue_ends_with?: Maybe<Scalars['String']>;
  reviewValue_not_ends_with?: Maybe<Scalars['String']>;
  reviewValue_i?: Maybe<Scalars['String']>;
  reviewValue_not_i?: Maybe<Scalars['String']>;
  reviewValue_contains_i?: Maybe<Scalars['String']>;
  reviewValue_not_contains_i?: Maybe<Scalars['String']>;
  reviewValue_starts_with_i?: Maybe<Scalars['String']>;
  reviewValue_not_starts_with_i?: Maybe<Scalars['String']>;
  reviewValue_ends_with_i?: Maybe<Scalars['String']>;
  reviewValue_not_ends_with_i?: Maybe<Scalars['String']>;
  reviewValue_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewValue_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewComment?: Maybe<Scalars['String']>;
  reviewComment_not?: Maybe<Scalars['String']>;
  reviewComment_contains?: Maybe<Scalars['String']>;
  reviewComment_not_contains?: Maybe<Scalars['String']>;
  reviewComment_starts_with?: Maybe<Scalars['String']>;
  reviewComment_not_starts_with?: Maybe<Scalars['String']>;
  reviewComment_ends_with?: Maybe<Scalars['String']>;
  reviewComment_not_ends_with?: Maybe<Scalars['String']>;
  reviewComment_i?: Maybe<Scalars['String']>;
  reviewComment_not_i?: Maybe<Scalars['String']>;
  reviewComment_contains_i?: Maybe<Scalars['String']>;
  reviewComment_not_contains_i?: Maybe<Scalars['String']>;
  reviewComment_starts_with_i?: Maybe<Scalars['String']>;
  reviewComment_not_starts_with_i?: Maybe<Scalars['String']>;
  reviewComment_ends_with_i?: Maybe<Scalars['String']>;
  reviewComment_not_ends_with_i?: Maybe<Scalars['String']>;
  reviewComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackValue?: Maybe<Scalars['String']>;
  feedbackValue_not?: Maybe<Scalars['String']>;
  feedbackValue_contains?: Maybe<Scalars['String']>;
  feedbackValue_not_contains?: Maybe<Scalars['String']>;
  feedbackValue_starts_with?: Maybe<Scalars['String']>;
  feedbackValue_not_starts_with?: Maybe<Scalars['String']>;
  feedbackValue_ends_with?: Maybe<Scalars['String']>;
  feedbackValue_not_ends_with?: Maybe<Scalars['String']>;
  feedbackValue_i?: Maybe<Scalars['String']>;
  feedbackValue_not_i?: Maybe<Scalars['String']>;
  feedbackValue_contains_i?: Maybe<Scalars['String']>;
  feedbackValue_not_contains_i?: Maybe<Scalars['String']>;
  feedbackValue_starts_with_i?: Maybe<Scalars['String']>;
  feedbackValue_not_starts_with_i?: Maybe<Scalars['String']>;
  feedbackValue_ends_with_i?: Maybe<Scalars['String']>;
  feedbackValue_not_ends_with_i?: Maybe<Scalars['String']>;
  feedbackValue_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackValue_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackComment_not?: Maybe<Scalars['String']>;
  feedbackComment_contains?: Maybe<Scalars['String']>;
  feedbackComment_not_contains?: Maybe<Scalars['String']>;
  feedbackComment_starts_with?: Maybe<Scalars['String']>;
  feedbackComment_not_starts_with?: Maybe<Scalars['String']>;
  feedbackComment_ends_with?: Maybe<Scalars['String']>;
  feedbackComment_not_ends_with?: Maybe<Scalars['String']>;
  feedbackComment_i?: Maybe<Scalars['String']>;
  feedbackComment_not_i?: Maybe<Scalars['String']>;
  feedbackComment_contains_i?: Maybe<Scalars['String']>;
  feedbackComment_not_contains_i?: Maybe<Scalars['String']>;
  feedbackComment_starts_with_i?: Maybe<Scalars['String']>;
  feedbackComment_not_starts_with_i?: Maybe<Scalars['String']>;
  feedbackComment_ends_with_i?: Maybe<Scalars['String']>;
  feedbackComment_not_ends_with_i?: Maybe<Scalars['String']>;
  feedbackComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackAdditionalOptions?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptions_not?: Maybe<Scalars['JSON']>;
  feedbackAdditionalOptions_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  feedbackAdditionalOptions_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_not?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_lt?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_lte?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_gt?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_gte?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackUpdatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlValue?: Maybe<Scalars['String']>;
  qualityControlValue_not?: Maybe<Scalars['String']>;
  qualityControlValue_contains?: Maybe<Scalars['String']>;
  qualityControlValue_not_contains?: Maybe<Scalars['String']>;
  qualityControlValue_starts_with?: Maybe<Scalars['String']>;
  qualityControlValue_not_starts_with?: Maybe<Scalars['String']>;
  qualityControlValue_ends_with?: Maybe<Scalars['String']>;
  qualityControlValue_not_ends_with?: Maybe<Scalars['String']>;
  qualityControlValue_i?: Maybe<Scalars['String']>;
  qualityControlValue_not_i?: Maybe<Scalars['String']>;
  qualityControlValue_contains_i?: Maybe<Scalars['String']>;
  qualityControlValue_not_contains_i?: Maybe<Scalars['String']>;
  qualityControlValue_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlValue_not_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlValue_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlValue_not_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlValue_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlValue_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlComment_not?: Maybe<Scalars['String']>;
  qualityControlComment_contains?: Maybe<Scalars['String']>;
  qualityControlComment_not_contains?: Maybe<Scalars['String']>;
  qualityControlComment_starts_with?: Maybe<Scalars['String']>;
  qualityControlComment_not_starts_with?: Maybe<Scalars['String']>;
  qualityControlComment_ends_with?: Maybe<Scalars['String']>;
  qualityControlComment_not_ends_with?: Maybe<Scalars['String']>;
  qualityControlComment_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_i?: Maybe<Scalars['String']>;
  qualityControlComment_contains_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_contains_i?: Maybe<Scalars['String']>;
  qualityControlComment_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlAdditionalOptions?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptions_not?: Maybe<Scalars['JSON']>;
  qualityControlAdditionalOptions_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  qualityControlAdditionalOptions_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_not?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_lt?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_lte?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_gt?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_gte?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlUpdatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlUpdatedBy?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy_not?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlUpdatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  statusUpdatedAt_not?: Maybe<Scalars['String']>;
  statusUpdatedAt_lt?: Maybe<Scalars['String']>;
  statusUpdatedAt_lte?: Maybe<Scalars['String']>;
  statusUpdatedAt_gt?: Maybe<Scalars['String']>;
  statusUpdatedAt_gte?: Maybe<Scalars['String']>;
  statusUpdatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusUpdatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt?: Maybe<Scalars['String']>;
  completedAt_not?: Maybe<Scalars['String']>;
  completedAt_lt?: Maybe<Scalars['String']>;
  completedAt_lte?: Maybe<Scalars['String']>;
  completedAt_gt?: Maybe<Scalars['String']>;
  completedAt_gte?: Maybe<Scalars['String']>;
  completedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastCommentAt_not?: Maybe<Scalars['String']>;
  lastCommentAt_lt?: Maybe<Scalars['String']>;
  lastCommentAt_lte?: Maybe<Scalars['String']>;
  lastCommentAt_gt?: Maybe<Scalars['String']>;
  lastCommentAt_gte?: Maybe<Scalars['String']>;
  lastCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt_not?: Maybe<Scalars['String']>;
  lastResidentCommentAt_lt?: Maybe<Scalars['String']>;
  lastResidentCommentAt_lte?: Maybe<Scalars['String']>;
  lastResidentCommentAt_gt?: Maybe<Scalars['String']>;
  lastResidentCommentAt_gte?: Maybe<Scalars['String']>;
  lastResidentCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastResidentCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_not?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_lt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_lte?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_gt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_gte?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentWithResidentTypeAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReason?: Maybe<Scalars['String']>;
  statusReason_not?: Maybe<Scalars['String']>;
  statusReason_contains?: Maybe<Scalars['String']>;
  statusReason_not_contains?: Maybe<Scalars['String']>;
  statusReason_starts_with?: Maybe<Scalars['String']>;
  statusReason_not_starts_with?: Maybe<Scalars['String']>;
  statusReason_ends_with?: Maybe<Scalars['String']>;
  statusReason_not_ends_with?: Maybe<Scalars['String']>;
  statusReason_i?: Maybe<Scalars['String']>;
  statusReason_not_i?: Maybe<Scalars['String']>;
  statusReason_contains_i?: Maybe<Scalars['String']>;
  statusReason_not_contains_i?: Maybe<Scalars['String']>;
  statusReason_starts_with_i?: Maybe<Scalars['String']>;
  statusReason_not_starts_with_i?: Maybe<Scalars['String']>;
  statusReason_ends_with_i?: Maybe<Scalars['String']>;
  statusReason_not_ends_with_i?: Maybe<Scalars['String']>;
  statusReason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadline?: Maybe<Scalars['String']>;
  deadline_not?: Maybe<Scalars['String']>;
  deadline_lt?: Maybe<Scalars['String']>;
  deadline_lte?: Maybe<Scalars['String']>;
  deadline_gt?: Maybe<Scalars['String']>;
  deadline_gte?: Maybe<Scalars['String']>;
  deadline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number?: Maybe<Scalars['JSON']>;
  number_not?: Maybe<Scalars['JSON']>;
  number_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  client?: Maybe<Scalars['String']>;
  client_not?: Maybe<Scalars['String']>;
  client_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  client_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact?: Maybe<Scalars['String']>;
  contact_not?: Maybe<Scalars['String']>;
  contact_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  contact_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName?: Maybe<Scalars['String']>;
  clientName_not?: Maybe<Scalars['String']>;
  clientName_contains?: Maybe<Scalars['String']>;
  clientName_not_contains?: Maybe<Scalars['String']>;
  clientName_starts_with?: Maybe<Scalars['String']>;
  clientName_not_starts_with?: Maybe<Scalars['String']>;
  clientName_ends_with?: Maybe<Scalars['String']>;
  clientName_not_ends_with?: Maybe<Scalars['String']>;
  clientName_i?: Maybe<Scalars['String']>;
  clientName_not_i?: Maybe<Scalars['String']>;
  clientName_contains_i?: Maybe<Scalars['String']>;
  clientName_not_contains_i?: Maybe<Scalars['String']>;
  clientName_starts_with_i?: Maybe<Scalars['String']>;
  clientName_not_starts_with_i?: Maybe<Scalars['String']>;
  clientName_ends_with_i?: Maybe<Scalars['String']>;
  clientName_not_ends_with_i?: Maybe<Scalars['String']>;
  clientName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail?: Maybe<Scalars['String']>;
  clientEmail_not?: Maybe<Scalars['String']>;
  clientEmail_contains?: Maybe<Scalars['String']>;
  clientEmail_not_contains?: Maybe<Scalars['String']>;
  clientEmail_starts_with?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with?: Maybe<Scalars['String']>;
  clientEmail_ends_with?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with?: Maybe<Scalars['String']>;
  clientEmail_i?: Maybe<Scalars['String']>;
  clientEmail_not_i?: Maybe<Scalars['String']>;
  clientEmail_contains_i?: Maybe<Scalars['String']>;
  clientEmail_not_contains_i?: Maybe<Scalars['String']>;
  clientEmail_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Scalars['String']>;
  clientPhone_not?: Maybe<Scalars['String']>;
  clientPhone_contains?: Maybe<Scalars['String']>;
  clientPhone_not_contains?: Maybe<Scalars['String']>;
  clientPhone_starts_with?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with?: Maybe<Scalars['String']>;
  clientPhone_ends_with?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with?: Maybe<Scalars['String']>;
  clientPhone_i?: Maybe<Scalars['String']>;
  clientPhone_not_i?: Maybe<Scalars['String']>;
  clientPhone_contains_i?: Maybe<Scalars['String']>;
  clientPhone_not_contains_i?: Maybe<Scalars['String']>;
  clientPhone_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignee?: Maybe<Scalars['String']>;
  assignee_not?: Maybe<Scalars['String']>;
  assignee_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignee_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executor?: Maybe<Scalars['String']>;
  executor_not?: Maybe<Scalars['String']>;
  executor_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  executor_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryClassifier?: Maybe<Scalars['String']>;
  categoryClassifier_not?: Maybe<Scalars['String']>;
  categoryClassifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryClassifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeClassifier?: Maybe<Scalars['String']>;
  placeClassifier_not?: Maybe<Scalars['String']>;
  placeClassifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  placeClassifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  problemClassifier?: Maybe<Scalars['String']>;
  problemClassifier_not?: Maybe<Scalars['String']>;
  problemClassifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  problemClassifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifier?: Maybe<Scalars['String']>;
  classifier_not?: Maybe<Scalars['String']>;
  classifier_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  classifier_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  isAutoClassified_not?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  details_not?: Maybe<Scalars['String']>;
  details_contains?: Maybe<Scalars['String']>;
  details_not_contains?: Maybe<Scalars['String']>;
  details_starts_with?: Maybe<Scalars['String']>;
  details_not_starts_with?: Maybe<Scalars['String']>;
  details_ends_with?: Maybe<Scalars['String']>;
  details_not_ends_with?: Maybe<Scalars['String']>;
  details_i?: Maybe<Scalars['String']>;
  details_not_i?: Maybe<Scalars['String']>;
  details_contains_i?: Maybe<Scalars['String']>;
  details_not_contains_i?: Maybe<Scalars['String']>;
  details_starts_with_i?: Maybe<Scalars['String']>;
  details_not_starts_with_i?: Maybe<Scalars['String']>;
  details_ends_with_i?: Maybe<Scalars['String']>;
  details_not_ends_with_i?: Maybe<Scalars['String']>;
  details_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  details_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  related?: Maybe<Scalars['String']>;
  related_not?: Maybe<Scalars['String']>;
  related_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  related_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPaid_not?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isPayable_not?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isEmergency_not?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isWarranty_not?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  isResidentTicket_not?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  canReadByResident_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddress_not?: Maybe<Scalars['String']>;
  propertyAddress_contains?: Maybe<Scalars['String']>;
  propertyAddress_not_contains?: Maybe<Scalars['String']>;
  propertyAddress_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_i?: Maybe<Scalars['String']>;
  propertyAddress_not_i?: Maybe<Scalars['String']>;
  propertyAddress_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_not_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddress_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_not?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sectionName?: Maybe<Scalars['String']>;
  sectionName_not?: Maybe<Scalars['String']>;
  sectionName_contains?: Maybe<Scalars['String']>;
  sectionName_not_contains?: Maybe<Scalars['String']>;
  sectionName_starts_with?: Maybe<Scalars['String']>;
  sectionName_not_starts_with?: Maybe<Scalars['String']>;
  sectionName_ends_with?: Maybe<Scalars['String']>;
  sectionName_not_ends_with?: Maybe<Scalars['String']>;
  sectionName_i?: Maybe<Scalars['String']>;
  sectionName_not_i?: Maybe<Scalars['String']>;
  sectionName_contains_i?: Maybe<Scalars['String']>;
  sectionName_not_contains_i?: Maybe<Scalars['String']>;
  sectionName_starts_with_i?: Maybe<Scalars['String']>;
  sectionName_not_starts_with_i?: Maybe<Scalars['String']>;
  sectionName_ends_with_i?: Maybe<Scalars['String']>;
  sectionName_not_ends_with_i?: Maybe<Scalars['String']>;
  sectionName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionType?: Maybe<Scalars['String']>;
  sectionType_not?: Maybe<Scalars['String']>;
  sectionType_contains?: Maybe<Scalars['String']>;
  sectionType_not_contains?: Maybe<Scalars['String']>;
  sectionType_starts_with?: Maybe<Scalars['String']>;
  sectionType_not_starts_with?: Maybe<Scalars['String']>;
  sectionType_ends_with?: Maybe<Scalars['String']>;
  sectionType_not_ends_with?: Maybe<Scalars['String']>;
  sectionType_i?: Maybe<Scalars['String']>;
  sectionType_not_i?: Maybe<Scalars['String']>;
  sectionType_contains_i?: Maybe<Scalars['String']>;
  sectionType_not_contains_i?: Maybe<Scalars['String']>;
  sectionType_starts_with_i?: Maybe<Scalars['String']>;
  sectionType_not_starts_with_i?: Maybe<Scalars['String']>;
  sectionType_ends_with_i?: Maybe<Scalars['String']>;
  sectionType_not_ends_with_i?: Maybe<Scalars['String']>;
  sectionType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorName?: Maybe<Scalars['String']>;
  floorName_not?: Maybe<Scalars['String']>;
  floorName_contains?: Maybe<Scalars['String']>;
  floorName_not_contains?: Maybe<Scalars['String']>;
  floorName_starts_with?: Maybe<Scalars['String']>;
  floorName_not_starts_with?: Maybe<Scalars['String']>;
  floorName_ends_with?: Maybe<Scalars['String']>;
  floorName_not_ends_with?: Maybe<Scalars['String']>;
  floorName_i?: Maybe<Scalars['String']>;
  floorName_not_i?: Maybe<Scalars['String']>;
  floorName_contains_i?: Maybe<Scalars['String']>;
  floorName_not_contains_i?: Maybe<Scalars['String']>;
  floorName_starts_with_i?: Maybe<Scalars['String']>;
  floorName_not_starts_with_i?: Maybe<Scalars['String']>;
  floorName_ends_with_i?: Maybe<Scalars['String']>;
  floorName_not_ends_with_i?: Maybe<Scalars['String']>;
  floorName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<Scalars['String']>;
  unitType_not?: Maybe<Scalars['String']>;
  unitType_contains?: Maybe<Scalars['String']>;
  unitType_not_contains?: Maybe<Scalars['String']>;
  unitType_starts_with?: Maybe<Scalars['String']>;
  unitType_not_starts_with?: Maybe<Scalars['String']>;
  unitType_ends_with?: Maybe<Scalars['String']>;
  unitType_not_ends_with?: Maybe<Scalars['String']>;
  unitType_i?: Maybe<Scalars['String']>;
  unitType_not_i?: Maybe<Scalars['String']>;
  unitType_contains_i?: Maybe<Scalars['String']>;
  unitType_not_contains_i?: Maybe<Scalars['String']>;
  unitType_starts_with_i?: Maybe<Scalars['String']>;
  unitType_not_starts_with_i?: Maybe<Scalars['String']>;
  unitType_ends_with_i?: Maybe<Scalars['String']>;
  unitType_not_ends_with_i?: Maybe<Scalars['String']>;
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Scalars['String']>;
  source_not?: Maybe<Scalars['String']>;
  source_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  source_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  sourceMeta_not?: Maybe<Scalars['JSON']>;
  sourceMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sourceMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  deferredUntil?: Maybe<Scalars['String']>;
  deferredUntil_not?: Maybe<Scalars['String']>;
  deferredUntil_lt?: Maybe<Scalars['String']>;
  deferredUntil_lte?: Maybe<Scalars['String']>;
  deferredUntil_gt?: Maybe<Scalars['String']>;
  deferredUntil_gte?: Maybe<Scalars['String']>;
  deferredUntil_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deferredUntil_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  isCompletedAfterDeadline_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketHistoryRecordsCreateInput = {
  data?: Maybe<TicketHistoryRecordCreateInput>;
};

export type TicketHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketHistoryRecordUpdateInput>;
};

export type TicketLabel = {
  __typename?: 'TicketLabel';
  label: Scalars['String'];
  color: Scalars['String'];
  value: Scalars['String'];
};

export type TicketMultipleUpdateInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  id: Scalars['ID'];
  data: Array<Maybe<TicketUpdateInput>>;
};

/**  Ticket settings rules for each organization. (Setting the "deadline" fields for each ticket type)  */
export type TicketOrganizationSetting = {
  __typename?: 'TicketOrganizationSetting';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketOrganizationSetting List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketOrganizationSetting List config, or
   *  3. As an alias to a 'name' field on the TicketOrganizationSetting List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketOrganizationSetting List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Default deadline duration for default tickets (ISO 8601 format)  */
  defaultDeadlineDuration?: Maybe<Scalars['String']>;
  /**  Default deadline duration for payable tickets (ISO 8601 format)  */
  paidDeadlineDuration?: Maybe<Scalars['String']>;
  /**  Default deadline duration for emergency tickets (ISO 8601 format)  */
  emergencyDeadlineDuration?: Maybe<Scalars['String']>;
  /**  Default deadline duration for warranty tickets (ISO 8601 format)  */
  warrantyDeadlineDuration?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketOrganizationSettingCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  defaultDeadlineDuration?: Maybe<Scalars['String']>;
  paidDeadlineDuration?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketOrganizationSettingHistoryRecord = {
  __typename?: 'TicketOrganizationSettingHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketOrganizationSettingHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketOrganizationSettingHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketOrganizationSettingHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketOrganizationSettingHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  defaultDeadlineDuration?: Maybe<Scalars['JSON']>;
  paidDeadlineDuration?: Maybe<Scalars['JSON']>;
  emergencyDeadlineDuration?: Maybe<Scalars['JSON']>;
  warrantyDeadlineDuration?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketOrganizationSettingHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  defaultDeadlineDuration?: Maybe<Scalars['JSON']>;
  paidDeadlineDuration?: Maybe<Scalars['JSON']>;
  emergencyDeadlineDuration?: Maybe<Scalars['JSON']>;
  warrantyDeadlineDuration?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketOrganizationSettingHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketOrganizationSettingHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  defaultDeadlineDuration?: Maybe<Scalars['JSON']>;
  paidDeadlineDuration?: Maybe<Scalars['JSON']>;
  emergencyDeadlineDuration?: Maybe<Scalars['JSON']>;
  warrantyDeadlineDuration?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketOrganizationSettingHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDeadlineDuration?: Maybe<Scalars['JSON']>;
  defaultDeadlineDuration_not?: Maybe<Scalars['JSON']>;
  defaultDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  defaultDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  paidDeadlineDuration?: Maybe<Scalars['JSON']>;
  paidDeadlineDuration_not?: Maybe<Scalars['JSON']>;
  paidDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  paidDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  emergencyDeadlineDuration?: Maybe<Scalars['JSON']>;
  emergencyDeadlineDuration_not?: Maybe<Scalars['JSON']>;
  emergencyDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  emergencyDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  warrantyDeadlineDuration?: Maybe<Scalars['JSON']>;
  warrantyDeadlineDuration_not?: Maybe<Scalars['JSON']>;
  warrantyDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  warrantyDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketOrganizationSettingHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketOrganizationSettingHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketOrganizationSettingHistoryRecordsCreateInput = {
  data?: Maybe<TicketOrganizationSettingHistoryRecordCreateInput>;
};

export type TicketOrganizationSettingHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketOrganizationSettingHistoryRecordUpdateInput>;
};

export type TicketOrganizationSettingUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  defaultDeadlineDuration?: Maybe<Scalars['String']>;
  paidDeadlineDuration?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketOrganizationSettingWhereInput = {
  AND?: Maybe<Array<Maybe<TicketOrganizationSettingWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketOrganizationSettingWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  defaultDeadlineDuration?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_contains?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_contains?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_starts_with?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_starts_with?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_ends_with?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_ends_with?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_contains_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_contains_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_starts_with_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_starts_with_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_ends_with_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_not_ends_with_i?: Maybe<Scalars['String']>;
  defaultDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  defaultDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paidDeadlineDuration?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not?: Maybe<Scalars['String']>;
  paidDeadlineDuration_contains?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_contains?: Maybe<Scalars['String']>;
  paidDeadlineDuration_starts_with?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_starts_with?: Maybe<Scalars['String']>;
  paidDeadlineDuration_ends_with?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_ends_with?: Maybe<Scalars['String']>;
  paidDeadlineDuration_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_contains_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_contains_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_starts_with_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_starts_with_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_ends_with_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_not_ends_with_i?: Maybe<Scalars['String']>;
  paidDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  paidDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  emergencyDeadlineDuration?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_contains?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_contains?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_starts_with?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_starts_with?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_ends_with?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_ends_with?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_contains_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_contains_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_starts_with_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_starts_with_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_ends_with_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_not_ends_with_i?: Maybe<Scalars['String']>;
  emergencyDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  emergencyDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  warrantyDeadlineDuration?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_contains?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_contains?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_starts_with?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_starts_with?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_ends_with?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_ends_with?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_contains_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_contains_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_starts_with_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_starts_with_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_ends_with_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_not_ends_with_i?: Maybe<Scalars['String']>;
  warrantyDeadlineDuration_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  warrantyDeadlineDuration_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketOrganizationSettingWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketOrganizationSettingsCreateInput = {
  data?: Maybe<TicketOrganizationSettingCreateInput>;
};

export type TicketOrganizationSettingsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketOrganizationSettingUpdateInput>;
};

export type TicketOverviewResult = {
  __typename?: 'TicketOverviewResult';
  tickets?: Maybe<Array<TicketGroupedCounter>>;
  translations?: Maybe<Array<Maybe<TicketOverviewTranslations>>>;
};

export type TicketOverviewTranslations = {
  __typename?: 'TicketOverviewTranslations';
  key: Scalars['String'];
  value: Scalars['String'];
};

/**  Describes where the incident occurred  */
export type TicketPlaceClassifier = {
  __typename?: 'TicketPlaceClassifier';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPlaceClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPlaceClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketPlaceClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPlaceClassifier List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  text content  */
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketPlaceClassifierCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketPlaceClassifierHistoryRecord = {
  __typename?: 'TicketPlaceClassifierHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPlaceClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPlaceClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketPlaceClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPlaceClassifierHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketPlaceClassifierHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketPlaceClassifierHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketPlaceClassifierHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketPlaceClassifierHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketPlaceClassifierHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketPlaceClassifierHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketPlaceClassifierHistoryRecordsCreateInput = {
  data?: Maybe<TicketPlaceClassifierHistoryRecordCreateInput>;
};

export type TicketPlaceClassifierHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketPlaceClassifierHistoryRecordUpdateInput>;
};

export type TicketPlaceClassifierRelateToOneInput = {
  create?: Maybe<TicketPlaceClassifierCreateInput>;
  connect?: Maybe<TicketPlaceClassifierWhereUniqueInput>;
  disconnect?: Maybe<TicketPlaceClassifierWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type TicketPlaceClassifierUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketPlaceClassifierWhereInput = {
  AND?: Maybe<Array<Maybe<TicketPlaceClassifierWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketPlaceClassifierWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketPlaceClassifierWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketPlaceClassifiersCreateInput = {
  data?: Maybe<TicketPlaceClassifierCreateInput>;
};

export type TicketPlaceClassifiersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketPlaceClassifierUpdateInput>;
};

/**  Describes what work needs to be done to fix incident  */
export type TicketProblemClassifier = {
  __typename?: 'TicketProblemClassifier';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketProblemClassifier List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketProblemClassifier List config, or
   *  3. As an alias to a 'name' field on the TicketProblemClassifier List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketProblemClassifier List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  text content  */
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketProblemClassifierCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketProblemClassifierHistoryRecord = {
  __typename?: 'TicketProblemClassifierHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketProblemClassifierHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketProblemClassifierHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketProblemClassifierHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketProblemClassifierHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketProblemClassifierHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketProblemClassifierHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketProblemClassifierHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketProblemClassifierHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketProblemClassifierHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketProblemClassifierHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketProblemClassifierHistoryRecordsCreateInput = {
  data?: Maybe<TicketProblemClassifierHistoryRecordCreateInput>;
};

export type TicketProblemClassifierHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketProblemClassifierHistoryRecordUpdateInput>;
};

export type TicketProblemClassifierRelateToOneInput = {
  create?: Maybe<TicketProblemClassifierCreateInput>;
  connect?: Maybe<TicketProblemClassifierWhereUniqueInput>;
  disconnect?: Maybe<TicketProblemClassifierWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type TicketProblemClassifierUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketProblemClassifierWhereInput = {
  AND?: Maybe<Array<Maybe<TicketProblemClassifierWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketProblemClassifierWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketProblemClassifierWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketProblemClassifiersCreateInput = {
  data?: Maybe<TicketProblemClassifierCreateInput>;
};

export type TicketProblemClassifiersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketProblemClassifierUpdateInput>;
};

/**  Textual information in free format related to a property or group of properties, for example list of responsible for property, organization number for clients  */
export type TicketPropertyHint = {
  __typename?: 'TicketPropertyHint';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHint List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHint List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHint List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHint List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Hint name  */
  name?: Maybe<Scalars['String']>;
  /**  Textual content of help in HTML format  */
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketPropertyHintCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketPropertyHintHistoryRecord = {
  __typename?: 'TicketPropertyHintHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHintHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHintHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHintHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHintHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPropertyHintHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketPropertyHintHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPropertyHintHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketPropertyHintHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketPropertyHintHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPropertyHintHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketPropertyHintHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  content_i?: Maybe<Scalars['String']>;
  content_not_i?: Maybe<Scalars['String']>;
  content_contains_i?: Maybe<Scalars['String']>;
  content_not_contains_i?: Maybe<Scalars['String']>;
  content_starts_with_i?: Maybe<Scalars['String']>;
  content_not_starts_with_i?: Maybe<Scalars['String']>;
  content_ends_with_i?: Maybe<Scalars['String']>;
  content_not_ends_with_i?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketPropertyHintHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketPropertyHintHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketPropertyHintHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketPropertyHintHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketPropertyHintHistoryRecordsCreateInput = {
  data?: Maybe<TicketPropertyHintHistoryRecordCreateInput>;
};

export type TicketPropertyHintHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintHistoryRecordUpdateInput>;
};

export type TicketPropertyHintPropertiesCreateInput = {
  data?: Maybe<TicketPropertyHintPropertyCreateInput>;
};

export type TicketPropertyHintPropertiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintPropertyUpdateInput>;
};

/**  Join entity for many-to-many relations, that determines a Property to which the TicketPropertyHint belongs  */
export type TicketPropertyHintProperty = {
  __typename?: 'TicketPropertyHintProperty';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHintProperty List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHintProperty List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHintProperty List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHintProperty List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  TicketPropertyHint which belongs to property  */
  ticketPropertyHint?: Maybe<TicketPropertyHint>;
  /**  Property for which ticketPropertyHint exists  */
  property?: Maybe<Property>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketPropertyHintPropertyCreateInput = {
  ticketPropertyHint?: Maybe<TicketPropertyHintRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketPropertyHintPropertyHistoryRecord = {
  __typename?: 'TicketPropertyHintPropertyHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketPropertyHintPropertyHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketPropertyHintPropertyHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketPropertyHintPropertyHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketPropertyHintPropertyHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  ticketPropertyHint?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketPropertyHintPropertyHistoryRecordCreateInput = {
  ticketPropertyHint?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketPropertyHintPropertyHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketPropertyHintPropertyHistoryRecordUpdateInput = {
  ticketPropertyHint?: Maybe<Scalars['String']>;
  property?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketPropertyHintPropertyHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecordWhereInput>>>;
  ticketPropertyHint?: Maybe<Scalars['String']>;
  ticketPropertyHint_not?: Maybe<Scalars['String']>;
  ticketPropertyHint_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticketPropertyHint_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property?: Maybe<Scalars['String']>;
  property_not?: Maybe<Scalars['String']>;
  property_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  property_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketPropertyHintPropertyHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketPropertyHintPropertyHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketPropertyHintPropertyHistoryRecordsCreateInput = {
  data?: Maybe<TicketPropertyHintPropertyHistoryRecordCreateInput>;
};

export type TicketPropertyHintPropertyHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintPropertyHistoryRecordUpdateInput>;
};

export type TicketPropertyHintPropertyUpdateInput = {
  ticketPropertyHint?: Maybe<TicketPropertyHintRelateToOneInput>;
  property?: Maybe<PropertyRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketPropertyHintPropertyWhereInput = {
  AND?: Maybe<Array<Maybe<TicketPropertyHintPropertyWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketPropertyHintPropertyWhereInput>>>;
  ticketPropertyHint?: Maybe<TicketPropertyHintWhereInput>;
  ticketPropertyHint_is_null?: Maybe<Scalars['Boolean']>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketPropertyHintPropertyWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketPropertyHintRelateToOneInput = {
  create?: Maybe<TicketPropertyHintCreateInput>;
  connect?: Maybe<TicketPropertyHintWhereUniqueInput>;
  disconnect?: Maybe<TicketPropertyHintWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type TicketPropertyHintUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  name?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketPropertyHintWhereInput = {
  AND?: Maybe<Array<Maybe<TicketPropertyHintWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketPropertyHintWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  content_not?: Maybe<Scalars['String']>;
  content_contains?: Maybe<Scalars['String']>;
  content_not_contains?: Maybe<Scalars['String']>;
  content_starts_with?: Maybe<Scalars['String']>;
  content_not_starts_with?: Maybe<Scalars['String']>;
  content_ends_with?: Maybe<Scalars['String']>;
  content_not_ends_with?: Maybe<Scalars['String']>;
  content_i?: Maybe<Scalars['String']>;
  content_not_i?: Maybe<Scalars['String']>;
  content_contains_i?: Maybe<Scalars['String']>;
  content_not_contains_i?: Maybe<Scalars['String']>;
  content_starts_with_i?: Maybe<Scalars['String']>;
  content_not_starts_with_i?: Maybe<Scalars['String']>;
  content_ends_with_i?: Maybe<Scalars['String']>;
  content_not_ends_with_i?: Maybe<Scalars['String']>;
  content_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketPropertyHintWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketPropertyHintsCreateInput = {
  data?: Maybe<TicketPropertyHintCreateInput>;
};

export type TicketPropertyHintsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketPropertyHintUpdateInput>;
};

export enum TicketQualityControlValueType {
  Bad = 'bad',
  Good = 'good'
}

export type TicketRelateToOneInput = {
  create?: Maybe<TicketCreateInput>;
  connect?: Maybe<TicketWhereUniqueInput>;
  disconnect?: Maybe<TicketWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type TicketReportData = {
  __typename?: 'TicketReportData';
  statusName: Scalars['String'];
  currentValue: Scalars['Int'];
  growth: Scalars['Float'];
  statusType: TicketStatusTypeType;
};

export enum TicketReportPeriodType {
  CalendarWeek = 'calendarWeek',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year'
}

export type TicketReportWidgetInput = {
  periodType: TicketReportPeriodType;
  offset?: Maybe<Scalars['Int']>;
  userOrganizationId: Scalars['String'];
};

export type TicketReportWidgetOutput = {
  __typename?: 'TicketReportWidgetOutput';
  data?: Maybe<Array<TicketReportData>>;
};

export enum TicketReviewValueType {
  Bad = 'bad',
  Good = 'good',
  Returned = 'returned'
}

export enum TicketSectionTypeType {
  Parking = 'parking',
  Section = 'section'
}

/**  Ticket source. Income call, mobile app, external system, ...  */
export type TicketSource = {
  __typename?: 'TicketSource';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketSource List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketSource List config, or
   *  3. As an alias to a 'name' field on the TicketSource List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketSource List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  type?: Maybe<TicketSourceTypeType>;
  /**  Localized Ticket source name  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketSourceCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<TicketSourceTypeType>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketSourceHistoryRecord = {
  __typename?: 'TicketSourceHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketSourceHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketSourceHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketSourceHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketSourceHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketSourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketSourceHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketSourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketSourceHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketSourceHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketSourceHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketSourceHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketSourceHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketSourceHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketSourceHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketSourceHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketSourceHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketSourceHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketSourceHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketSourceHistoryRecordsCreateInput = {
  data?: Maybe<TicketSourceHistoryRecordCreateInput>;
};

export type TicketSourceHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketSourceHistoryRecordUpdateInput>;
};

export type TicketSourceRelateToOneInput = {
  create?: Maybe<TicketSourceCreateInput>;
  connect?: Maybe<TicketSourceWhereUniqueInput>;
  disconnect?: Maybe<TicketSourceWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum TicketSourceTypeType {
  Email = 'email',
  MobileApp = 'mobile_app',
  RemoteSystem = 'remote_system',
  Call = 'call',
  Other = 'other',
  Visit = 'visit',
  WebApp = 'web_app',
  OrganizationSite = 'organization_site',
  Messenger = 'messenger',
  SocialNetwork = 'social_network',
  MobileAppStaff = 'mobile_app_staff',
  MobileAppResident = 'mobile_app_resident',
  CrmImport = 'crm_import'
}

export type TicketSourceUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<TicketSourceTypeType>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketSourceWhereInput = {
  AND?: Maybe<Array<Maybe<TicketSourceWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketSourceWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TicketSourceTypeType>;
  type_not?: Maybe<TicketSourceTypeType>;
  type_in?: Maybe<Array<Maybe<TicketSourceTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<TicketSourceTypeType>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketSourceWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketSourcesCreateInput = {
  data?: Maybe<TicketSourceCreateInput>;
};

export type TicketSourcesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketSourceUpdateInput>;
};

/**  Ticket status. We have a organization specific statuses  */
export type TicketStatus = {
  __typename?: 'TicketStatus';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketStatus List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketStatus List config, or
   *  3. As an alias to a 'name' field on the TicketStatus List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketStatus List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. If this ref is null the object is common for all organizations  */
  organization?: Maybe<Organization>;
  /**  Ticket status. You should also increase `statusReopenedCounter` if you want to reopen ticket  */
  type?: Maybe<TicketStatusTypeType>;
  /**  Status name  */
  name?: Maybe<Scalars['String']>;
  nameNonLocalized?: Maybe<Scalars['String']>;
  /**  Status colors, includes primary (font color), secondary (background color), additional (border color), all colors presented in HEX  */
  colors?: Maybe<TicketStatusColorsField>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TicketStatusColorsField = {
  __typename?: 'TicketStatusColorsField';
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  additional?: Maybe<Scalars['String']>;
};

export type TicketStatusCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<TicketStatusTypeType>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TicketStatusHistoryRecord = {
  __typename?: 'TicketStatusHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TicketStatusHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TicketStatusHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TicketStatusHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TicketStatusHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketStatusHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketStatusHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketStatusHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TicketStatusHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TicketStatusHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TicketStatusHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TicketStatusHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TicketStatusHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketStatusHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TicketStatusHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TicketStatusHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TicketStatusHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TicketStatusHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TicketStatusHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketStatusHistoryRecordsCreateInput = {
  data?: Maybe<TicketStatusHistoryRecordCreateInput>;
};

export type TicketStatusHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketStatusHistoryRecordUpdateInput>;
};

export type TicketStatusRelateToOneInput = {
  create?: Maybe<TicketStatusCreateInput>;
  connect?: Maybe<TicketStatusWhereUniqueInput>;
  disconnect?: Maybe<TicketStatusWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum TicketStatusTypeType {
  NewOrReopened = 'new_or_reopened',
  Processing = 'processing',
  Canceled = 'canceled',
  Completed = 'completed',
  Deferred = 'deferred',
  Closed = 'closed'
}

export type TicketStatusUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<TicketStatusTypeType>;
  name?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketStatusWhereInput = {
  AND?: Maybe<Array<Maybe<TicketStatusWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketStatusWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TicketStatusTypeType>;
  type_not?: Maybe<TicketStatusTypeType>;
  type_in?: Maybe<Array<Maybe<TicketStatusTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<TicketStatusTypeType>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketStatusWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketStatusesCreateInput = {
  data?: Maybe<TicketStatusCreateInput>;
};

export type TicketStatusesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketStatusUpdateInput>;
};

export enum TicketUnitTypeType {
  Parking = 'parking',
  Flat = 'flat',
  Apartment = 'apartment',
  Commercial = 'commercial',
  Warehouse = 'warehouse'
}

export type TicketUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  reviewValue?: Maybe<TicketReviewValueType>;
  reviewComment?: Maybe<Scalars['String']>;
  feedbackValue?: Maybe<TicketFeedbackValueType>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackAdditionalOptions?: Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlValue?: Maybe<TicketQualityControlValueType>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlAdditionalOptions?: Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedBy?: Maybe<UserRelateToOneInput>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['String']>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  statusReason?: Maybe<Scalars['String']>;
  status?: Maybe<TicketStatusRelateToOneInput>;
  deadline?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  client?: Maybe<UserRelateToOneInput>;
  contact?: Maybe<ContactRelateToOneInput>;
  clientName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  clientPhone?: Maybe<Scalars['String']>;
  assignee?: Maybe<UserRelateToOneInput>;
  executor?: Maybe<UserRelateToOneInput>;
  categoryClassifier?: Maybe<TicketCategoryClassifierRelateToOneInput>;
  placeClassifier?: Maybe<TicketPlaceClassifierRelateToOneInput>;
  problemClassifier?: Maybe<TicketProblemClassifierRelateToOneInput>;
  classifier?: Maybe<TicketClassifierRelateToOneInput>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  related?: Maybe<TicketRelateToOneInput>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  property?: Maybe<PropertyRelateToOneInput>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  sectionName?: Maybe<Scalars['String']>;
  sectionType?: Maybe<TicketSectionTypeType>;
  floorName?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  unitType?: Maybe<TicketUnitTypeType>;
  source?: Maybe<TicketSourceRelateToOneInput>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  deferredUntil?: Maybe<Scalars['String']>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TicketWhereInput = {
  AND?: Maybe<Array<Maybe<TicketWhereInput>>>;
  OR?: Maybe<Array<Maybe<TicketWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  statusReopenedCounter?: Maybe<Scalars['Int']>;
  statusReopenedCounter_not?: Maybe<Scalars['Int']>;
  statusReopenedCounter_lt?: Maybe<Scalars['Int']>;
  statusReopenedCounter_lte?: Maybe<Scalars['Int']>;
  statusReopenedCounter_gt?: Maybe<Scalars['Int']>;
  statusReopenedCounter_gte?: Maybe<Scalars['Int']>;
  statusReopenedCounter_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  statusReopenedCounter_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  reviewValue?: Maybe<TicketReviewValueType>;
  reviewValue_not?: Maybe<TicketReviewValueType>;
  reviewValue_in?: Maybe<Array<Maybe<TicketReviewValueType>>>;
  reviewValue_not_in?: Maybe<Array<Maybe<TicketReviewValueType>>>;
  reviewComment?: Maybe<Scalars['String']>;
  reviewComment_not?: Maybe<Scalars['String']>;
  reviewComment_contains?: Maybe<Scalars['String']>;
  reviewComment_not_contains?: Maybe<Scalars['String']>;
  reviewComment_starts_with?: Maybe<Scalars['String']>;
  reviewComment_not_starts_with?: Maybe<Scalars['String']>;
  reviewComment_ends_with?: Maybe<Scalars['String']>;
  reviewComment_not_ends_with?: Maybe<Scalars['String']>;
  reviewComment_i?: Maybe<Scalars['String']>;
  reviewComment_not_i?: Maybe<Scalars['String']>;
  reviewComment_contains_i?: Maybe<Scalars['String']>;
  reviewComment_not_contains_i?: Maybe<Scalars['String']>;
  reviewComment_starts_with_i?: Maybe<Scalars['String']>;
  reviewComment_not_starts_with_i?: Maybe<Scalars['String']>;
  reviewComment_ends_with_i?: Maybe<Scalars['String']>;
  reviewComment_not_ends_with_i?: Maybe<Scalars['String']>;
  reviewComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  reviewComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackValue?: Maybe<TicketFeedbackValueType>;
  feedbackValue_not?: Maybe<TicketFeedbackValueType>;
  feedbackValue_in?: Maybe<Array<Maybe<TicketFeedbackValueType>>>;
  feedbackValue_not_in?: Maybe<Array<Maybe<TicketFeedbackValueType>>>;
  feedbackComment?: Maybe<Scalars['String']>;
  feedbackComment_not?: Maybe<Scalars['String']>;
  feedbackComment_contains?: Maybe<Scalars['String']>;
  feedbackComment_not_contains?: Maybe<Scalars['String']>;
  feedbackComment_starts_with?: Maybe<Scalars['String']>;
  feedbackComment_not_starts_with?: Maybe<Scalars['String']>;
  feedbackComment_ends_with?: Maybe<Scalars['String']>;
  feedbackComment_not_ends_with?: Maybe<Scalars['String']>;
  feedbackComment_i?: Maybe<Scalars['String']>;
  feedbackComment_not_i?: Maybe<Scalars['String']>;
  feedbackComment_contains_i?: Maybe<Scalars['String']>;
  feedbackComment_not_contains_i?: Maybe<Scalars['String']>;
  feedbackComment_starts_with_i?: Maybe<Scalars['String']>;
  feedbackComment_not_starts_with_i?: Maybe<Scalars['String']>;
  feedbackComment_ends_with_i?: Maybe<Scalars['String']>;
  feedbackComment_not_ends_with_i?: Maybe<Scalars['String']>;
  feedbackComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackAdditionalOptions?: Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>;
  feedbackAdditionalOptions_not?: Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>;
  feedbackAdditionalOptions_in?: Maybe<Array<Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>>>;
  feedbackAdditionalOptions_not_in?: Maybe<Array<Maybe<Array<Maybe<FeedbackAdditionalOptionsType>>>>>;
  feedbackUpdatedAt?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_not?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_lt?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_lte?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_gt?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_gte?: Maybe<Scalars['String']>;
  feedbackUpdatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  feedbackUpdatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlValue?: Maybe<TicketQualityControlValueType>;
  qualityControlValue_not?: Maybe<TicketQualityControlValueType>;
  qualityControlValue_in?: Maybe<Array<Maybe<TicketQualityControlValueType>>>;
  qualityControlValue_not_in?: Maybe<Array<Maybe<TicketQualityControlValueType>>>;
  qualityControlComment?: Maybe<Scalars['String']>;
  qualityControlComment_not?: Maybe<Scalars['String']>;
  qualityControlComment_contains?: Maybe<Scalars['String']>;
  qualityControlComment_not_contains?: Maybe<Scalars['String']>;
  qualityControlComment_starts_with?: Maybe<Scalars['String']>;
  qualityControlComment_not_starts_with?: Maybe<Scalars['String']>;
  qualityControlComment_ends_with?: Maybe<Scalars['String']>;
  qualityControlComment_not_ends_with?: Maybe<Scalars['String']>;
  qualityControlComment_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_i?: Maybe<Scalars['String']>;
  qualityControlComment_contains_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_contains_i?: Maybe<Scalars['String']>;
  qualityControlComment_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_starts_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_not_ends_with_i?: Maybe<Scalars['String']>;
  qualityControlComment_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlComment_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlAdditionalOptions?: Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>;
  qualityControlAdditionalOptions_not?: Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>;
  qualityControlAdditionalOptions_in?: Maybe<Array<Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>>>;
  qualityControlAdditionalOptions_not_in?: Maybe<Array<Maybe<Array<Maybe<QualityControlAdditionalOptionsType>>>>>;
  qualityControlUpdatedAt?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_not?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_lt?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_lte?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_gt?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_gte?: Maybe<Scalars['String']>;
  qualityControlUpdatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlUpdatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  qualityControlUpdatedBy?: Maybe<UserWhereInput>;
  qualityControlUpdatedBy_is_null?: Maybe<Scalars['Boolean']>;
  statusUpdatedAt?: Maybe<Scalars['String']>;
  statusUpdatedAt_not?: Maybe<Scalars['String']>;
  statusUpdatedAt_lt?: Maybe<Scalars['String']>;
  statusUpdatedAt_lte?: Maybe<Scalars['String']>;
  statusUpdatedAt_gt?: Maybe<Scalars['String']>;
  statusUpdatedAt_gte?: Maybe<Scalars['String']>;
  statusUpdatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusUpdatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt?: Maybe<Scalars['String']>;
  completedAt_not?: Maybe<Scalars['String']>;
  completedAt_lt?: Maybe<Scalars['String']>;
  completedAt_lte?: Maybe<Scalars['String']>;
  completedAt_gt?: Maybe<Scalars['String']>;
  completedAt_gte?: Maybe<Scalars['String']>;
  completedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  completedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentAt?: Maybe<Scalars['String']>;
  lastCommentAt_not?: Maybe<Scalars['String']>;
  lastCommentAt_lt?: Maybe<Scalars['String']>;
  lastCommentAt_lte?: Maybe<Scalars['String']>;
  lastCommentAt_gt?: Maybe<Scalars['String']>;
  lastCommentAt_gte?: Maybe<Scalars['String']>;
  lastCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastResidentCommentAt?: Maybe<Scalars['String']>;
  lastResidentCommentAt_not?: Maybe<Scalars['String']>;
  lastResidentCommentAt_lt?: Maybe<Scalars['String']>;
  lastResidentCommentAt_lte?: Maybe<Scalars['String']>;
  lastResidentCommentAt_gt?: Maybe<Scalars['String']>;
  lastResidentCommentAt_gte?: Maybe<Scalars['String']>;
  lastResidentCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastResidentCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentWithResidentTypeAt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_not?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_lt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_lte?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_gt?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_gte?: Maybe<Scalars['String']>;
  lastCommentWithResidentTypeAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastCommentWithResidentTypeAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReason?: Maybe<Scalars['String']>;
  statusReason_not?: Maybe<Scalars['String']>;
  statusReason_contains?: Maybe<Scalars['String']>;
  statusReason_not_contains?: Maybe<Scalars['String']>;
  statusReason_starts_with?: Maybe<Scalars['String']>;
  statusReason_not_starts_with?: Maybe<Scalars['String']>;
  statusReason_ends_with?: Maybe<Scalars['String']>;
  statusReason_not_ends_with?: Maybe<Scalars['String']>;
  statusReason_i?: Maybe<Scalars['String']>;
  statusReason_not_i?: Maybe<Scalars['String']>;
  statusReason_contains_i?: Maybe<Scalars['String']>;
  statusReason_not_contains_i?: Maybe<Scalars['String']>;
  statusReason_starts_with_i?: Maybe<Scalars['String']>;
  statusReason_not_starts_with_i?: Maybe<Scalars['String']>;
  statusReason_ends_with_i?: Maybe<Scalars['String']>;
  statusReason_not_ends_with_i?: Maybe<Scalars['String']>;
  statusReason_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  statusReason_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<TicketStatusWhereInput>;
  status_is_null?: Maybe<Scalars['Boolean']>;
  deadline?: Maybe<Scalars['String']>;
  deadline_not?: Maybe<Scalars['String']>;
  deadline_lt?: Maybe<Scalars['String']>;
  deadline_lte?: Maybe<Scalars['String']>;
  deadline_gt?: Maybe<Scalars['String']>;
  deadline_gte?: Maybe<Scalars['String']>;
  deadline_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deadline_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number?: Maybe<Scalars['Int']>;
  number_not?: Maybe<Scalars['Int']>;
  number_lt?: Maybe<Scalars['Int']>;
  number_lte?: Maybe<Scalars['Int']>;
  number_gt?: Maybe<Scalars['Int']>;
  number_gte?: Maybe<Scalars['Int']>;
  number_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  number_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  client?: Maybe<UserWhereInput>;
  client_is_null?: Maybe<Scalars['Boolean']>;
  contact?: Maybe<ContactWhereInput>;
  contact_is_null?: Maybe<Scalars['Boolean']>;
  clientName?: Maybe<Scalars['String']>;
  clientName_not?: Maybe<Scalars['String']>;
  clientName_contains?: Maybe<Scalars['String']>;
  clientName_not_contains?: Maybe<Scalars['String']>;
  clientName_starts_with?: Maybe<Scalars['String']>;
  clientName_not_starts_with?: Maybe<Scalars['String']>;
  clientName_ends_with?: Maybe<Scalars['String']>;
  clientName_not_ends_with?: Maybe<Scalars['String']>;
  clientName_i?: Maybe<Scalars['String']>;
  clientName_not_i?: Maybe<Scalars['String']>;
  clientName_contains_i?: Maybe<Scalars['String']>;
  clientName_not_contains_i?: Maybe<Scalars['String']>;
  clientName_starts_with_i?: Maybe<Scalars['String']>;
  clientName_not_starts_with_i?: Maybe<Scalars['String']>;
  clientName_ends_with_i?: Maybe<Scalars['String']>;
  clientName_not_ends_with_i?: Maybe<Scalars['String']>;
  clientName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail?: Maybe<Scalars['String']>;
  clientEmail_not?: Maybe<Scalars['String']>;
  clientEmail_contains?: Maybe<Scalars['String']>;
  clientEmail_not_contains?: Maybe<Scalars['String']>;
  clientEmail_starts_with?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with?: Maybe<Scalars['String']>;
  clientEmail_ends_with?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with?: Maybe<Scalars['String']>;
  clientEmail_i?: Maybe<Scalars['String']>;
  clientEmail_not_i?: Maybe<Scalars['String']>;
  clientEmail_contains_i?: Maybe<Scalars['String']>;
  clientEmail_not_contains_i?: Maybe<Scalars['String']>;
  clientEmail_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_starts_with_i?: Maybe<Scalars['String']>;
  clientEmail_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_not_ends_with_i?: Maybe<Scalars['String']>;
  clientEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone?: Maybe<Scalars['String']>;
  clientPhone_not?: Maybe<Scalars['String']>;
  clientPhone_contains?: Maybe<Scalars['String']>;
  clientPhone_not_contains?: Maybe<Scalars['String']>;
  clientPhone_starts_with?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with?: Maybe<Scalars['String']>;
  clientPhone_ends_with?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with?: Maybe<Scalars['String']>;
  clientPhone_i?: Maybe<Scalars['String']>;
  clientPhone_not_i?: Maybe<Scalars['String']>;
  clientPhone_contains_i?: Maybe<Scalars['String']>;
  clientPhone_not_contains_i?: Maybe<Scalars['String']>;
  clientPhone_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_starts_with_i?: Maybe<Scalars['String']>;
  clientPhone_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_not_ends_with_i?: Maybe<Scalars['String']>;
  clientPhone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientPhone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignee?: Maybe<UserWhereInput>;
  assignee_is_null?: Maybe<Scalars['Boolean']>;
  executor?: Maybe<UserWhereInput>;
  executor_is_null?: Maybe<Scalars['Boolean']>;
  categoryClassifier?: Maybe<TicketCategoryClassifierWhereInput>;
  categoryClassifier_is_null?: Maybe<Scalars['Boolean']>;
  placeClassifier?: Maybe<TicketPlaceClassifierWhereInput>;
  placeClassifier_is_null?: Maybe<Scalars['Boolean']>;
  problemClassifier?: Maybe<TicketProblemClassifierWhereInput>;
  problemClassifier_is_null?: Maybe<Scalars['Boolean']>;
  classifier?: Maybe<TicketClassifierWhereInput>;
  classifier_is_null?: Maybe<Scalars['Boolean']>;
  isAutoClassified?: Maybe<Scalars['Boolean']>;
  isAutoClassified_not?: Maybe<Scalars['Boolean']>;
  details?: Maybe<Scalars['String']>;
  details_not?: Maybe<Scalars['String']>;
  details_contains?: Maybe<Scalars['String']>;
  details_not_contains?: Maybe<Scalars['String']>;
  details_starts_with?: Maybe<Scalars['String']>;
  details_not_starts_with?: Maybe<Scalars['String']>;
  details_ends_with?: Maybe<Scalars['String']>;
  details_not_ends_with?: Maybe<Scalars['String']>;
  details_i?: Maybe<Scalars['String']>;
  details_not_i?: Maybe<Scalars['String']>;
  details_contains_i?: Maybe<Scalars['String']>;
  details_not_contains_i?: Maybe<Scalars['String']>;
  details_starts_with_i?: Maybe<Scalars['String']>;
  details_not_starts_with_i?: Maybe<Scalars['String']>;
  details_ends_with_i?: Maybe<Scalars['String']>;
  details_not_ends_with_i?: Maybe<Scalars['String']>;
  details_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  details_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  related?: Maybe<TicketWhereInput>;
  related_is_null?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isPaid_not?: Maybe<Scalars['Boolean']>;
  isPayable?: Maybe<Scalars['Boolean']>;
  isPayable_not?: Maybe<Scalars['Boolean']>;
  isEmergency?: Maybe<Scalars['Boolean']>;
  isEmergency_not?: Maybe<Scalars['Boolean']>;
  isWarranty?: Maybe<Scalars['Boolean']>;
  isWarranty_not?: Maybe<Scalars['Boolean']>;
  isResidentTicket?: Maybe<Scalars['Boolean']>;
  isResidentTicket_not?: Maybe<Scalars['Boolean']>;
  canReadByResident?: Maybe<Scalars['Boolean']>;
  canReadByResident_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  property?: Maybe<PropertyWhereInput>;
  property_is_null?: Maybe<Scalars['Boolean']>;
  propertyAddress?: Maybe<Scalars['String']>;
  propertyAddress_not?: Maybe<Scalars['String']>;
  propertyAddress_contains?: Maybe<Scalars['String']>;
  propertyAddress_not_contains?: Maybe<Scalars['String']>;
  propertyAddress_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with?: Maybe<Scalars['String']>;
  propertyAddress_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with?: Maybe<Scalars['String']>;
  propertyAddress_i?: Maybe<Scalars['String']>;
  propertyAddress_not_i?: Maybe<Scalars['String']>;
  propertyAddress_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_not_contains_i?: Maybe<Scalars['String']>;
  propertyAddress_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_starts_with_i?: Maybe<Scalars['String']>;
  propertyAddress_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_not_ends_with_i?: Maybe<Scalars['String']>;
  propertyAddress_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddress_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  propertyAddressMeta?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_not?: Maybe<Scalars['JSON']>;
  propertyAddressMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  propertyAddressMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sectionName?: Maybe<Scalars['String']>;
  sectionName_not?: Maybe<Scalars['String']>;
  sectionName_contains?: Maybe<Scalars['String']>;
  sectionName_not_contains?: Maybe<Scalars['String']>;
  sectionName_starts_with?: Maybe<Scalars['String']>;
  sectionName_not_starts_with?: Maybe<Scalars['String']>;
  sectionName_ends_with?: Maybe<Scalars['String']>;
  sectionName_not_ends_with?: Maybe<Scalars['String']>;
  sectionName_i?: Maybe<Scalars['String']>;
  sectionName_not_i?: Maybe<Scalars['String']>;
  sectionName_contains_i?: Maybe<Scalars['String']>;
  sectionName_not_contains_i?: Maybe<Scalars['String']>;
  sectionName_starts_with_i?: Maybe<Scalars['String']>;
  sectionName_not_starts_with_i?: Maybe<Scalars['String']>;
  sectionName_ends_with_i?: Maybe<Scalars['String']>;
  sectionName_not_ends_with_i?: Maybe<Scalars['String']>;
  sectionName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionType?: Maybe<TicketSectionTypeType>;
  sectionType_not?: Maybe<TicketSectionTypeType>;
  sectionType_in?: Maybe<Array<Maybe<TicketSectionTypeType>>>;
  sectionType_not_in?: Maybe<Array<Maybe<TicketSectionTypeType>>>;
  floorName?: Maybe<Scalars['String']>;
  floorName_not?: Maybe<Scalars['String']>;
  floorName_contains?: Maybe<Scalars['String']>;
  floorName_not_contains?: Maybe<Scalars['String']>;
  floorName_starts_with?: Maybe<Scalars['String']>;
  floorName_not_starts_with?: Maybe<Scalars['String']>;
  floorName_ends_with?: Maybe<Scalars['String']>;
  floorName_not_ends_with?: Maybe<Scalars['String']>;
  floorName_i?: Maybe<Scalars['String']>;
  floorName_not_i?: Maybe<Scalars['String']>;
  floorName_contains_i?: Maybe<Scalars['String']>;
  floorName_not_contains_i?: Maybe<Scalars['String']>;
  floorName_starts_with_i?: Maybe<Scalars['String']>;
  floorName_not_starts_with_i?: Maybe<Scalars['String']>;
  floorName_ends_with_i?: Maybe<Scalars['String']>;
  floorName_not_ends_with_i?: Maybe<Scalars['String']>;
  floorName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  floorName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName?: Maybe<Scalars['String']>;
  unitName_not?: Maybe<Scalars['String']>;
  unitName_contains?: Maybe<Scalars['String']>;
  unitName_not_contains?: Maybe<Scalars['String']>;
  unitName_starts_with?: Maybe<Scalars['String']>;
  unitName_not_starts_with?: Maybe<Scalars['String']>;
  unitName_ends_with?: Maybe<Scalars['String']>;
  unitName_not_ends_with?: Maybe<Scalars['String']>;
  unitName_i?: Maybe<Scalars['String']>;
  unitName_not_i?: Maybe<Scalars['String']>;
  unitName_contains_i?: Maybe<Scalars['String']>;
  unitName_not_contains_i?: Maybe<Scalars['String']>;
  unitName_starts_with_i?: Maybe<Scalars['String']>;
  unitName_not_starts_with_i?: Maybe<Scalars['String']>;
  unitName_ends_with_i?: Maybe<Scalars['String']>;
  unitName_not_ends_with_i?: Maybe<Scalars['String']>;
  unitName_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  unitType?: Maybe<TicketUnitTypeType>;
  unitType_not?: Maybe<TicketUnitTypeType>;
  unitType_in?: Maybe<Array<Maybe<TicketUnitTypeType>>>;
  unitType_not_in?: Maybe<Array<Maybe<TicketUnitTypeType>>>;
  source?: Maybe<TicketSourceWhereInput>;
  source_is_null?: Maybe<Scalars['Boolean']>;
  sourceMeta?: Maybe<Scalars['JSON']>;
  sourceMeta_not?: Maybe<Scalars['JSON']>;
  sourceMeta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sourceMeta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  deferredUntil?: Maybe<Scalars['String']>;
  deferredUntil_not?: Maybe<Scalars['String']>;
  deferredUntil_lt?: Maybe<Scalars['String']>;
  deferredUntil_lte?: Maybe<Scalars['String']>;
  deferredUntil_gt?: Maybe<Scalars['String']>;
  deferredUntil_gte?: Maybe<Scalars['String']>;
  deferredUntil_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deferredUntil_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isCompletedAfterDeadline?: Maybe<Scalars['Boolean']>;
  isCompletedAfterDeadline_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TicketWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TicketsCreateInput = {
  data?: Maybe<TicketCreateInput>;
};

export type TicketsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TicketUpdateInput>;
};

/**  One of the few steps in the tour for the organization  */
export type TourStep = {
  __typename?: 'TourStep';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TourStep List config, or
   *  2. As an alias to the field set on 'labelField' in the TourStep List config, or
   *  3. As an alias to a 'name' field on the TourStep List (if one exists), or
   *  4. As an alias to the 'id' field on the TourStep List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Type of the tour step. It is need to understand what this step is responsible for  */
  type?: Maybe<TourStepTypeType>;
  /**  Step status, may be todo, waiting, completed and disabled  */
  status?: Maybe<TourStepStatusType>;
  /**  Used for sorting  */
  order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type TourStepCreateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<TourStepTypeType>;
  status?: Maybe<TourStepStatusType>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type TourStepHistoryRecord = {
  __typename?: 'TourStepHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the TourStepHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the TourStepHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the TourStepHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the TourStepHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TourStepHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TourStepHistoryRecordCreateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TourStepHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum TourStepHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type TourStepHistoryRecordUpdateInput = {
  organization?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<TourStepHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type TourStepHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<TourStepHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<TourStepHistoryRecordWhereInput>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  status_not?: Maybe<Scalars['String']>;
  status_contains?: Maybe<Scalars['String']>;
  status_not_contains?: Maybe<Scalars['String']>;
  status_starts_with?: Maybe<Scalars['String']>;
  status_not_starts_with?: Maybe<Scalars['String']>;
  status_ends_with?: Maybe<Scalars['String']>;
  status_not_ends_with?: Maybe<Scalars['String']>;
  status_i?: Maybe<Scalars['String']>;
  status_not_i?: Maybe<Scalars['String']>;
  status_contains_i?: Maybe<Scalars['String']>;
  status_not_contains_i?: Maybe<Scalars['String']>;
  status_starts_with_i?: Maybe<Scalars['String']>;
  status_not_starts_with_i?: Maybe<Scalars['String']>;
  status_ends_with_i?: Maybe<Scalars['String']>;
  status_not_ends_with_i?: Maybe<Scalars['String']>;
  status_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  status_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<TourStepHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<TourStepHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<TourStepHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<TourStepHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TourStepHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TourStepHistoryRecordsCreateInput = {
  data?: Maybe<TourStepHistoryRecordCreateInput>;
};

export type TourStepHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TourStepHistoryRecordUpdateInput>;
};

export enum TourStepStatusType {
  Todo = 'todo',
  Waiting = 'waiting',
  Disabled = 'disabled',
  Completed = 'completed'
}

export enum TourStepTypeType {
  Ticket = 'ticket',
  Billing = 'billing',
  Meter = 'meter',
  Resident = 'resident',
  CreateProperty = 'createProperty',
  CreatePropertyMap = 'createPropertyMap',
  CreateTicket = 'createTicket',
  UploadReceipts = 'uploadReceipts',
  CreateMeter = 'createMeter',
  CreateMeterReadings = 'createMeterReadings',
  ViewResidentsAppGuide = 'viewResidentsAppGuide',
  CreateNews = 'createNews'
}

export type TourStepUpdateInput = {
  organization?: Maybe<OrganizationRelateToOneInput>;
  type?: Maybe<TourStepTypeType>;
  status?: Maybe<TourStepStatusType>;
  order?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type TourStepWhereInput = {
  AND?: Maybe<Array<Maybe<TourStepWhereInput>>>;
  OR?: Maybe<Array<Maybe<TourStepWhereInput>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  type?: Maybe<TourStepTypeType>;
  type_not?: Maybe<TourStepTypeType>;
  type_in?: Maybe<Array<Maybe<TourStepTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<TourStepTypeType>>>;
  status?: Maybe<TourStepStatusType>;
  status_not?: Maybe<TourStepStatusType>;
  status_in?: Maybe<Array<Maybe<TourStepStatusType>>>;
  status_not_in?: Maybe<Array<Maybe<TourStepStatusType>>>;
  order?: Maybe<Scalars['Int']>;
  order_not?: Maybe<Scalars['Int']>;
  order_lt?: Maybe<Scalars['Int']>;
  order_lte?: Maybe<Scalars['Int']>;
  order_gt?: Maybe<Scalars['Int']>;
  order_gte?: Maybe<Scalars['Int']>;
  order_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  order_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type TourStepWhereUniqueInput = {
  id: Scalars['ID'];
};

export type TourStepsCreateInput = {
  data?: Maybe<TourStepCreateInput>;
};

export type TourStepsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<TourStepUpdateInput>;
};


/**  Individual / person / service account / impersonal company account. Used primarily for authorization purposes, optimized access control with checking of `type` field, tracking authority of performed CRUD operations. Think of `User` as a technical entity, not a business actor. Business actor entities are Resident, OrganizationEmployee etc., — they are participating in high-level business scenarios and have connected to `User`. Almost everyting, created in the system, ends up to `User` as a source of action.  */
export type User = {
  __typename?: 'User';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the User List config, or
   *  2. As an alias to the field set on 'labelField' in the User List config, or
   *  3. As an alias to a 'name' field on the User List (if one exists), or
   *  4. As an alias to the 'id' field on the User List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Name. If impersonal account should be a company name  */
  name?: Maybe<Scalars['String']>;
  hasEmail?: Maybe<Scalars['String']>;
  /**  Password. Update only  */
  password_is_set?: Maybe<Scalars['Boolean']>;
  /**  Field that allows you to distinguish CRM users from mobile app users  */
  type?: Maybe<UserTypeType>;
  /**  Superuser access to service data  */
  isAdmin?: Maybe<Scalars['Boolean']>;
  /**  Can access to "/admin/" panel. And do support tasks  */
  isSupport?: Maybe<Scalars['Boolean']>;
  /**  Email. Transformed to lower case  */
  email?: Maybe<Scalars['String']>;
  /**  Email verification flag. User verify email by access to secret link  */
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  /**  Phone. In international E.164 format without spaces  */
  phone?: Maybe<Scalars['String']>;
  /**  Phone verification flag. User verify phone by access to secret sms message  */
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  /**  User loaded avatar image  */
  avatar?: Maybe<File>;
  /**  User metadata. Example: `city`, `country`, ...  */
  meta?: Maybe<Scalars['JSON']>;
  /**  The user's locale  */
  locale?: Maybe<UserLocaleType>;
  /**  Override for business access rights for list or field of provided schema  */
  customAccess?: Maybe<CustomAccess>;
  /**  Show global hints in CRM or not  */
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  /**  A set of permissions that allow the user to directly read or manage certain schemas as well as run certain mutations.  */
  rightsSet?: Maybe<UserRightsSet>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};


/**  Individual / person / service account / impersonal company account. Used primarily for authorization purposes, optimized access control with checking of `type` field, tracking authority of performed CRUD operations. Think of `User` as a technical entity, not a business actor. Business actor entities are Resident, OrganizationEmployee etc., — they are participating in high-level business scenarios and have connected to `User`. Almost everyting, created in the system, ends up to `User` as a source of action.  */
export type UserHasEmailArgs = {
  formatAs?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<UserTypeType>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  locale?: Maybe<UserLocaleType>;
  customAccess?: Maybe<CustomAccessInput>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<UserRightsSetRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserExternalIdentitiesCreateInput = {
  data?: Maybe<UserExternalIdentityCreateInput>;
};

export type UserExternalIdentitiesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserExternalIdentityUpdateInput>;
};

/**  Individual user external identity link. Used primarily for authorization and linking to external identity provider purposes. Think of `User` as a technical entity, not a business actor.  */
export type UserExternalIdentity = {
  __typename?: 'UserExternalIdentity';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserExternalIdentity List config, or
   *  2. As an alias to the field set on 'labelField' in the UserExternalIdentity List config, or
   *  3. As an alias to a 'name' field on the UserExternalIdentity List (if one exists), or
   *  4. As an alias to the 'id' field on the UserExternalIdentity List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to user  */
  user?: Maybe<User>;
  /**  External identity id. The value of this field should be populated from an external identity provider  */
  identityId?: Maybe<Scalars['String']>;
  /**  The type of external identity that was a source for this link  */
  identityType?: Maybe<UserExternalIdentityIdentityTypeType>;
  /**  External identity provider user metadata  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type UserExternalIdentityCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  identityId?: Maybe<Scalars['String']>;
  identityType?: Maybe<UserExternalIdentityIdentityTypeType>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type UserExternalIdentityHistoryRecord = {
  __typename?: 'UserExternalIdentityHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserExternalIdentityHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserExternalIdentityHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserExternalIdentityHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserExternalIdentityHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  identityType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserExternalIdentityHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserExternalIdentityHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  identityType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserExternalIdentityHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserExternalIdentityHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserExternalIdentityHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  identityType?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserExternalIdentityHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserExternalIdentityHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identityId?: Maybe<Scalars['String']>;
  identityId_not?: Maybe<Scalars['String']>;
  identityId_contains?: Maybe<Scalars['String']>;
  identityId_not_contains?: Maybe<Scalars['String']>;
  identityId_starts_with?: Maybe<Scalars['String']>;
  identityId_not_starts_with?: Maybe<Scalars['String']>;
  identityId_ends_with?: Maybe<Scalars['String']>;
  identityId_not_ends_with?: Maybe<Scalars['String']>;
  identityId_i?: Maybe<Scalars['String']>;
  identityId_not_i?: Maybe<Scalars['String']>;
  identityId_contains_i?: Maybe<Scalars['String']>;
  identityId_not_contains_i?: Maybe<Scalars['String']>;
  identityId_starts_with_i?: Maybe<Scalars['String']>;
  identityId_not_starts_with_i?: Maybe<Scalars['String']>;
  identityId_ends_with_i?: Maybe<Scalars['String']>;
  identityId_not_ends_with_i?: Maybe<Scalars['String']>;
  identityId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identityId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identityType?: Maybe<Scalars['String']>;
  identityType_not?: Maybe<Scalars['String']>;
  identityType_contains?: Maybe<Scalars['String']>;
  identityType_not_contains?: Maybe<Scalars['String']>;
  identityType_starts_with?: Maybe<Scalars['String']>;
  identityType_not_starts_with?: Maybe<Scalars['String']>;
  identityType_ends_with?: Maybe<Scalars['String']>;
  identityType_not_ends_with?: Maybe<Scalars['String']>;
  identityType_i?: Maybe<Scalars['String']>;
  identityType_not_i?: Maybe<Scalars['String']>;
  identityType_contains_i?: Maybe<Scalars['String']>;
  identityType_not_contains_i?: Maybe<Scalars['String']>;
  identityType_starts_with_i?: Maybe<Scalars['String']>;
  identityType_not_starts_with_i?: Maybe<Scalars['String']>;
  identityType_ends_with_i?: Maybe<Scalars['String']>;
  identityType_not_ends_with_i?: Maybe<Scalars['String']>;
  identityType_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identityType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserExternalIdentityHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserExternalIdentityHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserExternalIdentityHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserExternalIdentityHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserExternalIdentityHistoryRecordsCreateInput = {
  data?: Maybe<UserExternalIdentityHistoryRecordCreateInput>;
};

export type UserExternalIdentityHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserExternalIdentityHistoryRecordUpdateInput>;
};

export enum UserExternalIdentityIdentityTypeType {
  AppleId = 'apple_id',
  SberId = 'sber_id',
  Sbbol = 'sbbol'
}

export type UserExternalIdentityUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  identityId?: Maybe<Scalars['String']>;
  identityType?: Maybe<UserExternalIdentityIdentityTypeType>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserExternalIdentityWhereInput = {
  AND?: Maybe<Array<Maybe<UserExternalIdentityWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserExternalIdentityWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  identityId?: Maybe<Scalars['String']>;
  identityId_not?: Maybe<Scalars['String']>;
  identityId_contains?: Maybe<Scalars['String']>;
  identityId_not_contains?: Maybe<Scalars['String']>;
  identityId_starts_with?: Maybe<Scalars['String']>;
  identityId_not_starts_with?: Maybe<Scalars['String']>;
  identityId_ends_with?: Maybe<Scalars['String']>;
  identityId_not_ends_with?: Maybe<Scalars['String']>;
  identityId_i?: Maybe<Scalars['String']>;
  identityId_not_i?: Maybe<Scalars['String']>;
  identityId_contains_i?: Maybe<Scalars['String']>;
  identityId_not_contains_i?: Maybe<Scalars['String']>;
  identityId_starts_with_i?: Maybe<Scalars['String']>;
  identityId_not_starts_with_i?: Maybe<Scalars['String']>;
  identityId_ends_with_i?: Maybe<Scalars['String']>;
  identityId_not_ends_with_i?: Maybe<Scalars['String']>;
  identityId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identityId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  identityType?: Maybe<UserExternalIdentityIdentityTypeType>;
  identityType_not?: Maybe<UserExternalIdentityIdentityTypeType>;
  identityType_in?: Maybe<Array<Maybe<UserExternalIdentityIdentityTypeType>>>;
  identityType_not_in?: Maybe<Array<Maybe<UserExternalIdentityIdentityTypeType>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserExternalIdentityWhereUniqueInput = {
  id: Scalars['ID'];
};

/**  Ticket that user has added to favorites  */
export type UserFavoriteTicket = {
  __typename?: 'UserFavoriteTicket';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserFavoriteTicket List config, or
   *  2. As an alias to the field set on 'labelField' in the UserFavoriteTicket List config, or
   *  3. As an alias to a 'name' field on the UserFavoriteTicket List (if one exists), or
   *  4. As an alias to the 'id' field on the UserFavoriteTicket List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  ticket?: Maybe<Ticket>;
  /**  Ref to the organization. It is filled in on the server and is read-only  */
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type UserFavoriteTicketCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  ticket?: Maybe<TicketRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type UserFavoriteTicketHistoryRecord = {
  __typename?: 'UserFavoriteTicketHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserFavoriteTicketHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserFavoriteTicketHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserFavoriteTicketHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserFavoriteTicketHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserFavoriteTicketHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserFavoriteTicketHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserFavoriteTicketHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserFavoriteTicketHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserFavoriteTicketHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserFavoriteTicketHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserFavoriteTicketHistoryRecordsCreateInput = {
  data?: Maybe<UserFavoriteTicketHistoryRecordCreateInput>;
};

export type UserFavoriteTicketHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserFavoriteTicketHistoryRecordUpdateInput>;
};

export type UserFavoriteTicketUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  ticket?: Maybe<TicketRelateToOneInput>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserFavoriteTicketWhereInput = {
  AND?: Maybe<Array<Maybe<UserFavoriteTicketWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserFavoriteTicketWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserFavoriteTicketWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserFavoriteTicketsCreateInput = {
  data?: Maybe<UserFavoriteTicketCreateInput>;
};

export type UserFavoriteTicketsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserFavoriteTicketUpdateInput>;
};

/**  Request from the user to help him with some functionality  */
export type UserHelpRequest = {
  __typename?: 'UserHelpRequest';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequest List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequest List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequest List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequest List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Type of request. It's can be, for example, request for callback or request to help with import  */
  type?: Maybe<UserHelpRequestTypeType>;
  /**  Ref to the organization. The object will be deleted if the organization ceases to exist  */
  organization?: Maybe<Organization>;
  /**  Specified phone in request for callback  */
  phone?: Maybe<Scalars['String']>;
  /**  Shows if the request is ready to send. False value can be, for example, if files are not synced with help request yet  */
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  /**  Additional info about request. May contain information about file urls, page where user made request or import type  */
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type UserHelpRequestCreateInput = {
  type?: Maybe<UserHelpRequestTypeType>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  phone?: Maybe<Scalars['String']>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  File related to user help request  */
export type UserHelpRequestFile = {
  __typename?: 'UserHelpRequestFile';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequestFile List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequestFile List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequestFile List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequestFile List.
   */
  _label_?: Maybe<Scalars['String']>;
  userHelpRequest?: Maybe<UserHelpRequest>;
  file?: Maybe<File>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type UserHelpRequestFileCreateInput = {
  userHelpRequest?: Maybe<UserHelpRequestRelateToOneInput>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type UserHelpRequestFileHistoryRecord = {
  __typename?: 'UserHelpRequestFileHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequestFileHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequestFileHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequestFileHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequestFileHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  userHelpRequest?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserHelpRequestFileHistoryRecordCreateInput = {
  userHelpRequest?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserHelpRequestFileHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserHelpRequestFileHistoryRecordUpdateInput = {
  userHelpRequest?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserHelpRequestFileHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecordWhereInput>>>;
  userHelpRequest?: Maybe<Scalars['String']>;
  userHelpRequest_not?: Maybe<Scalars['String']>;
  userHelpRequest_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  userHelpRequest_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file?: Maybe<Scalars['JSON']>;
  file_not?: Maybe<Scalars['JSON']>;
  file_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserHelpRequestFileHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserHelpRequestFileHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserHelpRequestFileHistoryRecordsCreateInput = {
  data?: Maybe<UserHelpRequestFileHistoryRecordCreateInput>;
};

export type UserHelpRequestFileHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestFileHistoryRecordUpdateInput>;
};

export type UserHelpRequestFileUpdateInput = {
  userHelpRequest?: Maybe<UserHelpRequestRelateToOneInput>;
  file?: Maybe<Scalars['Upload']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserHelpRequestFileWhereInput = {
  AND?: Maybe<Array<Maybe<UserHelpRequestFileWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserHelpRequestFileWhereInput>>>;
  userHelpRequest?: Maybe<UserHelpRequestWhereInput>;
  userHelpRequest_is_null?: Maybe<Scalars['Boolean']>;
  file?: Maybe<Scalars['String']>;
  file_not?: Maybe<Scalars['String']>;
  file_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  file_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserHelpRequestFileWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserHelpRequestFilesCreateInput = {
  data?: Maybe<UserHelpRequestFileCreateInput>;
};

export type UserHelpRequestFilesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestFileUpdateInput>;
};

/**  A keystone list  */
export type UserHelpRequestHistoryRecord = {
  __typename?: 'UserHelpRequestHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHelpRequestHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHelpRequestHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserHelpRequestHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHelpRequestHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHelpRequestHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserHelpRequestHistoryRecordCreateInput = {
  type?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHelpRequestHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserHelpRequestHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserHelpRequestHistoryRecordUpdateInput = {
  type?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHelpRequestHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserHelpRequestHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserHelpRequestHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserHelpRequestHistoryRecordWhereInput>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization?: Maybe<Scalars['String']>;
  organization_not?: Maybe<Scalars['String']>;
  organization_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  organization_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  isReadyToSend_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserHelpRequestHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserHelpRequestHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserHelpRequestHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserHelpRequestHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserHelpRequestHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserHelpRequestHistoryRecordsCreateInput = {
  data?: Maybe<UserHelpRequestHistoryRecordCreateInput>;
};

export type UserHelpRequestHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestHistoryRecordUpdateInput>;
};

export type UserHelpRequestRelateToOneInput = {
  create?: Maybe<UserHelpRequestCreateInput>;
  connect?: Maybe<UserHelpRequestWhereUniqueInput>;
  disconnect?: Maybe<UserHelpRequestWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export enum UserHelpRequestTypeType {
  Callback = 'callback',
  ImportFile = 'importFile'
}

export type UserHelpRequestUpdateInput = {
  type?: Maybe<UserHelpRequestTypeType>;
  organization?: Maybe<OrganizationRelateToOneInput>;
  phone?: Maybe<Scalars['String']>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserHelpRequestWhereInput = {
  AND?: Maybe<Array<Maybe<UserHelpRequestWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserHelpRequestWhereInput>>>;
  type?: Maybe<UserHelpRequestTypeType>;
  type_not?: Maybe<UserHelpRequestTypeType>;
  type_in?: Maybe<Array<Maybe<UserHelpRequestTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<UserHelpRequestTypeType>>>;
  organization?: Maybe<OrganizationWhereInput>;
  organization_is_null?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isReadyToSend?: Maybe<Scalars['Boolean']>;
  isReadyToSend_not?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserHelpRequestWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserHelpRequestsCreateInput = {
  data?: Maybe<UserHelpRequestCreateInput>;
};

export type UserHelpRequestsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserHelpRequestUpdateInput>;
};

/**  A keystone list  */
export type UserHistoryRecord = {
  __typename?: 'UserHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  customAccess?: Maybe<Scalars['JSON']>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  customAccess?: Maybe<Scalars['JSON']>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['JSON']>;
  meta?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  customAccess?: Maybe<Scalars['JSON']>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  password?: Maybe<Scalars['String']>;
  password_not?: Maybe<Scalars['String']>;
  password_contains?: Maybe<Scalars['String']>;
  password_not_contains?: Maybe<Scalars['String']>;
  password_starts_with?: Maybe<Scalars['String']>;
  password_not_starts_with?: Maybe<Scalars['String']>;
  password_ends_with?: Maybe<Scalars['String']>;
  password_not_ends_with?: Maybe<Scalars['String']>;
  password_i?: Maybe<Scalars['String']>;
  password_not_i?: Maybe<Scalars['String']>;
  password_contains_i?: Maybe<Scalars['String']>;
  password_not_contains_i?: Maybe<Scalars['String']>;
  password_starts_with_i?: Maybe<Scalars['String']>;
  password_not_starts_with_i?: Maybe<Scalars['String']>;
  password_ends_with_i?: Maybe<Scalars['String']>;
  password_not_ends_with_i?: Maybe<Scalars['String']>;
  password_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  password_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  type_not?: Maybe<Scalars['String']>;
  type_contains?: Maybe<Scalars['String']>;
  type_not_contains?: Maybe<Scalars['String']>;
  type_starts_with?: Maybe<Scalars['String']>;
  type_not_starts_with?: Maybe<Scalars['String']>;
  type_ends_with?: Maybe<Scalars['String']>;
  type_not_ends_with?: Maybe<Scalars['String']>;
  type_i?: Maybe<Scalars['String']>;
  type_not_i?: Maybe<Scalars['String']>;
  type_contains_i?: Maybe<Scalars['String']>;
  type_not_contains_i?: Maybe<Scalars['String']>;
  type_starts_with_i?: Maybe<Scalars['String']>;
  type_not_starts_with_i?: Maybe<Scalars['String']>;
  type_ends_with_i?: Maybe<Scalars['String']>;
  type_not_ends_with_i?: Maybe<Scalars['String']>;
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isAdmin_not?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  isSupport_not?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isEmailVerified_not?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  isPhoneVerified_not?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['JSON']>;
  avatar_not?: Maybe<Scalars['JSON']>;
  avatar_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  avatar_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<Scalars['String']>;
  locale_not?: Maybe<Scalars['String']>;
  locale_contains?: Maybe<Scalars['String']>;
  locale_not_contains?: Maybe<Scalars['String']>;
  locale_starts_with?: Maybe<Scalars['String']>;
  locale_not_starts_with?: Maybe<Scalars['String']>;
  locale_ends_with?: Maybe<Scalars['String']>;
  locale_not_ends_with?: Maybe<Scalars['String']>;
  locale_i?: Maybe<Scalars['String']>;
  locale_not_i?: Maybe<Scalars['String']>;
  locale_contains_i?: Maybe<Scalars['String']>;
  locale_not_contains_i?: Maybe<Scalars['String']>;
  locale_starts_with_i?: Maybe<Scalars['String']>;
  locale_not_starts_with_i?: Maybe<Scalars['String']>;
  locale_ends_with_i?: Maybe<Scalars['String']>;
  locale_not_ends_with_i?: Maybe<Scalars['String']>;
  locale_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  locale_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  customAccess?: Maybe<Scalars['JSON']>;
  customAccess_not?: Maybe<Scalars['JSON']>;
  customAccess_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  customAccess_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  showGlobalHints_not?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<Scalars['String']>;
  rightsSet_not?: Maybe<Scalars['String']>;
  rightsSet_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  rightsSet_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserHistoryRecordsCreateInput = {
  data?: Maybe<UserHistoryRecordCreateInput>;
};

export type UserHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserHistoryRecordUpdateInput>;
};

export enum UserLocaleType {
  Ru = 'ru',
  En = 'en'
}

export type UserRelateToOneInput = {
  create?: Maybe<UserCreateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<UserWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  A set of rights that allows one user or a group of users to gain direct access to objects of a particular schema and to perform certain mutations  */
export type UserRightsSet = {
  __typename?: 'UserRightsSet';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserRightsSet List config, or
   *  2. As an alias to the field set on 'labelField' in the UserRightsSet List config, or
   *  3. As an alias to a 'name' field on the UserRightsSet List (if one exists), or
   *  4. As an alias to the 'id' field on the UserRightsSet List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  A name that distinguishes this group of rights from others and describes the set of rights that users of this group receive. For example: "Full access to mini-applications", "Level 1 Support", "Read all organizations", etc  */
  name?: Maybe<Scalars['String']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BApp" as support / admin users do  */
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BApp" similar to support users  */
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppAccessRight" as support / admin users do  */
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppAccessRight" similar to support users  */
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppAccessRightSet" as support / admin users do  */
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppAccessRightSet" similar to support users  */
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppContext" as support / admin users do  */
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppContext" similar to support users  */
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppNewsSharingConfig" as support / admin users do  */
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppNewsSharingConfig" similar to support users  */
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppPermission" as support / admin users do  */
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppPermission" similar to support users  */
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2BAppPromoBlock" as support / admin users do  */
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2BAppPromoBlock" similar to support users  */
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2CApp" as support / admin users do  */
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CApp" similar to support users  */
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2CAppAccessRight" as support / admin users do  */
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CAppAccessRight" similar to support users  */
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2CAppBuild" as support / admin users do  */
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CAppBuild" similar to support users  */
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "B2CAppProperty" as support / admin users do  */
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "B2CAppProperty" similar to support users  */
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "Message" as support / admin users do  */
  canReadMessages?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "MessageBatch" as support / admin users do  */
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "MessageBatch" similar to support users  */
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "Organization" as support / admin users do  */
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "Organization" similar to support users  */
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "Ticket" as support / admin users do  */
  canReadTickets?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "Ticket" similar to support users  */
  canManageTickets?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "TicketAutoAssignment" as support / admin users do  */
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "TicketAutoAssignment" similar to support users  */
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "OidcClient" as support / admin users do  */
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "OidcClient" similar to support users  */
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "User" as support / admin users do  */
  canReadUsers?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "UserRightsSet" as support / admin users do  */
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to create, update or soft-delete entities of model "UserRightsSet" similar to support users  */
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "Payment" as support / admin users do  */
  canReadPayments?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "BillingReceipt" as support / admin users do  */
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to view all entities of model "BillingOrganizationIntegrationContext" as support / admin users do  */
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to execute "registerNewServiceUser" query/mutation  */
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to execute "sendMessage" query/mutation  */
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to execute "_internalSendHashedResidentPhones" query/mutation  */
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to execute "_allPaymentsSum" query/mutation  */
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to execute "_allBillingReceiptsSum" query/mutation  */
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to update "isApproved" field of model "Organization"  */
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  /**  Enables a user with the given UserRightsSet to read "email" field of model "User"  */
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type UserRightsSetCreateInput = {
  name?: Maybe<Scalars['String']>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type UserRightsSetHistoryRecord = {
  __typename?: 'UserRightsSetHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserRightsSetHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserRightsSetHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserRightsSetHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserRightsSetHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserRightsSetHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserRightsSetHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserRightsSetHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserRightsSetHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserRightsSetHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserRightsSetHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserRightsSetHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserRightsSetHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserRightsSetHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BApps_not?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks_not?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CApps_not?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps_not?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds_not?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds_not?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties_not?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties_not?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessages_not?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches_not?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches_not?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canReadOrganizations_not?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations_not?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canReadTickets_not?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets_not?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments_not?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments_not?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canReadOidcClients_not?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients_not?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUsers_not?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets_not?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets_not?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadPayments_not?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts_not?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts_not?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser_not?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage_not?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones_not?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum_not?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum_not?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField_not?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserRightsSetHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserRightsSetHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserRightsSetHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserRightsSetHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserRightsSetHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserRightsSetHistoryRecordsCreateInput = {
  data?: Maybe<UserRightsSetHistoryRecordCreateInput>;
};

export type UserRightsSetHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserRightsSetHistoryRecordUpdateInput>;
};

export type UserRightsSetRelateToOneInput = {
  create?: Maybe<UserRightsSetCreateInput>;
  connect?: Maybe<UserRightsSetWhereUniqueInput>;
  disconnect?: Maybe<UserRightsSetWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

export type UserRightsSetUpdateInput = {
  name?: Maybe<Scalars['String']>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserRightsSetWhereInput = {
  AND?: Maybe<Array<Maybe<UserRightsSetWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserRightsSetWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  canReadB2BApps?: Maybe<Scalars['Boolean']>;
  canReadB2BApps_not?: Maybe<Scalars['Boolean']>;
  canManageB2BApps?: Maybe<Scalars['Boolean']>;
  canManageB2BApps_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canReadB2BAppAccessRightSets_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets?: Maybe<Scalars['Boolean']>;
  canManageB2BAppAccessRightSets_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canReadB2BAppContexts_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts?: Maybe<Scalars['Boolean']>;
  canManageB2BAppContexts_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canReadB2BAppNewsSharingConfigs_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs?: Maybe<Scalars['Boolean']>;
  canManageB2BAppNewsSharingConfigs_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPermissions_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPermissions_not?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canReadB2BAppPromoBlocks_not?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks?: Maybe<Scalars['Boolean']>;
  canManageB2BAppPromoBlocks_not?: Maybe<Scalars['Boolean']>;
  canReadB2CApps?: Maybe<Scalars['Boolean']>;
  canReadB2CApps_not?: Maybe<Scalars['Boolean']>;
  canManageB2CApps?: Maybe<Scalars['Boolean']>;
  canManageB2CApps_not?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canReadB2CAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights?: Maybe<Scalars['Boolean']>;
  canManageB2CAppAccessRights_not?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canReadB2CAppBuilds_not?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds?: Maybe<Scalars['Boolean']>;
  canManageB2CAppBuilds_not?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canReadB2CAppProperties_not?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties?: Maybe<Scalars['Boolean']>;
  canManageB2CAppProperties_not?: Maybe<Scalars['Boolean']>;
  canReadMessages?: Maybe<Scalars['Boolean']>;
  canReadMessages_not?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches?: Maybe<Scalars['Boolean']>;
  canReadMessageBatches_not?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches?: Maybe<Scalars['Boolean']>;
  canManageMessageBatches_not?: Maybe<Scalars['Boolean']>;
  canReadOrganizations?: Maybe<Scalars['Boolean']>;
  canReadOrganizations_not?: Maybe<Scalars['Boolean']>;
  canManageOrganizations?: Maybe<Scalars['Boolean']>;
  canManageOrganizations_not?: Maybe<Scalars['Boolean']>;
  canReadTickets?: Maybe<Scalars['Boolean']>;
  canReadTickets_not?: Maybe<Scalars['Boolean']>;
  canManageTickets?: Maybe<Scalars['Boolean']>;
  canManageTickets_not?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canReadTicketAutoAssignments_not?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments?: Maybe<Scalars['Boolean']>;
  canManageTicketAutoAssignments_not?: Maybe<Scalars['Boolean']>;
  canReadOidcClients?: Maybe<Scalars['Boolean']>;
  canReadOidcClients_not?: Maybe<Scalars['Boolean']>;
  canManageOidcClients?: Maybe<Scalars['Boolean']>;
  canManageOidcClients_not?: Maybe<Scalars['Boolean']>;
  canReadUsers?: Maybe<Scalars['Boolean']>;
  canReadUsers_not?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets?: Maybe<Scalars['Boolean']>;
  canReadUserRightsSets_not?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets?: Maybe<Scalars['Boolean']>;
  canManageUserRightsSets_not?: Maybe<Scalars['Boolean']>;
  canReadPayments?: Maybe<Scalars['Boolean']>;
  canReadPayments_not?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts?: Maybe<Scalars['Boolean']>;
  canReadBillingReceipts_not?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts?: Maybe<Scalars['Boolean']>;
  canReadBillingOrganizationIntegrationContexts_not?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser?: Maybe<Scalars['Boolean']>;
  canExecuteRegisterNewServiceUser_not?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage?: Maybe<Scalars['Boolean']>;
  canExecuteSendMessage_not?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones?: Maybe<Scalars['Boolean']>;
  canExecute_internalSendHashedResidentPhones_not?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allPaymentsSum_not?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum?: Maybe<Scalars['Boolean']>;
  canExecute_allBillingReceiptsSum_not?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField?: Maybe<Scalars['Boolean']>;
  canManageOrganizationIsApprovedField_not?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField?: Maybe<Scalars['Boolean']>;
  canReadUserEmailField_not?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserRightsSetWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserRightsSetsCreateInput = {
  data?: Maybe<UserRightsSetCreateInput>;
};

export type UserRightsSetsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserRightsSetUpdateInput>;
};

/**  Time when a comment from a resident was last read by a specific user in a specific ticket  */
export type UserTicketCommentReadTime = {
  __typename?: 'UserTicketCommentReadTime';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserTicketCommentReadTime List config, or
   *  2. As an alias to the field set on 'labelField' in the UserTicketCommentReadTime List config, or
   *  3. As an alias to a 'name' field on the UserTicketCommentReadTime List (if one exists), or
   *  4. As an alias to the 'id' field on the UserTicketCommentReadTime List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  The user who read the comment  */
  user?: Maybe<User>;
  /**  Ticket in which the user read the comment  */
  ticket?: Maybe<Ticket>;
  /**  Time when the last comment was last read by the user  */
  readCommentAt?: Maybe<Scalars['String']>;
  /**  Time when the last comment from a resident was last read by the user  */
  readResidentCommentAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type UserTicketCommentReadTimeCreateInput = {
  user?: Maybe<UserRelateToOneInput>;
  ticket?: Maybe<TicketRelateToOneInput>;
  readCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type UserTicketCommentReadTimeHistoryRecord = {
  __typename?: 'UserTicketCommentReadTimeHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the UserTicketCommentReadTimeHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the UserTicketCommentReadTimeHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the UserTicketCommentReadTimeHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the UserTicketCommentReadTimeHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  readCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserTicketCommentReadTimeHistoryRecordCreateInput = {
  user?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  readCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum UserTicketCommentReadTimeHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type UserTicketCommentReadTimeHistoryRecordUpdateInput = {
  user?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['String']>;
  readCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type UserTicketCommentReadTimeHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecordWhereInput>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket?: Maybe<Scalars['String']>;
  ticket_not?: Maybe<Scalars['String']>;
  ticket_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  ticket_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readCommentAt?: Maybe<Scalars['String']>;
  readCommentAt_not?: Maybe<Scalars['String']>;
  readCommentAt_lt?: Maybe<Scalars['String']>;
  readCommentAt_lte?: Maybe<Scalars['String']>;
  readCommentAt_gt?: Maybe<Scalars['String']>;
  readCommentAt_gte?: Maybe<Scalars['String']>;
  readCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt_not?: Maybe<Scalars['String']>;
  readResidentCommentAt_lt?: Maybe<Scalars['String']>;
  readResidentCommentAt_lte?: Maybe<Scalars['String']>;
  readResidentCommentAt_gt?: Maybe<Scalars['String']>;
  readResidentCommentAt_gte?: Maybe<Scalars['String']>;
  readResidentCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readResidentCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<UserTicketCommentReadTimeHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UserTicketCommentReadTimeHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserTicketCommentReadTimeHistoryRecordsCreateInput = {
  data?: Maybe<UserTicketCommentReadTimeHistoryRecordCreateInput>;
};

export type UserTicketCommentReadTimeHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserTicketCommentReadTimeHistoryRecordUpdateInput>;
};

export type UserTicketCommentReadTimeUpdateInput = {
  user?: Maybe<UserRelateToOneInput>;
  ticket?: Maybe<TicketRelateToOneInput>;
  readCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserTicketCommentReadTimeWhereInput = {
  AND?: Maybe<Array<Maybe<UserTicketCommentReadTimeWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserTicketCommentReadTimeWhereInput>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  ticket?: Maybe<TicketWhereInput>;
  ticket_is_null?: Maybe<Scalars['Boolean']>;
  readCommentAt?: Maybe<Scalars['String']>;
  readCommentAt_not?: Maybe<Scalars['String']>;
  readCommentAt_lt?: Maybe<Scalars['String']>;
  readCommentAt_lte?: Maybe<Scalars['String']>;
  readCommentAt_gt?: Maybe<Scalars['String']>;
  readCommentAt_gte?: Maybe<Scalars['String']>;
  readCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readResidentCommentAt?: Maybe<Scalars['String']>;
  readResidentCommentAt_not?: Maybe<Scalars['String']>;
  readResidentCommentAt_lt?: Maybe<Scalars['String']>;
  readResidentCommentAt_lte?: Maybe<Scalars['String']>;
  readResidentCommentAt_gt?: Maybe<Scalars['String']>;
  readResidentCommentAt_gte?: Maybe<Scalars['String']>;
  readResidentCommentAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  readResidentCommentAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserTicketCommentReadTimeWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UserTicketCommentReadTimesCreateInput = {
  data?: Maybe<UserTicketCommentReadTimeCreateInput>;
};

export type UserTicketCommentReadTimesUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserTicketCommentReadTimeUpdateInput>;
};

export enum UserTypeType {
  Staff = 'staff',
  Resident = 'resident',
  Service = 'service'
}

export type UserUpdateInput = {
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<UserTypeType>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['Upload']>;
  meta?: Maybe<Scalars['JSON']>;
  locale?: Maybe<UserLocaleType>;
  customAccess?: Maybe<CustomAccessInput>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<UserRightsSetRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<Maybe<UserWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  password_is_set?: Maybe<Scalars['Boolean']>;
  type?: Maybe<UserTypeType>;
  type_not?: Maybe<UserTypeType>;
  type_in?: Maybe<Array<Maybe<UserTypeType>>>;
  type_not_in?: Maybe<Array<Maybe<UserTypeType>>>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  isAdmin_not?: Maybe<Scalars['Boolean']>;
  isSupport?: Maybe<Scalars['Boolean']>;
  isSupport_not?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  email_not?: Maybe<Scalars['String']>;
  email_contains?: Maybe<Scalars['String']>;
  email_not_contains?: Maybe<Scalars['String']>;
  email_starts_with?: Maybe<Scalars['String']>;
  email_not_starts_with?: Maybe<Scalars['String']>;
  email_ends_with?: Maybe<Scalars['String']>;
  email_not_ends_with?: Maybe<Scalars['String']>;
  email_i?: Maybe<Scalars['String']>;
  email_not_i?: Maybe<Scalars['String']>;
  email_contains_i?: Maybe<Scalars['String']>;
  email_not_contains_i?: Maybe<Scalars['String']>;
  email_starts_with_i?: Maybe<Scalars['String']>;
  email_not_starts_with_i?: Maybe<Scalars['String']>;
  email_ends_with_i?: Maybe<Scalars['String']>;
  email_not_ends_with_i?: Maybe<Scalars['String']>;
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isEmailVerified_not?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  phone_not?: Maybe<Scalars['String']>;
  phone_contains?: Maybe<Scalars['String']>;
  phone_not_contains?: Maybe<Scalars['String']>;
  phone_starts_with?: Maybe<Scalars['String']>;
  phone_not_starts_with?: Maybe<Scalars['String']>;
  phone_ends_with?: Maybe<Scalars['String']>;
  phone_not_ends_with?: Maybe<Scalars['String']>;
  phone_i?: Maybe<Scalars['String']>;
  phone_not_i?: Maybe<Scalars['String']>;
  phone_contains_i?: Maybe<Scalars['String']>;
  phone_not_contains_i?: Maybe<Scalars['String']>;
  phone_starts_with_i?: Maybe<Scalars['String']>;
  phone_not_starts_with_i?: Maybe<Scalars['String']>;
  phone_ends_with_i?: Maybe<Scalars['String']>;
  phone_not_ends_with_i?: Maybe<Scalars['String']>;
  phone_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  phone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  isPhoneVerified?: Maybe<Scalars['Boolean']>;
  isPhoneVerified_not?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  avatar_not?: Maybe<Scalars['String']>;
  avatar_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  avatar_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  meta?: Maybe<Scalars['JSON']>;
  meta_not?: Maybe<Scalars['JSON']>;
  meta_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  meta_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  locale?: Maybe<UserLocaleType>;
  locale_not?: Maybe<UserLocaleType>;
  locale_in?: Maybe<Array<Maybe<UserLocaleType>>>;
  locale_not_in?: Maybe<Array<Maybe<UserLocaleType>>>;
  customAccess?: Maybe<CustomAccessInput>;
  customAccess_not?: Maybe<CustomAccessInput>;
  customAccess_in?: Maybe<Array<Maybe<CustomAccessInput>>>;
  customAccess_not_in?: Maybe<Array<Maybe<CustomAccessInput>>>;
  showGlobalHints?: Maybe<Scalars['Boolean']>;
  showGlobalHints_not?: Maybe<Scalars['Boolean']>;
  rightsSet?: Maybe<UserRightsSetWhereInput>;
  rightsSet_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type UserWhereUniqueInput = {
  id: Scalars['ID'];
};

export type UsersCreateInput = {
  data?: Maybe<UserCreateInput>;
};

export type UsersUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<UserUpdateInput>;
};

export type ValidateQrCodeFeesOutput = {
  __typename?: 'ValidateQRCodeFeesOutput';
  explicitServiceCharge?: Maybe<Scalars['String']>;
  explicitFee?: Maybe<Scalars['String']>;
};

export type ValidateQrCodeInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  qrCode: Scalars['String'];
};

export type ValidateQrCodeLastReceiptDataOutput = {
  __typename?: 'ValidateQRCodeLastReceiptDataOutput';
  id: Scalars['ID'];
  period: Scalars['String'];
  toPay: Scalars['String'];
};

export type ValidateQrCodeOutput = {
  __typename?: 'ValidateQRCodeOutput';
  qrCodeFields: Scalars['JSON'];
  lastReceiptData?: Maybe<ValidateQrCodeLastReceiptDataOutput>;
  explicitFees: ValidateQrCodeFeesOutput;
  amount: Scalars['String'];
  acquiringIntegrationHostUrl: Scalars['String'];
  currencyCode: Scalars['String'];
};

/** Technical map of the 'village' type Property object. We assume that there will be different maps for different property types.  */
export type VillageMap = {
  __typename?: 'VillageMap';
  dv: Scalars['Int'];
  type?: Maybe<VillageMapType>;
};

export enum VillageMapType {
  Village = 'village'
}

/**  Webhooks are a way that the APP can send automated web callback with some messages to other apps or system to inform them about any updates. How does it work: 1. When objects are created or changed, we make requests to the GraphQL API to get data on behalf of the specified user; 2. Then we send the data to remote url. Webhook model contains basic configuration of integration, such as external server url, name, encryption parameters and so on.  */
export type Webhook = {
  __typename?: 'Webhook';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the Webhook List config, or
   *  2. As an alias to the field set on 'labelField' in the Webhook List config, or
   *  3. As an alias to a 'name' field on the Webhook List (if one exists), or
   *  4. As an alias to the 'id' field on the Webhook List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Short name used to distinguish this hook from others. Usually it's the name of the integration  */
  name?: Maybe<Scalars['String']>;
  /**  Any other details that reveal the purpose of this hook  */
  description?: Maybe<Scalars['String']>;
  /**  Webhook target URL to which requests will be sent  */
  url?: Maybe<Scalars['String']>;
  /**  The user on whose behalf a request is being made to the GraphQL API to prepare webhook data  */
  user?: Maybe<User>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type WebhookCreateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type WebhookHistoryRecord = {
  __typename?: 'WebhookHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the WebhookHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the WebhookHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the WebhookHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the WebhookHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<WebhookHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type WebhookHistoryRecordCreateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<WebhookHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum WebhookHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type WebhookHistoryRecordUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<WebhookHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type WebhookHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<WebhookHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<WebhookHistoryRecordWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  url_i?: Maybe<Scalars['String']>;
  url_not_i?: Maybe<Scalars['String']>;
  url_contains_i?: Maybe<Scalars['String']>;
  url_not_contains_i?: Maybe<Scalars['String']>;
  url_starts_with_i?: Maybe<Scalars['String']>;
  url_not_starts_with_i?: Maybe<Scalars['String']>;
  url_ends_with_i?: Maybe<Scalars['String']>;
  url_not_ends_with_i?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<Scalars['String']>;
  user_not?: Maybe<Scalars['String']>;
  user_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<WebhookHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<WebhookHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<WebhookHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<WebhookHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WebhookHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type WebhookHistoryRecordsCreateInput = {
  data?: Maybe<WebhookHistoryRecordCreateInput>;
};

export type WebhookHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<WebhookHistoryRecordUpdateInput>;
};

export type WebhookRelateToOneInput = {
  create?: Maybe<WebhookCreateInput>;
  connect?: Maybe<WebhookWhereUniqueInput>;
  disconnect?: Maybe<WebhookWhereUniqueInput>;
  disconnectAll?: Maybe<Scalars['Boolean']>;
};

/**  Determines which models the WebHook will be subscribed to. When model changes subscription task will be triggered to resolve changed data and send a webhook  */
export type WebhookSubscription = {
  __typename?: 'WebhookSubscription';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the WebhookSubscription List config, or
   *  2. As an alias to the field set on 'labelField' in the WebhookSubscription List config, or
   *  3. As an alias to a 'name' field on the WebhookSubscription List (if one exists), or
   *  4. As an alias to the 'id' field on the WebhookSubscription List.
   */
  _label_?: Maybe<Scalars['String']>;
  /**  Link to a webhook containing information about integration  */
  webhook?: Maybe<Webhook>;
  /**  Webhook target URL to which requests will be sent. Overrides url from webhook relation. Used in case when you need to send specific model to a separate url  */
  url?: Maybe<Scalars['String']>;
  /**  The time was the data was last synced. At the next synchronization, only objects that have changed since that time will be sent.  */
  syncedAt?: Maybe<Scalars['String']>;
  /**  The number of objects successfully delivered by webhooks. On successful synchronization, the syncedAt field is updated and syncedAmount becomes 0. If the remote server fails, syncedAt will not be updated, and syncedAmount will increment to the number of successfully delivered objects.  */
  syncedAmount?: Maybe<Scalars['Int']>;
  /**  The number of consecutive failures to send webhooks to a remote server. Field value is automatically incremented when the specified url is unavailable or the server response was not ok, but no more than once per hour. Field value is automatically reset to 0 when the remote server is successfully reached (syncedAt or syncedAmount changed), or can be manually reset by support. As soon as the counter reaches the value 10, which is interpreted as the unavailability of the external service for at least 10 hours, the webhook will stop being sent to this url. In this case, you will need to manually reset the counter via support to resume sending.  */
  failuresCount?: Maybe<Scalars['Int']>;
  /**  The data model (schema) that the webhook is subscribed to  */
  model?: Maybe<WebhookSubscriptionModelType>;
  /**  String representing list of model fields in graphql-query format. Exactly the fields specified here will be sent by the webhook. Correct examples: "field1 field2 { subfield }", "{ field1 relation { subfield } }"  */
  fields?: Maybe<Scalars['String']>;
  /**  Filters which is stored in JSON and used to filter models sent by the webhook. Examples of filters can be found in ModelWhereInput GQL type, where Model is name of your model  */
  filters?: Maybe<Scalars['JSON']>;
  /**  The maximum number of objects that the server can send in one request. The default is 100, and maxPackSize cannot be set beyond this value. In most cases, you do not need to override this field, but it is recommended to lower this value for requests with a large number of related fields or in case of external restrictions of the server accepting webhooks.  */
  maxPackSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  /**  Identifies a user, which has created this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  createdBy?: Maybe<User>;
  /**  Identifies a user, which has updated this record. It is a technical connection, that can represent real users, as well as automated systems (bots, scripts). This field should not participate in business logic.  */
  updatedBy?: Maybe<User>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  /**  Data structure Version  */
  dv?: Maybe<Scalars['Int']>;
  /**  Client-side device identification used for the anti-fraud detection. Example `{ "dv":1, "fingerprint":"VaxSw2aXZa"}`. Where the `fingerprint` should be the same for the same devices and it's not linked to the user ID. It's the device ID like browser / mobile application / remote system  */
  sender?: Maybe<SenderField>;
};

export type WebhookSubscriptionCreateInput = {
  webhook?: Maybe<WebhookRelateToOneInput>;
  url?: Maybe<Scalars['String']>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAmount?: Maybe<Scalars['Int']>;
  failuresCount?: Maybe<Scalars['Int']>;
  model?: Maybe<WebhookSubscriptionModelType>;
  fields?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  maxPackSize?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

/**  A keystone list  */
export type WebhookSubscriptionHistoryRecord = {
  __typename?: 'WebhookSubscriptionHistoryRecord';
  /**
   * This virtual field will be resolved in one of the following ways (in this order):
   *  1. Execution of 'labelResolver' set on the WebhookSubscriptionHistoryRecord List config, or
   *  2. As an alias to the field set on 'labelField' in the WebhookSubscriptionHistoryRecord List config, or
   *  3. As an alias to a 'name' field on the WebhookSubscriptionHistoryRecord List (if one exists), or
   *  4. As an alias to the 'id' field on the WebhookSubscriptionHistoryRecord List.
   */
  _label_?: Maybe<Scalars['String']>;
  webhook?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAmount?: Maybe<Scalars['Int']>;
  failuresCount?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  maxPackSize?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type WebhookSubscriptionHistoryRecordCreateInput = {
  webhook?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAmount?: Maybe<Scalars['Int']>;
  failuresCount?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  maxPackSize?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export enum WebhookSubscriptionHistoryRecordHistoryActionType {
  C = 'c',
  U = 'u',
  D = 'd'
}

export type WebhookSubscriptionHistoryRecordUpdateInput = {
  webhook?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAmount?: Maybe<Scalars['Int']>;
  failuresCount?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  maxPackSize?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['JSON']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<Scalars['JSON']>;
  history_date?: Maybe<Scalars['String']>;
  history_action?: Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>;
  history_id?: Maybe<Scalars['String']>;
};

export type WebhookSubscriptionHistoryRecordWhereInput = {
  AND?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecordWhereInput>>>;
  OR?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecordWhereInput>>>;
  webhook?: Maybe<Scalars['String']>;
  webhook_not?: Maybe<Scalars['String']>;
  webhook_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  webhook_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  url_i?: Maybe<Scalars['String']>;
  url_not_i?: Maybe<Scalars['String']>;
  url_contains_i?: Maybe<Scalars['String']>;
  url_not_contains_i?: Maybe<Scalars['String']>;
  url_starts_with_i?: Maybe<Scalars['String']>;
  url_not_starts_with_i?: Maybe<Scalars['String']>;
  url_ends_with_i?: Maybe<Scalars['String']>;
  url_not_ends_with_i?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAt_not?: Maybe<Scalars['String']>;
  syncedAt_lt?: Maybe<Scalars['String']>;
  syncedAt_lte?: Maybe<Scalars['String']>;
  syncedAt_gt?: Maybe<Scalars['String']>;
  syncedAt_gte?: Maybe<Scalars['String']>;
  syncedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncedAmount?: Maybe<Scalars['Int']>;
  syncedAmount_not?: Maybe<Scalars['Int']>;
  syncedAmount_lt?: Maybe<Scalars['Int']>;
  syncedAmount_lte?: Maybe<Scalars['Int']>;
  syncedAmount_gt?: Maybe<Scalars['Int']>;
  syncedAmount_gte?: Maybe<Scalars['Int']>;
  syncedAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  syncedAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  failuresCount?: Maybe<Scalars['Int']>;
  failuresCount_not?: Maybe<Scalars['Int']>;
  failuresCount_lt?: Maybe<Scalars['Int']>;
  failuresCount_lte?: Maybe<Scalars['Int']>;
  failuresCount_gt?: Maybe<Scalars['Int']>;
  failuresCount_gte?: Maybe<Scalars['Int']>;
  failuresCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  failuresCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  model?: Maybe<Scalars['String']>;
  model_not?: Maybe<Scalars['String']>;
  model_contains?: Maybe<Scalars['String']>;
  model_not_contains?: Maybe<Scalars['String']>;
  model_starts_with?: Maybe<Scalars['String']>;
  model_not_starts_with?: Maybe<Scalars['String']>;
  model_ends_with?: Maybe<Scalars['String']>;
  model_not_ends_with?: Maybe<Scalars['String']>;
  model_i?: Maybe<Scalars['String']>;
  model_not_i?: Maybe<Scalars['String']>;
  model_contains_i?: Maybe<Scalars['String']>;
  model_not_contains_i?: Maybe<Scalars['String']>;
  model_starts_with_i?: Maybe<Scalars['String']>;
  model_not_starts_with_i?: Maybe<Scalars['String']>;
  model_ends_with_i?: Maybe<Scalars['String']>;
  model_not_ends_with_i?: Maybe<Scalars['String']>;
  model_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  model_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields?: Maybe<Scalars['String']>;
  fields_not?: Maybe<Scalars['String']>;
  fields_contains?: Maybe<Scalars['String']>;
  fields_not_contains?: Maybe<Scalars['String']>;
  fields_starts_with?: Maybe<Scalars['String']>;
  fields_not_starts_with?: Maybe<Scalars['String']>;
  fields_ends_with?: Maybe<Scalars['String']>;
  fields_not_ends_with?: Maybe<Scalars['String']>;
  fields_i?: Maybe<Scalars['String']>;
  fields_not_i?: Maybe<Scalars['String']>;
  fields_contains_i?: Maybe<Scalars['String']>;
  fields_not_contains_i?: Maybe<Scalars['String']>;
  fields_starts_with_i?: Maybe<Scalars['String']>;
  fields_not_starts_with_i?: Maybe<Scalars['String']>;
  fields_ends_with_i?: Maybe<Scalars['String']>;
  fields_not_ends_with_i?: Maybe<Scalars['String']>;
  fields_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  filters_not?: Maybe<Scalars['JSON']>;
  filters_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  filters_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  maxPackSize?: Maybe<Scalars['Int']>;
  maxPackSize_not?: Maybe<Scalars['Int']>;
  maxPackSize_lt?: Maybe<Scalars['Int']>;
  maxPackSize_lte?: Maybe<Scalars['Int']>;
  maxPackSize_gt?: Maybe<Scalars['Int']>;
  maxPackSize_gte?: Maybe<Scalars['Int']>;
  maxPackSize_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  maxPackSize_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<Scalars['String']>;
  createdBy_not?: Maybe<Scalars['String']>;
  createdBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy?: Maybe<Scalars['String']>;
  updatedBy_not?: Maybe<Scalars['String']>;
  updatedBy_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['JSON']>;
  newId_not?: Maybe<Scalars['JSON']>;
  newId_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<Scalars['JSON']>;
  sender_not?: Maybe<Scalars['JSON']>;
  sender_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  sender_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  history_date?: Maybe<Scalars['String']>;
  history_date_not?: Maybe<Scalars['String']>;
  history_date_lt?: Maybe<Scalars['String']>;
  history_date_lte?: Maybe<Scalars['String']>;
  history_date_gt?: Maybe<Scalars['String']>;
  history_date_gte?: Maybe<Scalars['String']>;
  history_date_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_date_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_action?: Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>;
  history_action_not?: Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>;
  history_action_in?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>>>;
  history_action_not_in?: Maybe<Array<Maybe<WebhookSubscriptionHistoryRecordHistoryActionType>>>;
  history_id?: Maybe<Scalars['String']>;
  history_id_not?: Maybe<Scalars['String']>;
  history_id_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  history_id_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type WebhookSubscriptionHistoryRecordWhereUniqueInput = {
  id: Scalars['ID'];
};

export type WebhookSubscriptionHistoryRecordsCreateInput = {
  data?: Maybe<WebhookSubscriptionHistoryRecordCreateInput>;
};

export type WebhookSubscriptionHistoryRecordsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<WebhookSubscriptionHistoryRecordUpdateInput>;
};

export enum WebhookSubscriptionModelType {
  User = 'User',
  Organization = 'Organization',
  Ticket = 'Ticket',
  TicketComment = 'TicketComment',
  Contact = 'Contact',
  UserHelpRequest = 'UserHelpRequest',
  AcquiringIntegrationContext = 'AcquiringIntegrationContext',
  B2BApp = 'B2BApp',
  B2BAppContext = 'B2BAppContext'
}

export type WebhookSubscriptionUpdateInput = {
  webhook?: Maybe<WebhookRelateToOneInput>;
  url?: Maybe<Scalars['String']>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAmount?: Maybe<Scalars['Int']>;
  failuresCount?: Maybe<Scalars['Int']>;
  model?: Maybe<WebhookSubscriptionModelType>;
  fields?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSON']>;
  maxPackSize?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type WebhookSubscriptionWhereInput = {
  AND?: Maybe<Array<Maybe<WebhookSubscriptionWhereInput>>>;
  OR?: Maybe<Array<Maybe<WebhookSubscriptionWhereInput>>>;
  webhook?: Maybe<WebhookWhereInput>;
  webhook_is_null?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  url_i?: Maybe<Scalars['String']>;
  url_not_i?: Maybe<Scalars['String']>;
  url_contains_i?: Maybe<Scalars['String']>;
  url_not_contains_i?: Maybe<Scalars['String']>;
  url_starts_with_i?: Maybe<Scalars['String']>;
  url_not_starts_with_i?: Maybe<Scalars['String']>;
  url_ends_with_i?: Maybe<Scalars['String']>;
  url_not_ends_with_i?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncedAt?: Maybe<Scalars['String']>;
  syncedAt_not?: Maybe<Scalars['String']>;
  syncedAt_lt?: Maybe<Scalars['String']>;
  syncedAt_lte?: Maybe<Scalars['String']>;
  syncedAt_gt?: Maybe<Scalars['String']>;
  syncedAt_gte?: Maybe<Scalars['String']>;
  syncedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  syncedAmount?: Maybe<Scalars['Int']>;
  syncedAmount_not?: Maybe<Scalars['Int']>;
  syncedAmount_lt?: Maybe<Scalars['Int']>;
  syncedAmount_lte?: Maybe<Scalars['Int']>;
  syncedAmount_gt?: Maybe<Scalars['Int']>;
  syncedAmount_gte?: Maybe<Scalars['Int']>;
  syncedAmount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  syncedAmount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  failuresCount?: Maybe<Scalars['Int']>;
  failuresCount_not?: Maybe<Scalars['Int']>;
  failuresCount_lt?: Maybe<Scalars['Int']>;
  failuresCount_lte?: Maybe<Scalars['Int']>;
  failuresCount_gt?: Maybe<Scalars['Int']>;
  failuresCount_gte?: Maybe<Scalars['Int']>;
  failuresCount_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  failuresCount_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  model?: Maybe<WebhookSubscriptionModelType>;
  model_not?: Maybe<WebhookSubscriptionModelType>;
  model_in?: Maybe<Array<Maybe<WebhookSubscriptionModelType>>>;
  model_not_in?: Maybe<Array<Maybe<WebhookSubscriptionModelType>>>;
  fields?: Maybe<Scalars['String']>;
  fields_not?: Maybe<Scalars['String']>;
  fields_contains?: Maybe<Scalars['String']>;
  fields_not_contains?: Maybe<Scalars['String']>;
  fields_starts_with?: Maybe<Scalars['String']>;
  fields_not_starts_with?: Maybe<Scalars['String']>;
  fields_ends_with?: Maybe<Scalars['String']>;
  fields_not_ends_with?: Maybe<Scalars['String']>;
  fields_i?: Maybe<Scalars['String']>;
  fields_not_i?: Maybe<Scalars['String']>;
  fields_contains_i?: Maybe<Scalars['String']>;
  fields_not_contains_i?: Maybe<Scalars['String']>;
  fields_starts_with_i?: Maybe<Scalars['String']>;
  fields_not_starts_with_i?: Maybe<Scalars['String']>;
  fields_ends_with_i?: Maybe<Scalars['String']>;
  fields_not_ends_with_i?: Maybe<Scalars['String']>;
  fields_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  fields_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSON']>;
  filters_not?: Maybe<Scalars['JSON']>;
  filters_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  filters_not_in?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  maxPackSize?: Maybe<Scalars['Int']>;
  maxPackSize_not?: Maybe<Scalars['Int']>;
  maxPackSize_lt?: Maybe<Scalars['Int']>;
  maxPackSize_lte?: Maybe<Scalars['Int']>;
  maxPackSize_gt?: Maybe<Scalars['Int']>;
  maxPackSize_gte?: Maybe<Scalars['Int']>;
  maxPackSize_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  maxPackSize_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type WebhookSubscriptionWhereUniqueInput = {
  id: Scalars['ID'];
};

export type WebhookSubscriptionsCreateInput = {
  data?: Maybe<WebhookSubscriptionCreateInput>;
};

export type WebhookSubscriptionsUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<WebhookSubscriptionUpdateInput>;
};

export type WebhookUpdateInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<UserRelateToOneInput>;
  v?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<UserRelateToOneInput>;
  updatedBy?: Maybe<UserRelateToOneInput>;
  deletedAt?: Maybe<Scalars['String']>;
  newId?: Maybe<Scalars['String']>;
  dv?: Maybe<Scalars['Int']>;
  sender?: Maybe<SenderFieldInput>;
};

export type WebhookWhereInput = {
  AND?: Maybe<Array<Maybe<WebhookWhereInput>>>;
  OR?: Maybe<Array<Maybe<WebhookWhereInput>>>;
  name?: Maybe<Scalars['String']>;
  name_not?: Maybe<Scalars['String']>;
  name_contains?: Maybe<Scalars['String']>;
  name_not_contains?: Maybe<Scalars['String']>;
  name_starts_with?: Maybe<Scalars['String']>;
  name_not_starts_with?: Maybe<Scalars['String']>;
  name_ends_with?: Maybe<Scalars['String']>;
  name_not_ends_with?: Maybe<Scalars['String']>;
  name_i?: Maybe<Scalars['String']>;
  name_not_i?: Maybe<Scalars['String']>;
  name_contains_i?: Maybe<Scalars['String']>;
  name_not_contains_i?: Maybe<Scalars['String']>;
  name_starts_with_i?: Maybe<Scalars['String']>;
  name_not_starts_with_i?: Maybe<Scalars['String']>;
  name_ends_with_i?: Maybe<Scalars['String']>;
  name_not_ends_with_i?: Maybe<Scalars['String']>;
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  description_not?: Maybe<Scalars['String']>;
  description_contains?: Maybe<Scalars['String']>;
  description_not_contains?: Maybe<Scalars['String']>;
  description_starts_with?: Maybe<Scalars['String']>;
  description_not_starts_with?: Maybe<Scalars['String']>;
  description_ends_with?: Maybe<Scalars['String']>;
  description_not_ends_with?: Maybe<Scalars['String']>;
  description_i?: Maybe<Scalars['String']>;
  description_not_i?: Maybe<Scalars['String']>;
  description_contains_i?: Maybe<Scalars['String']>;
  description_not_contains_i?: Maybe<Scalars['String']>;
  description_starts_with_i?: Maybe<Scalars['String']>;
  description_not_starts_with_i?: Maybe<Scalars['String']>;
  description_ends_with_i?: Maybe<Scalars['String']>;
  description_not_ends_with_i?: Maybe<Scalars['String']>;
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  url_not?: Maybe<Scalars['String']>;
  url_contains?: Maybe<Scalars['String']>;
  url_not_contains?: Maybe<Scalars['String']>;
  url_starts_with?: Maybe<Scalars['String']>;
  url_not_starts_with?: Maybe<Scalars['String']>;
  url_ends_with?: Maybe<Scalars['String']>;
  url_not_ends_with?: Maybe<Scalars['String']>;
  url_i?: Maybe<Scalars['String']>;
  url_not_i?: Maybe<Scalars['String']>;
  url_contains_i?: Maybe<Scalars['String']>;
  url_not_contains_i?: Maybe<Scalars['String']>;
  url_starts_with_i?: Maybe<Scalars['String']>;
  url_not_starts_with_i?: Maybe<Scalars['String']>;
  url_ends_with_i?: Maybe<Scalars['String']>;
  url_not_ends_with_i?: Maybe<Scalars['String']>;
  url_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  user?: Maybe<UserWhereInput>;
  user_is_null?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  id_not?: Maybe<Scalars['ID']>;
  id_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id_not_in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  v?: Maybe<Scalars['Int']>;
  v_not?: Maybe<Scalars['Int']>;
  v_lt?: Maybe<Scalars['Int']>;
  v_lte?: Maybe<Scalars['Int']>;
  v_gt?: Maybe<Scalars['Int']>;
  v_gte?: Maybe<Scalars['Int']>;
  v_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  v_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['String']>;
  createdAt_not?: Maybe<Scalars['String']>;
  createdAt_lt?: Maybe<Scalars['String']>;
  createdAt_lte?: Maybe<Scalars['String']>;
  createdAt_gt?: Maybe<Scalars['String']>;
  createdAt_gte?: Maybe<Scalars['String']>;
  createdAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedAt_not?: Maybe<Scalars['String']>;
  updatedAt_lt?: Maybe<Scalars['String']>;
  updatedAt_lte?: Maybe<Scalars['String']>;
  updatedAt_gt?: Maybe<Scalars['String']>;
  updatedAt_gte?: Maybe<Scalars['String']>;
  updatedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdBy?: Maybe<UserWhereInput>;
  createdBy_is_null?: Maybe<Scalars['Boolean']>;
  updatedBy?: Maybe<UserWhereInput>;
  updatedBy_is_null?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['String']>;
  deletedAt_not?: Maybe<Scalars['String']>;
  deletedAt_lt?: Maybe<Scalars['String']>;
  deletedAt_lte?: Maybe<Scalars['String']>;
  deletedAt_gt?: Maybe<Scalars['String']>;
  deletedAt_gte?: Maybe<Scalars['String']>;
  deletedAt_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  deletedAt_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId?: Maybe<Scalars['String']>;
  newId_not?: Maybe<Scalars['String']>;
  newId_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  newId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>;
  dv?: Maybe<Scalars['Int']>;
  dv_not?: Maybe<Scalars['Int']>;
  dv_lt?: Maybe<Scalars['Int']>;
  dv_lte?: Maybe<Scalars['Int']>;
  dv_gt?: Maybe<Scalars['Int']>;
  dv_gte?: Maybe<Scalars['Int']>;
  dv_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dv_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sender?: Maybe<SenderFieldInput>;
  sender_not?: Maybe<SenderFieldInput>;
  sender_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
  sender_not_in?: Maybe<Array<Maybe<SenderFieldInput>>>;
};

export type WebhookWhereUniqueInput = {
  id: Scalars['ID'];
};

export type WebhooksCreateInput = {
  data?: Maybe<WebhookCreateInput>;
};

export type WebhooksUpdateInput = {
  id: Scalars['ID'];
  data?: Maybe<WebhookUpdateInput>;
};

export type _ListAccess = {
  __typename?: '_ListAccess';
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'create' operations.
   * NOTE: 'create' can only return a Boolean.
   * It is not possible to specify a declarative Where clause for this
   * operation
   */
  create?: Maybe<Scalars['Boolean']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'read' operations.
   */
  read?: Maybe<Scalars['JSON']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'update' operations.
   */
  update?: Maybe<Scalars['JSON']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'delete' operations.
   */
  delete?: Maybe<Scalars['JSON']>;
  /**
   * Access Control settings for the currently logged in (or anonymous)
   * user when performing 'auth' operations.
   */
  auth?: Maybe<Scalars['JSON']>;
};

export type _ListInputTypes = {
  __typename?: '_ListInputTypes';
  /** Input type for matching multiple items */
  whereInput?: Maybe<Scalars['String']>;
  /** Input type for matching a unique item */
  whereUniqueInput?: Maybe<Scalars['String']>;
  /** Create mutation input type name */
  createInput?: Maybe<Scalars['String']>;
  /** Create many mutation input type name */
  createManyInput?: Maybe<Scalars['String']>;
  /** Update mutation name input */
  updateInput?: Maybe<Scalars['String']>;
  /** Update many mutation name input */
  updateManyInput?: Maybe<Scalars['String']>;
};

export type _ListMeta = {
  __typename?: '_ListMeta';
  /** The Keystone list key */
  key?: Maybe<Scalars['String']>;
  /**
   * The Keystone List name
   * @deprecated Use `key` instead
   */
  name?: Maybe<Scalars['String']>;
  /** The list's user-facing description */
  description?: Maybe<Scalars['String']>;
  /** The list's display name in the Admin UI */
  label?: Maybe<Scalars['String']>;
  /** The list's singular display name */
  singular?: Maybe<Scalars['String']>;
  /** The list's plural display name */
  plural?: Maybe<Scalars['String']>;
  /** The list's data path */
  path?: Maybe<Scalars['String']>;
  /** Access control configuration for the currently authenticated request */
  access?: Maybe<_ListAccess>;
  /** Information on the generated GraphQL schema */
  schema?: Maybe<_ListSchema>;
};

export type _ListMutations = {
  __typename?: '_ListMutations';
  /** Create mutation name */
  create?: Maybe<Scalars['String']>;
  /** Create many mutation name */
  createMany?: Maybe<Scalars['String']>;
  /** Update mutation name */
  update?: Maybe<Scalars['String']>;
  /** Update many mutation name */
  updateMany?: Maybe<Scalars['String']>;
  /** Delete mutation name */
  delete?: Maybe<Scalars['String']>;
  /** Delete many mutation name */
  deleteMany?: Maybe<Scalars['String']>;
};

export type _ListQueries = {
  __typename?: '_ListQueries';
  /** Single-item query name */
  item?: Maybe<Scalars['String']>;
  /** All-items query name */
  list?: Maybe<Scalars['String']>;
  /** List metadata query name */
  meta?: Maybe<Scalars['String']>;
};

export type _ListSchema = {
  __typename?: '_ListSchema';
  /** The typename as used in GraphQL queries */
  type?: Maybe<Scalars['String']>;
  /**
   * Top level GraphQL query names which either return this type, or
   * provide aggregate information about this type
   */
  queries?: Maybe<_ListQueries>;
  /** Top-level GraphQL mutation names */
  mutations?: Maybe<_ListMutations>;
  /** Top-level GraphQL input types */
  inputTypes?: Maybe<_ListInputTypes>;
  /** Information about fields defined on this list */
  fields?: Maybe<Array<Maybe<_ListSchemaFields>>>;
  /**
   * Information about fields on other types which return this type, or
   * provide aggregate information about this type
   */
  relatedFields?: Maybe<Array<Maybe<_ListSchemaRelatedFields>>>;
};


export type _ListSchemaFieldsArgs = {
  where?: Maybe<_ListSchemaFieldsInput>;
};

export type _ListSchemaFields = {
  __typename?: '_ListSchemaFields';
  /** The path of the field in its list */
  path?: Maybe<Scalars['String']>;
  /**
   * The name of the field in its list
   * @deprecated Use `path` instead
   */
  name?: Maybe<Scalars['String']>;
  /** The field type (ie, Checkbox, Text, etc) */
  type?: Maybe<Scalars['String']>;
};

export type _ListSchemaFieldsInput = {
  type?: Maybe<Scalars['String']>;
};

export type _ListSchemaRelatedFields = {
  __typename?: '_ListSchemaRelatedFields';
  /** The typename as used in GraphQL queries */
  type?: Maybe<Scalars['String']>;
  /** A list of GraphQL field names */
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type _QueryMeta = {
  __typename?: '_QueryMeta';
  count?: Maybe<Scalars['Int']>;
};

export type _InternalDeleteMeterAndMeterReadingsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  propertyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationId: Scalars['ID'];
};

export type _InternalDeleteMeterAndMeterReadingsOutput = {
  __typename?: '_internalDeleteMeterAndMeterReadingsOutput';
  status: Status;
  metersToDelete: Scalars['Int'];
  deletedMeters: Scalars['Int'];
};

export type _InternalDeleteMeterReadingsInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  propertyIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  organizationId: Scalars['ID'];
  startDateTime: Scalars['String'];
  endDateTime: Scalars['String'];
};

export type _InternalDeleteMeterReadingsOutput = {
  __typename?: '_internalDeleteMeterReadingsOutput';
  status: Status;
  toDelete: Scalars['Int'];
  deleted: Scalars['Int'];
};

export type _InternalScheduleTaskByNameInput = {
  dv: Scalars['Int'];
  sender: Scalars['JSON'];
  taskName: Scalars['String'];
  taskArgs?: Maybe<Scalars['JSON']>;
};

export type _InternalScheduleTaskByNameOutput = {
  __typename?: '_internalScheduleTaskByNameOutput';
  id: Scalars['String'];
};

export type _InternalSendHashedResidentPhonesInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
};

export type _InternalSendHashedResidentPhonesOutput = {
  __typename?: '_internalSendHashedResidentPhonesOutput';
  taskId: Scalars['ID'];
};

export type _InternalSendNotificationNewMobileAppVersionInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  platform: MobilePlatform;
  app: MobileApp;
  buildVersion: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  organizationIds?: Maybe<Array<Scalars['ID']>>;
};

export type _InternalSendNotificationNewMobileAppVersionOutput = {
  __typename?: '_internalSendNotificationNewMobileAppVersionOutput';
  messageBatchId: Scalars['ID'];
};

export type _InternalSyncContactsWithResidentsForOrganizationInput = {
  dv: Scalars['Int'];
  sender: SenderFieldInput;
  organization: OrganizationWhereUniqueInput;
};

export type _KsListsMetaInput = {
  key?: Maybe<Scalars['String']>;
  /** Whether this is an auxiliary helper list */
  auxiliary?: Maybe<Scalars['Boolean']>;
};

export type AuthenticateUserOutput = {
  __typename?: 'authenticateUserOutput';
  /**  Used to make subsequent authenticated requests by setting this token in a header: 'Authorization: Bearer <token>'.  */
  token?: Maybe<Scalars['String']>;
  /**  Retrieve information on the newly authenticated User here.  */
  item?: Maybe<User>;
};

export type ResidentOrganization = {
  __typename?: 'residentOrganization';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type UnauthenticateUserOutput = {
  __typename?: 'unauthenticateUserOutput';
  /**
   * `true` when unauthentication succeeds.
   * NOTE: unauthentication always succeeds when the request has an invalid or missing authentication token.
   */
  success?: Maybe<Scalars['Boolean']>;
};
